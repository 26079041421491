import {
  Box,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { BuyerProduct, LineItem } from "ordercloud-javascript-sdk";
import React, { useMemo, Fragment } from "react";
import { DeliveryTypes } from "../../../models/buyerXp";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import ProductPrice from "../ProductPrice";
import { neutral } from "../../../themes/colors";
import { AseDrawer } from "../../../models/aseDrawer";
import { flatten } from "lodash";
import BachmansCarousel from "../../Shared/BachmansCarousel";
import { Quantity } from "../../../models/product";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmProductDisplay from "./ConfirmProductDisplay";
import BachmansBadge from "../../../assets/svg/logoBadge.svg";
import deliveryService from "../../../services/delivery.service";
import productService from "../../../services/product.service";

interface AddToCartConfirmationProps {
  open: boolean;
  aseDrawers?: AseDrawer[];
  quantity?: Quantity;
  onClose: () => void;
  lineItems: LineItem[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productInfo: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    productInfoPlaceholderImage: {
      backgroundColor: neutral.disabled,
      padding: theme.spacing(2),
      width: 75,
      height: 75,
    },
    productInfoProductImage: {
      width: 75,
      height: 75,
      aspectRatio: "1 / 1",
      objectFit: "cover",
    },
    diaglogContentNoASE: {
      flex: "none",
      display: "none",
    },
    productText: {},
    header: {
      backgroundColor: neutral.grey_background,
      padding: theme.spacing(2, 3),
      position: "relative",
      display: "flex",
      flexFlow: "column nowrap",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2, 3, 5, 3),
        minHeight: "unset",
      },
    },
    headerTextContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    headerText: {
      fontSize: 20,
      fontWeight: 500,
    },
    headerConfirmation: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(2),
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        gap: theme.spacing(4),
        flexFlow: "row nowrap",
      },
    },
    buttonClose: {
      alignSelf: "flex-end",
      marginTop: -12,
      marginRight: -20,
    },
    dialogPaper: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "50vw",
      },
    },
    confirmProductDisplayHeader: {
      backgroundColor: neutral.search_bg,
      margin: theme.spacing(2, -1.5),
    },
    dialogActions: {
      display: "flex",
      justifyContent: "center",
      flexFlow: "column nowrap",
      gap: theme.spacing(3),
      padding: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        flexFlow: "row nowrap",
      },
    },
    text: {
      color: neutral.text_colored_bg,
    },
    subtext: {
      color: theme.palette.grey[600],
      lineHeight: 1,
      marginBottom: theme.spacing(1),
    },
    carouselWrapper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      paddingLeft: 8,
      maxWidth: "90%",
      "& .MuiTypography-h3": {
        margin: theme.spacing(0, 0, 2, 1),
        fontSize: 14,
        color: theme.palette.text.secondary,
      },
      "& .slick-slider button.slick-prev": {
        marginLeft: 12,
      },
      "& .slick-slider button.slick-next": {
        marginRight: 0,
      },
    },
  })
);

const responsiveSettings = (theme: Theme) => ({
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  easing: "ease",
  responsive: [
    {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        infinite: true,
      },
    },
  ],
});

const AddToCartConfirmation: React.FunctionComponent<AddToCartConfirmationProps> = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { open, onClose, aseDrawers, quantity, lineItems } = props;
  const theme = useTheme();
  let history = useHistory();
  const drawerProducts = useMemo(() => {
    return flatten(aseDrawers?.map((drawer) => drawer.products as BuyerProduct));
  }, [aseDrawers]);

  const getDeliveryText = (deliveryMethod?: DeliveryTypes): string => {
    if (deliveryService.IsPickUp(deliveryMethod)) {
      return "Pickup";
    } else return "Delivery";
  };

  const goToCart = () => history.push("/cart");

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // map through ASE array passed in.

  //what if something can't get added to cart? maybe do a catch on the on submit && modify the ASE array-
  const isASEAdded = () => {
    return lineItems.length > 1;
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      PaperProps={{ square: true }}
      fullScreen={isMobile}
      maxWidth="xl"
    >
      <div className={classes.header}>
        <IconButton onClick={onClose} className={classes.buttonClose}>
          <CloseIcon color="action" />
        </IconButton>
        <Box className={classes.headerConfirmation}>
          <Box className={classes.headerTextContainer} alignItems={isASEAdded() ? "center" : "flex-start"}>
            {lineItems[0]?.xp?.EventName ? (
              <Typography
                align={isASEAdded() ? "center" : "left"}
                className={classes.headerText}
                variant="h4"
                color="primary"
              >
                {`Event Ticket${quantity?.quantity! > 1 ? "s" : ""}  Added to Cart`}
              </Typography>
            ) : (
              <Typography
                gutterBottom
                align={isASEAdded() ? "center" : "left"}
                className={classes.headerText}
                variant="h4"
                color="primary"
              >
                Added to Cart for <strong>{getDeliveryText(lineItems[0]?.xp?.DeliveryMethod) as DeliveryTypes}</strong>
              </Typography>
            )}
            {!lineItems[0]?.Product?.xp?.IsWorkShopEvent && lineItems[0]?.xp?.DeliveryMethod !== "Email" && (
              <Typography variant="body2" color="textSecondary">
                {lineItems[0]?.xp?.DeliveryMethod === "InStorePickUp" ||
                lineItems[0]?.xp?.DeliveryMethod === "CurbsidePickUp"
                  ? "Select your pickup store in cart"
                  : "Recipient information can be entered in the cart"}
              </Typography>
            )}
          </Box>
          {!isASEAdded() && (
            <Fragment>
              <Divider orientation="vertical" flexItem />
              <Box display="flex" className={classes.productInfo}>
                {lineItems[0]?.xp?.ShownImage ? (
                  <img
                    className={classes.productInfoProductImage}
                    src={lineItems[0]?.xp?.ShownImage}
                    alt={lineItems[0]?.Product?.Name}
                  ></img>
                ) : (
                  <img
                    className={classes.productInfoPlaceholderImage}
                    src={BachmansBadge}
                    alt="No product image found"
                    aria-hidden="true"
                  />
                )}
                <div className={classes.productText}>
                  <Typography variant="h4">{productService.GetLineItemName(lineItems[0])}</Typography>
                  {lineItems[0]?.Product?.xp?.SpecsOptions?.Color && (
                    <Typography title="lineItemIDCart" className={classes.subtext} variant="overline">
                      {lineItems[0]?.Product?.xp?.IsWorkShopEvent
                        ? "Event Time: " +
                          lineItems[0]?.Product?.xp?.SpecsOptions?.Size +
                          " - " +
                          lineItems[0]?.Product?.xp?.SpecsOptions?.Color
                        : "Color " + lineItems[0]?.Product?.xp?.SpecsOptions?.Color}
                    </Typography>
                  )}
                  {lineItems[0]?.Product?.xp?.SpecsOptions?.Size && !lineItems[0]?.Product?.xp?.IsWorkShopEvent && (
                    <Typography title="lineItemIDCart" className={classes.subtext} variant="overline">
                      Type: {lineItems[0]?.Product?.xp?.SpecsOptions?.Size}
                    </Typography>
                  )}
                  <ProductPrice lineItem={lineItems[0]} alignment="left" />
                  <Typography color="textSecondary" variant="body2">
                    QUANTITY: {quantity?.quantity}
                  </Typography>
                </div>
              </Box>
            </Fragment>
          )}
        </Box>
      </div>
      {(drawerProducts || isASEAdded()) && (
        <DialogContent className={!isASEAdded() ? classes.diaglogContentNoASE : ""}>
          {isASEAdded() && (
            <Container>
              <Grid container spacing={3} className={classes.confirmProductDisplayHeader}>
                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="overline">
                    Product
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" variant="overline">
                    Qty
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Typography color="textSecondary" variant="overline">
                    Price
                  </Typography>
                </Grid>
              </Grid>
              {lineItems.map((li) => (
                <ConfirmProductDisplay lineItem={li} />
              ))}
            </Container>
          )}

          {!!(drawerProducts && drawerProducts.length && !isASEAdded()) && (
            <Container className={classes.carouselWrapper} maxWidth="md">
              <BachmansCarousel
                variant="product"
                carouselData={drawerProducts}
                carouselTitle="Frequently Bought Together"
                settings={responsiveSettings(theme)}
              />
            </Container>
          )}
        </DialogContent>
      )}
      <DialogActions disableSpacing className={classes.dialogActions}>
        <DoubleOutlinedBtn
          buttonText="Continue Shopping"
          styleProps={{
            paddingLeft: 40,
            paddingRight: 40,
            width: isMobile ? "100%" : "",
          }}
          buttonProps={{
            onClick: handleClose,
            variant: "outlined",
            color: "primary",
          }}
        />
        <DoubleOutlinedBtn
          buttonText="View Cart & Checkout"
          styleProps={{
            paddingLeft: 40,
            paddingRight: 40,
            width: isMobile ? "100%" : "",
          }}
          buttonProps={{
            variant: "contained",
            color: "primary",
            onClick: goToCart,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddToCartConfirmation;
