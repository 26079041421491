import React, { FunctionComponent, Fragment, useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  alpha,
  Box,
  Button,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Address } from "ordercloud-javascript-sdk";
import { neutral } from "../../themes/colors";
import QuantityInput from "../Shared/QuantityInput";
import LocationDropDown from "./LocationDropDown";
import { DeliveryTypes } from "../../models/buyerXp";
import { SplitQuantityItem } from "./SplitQuantityModal";
import { Quantity } from "../../models/product";

interface SplitQuantityAccordionProps {
  item: SplitQuantityItem;
  quantity: Quantity;
  index: number; // index of item in array
  onAddressUpdate: (address: Address, inxex: number, routeCode?: string) => Promise<void>;
  onAddressRemove?: (index: number) => void;
  onQuantityUpdate: (quantity: number, index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addressName: {
      display: "inline",
      fontWeight: "bold",
      color: neutral.text_colored_bg,
    },
    addressBody: {
      fontStyle: "italic",
      display: "inline",
      marginLeft: "5px",
      color: neutral.text_colored_bg,
    },
    addressAccordion: {
      boxShadow: "none",
      backgroundColor: neutral.search_bg,
      border: `1px solid ${neutral.grey_background}`,
    },
    addressAccordionSummary: {
      "& .MuiAccordionSummary-content": {
        [theme.breakpoints.down("sm")]: {
          flexFlow: "column nowrap",
        },
      },
      "& .MuiFormControlLabel-root": {
        display: "flex",
        alignItems: "flex-start",
        margin: 0,
        gap: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          gap: theme.spacing(3),
        },
      },
      "& .MuiFormControl-root": {
        minWidth: 75,
        marginTop: 4,
      },
      "& .MuiSelect-root": {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          minWidth: 250,
        },
      },
    },
    addressQuantityInput: {
      width: 100,
    },
    addressAccordionDetails: {
      display: "grid",
      placeItems: "center stretch",
      gap: theme.spacing(2),
      maxHeight: 300,
      overFlowY: "auto",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat(auto-fit, minmax(175px, 1fr))",
      },
    },

    addressCard: {
      height: "min(125px, 150px)",
      overFlowY: "hidden",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flext-start",
      justifyContent: "flex-start",
      position: "relative",
      "& .MuiCardActions-root": {
        background: `linear-gradient(to left, ${alpha(theme.palette.primary.light, 1)}, ${alpha(
          neutral.grey_background,
          0
        )})`,
        width: "100%",
        position: "absolute",
        bottom: 0,
        right: 0,
        [theme.breakpoints.up("sm")]: {
          opacity: 0.75,
          "&:hover": {
            opacity: 1,
          },
        },
        "& button:first-child": {
          marginLeft: "auto",
        },
        "& button": {
          fontSize: 10,
          padding: theme.spacing(0, 2),
        },
      },
    },
    addressCardContent: {
      maxHeight: 120,
      overflowY: "auto",
    },
    buttonEditAddress: {
      fontSize: 10,
      color: neutral.text_light_bg,
      padding: 4,
      marginLeft: "auto",
      backgroundColor: "white",
      border: `1px solid ${neutral.grey_background}`,
      alignSelf: "center",
      "& .MuiSvgIcon-root": {
        fontSize: 12,
      },
    },
    buttongGroupSplitQty: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: theme.spacing(2),
      marginLeft: "auto",
      marginTop: 8,
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
      },
    },
  })
);

const SplitQuantityAccordion: FunctionComponent<SplitQuantityAccordionProps> = (props) => {
  const classes = useStyles();
  const { item, quantity, index, onAddressUpdate, onAddressRemove, onQuantityUpdate } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleAddressUpdate = (address: Address, routeCode?: string) => {
    setIsEditing(false);
    return onAddressUpdate(address, index, routeCode);
  };

  const handleAddressEdit = () => {
    setIsEditing((val) => !val);
  };

  const handleRemoveAddress = useCallback(() => {
    if (onAddressRemove && index) {
      onAddressRemove(index);
    }
  }, [index, onAddressRemove]);

  const handleQuantityUpdate = (quantity: number) => {
    onQuantityUpdate(quantity, index);
  };

  const displayRemoveButton = () => {
    if (index && index >= 1) return true;
    return false;
  };

  return (
    <Accordion className={classes.addressAccordion} square expanded={false}>
      <AccordionSummary className={classes.addressAccordionSummary} id="accordionSplitQty">
        {item.address && !isEditing ? (
          <Fragment>
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <QuantityInput
                  hideLabel={true}
                  lineItem={item.lineItem}
                  quantity={quantity}
                  onChange={handleQuantityUpdate}
                  disabled={false}
                  pending={false}
                />
              }
              label={
                <Box id="WOLOLOLOLOLO" alignSelf="center">
                  <Address1Line address={item.address} />
                </Box>
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <QuantityInput
                  hideLabel={true}
                  lineItem={item.lineItem}
                  quantity={quantity}
                  onChange={handleQuantityUpdate}
                  disabled={false}
                  pending={false}
                />
              }
              label={""}
            />
            <LocationDropDown
              formControlProps={{
                size: "small",
                margin: "dense",
                variant: "outlined",
                required: true,
                error: false,
              }}
              shipper={item.lineItem.xp?.DeliveryMethod as DeliveryTypes}
              formHelperText="Please select an address before continuing."
              onAddressSubmit={handleAddressUpdate}
            />
          </Fragment>
        )}
        <div className={classes.buttongGroupSplitQty}>
          {!isEditing && (
            <Button
              size="small"
              className={classes.buttonEditAddress}
              startIcon={<Edit fontSize="small" />}
              onClick={handleAddressEdit}
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <Button
              onClick={() => setIsEditing((val) => !val)}
              className={classes.buttonEditAddress}
              variant="text"
              size="small"
            >
              Cancel
            </Button>
          )}
          {/* if more than 2 address allow user to remove  */}
          {displayRemoveButton() && (
            <Button onClick={handleRemoveAddress} className={classes.buttonEditAddress} variant="text" size="small">
              Remove
            </Button>
          )}
        </div>
      </AccordionSummary>
    </Accordion>
  );
};
export default SplitQuantityAccordion;
interface Address1LineProps {
  address: Address;
}
const Address1Line: React.FunctionComponent<Address1LineProps> = (props) => {
  const { address } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.addressName} variant="body1">
        {address.CompanyName ? address.CompanyName : `${address.FirstName} ${address.LastName}`}
      </Typography>
      <Typography className={classes.addressBody} variant="body1">
        {`${address.Street1}, ${address.City}, ${address.State}, ${address.Zip}`}
      </Typography>
    </Fragment>
  );
};
