import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Address, BuyerAddress } from "ordercloud-javascript-sdk";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  TextField,
  Grid,
  Theme,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  makeStyles,
  createStyles,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import deliveryService from "../../../services/delivery.service";
import { cloneDeep, get, set } from "lodash";
import geography from "../../../constants/geography";
import { Destination } from "../../../constants/destinations";
import stringService from "../../../services/string.service";
import PhoneMaskInput from "../PhoneMaskInput";
import { Autocomplete } from "@material-ui/lab";
import { neutral } from "../../../themes/colors";
import { EditedTracker, RequiredValue } from "../../../models/address";
import locationService from "../../../services/locationService";
import GoogleAutoComplete from "../AutoComplete/GoogleAutoComplete";

export interface AddressFormProps {
  variant: "Shipping" | "Billing" | "InStorePickUp";
  address?: BuyerAddress;
  disabled?: boolean;
  addressStatic?: BuyerAddress;
  onAddressChange?: (value: BuyerAddress) => void;
  destinationOptions?: Destination[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    InStorePickUpWrapper: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        "& MuiGrid-item:first-child": {
          flex: "none",
          width: "100%",
        },
      },
    },
    CardStoreInformation: {
      backgroundColor: neutral.offwhite_bg,
      borderRadius: 0,
      flexGrow: 1,
      display: "flex",
      flexFlow: "column nowrap",
      [theme.breakpoints.up("lg")]: { height: "100%" },
    },
    CardStoreActions: {
      justifyContent: "flex-end",
      gap: theme.spacing(1),
      marginTop: "auto",
      "& a": {
        color: neutral.strikethrough,
        margin: "0 !important",
        fontSize: 12,
        padding: theme.spacing(0.5, 1.5),
      },
    },
  })
);

const initialEditedTracker: EditedTracker = {
  FirstName: false,
  LastName: false,
  Street1: false,
  State: false,
  City: false,
  Zip: false,
  Phone: false,
  "xp.addressType": false,
  "xp.Email": false,
  CompanyName: false,
};

const requiredFields: RequiredValue[] = [
  "FirstName",
  "LastName",
  "Street1",
  "City",
  "Zip",
  "State",
  "Phone",
  "xp.addressType",
];

const AddressForm: React.FunctionComponent<AddressFormProps> = (props) => {
  const { address, onAddressChange, addressStatic, destinationOptions, variant, disabled } = props;
  const [edited, setEdited] = useState<EditedTracker>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [bachmansStores, setBachmansStores] = useState<Address[]>();
  const [churchOption, setChurchOption] = useState<string>("Staff");
  const phoneInputRef = useRef<any>(null);
  const classes = useStyles();
  const directionsUrl = locationService.DirectionsUrlPrefix;

  useEffect(() => {
    deliveryService.GetPickUpStores().then((stores) => setBachmansStores(stores));
  }, []);

  useEffect(() => {
    setEdited(initialEditedTracker);
  }, [addressStatic]);

  const handleAddressChange = (path: string, value?: string) => {
    if (edited) {
      setEdited(set(cloneDeep(edited), path, true));
    }
    const addressToUpdate = cloneDeep(address);
    let val = value || "";
    if (["Zip", "Phone"].includes(path)) {
      val = stringService.OnlyNumbers(val);
    } else if (["FirstName", "LastName", "Email"].includes(path)) {
      val = stringService.OnlyAlpha(val);
    }
    const updatedAddress = set(addressToUpdate || {}, path, val);
    if (onAddressChange) onAddressChange(updatedAddress);
  };

  const handleStateChange = (state: string | null) => {
    const addressToUpdate = {
      ...address,
    };
    if (state && state !== null) {
      addressToUpdate.State = state;
    }
    if (onAddressChange) {
      onAddressChange(addressToUpdate);
    }
  };

  const handleStoreSelect = (event: React.ChangeEvent<any>) => {
    const selectedStore = bachmansStores?.find((store) => store.CompanyName === event.target.value);
    if (selectedStore) {
      set(selectedStore, "xp.addressType", "InStorePickUp");
      handleAddressSelect(selectedStore);
    }
  };

  const handleAddressSelect = (newAddress: Address) => {
    let updatedAddress = cloneDeep(address);
    if (updatedAddress) {
      updatedAddress.CompanyName = newAddress.CompanyName;
      updatedAddress.Street1 = newAddress.Street1;
      updatedAddress.Street2 = newAddress.Street2;
      updatedAddress.City = newAddress.City;
      updatedAddress.State = newAddress.State;
      updatedAddress.Zip = newAddress.Zip;
      if (newAddress.xp) {
        updatedAddress.xp = {
          addressType: updatedAddress.xp?.addressType,
          NickName: newAddress.xp?.NickName || updatedAddress?.xp?.NickName,
          ...newAddress.xp,
        };
        const addressToUpdate = {
          ...address,
        };
        const businessName = updatedAddress.CompanyName ? updatedAddress.CompanyName.toString().trim() : "";
        addressToUpdate.CompanyName = businessName;
        if (onAddressChange) {
          onAddressChange(addressToUpdate);
        }
      }
      if (newAddress.Phone && variant !== "InStorePickUp") {
        updatedAddress.Phone = newAddress.Phone;
      }
      if (onAddressChange) onAddressChange(updatedAddress);
      if ((!updatedAddress.Phone || updatedAddress.Phone === "") && phoneInputRef?.current && !isMobile) {
        phoneInputRef.current.selectionStart = 1;
        phoneInputRef.current.selectionEnd = 1;
        phoneInputRef.current.focus();
      }
    }
  };

  const displayError = (field: RequiredValue) => {
    const requiresCompanyName =
      destination?.googleOptions?.types && destination?.googleOptions.types[0] === "establishment";
    const editedValue = get(address, field) || "";
    return (
      edited &&
      edited[field] &&
      (requiredFields.includes(field) || (requiresCompanyName && field === "CompanyName")) &&
      (editedValue === "" ||
        (field === "Phone" && editedValue.length < 10) ||
        (field === "Zip" && editedValue.length < 5))
    );
  };

  const destination = useMemo(() => {
    return deliveryService.GetDestinationTypes().find((dest) => dest.type === address?.xp?.addressType);
  }, [address?.xp?.addressType]);

  return (
    <Grid container spacing={isMobile && variant !== "InStorePickUp" ? 1 : 2}>
      {(address?.xp?.addressType === "Church" ||
        address?.xp?.addressType === "FuneralHome" ||
        address?.xp?.addressType === "Cemetery" ||
        address?.xp?.addressType === "Hospital") &&
        address?.xp?.addressType !== "Email" && (
          <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
            <RadioGroup row value={churchOption} onChange={(e) => setChurchOption(e.target.value)}>
              <FormControlLabel value="Staff" label="Staff" control={<Radio size="small" color="primary" />} />
              <FormControlLabel
                value="InMemory"
                label={address?.xp?.addressType === "Hospital" ? "Patient" : "In Memory Of"}
                control={<Radio size="small" color="primary" />}
              />
            </RadioGroup>
          </Grid>
        )}
      {variant === "InStorePickUp" ? (
        <Grid container spacing={2} className={classes.InStorePickUpWrapper}>
          <Grid item xs={12} sm={4}>
            <Card className={classes.CardStoreInformation}>
              <CardContent>
                <Typography variant="h5">{address?.CompanyName || ""}</Typography>
                <Typography>
                  {address?.Street1 || ""}
                  {address?.Street2 || ""}
                </Typography>
                <Typography>
                  {address?.City || ""}, {address?.State || ""} {address?.Zip || ""}
                </Typography>
              </CardContent>
              <CardActions className={classes.CardStoreActions}>
                <Button
                  size="small"
                  href={`tel:,${bachmansStores?.find((store) => store.CompanyName === address?.CompanyName)?.Phone}`}
                >
                  {bachmansStores?.find((store) => store.CompanyName === address?.CompanyName)?.Phone}
                </Button>
                <Button
                  size="small"
                  target="_blank"
                  href={`${
                    directionsUrl +
                    bachmansStores?.find((store) => store.CompanyName === address?.CompanyName)?.xp?.PlaceID
                  }&destination=${
                    bachmansStores?.find((store) => store.CompanyName === address?.CompanyName)?.xp?.Location?.lat
                  },${bachmansStores?.find((store) => store.CompanyName === address?.CompanyName)?.xp?.Location?.lng}`}
                >
                  Get Directions
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              {/* Store Selector */}
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel id="storeSelect">Select a Store</InputLabel>
                  <Select
                    disabled={disabled}
                    required
                    margin="dense"
                    labelId="storeSelect"
                    label="Select a Store"
                    value={address?.CompanyName || ""}
                    onChange={handleStoreSelect}
                  >
                    {bachmansStores?.map((store, index) => (
                      <MenuItem value={store.CompanyName} key={index}>
                        {store.CompanyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* First Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={disabled}
                  required
                  margin="dense"
                  variant="outlined"
                  id="first"
                  label="First Name"
                  value={address?.FirstName || ""}
                  error={displayError("FirstName")}
                  helperText={displayError("FirstName") && "First name is required"}
                  onChange={(e: any) => handleAddressChange("FirstName", e.target.value)}
                ></TextField>
              </Grid>
              {/* Last Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={disabled}
                  required
                  margin="dense"
                  variant="outlined"
                  id="last"
                  label="Last Name"
                  value={address?.LastName || ""}
                  error={displayError("LastName")}
                  helperText={displayError("LastName") && "Last name is required"}
                  onChange={(e: any) => handleAddressChange("LastName", e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={disabled}
                  required
                  margin="dense"
                  variant="outlined"
                  id="phone"
                  label="Phone"
                  InputProps={{ inputComponent: PhoneMaskInput as any }}
                  inputRef={phoneInputRef}
                  value={address?.Phone || ""}
                  error={displayError("Phone")}
                  helperText={displayError("Phone") && "Please enter a 10 digit phone number"}
                  onChange={(e: any) => handleAddressChange("Phone", e.target.value)}
                  onFocus={() =>
                    setEdited({
                      ...(edited || initialEditedTracker),
                      FirstName: true,
                      LastName: true,
                      Phone: true,
                      Street1: true,
                      CompanyName: true,
                    })
                  }
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          {variant === "Shipping" && address?.xp?.addressType !== "Email" && (
            <Fragment>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required>
                  <TextField
                    id="addressType"
                    select
                    autoFocus
                    disabled={disabled}
                    required
                    margin="dense"
                    error={displayError("xp.addressType")}
                    helperText={displayError("xp.addressType") && "Address Type Required"}
                    variant="outlined"
                    label="Address Type"
                    onChange={(e: any) => handleAddressChange("xp.addressType", e.target.value)}
                    value={address?.xp?.addressType || ""}
                  >
                    {destinationOptions
                      ? destinationOptions.map((destination, index) => (
                          <MenuItem value={destination.type} key={index}>
                            {destination.label}{" "}
                          </MenuItem>
                        ))
                      : deliveryService.GetDestinationTypes()?.map((destination, index) => (
                          <MenuItem value={destination.type} key={index}>
                            {destination.label}
                          </MenuItem>
                        ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={disabled}
                  margin="dense"
                  variant="outlined"
                  id="nickName"
                  label="Nickname"
                  value={address?.xp?.NickName}
                  onChange={(e: any) => handleAddressChange("xp.NickName", e.target.value)}
                  error={(address?.xp?.NickName || "").length >= 15}
                  helperText={(address?.xp?.NickName || "").length >= 15 && "The maximum line limit is 15 characters"}
                  inputProps={{ maxLength: 15 }}
                ></TextField>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={disabled}
              required
              margin="dense"
              variant="outlined"
              id="first"
              label="First Name"
              value={address?.FirstName || ""}
              error={displayError("FirstName")}
              helperText={displayError("FirstName") && "First name is required"}
              onChange={(e: any) => handleAddressChange("FirstName", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={disabled}
              required
              margin="dense"
              variant="outlined"
              id="last"
              label="Last Name"
              value={address?.LastName || ""}
              error={displayError("LastName")}
              helperText={displayError("LastName") && "Last name is required"}
              onChange={(e: any) => handleAddressChange("LastName", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            {destination?.googleOptions?.types && destination?.googleOptions.types[0] === "establishment" && (
              <GoogleAutoComplete
                error={displayError("CompanyName")}
                destination={destination}
                onAddressSelect={handleAddressSelect}
                inputValue={address?.CompanyName || ""}
                addressType={address?.xp?.addressType}
                onInputChange={(val?: string) => handleAddressChange("CompanyName", val)}
              />
            )}
          </Grid>
          {address?.xp?.addressType !== "Email" && (
            <Grid item xs={12} sm={6}>
              {address?.xp?.addressType === "Residence" ? (
                <GoogleAutoComplete
                  error={displayError("Street1")}
                  destination={destination}
                  onAddressSelect={handleAddressSelect}
                  inputValue={address?.Street1 || ""}
                  addressType={address?.xp?.addressType}
                  onInputChange={(val?: string) => handleAddressChange("Street1", val)}
                />
              ) : (
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  required
                  id="address1"
                  label="Address 1"
                  disabled={disabled}
                  value={address?.Street1 || ""}
                  error={displayError("Street1")}
                  helperText={displayError("Street1") && "Address 1 is required"}
                  onChange={(e: any) => handleAddressChange("Street1", e.target.value)}
                ></TextField>
              )}
            </Grid>
          )}
          {address?.xp?.addressType == "Hospital" && (
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="address2"
                label="Room/Unit/Floor"
                disabled={disabled}
                value={address?.Street2 || ""}
                onChange={(e: any) => handleAddressChange("Street2", e.target.value)}
              ></TextField>
            </Grid>
          )}
          {address?.xp?.addressType !== "Email" && address?.xp?.addressType !== "Hospital" && (
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="address2"
                label="Address 2"
                disabled={disabled}
                value={address?.Street2 || ""}
                onChange={(e: any) => handleAddressChange("Street2", e.target.value)}
              ></TextField>
            </Grid>
          )}
          {address?.xp?.addressType !== "Email" && (
            <Grid item xs={12} sm={4}>
              <TextField
                required
                margin="dense"
                variant="outlined"
                id="city"
                label="City"
                disabled={disabled}
                value={address?.City || ""}
                error={displayError("City")}
                helperText={displayError("City") && "City is required"}
                onChange={(e: any) => handleAddressChange("City", e.target.value)}
              ></TextField>
            </Grid>
          )}
          {address?.xp?.addressType !== "Email" && (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                autoHighlight
                autoSelect
                value={address?.State || ""}
                disablePortal
                disabled={disabled}
                id="state"
                onChange={(event, newVal) => handleStateChange(newVal)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                disableClearable={true}
                options={geography.States.map((s) => s.value)}
                renderInput={(params) => (
                  <form autoComplete="off">
                    <TextField variant="outlined" margin="dense" required {...params} label="State" />
                  </form>
                )}
              />
            </Grid>
          )}
          {address?.xp?.addressType !== "Email" && (
            <Grid item xs={12} sm={4}>
              <TextField
                required
                margin="dense"
                variant="outlined"
                id="zip"
                label="Zip"
                disabled={disabled}
                value={address?.Zip || ""}
                error={displayError("Zip")}
                helperText={displayError("Zip") && "Please enter a 5 digit zip"}
                onChange={(e: any) => handleAddressChange("Zip", e.target.value)}
              ></TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={disabled}
              required
              margin="dense"
              variant="outlined"
              id="phone"
              label="Phone"
              InputProps={{ inputComponent: PhoneMaskInput as any }}
              inputRef={phoneInputRef}
              value={address?.Phone || ""}
              error={displayError("Phone")}
              helperText={displayError("Phone") && "Please enter a 10 digit phone number"}
              onChange={(e: any) => handleAddressChange("Phone", e.target.value)}
              onFocus={() =>
                setEdited({
                  ...(edited || initialEditedTracker),
                  FirstName: true,
                  LastName: true,
                  Phone: true,
                  CompanyName: true,
                  Street1: true,
                  "xp.addressType": true,
                  "xp.Email": true,
                })
              }
            ></TextField>
          </Grid>
          {address?.xp?.addressType === "Email" && (
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense"
                variant="outlined"
                id="email"
                label="Email"
                disabled={disabled}
                value={address?.xp.Email || ""}
                error={!!(address?.xp.Email && !stringService.ValidEmail(address?.xp.Email))}
                helperText={
                  !!(address?.xp.Email && !stringService.ValidEmail(address?.xp.Email)) && "Please enter a valid email"
                }
                onChange={(e: any) => handleAddressChange("xp.Email", e.target.value)}
              ></TextField>
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  );
};

export default AddressForm;
