import React, { ChangeEvent, useState, Fragment, useEffect, useMemo } from "react";
import { LineItem } from "ordercloud-javascript-sdk";
import { Quantity, BachmansProduct } from "../../models/product";
import { CircularProgress, makeStyles, TextField, Theme, createStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";

interface QuantityInputProps {
  product?: BachmansProduct | null;
  lineItem?: LineItem;
  quantity: Quantity;
  hideLabel?: boolean;
  onChange: (update: number, id?: string) => void;
  disabled?: boolean;
  pending?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      marginTop: "-30px",
      marginLeft: "50px",
    },
    helperText: {
      fontSize: 10,
      fontStyle: "italic",
      fontWeight: 900,
      lineHeight: 1,
      marginRight: 0,
    },
  })
);

const QuantityInput: React.FunctionComponent<QuantityInputProps> = (props) => {
  const classes = useStyles();
  const { lineItem, quantity, onChange, hideLabel, disabled, pending } = props;
  const [displayQuantity, setDisplayQuantity] = useState<number | undefined>(quantity.quantity);
  const [timer, setTimer] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    setDisplayQuantity(quantity.quantity);
  }, [quantity.quantity]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value) {
      setDisplayQuantity(undefined);
    } else {
      let updateQuantity = parseInt(event.currentTarget.value);

      if (updateQuantity >= (quantity?.minQuantity || 1)) {
        setDisplayQuantity(updateQuantity);
        clearTimeout(timer);
        setTimer(null);
        setTimer(setTimeout(() => onChange(updateQuantity, lineItem?.ID), 1000));
      }
    }
  };

  const handleBlur = () => {
    setDisplayQuantity(quantity.quantity);
  };

  const helperText = useMemo(() => {
    if ((displayQuantity || 0) > (quantity?.maxQuantity || 1000)) {
      return location.pathname.toLowerCase().trim().includes("/event/")
        ? `Quantity Unavailable: ${quantity!.maxQuantity} tickets remain.`
        : `Quantity Unavailable: ${quantity!.maxQuantity} items remain.`;
    } else {
      return displayQuantity === (quantity?.maxQuantity || 1000) ? `Only ${quantity?.maxQuantity || 1000} left.` : null;
    }
  }, [displayQuantity, quantity?.maxQuantity]);

  return (
    <Fragment>
      <TextField
        error={(displayQuantity || 0) > (quantity.maxQuantity || 10000)}
        className={classes.container}
        size="small"
        id="standard-number"
        label={!hideLabel ? "Quantity" : undefined}
        type="number"
        value={displayQuantity ? displayQuantity.toString() : ""}
        FormHelperTextProps={{
          "aria-live": "polite",
          className: classes.helperText,
        }}
        helperText={helperText}
        InputProps={{ inputProps: { min: quantity.minQuantity } }}
        variant="outlined"
        disabled={disabled || false}
        onChange={handleChange}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {pending && <CircularProgress className={classes.buttonProgress} size={20} />}
    </Fragment>
  );
};

export default QuantityInput;
