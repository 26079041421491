import React, { Fragment } from "react";
import { ComplexContent } from "../../../models/contentful/ComplexContent";
import { Card, CardActionArea, CardContent, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { neutral } from "../../../themes/colors";
import { useHistory } from "react-router";
import BachmansCardMedia from "../BachmansCardMedia";

interface BachmansFeaturesProps {
  content: ComplexContent;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: 0,
      boxShadow: "none",
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiCardContent-root": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    cardActionArea: {
      height: "100%",
      display: "flex",
      flexFlow: "column nowrap",
    },
    cardImage: {
      aspectRatio: "1.42 / 1",
    },
    cardBody: {
      display: "flex",
      flexFlow: "column nowrap",
      flexGrow: 1,
      width: "100%",
    },
    cardTitle: {
      fontWeight: theme.typography.fontWeightLight,
      color: neutral.text_white_bg,
      fontSize: 24,
    },
    cardSubtitle: {
      color: neutral.text_white_bg,
      textTransform: "capitalize",
      fontSize: 18,
    },
    h100: {
      height: "100%",
    },
  })
);

const BachmansContentBlock: React.FunctionComponent<BachmansFeaturesProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const goToPage = () => {
    if (props?.content?.fields?.linkUrl) history.push(props?.content?.fields?.linkUrl);
  };

  return (
    <Card square onClick={goToPage} className={classes.h100}>
      <CardActionArea className={classes.cardActionArea}>
        <BachmansCardMedia
          aspectRatio={"1.42"}
          alt={"Image For " + props.content.fields.title}
          image={props?.content?.fields?.image?.fields?.file?.url}
        />
        <CardContent className={classes.cardBody}>
          {props.children ? (
            props.children
          ) : (
            <Fragment>
              <Typography gutterBottom className={classes.cardSubtitle} component="p" variant="h3">
                {props.content.fields.title}
              </Typography>
              <Typography className={classes.cardTitle} variant="h4">
                {props.content.fields.subTitle}
              </Typography>
            </Fragment>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BachmansContentBlock;
