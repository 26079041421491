import React, { Fragment, useMemo, useState } from "react";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import bachDateTime from "../../../services/bachDateTime.service";
import { groupBy } from "lodash";
import moment from "moment-timezone";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { neutral } from "../../../themes/colors";
import { Remove } from "@material-ui/icons";
import { FreeEventDateTime } from "../../../models/Events";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    AccordionRoot: {
      backgroundColor: neutral.search_bg,
      marginBottom: -1,
      minHeight: 40,
      "&$expanded": {
        minHeight: 40,
      },
      "& .MuiAccordionSummary-content": {
        alignItems: "center",
        gap: theme.spacing(2),
      },
    },
    AccordionDetails: {
      backgroundColor: "white",
      display: "flex",
      flexFlow: "row wrap",
      gap: theme.spacing(1),
      padding: theme.spacing(3, 2),
      "& .MuiChip-root": {
        fontFamily: "inherit",
      },
    },
    content: {
      margin: "8px 0",
      "&$expanded": {
        margin: "8px 0",
      },
    },
    timeChip: {
      backgroundColor: theme.palette.background.paper,
      border: `.5px solid ${theme.palette.grey[400]}`,
      borderRadius: 4,
      "& .MuiChip-label": {
        fontFamily: theme.typography.body1.fontFamily,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.secondary,
      },
    },
    flexGrow1: {
      flexGrow: 1,
    },
    mr2: {
      marginRight: theme.spacing(2),
    },
    noPointer: {
      pointerEvents: "none",
    },
  })
);

export interface EventSlotViewerProps {
  defaultEvent?: BuyerProduct;
  eventDateTimes?: FreeEventDateTime[];
}

const EventSlotViewer: React.FunctionComponent<EventSlotViewerProps> = (props) => {
  const classes = useStyle();
  const { eventDateTimes } = props;
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(0);

  const handleChange = (index: number) => (event: any, isExpanded: boolean) => {
    setExpandedIndex(isExpanded ? index : undefined);
  };

  const groupedDates = useMemo(() => {
    const now = bachDateTime.GetLocalTimeStamp();
    const futureDates = eventDateTimes?.filter((item) =>
      moment(item.Date, "YYYY-MM-DD").tz("America/Chicago").endOf("d").isSameOrAfter(now)
    );
    const grouped = groupBy(futureDates, (e) => e.Date);
    return grouped;
  }, [eventDateTimes]);

  return (
    <Fragment>
      {Object.values(groupedDates)?.map((items, index) => (
        <Accordion
          className={classes.AccordionRoot}
          square
          expanded={expandedIndex === index && items.length > 1}
          key={index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={
              items.length <= 1 ? (
                ""
              ) : expandedIndex !== index ? (
                <Fragment>
                  <AddIcon color="action" />
                </Fragment>
              ) : (
                <Remove color="action" />
              )
            }
            id="eventDateSummary"
            classes={{
              root: `${items.length <= 1 && classes.noPointer}`,
            }}
          >
            <Typography className={classes.flexGrow1} variant="body1" color="textSecondary">
              <strong>
                {items ? moment(items[0].Date).format("dddd") + ", " + moment(items[0].Date).format("MMMM DD") : ""}
              </strong>
            </Typography>
            {items.length > 1 && <Typography variant="body2">Multiple Times</Typography>}
            <Fragment>
              {items && items.length <= 1 && (
                <Chip className={classes.timeChip} size="small" label={`${items[0].Start} - ${items[0].End}`} />
              )}
            </Fragment>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            {items.map((d: FreeEventDateTime, i: number) => (
              <Chip className={classes.timeChip} key={i} size="small" label={d.Start + " - " + d.End} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Fragment>
  );
};

export default EventSlotViewer;
