import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import Link from "../Shared/Link";
import { searchType } from "../../models/search";
interface SearchResultsHeaderProps {
  variant: searchType;
  queryTerm: string;
  toggleMenu: () => void;
  result: any;
  queryID?: any;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      zIndex: theme.zIndex.mobileStepper,
      padding: theme.spacing(2, 1),
      margin: theme.spacing(0, -1),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2.5, 0),
        margin: "unset",
      },
    },
    link: {
      display: "flex",
      alignItems: "center",
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightBold,
      "& .MuiSvgIcon-root": {
        fontSize: "inherit",
      },
    },
    title: {
      marginRight: theme.spacing(2),
    },
  })
);

const SearchResultsHeader: FunctionComponent<SearchResultsHeaderProps> = (props) => {
  const classes = useStyle();
  const { variant, queryTerm, toggleMenu, result, queryID } = props;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const handleRoute = () => {
    switch (variant) {
      case "Products":
        return `/search?query=${queryTerm}&type=Products&queryid=${queryID}`;
      case "Events":
        return `/search?query=${queryTerm}&type=Events`;
      case "CareInfo":
        return `/search?query=${queryTerm}&type=CareInfo`;
      case "Query Suggestions":
        return `/search?query=${queryTerm}&type=Query Suggestions`;
      case "Categories":
        return `/search?query=${queryTerm}&type=Categories`;
    }
  };

  return (
    <div className={classes.root + " " + "MobileSearchHeading"}>
      {isDesktop && (
        <Typography className={classes.title} variant="h5">
          {variant === "CareInfo" ? "Resource Hub" : variant === "Query Suggestions" ? "Popular Searches" : variant}
        </Typography>
      )}
      {!isDesktop && (
        <Typography className={classes.title} variant="h5">
          {variant === "Categories"
            ? null
            : variant === "CareInfo"
            ? "Resource Hub "
            : variant === "Query Suggestions"
            ? "Popular Searches"
            : variant}
        </Typography>
      )}

      {variant !== "Query Suggestions" &&
        variant !== "Categories" &&
        (variant === "Products" ? (
          <div className={classes.link}>
            <Link to={handleRoute()} onClick={toggleMenu} className="see-all-link">
              See All
            </Link>
            <ChevronRight color="primary" />
          </div>
        ) : (
          <div className={classes.link}>
            <Link to={handleRoute()} onClick={toggleMenu} className="see-all-link">
              See All
            </Link>
            <ChevronRight color="primary" />
          </div>
        ))}
    </div>
  );
};

export default SearchResultsHeader;
