import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import React, { Fragment, useContext, useMemo, useState } from "react";
import { SessionContext } from "../../../providers/session";
import { neutral } from "../../../themes/colors";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { BachmansProduct } from "../../../models/product";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AuthDialog from "../../Authentication/AuthDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addToListButton: {
      "& .MuiButton-label": {
        display: "block",
        textAlign: "center",
        color: neutral.text_light_bg,
        "&:hover": { color: "white" },
      },
      padding: "0px",
    },
    addtoListText: {
      marginTop: "-10px",
      margin: 0,
      fontSize: "10px",
    },
  })
);

interface WishListItem {
  ProductCode: string;
}

interface WishListButtonProps {
  product?: BachmansProduct;
}

const WishListButton: React.FunctionComponent<WishListButtonProps> = (props) => {
  const { user, anonymous, patchUser } = useContext(SessionContext);
  const [authDialogOpen, setAuthDialogOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleWishListChange = () => {
    if (!anonymous) {
      const currentCode = props?.product?.xp?.ProductCode;
      var updatedWishList: WishListItem[] = [];
      if (productInWishList) {
        updatedWishList = user?.xp?.WishList?.filter((w: WishListItem) => w.ProductCode !== currentCode);
      } else {
        //Fixing adding new product to wishlist for the first time
        if (user?.xp.WishList) {
          updatedWishList = user?.xp.WishList?.concat({ ProductCode: currentCode });
        } else {
          updatedWishList = updatedWishList.concat({ ProductCode: currentCode });
        }
      }
      if (patchUser) {
        patchUser({ xp: { WishList: updatedWishList } });
      }
    } else {
      setAuthDialogOpen(true);
    }
  };

  const handleAuthDialogClose = () => {
    setAuthDialogOpen(false);
  };

  const productInWishList = useMemo(() => {
    const currentCode = props?.product?.xp?.ProductCode;
    var res = user?.xp?.WishList?.filter((item: WishListItem) => item.ProductCode === currentCode);
    return res?.length >= 1;
  }, [props, user]);

  return (
    <Fragment>
      {!productInWishList ? (
        <Button variant="outlined" className={classes.addToListButton} onClick={handleWishListChange}>
          <PlaylistAddIcon />
          <p className={classes.addtoListText}>Add to List</p>
        </Button>
      ) : (
        <Button variant="outlined" startIcon={<CheckCircleOutlineIcon />} color="secondary" disabled>
          Saved
        </Button>
      )}
      <AuthDialog open={authDialogOpen} onClose={handleAuthDialogClose}></AuthDialog>
    </Fragment>
  );
};

export default WishListButton;
