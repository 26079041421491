import React, { Fragment, FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Address, BuyerAddress, BuyerProduct, Me } from "ordercloud-javascript-sdk";
import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Theme,
  makeStyles,
  createStyles,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  ButtonBase,
  alpha,
  CircularProgress,
  Hidden,
} from "@material-ui/core";
import AddressForm from "../Shared/Address/AddressForm";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import deliveryService, { LocalAddressData } from "../../services/delivery.service";
import { SessionContext } from "../../providers/session";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import deliveryResourceService from "../../services/delivery-resource.service";
import bachmansIntegrationsService from "../../services/bachmansIntegrations.service";
import { each, isEqual, isUndefined } from "lodash";
import { neutral } from "../../themes/colors";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { DeliveryMethods } from "../Product/ProductDetail/DeliveryOptionSelect";
import { LineItem, LineItems } from "ordercloud-javascript-sdk";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      padding: theme.spacing(5),
      width: "100%",
      position: "relative",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "50vw",
      },
    },
    dialogBody: {
      padding: 0,
      overflowY: "unset",
      minHeight: 150,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      },
      "& .MuiFormControl-root, .MuiSelect-nativeInput": {
        display: "flex",
        margin: 0,
      },
    },
    diaglogTitle: {
      marginBottom: theme.spacing(2),
      padding: 0,
    },
    dialogActions: {
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        bottom: theme.spacing(-5),
        backgroundColor: "white",
        height: "20vh",
        alignItems: "flex-start",
        width: "100%",
        zIndex: 1,
      },
    },
    loadingIndicator: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      zIndex: 1,
      backgroundColor: alpha(neutral.offwhite_bg, 0.9),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      margin: "0 auto",
      "& .MuiCircularProgress-root": {
        zIndex: 2,
        marginRight: theme.spacing(3),
      },
    },
    localDeliveryError: {
      marginTop: theme.spacing(1),
    },
    localDeliveryWarning: {
      marginTop: theme.spacing(1),
      "& a": {
        color: "rgb(102, 60, 0)", // warning text color
      },
    },
    btnLogin: {
      fontWeight: "bold",
      padding: 0,
      minWidth: "unset",
      marginLeft: theme.spacing(1),
      textDecoration: "underline",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    addressButton: {
      width: "100%",
      minHeight: 100,
      height: "100%",
      boxShadow: theme.shadows[3],
      marginTop: theme.spacing(1),
      border: `1px solid ${neutral.search_bg}`,
      transition: "all .25s cubic-bezier(0.4, 0, 0.2, 1)",
      "&:hover": {
        backgroundColor: neutral.search_bg,
        transform: "translateY(-2px)",
      },
    },
    addressButtonSuggested: {
      backgroundColor: alpha(theme.palette.primary.light, 0.15),
      borderColor: alpha(theme.palette.primary.light, 0.5),
      boxShadow:
        "0px 3px 3px -2px rgb(146 98 184 / 20%), 0px 3px 4px 0px rgb(146 98 184 / 14%), 0px 1px 8px 0px rgb(146 98 184 / 12%)",
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.light, 0.4),
      },
    },
    addressCard: {
      display: "flex",
      alignItems: "flex-start",
      flexFlow: "column nowrap",
      padding: theme.spacing(2),
      flexGrow: 1,
      height: "100%",
      "& p:first-child": {
        marginTop: "auto",
      },
    },
    Divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);

export interface AddressDialogProps {
  open: boolean;
  variant: "Shipping" | "Billing" | "InStorePickUp";
  products?: BuyerProduct[];
  deliveryMethods?: DeliveryMethods;
  address?: BuyerAddress;
  dialogTitle?: string;
  lineItems?: LineItem[];
  onClose: () => void;
  onSubmit?: (address: BuyerAddress, routeCode?: string, saveAddress?: boolean) => Promise<void>;
}

const AddressDialog: FunctionComponent<AddressDialogProps> = (props) => {
  const classes = useStyles();
  const { open, address, onClose, onSubmit, dialogTitle, variant, products, deliveryMethods } = props;
  const { anonymous } = useContext(SessionContext);
  const [addressStatic, setAddressStatic] = useState<BuyerAddress>();
  const [addressEditable, setAddressEditable] = useState<BuyerAddress>();
  const [localAddressData, setLocalAddressData] = useState<LocalAddressData>();
  const [loading, setLoading] = useState(false);
  const [localCities, setLocalCities] = useState<Address[]>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [avalaraSuggested, setAvalaraSuggested] = useState<Address>();
  const [avalaraNotFound, setAvalaraNotFound] = useState<boolean>(false);
  const [saveAddress, setSaveAddress] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const componentMounted = useRef(true);

  useEffect(() => {
    (async () => {
      const local = await deliveryResourceService.GetLocalCities();
      if (componentMounted.current) {
        setLocalCities(local);
      }
    })();
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setAddressStatic(address);
    setAddressEditable({ ...address, Billing: true, Shipping: true, Country: "US" });
  }, [address]);

  const resetData = () => {
    setAvalaraSuggested(undefined);
    setLoading(false);
    setAvalaraNotFound(false);
    setAddressEditable({ ...address, Billing: true, Shipping: true, Country: "US" });
    setLocalAddressData(undefined);
  };

  const handleClose = () => {
    resetData();
    onClose();
  };

  const avalaraMap: any = {
    Region: "State",
    Line1: "Street1",
    Line2: "Street2",
    City: "City",
    Country: "Country",
    PostalCode: "Zip",
  };

  const handleAvalaraSuggestion = async (suggestion: any) => {
    var mappedAddress: any = {};
    each(suggestion, function (v, k) {
      if (avalaraMap[k] && v !== "") {
        if (k === "PostalCode") v = v.split("-")[0];
        mappedAddress[avalaraMap[k]] = v;
      }
    });
    const verifiedAddress: Address = Object.assign({}, addressEditable, mappedAddress);
    if (verifiedAddress.Street2 && !mappedAddress.Street2) {
      delete verifiedAddress.Street2;
    }
    if (isEqual(verifiedAddress, addressEditable)) {
      await submitAddress(addressEditable);
    } else {
      setAvalaraSuggested(verifiedAddress);
      setLoading(false);
    }
  };

  const submitAddress = async (address?: BuyerAddress) => {
    if (address && onSubmit) {
      try {
        setLoading(true);
        if (!anonymous && saveAddress) {
          await Me.CreateAddress(address);
        }
        await onSubmit(address, localAddressData?.routeCode);
      } finally {
        handleClose();
      }
    }
  };

  const authAndSubmitAddress = async () => {
    const destinationType = deliveryService
      .GetDestinationTypes()
      .find((d) => d.type === addressEditable?.xp?.addressType);
    if (destinationType?.search !== "S") {
      setLoading(true);
      const avalaraResp = await bachmansIntegrationsService.validateAddress(addressEditable as Address);
      if (avalaraResp.ResultCode === "Success") {
        console.log("avalaraResp.Address", avalaraResp.Address);
        handleAvalaraSuggestion(avalaraResp.Address);
      } else if (avalaraResp.ResultCode === "Error") {
        setAvalaraNotFound(true);
        setLoading(false);
      }
    } else {
      submitAddress(addressEditable as Address);
    }
  };

  const handleAddressChange = (val: BuyerAddress) => {
    setAvalaraNotFound(false);
    // BI-474: Clear destination fields upon clicking 'X' icons
    if ((val.xp && val.xp.addressType == "Residence") || val?.CompanyName == null) {
      if (val?.Street1 == "") {
        val.City = val.State = val.Zip = val.Street1 = val.Street2 = undefined;
      }
    } else {
      if (val?.CompanyName == "") {
        val.City = val.State = val.Zip = val.Street1 = val.Street2 = undefined;
      }
    }
    setAddressEditable(val);
  };

  const handleLoginClick = () => {
    handleClose();
    dispatch(setBreadcrumbs({ authDialogOpen: true }));
  };

  useEffect(() => {
    if (localCities && addressEditable?.Zip && addressEditable?.Zip?.length >= 5) {
      const localAddress = deliveryService.IsAddressLocal(addressEditable as Address, localCities);
      setLocalAddressData(localAddress);
    }
  }, [addressEditable, localCities]);

  const isAddressValid = useMemo(() => {
    const hasValue = (value?: string | null) => {
      return !(value === undefined || value === null || value === "");
    };

    const requiresCompanyName = [
      "FuneralHome",
      "Hospital",
      "School",
      "Business",
      "Cemetery",
      "Church",
      "Email",
    ].includes(addressEditable?.xp?.addressType);

    const isValid =
      !isUndefined(addressEditable) &&
      !isEqual(addressEditable, addressStatic) &&
      hasValue(addressEditable?.FirstName) &&
      hasValue(addressEditable?.LastName) &&
      hasValue(addressEditable?.Street1) &&
      hasValue(addressEditable?.State) &&
      hasValue(addressEditable?.City) &&
      hasValue(addressEditable?.Zip) &&
      (hasValue(addressEditable?.xp?.addressType) || variant === "Billing") &&
      (hasValue(addressEditable?.CompanyName) || !requiresCompanyName) &&
      addressEditable.Zip!.length >= 5 &&
      hasValue(addressEditable?.Phone) &&
      addressEditable.Phone!.length > 9;

    return isValid;
  }, [addressEditable, addressStatic, variant]);

  const localProducts = useMemo(() => {
    if (deliveryMethods) {
      return products?.filter((p) => !deliveryService.CanDeliveryNationWide(deliveryMethods, p));
    } else return [];
  }, [deliveryMethods, products]);

  const onlyLocal = (): boolean => {
    return !!(products && localProducts && products.length === localProducts.length);
  };

  const redirectToFAQ = () => {
    window.open("https://www.bachmans.com/customer-service/frequently-asked-questions", "_blank");
  };

  const hospitalDisclaimerLabel =
    addressEditable?.xp?.addressType === "Hospital" ? (
      <Typography variant="body1">
        All hospital deliveries are final.
        <Button variant="text" className={classes.btnLogin} color="primary" onClick={redirectToFAQ}>
          Please read our FAQ
        </Button>
      </Typography>
    ) : (
      <></>
    );

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      PaperProps={{
        square: true,
      }}
      fullScreen={isMobile ? true : false}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      {avalaraSuggested && loading && (
        <div className={classes.loadingIndicator}>
          <CircularProgress />
          <Typography display="inline" variant="caption">
            Updating Address...
          </Typography>
        </div>
      )}
      <div>
        <Fragment>
          <div className={classes.diaglogTitle}>
            {avalaraSuggested && (
              <Fragment>
                <Typography variant="h2" gutterBottom>
                  Please Verify Your Address
                </Typography>
                <Typography variant="body2">
                  We may have found a more precise version of the address you entered.{" "}
                  <Hidden smDown>
                    <br />
                  </Hidden>
                  Please select the correct address below to ensure timely and accurate delivery.
                </Typography>
              </Fragment>
            )}
            {!avalaraSuggested && (
              <Typography variant="h2" gutterBottom>
                {variant !== "InStorePickUp" ? "Recipient Information" : "Order Pickup Information"}
              </Typography>
            )}
            {variant === "InStorePickUp" && (
              <Typography variant="body2">Confirm pickup location and enter your pickup person</Typography>
            )}
            <Typography variant="h2">{dialogTitle}</Typography>
            {anonymous && !avalaraSuggested && variant !== "InStorePickUp" && (
              <>
                <Typography variant="body1">
                  Already have an account?
                  <Button variant="text" className={classes.btnLogin} color="primary" onClick={handleLoginClick}>
                    Login
                  </Button>
                </Typography>
                {hospitalDisclaimerLabel}
              </>
            )}
            {!anonymous && variant !== "InStorePickUp" && hospitalDisclaimerLabel}
          </div>

          {avalaraSuggested ? (
            <Fragment>
              <DialogContent className={classes.dialogBody}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ButtonBase
                      centerRipple
                      focusRipple
                      disabled={loading}
                      onClick={() => submitAddress(addressEditable)}
                      className={classes.addressButton}
                    >
                      <div className={classes.addressCard}>
                        <Typography variant="h5">Entered Address</Typography>
                        <Typography variant="body1">{addressEditable?.Street1}</Typography>
                        {addressEditable?.Street2 && <Typography variant="body1">{addressEditable.Street2}</Typography>}
                        <Typography variant="body1">{`${addressEditable?.City}, ${addressEditable?.State} ${addressEditable?.Zip}`}</Typography>
                      </div>
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ButtonBase
                      centerRipple
                      focusRipple
                      disabled={loading}
                      onClick={() => submitAddress(avalaraSuggested)}
                      className={`${classes.addressButton} ${classes.addressButtonSuggested}`}
                    >
                      <div className={classes.addressCard}>
                        <Typography variant="h5">Suggested Address</Typography>
                        <Typography variant="body1">{avalaraSuggested?.Street1}</Typography>
                        <Typography variant="body1">{`${avalaraSuggested?.City}, ${avalaraSuggested?.State} ${avalaraSuggested?.Zip}`}</Typography>
                      </div>
                    </ButtonBase>
                  </Grid>
                </Grid>
              </DialogContent>
            </Fragment>
          ) : (
            <Fragment>
              <DialogContent className={classes.dialogBody}>
                <Fragment>
                  <AddressForm
                    variant={variant}
                    address={addressEditable}
                    addressStatic={addressStatic}
                    onAddressChange={handleAddressChange}
                    destinationOptions={deliveryService.GetDestinationTypes("*")}
                  />
                  {!anonymous &&
                    variant !== "InStorePickUp" &&
                    !window.location.href.includes("address-book") &&
                    isUndefined(props.address?.ID) && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="saveAddress"
                            name="saveAddress"
                            value={saveAddress}
                            checked={saveAddress}
                            onChange={() => setSaveAddress(!saveAddress)}
                            color="primary"
                          />
                        }
                        label="Save Address"
                      ></FormControlLabel>
                    )}
                  {onlyLocal() && localAddressData?.isLocal === false && (
                    <Alert severity="warning" className={classes.localDeliveryWarning}>
                      <Typography variant="body1">
                        These products cannot be delivered to this location. Please change to Store Pickup, or shop from
                        our <Link to="/c/occasions/out-of-town-delivery">Out Of Town</Link> category.
                      </Typography>
                    </Alert>
                  )}
                  {localProducts && localProducts.length > 0 && localAddressData?.isLocal === false && (
                    <Fragment>
                      {localProducts.map((p) => (
                        <Alert severity="warning" className={classes.localDeliveryWarning}>
                          <Typography variant="body1">
                            {`${p.xp?.WebFacingProductTitle || p.Name} cannot be delivered to this location`}
                          </Typography>
                        </Alert>
                      ))}
                    </Fragment>
                  )}
                  {avalaraNotFound && (
                    <Alert severity="error" className={classes.localDeliveryError}>
                      <Typography variant="body1">
                        We are unable to validate this address.
                        <br />
                        Please confirm and re-enter your recipient address, or call our Order Center at (612) 861-7311
                        to place your order.
                      </Typography>
                    </Alert>
                  )}
                </Fragment>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button variant="text" disabled={loading} onClick={handleClose}>
                  Cancel
                </Button>
                <DoubleOutlinedBtn
                  buttonText="Save"
                  buttonProps={{
                    disabled:
                      loading ||
                      !isAddressValid ||
                      (onlyLocal() && localAddressData?.isLocal === false) ||
                      avalaraNotFound,
                    type: "submit",
                    onClick: authAndSubmitAddress,
                  }}
                  styleProps={{
                    padding: "4px 40px",
                  }}
                />
              </DialogActions>
            </Fragment>
          )}
        </Fragment>
      </div>
    </Dialog>
  );
};

export default AddressDialog;
