import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { groupBy, values } from "lodash";
import React, { useEffect, useState } from "react";
import { BachmansProduct } from "../../../models/product";
import productService from "../../../services/product.service";
import BachmansCarousel from "../../Shared/BachmansCarousel";

interface ProductCrossSellProps {
  related?: string[];
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    header: {
      marginBottom: theme.spacing(2),
    },
  })
);

const ProductCrossSell: React.FunctionComponent<ProductCrossSellProps> = (props) => {
  const { related } = props;
  const classes = useStyles();
  const [upsellProds, setUpsellProds] = useState<BachmansProduct[][]>();

  useEffect(() => {
    if (related) {
      productService.ListProductsByCodes(related).then((response) => {
        var xsellProds = values(
          groupBy(response, function (p) {
            return p.xp.ProductCode;
          })
        );
        setUpsellProds(xsellProds);
      });
    }
  }, [related]);

  if (upsellProds && upsellProds.length) {
    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.header}>
          You Might Also Like...
        </Typography>
        <BachmansCarousel carouselData={upsellProds} variant="product" alignLeft={true}></BachmansCarousel>
      </div>
    );
  } else return null;
};

export default ProductCrossSell;
