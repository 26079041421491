import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { purple, green, red } from "./colors";

const fontFallbackStack =
  ", -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji";

const colorsFonts: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: "Roboto Slab" + fontFallbackStack,
      fontWeight: 500,
      fontSize: "28px",
    },
    h2: {
      fontFamily: "Roboto Slab" + fontFallbackStack,
      fontWeight: 700,
      fontSize: "24px",
    },
    h3: {
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "18px",
      fontSmooth: "always",
    },
    h4: {
      fontFamily: "Roboto Slab" + fontFallbackStack,
      fontWeight: 600,
      fontSize: "16px",
    },
    h5: {
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "bold",
      fontSize: "14px",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    h6: {
      fontFamily: "Roboto Slab" + fontFallbackStack,
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "12px",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    body1: {
      //BODY CONTENT
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "normal",
      fontSize: "14px",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    body2: {
      // FACET LIST
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "normal",
      fontSize: "12px",
    },
    subtitle1: {
      //CATEGORY TITLE
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "normal",
      fontSize: "18px",
    },
    subtitle2: {
      //CATEGORY SPECIAL TITLE
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "bold",
      fontSize: "12px",
    },
    overline: {
      //DIRECTIONS
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "12px",
      letterSpacing: "normal",
    },
    caption: {
      //SEO COPY
      fontFamily: "Roboto Slab" + fontFallbackStack,
      fontWeight: "lighter",
      fontSize: "14px",
    },
    button: {
      fontFamily: "Proxima Nova" + fontFallbackStack,
      fontWeight: "bold",
      fontSize: "14px",
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: purple.regular,
      dark: purple.hover,
      light: purple.light,
    },
    secondary: {
      main: green.regular,
      contrastText: "#fff",
    },
    error: {
      main: red.regular,
    },
  },
};

const defaultTheme = createTheme(colorsFonts);

const theme = createTheme({
  ...colorsFonts,
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: defaultTheme.palette.background.paper,
          [defaultTheme.breakpoints.down("sm")]: {
            paddingBottom: 56,
            minHeight: "100vh",
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        ...defaultTheme.typography.body2,
      },
      contained: {
        marginLeft: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: defaultTheme.palette.background.paper,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
      outlined: {
        "&:hover": {
          borderColor: defaultTheme.palette.primary.dark,
          backgroundColor: defaultTheme.palette.primary.dark,
          color: defaultTheme.palette.getContrastText(defaultTheme.palette.primary.dark),
        },
      },
      outlinedPrimary: {
        "&:hover": {
          borderColor: defaultTheme.palette.primary.dark,
          backgroundColor: defaultTheme.palette.primary.dark,
          color: defaultTheme.palette.getContrastText(defaultTheme.palette.primary.dark),
        },
      },
      outlinedSecondary: {
        "&:hover": {
          borderColor: defaultTheme.palette.secondary.dark,
          backgroundColor: defaultTheme.palette.secondary.dark,
          color: defaultTheme.palette.getContrastText(defaultTheme.palette.secondary.dark),
        },
      },
    },
  },
});

export default theme;
