import React, { Fragment, FunctionComponent } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { neutral, purple } from "../../themes/colors";

interface FaqProps {
  headline: any;
  data: any;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      boxShadow: "none",
    },
    summary: {
      backgroundColor: neutral.offwhite_bg,
      transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",
      "&.Mui-expanded": {
        backgroundColor: purple.inline + "66",
        minHeight: 48,
        "&>div": {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    heading: {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: "capitalize",
    },
    details: {
      display: "block",
    },
  })
);
const Faq: FunctionComponent<FaqProps> = (props) => {
  const classes = useStyle();
  const { data } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      {data &&
        data.map((item: any, index: number) => (
          <Fragment key={index}>
            <Accordion square onChange={handleChange(item.fields?.question)} elevation={0}>
              <AccordionSummary
                className={classes.summary}
                expandIcon={expanded !== item.fields?.question ? <Add color="primary" /> : <Remove color="primary" />}
                aria-controls="panel1a-content"
                id={item.fields?.question}
              >
                <Typography color="primary" variant="h3" className={classes.heading}>
                  {item.fields?.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ReactMarkdown escapeHtml={false} plugins={[[gfm]]} children={item.fields.answer}></ReactMarkdown>
              </AccordionDetails>
            </Accordion>
          </Fragment>
        ))}
    </div>
  );
};
export default Faq;
