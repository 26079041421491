import React, { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { Catalog, Category, RequiredDeep } from "ordercloud-javascript-sdk";
import bachmansIntegrations from "../services/bachmansIntegrations.service";
import { SessionContext } from "./session";
import CategoryService from "../services/category.service";
import { cloneDeep, sortBy } from "lodash";

interface ICatalogContext {
  catalog?: Catalog;
  DCFcategories?: RequiredDeep<Category>[];
  categories?: RequiredDeep<Category>[];
}

export const CatalogContext = createContext<ICatalogContext>({});

const CatalogProvider: FunctionComponent = ({ children }) => {
  const { token } = useContext(SessionContext);
  const [categories, setCategories] = useState<RequiredDeep<Category>[]>();
  const [DCFcategories, setDCFCategories] = useState<RequiredDeep<Category>[]>();
  // const [catalog, setCatalog] = useState<Catalog>();

  useEffect(() => {
    if (token && (!categories || !DCFcategories)) {
      initCategories();
    }
  }, [token, categories, DCFcategories]);

  const initCategories = async () => {
    try {
      const response = await Promise.all([
        bachmansIntegrations.getCatalogCache("Bachmans"),
        bachmansIntegrations.getCatalogCache("DCF"),
      ]);
      const inSeasonCategories = CategoryService.CategoriesFilter(response[0].data);
      //  need to filter out categories that have parents or grandparents that have already been excluded
      const filtered = cloneDeep(inSeasonCategories).filter((c) => {
        var exclude = false;
        const parent = inSeasonCategories.find((cat) => cat.ID === c.ParentID);
        if (c.ParentID && c.ParentID !== null && c.ParentID !== "" && (!parent || parent === null)) {
          exclude = true;
        }
        if (parent) {
          const grandparent = inSeasonCategories.find((cat) => cat.ID === parent.ParentID);
          if (
            parent.ParentID &&
            parent.ParentID !== null &&
            parent.ParentID !== "" &&
            (!grandparent || grandparent === null)
          ) {
            exclude = true;
          }
        }
        return !exclude;
      });
      const categoriesSortbyListOrder = sortBy(filtered, ["ListOrder"]);
      setCategories(categoriesSortbyListOrder);
      setDCFCategories(response[1].data);
    } catch {}
  };

  const value = useMemo(() => {
    return {
      // catalog,
      categories,
      DCFcategories,
    };
  }, [categories, DCFcategories]);

  return <CatalogContext.Provider value={value}>{children}</CatalogContext.Provider>;
};

export default CatalogProvider;
