import { makeStyles, Theme, createStyles } from "@material-ui/core";
import React from "react";
import { BachmansProduct } from "../../../models/product";
import stringService from "../../../services/string.service";

interface ProductSpecTableProps {
  product?: BachmansProduct;
  defaultProduct?: BachmansProduct;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableBorder: {
      width: "100%",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderSpacing: 0,
      borderCollapse: "collapse",
      marginBottom: theme.spacing(2),
    },
    tableCell: {
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: theme.spacing(1),
    },
  })
);

const ProductSpecTable: React.FunctionComponent<ProductSpecTableProps> = (props) => {
  const { product, defaultProduct } = props;
  const specs = product?.xp?.Attributes || defaultProduct?.xp?.Attributes;
  const classes = useStyles();

  return (
    <table className={classes.tableBorder}>
      <tbody>
        {specs &&
          !specs.ColorVarient &&
          Object.entries(specs).map(([key, value]) => {
            if (value && key !== "HandlingFee" && key !== "Occasions") {
              return (
                <tr key={key}>
                  <td className={classes.tableCell}>
                    <b>{stringService.Camel2Dash(key, "space")}</b>
                  </td>
                  <td className={classes.tableCell}>{value as string}</td>
                </tr>
              );
            } else return null;
          })}
      </tbody>
    </table>
  );
};

export default ProductSpecTable;
