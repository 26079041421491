import { ButtonBase, createStyles, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { Entry } from "contentful";
import { cloneDeep, each, filter, find, reverse, sortBy } from "lodash";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { ArticlesContext } from "../../../providers/contentful";
import contentfulService from "../../../services/contentful.service";
import FullWidthContentBlock from "../../Shared/Content/FullWidthContentBlock";
import { ComplexContentModel } from "../../../models/contentful/ComplexContent";
import moment from "moment";
import { StaticPageQueryParams } from "..";
import BachmansLoading from "../../Shared/BachmansLoading";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import { Category } from "ordercloud-javascript-sdk";
import { CatalogContext } from "../../../providers/catalog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conentfulContainer: {
      marginTop: theme.spacing(4),
    },
    articleCard: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    articleTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      transition: theme.transitions.create(["all"], {
        duration: theme.transitions.duration.shorter,
      }),
      "&:hover, &:focus": {
        textDecoration: "underline",
        color: theme.palette.primary.dark,
      },
    },
    flexGrow0: {
      flexGrow: 0,
      whiteSpace: "nowrap",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(4),
      },
    },
  })
);

export interface CareAdviceListProps {
  queryParams?: StaticPageQueryParams;
  pageSize?: number;
  contentList?: any[];
  sortState: string;
  updateTotal?: (total?: number) => void;
  articleSelect: (slug: string, info?: any) => void;
  searchResults?: any[];
}

const CareAdviceList: React.FunctionComponent<CareAdviceListProps> = (props) => {
  const { queryParams, contentList, updateTotal, articleSelect, pageSize, sortState, searchResults } = props;
  const classes = useStyles();
  const articleContent = useContext(ArticlesContext);
  const { categories } = useContext(CatalogContext);
  const [articlesInView, setArticlesInView] = useState<Entry<any>[]>();
  const [contentfulContent, setContentfulContent] = useState<ComplexContentModel>();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  //TODO: route to page if only single result
  //TODO: fix sort by, doesn't seem to work while a tag is selected

  useEffect(() => {
    const currentPage = queryParams?.page || 1;
    let start = (currentPage - 1) * 20;

    var algoliaOrder;
    if (sortState === "Newest to Oldest") algoliaOrder = "sys.updatedAt,fields.title";
    else if (sortState === "Oldest to Newest") algoliaOrder = "-sys.updatedAt,fields.title";
    else if (sortState === "Title A-Z") algoliaOrder = "fields.title";
    else if (sortState === "Title Z-A") algoliaOrder = "-fields.title";

    const finishArticles = (items?: any[], total?: number) => {
      if (currentPage > 1 && queryParams?.search) start = 0; //pagination for search : contentful service already segments array of response so we need to skip the slice when page is greater than 1
      const articlesToSet =
        items?.length === (pageSize || 20) ? items : items?.slice(start, start + (pageSize || 20)) || [];
      setArticlesInView(articlesToSet);
      if (updateTotal) {
        updateTotal(total || items?.length || 0);
      }
    };

    if (queryParams?.search) {
      setLoading(true);
      if (updateTotal) updateTotal(undefined);
      contentfulService
        .getEntries({
          content_type: "article",
          query: queryParams?.search,
          "fields.tags": queryParams?.tag,
          order: algoliaOrder,
          limit: pageSize,
          skip: start,
        })
        .then((contentfulData) => {
          finishArticles(contentfulData?.items, contentfulData.total);
          setLoading(false);
        });
    }

    if (queryParams?.catID && articleContent.ready) {
      let cat = filter(categories, (category: Category) => category.ID === queryParams.catID) as any;
      let articlesOnCat = [] as Entry<any>[];
      each(cat?.[0]?.xp?.Articles, (catArticle) => {
        let result = find(articleContent.data, (article) => article.sys.id === catArticle.id);
        if (result) articlesOnCat.push(result);
      });
      if (articlesOnCat.length) {
        if (updateTotal) updateTotal(articlesOnCat.length);
        setArticlesInView(articlesOnCat);
      }
    } else {
      if (articleContent && articleContent.ready) {
        let available;
        if (searchResults?.length) {
          available = searchResults;
        } else {
          available = queryParams?.tag
            ? articleContent.data?.filter((article) => article.fields.tags.includes(queryParams?.tag))
            : articleContent.data;
        }

        if (sortState === "Oldest to Newest" && available) {
          available = reverse(cloneDeep(available));
        } else if (sortState === "Title A-Z") {
          available = sortBy(available, (a) => a.fields.title);
        } else if (sortState === "Title Z-A") {
          available = sortBy(available, (a) => a.fields.title).reverse();
        }
        finishArticles(available);
      }
    }
  }, [queryParams, articleContent, pageSize, updateTotal, sortState, categories, searchResults]);

  useEffect(() => {
    if (queryParams?.tag && contentList?.length) {
      let content = filter(contentList, (info) => queryParams.tag === info.fields.tag);
      if (content.length) {
        setContentfulContent(content[0]?.fields?.complexContent?.fields);
      }
    }
  }, [queryParams?.tag, contentList]);

  return (
    <Fragment>
      {loading ? (
        <BachmansLoading />
      ) : (
        articlesInView?.map((article, index) => {
          return (
            <Fragment key={index}>
              {queryParams?.tag && contentfulContent && index === 2 && (
                <div key={index} className={classes.conentfulContainer}>
                  <FullWidthContentBlock fullWidthContent={contentfulContent} />
                </div>
              )}
              <Paper variant="outlined" square key={article.sys.id} className={classes.articleCard}>
                <Grid container spacing={isMobile ? 4 : 8} alignItems="center">
                  <Grid item xs={12} sm>
                    <Typography color="textSecondary" variant="body2">{`Last Updated on ${moment(
                      article.sys.updatedAt
                    ).format("MMMM D, YYYY")}`}</Typography>
                    <ButtonBase disableRipple onClick={() => articleSelect(article.fields.slug, article.fields)}>
                      <Typography className={classes.articleTitle} variant="h2">
                        {article.fields.title}
                      </Typography>
                    </ButtonBase>
                    <Typography variant="body1">{article.fields.description}</Typography>
                  </Grid>
                  <Grid item xs={12} sm className={classes.flexGrow0}>
                    <DoubleOutlinedBtn
                      buttonText="View Article"
                      buttonProps={{
                        variant: "outlined",
                        type: "button",
                        onClick: () => articleSelect(article.fields.slug, article.fields),
                      }}
                      styleProps={{
                        padding: "4px 32px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Fragment>
          );
        })
      )}
    </Fragment>
  );
};

export default CareAdviceList;
