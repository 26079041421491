import {
  Box,
  Button,
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { BachmansCardMessage } from "../../../models/shipment";
import { useAppSelector } from "../../../redux/store-hook";
import { neutral } from "../../../themes/colors";
import { CardMessageData, DeliveryInfo } from "./index";
import { regexConstants } from "../../../constants/regex.constants";
import { useHistory, useLocation } from "react-router-dom";
import stringService from "../../../services/string.service";
import $ from "jquery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMessageInstructions: {
      paddingLeft: theme.spacing(2),
      fontSize: 10,
    },
    cardMessageForm: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    cardMessageReadOnly: {
      marginBlock: theme.spacing(2),
      fontStyle: "italic",
      position: "relative",
      backgroundColor: neutral.offwhite_bg,
      padding: theme.spacing(1),
      "& > div": {
        display: "grid",
        gridTemplateColumns: "auto 2fr",
        gap: theme.spacing(2),
      },
    },
    edit: {
      backgroundColor: neutral.offwhite_bg,
      textDecoration: "underline",
      fontSize: "10px",
      position: "absolute",
      right: 12,
      minWidth: "unset",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    buttonGroup: {
      width: "100%",
      justifyContent: "flex-end",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& button": { fontSize: 10 },
    },
    marginBlock3: {
      marginBlock: theme.spacing(1),
    },
  })
);

interface CardMessageProps {
  cardMessageData?: CardMessageData;
  deliveryInfoStateArray?: DeliveryInfo[];
  updateCardMessage(shipmentID: string, cardMessageData: CardMessageData, applyToAll?: boolean | false): void;
  saveCardMessage(shipmentID: string, cardMessageData: CardMessageData, moveNext?: boolean | false): void;
}

const CardMessageForm: React.FunctionComponent<CardMessageProps> = (props) => {
  const classes = useStyles();
  const { cardMessageData, deliveryInfoStateArray, updateCardMessage, saveCardMessage } = props;
  const [cardMessageDataEditable, setCardMessageDataEditable] = useState<CardMessageData>({
    ...props.cardMessageData!,
  });
  const [deliveryInfoState, setDeliveryInfoState] = useState<DeliveryInfo[]>();
  const currentOrder = useAppSelector((state) => state.order);
  const [validChar, setValidChar] = useState(true);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const checkBoxRef = useRef<any>();
  const location = useLocation();
  const history = useHistory();
  const queryParams = stringService.GetQueryParamsFromLocation(location.search);

  useEffect(() => {
    setDeliveryInfoState(props.deliveryInfoStateArray);
  }, [props.deliveryInfoStateArray]);

  useEffect(() => {
    setCardMessageDataEditable(props.cardMessageData!);
  }, [props.cardMessageData]);

  const handleIncludeMessageCheckboxChange = (e: any) => {
    var elementOffset = $("#cardMessageCheckbox").offset()?.top;
    if (isMobile && e.target.checked && elementOffset) {
      window.scrollTo({
        top: elementOffset - 100,
        left: 0,
        behavior: "smooth",
      });
    }

    toggleCardMessageCheckedStatus(e.target.checked);
  };

  const toggleCardMessageCheckedStatus = (isChecked: boolean): void => {
    if (isChecked) {
      setCardMessageDataEditable({
        ...cardMessageDataEditable,
        showField: isChecked,
        applyToAll: cardMessageData?.applyToAll || false,
      });
    } else {
      setCardMessageDataEditable({
        ...cardMessageDataEditable,
        showField: isChecked,
        readOnly: false,
        cardMessage: null,
      });
      $("#delivery-info-card-message-invalid").removeClass("show");
    }
  };

  const updateCardMessageEditable = (message?: Partial<BachmansCardMessage>) => {
    const newMessage: BachmansCardMessage = {
      line1: cardMessageDataEditable?.cardMessage?.line1 || "",
      line2: cardMessageDataEditable?.cardMessage?.line2 || "",
      line3: cardMessageDataEditable?.cardMessage?.line3 || "",
      line4: cardMessageDataEditable?.cardMessage?.line4 || "",
    };
    const updatedCardMessage: BachmansCardMessage = {
      ...newMessage,
      ...message,
    };
    if (
      regexConstants.validCardMessage.test(
        updatedCardMessage.line1 + updatedCardMessage.line2 + updatedCardMessage.line3 + updatedCardMessage.line4
      )
    ) {
      setValidChar(true);
    } else {
      setValidChar(false);
    }
    setCardMessageDataEditable({ ...cardMessageDataEditable, cardMessage: updatedCardMessage });
  };

  const handleApplyCardMessageToAllShipmentsChange = (e: any) => {
    $(".delivery-info-invalid-error").removeClass("show");
    var elementOffset = $("#applyCardMessageToAllShipments").offset()?.top;

    if (isMobile && e.target.checked && elementOffset) {
      window.scrollTo({
        top: elementOffset - 100,
        left: 0,
        behavior: "smooth",
      });
    }
    setCardMessageDataEditable({ ...cardMessageDataEditable, applyToAll: e.target.checked });
  };

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            id="cardMessageCheckbox"
            ref={checkBoxRef}
            value={cardMessageDataEditable?.showField || false}
            checked={cardMessageDataEditable?.showField || false}
            onChange={handleIncludeMessageCheckboxChange}
            name="includeCardMessage"
            color="primary"
            size="small"
          />
        }
        label="Include a card message"
      />
      {currentOrder.shipments && currentOrder.shipments.length > 1 && cardMessageDataEditable?.showField === false && (
        // If they have selected no card message
        <FormControlLabel
          control={
            <Checkbox
              id="applyCardMessageToAllShipments"
              checked={cardMessageDataEditable?.applyToAll || false}
              value={cardMessageDataEditable?.applyToAll || false}
              onChange={handleApplyCardMessageToAllShipmentsChange}
              name="applyCardMessageToAllShipments"
              color="primary"
              size="small"
            />
          }
          label="Apply this card message to all shipments in your order"
        />
      )}
      {cardMessageDataEditable && cardMessageDataEditable.readOnly ? (
        <Box className={classes.cardMessageReadOnly} display="flex">
          <div>
            {cardMessageDataEditable?.cardMessage?.line1 && (
              <Fragment>
                <Typography variant="body2" color="textSecondary">
                  Line 1:
                </Typography>
                <Typography variant="body2" className="cardMessageLine1">
                  {cardMessageDataEditable?.cardMessage?.line1}
                </Typography>
              </Fragment>
            )}
            {cardMessageDataEditable?.cardMessage?.line2 && (
              <Fragment>
                <Typography variant="body2" color="textSecondary">
                  Line 2:
                </Typography>
                <Typography variant="body2" className="cardMessageLine2">
                  {cardMessageDataEditable?.cardMessage?.line2}
                </Typography>
              </Fragment>
            )}
            {cardMessageDataEditable?.cardMessage?.line3 && (
              <Fragment>
                <Typography variant="body2" color="textSecondary">
                  Line 3:
                </Typography>
                <Typography variant="body2" className="cardMessageLine3">
                  {cardMessageDataEditable?.cardMessage?.line3}
                </Typography>
              </Fragment>
            )}
            {cardMessageDataEditable?.cardMessage?.line4 && (
              <Fragment>
                <Typography variant="body2" color="textSecondary">
                  Line 4:
                </Typography>
                <Typography variant="body2" className="cardMessageLine4">
                  {cardMessageDataEditable?.cardMessage?.line4}
                </Typography>
              </Fragment>
            )}
          </div>
          <Button
            className={classes.edit}
            variant="text"
            color="primary"
            size="small"
            onClick={async () => {
              setCardMessageDataEditable({
                ...cardMessageDataEditable,
                readOnly: false,
              });
            }}
          >
            Edit
          </Button>
        </Box>
      ) : (
        <Collapse in={cardMessageDataEditable?.showField} unmountOnExit>
          <Typography gutterBottom variant="body2" color="textSecondary">
            Review your message carefully. The message sent with the enclosure card will read exactly as entered.
          </Typography>
          <p
            id="delivery-info-card-message-invalid"
            className="MuiTypography-root MuiTypography-body2 MuiTypography-colorError delivery-info-invalid-error"
          >
            Please enter a card message or uncheck the include card message checkbox.
          </p>
          <form className={classes.cardMessageForm} noValidate autoComplete="off">
            <TextField
              required
              margin="dense"
              autoFocus={true}
              value={cardMessageDataEditable?.cardMessage?.line1 || ""}
              onChange={(e: any) => {
                updateCardMessageEditable({
                  line1: e.target.value.replace(/[-+=~}[{@#$%^&:;())*&$^\\||`]+/, "").replace(/[\[\]]+/g, ""),
                });
              }}
              inputProps={{ maxLength: 40 }}
              error={
                (cardMessageDataEditable?.cardMessage?.line1 || "").length >= 40 ||
                !regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line1!)
              }
              helperText={
                ((cardMessageDataEditable?.cardMessage?.line1 || "").length >= 40 &&
                  "The maximum line limit is 40 characters") ||
                (!regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line1!) &&
                  "Sorry, we cannot print emojis and some special characters.")
              }
              variant="outlined"
              id="cardMessageLine1"
              name="line1"
              label="Line 1"
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              variant="outlined"
              value={cardMessageDataEditable?.cardMessage?.line2 || ""}
              onChange={(e: any) => {
                updateCardMessageEditable({
                  line2: e.target.value.replace(/[-+=~}[{@#$%^&:;())*&$^\\||`]+/, "").replace(/[\[\]]+/g, ""),
                });
              }}
              inputProps={{ maxLength: 40 }}
              error={
                (cardMessageDataEditable?.cardMessage?.line2 || "").length >= 40 ||
                !regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line2!)
              }
              helperText={
                ((cardMessageDataEditable?.cardMessage?.line2 || "").length >= 40 &&
                  "The maximum line limit is 40 characters") ||
                (!regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line2!) &&
                  "Sorry, we cannot print emojis and some special characters.")
              }
              id="cardMessageLine2"
              name="line2"
              label="Line 2"
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              variant="outlined"
              value={cardMessageDataEditable?.cardMessage?.line3 || ""}
              onChange={(e: any) => {
                updateCardMessageEditable({
                  line3: e.target.value.replace(/[-+=~}[{@#$%^&:;())*&$^\\||`]+/, "").replace(/[\[\]]+/g, ""),
                });
              }}
              error={
                (cardMessageDataEditable?.cardMessage?.line3 || "").length >= 40 ||
                !regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line3!)
              }
              helperText={
                ((cardMessageDataEditable?.cardMessage?.line3 || "").length >= 40 &&
                  "The maximum line limit is 40 characters") ||
                (!regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line3!) &&
                  "Sorry, we cannot print emojis and some special characters.")
              }
              inputProps={{ maxLength: 40 }}
              id="cardMessageLine3"
              name="line3"
              label="Line 3"
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              variant="outlined"
              value={cardMessageDataEditable?.cardMessage?.line4 || ""}
              onChange={(e: any) => {
                updateCardMessageEditable({
                  line4: e.target.value.replace(/[-+=~}[{@#$%^&:;())*&$^\\||`]+/, "").replace(/[\[\]]+/g, ""),
                });
              }}
              error={
                (cardMessageDataEditable?.cardMessage?.line4 || "").length >= 40 ||
                !regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line4!)
              }
              helperText={
                ((cardMessageDataEditable?.cardMessage?.line4 || "").length >= 40 &&
                  "The maximum line limit is 40 characters") ||
                (!regexConstants.validCardMessage.test(cardMessageDataEditable?.cardMessage?.line4!) &&
                  "Sorry, we cannot print emojis and some special characters.")
              }
              inputProps={{ maxLength: 40, pattern: "[a-z]{1,15}" }}
              id="cardMessageLine4"
              name="line4"
              label="Line 4"
              type="text"
              fullWidth
            />
            {currentOrder.shipments && currentOrder.shipments.length > 1 && cardMessageDataEditable?.showField && (
              // want this to show if they have edited a card message or deselcted the box
              <FormControlLabel
                control={
                  <Checkbox
                    id="applyCardMessageToAllShipments"
                    checked={cardMessageDataEditable?.applyToAll}
                    value={cardMessageDataEditable?.applyToAll}
                    onChange={handleApplyCardMessageToAllShipmentsChange}
                    name="applyCardMessageToAllShipments"
                    color="primary"
                    size="small"
                  />
                }
                label="Apply this card message to all shipments in your order"
              />
            )}
          </form>
        </Collapse>
      )}
    </Fragment>
  );
};
export default CardMessageForm;
