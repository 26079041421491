import React, { Fragment, useContext, useEffect, useState } from "react";
import { SessionContext } from "../../../providers/session";
import { Box, Checkbox, FormControlLabel, Theme, Typography } from "@material-ui/core";
import { BachmansPay, PaymentTypes, UIPayment } from "../../../models/Payment";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Me, SpendingAccount } from "ordercloud-javascript-sdk";
import { isUndefined } from "lodash";

interface CheckoutBachmansChargeProps {
  bachmansPay: BachmansPay;
  updatePaymentAmounts: (type: PaymentTypes, payment?: UIPayment) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textPaymentMethod: {
      textTransform: "capitalize",
      lineHeight: 1,
    },
  })
);

const CheckoutBachmansCharge: React.FunctionComponent<CheckoutBachmansChargeProps> = (props) => {
  const { updatePaymentAmounts, disabled, bachmansPay } = props;
  const classes = useStyles();
  const { anonymous } = useContext(SessionContext);
  const [BCAccount, setBCAccount] = useState<SpendingAccount>();
  const [useBachmansCharge, setUseBachmansCharge] = useState<boolean>();

  useEffect(() => {
    (async () => {
      if (!anonymous) {
        const spendingAccounts = await Me.ListSpendingAccounts({
          pageSize: 100,
          filters: {
            Name: "Bachmans Charge",
          },
        });
        if (spendingAccounts?.Items) {
          setBCAccount(spendingAccounts.Items[0]);
        }
      }
    })();
  }, [anonymous]);

  useEffect(() => {
    const amount = bachmansPay.BachmansCharge?.Amount || 0;
    if (amount === 0) {
      setUseBachmansCharge(false);
    }
  }, [bachmansPay.BachmansCharge?.Amount]);

  const toggleBC = (event: any) => {
    setUseBachmansCharge(event.target.checked);
    const payment: UIPayment | undefined = event.target.checked
      ? {
          AcctNumber: BCAccount?.ID,
          Amount: BCAccount?.Balance,
          Balance: BCAccount?.Balance,
        }
      : undefined;
    updatePaymentAmounts("BachmansCharge", payment);
  };

  return (
    <Fragment>
      {Boolean(!anonymous && !isUndefined(BCAccount?.Balance)) && (
        <Box display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                checked={useBachmansCharge}
                onChange={toggleBC}
                disabled={Boolean(disabled || (BCAccount?.Balance || 0) <= 0)}
                name="usePurplePerks"
                color="primary"
                size="small"
              />
            }
            label={
              <Fragment>
                <Typography variant="h3" className={classes.textPaymentMethod} color="textSecondary">
                  Bachman's Charge{" "}
                  <Typography style={{ marginLeft: 4 }} variant="h3" component="span" color="primary">
                    {`$${BCAccount?.Balance}`}
                  </Typography>
                </Typography>
                <Typography variant="body2">Apply my available Bachman's Charge balance</Typography>
              </Fragment>
            }
          />
        </Box>
      )}
    </Fragment>
  );
};

export default CheckoutBachmansCharge;
