import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
interface EmploymentListProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    iframeWrapper: {
      height: "1000px",
      "& > iframe": {
        marginTop: theme.spacing(3),
        border: `1px solid ${theme.palette.grey[300]}`,
        width: "100%",
        minHeight: "50vh",
      },
    },
    title: {
      margin: theme.spacing(2, 2),
    },
  })
);
const EmploymentList: FunctionComponent<EmploymentListProps> = (props) => {
  const classes = useStyle();
  return (
    <div className={classes.iframeWrapper}>
      <iframe
        title="employment-iframe"
        id="resumator-job-iframe"
        name="resumator-job-iframe"
        src="//bachmansinc.applytojob.com/apply/jobs/"
        width="100%"
        height="1000px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};
export default EmploymentList;
