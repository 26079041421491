import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: -10,
    },
    productFlag: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      color: "white",
      textAlign: "center",
      display: "inline-block",
      padding: theme.spacing(0.5, 1),
      margin: "0 auto",
      zIndex: 1,
    },
  })
);

interface ProductFlagProps {
  label: string;
}

const ProductFlag: FunctionComponent<ProductFlagProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <small className={classes.productFlag}>{props.label}</small>
    </div>
  );
};

export default ProductFlag;
