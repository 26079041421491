import { BuyerProduct, LineItem } from "ordercloud-javascript-sdk";
import ReactGA from "react-ga";
import BachmansOrder from "../models/order";

const addItemToCart = (item: LineItem) => {
  ReactGA.plugin.execute("ec", "addProduct", {
    id: item.ProductID,
    name: item.Product?.Name,
    quantity: item.Quantity,
  });
  ReactGA.plugin.execute("ec", "setAction", "add");
};

const viewProductPage = (product: BuyerProduct) => {
  ReactGA.plugin.execute("ec", "addProduct", {
    id: product.ID,
    name: product.Name,
  });
  ReactGA.plugin.execute("ec", "setAction", "detail");
};

const addItemsForPurchase = (items: LineItem[]) => {
  items.forEach((item) => {
    ReactGA.plugin.execute("ec", "addProduct", {
      id: item.ProductID,
      name: item.Product?.Name,
      category: item.xp?.CategoryID,
      price: item?.xp?.Amount || item.UnitPrice,
      quantity: item.Quantity,
    });
  });
};

const purchase = (order?: BachmansOrder) => {
  ReactGA.plugin.execute("ec", "setAction", "purchase", {
    id: order?.ID,
    name: "Bachmans Redesign web",
    revenue: order?.Total,
    shipping: order?.ShippingCost,
  });
  ReactGA.pageview("/orderConfirmation");
  ReactGA.plugin.execute("ec", "clear", null);
};

const trackCheckout = (step: number) => {
  ReactGA.plugin.execute("ec", "setAction", "checkout", { step: step });
  ReactGA.pageview("/cart");
};

const removeFromCart = (item: LineItem) => {
  ReactGA.plugin.execute("ec", "setAction", "remove", {
    id: item.ProductID,
    name: item.Product?.Name,
    quantity: item.Quantity,
  });
};

const service = {
  AddItemToCart: addItemToCart,
  RemoveItemFromCart: removeFromCart,
  ViewProductPage: viewProductPage,
  Checkout: {
    AddItemsForPurchase: addItemsForPurchase,
    Purchase: purchase,
    TrackCheckout: trackCheckout,
  },
};

export default service;
