import { Address, Addresses, Me } from "ordercloud-javascript-sdk";
import { listAll } from "../helpers/listAll";
import { BuyerXp } from "../models/buyerXp";
import bachmansIntegrationsService from "./bachmansIntegrations.service";

const init = () => {
  getLocalCities();
  getLyndaleStore();
};

const getMatchingAddress = (zipCode: string) => {
  const check = () => service.LocalCities?.filter((city: Address) => city.Zip === zipCode);

  const wait = () => {
    setTimeout(() => (service.LocalCities ? wait() : check()), 100);
  };
  return !service.LocalCities ? wait() : check();
};

const getLyndaleStore = (): Promise<Address> => {
  if (!service.LyndaleStore) {
    return Me.GetAddress("Store-Lyndale-6010--612-861-7676").then((store) => {
      service.LyndaleStore = store;
      return store;
    });
  } else {
    return Promise.resolve(service.LyndaleStore);
  }
};

const getLocalCities = (): Promise<Address[]> => {
  if (!service.LocalCities) {
    var LocalCities: Address<any>[] = [];
    var addressOptions = {
      page: 1,
      pageSize: 100,
      filters: { ID: "LDCity-*" },
    };
    return listAll(Addresses.List, "Bachmans", addressOptions).then((res) => {
      LocalCities = LocalCities.concat(res);
      service.LocalCities = LocalCities;
      return LocalCities;
    });
  } else {
    return Promise.resolve(service.LocalCities);
  }
};

const getBuyerXp = async (): Promise<BuyerXp> => {
  if (!service.BuyerXp) {
    var buyerxp = await bachmansIntegrationsService.getBuyerXp();
    service.BuyerXp = buyerxp.data;
    return buyerxp.data;
  } else {
    return Promise.resolve(service.BuyerXp);
  }
};

//  Point of this service is to store resources as variables to prevent making numerous calls to get the same items
interface IResourceService {
  BuyerXp?: BuyerXp;
  LocalCities?: Address[];
  LyndaleStore?: Address;
  Init: () => void;
  GetMatchingAddress: (zipCode: string) => any;
  GetLyndaleStore: () => Promise<Address>;
  GetLocalCities: () => Promise<Address[]>;
  GetBuyerXp: () => Promise<BuyerXp>;
}

const service: IResourceService = {
  Init: init,
  GetMatchingAddress: getMatchingAddress,
  GetLyndaleStore: getLyndaleStore,
  GetLocalCities: getLocalCities,
  GetBuyerXp: getBuyerXp,
};

export default service;
