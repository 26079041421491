import { Fragment, useEffect, useState } from "react";
import { Address, BuyerAddress } from "ordercloud-javascript-sdk";
import {
  FormControl,
  Theme,
  InputLabel,
  useMediaQuery,
  Select,
  MenuItem,
  FormControlProps,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import deliveryService from "../../services/delivery.service";
import AddressDialog from "../Account/AddressDialog";
import { omit } from "lodash";
import { ValidatedBachmansShipmentWithLineItems } from "../../models/shipment";

interface StoreDropDownProps {
  formControlProps?: FormControlProps;
  onAddressSubmit?: (address: Address, routeCode?: string) => Promise<void>;
  shipment?: ValidatedBachmansShipmentWithLineItems;
  onDialogClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      display: "flex",
      justifyContent: "flex-start",
      "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        "& .MuiTypography-root": {
          lineHeight: 1,
        },
      },
    },
  })
);

const StoreDropDown: React.FunctionComponent<StoreDropDownProps> = (props) => {
  const { formControlProps, onAddressSubmit, shipment, onDialogClose } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [stores, setStores] = useState<Address[]>([]);
  const [selectedStore, setSelectedStore] = useState<Address>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      let stores = await deliveryService.GetPickUpStores();
      setStores(stores);
    })();
  }, []);

  useEffect(() => {
    if (shipment?.ToAddress?.CompanyName) {
      setSelectedStore(shipment.ToAddress);
      setDialogOpen(true);
    }
  }, [shipment?.ToAddress]);

  const handleClose = () => {
    if (onDialogClose) {
      onDialogClose();
    }
    setDialogOpen(false);
  };

  const selectStore = (e: any) => {
    const store = stores.find((s) => s.ID === e.target.value);
    if (store) {
      store.xp = {
        addressType: "InStorePickUp",
        WorkingHrs: store.xp.WorkingHrs,
        Holidays: store.xp.Holidays,
      };
      setSelectedStore(omit(store, "FirstName", "LastName", "Phone"));
    }
  };

  const handleAddressSubmit = async (address: BuyerAddress, routeCode?: string) => {
    if (onAddressSubmit) {
      const addressToSubmit = omit(address, "ID") as Address;
      await onAddressSubmit(addressToSubmit, routeCode);
    }
  };

  return (
    <Fragment>
      <FormControl {...formControlProps}>
        <InputLabel variant="outlined" id="storePickUpSelect">
          Select Store
        </InputLabel>
        <Select
          className={classes.menu}
          labelId="storePickUpSelect"
          id="storePickUpSelect"
          label="Select Store"
          value={selectedStore?.ID}
          onChange={selectStore}
          MenuProps={{
            PaperProps: {
              square: true,
              style: { maxWidth: !isMobile ? "275px" : "100%" },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {stores?.map((store, index) => (
            <MenuItem key={index} value={store.ID} onClick={() => setDialogOpen(true)}>
              {store.CompanyName}
            </MenuItem>
          ))}
        </Select>
        <AddressDialog
          variant="InStorePickUp"
          open={dialogOpen}
          onClose={handleClose}
          onSubmit={handleAddressSubmit}
          address={selectedStore}
        />
      </FormControl>
    </Fragment>
  );
};

export default StoreDropDown;
