import { Button, makeStyles, createStyles, Theme } from "@material-ui/core";
import { clone, replace } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { StaticPageUrlParams } from ".";
import stringService from "../../services/string.service";
import useQuery from "../Shared/hooks/useQuery";

export interface ResourceDrawerButtonProps {
  handleClick: (event: React.ChangeEvent<any>, child?: any) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    purpleCta: {
      backgroundColor: "#9262B8",
      color: "#fff",
      display: "none",
      "&:first-child": {
        display: "block",
      },
    },
  })
);

const ResourceDrawerButton: React.FunctionComponent<ResourceDrawerButtonProps> = (props) => {
  const { handleClick } = props;
  const { section, rootSlug, branchSlug } = useParams<StaticPageUrlParams>();
  const query = useQuery();

  const getTitle = useMemo(() => {
    let root = `${replace(stringService.CapitalizeString(rootSlug, ["-"]), /-/g, " ")}`;
    let branch = clone(branchSlug ? ` > ${replace(stringService.CapitalizeString(branchSlug, ["-"]), /-/g, " ")}` : "");
    let tag = query.get("tag");
    if (section === "information" && rootSlug === "resource-hub") {
      if (!tag) {
        branch = "All Care Guides";
      } else {
        root = "";
        branch = `${tag}`;
      }
    }

    return `${root}${branch}`;
  }, [section, branchSlug, rootSlug, query]);
  const classes = useStyles();
  return (
    // <Button variant="outlined" fullWidth={true} onClick={handleClick}>
    //   {getTitle} - Trial
    // </Button>
    <Button className={classes.purpleCta} variant="outlined" color="primary" fullWidth={true} onClick={handleClick}>
      {"< Return to Menu"}
    </Button>
  );
};

export default ResourceDrawerButton;
