import React, { Fragment, FunctionComponent, useCallback, useState } from "react";
import {
  Box,
  createStyles,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Link,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { AuthDialogTab } from "./AuthDialog";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import AuthService from "../../services/authentication.service";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import PasswordRequirementsList from "./PasswordRequirementsList";
interface ForgotPasswordProps {
  mustResetPassword?: boolean;
  onTabChange: (newTab: AuthDialogTab) => (e: React.MouseEvent) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    forgotMessage: {
      marginBottom: theme.spacing(2),
    },
    resetMessage: {
      marginBottom: theme.spacing(2),
    },
    resetDirections: {
      marginTop: theme.spacing(1),
      fontWeight: "bold",
    },
  })
);

interface FormValues {
  email: string;
}
const validationSchema = yup.object({
  email: yup.string().email("Email not valid").required("Email is required"),
});

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = (props) => {
  const classes = useStyle();
  const { onTabChange, mustResetPassword } = props;
  const [resetLinkConfirm, setResetLinkConfirm] = useState(false);
  const [pageInfo, setPageInfo] = useState({ loading: false, error: false, message: "" });

  const handleRegister = useCallback(async ({ email }: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      await AuthService.SendVerificationCodeAsync(email);
      setResetLinkConfirm(true);
    } catch (err) {
      setPageInfo({ loading: false, error: true, message: "Entered email is not found." });
    }
  }, []);

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography variant="h1">Reset Password</Typography>
      </DialogTitle>
      {resetLinkConfirm ? (
        <Box>
          <DialogContentText>
            <Typography variant="body1" color="secondary">
              A password reset link has been sent to your registered email. It will expire in 2 hours.
            </Typography>
          </DialogContentText>
          <DialogContentText>
            {`Remember your password? `}
            <Link href="#" onClick={onTabChange("login")}>
              Log in
            </Link>
          </DialogContentText>
        </Box>
      ) : (
        <Formik initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={handleRegister}>
          {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {mustResetPassword && (
                  <div className={classes.resetMessage}>
                    <Typography variant="body1">
                      For your security, Bachmans has enhanced our password requirements.
                    </Typography>
                    <Typography variant="body1" className={classes.resetDirections}>
                      You will need to update your password to include a minimum of 10 characters, and each of the
                      following attributes:
                    </Typography>
                    <PasswordRequirementsList />
                  </div>
                )}
                <FormHelperText className={classes.forgotMessage}>
                  Enter your email address and we will send you instructions to reset your password.
                </FormHelperText>
                <TextField
                  margin="dense"
                  autoFocus
                  variant="outlined"
                  id="email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  type="email"
                  fullWidth
                />
                {pageInfo.error && pageInfo.message && (
                  <DialogContentText color="error">{pageInfo.message}</DialogContentText>
                )}
                <DoubleOutlinedBtn
                  buttonText="Send Link"
                  buttonProps={{
                    type: "submit",
                    disabled: isSubmitting || !!(errors.email && touched.email),
                  }}
                  styleProps={{
                    margin: "16px 0",
                    padding: "4px 32px",
                  }}
                />
                <DialogContentText>
                  {`Remember your password? `}
                  <Link href="#" onClick={onTabChange("login")}>
                    Log in
                  </Link>
                </DialogContentText>
              </DialogContent>
            </form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default ForgotPassword;
