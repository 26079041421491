import React, { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import {
  Checkbox,
  Collapse,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Category } from "ordercloud-javascript-sdk";
import { find } from "lodash";
import { purple } from "../../../themes/colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

interface ChildrenCategoriesProps {
  childrenCategories: Category[];
  currentCategory?: Category;
  categoryFilters?: string[];
  categorySelectChange: (catID: string) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      width: "calc(100% - 16px)",
      padding: theme.spacing(0, 0, 0, 2),
    },
    header: {
      display: "flex",
      alignContent: "center",
      padding: theme.spacing(1, 0, 0, 0),
    },
    arrow: {
      color: theme.palette.grey[500],
      margin: theme.spacing(0.5, 0, 0.5, 0),
    },
    formControlLabel: {
      "& > .MuiTypography-body1": {
        lineHeight: 1.15,
        fontWeight: theme.typography.fontWeightBold,
        fontFamily: theme.typography.subtitle2.fontFamily,
      },
    },
    formLabel: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.h4.fontFamily,
      color: purple.regular,
      margin: theme.spacing(1, 0, 1, 0),
      flex: "2 0 0",
    },
    formGroup: {
      padding: theme.spacing(0, 0, 1, 0),
      color: theme.palette.text.secondary,
    },
    checkbox: {
      color: theme.palette.text.secondary,
    },
  })
);

const ChildrenCategories: FunctionComponent<ChildrenCategoriesProps> = (props) => {
  const classes = useStyle();
  const [isOpen, setIsOpen] = useState(true);
  const { childrenCategories, currentCategory, categoryFilters, categorySelectChange } = props;

  const handleHeaderClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return childrenCategories.length > 0 ? (
    <FormControl className={classes.formControl}>
      <div className={classes.header} onClick={handleHeaderClick}>
        <FormLabel className={classes.formLabel}>{currentCategory?.Name}</FormLabel>
        {isOpen ? <KeyboardArrowUp className={classes.arrow} /> : <KeyboardArrowDown className={classes.arrow} />}
      </div>

      <FormGroup className={classes.formGroup}>
        <Collapse in={isOpen}>
          {childrenCategories &&
            childrenCategories.map((c: Category, i: number) => (
              <FormControlLabel
                className={classes.formControlLabel}
                key={i}
                control={
                  <Checkbox
                    id={c.ID!}
                    className={classes.checkbox}
                    size="small"
                    color="primary"
                    checked={
                      categoryFilters
                        ? !!find(categoryFilters, (cid) => cid.toLowerCase() === c.ID!.toLowerCase())
                        : false
                    }
                    onChange={categorySelectChange(c.ID!)}
                  />
                }
                label={c.Name}
              />
            ))}
        </Collapse>
      </FormGroup>
    </FormControl>
  ) : null;
};

export default ChildrenCategories;
