import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  alpha,
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Address, BuyerProduct, LineItem, LineItems } from "ordercloud-javascript-sdk";
import { BachmansShipmentWithItems } from "../../models/shipment";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import { cloneDeep, omit, sumBy } from "lodash";
import { neutral } from "../../themes/colors";
import CloseIcon from "@material-ui/icons/Close";
import ProductPrice from "../Product/ProductPrice";
import { Quantity } from "../../models/product";
import SplitQuantityAccordion from "./SplitQuantityAccordian";
import { useAppSelector } from "../../redux/store-hook";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { calculateOrder, patchOrder } from "../../redux/slices/order";
import addToCartService from "../../services/addToCart.service";
import { DeliveryMethods } from "../../models/delivery";
import deliveryService from "../../services/delivery.service";
import moment from "moment";
import productService from "../../services/product.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "50vw",
        minHeight: "50vh",
      },
      "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(3),
        },
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(1, 3, 3, 3),
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
          flexFlow: "row nowrap",
        },
      },
    },
    addressBox: {
      marginTop: theme.spacing(1),
    },
    placeholderText: {
      color: neutral.strikethrough,
    },
    boldText: {
      fontWeight: "bold",
    },

    header: {
      backgroundColor: neutral.grey_background,
      padding: theme.spacing(3),
      position: "relative",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    buttonClose: {
      position: "relative",
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      alignSelf: "flex-end",
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        top: 4,
        right: 4,
        margin: 0,
      },
    },
    headerConfirmation: {
      display: "flex",
      flexFlow: "row nowrap",
      gap: theme.spacing(4),
      alignItems: "stretch",
      justifyContent: "center",
    },
    headerTextContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },

    toggleButtonGroup: {
      borderRadius: 0,
    },
    toggleButton: {
      borderRadius: 0,
      minHeight: 66,
      "& .MuiToggleButton-label": {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "flex-start",
      },
    },
    gridCalendar: {
      flexGrow: 0,
      "& .MuiPickersStaticWrapper-staticWrapperRoot": {
        boxShadow: theme.shadows[1],
      },
      "& .MuiPickersDay-daySelected.MuiPickersDay-dayDisabled": {
        color: "rgba(255, 255, 255, 0.38)",
      },
    },
    productInfo: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      "& img": {
        width: "60px",
        aspectRatio: "1 / 1",
        objectFit: "cover",
      },
    },
    formLabel: {
      width: "100%",
      minHeight: 66,
      margin: "-1px 0 0 0",
      border: `1px solid ${neutral.grey_background}`,
      color: neutral.text_white_bg,
      padding: theme.spacing(1),
    },
    formLabelChecked: {
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
    },
    BtnShipToAdditionalRecipient: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 6px)",
        margin: "16px auto",
      },
    },
  })
);

interface SplitQuantityModalProps {
  open: boolean;
  products: BuyerProduct[];
  shipment: BachmansShipmentWithItems;
  deliveryMethods: DeliveryMethods;
  lineItem?: LineItem;
  onClose: () => void;
  origQuantity: Quantity;
  disabled?: boolean;
}

export interface SplitQuantityItem {
  lineItem: LineItem;
  address?: Address;
}

// component is modifing the the quantity of the orig li, and creating a new li, with differnt shippingto and quantity info
const SplitQuantityModal: React.FunctionComponent<SplitQuantityModalProps> = (props) => {
  const classes = useStyles();
  const { open, shipment, lineItem, origQuantity, onClose, disabled, deliveryMethods, products } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState<boolean>(false);
  const currentOrder = useAppSelector((state) => state.order);
  const [splitQuantityState, setSplitQuantityState] = useState<SplitQuantityItem[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const createClone = useCallback((item: LineItem) => {
    return cloneDeep(omit(item, ["ID", "ShippingAddressID", "ShippingAddress", "DateAdded"]));
  }, []);

  useEffect(() => {
    if (lineItem) {
      let original = cloneDeep(lineItem);
      original.Quantity = lineItem.Quantity - 1;
      let clone = createClone(lineItem);
      clone.Quantity = 1;
      setSplitQuantityState([
        { lineItem: original, address: original.ShippingAddress },
        { lineItem: clone, address: undefined },
      ]);
    }
  }, [createClone, lineItem]);

  const handleClose = () => {
    if (lineItem) {
      let original = cloneDeep(lineItem);
      original.Quantity = lineItem.Quantity - 1;
      let clone = createClone(lineItem);
      clone.Quantity = 1;
      setSplitQuantityState([
        { lineItem: original, address: original.ShippingAddress },
        { lineItem: clone, address: undefined },
      ]);
    }
    onClose();
  };

  const onAddressUpdate = async (address: Address, index: number, routeCode?: string) => {
    let items = [...splitQuantityState];
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        if (routeCode) {
          item.lineItem.xp.RouteCode = routeCode;
        }
        return {
          lineItem: item.lineItem,
          address: address,
        };
      } else return item;
    });
    setSplitQuantityState(updatedItems);
  };

  const onQuantityUpdate = (quantity: number, index: number) => {
    let items = [...splitQuantityState];
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        item.lineItem.Quantity = quantity;
      }
      return item;
    });
    setSplitQuantityState(updatedItems);
  };

  // remove additional shipTo address -should always be a min of 2 addresses
  const onAddressRemove = (index: number) => {
    const state = [...splitQuantityState];
    state.splice(index, 1);
    setSplitQuantityState(state);
  };

  //start to adding a new shipping address
  const addNewLi = () => {
    if (lineItem) {
      const clone = createClone(lineItem);
      clone.Quantity = 1;
      const state = cloneDeep(splitQuantityState);
      // need to subtract from initial item
      const newState = state?.map((item, index) => {
        if (index === 0) {
          item.lineItem.Quantity = item.lineItem.Quantity - 1;
        }
        return item;
      });
      newState?.push({
        lineItem: clone,
        address: undefined,
      });
      setSplitQuantityState(newState);
    }
  };

  const canAddItem: boolean = useMemo(() => {
    if (!splitQuantityState?.length || splitQuantityState[0]?.lineItem.Quantity === 1) {
      return false;
    } else return true;
  }, [splitQuantityState]);

  const canSave: boolean = useMemo(() => {
    if (!splitQuantityState?.length) {
      return false;
    } else {
      return splitQuantityState.every((item) => item.address && item.address !== null);
    }
  }, [splitQuantityState]);

  const PatchAndSetAddress = async (item: SplitQuantityItem) => {
    let reqs: Promise<any>[] = [];
    const partialLineItem: Partial<LineItem> = {
      Quantity: item.lineItem.Quantity,
      xp: {
        RouteCode: item.lineItem?.xp?.RouteCode,
        AddressType: item?.address?.xp?.addressType,
      },
    };
    const product = products.find((p) => p.ID === item.lineItem.ProductID);
    if (product && item.address) {
      const validDelivery = deliveryService.GetDeliveryTypeFromAddress(
        [product],
        deliveryMethods,
        item.address,
        item.lineItem?.xp?.DeliveryMethod
      );
      if (validDelivery) {
        partialLineItem.xp.DeliveryMethod = validDelivery;
        if (item.address?.ID) {
          partialLineItem.ShippingAddressID = item.address.ID;
          reqs.push(LineItems.Patch("Outgoing", currentOrder.order?.ID!, item.lineItem.ID!, partialLineItem));
        } else if (item.address) {
          reqs = reqs.concat([
            LineItems.Patch("Outgoing", currentOrder.order?.ID!, item.lineItem.ID!, partialLineItem),
            LineItems.SetShippingAddress("Outgoing", currentOrder.order?.ID!, item.lineItem.ID!, item.address),
          ]);
        }
        await Promise.all(reqs);
      } else {
        Promise.reject("No valid delivery method");
      }
    }
  };

  const createOrUpdate = async (item: SplitQuantityItem) => {
    if (item.lineItem.ID) {
      await PatchAndSetAddress(item);
    } else {
      const itemToCreate = addToCartService.DuplicateLineItem(item.lineItem);
      const dif = moment(new Date()).add(1, "m").diff(itemToCreate.DateNeeded, "m");
      if (dif > 0) {
        itemToCreate.DateNeeded = moment(itemToCreate.DateNeeded).add(dif, "m").toISOString();
      }
      const createdItem = await LineItems.Create("Outgoing", currentOrder.order?.ID!, itemToCreate);
      item.lineItem = createdItem;
      await PatchAndSetAddress(item);
    }
  };

  const submit = async () => {
    if (canSave) {
      let shipToMultiple = true as boolean;
      setLoading(true);
      let reqs: Promise<any>[] = [];
      splitQuantityState?.forEach((item) => {
        reqs.push(createOrUpdate(item));
      });
      try {
        await dispatch(
          patchOrder({
            partial: { xp: { shipToMultiple } },
            orderID: currentOrder?.order?.ID as string,
          })
        );
        await Promise.all(reqs);
        await dispatch(calculateOrder(currentOrder.order?.ID!));
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  const getQuantityObj = (item: LineItem): Quantity => {
    const items: LineItem[] = splitQuantityState?.map((state) => state.lineItem) || [];
    const added = sumBy(items, (li) => li.Quantity);
    return {
      maxQuantity: (origQuantity.maxQuantity || 10000) - added,
      quantity: item.Quantity,
      minQuantity: origQuantity.minQuantity,
    };
  };

  const canAddMoreLi: boolean = useMemo(() => {
    const items: LineItem[] = splitQuantityState?.map((state) => state.lineItem) || [];
    if (splitQuantityState[0]?.lineItem.Quantity === 1) {
      return false;
    } else {
      const added = sumBy(items, (li) => li?.Quantity || 0);
      return added < (origQuantity.maxQuantity || 1000);
    }
  }, [origQuantity.maxQuantity, splitQuantityState]);

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      PaperProps={{ square: true }}
      fullScreen={isMobile}
      onClose={handleClose}
      maxWidth="xl"
    >
      <div className={classes.header}>
        <IconButton onClick={onClose} className={classes.buttonClose} disabled={disabled} disableRipple>
          <CloseIcon color="action" />
        </IconButton>
        <Box className={classes.headerConfirmation}>
          <Box className={classes.headerTextContainer} alignItems={"flex-end"}>
            <Typography variant="h4" color="primary">
              Send to Multiple Recipients
            </Typography>
          </Box>
          <Fragment>
            <Divider orientation="vertical" flexItem />
            {lineItem ? (
              <Box display="flex" className={classes.productInfo}>
                <img src={lineItem.xp?.ShownImage} alt={lineItem.Product?.Name}></img>
                <div>
                  <Typography variant="h4">{productService.GetLineItemName(lineItem)}</Typography>
                  <ProductPrice lineItem={lineItem as LineItem} />
                </div>
              </Box>
            ) : (
              <Box>
                <Typography variant="h4">
                  {`${sumBy(shipment.LineItems, function (l) {
                    return l.Quantity;
                  })} Item(s)`}
                </Typography>
              </Box>
            )}
          </Fragment>
        </Box>
      </div>
      <DialogContent>
        {splitQuantityState?.length &&
          splitQuantityState.map((item, index) => (
            <SplitQuantityAccordion
              key={index}
              index={index}
              item={item}
              quantity={getQuantityObj(item.lineItem)}
              onAddressUpdate={onAddressUpdate}
              onAddressRemove={onAddressRemove}
              onQuantityUpdate={onQuantityUpdate}
            />
          ))}
        <Hidden smUp>
          <DoubleOutlinedBtn
            className={classes.BtnShipToAdditionalRecipient}
            buttonText="Ship to Additional Recipient"
            buttonProps={{
              variant: "outlined",
              size: "small",
              color: "primary",
              disabled: !canAddMoreLi || loading,
              onClick: addNewLi,
            }}
          />
        </Hidden>
      </DialogContent>
      <DialogActions disableSpacing>
        <Hidden smDown>
          {canAddMoreLi && (
            <DoubleOutlinedBtn
              className={classes.BtnShipToAdditionalRecipient}
              buttonText="Ship to Additional Recipient"
              buttonProps={{
                variant: "outlined",
                size: "small",
                color: "primary",
                disabled: !canAddItem || loading,
                onClick: addNewLi,
              }}
            />
          )}
        </Hidden>
        <Button variant="text" size="small" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <DoubleOutlinedBtn
          buttonText="Save"
          buttonProps={{ color: "primary", size: "small", disabled: !canSave || loading, onClick: submit }}
          styleProps={{ paddingLeft: 40, paddingRight: 40, margin: 3 }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SplitQuantityModal;
