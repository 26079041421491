import React, { Fragment, useState } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import productService from "../../../services/product.service";
import { BachmansProduct } from "../../../models/product";
import { neutral } from "../../../themes/colors";
import { StyledTab, StyledTabs } from "../../Shared/Tabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanelRoot: {
      backgroundColor: "white",
      // TODO: Does this need to be longhand? Can we do border: `1px solid ${theme.palette.grey[200]}`?
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      marginTop: "-1px",
    },
    iconImage: {
      maxWidth: "50px",
    },
    attributeList: {
      padding: 0,
      display: "inline-block",
      width: "100%",
    },
    attributeListItem: {
      marginRight: theme.spacing(2),
      maxWidth: "33%",
      padding: 0,
    },
    attributeTitle: {
      color: theme.palette.primary.main,
    },
    prodHtml: {
      color: neutral.text_white_bg,
    },
  })
);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface EventTabsProps {
  event?: null | BachmansProduct;
  group?: BachmansProduct[];
}

export interface AttributeImage {
  attribute: string;
  svg: string;
  title: string;
}

const EventTabs: React.FunctionComponent<EventTabsProps> = (props) => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => setValue(tab);

  return (
    <Fragment>
      <StyledTabs value={value} onChange={handleTabChange}>
        <StyledTab label="Description" {...a11yProps(0)} />
        <StyledTab label="Ticket Information" {...a11yProps(1)} />
        {props.event?.xp?.IsWorkshop && <StyledTab label="Returns &amp; Exchanges" {...a11yProps(2)} />}
      </StyledTabs>

      <EventTabPanel value={value} index={0} {...props}></EventTabPanel>
      <EventTabPanel value={value} index={1} {...props}></EventTabPanel>
      <EventTabPanel value={value} index={2} {...props}></EventTabPanel>
    </Fragment>
  );
};

interface EventTabPanelProps extends EventTabsProps {
  index: any;
  value: any;
  warranty?: string;
  attributes?: AttributeImage[];
}

const EventTabPanel: React.FunctionComponent<EventTabPanelProps> = (props) => {
  const classes = useStyles();
  const { value, index, group, event } = props;
  const defaultEvent = productService.GetDefaultProduct(group);

  return (
    <div
      className={classes.tabPanelRoot}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={2}>
          {index === 0 && (
            <div
              className={classes.prodHtml}
              dangerouslySetInnerHTML={{
                __html: event?.Description || defaultEvent?.Description || "",
              }}
            ></div>
          )}
          {index === 1 && (
            <div
              className={classes.prodHtml}
              dangerouslySetInnerHTML={{
                __html: event?.xp?.TicketInformation || defaultEvent?.xp?.TicketInformation || "",
              }}
            ></div>
          )}
          {index === 2 && (
            <div
              className={classes.prodHtml}
              dangerouslySetInnerHTML={{
                __html: event?.xp?.CancellationOrPolicies || defaultEvent?.xp?.CancellationOrPolicies || "",
              }}
            ></div>
          )}
        </Box>
      )}
    </div>
  );
};

export default EventTabs;
