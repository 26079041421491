import {
  CardContent,
  createStyles,
  makeStyles,
  useTheme,
  Typography,
  Theme,
  Card,
  CardActionArea,
  Box,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import React, { Fragment, FunctionComponent } from "react";
import eventPlaceHolderImg from "../../assets/images/WorkshopsEventsFiller.jpg";
import Currency from "react-currency-formatter";
import { useHistory } from "react-router-dom";
import { BachmansEvent } from "../../models/product";
import { DoubleOutlinedBtn } from "../Shared/DoubleOutlinedBtn";
import BachmansCardMedia from "../Shared/BachmansCardMedia";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "0px",
      border: `1px solid ${theme.palette.grey[200]}`,
      transition: "box-shadow .5s, transform .5s",
      flexGrow: 1,
      "&:hover, &:active, &:focus": {
        boxShadow: theme.shadows[1],
        transform: "translateY(-2px)",
      },
    },
    cardAction: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      height: "100%",
    },
    cardBody: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      justifyContent: "space-between",
      flexGrow: 1,
      "& h4": {
        flexGrow: 0,
      },
    },
    linkViewAll: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      lineHeight: 1,
    },
    TextToAttend: {
      fontWeight: theme.typography.fontWeightBold,
    },
    dateDisplay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
      margin: theme.spacing(1, 0, 2, 0),
      "& .MuiTypography-root": {
        lineHeight: 1,
        textAlign: "left",
      },
    },
  })
);

interface EventCardProps {
  event: BachmansEvent;
  featured?: boolean;
  purchased?: boolean; // event has been purchased
}

const EventCard: FunctionComponent<EventCardProps> = (props) => {
  const classes = useStyles();
  const { purchased, featured, event } = props;
  const images = event?.xp?.Contentful?.Images;
  const history = useHistory();
  const theme = useTheme();
  const eventPrice =
    event.PriceSchedule?.PriceBreaks && event.PriceSchedule?.PriceBreaks[0]
      ? event.PriceSchedule?.PriceBreaks[0].Price
      : event.UnitPrice
      ? event.UnitPrice
      : "";

  const routeToProduct = () => {
    history.push(`/event/${event?.xp?.ProductCode}`, {
      path: window.location.pathname,
      search: window.location.search,
    });
  };

  return (
    <Card className={classes.root} elevation={0} onClick={routeToProduct}>
      <CardActionArea component="div" className={classes.cardAction}>
        <BachmansCardMedia
          aspectRatio="1.42"
          alt={"Image For " + event.Name}
          image={
            images && images.length && !images[0].url.includes("WorkshopsEventsFiller")
              ? images[0].url
              : eventPlaceHolderImg
          }
        />
        <CardContent className={classes.cardBody}>
          {featured ? (
            <Fragment>
              <Typography variant="h5">Featured Event</Typography>
              <Typography variant="body1">{event.Name}</Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography variant="h4">{event.Name}</Typography>
              <Fragment>
                <Box className={classes.dateDisplay}>
                  <CalendarToday fontSize="small" color="primary" />
                  <Typography variant="body2" color="primary">
                    {event?.xp?.DateDisplay}
                  </Typography>
                </Box>
                {purchased && (
                  <Typography variant="body2">
                    {`${event.xp?.SpecsOptions?.Size} - ${event.xp?.SpecsOptions?.Color} `}{" "}
                  </Typography>
                )}
              </Fragment>
              {eventPrice ? (
                <Box display="flex" justifyContent="space-between" flexDirection="column" alignItems="center">
                  <Typography className={classes.TextToAttend} gutterBottom variant="caption" color="primary">
                    <Currency quantity={eventPrice} />
                  </Typography>
                  {purchased ? (
                    <DoubleOutlinedBtn
                      buttonText="View Details"
                      buttonProps={{
                        size: "small",
                      }}
                      styleProps={{
                        marginTop: theme.spacing(2),
                        whiteSpace: "nowrap",
                        width: "calc(100% - 4px)",
                      }}
                    />
                  ) : (
                    <DoubleOutlinedBtn
                      buttonText="Register Now"
                      styleProps={{
                        width: "calc(100% - 4px)",
                      }}
                      buttonProps={{
                        size: "small",
                        variant: "outlined",
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  marginTop="auto"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography className={classes.TextToAttend} color="primary" variant="caption" gutterBottom>
                    Free to attend
                  </Typography>
                  <DoubleOutlinedBtn
                    buttonText="View Details"
                    styleProps={{
                      width: "calc(100% - 4px)",
                    }}
                    buttonProps={{
                      size: "small",
                    }}
                  />
                </Box>
              )}
            </Fragment>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default EventCard;
