import { createStyles, makeStyles, Typography, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import ArticleList from "./ArticleList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      paddingLeft: "10px",
    },
    title: {
      color: theme.palette.grey[700],
    },
    list: {
      paddingLeft: "15px",
      marginTop: "5px",
      marginBottom: "5px",
    },
    bulletList: {
      marginBottom: "2px",
    },
  })
);

interface CareAdviceProps {
  articles: any[];
}
const CareAdviceInformation: FunctionComponent<CareAdviceProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="overline" className={classes.title}>
        Resource Hub
      </Typography>
      <ArticleList articles={props.articles}></ArticleList>
    </div>
  );
};

export default CareAdviceInformation;
