import { Container, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { Asset, Entry } from "contentful";
import { Category } from "ordercloud-javascript-sdk";
import { Fragment, useContext, useEffect, useState } from "react";
import { StaticPageContext } from "../../../providers/contentful";
import { neutral } from "../../../themes/colors";
import BachmansCarousel from "../../Shared/BachmansCarousel";
import { PageTitles } from "../StaticPageConstants";
import OnSaleItem from "./OnSaleItem";
import OnSaleHeader from "./OnSaleHeader";
import FullWidthContentBlock from "../../Shared/Content/FullWidthContentBlock";
import { setBreadcrumbs } from "../../../redux/slices/breadcrumbs";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import productService from "../../../services/product.service";

export interface DynamicElement {
  backgroundImage?: Asset;
  buttonText?: string;
  disclaimer?: string;
  link?: string;
  style?: any;
  subtext: string;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    onSaleGridItem: {
      display: "flex",
    },
    greyBackground: {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      backgroundColor: neutral.grey_background,
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(4),
      },
    },
    contentContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    onSaleContainer: {
      marginTop: theme.spacing(4),
    },
  })
);

const OnSaleThisWeek: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const staticPages = useContext(StaticPageContext);
  const [onSaleCategory, setOnSaleCategory] = useState<Category>();
  const [onSalePage, setOnSalePage] = useState<Entry<any>>();
  const [onSaleProducts, setOnSaleProducts] = useState<any[][]>();

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        visible: true,
        links: [],
        current: "On Sale This Week",
      })
    );
    (async function () {
      let onSale = await productService.ListProducts({ onSale: true });
      setOnSaleProducts(onSale.hits);
    })();
  }, [dispatch]);

  useEffect(() => {
    if (staticPages && staticPages.ready) {
      const page = staticPages?.data?.find((p) => p.fields.slug === PageTitles.OnSaleThisWeek.slug);
      setOnSalePage(page);
      const cat: Category = {
        Name: page?.fields.title,
        xp: {
          Contentful: {
            Images: {
              Banner: {
                url: page?.fields?.bannerImage?.fields?.file?.url,
              },
            },
          },
        },
      };
      setOnSaleCategory(cat);
    }
  }, [staticPages]);

  return (
    <Fragment>
      <OnSaleHeader category={onSaleCategory} />
      <Container className={classes.onSaleContainer}>
        <Grid container spacing={3}>
          {onSalePage?.fields?.dynamicContentDisplay?.map((content: Entry<DynamicElement>) => (
            <Grid className={classes.onSaleGridItem} item xs={12} sm={3} key={content.sys.id}>
              <OnSaleItem item={content} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <div className={classes.greyBackground}>
        {onSaleProducts && onSaleProducts.length && (
          <Container>
            <BachmansCarousel
              variant="product"
              carouselData={onSaleProducts.slice(0, 8)}
              carouselTitle="Products On Sale This Week"
            ></BachmansCarousel>
          </Container>
        )}
      </div>
      <Container className={classes.contentContainer}>
        {onSalePage?.fields?.complexContent?.fields && (
          <FullWidthContentBlock fullWidthContent={onSalePage?.fields?.complexContent?.fields} />
        )}
      </Container>
    </Fragment>
  );
};

export default OnSaleThisWeek;
