import {
  Container,
  createStyles,
  FormControl,
  Grid,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { AppDispatch } from "../../redux/store";
import stringService from "../../services/string.service";
import { neutral } from "../../themes/colors";
import Link from "../Shared/Link";
import NotFound from "../Shared/NotFound";
import AddressBook from "./AddressBook";
import CreditCards from "./CreditCards/CreditCards";
import Events from "./Events";
import OrderHistory from "./OrderHistory/OrderHistory";
import Profile from "./Profile";
import PurplePerks from "./PurplePerks";
import Wishlist from "./Wishlist";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      margin: theme.spacing(3, 0),
    },
    navigation: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: neutral.offwhite_bg,
      padding: 0,
    },
    link: {
      padding: theme.spacing(0.5, 2),
      cursor: "pointer",
      pointerEvents: "fill",
      height: "100%", //INFO: routing is handled on the <Link> instead of the <ListItem> so we need to fill the parent so the entire item is clickable.
      width: "100%",
    },

    activeLink: {
      padding: theme.spacing(0.5, 2),
      cursor: "pointer",
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      pointerEvents: "fill",
    },
    fullWidth: {
      width: "100%",
    },
  })
);

const baseBreadcrumbs = {
  visible: true,
  dark: false,
  links: [
    {
      path: "/account/profile",
      label: "My Account",
    },
  ],
};

export const accountLinks = [
  {
    path: "profile",
    label: "Profile",
  },
  {
    path: "orders",
    label: "Orders",
  },
  {
    path: "wishlist",
    label: "Wish List",
  },
  {
    path: "address-book",
    label: "Address Book",
  },

  {
    path: "events",
    label: "Events",
  },
  {
    path: "payment-methods",
    label: "Payment Methods",
  },
  {
    path: "purple-perks",
    label: "Purple Perks",
  },
];

const Account: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteMatch<{ page: string }>();
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const currentLink = accountLinks.find((l) => l.path === params.page);
    dispatch(
      setBreadcrumbs({
        ...baseBreadcrumbs,
        current: currentLink ? currentLink.label : "Not Found",
      })
    );
  }, [params.page, dispatch]);

  const innerContent = useMemo(() => {
    switch (params.page) {
      case "profile": {
        return <Profile />;
      }
      case "orders": {
        return <OrderHistory />;
      }
      case "events": {
        return <Events />;
      }
      case "wishlist": {
        return <Wishlist />;
      }
      case "address-book": {
        return <AddressBook />;
      }
      case "payment-methods": {
        return <CreditCards />;
      }
      case "purple-perks": {
        return <PurplePerks />;
      }
      default: {
        return <NotFound />;
      }
    }
  }, [params.page]);

  const handleSelectChange = (event: React.ChangeEvent<any>) => history.push(`/account/${event.target.value}`);

  return (
    <Container maxWidth="lg">
      <Typography variant="h1" className={classes.pageHeader}>
        {`My Account - ${
          params.page === "wishlist" ? "Wish List" : stringService.CapitalizeString(params.page.replace("-", " "))
        }`}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          {isMobile ? (
            <div>
              <FormControl className={classes.fullWidth}>
                <Select
                  onChange={handleSelectChange}
                  value={params.page}
                  fullWidth={true}
                  variant="outlined"
                  margin="dense"
                  IconComponent={() => <ExpandMore />}
                >
                  {accountLinks.map((l, i) => (
                    <MenuItem value={l.path}>{l.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <List className={classes.navigation}>
              {accountLinks.map((l, i) => (
                <ListItem button disableGutters={true} key={i}>
                  <Link
                    className={params.page === l.path ? classes.activeLink : classes.link}
                    to={`/account/${l.path}`}
                    variant="body1"
                    color="textPrimary"
                    display="block"
                    underline="none"
                  >
                    {l.label}
                  </Link>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {innerContent}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Account;
