import React, { Fragment, FunctionComponent, useState } from "react";
import {
  createStyles,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { AuthDialogTab } from "./AuthDialog";
import UserInputForm from "../Shared/UserInputForm";

interface RegisterProps {
  onTabChange: (newTab: AuthDialogTab) => (e: React.MouseEvent) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      marginBottom: theme.spacing(2),
    },
  })
);

const Register: FunctionComponent<RegisterProps> = (props) => {
  const classes = useStyles();
  const { onTabChange, onClose } = props;
  const [registerError, setRegisterError] = useState<any>();
  const [conflictError, setConflictError] = useState<boolean>(false);

  const onError = (err?: any) => {
    if (err?.response?.data?.message.includes("409")) {
      setRegisterError("An account already exists with the email entered.");
      setConflictError(true);
    } else {
      setRegisterError("An Error Occurred. Please try again");
      setConflictError(false);
    }
    setRegisterError(err?.response?.status);
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography variant="h1">Create an Account</Typography>
      </DialogTitle>
      <DialogContent>
        {registerError && (
          <div className={classes.error}>
            {conflictError ? (
              <Fragment>
                <Typography variant="body1" color="error">
                  An account already exists with the email entered.
                </Typography>
                <Link href="#" onClick={onTabChange("login")}>
                  Click here to log in.
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Typography variant="body1" color="error">
                  An Error Occurred. Please try again
                </Typography>
              </Fragment>
            )}
          </div>
        )}
        <UserInputForm type="register" onComplete={onClose} onError={onError}></UserInputForm>
        <DialogContentText style={{ marginTop: 16 }}>
          {`Already have an account? `}
          <Link href="#" onClick={onTabChange("login")}>
            Log in
          </Link>
        </DialogContentText>
      </DialogContent>
    </Fragment>
  );
};

export default Register;
