import React, { FunctionComponent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { neutral } from "../../../themes/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(0.5),
      textTransform: "capitalize",
    },
    subTitle: {
      color: neutral.text_white_bg,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        maxWidth: "50%",
      },
    },
    productPaddingBottom: { paddingBottom: theme.spacing(4) },
    gridItem: {
      paddingLeft: `${theme.spacing(2)}px !important`,
      paddingRight: `${theme.spacing(2)}px !important`,
      overflow: "hidden",
      "& .slick-track": {
        gap: 0 + " !important", // need to reset the gap property when only showing 1 carousel card
      },
    },
    // .featureCard is targeting "Get Inspired, Stay Informed" Homepage Carousel Cards only
    featuredCard: {
      "& .slick-slider": {
        display: "flex",
        flexFlow: "column nowrap",
        height: "100%",
        "& .slick-list": {
          display: "flex",
          height: "100%",
        },
      },
    },
  })
);

interface ContentRowProps {
  title: string;
  subTitle?: string;
}

const ContentRow: FunctionComponent<ContentRowProps> = (props) => {
  const classes = useStyles();
  const { title, subTitle } = props;

  return (
    <Grid container spacing={3} className={classes.productPaddingBottom}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {subTitle && (
          <Typography variant="body1" className={classes.subTitle}>
            {subTitle}
          </Typography>
        )}
      </Grid>
      <Grid item md={4} className={classes.gridItem}>
        {(Array.isArray(props.children) ? props.children[0] : props.children) || null}
      </Grid>
      <Grid item md={4}>
        {(Array.isArray(props.children) ? props.children[1] : props.children) || null}
      </Grid>
      <Grid item md={4} className={`${classes.gridItem} ${classes.featuredCard}`}>
        {(Array.isArray(props.children) ? props.children[2] : props.children) || null}
      </Grid>
    </Grid>
  );
};

export default ContentRow;
