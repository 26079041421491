import React, { useContext } from "react";
import { Switch, Route, useLocation, useHistory, RouteComponentProps } from "react-router-dom";
import Home from "../Home/Home";
import Catalog from "../Catalog/Catalog";
import ProductDetail from "../Product/ProductDetail";
import Account from "../Account/Account";
import StyleGuide from "../../themes/StyleGuide";
import NotFound from "../Shared/NotFound";
import { SessionContext } from "../../providers/session";
import StoreDetail from "../StoreLocator/StoreDetail";
import SearchResultsContainer from "../Search/SearchResultsContainer";
import StoreLocator from "../StoreLocator";
import EventsLanding from "../Events";
import EventList from "../Events/EventList";
import EventCalendar from "../Events/EventCalendar";
import EventDetail from "../Events/EventDetail/index";
import axios from "axios";
import StaticPages from "../StaticPages/index";
import OnSaleThisWeek from "../StaticPages/OnSaleThisWeek";
import GiftCard from "../Product/GiftCard";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { initOrder } from "../../redux/slices/order";
import Cart from "../Cart";
import Checkout from "../Checkout";
import OrderConfirmation from "../Checkout/OrderConfirmation";
import usePageTracking from "../../components/Shared/hooks/usePageViews";
import categoryService from "../../services/category.service";
import ProductNotFound from "../Product/ProductNotFound";
import ResetPassword from "../Authentication/ResetPassword";
import seoService from "../../services/seo-service";
import $ from "jquery";
import { HomeContentContext } from "../../providers/contentful";
import BachmansMiddleware, { RedirectsShape } from "../Shared/BachmansMiddleware";
import PayPalScriptProvider from "../../providers/paypalScript";

const MainContent = () => {
  usePageTracking();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const history = useHistory();
  const { anonymous, setAnonTokens } = useContext(SessionContext);
  const homeContent = useContext(HomeContentContext);

  axios.interceptors.response.use(undefined, async (error) => {
    if (error.response.status === 401) {
      if (setAnonTokens) {
        //TODO: update logic when OC api returns refresh token for profiled users;
        let token = await setAnonTokens();
        error.config.headers["Authorization"] = "Bearer " + token;
        error.config.baseURL = undefined;
        return axios.request(error.config);
      }
    }
    return Promise.reject(error);
  });

  useEffect(() => {
    dispatch(initOrder());
  }, [dispatch]);

  const MapRedirectsToArray = (data: RedirectsShape) => {
    return Object.entries(data).map((value) => {
      return {
        slug: value[0],
        destination: value[1],
      };
    });
  };

  useEffect(() => {
    if (location.pathname.includes("category")) {
      const pathArray = location.pathname.split("/");
      const id = pathArray[2];
      const urlToRoute = categoryService.BuildCategoryUrlFromId(id, "c");
      history.push(urlToRoute);
    } else if (location.pathname.includes("/product/")) {
      const urlToRoute = location.pathname.replace("/product/", "/p/");
      history.push(urlToRoute);
    } else if (location.pathname.includes("/p/G0000GIFTCARD")) {
      history.push("/c/gift-cards");
    }

    //Check for redirects from Admin Portal
    BachmansMiddleware.getRedirects().then((response) => {
      var redirects = MapRedirectsToArray(response.data);
      if (redirects && redirects.length > 0) {
        var currentRelPath = `${location.pathname.trim()}${location.search.trim()}`;
        var redirect = redirects.find((item) => item.slug.toLowerCase().trim() === currentRelPath.toLowerCase().trim());
        if (redirect && redirect.slug.toLowerCase().trim() !== redirect.destination.toLowerCase().trim()) {
          history.push(redirect.destination);
        }
      }
    });
    //Check for redirects from Admin Portal

    //Check Validation for updatedFilteredFacets from sessionStorage
    var updatedFilteredFacets = sessionStorage.getItem("updatedFilteredFacets");
    if (updatedFilteredFacets !== null) {
      var currentPathname = location.pathname.trim() as string;

      if ((currentPathname.match(/-events/g) || []).length === 1) {
        sessionStorage.removeItem("updatedFilteredFacets");
      }
    }
    //Check Validation for updatedFilteredFacets from sessionStorage

    //Start - Updating Dynamic Seo Elements Data
    const pageSeoKeyId = getPageKeyContext();
    if (pageSeoKeyId !== "Category" && pageSeoKeyId !== "Product" && pageSeoKeyId !== "Services") {
      //console.log(pageSeoKeyId);
      seoService.SetSeoData(pageSeoKeyId, homeContent);
    }
    //End - Updating Dynamic Seo Elements Data
  }, [history, location, homeContent]);

  function getPageKeyContext() {
    const currentPathname = window.location.pathname.toLowerCase().trim();
    var isForCareAdviceAndInfoPages = currentPathname.includes("/information/");
    var isForSearchResultsPage = currentPathname === "/search";
    var isForCategoryPages = currentPathname.includes("/c/") || currentPathname.includes("/category/");
    var isForProductPages = currentPathname.includes("/p/") || currentPathname.includes("/product/");
    var isForServicesPages = currentPathname.includes("/services");
    var isForStoreLocatorPage = currentPathname === "/store-locator";
    var isForEventCalendarPage = currentPathname === "/event-calendar";
    var isForPlantLibrary = currentPathname.includes("plant-tree-and-shrub-library");
    var isFor404Pages =
      currentPathname.includes("/item-not-found/") || ($("#page-not-found-h1") && $("#page-not-found-h1").length === 1);

    if (isForCareAdviceAndInfoPages && !isForPlantLibrary) {
      return "Care, Advice and Info";
    } else if (isForSearchResultsPage) {
      return "Search Result";
    } else if (isForCategoryPages) {
      return "Category";
    } else if (isForProductPages) {
      return "Product";
    } else if (isForServicesPages) {
      return "Services";
    } else if (isForStoreLocatorPage) {
      return "Store Locator";
    } else if (isForEventCalendarPage) {
      return "Event Calendar";
    } else if (isForPlantLibrary) {
      return "Plant Library";
    } else if (isFor404Pages) {
      return "404";
    } else {
      return "Home Page";
    }
  }

  return (
    <Switch>
      <Route path="/style-guide" exact component={StyleGuide} />
      <Route path="/workshops-and-events" exact component={EventsLanding}></Route>
      <Route path="/event-calendar" exact component={EventCalendar}></Route>
      <Route path="/c/gift-cards" exact component={GiftCard}></Route>
      <Route path="/cart" exact component={Cart}></Route>
      <Route
        path="/e/:partOne/:partTwo?"
        render={(props) => <EventList {...props} isContainer={true}></EventList>} // using render because if we use component "you provide an inline function to the component attribute, you would create a new component every render."
      ></Route>
      <Route path="/event/:eventCode" component={EventDetail}></Route>
      <Route path="/store-locator" exact component={StoreLocator} />
      <Route path="/store-locator/:storeId" exact component={StoreDetail} />
      <Route path="/c/:partOne/:partTwo?" component={Catalog}></Route>
      <Route path="/p/:productCode/:productName?" component={ProductDetail}></Route>
      <Route path="/item-not-found/:productCode" component={ProductNotFound}></Route>
      <Route path="/search" component={SearchResultsContainer}></Route>
      <Route path="/orderConfirmation/:userID/:orderID" exact component={OrderConfirmation}></Route>
      {!anonymous && <Route path="/account/:page" component={Account} />}
      <Route path="/customer-service/on-sale-this-week" exact component={OnSaleThisWeek}></Route>
      <Route path="/:section/:rootSlug/:branchSlug?/:leafSlug?" component={StaticPages}></Route>

      <Route
        path="/checkout"
        exact
        render={(props: RouteComponentProps) => (
          <PayPalScriptProvider>
            <Checkout {...props} />
          </PayPalScriptProvider>
        )}
      ></Route>

      <Route path="/reset-password" exact component={ResetPassword}></Route>
      <Route path="/" exact component={Home} />
      <Route path="/storefront" exact component={Home} />
      <Route path="*" component={NotFound}></Route>
    </Switch>
  );
};

export default MainContent;
