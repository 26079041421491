import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { PurplePerksContext } from "../../providers/contentful";
import { createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";

interface PurplePerksCardProps {
  type: "footer" | "stacked";
}

const useStyle = (type: "footer" | "stacked") =>
  makeStyles((theme: Theme) =>
    createStyles(
      type === "footer"
        ? {
            root: {
              width: "80%",
              height: 100,
              position: "absolute",
              top: -50,
              left: "50%",
              transform: "translateX(-50%)",
              padding: theme.spacing(0, 2, 0, 1),
              [theme.breakpoints.up("md")]: {
                width: "auto",
                padding: theme.spacing(0, 8, 0, 7),
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },

            ppImg: {
              "& img": {
                maxWidth: 90,
              },
              margin: theme.spacing(0.5, 1, 0, 0),
            },
            ppText: {
              fontSize: theme.typography.h3.fontSize,
            },
          }
        : {
            root: {
              padding: theme.spacing(1.5),
              margin: theme.spacing(1),
              boxShadow: "none",
              border: `3px solid ${theme.palette.primary.main}`,
              outline: `1px solid ${theme.palette.grey[300]}`,
              outlineOffset: theme.spacing(1),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            ppImg: {
              "& img": {
                maxWidth: 75,
              },
              margin: theme.spacing(0.5, 1, 0, 0),
            },
            ppText: {
              fontSize: theme.typography.h4.fontSize,
              fontWeight: theme.typography.fontWeightRegular,
            },
          }
    )
  );

const PurplePerksCard: FunctionComponent<PurplePerksCardProps> = ({ type }) => {
  const classes = useStyle(type)();
  const purplePerks = useContext(PurplePerksContext);
  const [preBoldText, setPreBoldText] = useState<string>();
  const [postBoldText, setPostBoldText] = useState<string>();
  const [boldText, setBoldText] = useState<string>();

  useEffect(() => {
    if (purplePerks?.data?.fields?.title) {
      const title = purplePerks?.data?.fields?.title;
      setPreBoldText(title.substring(0, title.indexOf("|")));
      setBoldText(title.substring(title.indexOf("|") + 1, title.lastIndexOf("|")));
      setPostBoldText(title.substring(title.lastIndexOf("|") + 1, title.length));
    }
  }, [purplePerks]);

  return (
    <Paper className={classes.root} elevation={3} square>
      <figure className={classes.ppImg}>
        <img alt="purple perks badge" src={purplePerks.data?.fields.image?.fields.file.url}></img>
      </figure>
      <Typography className={classes.ppText} component="p" variant="h1" color="inherit">
        {preBoldText}
        <strong>{boldText}</strong>
        {postBoldText}
      </Typography>
    </Paper>
  );
};

export default PurplePerksCard;
