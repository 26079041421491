import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from "react";
import {
  createStyles,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  alpha,
} from "@material-ui/core";

import { ChevronLeft, ChevronRightSharp, Close } from "@material-ui/icons";
import { neutral, purple } from "../../../../themes/colors";
import { CatalogContext } from "../../../../providers/catalog";
import CategoryService from "../../../../services/category.service";
import { Category, RequiredDeep } from "ordercloud-javascript-sdk";
import MobileMenuSubCategory from "../MegaMenu/MobileMenuSubCategory";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

interface MobileMegaMenuProps {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const useStyle = (displayMobileSubCategoryState?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      mobileMenu: {
        "& > .MuiDrawer-paperAnchorLeft": {
          height: "100%",
          width: "100%",
          backgroundColor: displayMobileSubCategoryState ? neutral.offwhite_bg : purple.inline,
        },
      },
      mobileMenuBottom: {
        boxShadow: `0px -2px 10px rgba(0,0,0,0.2)`,
        position: "fixed",
        bottom: 0,
        display: "flex",
        "& > div": {
          flex: "1 0 0",
        },
        alignItems: "center",
        background: neutral.offwhite_bg,
        width: "100%",
      },
      closeButton: {
        borderRadius: "0",
        background: purple.regular,
        color: neutral.offwhite_bg,
        "&:hover": {
          color: purple.regular,
        },
      },
      mobileMenuTitle: {
        margin: theme.spacing(0, 0, 0, 3),
        "& > .MuiTypography-h1": {
          fontSize: "21px",
          color: neutral.text_colored_bg,
        },
      },
      topCatList: {
        margin: theme.spacing(2, 0, 0, 0),
      },
      backToTopCategories: {
        margin: theme.spacing(0, 0, 1.5, 0),
      },
      categoryTitleWrapper: {
        alignItems: "stretch",
        color: theme.palette.grey[800],
        "&:nth-last-of-type(3)": {
          borderBottom: `1px solid ${theme.palette.primary.light}`,
          paddingBottom: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },
        "& > div:first-of-type": {
          display: "flex",
          alignItems: "center",
        },
      },
      categoryTitle: {
        width: "50%",
        flexGrow: 1,
        margin: "0",
        "& > .MuiTypography-h5": {
          fontSize: "19px",
        },
      },
      categoryIconButton: {
        display: "flex",
        justifyContent: "flex-end",
        flexGrow: 0,
        padding: 0,
        color: "inherit",
      },
      viewRelatedArticleListItem: {
        margin: theme.spacing(3, 0, 0, 0),
      },
      viewRelatedArticlesWrapper: {
        backgroundColor: alpha(purple.inline, 0.4),
        display: "flex",
        width: "100%",
        alignContent: "center",
      },
      viewRelatedArticles: {
        flex: "1 0 0",
        margin: theme.spacing(2, 0, 1, 3),
        fontSize: "1rem !important",
        textDecoration: "underline",
        color: purple.regular,
      },
    })
  );
//TODO: Refactor so that it could use MobileDrawer in shared folder
const MobileMegaMenu: FunctionComponent<MobileMegaMenuProps> = (props) => {
  const [displayMobileSubCategoryState, setMobileDisplaySubCategoryState] = useState(false);
  const classes = useStyle(displayMobileSubCategoryState)();
  const { menuOpen, toggleMenu } = props;
  const { categories } = useContext(CatalogContext);
  const [menuTitle, setMenuTitle] = useState("Close Menu"); // bachmans products || l1 category name
  const [l2Categories, setL2Categories] = useState<Category[]>([]);
  const [selectedCat, setSelectedCat] = useState<Category>();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  let history = useHistory();

  useEffect(() => {
    setMobileDisplaySubCategoryState(false);
  }, [menuOpen]);

  const handleCatSelect = useCallback(
    (category: Category, showSubCat = false) => () => {
      if (categories && showSubCat) {
        setL2Categories(
          categories.filter((c: Category) => {
            return c.ParentID === category.ID;
          })
        );
        setSelectedCat(category);
        setMobileDisplaySubCategoryState(true);
        if (category.Name) setMenuTitle(category.Name);
      }
      if (category.ID && !showSubCat) {
        history.push(`${CategoryService.BuildCategoryUrlFromId(category.ID, "c")}`);
        toggleMenu();
      }
    },
    [categories, history, toggleMenu]
  );

  const handleOnSaleSelect = useCallback(
    () => () => {
      history.push("/customer-service/on-sale-this-week");
      toggleMenu();
    },
    [history, toggleMenu]
  );

  const handleArticlesSelect = useCallback(() => {
    history.push(`/information/resource-hub${selectedCat ? `?catID=${selectedCat.ID}` : null}`);
    toggleMenu();
  }, [toggleMenu, history, selectedCat]);

  const handleExpandClick = useCallback((selectedIndex: number) => {
    setExpandedIndex((currentState) => (currentState === selectedIndex ? -1 : selectedIndex));
  }, []);

  /// Templates ////

  const topCatLinks = useMemo(() => {
    if (!categories) return [];
    let topCats = categories.filter((c: Category) => !c.ParentID).filter((c: Category) => c.ID !== "WorkshopsEvents");

    return (
      <List className={classes.topCatList}>
        {topCats.map((cat: RequiredDeep<Category>, index: number) => (
          <ListItem button key={index} className={classes.categoryTitleWrapper}>
            <ListItemText
              className={classes.categoryTitle}
              onClick={handleCatSelect(cat)}
              primary={cat.Name}
              primaryTypographyProps={{ variant: "h5" }}
            />
            {cat.Name !== "Gift Cards" && (
              <IconButton
                aria-label={cat.Name}
                onClick={handleCatSelect(cat, true)}
                className={classes.categoryIconButton}
              >
                <ChevronRightSharp fontSize="large" />
              </IconButton>
            )}
          </ListItem>
        ))}
        <ListItem button className={classes.categoryTitleWrapper}>
          <ListItemText
            onClick={handleOnSaleSelect()}
            className={classes.categoryTitle}
            primary={"On Sale"}
            primaryTypographyProps={{ variant: "h5" }}
          ></ListItemText>
        </ListItem>
      </List>
    );
  }, [
    categories,
    handleCatSelect,
    handleOnSaleSelect,
    classes.categoryTitle,
    classes.categoryIconButton,
    classes.categoryTitleWrapper,
    classes.topCatList,
  ]);

  const mobileSubCats = useMemo(() => {
    if (!categories || l2Categories.length === 0) return [];
    return (
      <List>
        <ListItem
          button
          onClick={() => {
            setMobileDisplaySubCategoryState(false);
            setMenuTitle("Close Menu");
          }}
          className={classes.backToTopCategories}
        >
          <IconButton aria-label="back">
            <ChevronLeft fontSize="large" />
          </IconButton>
          <ListItemText
            primary={"View All Bachman's Products"}
            primaryTypographyProps={{ variant: "subtitle1" }}
          ></ListItemText>
        </ListItem>
        {l2Categories.map((subcat: Category, index: number) => (
          <MobileMenuSubCategory
            key={index}
            category={subcat}
            categoryClickCallbak={handleCatSelect}
            expandedIndex={expandedIndex}
            expandCallback={handleExpandClick}
            index={index}
          />
        ))}

        {selectedCat?.xp?.Articles?.length > 0 && (
          <ListItem button className={classes.viewRelatedArticleListItem} onClick={handleArticlesSelect}>
            <div className={classes.viewRelatedArticlesWrapper}>
              <Typography className={classes.viewRelatedArticles} variant={"subtitle1"}>
                {"View Related Articles"}
              </Typography>
              <IconButton>
                <ChevronRightSharp color="primary" fontSize="large"></ChevronRightSharp>
              </IconButton>
            </div>
          </ListItem>
        )}
      </List>
    );
  }, [
    categories,
    l2Categories,
    expandedIndex,
    handleExpandClick,
    handleCatSelect,
    handleArticlesSelect,
    selectedCat?.xp?.Articles,
    classes.backToTopCategories,
    classes.viewRelatedArticles,
    classes.viewRelatedArticlesWrapper,
    classes.viewRelatedArticleListItem,
  ]);

  return (
    <Drawer classes={{ root: classes.mobileMenu }} anchor={"left"} open={menuOpen} onClose={toggleMenu}>
      <div>
        {displayMobileSubCategoryState ? mobileSubCats : topCatLinks}
        <div className={classes.mobileMenuBottom}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleMenu}>
            <Close fontSize={"large"} />
          </IconButton>
          <div className={classes.mobileMenuTitle}>
            <Typography variant="h1">Close Menu</Typography>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileMegaMenu;
