import { CardContent, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Asset, Entry } from "contentful";
import { useCallback, useMemo } from "react";
import { CardRoot } from "../Shared/BachmansCard";
import DynamicCarousel from "./DynamicCarousel";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import { useHistory } from "react-router-dom";

interface DynamicContentProps {
  content?: Entry<any>[];
}

interface DynamicContentType {
  id?: string;
  image?: string;
  title?: string;
  description?: string;
  disclaimer?: string;
  style?: any;
  buttonText?: string;
  carouselImages?: Asset[];
  linkUrl?: string;
  action?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomSpace: {
      marginBottom: theme.spacing(2),
    },
    carouselBox: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    DynamicContentCard: {
      "& .MuiCard-root": {
        flexFlow: "column nowrap",
      },
    },
  })
);

const DynamicContent: React.FunctionComponent<DynamicContentProps> = (props) => {
  const { content } = props;
  const history = useHistory();
  const classes = useStyles();

  const getPageImage = (page: any) => {
    var seoImage = page.fields.seo.image;
    var bannerImage = page.fields.bannerImage;
    return seoImage ? seoImage : bannerImage ? bannerImage.fields.file.url : null;
  };

  const mapStaticPage = useCallback(
    (page: Entry<any>): DynamicContentType => ({
      id: page.sys.id,
      image: getPageImage(page),
      title: page.fields.title,
      description: page.fields.shortDescription,
      action: function () {
        var url = `/${page.fields.section}`;
        if (page.fields.parentPage) {
          url += `/${page.fields.parentPage.fields.slug}`;
        }

        var staticUrl = (url + `/${page.fields.slug}`).toLowerCase().trim();
        history.push(staticUrl);
      },
    }),
    [history]
  );

  const mapPromotion = useCallback(
    (promo: Entry<any>): DynamicContentType => ({
      id: promo.sys.id,
      image: promo.fields.backgroundImage ? promo.fields.backgroundImage.fields.file.url : null,
      title: promo.fields.title,
      description: promo.fields.subtext,
      disclaimer: promo.fields.disclaimer,
      buttonText: promo.fields.buttonText,
      style: promo.fields.style,
      linkUrl: !String(promo.fields.link).startsWith("https://www.")
        ? String(
            "https://www." + String(promo.fields.link).replace("https:", "").replace("//", "").replace("www.", "")
          ).trim()
        : String(promo.fields.link).trim(),
      action: function () {
        if (!promo.fields.link) {
          return;
        }

        if (promo.fields.link[0] === "/") {
          return (window.location.href = promo.fields.link);
        } else {
          var promoUrl = !String(promo.fields.link).startsWith("https://www.")
            ? String(
                "https://www." + String(promo.fields.link).replace("https:", "").replace("//", "").replace("www.", "")
              ).trim()
            : String(promo.fields.link).trim();
          return window.open(promoUrl, "_blank");
        }
      },
    }),
    []
  );

  const mapCarousel = useCallback(
    (modal: Entry<any>): DynamicContentType => ({
      id: modal.sys.id,
      carouselImages: modal.fields.images,
      title: modal.fields.title,
      description: modal.fields.description,
    }),
    []
  );

  const contentItems = useMemo(() => {
    return content?.map((item: Entry<any>) => {
      switch (item.sys.contentType.sys.id) {
        case "staticPage":
          return mapStaticPage(item);
        case "promotion":
          return mapPromotion(item);
        case "collageModal":
          return mapCarousel(item);
        default:
          return {};
      }
    });
  }, [content, mapStaticPage, mapPromotion, mapCarousel]);

  const currentPathname = window.location.pathname.toLowerCase().trim();
  const isInServicesPages =
    currentPathname.includes("/services/") &&
    (currentPathname.includes("/residential-services") || currentPathname.includes("/business-services"));

  return (
    <div>
      <Grid container spacing={3}>
        {contentItems?.map((item: DynamicContentType, index) =>
          item.carouselImages && item.carouselImages?.length ? (
            <Grid key={index} item xs={12}>
              <DynamicCarousel content={item} />
            </Grid>
          ) : (
            <Grid className={classes.DynamicContentCard} key={index} item xs={12} sm={isInServicesPages ? 12 : 4}>
              <DynamicContentCard content={item} />
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
};

export interface DynamicContentCardProps {
  content: DynamicContentType;
}

const DynamicContentCard: React.FunctionComponent<DynamicContentCardProps> = (props) => {
  const { content } = props;
  const classes = useStyles();
  const currentPathname = window.location.pathname.toLowerCase().trim();
  const isInServicesPages =
    currentPathname.includes("/services/") &&
    (currentPathname.includes("/residential-services") || currentPathname.includes("/business-services"));

  if (isInServicesPages) {
    return (
      <div className={"dynamicCardRootItem"} onClick={content.action}>
        <div className="MuiGrid-grid-xs-12 MuiGrid-grid-lg-4">
          <img src={content.image} alt={content?.title} />
        </div>
        <div className="MuiGrid-grid-xs-12 MuiGrid-grid-lg-8">
          <Typography variant="h2" color="textSecondary" className={classes.bottomSpace}>
            {content?.title}
          </Typography>
          <Typography variant="body1" className={content?.disclaimer || content.buttonText ? classes.bottomSpace : ""}>
            {content?.description}
          </Typography>
          {content?.disclaimer && <Typography variant="body2">{`* ${content.disclaimer}`}</Typography>}
          {content.buttonText && (
            <DoubleOutlinedBtn
              buttonText={content.buttonText}
              dataUrl={content.linkUrl}
              styleProps={{
                width: "75%",
                padding: "4px 16px",
              }}
              buttonProps={{
                variant: "outlined",
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <CardRoot imageUrl={content.image} onClick={content.action}>
        <CardContent>
          <Typography variant="h2" color="textSecondary" className={classes.bottomSpace}>
            {content?.title}
          </Typography>
          <Typography variant="body1" className={content?.disclaimer || content.buttonText ? classes.bottomSpace : ""}>
            {content?.description}
          </Typography>
          {content?.disclaimer && <Typography variant="body2">{`* ${content.disclaimer}`}</Typography>}
          {content.buttonText && (
            <DoubleOutlinedBtn
              buttonText={content.buttonText}
              dataUrl={content.linkUrl}
              styleProps={{
                width: "75%",
                padding: "4px 16px",
              }}
              buttonProps={{
                variant: "outlined",
              }}
            />
          )}
        </CardContent>
      </CardRoot>
    );
  }
};

export default DynamicContent;
