import React, { Fragment, FunctionComponent, useMemo } from "react";
import { Box, CircularProgress, createStyles, Divider, makeStyles, Grid, Theme, Typography } from "@material-ui/core";
import { PrintOutlined } from "@material-ui/icons";
import { CompletedOrder } from "../../../models/order";
import OrderShipment from "./OrderShipment";
import { BachmansShipmentWithLineItems } from "../../../models/shipment";
import bachDateTimeService from "../../../services/bachDateTime.service";
import Currency from "react-currency-formatter";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import PrintOrderConfirmation from "../../Checkout/PrintOrderConfirmation";
import { Order } from "ordercloud-javascript-sdk";
import ReactToPrint from "react-to-print";
import deliveryService from "../../../services/delivery.service";
import { sumBy } from "lodash";

interface OrderDetailProps {
  order: CompletedOrder;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1",
    },
    summary: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(0.25),
      marginTop: theme.spacing(0.25),
    },
    summaryContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      width: 225,
    },
  })
);

const OrderDetail: FunctionComponent<OrderDetailProps> = (props) => {
  const classes = useStyle();
  const { order } = props;
  const componentRef = React.useRef(null);

  const totalHandling = useMemo(() => {
    return sumBy(order.Shipments, (s) => s?.xp?.HandlingCharges || 0);
  }, [order.Shipments]);

  const totalPicking = useMemo(() => {
    const pickupShipments = order.Shipments?.filter((s) => deliveryService.IsPickUp(s.Shipper));
    return sumBy(
      pickupShipments,
      (s) => (s.Cost || 0) - (s?.xp?.HandlingCharges || 0) - (s?.xp?.WiredServiceFees || 0)
    );
  }, [order.Shipments]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div>
        <DoubleOutlinedBtn
          buttonText="Print"
          buttonProps={{ startIcon: <PrintOutlined />, variant: "outlined", size: "small", color: "primary" }}
        />
      </div>
    );
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  type paymentTypeInfo = "spendingAccount" | "payment";
  const paymentType = (key: string, payment: any, type: paymentTypeInfo, index: number) => {
    let text = "";

    switch (key) {
      case "GiftCard":
        text = "Gift Card";
        break;
      case "PurplePerks":
        text = "Purple Perks";
        break;
      case "Paypal":
        text = `PayPal`;
        break;
      case "Venmo":
        text = `Venmo`;
        break;
      case "CreditCard":
        switch (payment.xp.Type) {
          case "Paypal":
            text = "PayPal";
            break;
          case "Venmo":
            text = "Venmo";
            break;
          default:
            text =
              type === "spendingAccount"
                ? ` ${payment.CardType} ending in ${payment.PartialCardNumber}`
                : `${payment.xp.CardType} ending in ${payment.xp.CardNumber}`;
            break;
        }
        break;
      case "BachmansCharge":
        text = "Bachman's Charge";
        break;
      case "PurchaseOrder":
        if (payment.xp.Type === "GiftCard") text = "Gift Card";
        if (payment.xp.Type === "PurplePerks") text = "Purple Perks";
        if (payment.xp.Type === "Paypal") text = "PayPal";
        if (payment.xp.Type === "Venmo") text = "Venmo";
        break;
      case "SpendingAccount":
        text = "Bachman's Charge";
        break;
    }
    return (
      <Typography key={`${type}-${index}`} variant="h5">
        {text}
      </Typography>
    );
  };

  return order ? (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" mb={3}>
        <div>
          <Typography variant="h2">{`Order #${order.ID}`}</Typography>
          <Typography variant="body1" color="textSecondary">{`Placed on ${bachDateTimeService.GetMediumDate(
            order.DateSubmitted || ""
          )}`}</Typography>
        </div>
        <ReactToPrint trigger={reactToPrintTrigger} content={reactToPrintContent} />
        <Box displayPrint="block" display="none">
          {order && order.Shipments && (
            <div ref={componentRef}>
              <PrintOrderConfirmation order={order as Order} shipments={order.Shipments} />
            </div>
          )}
        </Box>
      </Box>
      {order.Shipments &&
        order.Shipments.map((shipment: BachmansShipmentWithLineItems, index: number) => (
          <OrderShipment key={index} order={order} shipment={shipment} />
        ))}

      {/* Order Summary */}
      <Grid container className={classes.summaryContainer}>
        {order?.Shipments && order?.Shipments.length > 1 && (
          <Box className={classes.summary}>
            <Typography variant="body2">ORDER SUBTOTAL</Typography>
            <Typography variant="body2" color="primary">
              <Currency quantity={order.Subtotal || 0} />
            </Typography>
          </Box>
        )}

        {order?.Shipments && order?.Shipments.length > 1 && totalPicking > 0 && (
          <Box className={classes.summary}>
            <Typography variant="body2">PICKING FEE</Typography>
            <Typography variant="body2" color="primary">
              <Currency quantity={totalPicking} />
            </Typography>
          </Box>
        )}

        {order?.Shipments && order?.Shipments.length > 1 && totalHandling > 0 && (
          <Box className={classes.summary}>
            <Typography variant="body2">HANDLING FEE</Typography>
            <Typography variant="body2" color="primary">
              <Currency quantity={totalHandling} />
            </Typography>
          </Box>
        )}

        {order?.Shipments && order?.Shipments.length > 1 && (
          <Box className={classes.summary}>
            <Typography variant="body2">DELIVERY</Typography>
            <Typography variant="body2" color="primary">
              <Currency quantity={(order?.ShippingCost || 0) - totalPicking - totalHandling} />
            </Typography>
          </Box>
        )}

        <Box className={classes.summary}>
          <Typography variant="body2">TAX</Typography>
          <Typography variant="body2" color="primary">
            <Currency quantity={order.TaxCost || 0} />
          </Typography>
        </Box>
        <Box className={classes.summary}>
          <Typography variant="body2">
            <strong>TOTAL</strong>
          </Typography>
          <Typography variant="body2" color="primary">
            <strong>
              <Currency quantity={order.Total || 0} />
            </strong>
          </Typography>
        </Box>
      </Grid>

      <Box my={3}>
        <Divider light />
      </Box>

      {order.xp?.SpendingAccounts
        ? Object.entries(order.xp?.SpendingAccounts).map(([key, payment], i) => {
            return (
              <Box>
                {paymentType(key, payment, "spendingAccount", i)}
                {payment.Amount}
              </Box>
            );
          })
        : order.Payments?.map((payment, i) => {
            return (
              <Fragment>
                <Typography variant="body2" color="textSecondary">
                  {paymentType(payment.Type, payment, "payment", i)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <Currency quantity={payment.Amount || 0} />
                </Typography>
              </Fragment>
            );
          })}
    </div>
  ) : (
    <CircularProgress />
  );
};

export default OrderDetail;
