import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Box, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Entry } from "contentful";
import ReactMarkdown from "react-markdown";
import DynamicContent from "./DynamicContent";
import { PageTitles } from "./StaticPageConstants";
import PlantLibrary from "./PlantLibrary";
import EmploymentList from "./EmploymentList";
import Faq from "./Faq";
import gfm from "remark-gfm";
import SocialLinks from "./SocialLinks";
import { neutral } from "../../themes/colors";
import { StaticPageContext } from "../../providers/contentful";
import { find } from "lodash";
import { useParams } from "react-router-dom";
import { StaticPageUrlParams } from ".";
import ContactUs from "./ContactUs";
import PurplePerksFull from "../Shared/PurplePerksFull";
import GenericStaticPageSidebar from "./GenericStaticPageSidebar";
import GiftCardBalanceCheck from "./GiftCardBalance";

interface GenericStaticPageProps {
  page: Entry<any>;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    jumbotron: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      alignItems: "flex-end",
      gap: theme.spacing(2),
      height: "20vh",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(1.5),
      "& div": {
        flexShrink: 0,
        alignSelf: "flex-end",
      },
    },
    socialMenuOnly: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      marginBottom: theme.spacing(3),
      "& .MuiTypography-root": {
        color: theme.palette.text.secondary,
        textShadow: "unset",
        marginRight: "auto",
      },
    },
    jumbotronTitle: {
      color: neutral.offwhite_bg,
      textShadow: ".5px .5px 2px rgba(0,0,0,.85)",
      fontWeight: 500,
      display: "flex",
      flexFlow: "row wrap",
      alignSelf: "flex-start",
      fontSize: "clamp(.75em, 5vw, 32px)",
    },
    mainContent: {
      "& p, li": {
        fontSize: 14,
      },
      "& img": {
        width: "100%",
        objectFit: "cover",
        height: "auto",
        display: "block",
      },
      "& a": {
        color: theme.palette.primary.main,
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
        },
      },
    },
    mainContentNotFAQ: {
      "& p:first-child:not(li>p)": {
        marginBottom: 24,
        fontSize: 18,
        fontFamily:
          "'Roboto Slab', -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontWeight: "300",
        color: theme.palette.grey[700],
      },
      "& a": {
        color: theme.palette.primary.main,
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
        },
      },
    },

    locationTitle: {
      textTransform: "uppercase",
    },
    address: {
      margin: theme.spacing(1, 0),
    },
    closeButton: {
      top: "8px",
      right: "8px",
      position: "absolute",
    },
    dialogPaper: {
      maxWidth: "100%",
      width: 1200,
      maxHeight: "700px",
    },
    faqWrapper: {
      marginBottom: "20px",
    },
  })
);

interface PageData {
  page?: any;
  parentPage?: any;
  root?: any;
}

const GenericStaticPage: React.FunctionComponent<GenericStaticPageProps> = (props) => {
  const { page } = props;
  const classes = useStyle();
  const staticPages = useContext(StaticPageContext);
  const [pageData, setPageData] = useState<PageData>();
  const { rootSlug } = useParams<StaticPageUrlParams>();
  const [hasSidebar, setHasSidebar] = useState<boolean>(false);

  const sideBarContent = useMemo(() => {
    if (pageData) {
      const { page, parentPage, root } = pageData;
      if (page.fields.title === PageTitles.GiftCards.title) {
        setHasSidebar(true);
        return <GiftCardBalanceCheck />;
      }
      const sidebarContact =
        page?.fields?.sidebarContact || parentPage?.fields?.sidebarContact || root?.fields?.sidebarContact;
      const departments = page?.fields?.departments || parentPage?.fields?.departments || root?.fields?.departments;
      if (departments || sidebarContact) {
        setHasSidebar(true);
        const content = {
          fields: {
            departments: departments,
            sidebarContact: sidebarContact,
          },
        };
        return <GenericStaticPageSidebar content={content} page={pageData.page} />;
      } else {
        setHasSidebar(false);
      }
    }
  }, [pageData]);

  useEffect(() => {
    const getParentPage = (child: any) => {
      if (!child) return;
      return child.fields.parentPage
        ? find(staticPages?.data, (p) => {
            return p.sys.id === child.fields.parentPage.sys.id;
          })
        : null;
    };

    if (staticPages.ready && page) {
      const pages: PageData = {
        page: page,
        parentPage: getParentPage(page),
      };
      if (pages.parentPage) {
        pages.root = getParentPage(pages.parentPage);
      }
      setPageData(pages);
    }
  }, [page, staticPages?.data, staticPages.ready]);

  return (
    <Fragment>
      <Box
        marginBottom={2}
        className={page.fields?.bannerImage?.fields?.file?.url ? classes.jumbotron : classes.socialMenuOnly}
        style={{ backgroundImage: `url("${page.fields?.bannerImage?.fields?.file?.url}")` }}
      >
        {/* <SocialLinks seoInfo={page?.fields?.seo} /> */}
        {/* Default Heading */}
        {page.fields.title !== PageTitles.Employment.title && (
          <Typography variant="h1" className={classes.jumbotronTitle}>
            {page.fields.title}
          </Typography>
        )}
        {/* Employment Heading */}
        {!["customer-service"].includes(page?.fields?.section) && page.fields.title === PageTitles.Employment.title && (
          <Typography variant="h1" className={classes.jumbotronTitle}>
            Employment Opportunities at Bachman's
          </Typography>
        )}
      </Box>
      {rootSlug === "contact-us" ? (
        <ContactUs hideForm={true} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={hasSidebar ? 8 : 12} className={classes.mainContent}>
            {page.fields.slug === PageTitles.PurplePerks.slug && (
              <Box mt={2} mb={4}>
                <PurplePerksFull displayTitle={false} />
              </Box>
            )}

            <div className={classes.mainContentNotFAQ}>
              <ReactMarkdown escapeHtml={false} plugins={[[gfm]]} children={page.fields.content} />
            </div>
            {page.fields.faq && page.fields.faq.length > 0 && (
              <Fragment>
                <div className={classes.faqWrapper}>
                  <Faq
                    data={page.fields.faq}
                    headline={page.fields.title === "Frequently Asked Questions" ? null : "Frequently Asked Questions"}
                  />
                </div>
              </Fragment>
            )}
            <DynamicContent content={page.fields.dynamicContentDisplay} />
            {page.fields.title === PageTitles.PlantLibrary.title && <PlantLibrary />}
            {page.fields.title === PageTitles.Employment.title && <EmploymentList />}
          </Grid>
          {hasSidebar && (
            <Grid item xs={12} md={4} lg={4}>
              {sideBarContent}
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
};

export default GenericStaticPage;
