import {
  ButtonBase,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
} from "@material-ui/core";
import { Entry } from "contentful";
import { cloneDeep, each, filter, find, reverse, sortBy } from "lodash";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { ArticlesContext } from "../../../providers/contentful";
import FullWidthContentBlock from "../../Shared/Content/FullWidthContentBlock";
import { ComplexContentModel } from "../../../models/contentful/ComplexContent";
import moment from "moment";
import { StaticPageQueryParams } from "..";
import BachmansLoading from "../../Shared/BachmansLoading";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import { CatalogContext } from "../../../providers/catalog";
import algolia from "../../../services/algolia.service";
import noImg from "../../../assets/images/noimg.jpg";
import BachmansCardMedia from "../../Shared/BachmansCardMedia";
import appConstants from "../../../constants/app.constants";
import { Tokens } from "ordercloud-javascript-sdk";
import { SystemUpdateSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conentfulContainer: {
      marginTop: theme.spacing(4),
    },
    onSaleGridItem: {
      display: "flex",
      padding: "0 12px",
      marginBottom: "12px",
      alignItems: "stretch",
    },
    container: {
      display: "block",
      width: "100%",
    },
    root: {
      display: "flex",
      flexFlow: "column nowrap",
      borderRadius: "0",
      border: 0,
      transition: "box-shadow .5s, transform .5s",
      width: "100%",
      position: "relative",
      "&:hover, &:active, &:focus": {
        boxShadow: theme.shadows[1],
        transform: "translateY(-2px)",
      },
      cursor: "pointer",
    },
    CardContent: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(1),
      flexGrow: 1,
    },
    articleCard: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    articleTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      transition: theme.transitions.create(["all"], {
        duration: theme.transitions.duration.shorter,
      }),
      "&:hover, &:focus": {
        textDecoration: "underline",
        color: theme.palette.primary.dark,
      },
    },
    flexGrow0: {
      flexGrow: 0,
      whiteSpace: "nowrap",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(4),
      },
    },
    customBox: {
      // display: "-webkit-box",
      // boxOrient: "vertical",
      // lineClamp: 3,
      //wordBreak: "break-word",
      overflow: "hidden",
    },
    contentTag: {
      fontSize: 12,
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.44)",
      letterSpacing: "0.5px",
    },
    filteredarticle: {
      position: "absolute",
      left: "10px",
      top: "10px",
      backgroundColor: "#9262B8",
      color: "#fff",
      padding: "4px 8px",
    },
    textTrasnformNormal: {
      textTransform: "unset",
    },
  })
);

export interface CareAdviceListProps {
  queryParams?: StaticPageQueryParams;
  pageSize?: number;
  contentList?: any[];
  sortState: string;
  updateTotal?: (total?: number) => void;
  articleSelect: (slug: string, info?: any) => void;
  searchResults?: any[];
}

export interface ContentfulOptions {
  query: string;
  searchOptions: any;
  search?: string;
}

const CareAdviceList: React.FunctionComponent<CareAdviceListProps> = (props) => {
  const { queryParams, contentList, updateTotal, articleSelect, pageSize, sortState, searchResults } = props;
  const classes = useStyles();
  const articleContent = useContext(ArticlesContext);
  const { categories } = useContext(CatalogContext);
  const [articlesInView, setArticlesInView] = useState<any>();
  const [featurearticlesInView, setfeaturearticlesInView] = useState<any>();
  const [algoliaData, setalgoliaData] = useState<Entry<any>[]>();
  const [loading, setLoading] = useState(false);
  const [filterInitiated, setfilterInitiated] = useState(false);
  const [sortFilterRetain, setsortFilterRetain] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  function buildContentfulOptions(filters?: any): ContentfulOptions {
    let query: string = ``;

    if (filters) {
      if (filters.search) query = filters.search;
    }
    let searchOptions: any = {
      contenttype: "article",
    };
    return {
      query: query,
      searchOptions: searchOptions,
    };
  }
  const currentPage = queryParams?.page || 1;
  let start = (currentPage - 1) * 20;
  const finishArticles = (items?: any[], total?: number) => {
    setfilterInitiated(false);
    if (currentPage > 1 && queryParams?.search) start = 0; //pagination for search : contentful service already segments array of response so we need to skip the slice when page is greater than 1
    const articlesToSet = items?.length ? items : items?.slice(start, start + (pageSize || 50)) || [];
    setArticlesInView(articlesToSet);
    if (updateTotal) {
      updateTotal(total || items?.length || 0);
    }
  };

  const getUsers = async (pageNum?: number, tags?: any) => {
    let sortedavailabledata;

    if (filterInitiated == false) {
      const sortIndex = algolia.getAlgoliaContentIndex(queryParams?.search);
      let index = algolia.getProductIndex(sortIndex);
      index.setSettings({
        attributesForFaceting: [
          "tags", // or 'filterOnly(categories)' for filtering purposes only
          "contenttype", // or 'filterOnly(store)' for filtering purposes only
        ],
      });

      const options = buildContentfulOptions(queryParams);
      let actualquery = queryParams?.search;

      if (tags !== undefined) {
        let pageNumber;
        if (isNaN(pageNum as any)) {
          pageNumber = 0;
        } else {
          pageNumber = pageNum;
        }
        let searchOptions: any = {
          clickAnalytics: true,
          filters: "contenttype:article",
          facetFilters: [["tags:" + tags + ""]],
          query: options?.query,
          page: pageNumber,
          hitsPerPage: 200,
        };
        const [res] = await Promise.all([index.search(actualquery as any, searchOptions)]);
        sortedavailabledata = res?.hits as any;
        if (sortState === "Oldest to Newest" && sortedavailabledata) {
          sortedavailabledata.sort(function compare(a: any, b: any) {
            var dateA: any = new Date(a.updatedAt);
            var dateB: any = new Date(b.updatedAt);
            return dateA - dateB;
          });
        } else if (sortState === "Newest to Oldest" && sortedavailabledata) {
          sortedavailabledata.sort(function compare(a: any, b: any) {
            var dateA: any = new Date(a.updatedAt);
            var dateB: any = new Date(b.updatedAt);
            return dateB - dateA;
          });
        } else if (sortState === "Title A-Z" && sortedavailabledata) {
          sortedavailabledata = sortBy(sortedavailabledata, ["title"]);
        } else if (sortState === "Title Z-A" && sortedavailabledata) {
          sortedavailabledata = sortBy(sortedavailabledata, ["title"]).reverse();
        }
        //sortedavailabledata = sortBy(sortedavailabledata, ["title"]);
        setalgoliaData(sortedavailabledata as any);
        setArticlesInView(sortedavailabledata as any);
        // finishArticles(sortedavailabledata, res?.nbHits);  // commenting this due to its nature of overriding the same call from setArticlesInView BI-456
        setLoading(false);
        return await res;
      } else {
        let searchOptions: any = {
          clickAnalytics: true,
          filters: "contenttype:article",
          query: options?.query,
          page: pageNum,
          hitsPerPage: 200,
        };

        const [res] = await Promise.all([index.search(actualquery as any, searchOptions)]);
        sortedavailabledata = res?.hits as any;
        if (sortState === "Oldest to Newest" && sortedavailabledata) {
          sortedavailabledata.sort(function compare(a: any, b: any) {
            var dateA: any = new Date(a.updatedAt);
            var dateB: any = new Date(b.updatedAt);
            return dateA - dateB;
          });
        } else if (sortState === "Newest to Oldest" && sortedavailabledata) {
          sortedavailabledata.sort(function compare(a: any, b: any) {
            var dateA: any = new Date(a.updatedAt);
            var dateB: any = new Date(b.updatedAt);
            return dateB - dateA;
          });
        } else if (sortState === "Title A-Z" && sortedavailabledata) {
          sortedavailabledata = sortBy(sortedavailabledata, ["title"]);
        } else if (sortState === "Title Z-A" && sortedavailabledata) {
          sortedavailabledata = sortBy(sortedavailabledata, ["title"]).reverse();
        }
        setalgoliaData(sortedavailabledata);
        setArticlesInView(sortedavailabledata);
        fetchFeaturedArticles(sortedavailabledata);
        finishArticles(sortedavailabledata, res?.nbHits);
        setLoading(false);

        if (queryParams?.search !== undefined) {
          setfeaturearticlesInView("");
        }
        if (queryParams?.search == "") {
          fetchFeaturedArticles(sortedavailabledata);
        }
        return await res;
      }
    }
    setsortFilterRetain(true);
  };
  let featureArticleArr: any = [];
  function fetchFeaturedArticles(data: any) {
    data.map((i: any) => {
      if (i.feature == true) {
        featureArticleArr.push(i);
      }
    });
    setfeaturearticlesInView(featureArticleArr);
  }
  useEffect(() => {
    getUsers(0, "");
  }, []);

  useEffect(() => {
    let available;
    // debugger;
    available = algoliaData;
    if (sortState === "Oldest to Newest" && available) {
      available.sort(function compare(a: any, b: any) {
        var dateA: any = new Date(a.updatedAt);
        var dateB: any = new Date(b.updatedAt);
        return dateA - dateB;
      });
    } else if (sortState === "Newest to Oldest" && available) {
      available.sort(function compare(a: any, b: any) {
        var dateA: any = new Date(a.updatedAt);
        var dateB: any = new Date(b.updatedAt);
        return dateB - dateA;
      });
    } else if (sortState === "Title A-Z" && available) {
      available = sortBy(available, ["title"]);
    } else if (sortState === "Title Z-A" && available) {
      available = sortBy(available, ["title"]).reverse();
    }
    finishArticles(available);
  }, [sortState, algoliaData, sortFilterRetain]);

  useEffect(() => {
    if (queryParams?.page) {
      getUsers((queryParams?.page as number) - 1, queryParams?.tag);
    } else {
      getUsers(0, queryParams?.tag);
    }
  }, [queryParams?.tag, sortState]); // adding sortState for BI-456

  useEffect(() => {
    getUsers(0, undefined);
  }, [queryParams?.search]);

  useEffect(() => {
    if (queryParams?.tag) {
      getUsers((queryParams?.page as number) - 1, queryParams?.tag);
    } else {
      getUsers((queryParams?.page as number) - 1, "");
    }
  }, [queryParams?.page]);

  return (
    <>
      {featurearticlesInView && queryParams?.tag == undefined && (
        <Fragment>
          {loading ? (
            <BachmansLoading />
          ) : (
            featurearticlesInView?.slice(0, 3).map((article: any, index: any) => {
              return (
                <Grid className={classes.onSaleGridItem} item xs={12} sm={4} key={article.id}>
                  <Card className={classes.root} onClick={() => articleSelect(article.slug, article.fields)}>
                    <BachmansCardMedia
                      aspectRatio="1.42"
                      alt={article?.image.description}
                      image={
                        article?.image?.image && article?.image?.image !== undefined ? article?.image?.image : noImg
                      }
                    />
                    <Typography color="textSecondary" className={classes.filteredarticle} variant="body2">
                      Featured
                    </Typography>
                    <CardContent className={classes.CardContent}>
                      {article?.contenttag && (
                        <Typography className={classes.contentTag} variant="h3" gutterBottom>
                          {article?.contenttag}
                        </Typography>
                      )}
                      <Typography
                        variant="h3"
                        className={classes.textTrasnformNormal}
                        gutterBottom
                        onClick={() => articleSelect(article.slug, article.fields)}
                      >
                        {article?.title}
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {/* {`Last Updated on ${new Date(article.updatedAt).toLocaleDateString("en-US")}`} */}
                      </Typography>
                      <Typography variant="body1" gutterBottom className={classes.customBox}>
                        {article.description?.substring(0, 100)}
                      </Typography>
                      {article?.button && (
                        <DoubleOutlinedBtn
                          buttonText={article?.button}
                          buttonProps={{
                            variant: "outlined",
                            type: "button",
                            onClick: () => articleSelect(article.slug, article.fields),
                          }}
                          styleProps={{
                            padding: "4px 32px",
                            marginTop: "auto",
                          }}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          )}
        </Fragment>
      )}

      <Fragment>
        {loading ? (
          <BachmansLoading />
        ) : (
          articlesInView?.map((article: any, index: any) => {
            return featurearticlesInView == "" ||
              queryParams?.tag !== undefined ||
              article.feature == false ||
              article.feature == undefined ? (
              <Grid className={classes.onSaleGridItem} item xs={12} sm={4} key={article.id}>
                <Card className={classes.root} onClick={() => articleSelect(article.slug, article.fields)}>
                  <BachmansCardMedia
                    aspectRatio="1.42"
                    alt={article?.image?.description}
                    image={article?.image?.image && article?.image?.image !== undefined ? article?.image?.image : noImg}
                  />
                  <CardContent className={classes.CardContent}>
                    {article?.contenttag && (
                      <Typography className={classes.contentTag} variant="h3" gutterBottom>
                        {article?.contenttag}
                      </Typography>
                    )}
                    <Typography
                      className={classes.textTrasnformNormal}
                      variant="h3"
                      gutterBottom
                      onClick={() => articleSelect(article.slug, article.fields)}
                    >
                      {article?.title}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {/* {`Last Updated on ${new Date(article.updatedAt).toLocaleDateString("en-US")}`} */}
                    </Typography>
                    <Typography variant="body1" gutterBottom className={classes.customBox}>
                      {article.description?.substring(0, 100)}
                    </Typography>
                    {article?.button && (
                      <DoubleOutlinedBtn
                        buttonText={article?.button}
                        buttonProps={{
                          variant: "outlined",
                          type: "button",
                          onClick: () => articleSelect(article.slug, article.fields),
                        }}
                        styleProps={{
                          padding: "4px 32px",
                          marginTop: "auto",
                        }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <></>
            );
          })
        )}
      </Fragment>
    </>
  );
};

export default CareAdviceList;
