import { alpha, Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent, useMemo } from "react";
import Slider from "react-slick";
import ImageSlide from "../../models/contentful/imageSlide";
import Link from "../Shared/Link";
import { omit } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .slick-slider": {
        height: "auto",
      },
      overflow: "hidden",
      "& .slick-slide:not(.slick-active)": {
        pointerEvents: "none",
      },
      "& .slick-dots": {
        bottom: "18%",
        [theme.breakpoints.up("lg")]: {
          bottom: "12%",
        },
        "& li": {
          margin: 0,
          background: "rgba(0,0,0,0.4)",
          "&.slick-active button:before": {
            color: theme.palette.primary.dark,
            opacity: 1,
          },
          "& button:before": {
            opacity: 0.75,
            fontSize: 12,
            color: theme.palette.common.white,
          },
        },
      },
    },
    slide: {
      height: "60vh",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        height: 550,
      },
    },
    image: {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    content: {
      height: "100%",
      position: "relative",
    },
    slidePromotion: {
      position: "absolute",
      top: "40%",
      left: theme.spacing(2),
      right: theme.spacing(2),
      transform: "translateY(-50%)",
      textAlign: "center",
      background: alpha(theme.palette.primary.main, 0.9),
      padding: theme.spacing(5),
      color: theme.palette.common.white,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        width: 400,
        top: "50%",
        left: theme.spacing(15),
        right: "auto",
      },
      [theme.breakpoints.up("lg")]: {
        width: 450,
      },
    },

    slideButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(3),
      padding: theme.spacing(0.75, 8),
      color: theme.palette.common.white,
      fontSize: 16,
      textTransform: "uppercase",
      whiteSpace: "pre", // INFO: Prevents button text from breaking on very small viewports (ex: iphone 5).
      maxWidth: "100%", // INFO: As great as the above declaration is, don't let the button expand more than 100%.
      border: `2px solid ${theme.palette.common.white}`,
      outline: `1px solid ${theme.palette.grey[400]}`,
      outlineOffset: 4,
      transition: theme.transitions.create("all"),
      "&:hover, &:focus, &:active": {
        textDecoration: "none",
        outline: `1px solid ${theme.palette.grey[400]}`,
        outlineOffset: 4,
        borderWidth: 2,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.common.white,
      },
    },
    slideTitle: {
      ...theme.typography.h2,
      fontSize: 20,
    },
    slideSubTitle: {
      ...theme.typography.h1,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
    },
  })
);

interface CarouselProps {
  slides: ImageSlide[];
}

const Carousel: FunctionComponent<CarouselProps> = ({ slides }) => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const carouselSlides = useMemo(() => {
    return slides.map((s, i) => {
      let slide = {
        imageUrl: s.fields.backgroundImage.fields.file.url,
        ...omit(s.fields, "backgroundImage"),
      };
      return (
        <div key={i} className={classes.slide}>
          <div className={classes.image} style={{ backgroundImage: "url(" + slide.imageUrl + ")" }}></div>
          <Container maxWidth="lg" className={classes.content}>
            <div className={classes.slidePromotion}>
              <Typography className={classes.slideTitle}>{slide.title}</Typography>
              <Typography className={classes.slideSubTitle}>{slide.subtext}</Typography>
              <Link to={slide.link} className={classes.slideButton} variant="button">
                {slide.buttonText}
              </Link>
            </div>
          </Container>
        </div>
      );
    });
  }, [slides, classes]);

  return (
    carouselSlides && (
      <Slider {...settings} className={classes.root}>
        {carouselSlides}
      </Slider>
    )
  );
};

export default Carousel;
