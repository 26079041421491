import { Address, Category, LineItem } from "ordercloud-javascript-sdk";
import { DeliveryOption, SpecData } from "../components/Product/ProductDetail";
import productService from "./product.service"; //?? is there a better name for services?
import eventService from "./event.service";
import { BachmansEvent, BachmansProduct } from "../models/product";
import { BachmansLineItem } from "../models/lineItem";
import { PartialObjectDeep } from "../models/partialObjectDeep";
import { BuyerXp } from "../models/buyerXp";
import deliveryService from "./delivery.service";
import moment from "moment";
import { isAnyOf } from "@reduxjs/toolkit";

interface LineItemConstructParams {
  product: BachmansProduct;
  isBaseItem: boolean;
  quantity: number;
  storeID: string;
  productSpecs?: SpecData | undefined;
  delivery?: DeliveryOption;
  productNote?: string | undefined;
  shippingAddress?: Address;
  productCategory: Category | undefined;
  productImageUrl?: string;
  email?: string;
  AddressType?: string;
  currentOrder?: any;
}

const constructLI = (liInfo: LineItemConstructParams, buyerXp?: BuyerXp): LineItem => {
  const {
    product,
    isBaseItem,
    quantity,
    storeID,
    productSpecs,
    delivery,
    productNote,
    shippingAddress,
    productCategory,
    productImageUrl,
    currentOrder,
    email,
  } = liInfo;
  const regularPrice = product?.PriceSchedule?.PriceBreaks ? product?.PriceSchedule?.PriceBreaks[0]?.Price : 0;
  const retailPrice =
    product?.ID !== "GIFTCARD" &&
    product?.PriceSchedule?.xp?.Price?.RetailPrice &&
    product?.PriceSchedule?.xp?.Price?.RetailPrice !== regularPrice
      ? product.PriceSchedule.xp.Price.RetailPrice
      : undefined;
  const productImage = productService.GetProductImage(product);
  let timeWindow = "9:00 am - 6:00 pm";
  if (delivery?.date && buyerXp) {
    timeWindow = deliveryService.GetDeliveryRun(moment(delivery?.date), buyerXp)["NO PREF"].TimeSlot;
  }

  var AddressType;
  if (delivery?.type == "Email") {
    AddressType = "Email";
  } else if (currentOrder && currentOrder.shipments.length) {
    currentOrder?.shipments.map((item: any, index: any) => {
      if (item.LineItems[0].xp.DeliveryMethod === delivery?.type && item.LineItems[0].xp.DeliveryMethod !== null) {
        AddressType = item.LineItems[0].xp.AddressType;
      }
    });
  } else {
    AddressType = shippingAddress?.xp?.addressType;
  }

  return {
    Quantity: quantity,
    ProductID: product.ID!,
    ShipFromAddressID: storeID,
    Specs: productSpecs?.Specs,
    DateNeeded: delivery?.date || moment().format(),
    ShippingAddress: shippingAddress,
    ShippingAddressID: shippingAddress?.ID ? shippingAddress.ID : undefined,
    xp: {
      ShownImage: productImage?.url ? productImage?.url : productImageUrl,
      RetailPrice: retailPrice,
      DeliveryMethod: delivery?.type,
      ProductNote: productNote,
      CategoryID: productCategory?.ID,
      PLCMarkup: productSpecs?.PLCMarkup,
      ASMarkup: productSpecs?.ASMMarkup,
      BaseItem: isBaseItem,
      AddressType: AddressType,
      TimePreference: "NO PREF",
      TimeWindow: timeWindow,
      //CardMessage:
      //PLCMarkup:
      //ASMMarkup:
      //OverrideCodeB2:
      Amount: product.Amount, // used for gift card
      Email: liInfo.email,
    },
  };
};

const duplicateLineItem = (lineItem: LineItem): LineItem => {
  const item = constructLI({
    product: lineItem.Product as BachmansProduct,
    isBaseItem: false,
    quantity: lineItem.Quantity,
    storeID: lineItem.ShipFromAddressID || "",
    productSpecs: {
      Specs: lineItem.Specs || [],
      ASMMarkup: lineItem.xp?.ASMarkup,
      PLCMarkup: lineItem.xp?.PLCMarkup,
    },
    delivery: { date: lineItem.DateNeeded, type: lineItem.xp?.DeliveryMethod },
    productNote: lineItem?.xp?.ProductNote || "",
    productCategory: { ID: lineItem.xp?.CategoryID },
    productImageUrl: lineItem.xp?.ShownImage,
  });
  item.xp.TimePreference = lineItem.xp?.TimePreference || "NO PREF";
  item.xp.TimeWindow = lineItem.xp?.TimeWindow;
  item.xp.Amount = lineItem.xp?.Amount;
  item.xp.RouteCode = lineItem.xp?.RouteCode;
  return item;
};

interface EventLIConstructParams {
  selectedEvent: BachmansEvent;
  defaultEvent: BachmansEvent;
  quantity: number;
  shippingAddress?: Address;
  sendEventEmail: boolean;
  email: string;
  primaryEvent?: any;
}

const constructEventLI = (liInfo: EventLIConstructParams): LineItem => {
  const { selectedEvent, quantity, defaultEvent, primaryEvent } = liInfo;
  let dateNeeded = eventService.GetDateNeeded(selectedEvent);
  return {
    ProductID: selectedEvent.ID!,
    Quantity: quantity,
    DateNeeded: dateNeeded,
    ShippingAddress: liInfo.shippingAddress || selectedEvent.xp?.Location,
    xp: {
      EventName: defaultEvent.xp?.WebFacingProductTitle || selectedEvent.Name,
      EventDescription: selectedEvent.Description + (selectedEvent.xp?.DescroptionOverflow || ""),
      EventRoom: selectedEvent.xp?.Room,
      EventDateDisplay: selectedEvent.xp?.DateDisplay,
      ShownImage:
        defaultEvent.xp?.Contentful?.Images && defaultEvent.xp?.Contentful?.Images?.length
          ? defaultEvent.xp?.Contentful?.Images?.[0].url
          : primaryEvent.xp?.Contentful.Images[0].url,
      AddressType: "InStorePickUp",
      DeliveryMethod: "InStorePickUp",
      SendEventEmail: liInfo.sendEventEmail,
      Email: liInfo.email,
    },
  };
};

const constructDeliveryTypeUpdate = (
  selectedDelivery: DeliveryOption,
  shipfromID: string
): PartialObjectDeep<BachmansLineItem> => {
  return {
    ShipFromAddressID: shipfromID,
    DateNeeded: selectedDelivery.date,
    xp: {
      DeliveryMethod: selectedDelivery?.type,
    },
  };
};

const service = {
  ConstructLineItem: constructLI,
  ConstructEventLineItem: constructEventLI,
  ConstructDeliveryTypeUpdate: constructDeliveryTypeUpdate,
  DuplicateLineItem: duplicateLineItem,
};

export default service;
