import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Categories, Category, RequiredDeep } from "ordercloud-javascript-sdk";

import MegaMenuSubCategory from "./MegaMenuSubCatergory";
import MegaMenu from "./MegaMenu";
import RelatedArticles from "./RelatedArticles";
import { neutral, purple } from "../../../../themes/colors";
import Link from "../../../Shared/Link";
import useDebounce from "../../../Shared/hooks/useDebounce";
import CategoryService from "../../../../services/category.service";
import { CatalogContext } from "../../../../providers/catalog";
import { find } from "lodash";

interface TopLevelCategoriesProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "stretch",
      width: "100%",
    },
    topLevelCat: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      zIndex: theme.zIndex.appBar,
      color: neutral.text_white_bg,
    },
    subcategoriesContainer: {
      display: "flex",
      height: "100%",
      margin: 0,
      padding: theme.spacing(3, 2),
    },
    subcategories: {
      flex: "1 0 83.33%",
      columns: "200px auto",
      columnFill: "balance",
      columnRule: `solid 1px ${theme.palette.grey[300]}`,
      overflowX: "auto",
      position: "relative",

      "& .MuiList-subheader": {
        padding: "0 16px",
        pageBreakInside: "avoid",
        "& li:not(:only-child):last-child": {
          marginBottom: 16,
          pageBreakAfter: "always",
        },
        "& li:first-child": {
          paddingBottom: 0,
        },
        "& li:only-child": {
          paddingBottom: 16,
        },
      },
      "& li": {
        lineHeight: "initial",
      },
    },
    relatedArticles: {
      flex: "1 0 16.67%",
      backgroundColor: purple.light,
      "& ul": {
        display: "flex",
        flexFlow: "column nowrap",
        height: "100%",
      },
    },
    onSale: {
      color: purple.regular,
    },
  })
);

const TopLevelCategories: FunctionComponent<TopLevelCategoriesProps> = (props) => {
  const { categories } = useContext(CatalogContext);

  const containerRef = useRef<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const debouncedOpen = useDebounce(isOpen, 400, { toggle: "on" });
  const [l2Categories, setL2Categories] = useState<Category[]>([]);
  const [selectedCat, setSelectedCat] = useState<Category<any>>();
  const classes = useStyle();

  useEffect(() => {
    if (containerRef.current && categories) {
      setAnchorEl(containerRef.current);
    }
  }, [containerRef, categories]);

  const handleMenuOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onCategoryMouseEnter = useCallback(
    (categoryId: string) => async () => {
      if (["GiftCards", "OnSale"].includes(categoryId)) {
        return handleMenuClose();
      }
      if (categories) {
        setL2Categories(
          categories.filter((c: Category) => {
            return c.ParentID === categoryId;
          })
        );

        if (isOpen === false) {
          //handle scenario where hovering cat links doesn't open after hovering over giftcards && on sale
          handleMenuOpen();
        }

        var activeCategory = await Categories.Get("Bachmans", categoryId);
        setSelectedCat(activeCategory);
      }
    },
    [categories, handleMenuClose, handleMenuOpen, isOpen]
  );

  //--- TEMPLATES ---//

  const subCategories = useMemo(() => {
    if (l2Categories.length === 0) return [];
    return l2Categories.map((cat: Category, index: number) => (
      <MegaMenuSubCategory key={index} subcategory={cat} onItemClick={handleMenuClose} />
    ));
  }, [l2Categories, handleMenuClose]);

  const topCatLinks = useMemo(() => {
    if (!categories) return [];
    let topCats = categories
      .filter((c: Category) => !c.ParentID)
      .filter((c: Category) => !["WorkshopsEvents"].includes(c.ID || ""));

    return topCats.map((cat: RequiredDeep<Category>, index: number) => (
      <Link
        className={classes.topLevelCat}
        onMouseEnter={onCategoryMouseEnter(cat.ID)}
        key={index}
        variant="h5"
        color="inherit"
        to={`${CategoryService.BuildCategoryUrlFromId(cat.ID, "c")}`}
      >
        {cat.Name}
      </Link>
    ));
  }, [categories, classes.topLevelCat, onCategoryMouseEnter]);

  return !categories ? (
    <Skeleton animation="wave" width="100%" />
  ) : (
    <div className={classes.root} ref={containerRef} onMouseEnter={handleMenuOpen} onMouseLeave={handleMenuClose}>
      {topCatLinks}
      <Link
        className={`${classes.topLevelCat} ${classes.onSale}`}
        variant="h5"
        onMouseEnter={onCategoryMouseEnter("OnSale")}
        to="/customer-service/on-sale-this-week"
      >
        On Sale
      </Link>
      <MegaMenu anchorEl={anchorEl} open={debouncedOpen} onClickAway={handleMenuClose}>
        <Container maxWidth="lg" className={classes.subcategoriesContainer}>
          <div className={classes.subcategories}>{subCategories}</div>
          {selectedCat?.xp?.Articles && (
            <RelatedArticles
              className={classes.relatedArticles}
              articles={selectedCat.xp.Articles}
              categoryId={selectedCat.ID || ""}
            />
          )}
        </Container>
      </MegaMenu>
    </div>
  );
};

export default TopLevelCategories;
