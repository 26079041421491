import React, { Fragment } from "react";
import { Box, Chip, createStyles, makeStyles, SvgIcon, Theme } from "@material-ui/core";
import { BachmansProduct } from "../../../models/product";

export interface GrownExclusiveProps {
  productGroup: BachmansProduct[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGap: {
      // I really wish <Box> supported gap={3} without needing to create a wrapper component.
      gap: theme.spacing(1),
    },
    featuredFlag: {
      height: 20,
      fontSize: 10,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: 0,
      "&.MuiChip-colorSecondary": {
        "& svg.MuiChip-avatar": {
          "& path": {
            fill: theme.palette.secondary.main,
          },
        },
      },
      "& svg.MuiChip-avatar": {
        backgroundColor: "transparent",
        padding: 3,
        "& path": {
          backgroundColor: "transparent",
          fill: theme.palette.primary.main,
        },
      },
    },
  })
);

const GrownExclusive: React.FunctionComponent<GrownExclusiveProps> = (props) => {
  const { productGroup } = props;
  const classes = useStyles();
  const checkProductXP = (key: string) => {
    return productGroup.filter((p) => p && p.xp && p.xp[key]).length > 0;
  };

  const isGrown = checkProductXP("IsBachmansGrown");
  const isExclusive = checkProductXP("IsBachmansExclusive");

  return (
    <Fragment>
      {(isGrown || isExclusive) && (
        <Box
          className={classes.flexGap}
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {isGrown && (
            <Chip
              variant="outlined"
              color="secondary"
              className={classes.featuredFlag}
              avatar={
                <SvgIcon viewBox="0 0 197.66 243.23">
                  <path d="M143.83 115.13q40.69-16.71 40.7-55.3 0-16.38-8.11-28.7Q156 0 90.32 0H0v17.84q17.19 0 23.67 4.54 3.57 2.6 3.57 13.13v172.2a36.23 36.23 0 01-.81 8.27c-.54 2.28-2.22 4.41-5 6.41s-9.9 3-21.25 3v17.84h98.41q57.07 0 80.42-21.57a64 64 0 0012.9-17.11q5.75-10.62 5.75-29.59 0-45.72-53.83-59.83zm-2.42-31.92c-7.76 47-35.69 43.38-35.69 43.38s-8.37-25.21 35.69-43.38zm-52.55 111s-24.86 3.21-31.81-38.7c39.27 16.19 31.81 38.66 31.81 38.66zm6.29-45.85s-32.06 10.64-55.37-46h-.05c59.96 10.12 55.42 45.96 55.42 45.96zm5.32-118.74c27.59 49.87-1.31 67.16-1.31 67.16s-26.55-17.48 1.31-67.2zm62.29 125.84c-26.63 64.54-63.58 52.28-63.58 52.28s-5.35-40.94 63.58-52.32v-.06z" />
                </SvgIcon>
              }
              label="Bachman's-Grown"
              size="small"
            ></Chip>
          )}
          {isExclusive && (
            <Chip
              variant="outlined"
              color="primary"
              className={classes.featuredFlag}
              avatar={
                <SvgIcon viewBox="0 0 197.66 243.23">
                  <path d="M143.83 115.13q40.69-16.71 40.7-55.3 0-16.38-8.11-28.7Q156 0 90.32 0H0v17.84q17.19 0 23.67 4.54 3.57 2.6 3.57 13.13v172.2a36.23 36.23 0 01-.81 8.27c-.54 2.28-2.22 4.41-5 6.41s-9.9 3-21.25 3v17.84h98.41q57.07 0 80.42-21.57a64 64 0 0012.9-17.11q5.75-10.62 5.75-29.59 0-45.72-53.83-59.83zm-2.42-31.92c-7.76 47-35.69 43.38-35.69 43.38s-8.37-25.21 35.69-43.38zm-52.55 111s-24.86 3.21-31.81-38.7c39.27 16.19 31.81 38.66 31.81 38.66zm6.29-45.85s-32.06 10.64-55.37-46h-.05c59.96 10.12 55.42 45.96 55.42 45.96zm5.32-118.74c27.59 49.87-1.31 67.16-1.31 67.16s-26.55-17.48 1.31-67.2zm62.29 125.84c-26.63 64.54-63.58 52.28-63.58 52.28s-5.35-40.94 63.58-52.32v-.06z" />
                </SvgIcon>
              }
              label="Bachman's Exclusive"
              size="small"
            ></Chip>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default GrownExclusive;
