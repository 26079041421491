import { ApiRole } from "ordercloud-javascript-sdk";

export type ENVIRONMENT = "test" | "production";
export type ContentfulEnv = "master" | "test";

export const scope: ApiRole[] = [
  "PasswordReset",
  "Shopper",
  "BuyerReader",
  "CategoryReader",
  "AddressReader",
  "ProductReader",
  "MeAdmin",
  "MeXpAdmin",
  "MeAddressAdmin",
  "MeCreditCardAdmin",
];

export interface AppConstants {
  apiUrl: string;
  clientID: string;
  jitterbitUrl: string;
  blobStorageUrl: string;
  bachmansIntegrationsUrl: string;
  contentfulEnv: ContentfulEnv;
  algoliaIndexPrefix: string;
  name: string;
  buyerId: string;
  catalogid: string;
  anonymous: boolean;
  enableimageZoom: boolean;
  algoliaQueryIndexPrefix: string;
  paypalClientID: string;
}

const localMiddlewareUrl = "https://localhost:5001";
const useLocalMiddleware = false;

// store non sensitive application constants here

const getConfig = (env: ENVIRONMENT): AppConstants => {
  if (env === "test") {
    return {
      apiUrl: "https://sandboxapi.ordercloud.io",
      clientID: "CEE9561C-3544-4526-AA15-976900D79AB2",
      jitterbitUrl: "https://Four51TRIAL104401.jitterbit.net/Test_BachmansOnPrem",
      blobStorageUrl: "https://bachmansstorageqa.blob.core.windows.net/webdata",
      bachmansIntegrationsUrl: "https://bachmans-integrationsqa.azurewebsites.net",
      contentfulEnv: "test",
      algoliaIndexPrefix: "test_",
      algoliaQueryIndexPrefix: "",
      name: "bachmans test",
      buyerId: "Bachmans",
      catalogid: "Bachmans",
      anonymous: true,
      enableimageZoom: true,
      paypalClientID: "Aenn3GZcN5-zuGYKu8CDrxeUJ7R1BfurZfk3q4RPKqDuevoMyhXE-sMt4E1KBVt0XRd722NuvMhgcsmZ",
    };
  } else
    return {
      apiUrl: "https://api.ordercloud.io",
      clientID: "8836BE8D-710A-4D2D-98BF-EDBE7227E3BB",
      jitterbitUrl: "https://Four51TRIAL104401.jitterbit.net/BachmansOnPrem",
      blobStorageUrl: "https://bachmansstorage.blob.core.windows.net/webdata",
      bachmansIntegrationsUrl: "https://bachmans-integrations.azurewebsites.net",
      contentfulEnv: "master",
      algoliaIndexPrefix: "",
      algoliaQueryIndexPrefix: "",
      name: "bachmans production",
      buyerId: "Bachmans",
      catalogid: "Bachmans",
      anonymous: true,
      enableimageZoom: true,
      paypalClientID: "AQOTWlGhkPm7OmEqW3GigskqTrYxTCMMIgnRUcX7kqLKHxv2oku-N9UQUK5O15IGpBuqYB0c9fF8M6FM",
    };
};

const config = getConfig(process.env.REACT_APP_ENVIRONMENT as ENVIRONMENT);

if (useLocalMiddleware) {
  config.bachmansIntegrationsUrl = localMiddlewareUrl;
}
export default config;
