import algoliasearch, { SearchIndex } from "algoliasearch";
import appConstants from "../constants/app.constants";

class Algolia {
  private client: any;

  constructor() {
    this.client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP as string,
      process.env.REACT_APP_ALGOLIA_KEY as string
    );
  }

  public getSortIndex = (filters: any): string => {
    return filters && filters.query
      ? filters && filters.sort
        ? filters.sort
        : "products_name_asc"
      : filters && filters.sort
      ? filters.sort
      : "products";
  };

  public SearchResultSortIndex = (filters: any): string => {
    return filters && filters.query
      ? filters && filters.sort
        ? filters.sort
        : "dotcom_products"
      : filters && filters.sort
      ? filters.sort
      : "new_products";
  };

  public getQuickSearchProducts = (): string => {
    return "dotcom_products";
  };

  public getCategoryProducts = (): string => {
    return "products";
  };

  public getQuerySuggestionIndex = (filters?: any): string => {
    return "products_query_suggestions";
  };

  public getAlgoliaContentIndex = (filters?: any): string => {
    return "contentful_data";
  };

  public getProductIndex(sortIndex?: string): SearchIndex {
    return this.client.initIndex((appConstants.algoliaIndexPrefix || "") + sortIndex);
  }
  public getQueryIndex(sortIndex?: string): SearchIndex {
    return this.client.initIndex((appConstants.algoliaQueryIndexPrefix || "") + sortIndex);
  }
  // to Initialize Multiple Indices Query: BI-479
  public async getQueriesInit(filters?: any, options?: any): Promise<any> {
    let sortedQueries: any[] = [];
    const envPrefix = appConstants.algoliaIndexPrefix || "";
    const createQuery = [
      {
        indexName: envPrefix + this.getQuickSearchProducts(), //dotcom_products
        query: filters,
        params: {
          hitsPerPage: 40,
        },
      },
      {
        indexName: this.getQuerySuggestionIndex(), // products_query_suggestions
        query: filters,
        params: {
          clickAnalytics: true,
        },
      },
      {
        indexName: envPrefix + this.getAlgoliaContentIndex(), // contentful_data
        query: filters,
      },
      {
        indexName: envPrefix + this.getCategoryProducts(), // products
        query: filters,
      },
    ];

    createQuery.map((item) => {
      sortedQueries.push(this.client.initIndex((appConstants.algoliaQueryIndexPrefix || "") + item.indexName));
    });
    return sortedQueries;
  }

  // Performs search on multiple indices on a single instance BI-479
  public async performMultipleSearch(queryArr?: any): Promise<any> {
    let responseArray: any = [];
    try {
      const results = await this.client.multipleQueries(queryArr);
      if (results && results.results) {
        results.results.forEach((resp: any) => {
          responseArray.push(resp);
        });
      }
    } catch (error) {
      console.log("Some error: ", error);
    }
    return responseArray;
  }

  public facetToCollect = [
    "PriceRange",
    "Brand",
    "Color",
    "Attributes.Occasion",
    "Size",
    "Attributes.ColorVariant",
    "Attributes.BloomTiming",
    "Attributes.Collection",
    "Attributes.ContainerVolumes",
    "Attributes.HandlingFee",
    "Attributes.HardinessZone",
    "Attributes.HardscapesColor",
    "Attributes.HardscapesStyle",
    "Attributes.LightLevel",
    "Attributes.Material",
    "Attributes.MatureHeight",
    "Attributes.MatureWidthSpread",
    "Attributes.StyleForm",
    "CategoryId",
  ];

  public facetsToDisplay = [
    "PriceRange",
    "Brand",
    "Color",
    "Attributes.Occasion",
    "Size",
    "Attributes.ColorVariant",
    "Attributes.BloomTiming",
    "Attributes.Collection",
    "Attributes.ContainerVolumes",
    // "Attributes.HandlingFee",
    "Attributes.HardinessZone",
    "Attributes.HardscapesColor",
    "Attributes.HardscapesStyle",
    "Attributes.LightLevel",
    "Attributes.Material",
    "Attributes.MatureHeight",
    "Attributes.MatureWidthSpread",
    "Attributes.StyleForm",
  ];
}

export default new Algolia();
