import React, { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { neutral } from "../../../themes/colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { camelCase, find, isNumber, each, startCase } from "lodash";
import algoliaService from "../../../services/algolia.service";
interface FacetProps {
  facet: any;
  expandedIndices: number[];
  index: number;
  title: string;
  facetFilters: string[];
  hideFacetCheckbox?: boolean;
  expandCallback: (index: number) => void;
  facetChangeCallback: (facet: string, checked: boolean) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    header: {
      display: "flex",
      alignContent: "center",
    },
    formLabel: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.h4.fontFamily,
      margin: theme.spacing(1, 0, 1, 0),
      flex: "2 0 0",
      color: neutral.text_white_bg,
    },
    formControlLabel: {
      display: "block",
    },
    checkbox: {
      color: neutral.text_white_bg,
      "&.MuiCheckbox-root": { padding: theme.spacing(0.5, 2, 0.5, 1) },
    },
    arrow: {
      color: theme.palette.grey[500],
      margin: theme.spacing(0.5, 0, 0.5, 0),
    },
    checkboxHidden: {
      marginLeft: theme.spacing(1),
    },
  })
);

const Facet: FunctionComponent<FacetProps> = (props) => {
  const classes = useStyle();
  const {
    facet,
    expandedIndices,
    index,
    title,
    facetFilters,
    hideFacetCheckbox,
    expandCallback,
    facetChangeCallback,
  } = props;

  const handleExpandClick = useCallback(
    (index: number) => (e: React.MouseEvent) => {
      expandCallback(index);
    },
    [expandCallback]
  );

  const isFacetOptionChecked = useCallback(
    (facet: string, option: string) => {
      return !!find(facetFilters, (selectedFacet: string) => {
        //eg."Attributes.ColorVariant"
        let facetInfo = selectedFacet.split(":");
        let facetTitle = facetInfo ? facetInfo[0] : null;
        if (facetTitle?.includes("Attributes.")) facetTitle = facetTitle.replace("Attributes.", "");
        let parsedFacetOption = facetInfo ? facetInfo[1] : null;
        //using startCase to give spacing to Camel case so that it will match facet passed in. eg. "PriceRange" to "Price Range"
        if (startCase(facetTitle || "") === facet && parsedFacetOption === option) return true;
      });
    },
    [facetFilters]
  );

  const handleFacetCheck = useCallback(
    (option: string, facet: string) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      //format facet so that it maps back to the way algolia can read it. Blooming Time = Attributes.BloomingTime
      let algoliaFacetNameSelect = "";
      each(algoliaService.facetsToDisplay, (algoliaFacetName: string) => {
        let isSelectedFacet = algoliaFacetName.includes(formatFacet(facet));
        if (facet === "Color") return (algoliaFacetNameSelect = "Color");
        if (isSelectedFacet) algoliaFacetNameSelect = algoliaFacetName;
      });
      if (facet === "Type") algoliaFacetNameSelect = "Type";
      let formattedFacet = `${algoliaFacetNameSelect}:${option}`;
      isFacetOptionChecked(facet, option);
      facetChangeCallback(formattedFacet, checked);
    },
    [facetChangeCallback, isFacetOptionChecked]
  );
  function formatFacet(facet: string) {
    //eg Price Range to PriceRange
    let string = camelCase(facet); // eg. priceRange
    return (string = string.charAt(0).toUpperCase() + string.slice(1));
  }
  const compare = (a: any, b: any) => {
    const aPrice = Number(a[0].replace(/[^0-9.-]+/g, "").split("-")[0]);
    const bPrice = Number(b[0].replace(/[^0-9.-]+/g, "").split("-")[0]);
    return aPrice - bPrice;
  };

  const isExpanded = useMemo(() => {
    return isNumber(find(expandedIndices, (openIndex) => openIndex === index));
  }, [expandedIndices, index]);

  const options = useMemo(() => {
    let facetVals = Object.entries(facet);
    if (title === "Price Range") {
      facetVals = facetVals.sort(compare);
    }
    return facetVals.map(([key, value], index) => (
      <FormControlLabel
        className={classes.formControlLabel}
        key={index}
        control={
          hideFacetCheckbox ? (
            <Box className={classes.checkboxHidden} component="span" />
          ) : (
            <Checkbox
              className={classes.checkbox}
              size="small"
              checked={isFacetOptionChecked(title, key)}
              onChange={handleFacetCheck(key, title)}
            />
          )
        }
        label={`${key}: (${value})`}
      />
    ));
  }, [facet, classes, hideFacetCheckbox, title, handleFacetCheck, isFacetOptionChecked]);

  return (
    <FormGroup className={classes.root}>
      <div className={classes.header} onClick={handleExpandClick(index)}>
        <FormLabel className={classes.formLabel} component="h4">
          {title}
        </FormLabel>
        {isExpanded ? <KeyboardArrowUp className={classes.arrow} /> : <KeyboardArrowDown className={classes.arrow} />}
      </div>
      <Collapse in={isExpanded}>{options}</Collapse>
    </FormGroup>
  );
};

export default Facet;
