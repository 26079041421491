import React, { Fragment, useContext, useMemo } from "react";
import { HomeContentContext } from "../../providers/contentful";
import StackedContentBlock from "../Shared/Content/StackedContentBlock";

const HomeStackedContent: React.FunctionComponent = () => {
  const homeContent = useContext(HomeContentContext);
  const stacked = useMemo(() => {
    if (homeContent && homeContent.data?.fields.stackedContent) {
      let formattedStacked = homeContent.data?.fields.stackedContent.map((stack) => stack.fields);
      return <StackedContentBlock content={formattedStacked} />;
    }
  }, [homeContent]);

  return <Fragment>{stacked}</Fragment>;
};

export default HomeStackedContent;
