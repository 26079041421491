import React from "react";
import { Button, ButtonProps, CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyle = (styleProps: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      DoubleOutlinedBtn: {
        display: "flex",
        alignItems: "center",
        borderWidth: 2,
        outline: `1px solid ${theme.palette.grey[300]}`,
        outlineOffset: 2,
        ...styleProps,
        "&:hover, &:focus, &:active": {
          borderWidth: 2,
          outline: `1px solid ${theme.palette.grey[400]}`,
          outlineOffset: 2,
        },
      },
      buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
      },
    })
  );

interface DoubleOutlinedBtnProps {
  buttonText?: string;
  buttonProps?: ButtonProps;
  styleProps?: any;
  loading?: boolean;
  loadingText?: string;
  className?: string | null;
  dataUrl?: string;
}

export const DoubleOutlinedBtn: React.FunctionComponent<DoubleOutlinedBtnProps> = (props) => {
  const classes = useStyle(props?.styleProps)();
  const buttonProps = {
    color: props?.buttonProps?.color || "primary",
    variant: props?.buttonProps?.variant || "contained",
    dataUrl: props?.dataUrl,
    ...props.buttonProps,
  };
  return (
    <Button
      {...buttonProps}
      className={props?.className ? `${classes.DoubleOutlinedBtn} ${props?.className}` : classes.DoubleOutlinedBtn}
    >
      {props.loadingText && props.loading ? props.loadingText : props?.buttonText}
      {props?.loading && <CircularProgress className={classes.buttonProgress} size={20} />}
    </Button>
  );
};

interface DoubleOutlinedLinkBtnProps extends DoubleOutlinedBtnProps {
  href: string;
  target?: string;
}
export const DoubleOutlinedLinkBtn: React.FunctionComponent<DoubleOutlinedLinkBtnProps> = (props) => {
  const classes = useStyle(props?.styleProps)();
  return (
    <Button
      href={props.href}
      target={props.target}
      variant={props.buttonProps?.variant || "contained"}
      color={props.buttonProps?.color || "primary"}
      size={props.buttonProps?.size}
      className={classes.DoubleOutlinedBtn}
    >
      {props?.buttonText}
    </Button>
  );
};

export default DoubleOutlinedBtn;
