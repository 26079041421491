import { createStyles, Link, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingLeft: "15px",
      marginTop: "5px",
      marginBottom: "5px",
    },
    bulletList: {
      marginBottom: "2px",
    },
  })
);

interface ArticleListProps {
  articles: any[];
}

const ArticleList: FunctionComponent<ArticleListProps> = (props) => {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {props?.articles?.map((a, i) => (
        <li className={classes.bulletList} key={i}>
          <Link href={`/information/resource-hub/${a.slug}` || `#`} color="primary" variant="body1">
            {a.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ArticleList;
