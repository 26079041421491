import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Theme, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { ErrorInfo } from "react";
import svgError from "../../assets/svg/something-went-wrong.svg";
import { Link } from "react-router-dom";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";

type ErrorBoundaryProp = {
  classes: any;
};
type ErrorBoundaryState = {
  error: any;
  errorInfo: any;
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: "65vh",
      [theme.breakpoints.up("sm")]: {
        minHeight: "50vh",
        marginTop: "10vh",
      },
      display: "grid",
      placeItems: "center center",
    },
    text: {
      margin: theme.spacing(3, 0, 2, 0),
    },
    goBackLink: {
      textDecoration: "none",
    },
    errorText: {
      whiteSpace: "pre-wrap",
      fontSize: ".5rem",
      color: theme.palette.grey[700],
      border: `1px solid ${theme.palette.error.light}`,
      boxShadow: theme.shadows[1],
    },
  });

class ErrorBoundary extends React.Component<ErrorBoundaryProp, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Container className={this.props.classes.container} maxWidth="lg">
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            style={{ gap: 8 }}
          >
            <img height="200" src={svgError} alt="illustration of man inspecting a dying plant" aria-hidden="true" />
            <Box>
              <Typography className={this.props.classes.text} variant="h1">
                Something went wrong.
              </Typography>
              <Link to="/" className={this.props.classes.goBackLink}>
                <DoubleOutlinedBtn
                  buttonText="Go Back Home"
                  styleProps={{
                    margin: "24px 0",
                  }}
                  buttonProps={{
                    color: "default",
                    onClick: () => this.setState({ error: null, errorInfo: null }),
                  }}
                />
              </Link>
              <Accordion square className={this.props.classes.errorText}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                  <code style={{ fontSize: 9 }}>{this.state.error && this.state.error.toString()}</code>
                </AccordionSummary>
                <AccordionDetails>
                  <code>{this.state.errorInfo.componentStack}</code>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
