import React, { Fragment, FunctionComponent } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  Divider,
  makeStyles,
  Hidden,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { neutral } from "../../themes/colors";
import Link from "../Shared/Link";
import logoPurple from "../../assets/svg/logoPurple.svg";
import { ChevronLeft, Phone } from "@material-ui/icons";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar,
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      boxShadow: `0px 2px 10px rgba(0,0,0,0.2)`,
    },
    appBar: {
      color: neutral.text_white_bg,
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1, 3),
      },
    },
    toolbarPullRight: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",

      "& hr": {
        marginTop: theme.spacing(0.75),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        maxHeight: 25,
      },
      "& button": {
        color: neutral.text_light_bg,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.body2.fontSize,
        },
      },
      "& a": {
        color: neutral.text_light_bg,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 10,
        [theme.breakpoints.up("sm")]: {
          fontSize: 14,
        },
      },
    },
    navBrand: {
      display: "block",
      width: "100%",
      maxWidth: 70,
      [theme.breakpoints.up("sm")]: {
        maxWidth: 200,
      },
    },
    navPageTitle: {
      fontSize: "clamp(14px, 4vw, 28px)",
    },
    logo: {
      display: "block",
      width: "100%",
      height: "auto",
    },
    divider: {
      maxHeight: 30,
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },
  })
);

const CheckoutHeader: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  // const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <AppBar position="relative" elevation={0} component="header" className={classes.root}>
        <Toolbar
          className={classes.appBar}
          component={(props: any) => <Container {...props} maxWidth="lg"></Container>}
        >
          <Link to="/" className={classes.navBrand}>
            <Typography variant="srOnly" component="h1">
              Bachman's Checkout
            </Typography>
            <img src={logoPurple} alt="Bachman's Logo" className={classes.logo}></img>
          </Link>
          <Divider className={classes.divider} orientation="vertical" flexItem light />
          <Typography className={classes.navPageTitle} variant="h1" component="h2">
            Checkout
          </Typography>
          <Box className={classes.toolbarPullRight}>
            <Button onClick={() => history.push("/cart")} startIcon={<ChevronLeft fontSize="small" />} variant="text">
              Continue Shopping
            </Button>
            <Hidden smDown>
              <Divider orientation="vertical" flexItem />
              <Button href="tel:+16128617311" startIcon={<Phone fontSize="small" />} variant="text">
                612-861-7311
              </Button>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default CheckoutHeader;
