import React, { Fragment, FunctionComponent } from "react";
import { Avatar, Box, Grid, createStyles, makeStyles, Hidden, Theme, Typography } from "@material-ui/core";
import { LineItem } from "ordercloud-javascript-sdk";
import noImg from "../../assets/images/noimg.jpg";
import ProductPrice from "../Product/ProductPrice";
import Currency from "react-currency-formatter";
import productService from "../../services/product.service";
interface BachmansLineItemsProps {
  lineItems: LineItem[] | undefined;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    listItemsHeader: {
      gap: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(3),
      "& > div": {
        // PRODUCT
        "&:nth-of-type(1)": {
          flexGrow: 0,
          marginRight: "auto",
        },
        // QUANTITY
        "&:nth-of-type(3)": {
          display: "flex",
          flex: "0 0 65px",
          paddingLeft: 0,
          paddingRight: 0,
        },
        // TOTAL
        "&:last-of-type": {
          whiteSpace: "nowrap",
          flex: "0 0 65px",
        },
      },
      textTransform: "uppercase",
    },
    listItemsBody: {
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        paddingTop: theme.spacing(2),
        alignItems: "center",
      },
      "&>div": {
        "&:nth-of-type(1)": {
          flexGrow: 0,
        },
        "&:nth-of-type(3)": {
          [theme.breakpoints.down("sm")]: {
            marginLeft: "auto",
          },
          display: "flex",
        },
        "&:nth-of-type(4)": {
          whiteSpace: "nowrap",
          flex: "0 0 65px",
        },
        "&:last-of-type": {
          whiteSpace: "nowrap",
          flex: "0 0 65px",
        },
      },
    },
    lineItemText: {
      fontFamily: theme.typography.h2.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.2,
    },
    imgAvatar: {
      height: 50,
      width: 50,
    },
  })
);

const BachmansLineItems: FunctionComponent<BachmansLineItemsProps> = (props) => {
  const classes = useStyle();
  const { lineItems } = props;
  return lineItems ? (
    <Box marginTop={3}>
      <Hidden smDown>
        <Grid container className={classes.listItemsHeader}>
          <Grid item xs>
            <Typography variant="body2" color="textSecondary">
              Product
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" color="textSecondary">
              Price
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" color="textSecondary">
              Quantity
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" color="textSecondary">
              Total
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      {lineItems &&
        lineItems.map((li: LineItem, i: number) => (
          <Grid key={i} container className={classes.listItemsBody}>
            <Grid item xs>
              <Avatar
                className={classes.imgAvatar}
                variant="square"
                src={li.xp?.ShownImage ? li.xp?.ShownImage : noImg}
              />
            </Grid>
            <Grid item xs>
              <Box display="flex" flexDirection="column" flexWrap="wrap">
                <Typography variant="body1" className={classes.lineItemText}>
                  {productService.GetLineItemName(li)}
                </Typography>
                <Box display="flex" alignItems="center" flexWrap="wrap" style={{ gap: 8 }}>
                  <Typography title="lineItemIDCart" color="textSecondary" variant="body2">
                    Item # {li?.Product?.ID}
                  </Typography>
                  {li.Product?.xp?.SpecsOptions?.Color && !li.Product?.xp?.IsWorkShopEvent && (
                    <Fragment>
                      |
                      <Typography title="lineItemIDCart" color="textSecondary" variant="body2">
                        Color: {li.Product?.xp?.SpecsOptions?.Color}
                      </Typography>
                    </Fragment>
                  )}

                  {li.Product?.xp?.SpecsOptions?.Size && (
                    <Fragment>
                      |
                      <Typography title="lineItemIDCart" color="textSecondary" variant="body2">
                        {li.Product?.xp?.IsWorkShopEvent ? "Event Time:" : "Type:"} {li.Product?.xp?.SpecsOptions?.Size}
                      </Typography>
                    </Fragment>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <ProductPrice lineItem={li} />
            </Grid>
            <Grid item xs>
              <Typography variant="h4" color="primary" className={classes.lineItemText}>
                x {li.Quantity}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4" color="primary" className={classes.lineItemText}>
                <Currency quantity={li.LineTotal || 0} />
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Box>
  ) : null;
};

export default BachmansLineItems;
