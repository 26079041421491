import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import SearchSummary from "../Search/SearchSummary";
import { useLocation, useRouteMatch } from "react-router-dom";
import { searchType } from "../../models/search";
import searchService from "../../services/search.service";
import ProductList from "../Product/ProductList/ProductList";
import EventList from "../Events/EventList";
import CareAdviceInfoList from "../Articles/CareAdviceInfoList";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import NotFound from "../Shared/NotFound";
import stringService from "../../services/string.service";
import { isEmpty } from "lodash";

interface SearchResultsContainerProps {}
interface searchLocationState {
  products: any;
  events: any;
  information: any;
  articles: any;
  active: searchType;
}
export interface SearchResultsMatch {
  query: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);
/**
 * takes in results passed in through the state
 * @param props
 * @returns
 */
const SearchResultsContainer: FunctionComponent<SearchResultsContainerProps> = (props) => {
  const classes = useStyle();
  const match = useRouteMatch<SearchResultsMatch>();
  const location = useLocation<searchLocationState>();

  const [products, setProducts] = useState<any>({});
  const [events, setEvents] = useState<any>({});
  const [articles, setArticles] = useState<any>([]);
  const [information, setInfo] = useState<any>([]);
  const [activeType, setActiveType] = useState<searchType>("Products");
  const [queryTerm, setQueryTerm] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleResultTypeClick = useCallback((searchType: searchType) => {
    setActiveType(searchType);
  }, []);

  useEffect(() => {
    let isMounted = true;
    const queryParams = stringService.GetQueryParamsFromLocation(location.search);
    setQueryTerm(queryParams?.query);
    if (!isEmpty(location.state)) {
      if (location.state.products) setProducts(location.state.products);
      if (location.state.events) setEvents(location.state.events);
      if (location.state.articles) setArticles(location.state.articles);
      if (location.state.information) setInfo(location.state.information);
    } else {
      setLoading(true);
      searchService.GetAllSearchResultsAsync({ query: queryParams?.query }).then((searchResults) => {
        if (isMounted) {
          setProducts(searchResults?.products);
          setEvents(searchResults?.events);
          setArticles(searchResults?.articles);
          setInfo(searchResults?.information);
          setLoading(false);
        }
      });
    }
    if (queryParams.type) setActiveType(queryParams.type as searchType);
    dispatch(setBreadcrumbs({ visible: false }));

    return () => {
      isMounted = false;
    };
  }, [location, match.params, dispatch]);

  const display = useMemo(() => {
    switch (activeType) {
      case "Products": {
        return <ProductList loading={loading}></ProductList>;
      }
      case "Events": {
        return <EventList hideTitle={true} eventList={events} isContainer={false} />;
      }
      case "CareInfo": {
        return <CareAdviceInfoList infoResults={[...information, ...articles]} />;
      }

      default: {
        return <NotFound />;
      }
    }
  }, [activeType, events, loading, articles, information]);

  return (
    <Fragment>
      <Container className={classes.root}>
        <SearchSummary
          queryTerm={queryTerm}
          products={products?.nbHits}
          events={events?.events ? events?.events?.length : 0}
          infoResults={information?.length + articles?.length}
          activeType={activeType}
          onTypeClick={handleResultTypeClick}
        />
      </Container>
      {display}
    </Fragment>
  );
};

export default SearchResultsContainer;
