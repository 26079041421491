import { createContext, FunctionComponent, useCallback, useMemo, useState } from "react";

interface ILIActionBarContext {
  isOpen: boolean;
  toggleOpen?: () => void;
}
export const LIActionBarContext = createContext<ILIActionBarContext>({ isOpen: false });

const LIActionBarProvider: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpen = useCallback(() => {
    setIsOpen((state) => !state);
  }, [setIsOpen]);

  const contextValue = useMemo(() => {
    return { isOpen, toggleOpen };
  }, [isOpen, toggleOpen]);

  return <LIActionBarContext.Provider value={contextValue}>{children}</LIActionBarContext.Provider>;
};

export default LIActionBarProvider;
