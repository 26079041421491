import React, { Fragment, FunctionComponent } from "react";
import { Container, createStyles, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import MobileFilters from "../Product/ProductList/MobileFilters";

interface ListLayoutWithFiltersProps {
  title?: React.ReactNode;
  banner?: React.ReactNode;
  actionHeader?: React.ReactNode;
  filters: React.ReactNode;
  stackedContent?: React.ReactNode;
  itemList: React.ReactNode;
  pagination: React.ReactNode;
  menuOpen: boolean;
  toggleMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    filterSidebar: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
  })
);

const ListLayoutWithFilters: FunctionComponent<ListLayoutWithFiltersProps> = (props) => {
  const classes = useStyle();
  const { title, filters, itemList, pagination, menuOpen, toggleMenu, actionHeader, stackedContent } = props;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <Fragment>
      {title}
      {actionHeader}
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item sm={3} className={classes.filterSidebar}>
            {isDesktop ? (
              <Fragment>
                {filters}
                {stackedContent}
              </Fragment>
            ) : (
              <MobileFilters menuOpen={menuOpen} toggleMenu={toggleMenu}>
                {filters ? filters : null}
              </MobileFilters>
            )}
          </Grid>
          <Grid item sm={9}>
            {itemList}
            {pagination}
            {!isDesktop && stackedContent}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default ListLayoutWithFilters;
