import { createStyles, CardContent, makeStyles, Typography, Theme, Link } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { ComplexContentModel } from "../../../models/contentful/ComplexContent";
import { CardRoot } from "../BachmansCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "0px",
      border: `1px solid ${theme.palette.grey[200]}`,
      transition: "box-shadow .5s, transform .5s",
      "&:hover, &:active, &:focus": {
        boxShadow: theme.shadows[1],
        transform: "translateY(-2px)",
      },
    },
    cardBody: {
      display: "flex",
      flexFlow: "column nowrap",
    },
  })
);

interface ContentCardProps {
  content: ComplexContentModel;
}
const ContentCard: FunctionComponent<ContentCardProps> = (props) => {
  const classes = useStyles();

  return (
    <CardRoot imageUrl={props.content?.image?.fields?.file?.url} linkUrl={props?.content?.linkUrl}>
      <CardContent className={classes.cardBody}>
        <Typography variant="h5">{props?.content?.superTitle}</Typography>
        <Typography variant="body1">{props?.content?.title}</Typography>
        {props.content.linkText && (
          <Link href={props?.content?.linkUrl} color="primary">
            {props?.content?.linkText}
          </Link>
        )}
      </CardContent>
    </CardRoot>
  );
};

export default ContentCard;
