import { alpha, Box, makeStyles, Theme, createStyles, Typography, Fade } from "@material-ui/core";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import React, { Fragment, useMemo } from "react";
import deliveryService, { DeliveryDateOptions } from "../../../services/delivery.service";
import moment from "moment";
import { DeliveryTypes } from "../../../models/buyerXp";
import { neutral } from "../../../themes/colors";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { LocalShipping, Store } from "@material-ui/icons";
import { DeliveryOption } from ".";
import { useAppSelector } from "../../../redux/store-hook";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleButtonContainer: {
      display: "flex",
      flexFlow: "row wrap",
      gap: theme.spacing(2),
      width: "100%",
      "& .MuiToggleButtonGroup-root": {
        flexGrow: 1,
        [theme.breakpoints.up("md")]: {
          maxWidth: "calc(50% - 8px)",
        },
      },
    },
    ToggleButtonBody: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexBasis: "50%",
      textAlign: "left",
      borderRadius: 0,
      padding: theme.spacing(1, 3),
      minHeight: 52,
      "&:hover, &:focus": {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1),
      },
      "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        borderColor: theme.palette.secondary.light,
        backgroundColor: alpha(theme.palette.secondary.light, 0.25),
      },
      "& .MuiToggleButton-label": {
        color: theme.palette.text.secondary,
        pointerEvents: "none",
        "& .MuiTypography-subtitle1": {
          lineHeight: 1,
        },
      },
    },
    toggleButtonMessage: {
      color: theme.palette.grey[600],
      width: "100%",
      height: 52,
      "& .MuiTypography-root": {
        fontSize: ".65rem",
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(0.5),
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "calc(50% - 8px)",
      },
    },
    nationwideAvailable: {
      // backgroundColor: alpha(theme.palette.secondary.light, .25),
      color: theme.palette.secondary.main,
    },
    flexGap1: {
      gap: theme.spacing(1),
    },
    flexGap2: {
      gap: theme.spacing(2),
    },
    wishListContainer: {
      flexBasis: "25%",
      height: 40,
      "&>button": {
        width: "100%",
        height: "100%",
      },
    },
  })
);

export type DeliveryMethods = {
  [key in DeliveryTypes]?: DeliveryMethodData;
};

export interface DeliveryMethodData {
  enabled: boolean;
  leadDays: number;
}
export interface ProductActionProps {
  deliveryMethods?: DeliveryMethods;
  deliveryDateOptions?: DeliveryDateOptions;
  product?: BuyerProduct;
  onDeliveryChange: (type: DeliveryOption) => void;
  selectedDeliveryOption?: DeliveryOption;
}

const DeliveryOptionSelect: React.FunctionComponent<ProductActionProps> = (props) => {
  const currentOrder = useAppSelector((state) => state.order);
  const { product, onDeliveryChange, selectedDeliveryOption, deliveryDateOptions, deliveryMethods } = props;
  const classes = useStyles();

  const handleDeliveryChange = (value: any) => {
    const newOption = value;
    if (newOption === "Delivery") {
      if (deliveryMethods) {
        const matchingDeliveryMethod = deliveryService.GetMatchingDeliveryMethod(deliveryMethods, currentOrder);
        if (matchingDeliveryMethod) {
          onDeliveryChange(deliveryService.BuildDeliveryDateOption(matchingDeliveryMethod, deliveryDateOptions));
        } else {
          onDeliveryChange(deliveryService.BuildDeliveryDateOption("LocalDelivery", deliveryDateOptions));
        }
      } else {
        onDeliveryChange(deliveryService.BuildDeliveryDateOption("LocalDelivery", deliveryDateOptions));
      }
    } else {
      const pickupMethod = currentOrder.shipments?.some((s) => s.Shipper === "CurbsidePickUp")
        ? "CurbsidePickUp"
        : "InStorePickUp";
      onDeliveryChange(deliveryService.BuildDeliveryDateOption(pickupMethod, deliveryDateOptions));
    }
  };

  const canDeliver = useMemo(() => {
    return deliveryService.CanDeliver(deliveryMethods, product);
  }, [deliveryMethods, product]);

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" flexWrap="nowrap" alignItems="flex-start">
        <Typography variant="overline" style={{ color: neutral.text_white_bg }}>
          Select Delivery Options
        </Typography>
        {(canDeliver || deliveryMethods?.InStorePickUp) && (
          <Box className={classes.toggleButtonContainer}>
            <ToggleButtonGroup
              value={selectedDeliveryOption?.selectedOption || ""}
              exclusive
              onChange={(event: any) => handleDeliveryChange(event?.target?.value)}
              aria-label="text alignment"
            >
              {canDeliver && (
                <ToggleButton value="Delivery" aria-label="Local Delivery" className={classes.ToggleButtonBody}>
                  <Box display="flex" alignItems="center" className={classes.flexGap1}>
                    <LocalShipping fontSize="small" />
                    <Typography variant="h4">Delivery</Typography>
                  </Box>
                </ToggleButton>
              )}
              {deliveryMethods?.InStorePickUp && (
                <ToggleButton value="PickUp" aria-label="In-Store Pickup" className={classes.ToggleButtonBody}>
                  <Box display="flex" alignItems="center" className={classes.flexGap1}>
                    <Store fontSize="small" />
                    <Typography variant="h4">Pickup</Typography>
                  </Box>
                </ToggleButton>
              )}
            </ToggleButtonGroup>
            {selectedDeliveryOption && (
              <Fade
                in={
                  selectedDeliveryOption.selectedOption === "Delivery" ||
                  selectedDeliveryOption.selectedOption === "PickUp"
                }
              >
                <Box className={classes.toggleButtonMessage}>
                  {selectedDeliveryOption.selectedOption === "Delivery" && (
                    <Typography variant="body2">
                      Twin Cities delivery available as early as{" "}
                      <strong>{moment(deliveryDateOptions?.LocalDelivery?.minDate).format("MMMM D")}</strong>
                    </Typography>
                  )}

                  {selectedDeliveryOption.selectedOption === "Delivery" && (
                    <Fragment>
                      {product?.xp?.CodeB2 === "Y" ? (
                        <Typography className={classes.nationwideAvailable} variant="body2">
                          Nationwide Delivery Available For This Product.
                        </Typography>
                      ) : (
                        <Typography variant="body2">Nationwide Delivery Not Available.</Typography>
                      )}
                    </Fragment>
                  )}

                  {deliveryDateOptions?.InStorePickUp && selectedDeliveryOption.selectedOption === "PickUp" && (
                    <Typography variant="body2">
                      In-store or curbside pickup as early as{" "}
                      <strong>{moment(deliveryDateOptions.InStorePickUp.minDate).format("MMMM D")}</strong>
                    </Typography>
                  )}
                </Box>
              </Fade>
            )}
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default DeliveryOptionSelect;
