import React, { FunctionComponent, useContext, useMemo } from "react";
import LineItemActionBar from "./LineItemActionBar";

import { BachmansLineItem } from "../../../models/lineItem";
import LineItemAction from "./LineItemAction";
import { LocalShippingOutlined, PlaylistAdd, RateReviewTwoTone, Store } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import LIActionBarProvider from "../../../providers/lineItemAction";
import { SessionContext } from "../../../providers/session";
import deliveryService, { DeliveryDateOptions } from "../../../services/delivery.service";
import { useAppSelector } from "../../../redux/store-hook";

interface LineItemActionsProps {
  shipperType: string; // ShipperType can't over OC shipment Shipper
  lineItem: BachmansLineItem;
  deliveryOptions?: DeliveryDateOptions;
  updating: boolean;
  onProductRequestEnabled: (id: string) => void;
  onWishListUpdate: (item: BachmansLineItem, action: "add" | "remove") => void;
  productNotInWishList: (item: BachmansLineItem) => boolean;
  onLiToDelete: (item: BachmansLineItem) => void;
  onDeliveryTypeUpdate: (action: "InStorePickUp" | "CurbsidePickUp" | "Delivery") => void;
}

const LineItemActions: FunctionComponent<LineItemActionsProps> = (props) => {
  // const classes = useStyle();
  const {
    shipperType,
    lineItem,
    updating,
    onProductRequestEnabled,
    productNotInWishList,
    onWishListUpdate,
    onLiToDelete,
    onDeliveryTypeUpdate,
    deliveryOptions,
  } = props;
  const { anonymous } = useContext(SessionContext);
  const currentOrder = useAppSelector((state) => state.order);

  const canSwitchToPickUp = useMemo(() => {
    return !deliveryService.IsPickUp(shipperType) && deliveryOptions?.InStorePickUp?.standardMinDate;
  }, [shipperType, deliveryOptions?.InStorePickUp?.standardMinDate]);

  const canSwitchToDelivery = useMemo(() => {
    return (
      deliveryService.IsPickUp(shipperType) &&
      (deliveryOptions?.LocalDelivery?.standardMinDate ||
        deliveryOptions?.UPS?.standardMinDate ||
        deliveryOptions?.USPS?.standardMinDate)
    );
  }, [deliveryOptions, shipperType]);

  const canAddProductNote = useMemo(() => !lineItem?.Product?.xp?.IsWorkShopEvent, [lineItem]);

  const handleChangeToPickUp = () => {
    const newType =
      currentOrder?.shipments && currentOrder.shipments.some((s) => s.Shipper === "CurbsidePickUp")
        ? "CurbsidePickUp"
        : "InStorePickUp";
    onDeliveryTypeUpdate(newType);
  };

  return (
    <LIActionBarProvider>
      <LineItemActionBar>
        {/* <Action >   will display menu item if mobile or tool tip with icon button */}

        {canSwitchToPickUp && lineItem?.xp?.DeliveryMethod !== "Email" && (
          <LineItemAction
            title="Change Item to Pickup"
            onClick={() => {
              handleChangeToPickUp();
            }}
            icon={<Store color="action" fontSize="small" />}
          />
        )}
        {canSwitchToDelivery && lineItem?.xp?.DeliveryMethod !== "Email" && (
          <LineItemAction
            title="Change Item To Delivery"
            onClick={() => {
              onDeliveryTypeUpdate("Delivery");
            }}
            icon={<LocalShippingOutlined color="action" fontSize="small" />}
          />
        )}

        {!lineItem.xp?.ProductNote && canAddProductNote && lineItem?.xp?.DeliveryMethod !== "Email" && (
          <LineItemAction
            title="(Optional) Product Notes For Designers"
            onClick={() => {
              onProductRequestEnabled(lineItem?.ID!);
            }}
            disabled={updating}
            icon={<RateReviewTwoTone color="action" fontSize="small" />}
          />
        )}
        {!anonymous && productNotInWishList(lineItem) && (
          <LineItemAction
            title="Move To Wishlist"
            onClick={() => {
              onWishListUpdate(lineItem, "add");
            }}
            disabled={updating}
            icon={<PlaylistAdd color="action" fontSize="small" />}
          />
        )}

        <LineItemAction
          title="Remove From Cart"
          onClick={() => {
            onLiToDelete(lineItem);
          }}
          icon={<CloseIcon color="error" fontSize="small" />}
        />
      </LineItemActionBar>
    </LIActionBarProvider>
  );
};
export default LineItemActions;
