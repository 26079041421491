import React, { createContext, FunctionComponent, useEffect, useState } from "react";
import { Entry } from "contentful";
import contentful from "../services/contentful.service";
import { HomeContent } from "../models/contentful/HomeContent";
import { SimpleContent } from "../models/contentful/SimpleContent";
import { PurplePerksContent } from "../models/contentful/PurplePerks";
import { WorkWithBachmansContent } from "../models/contentful/WorkWithBachmans";
import { EventsLandingContent } from "../models/contentful/EventsLandingContent";

interface ContextState<T> {
  ready: boolean;
  data?: T;
}

export interface StaticPageState extends ContextState<Entry<any>[]> {}
export interface ArticlesState extends ContextState<Entry<any>[]> {}
export interface SimpleContentsBlockState extends ContextState<SimpleContent[]> {}
export interface ComplexContentsBlockState extends ContextState<Entry<any>[]> {}
export interface HomeContentState extends ContextState<HomeContent> {}
export interface PurplePerksState extends ContextState<PurplePerksContent> {}
export interface WorkWithBachmansState extends ContextState<WorkWithBachmansContent> {}
export interface EventsLandingState extends ContextState<EventsLandingContent> {}
interface FooterState extends ContextState<Entry<any>> {}

export const StaticPageContext = createContext<StaticPageState>({
  ready: false,
});
export const ArticlesContext = createContext<ArticlesState>({
  ready: false,
});
export const SimpleContentBlocksContext = createContext<SimpleContentsBlockState>({
  ready: false,
});
export const ComplexContentBlocksContext = createContext<ComplexContentsBlockState>({
  ready: false,
});
export const HomeContentContext = createContext<HomeContentState>({
  ready: false,
});
export const FooterContext = createContext<FooterState>({ ready: false });
export const PurplePerksContext = createContext<PurplePerksState>({ ready: false });
export const WorkWithBachmansContext = createContext<WorkWithBachmansState>({ ready: false });
export const EventsLandingContext = createContext<EventsLandingState>({ ready: false });

const ContentfulProvider: FunctionComponent = ({ children }) => {
  const [staticPages, setStaticPages] = useState<StaticPageState>({
    ready: false,
  });
  const [articles, setArticles] = useState<StaticPageState>({
    ready: false,
  });
  const [simpleContentBlocks, setSimpleContentBlocks] = useState<SimpleContentsBlockState>({
    ready: false,
  });
  const [complexContentBlocks, setComplexContentBlocks] = useState<ComplexContentsBlockState>({
    ready: false,
  });
  const [homeContent, setHomeContentPage] = useState<HomeContentState>({
    ready: false,
  });
  const [footer, setFooter] = useState<FooterState>({ ready: false });

  const [purplePerks, setPurplePerks] = useState<PurplePerksState>({ ready: false });
  const [WorkWithBachmans, setWorkWithBachmans] = useState<WorkWithBachmansState>({ ready: false });
  const [eventsLanding, setEventsLanding] = useState<EventsLandingState>({ ready: false });

  useEffect(() => {
    contentful
      .getEntries({ content_type: "homeContent", limit: 1 })
      .then((response) => setHomeContentPage({ ready: true, data: response.items[0] }));
    contentful.getEntries({ content_type: "simpleContentBlock", limit: 1000 }).then((response) => {
      setSimpleContentBlocks({ ready: true, data: response.items });
    });
    contentful.getEntries({ content_type: "complexContentBlock", limit: 1000 }).then((response) => {
      setComplexContentBlocks({ ready: true, data: response.items });
    });
    contentful
      .getEntries({ content_type: "globalFooter", limit: 1 })
      .then((response) => setFooter({ ready: true, data: response.items[0] }));
    contentful
      .getEntries({ content_type: "staticPage", limit: 1000 })
      .then((response) => setStaticPages({ ready: true, data: response.items }));
    contentful
      .getEntries({ content_type: "article", limit: 1000 })
      .then((response) => setArticles({ ready: true, data: response.items }));
    contentful.getEntries({ content_type: "WorkWithBachmans", limit: 1 }).then((response) => {
      console.log("THIS IS RESP :", response.items[0]);
      setWorkWithBachmans({ ready: true, data: response.items[0] });
    });
    contentful
      .getEntries({ content_type: "purplePerks", limit: 1 })
      .then((response) => setPurplePerks({ ready: true, data: response.items[0] }));
    contentful
      .getEntries({ content_type: "eventsLanding", limit: 1 })
      .then((response) => setEventsLanding({ ready: true, data: response.items[0] }));
  }, []);
  return (
    <HomeContentContext.Provider value={homeContent}>
      <FooterContext.Provider value={footer}>
        <StaticPageContext.Provider value={staticPages}>
          <ArticlesContext.Provider value={articles}>
            <SimpleContentBlocksContext.Provider value={simpleContentBlocks}>
              <ComplexContentBlocksContext.Provider value={complexContentBlocks}>
                <WorkWithBachmansContext.Provider value={WorkWithBachmans}>
                  <PurplePerksContext.Provider value={purplePerks}>
                    <EventsLandingContext.Provider value={eventsLanding}>{children}</EventsLandingContext.Provider>
                  </PurplePerksContext.Provider>
                </WorkWithBachmansContext.Provider>
              </ComplexContentBlocksContext.Provider>
            </SimpleContentBlocksContext.Provider>
          </ArticlesContext.Provider>
        </StaticPageContext.Provider>
      </FooterContext.Provider>
    </HomeContentContext.Provider>
  );
};

export default ContentfulProvider;
