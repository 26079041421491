import { LineItem, BuyerProduct, Category, Me, Address } from "ordercloud-javascript-sdk";
import deliveryService from "./delivery.service";
import deliveryResourceService from "./delivery-resource.service";
import bachDateTime from "./bachDateTime.service";
import { BuyerXp, DeliveryTypes } from "../models/buyerXp";
import {
  BachmansShipmentWithItems,
  ValidatedBachmansShipmentWithLineItems,
  ValidatedLineItem,
  VerificationReason,
} from "../models/shipment";
import { cloneDeep, flatten, sumBy } from "lodash";
import { defaultTypes } from "../constants/destinations";
import bachmansIntegrationsService from "./bachmansIntegrations.service";
import { isFulfilled } from "@reduxjs/toolkit";
const validateAllShipments = async (
  dcfCategories: Category[],
  shipments?: BachmansShipmentWithItems[],
  products?: BuyerProduct[]
): Promise<ValidatedBachmansShipmentWithLineItems[]> => {
  const dataReqs: Promise<any>[] = [deliveryResourceService.GetLyndaleStore(), deliveryResourceService.GetBuyerXp()];
  if (!products) {
    const productIDs = flatten(shipments?.map((s) => s.LineItems)).map((li) => li.ProductID);
    dataReqs.push(Me.ListProducts({ filters: { ID: productIDs.join("|") } }));
  }
  const [lyndale, buyerXp, productList] = await Promise.all(dataReqs);
  const allProducts = products ? products : productList.Items;

  const allItems = flatten(shipments?.map((s) => s.LineItems));
  const reqs = allItems.map((li) => {
    const prod = allProducts.find((p: BuyerProduct) => p.ID === li.ProductID);
    return validateLineItemDateNeeded(dcfCategories, li, lyndale, buyerXp, prod);
  });

  // Call Validation functions for line items
  const dateValidated = await Promise.all(reqs);
  const ISPAddressValidated = await validateISPAddresses(dateValidated);
  const inventoryValidated = validateLineItemInventory(ISPAddressValidated, allProducts);
  const methodsValidated = validateDeliveryMethod(inventoryValidated, allProducts, dcfCategories);
  const nationwideDeliveryValidated = validateNationwideDeliveries(methodsValidated, allProducts);

  // Group the validated line items back into their original shipments
  const validatedShipments = cloneDeep(shipments) as ValidatedBachmansShipmentWithLineItems[];
  validatedShipments?.forEach((s) => {
    const itemIDsOnShipment = s.LineItems.map((li) => li.ID);
    const validatedLis = nationwideDeliveryValidated.filter((v) => itemIDsOnShipment.includes(v.ID));
    s.LineItems = validatedLis;
  });
  return validatedShipments;
};

const generateValidatedLineItem = (
  lineItem: LineItem,
  v: boolean,
  r?: VerificationReason,
  m?: string
): ValidatedLineItem => ({
  ...lineItem,
  Validation: {
    valid: v,
    reason: r,
    message: m,
  },
});

const validateNationwideDeliveries = (lineItems: ValidatedLineItem[], products: BuyerProduct[]) => {
  const validatedItems: ValidatedLineItem[] = [];
  const ineligibleAddressMessage = "Selected Address is out of delivery range for one or more items";
  lineItems.forEach((item) => {
    let valid = true;
    if (
      item.ShippingAddress &&
      item.ShippingAddress !== null &&
      !deliveryService.IsLocalDelivery(item.ShippingAddress.Zip, item.ShippingAddress.City)
    ) {
      const product = products.find((p) => p.ID === item.ProductID);
      valid = product?.xp?.CodeB2 === "Y";
    }
    if (!valid && item.Validation?.valid === true) {
      validatedItems.push(generateValidatedLineItem(item, false, "Address", ineligibleAddressMessage));
    } else if (valid && item.Validation?.valid === false && item.Validation.message === ineligibleAddressMessage) {
      validatedItems.push(generateValidatedLineItem(item, true));
    } else validatedItems.push(item);
  });
  return validatedItems;
};

const validateDeliveryMethod = (
  lineItems: ValidatedLineItem[],
  products: BuyerProduct[],
  dcfCategories: Category<any>[]
) => {
  const validatedItems: ValidatedLineItem[] = [];
  lineItems.forEach((item) => {
    let isValidDelivery = true;
    const product = products.find((p) => p.ID === item.ProductID);
    const isEvent = item?.Product?.xp?.IsWorkShopEvent;
    if (product) {
      const availableMethods = deliveryService.GetAvailableDeliveryMethods([product], dcfCategories);
      const currentMethod = deliveryService.IsPickUp(item.xp.DeliveryMethod) ? "InStorePickUp" : item.xp.DeliveryMethod;

      if (availableMethods && availableMethods[currentMethod as DeliveryTypes]) {
        isValidDelivery = true;
      } else if (isEvent) {
        isValidDelivery = true;
      } else if (item.xp.DeliveryMethod == "Email") {
        isValidDelivery = true;
      } else {
        isValidDelivery = false;
      }
    } else {
      isValidDelivery = false;
    }
    if (!isValidDelivery && item.xp.DeliveryMethod !== "Email") {
      validatedItems.push(
        generateValidatedLineItem(
          item,
          false,
          "DeliveryMethod",
          "Selected delivery method is not valid for one or more items"
        )
      );
    } else if (
      !isEvent &&
      isValidDelivery &&
      item.Validation?.valid === false &&
      item.Validation.reason === "DeliveryMethod"
    ) {
      validatedItems.push(generateValidatedLineItem(item, true));
    } else if (isEvent) {
      validatedItems.push(generateValidatedLineItem(item, true));
    } else validatedItems.push(item);
  });
  return validatedItems;
};

const validateISPAddresses = async (lineItems: ValidatedLineItem[]): Promise<ValidatedLineItem[]> => {
  const ISPItems = lineItems.filter((li) => deliveryService.IsPickUp(li.xp?.DeliveryMethod));
  if (!ISPItems || ISPItems.length === 0) {
    return lineItems;
  } else {
    const validatedItems: ValidatedLineItem[] = [];
    const destinationInfo = defaultTypes.find((d) => deliveryService.IsPickUp(d.type));
    const stores = await Me.ListAddresses({ pageSize: 100, filters: destinationInfo?.filters });
    const invalidMessage = "One or more address(es) is not valid for in store pickup";
    lineItems.forEach((item) => {
      let ISPAddressValid = true;
      if (!deliveryService.IsPickUp(item.xp.DeliveryMethod)) {
        ISPAddressValid = true;
      } else {
        const isEvent = item.Product?.xp?.IsWorkShopEvent;
        const addressNames = stores.Items.map((s) => s.CompanyName);
        if (isEvent || !item.ShippingAddress?.CompanyName || !addressNames.includes(item.ShippingAddress.CompanyName)) {
          ISPAddressValid = false;
        }
      }
      if (!ISPAddressValid && item.Validation?.valid === true) {
        validatedItems.push(generateValidatedLineItem(item, false, "Address", invalidMessage));
      } else if (ISPAddressValid && item.Validation?.valid === false && item.Validation.message === invalidMessage) {
        validatedItems.push(generateValidatedLineItem(item, true));
      } else validatedItems.push(item);
    });
    return validatedItems;
  }
};
var validateEgiftcardoncart = (async function () {
  const obj = await bachmansIntegrationsService.validateGiftCard(1, true);
  if (obj.NumberAvailable <= 0) {
    document.body.classList.add("block-checkout");
  }
  return obj;
})();
const validateLineItemInventory = (lineItems: ValidatedLineItem[], products: BuyerProduct[]): ValidatedLineItem[] => {
  const validatedItems: ValidatedLineItem[] = [];
  lineItems.forEach((item) => {
    const added = lineItems.filter((li) => li.ProductID === item.ProductID);
    const addedQuantity = sumBy(added, function (li) {
      return li.Quantity;
    });
    let inventoryValid = true;
    const prod = products.find((p) => p.ID === item.ProductID);
    if (prod?.xp?.CodeB3 === "9") {
      inventoryValid = true;
    } else if (
      prod?.Inventory?.QuantityAvailable &&
      (addedQuantity > prod?.Inventory?.QuantityAvailable ||
        addedQuantity > prod.Inventory.QuantityAvailable - (prod?.xp?.SafetyStock || 0))
    ) {
      inventoryValid = false;
    }
    // 2 cases we have to act on
    if (!inventoryValid && item.Validation?.valid === true) {
      validatedItems.push(
        generateValidatedLineItem(item, false, "Inventory", "Selected quantity is not available for one or more items")
      );
    } else if (item.Validation?.valid === true && item?.ProductID == "EGIFTCARD") {
      if (document.body.classList.contains("block-checkout")) {
        // do some stuff
        validatedItems.push(
          generateValidatedLineItem(
            item,
            false,
            "Inventory",
            "Selected quantity is not available for one or more items"
          )
        );
      } else {
        validatedItems.push(generateValidatedLineItem(item, true));
      }
    } else if (inventoryValid && item.Validation?.valid === false && item.Validation?.reason === "Inventory") {
      validatedItems.push(generateValidatedLineItem(item, true));
    } else validatedItems.push(item);
  });
  return validatedItems;
};

const validateLineItemDateNeeded = async (
  dcfCategories: Category[],
  lineItem: LineItem,
  lyndale: Address,
  buyerXp: BuyerXp,
  product?: BuyerProduct
): Promise<ValidatedLineItem> => {
  const currentMethod = lineItem.xp?.DeliveryMethod as DeliveryTypes;
  const isEvent = lineItem?.Product?.xp?.IsWorkShopEvent;
  if (!product) {
    return generateValidatedLineItem(lineItem, false, "Product", `${lineItem.Product?.Name} is not found`);
  } else if (isEvent) {
    // Validate that the event date has not passed
    const hasDatePassed = bachDateTime.IsBeforeMinDate(new Date(lineItem?.DateNeeded!), new Date(Date.now()));
    if (hasDatePassed) {
      return generateValidatedLineItem(lineItem, false, "Date", "The date of this event has already passed");
    } else {
      return generateValidatedLineItem(lineItem, true);
    }
  } else if (currentMethod === "Email") {
    return generateValidatedLineItem(lineItem, true);
  } else if (!lineItem.ShippingAddress && currentMethod === "InStorePickUp") {
    return generateValidatedLineItem(lineItem, false, "Address", "Pickup location is not set for one or more items");
  } else if (!lineItem.ShippingAddress) {
    return generateValidatedLineItem(lineItem, false, "Address", "Shipping address is not set for one or more items");
  } else if (!lineItem.DateNeeded) {
    return generateValidatedLineItem(lineItem, false, "Date", "No Delivery Date is set for one or more items");
  }

  const methods = deliveryService.GetAvailableDeliveryMethods([product], dcfCategories);
  const dateOptions = await deliveryService.GetDateOptions({
    products: [product],
    deliveryMethods: methods,
    shippingAddress: lineItem.ShippingAddress,
  });
  if (!dateOptions) {
    return generateValidatedLineItem(lineItem, false, "Date", "Date is not valid for one or more items");
  }
  const isDisabled = deliveryService.IsDateDisabled(
    lineItem.ShippingAddress,
    [product],
    buyerXp,
    lyndale,
    lineItem.xp?.DeliveryMethod,
    { mode: "day", date: new Date(lineItem.DateNeeded) }
  );
  const isBeforeMinDate = bachDateTime.IsBeforeMinDate(
    new Date(lineItem.DateNeeded),
    dateOptions[currentMethod]?.minDate
  );
  if (!lineItem.Product?.xp?.IsWorkShopEvent && (isDisabled || isBeforeMinDate)) {
    return generateValidatedLineItem(
      lineItem,
      false,
      "Date",
      isDisabled ? "Selected date is disabled" : "The date selected is not available. Please choose a different date."
    );
  } else if (
    bachDateTime.IsAfterNextDayAMCutoff(buyerXp) &&
    lineItem?.xp?.TimePreference === "AM" &&
    bachDateTime.Tomorrow().utc().isSame(lineItem.DateNeeded, "d")
  ) {
    return generateValidatedLineItem(lineItem, false, "Date", "Next Day AM delivery no longer available");
  } else if (lineItem.xp.DeliveryMethod === "LocalDelivery" && !lineItem.xp.TimePreference) {
    return generateValidatedLineItem(lineItem, false, "Date", "Time Preference required on local delivery");
  } else return generateValidatedLineItem(lineItem, true);
};

const allItemsValid = (shipments?: ValidatedBachmansShipmentWithLineItems[]) => {
  const items = flatten(shipments?.map((s) => s.LineItems));
  return items && items.every((li) => li.Validation?.valid === true);
};

const service = {
  ValidateAllShipments: validateAllShipments,
  AllItemsValid: allItemsValid,
};

export default service;
