import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Container,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import iconResource from "./../../../assets/svg/iconResource.svg";
import { neutral, purple } from "../../../themes/colors";
import Link from "../../Shared/Link";
import Breadcrumbs from "./Breadcrumbs";
import CategoryToolbar from "./CategoryToolbar";
import MiniCart from "./MiniCart";
import Search from "../../Search/Search";
import User from "./User";
import ContentLinksMenu, { contentfulContentLinkAndTitle } from "./ContentLinksMenu";
import MobileMegaMenu from "./MegaMenu/MobileMegaMenu";
import { StaticPageContext } from "../../../providers/contentful";
import contentful from "../../../services/contentful.service";
import { Dictionary } from "@reduxjs/toolkit";
import MobileContentLinksMenu from "./MobileContentLinksMenu";
import AuthDialog from "../../Authentication/AuthDialog";
import { SessionContext } from "../../../providers/session";
import MobileAccountMenu from "./MobileAccountMenu";
import MobileContactUs from "./MobileContactUs";

import logoPurple from "../../../assets/svg/logoPurple.svg";
import iconProducts from "../../../assets/svg/iconProducts.svg";
import iconEvents from "../../../assets/svg/iconEvents.svg";
import iconLocations from "../../../assets/svg/iconLocations.svg";
import iconContact from "../../../assets/svg/iconContact.svg";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/store-hook";
import { AppDispatch } from "../../../redux/store";
import { setBreadcrumbs } from "../../../redux/slices/breadcrumbs";
import { useHistory, useLocation } from "react-router-dom";
import { filter, find } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar,
      position: "sticky",
      top: 0,
      [theme.breakpoints.down("sm")]: {
        borderTop: `6px solid ${theme.palette.primary.main}`,
      },
    },
    rootShadow: {
      boxShadow: `0px 2px 10px rgba(0,0,0,0.2)`,
      zIndex: theme.zIndex.appBar + 1,
      position: "relative",
    },
    MobileContentMenu: {
      [theme.breakpoints.down("sm")]: {
        "& button:first-child": {
          "&::after": {
            content: "'|'",
            marginLeft: theme.spacing(3),
            paddingRight: theme.spacing(2),
            marginTop: theme.spacing(0.5),
          },
        },
      },
    },
    utility: {
      background: purple.utility,
      color: neutral.text_utility_bg,
    },
    utilityLinks: {
      flexGrow: 1,
      display: "flex",
      alignItems: "stretch", //do this so that the context menu displays at bottom of toolbar
      justifyContent: "flex-end",
      "& > div": {
        display: "flex",
        alignItems: "center", //align content center
        "& .MuiLink-root": {
          position: "relative",
          color: "inherit",
          margin: theme.spacing(0, 1.5, 0, 1.5),
          [theme.breakpoints.down("sm")]: {
            "&:not(:last-child)": {
              marginRight: theme.spacing(1.5),
            },
          },
        },
      },
    },
    utilityLinkTel: {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    mainNav: {
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1, 3),
      },
    },
    navBrand: {
      display: "block",
      width: "100%",
      marginRight: "auto",
      maxWidth: 125,
      [theme.breakpoints.up("md")]: {
        maxWidth: 200,
        marginRight: theme.spacing(5),
      },
    },
    logo: {
      display: "block",
      width: "100%",
      height: "auto",
    },
    navMenuRight: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        gap: theme.spacing(2),
        marginLeft: "auto",
      },
    },
    menuItems: {
      marginLeft: "auto",
      alignItems: "center",
      "& > div": {
        padding: theme.spacing(3, 0, 3, 3),
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    mobileNavigation: {
      height: theme.spacing(8),
      boxShadow: `0px -2px 10px rgba(0,0,0,0.2)`,
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: theme.zIndex.appBar,
      background: theme.palette.background.paper,
      ...theme.typography.body2,
      "& > .MuiBottomNavigationAction-root": {
        minWidth: 70,
      },
    },
    iconMobileNavigation: {
      height: 35,
      color: theme.palette.primary.main,
      textAlign: "center",
      fontSize: 0,
      marginBottom: 3,
    },
    resourceIcon: {
      fontsize: "2.2rem",
      color: theme.palette.primary.main,
    },
    articleLinksNav: {
      display: "flex",
      alignItems: "stretch",
      width: "100%",
      "& > p": {
        flex: "1 0 0",
        display: "flex",
        alignItems: "center",
      },
    },
  })
);

const Header: FunctionComponent = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [bottomNavValue, setBottomNavValue] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountMobileMenuOpen, setAccountMobileMenuOpen] = useState(false);
  const [contactUsMobileMenuOpen, setContactUsMobileMenuOpen] = useState(false);
  const [articleTagList, setArticleTagList] = useState<Array<any>>([]);
  const [formattedPages, setFormattedPages] = useState<Dictionary<any>>();
  const [workshopEventLinks, setWorkshopEventLinks] = useState<Array<any>>([]);
  const [bachmansBlog, setBachmansBlog] = useState<contentfulContentLinkAndTitle>();
  const staticPages = useContext(StaticPageContext);
  const { anonymous } = useContext(SessionContext);
  const breadCrumbState = useAppSelector((state) => state.breadcrumbs);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (staticPages.ready) {
      let pages = contentful.groupStaticPagesBySection(staticPages);
      pages["customer-service"].shift(); /// remove "on sale" from list
      setFormattedPages(pages);
    }
  }, [staticPages, articleTagList, workshopEventLinks]);

  useEffect(() => {
    contentful.getArticlesByTag().then((articleTags) => {
      if (articleTags) {
        let blog = find(articleTags, (tagInfo: contentfulContentLinkAndTitle) => tagInfo.title === "Bachman's Blog");
        if (blog) {
          setBachmansBlog(blog);
        }
        articleTags = filter(
          articleTags,
          (tagInfo: contentfulContentLinkAndTitle) => tagInfo.title !== "Bachman's Blog"
        );
        articleTags.unshift({ link: "/information/resource-hub", title: "All Care Guides" });
        setArticleTagList(articleTags);
      }
    });
    contentful.getWorkshopAndEventsLinks().then((response) => {
      setWorkshopEventLinks(response);
    });
  }, []);

  useEffect(() => {
    //set bachmans blog to top of information section
    if (formattedPages?.information && bachmansBlog) {
      formattedPages?.information.unshift(bachmansBlog);
    }
  }, [bachmansBlog, formattedPages]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleAccountMobileMenu = () => {
    setAccountMobileMenuOpen(!accountMobileMenuOpen);
  };
  const toggleContactUsMobileMenu = () => {
    setContactUsMobileMenuOpen(!contactUsMobileMenuOpen);
  };

  const toggleResourceMobileMenu = () => {
    let resourceStatus = breadCrumbState.resourceDrawerOpen;
    dispatch(
      setBreadcrumbs({
        resourceDrawerOpen: !resourceStatus,
      })
    );
  };

  const handleAuthDialogOpen = () => {
    dispatch(setBreadcrumbs({ authDialogOpen: true }));
  };

  const handleAuthDialogClose = () => {
    dispatch(setBreadcrumbs({ authDialogOpen: false }));
  };

  const handleBottomNavPress = (event: React.ChangeEvent<{}>, newValue: any) => {
    if (newValue === "products") {
      toggleMenu();
    }
    if (newValue === "account") {
      anonymous ? handleAuthDialogOpen() : toggleAccountMobileMenu();
    }
    if (newValue === "resources") toggleResourceMobileMenu();
    if (newValue === "contact") {
      history.push("/customer-service/contact-us");
    }
    if (newValue === "locations") {
      history.push("/store-locator");
    }
    if (newValue === "events") {
      history.push("/workshops-and-events");
    }

    setBottomNavValue(newValue);
  };

  return (
    <Fragment>
      {location.pathname === "/checkout" ? null : (
        <Fragment>
          <Box component="header" className={classes.root}>
            <div className={classes.rootShadow}>
              <Hidden smDown>
                <AppBar position="relative" elevation={0} className={classes.utility}>
                  <Toolbar
                    variant="dense"
                    component={(props: any) => (
                      <Container {...props} maxWidth="lg" classes={{ root: classes.articleLinksNav }}></Container>
                    )}
                  >
                    {!isMobile ? (
                      <Typography variant="body1">
                        <a className={classes.utilityLinkTel} href="tel:+16128617311">
                          Order Local: 612-861-7311
                        </a>
                      </Typography>
                    ) : (
                      ""
                    )}

                    {formattedPages && Object.keys(formattedPages).length && (
                      <div className={classes.utilityLinks}>
                        {!isMobile ? (
                          <Fragment>
                            <ContentLinksMenu
                              menuLink={"/workshops-and-events"}
                              menuTitle={"Workshops & Events"}
                              contentLinks={[{ Links: workshopEventLinks, ID: "workshops-and-events", Type: "event" }]}
                            />
                            <ContentLinksMenu
                              menuLink={"/information/resource-hub"}
                              menuTitle={"Resource Hub"}
                              contentLinks={[
                                {
                                  Title: "",
                                  Links: articleTagList,
                                  ID: "string",
                                  Type: "article",
                                },
                                {
                                  Title: "References & Tools",
                                  Links: formattedPages.information,
                                  ID: "string",
                                  Type: "staticPage",
                                },
                              ]}
                            />
                            <ContentLinksMenu
                              menuTitle={"Services"}
                              menuLink={formattedPages.services[0]?.fields?.link}
                              contentLinks={[{ Links: formattedPages.services, ID: "services", Type: "staticPage" }]}
                            />
                            <ContentLinksMenu
                              menuTitle={"Customer Service"}
                              menuLink={formattedPages["customer-service"][0]?.fields?.link}
                              contentLinks={[
                                {
                                  Links: formattedPages["customer-service"],
                                  ID: "customer-service",
                                  Type: "staticPage",
                                },
                              ]}
                            />
                            <div>
                              <Link to="/store-locator" variant="body1" color="inherit">
                                Store Locator
                              </Link>
                            </div>
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Toolbar>
                </AppBar>
              </Hidden>
              <AppBar position="relative" elevation={0} color="default">
                <Toolbar
                  className={classes.mainNav}
                  component={(props: any) => <Container {...props} maxWidth="lg"></Container>}
                >
                  <Link to="/" className={classes.navBrand}>
                    <Typography variant="srOnly" component="h1">
                      Bachman's
                    </Typography>
                    <img src={logoPurple} alt="Bachman's Logo" className={classes.logo}></img>
                  </Link>
                  <Search />
                  <Box className={classes.navMenuRight}>
                    <User onAuthDialogOpen={handleAuthDialogOpen} />
                    <MiniCart />
                  </Box>
                </Toolbar>
                <CategoryToolbar />
              </AppBar>
            </div>
            <Breadcrumbs />
          </Box>
          {isMobile && (
            <React.Fragment>
              <BottomNavigation
                showLabels
                className={classes.mobileNavigation}
                onChange={handleBottomNavPress}
                value={bottomNavValue}
              >
                <BottomNavigationAction
                  label="Products"
                  value="products"
                  icon={
                    <img
                      src={iconProducts}
                      alt="Products"
                      aria-hidden="true"
                      className={classes.iconMobileNavigation}
                    />
                  }
                />
                <BottomNavigationAction
                  label="Events"
                  value="events"
                  icon={
                    <img src={iconEvents} alt="Events" aria-hidden="true" className={classes.iconMobileNavigation} />
                  }
                />
                <BottomNavigationAction
                  label="Locations"
                  value="locations"
                  icon={
                    <img
                      src={iconLocations}
                      alt="Locations"
                      aria-hidden="true"
                      className={classes.iconMobileNavigation}
                    />
                  }
                />
                {formattedPages && Object.keys(formattedPages).length && (
                  <MobileContentLinksMenu
                    toggleMenu={toggleResourceMobileMenu}
                    open={breadCrumbState.resourceDrawerOpen}
                    contentLinks={[
                      {
                        Title: "Resource Hub",
                        Links: articleTagList,
                        ID: "care-guides",
                        Type: "article",
                      },
                      {
                        Title: "References & Tools",
                        Links: formattedPages?.information,
                        ID: "care-guides",
                        Type: "staticPage",
                      },
                      {
                        Title: "Customer Service",
                        Links: formattedPages?.["customer-service"],
                        ID: "care-guides",
                        Type: "staticPage",
                      },
                      {
                        Title: "Services",
                        Links: formattedPages?.services,
                        ID: "care-guides",
                        Type: "staticPage",
                      },
                      {
                        Title: "Corporate",
                        Links: formattedPages?.corporate,
                        ID: "care-guides",
                        Type: "staticPage",
                      },
                    ]}
                  />
                )}
                <BottomNavigationAction
                  label="Contact"
                  value="contact"
                  icon={
                    <img src={iconContact} alt="Contact" aria-hidden="true" className={classes.iconMobileNavigation} />
                  }
                />
                <BottomNavigationAction
                  label="Resources"
                  value="resources"
                  icon={
                    <img
                      src={iconResource}
                      alt="Resource"
                      aria-hidden="true"
                      className={classes.iconMobileNavigation}
                      style={{ padding: 3 }}
                    />
                  }
                ></BottomNavigationAction>
              </BottomNavigation>
              <MobileMegaMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />
              <MobileAccountMenu menuOpen={accountMobileMenuOpen} toggleMenu={toggleAccountMobileMenu} />
              <MobileContactUs menuOpen={contactUsMobileMenuOpen} toggleMenu={toggleContactUsMobileMenu} />
            </React.Fragment>
          )}
          <AuthDialog
            open={breadCrumbState.authDialogOpen}
            onClose={handleAuthDialogClose}
            state={breadCrumbState.authDialogState}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Header;
