import React, { FunctionComponent } from "react";
import { createStyles, Grid, makeStyles, Theme, Typography, Link } from "@material-ui/core";
import { neutral } from "../../../themes/colors";

import stringService from "../../../services/string.service";
import { LineItem } from "ordercloud-javascript-sdk";
import ProductPrice from "../ProductPrice";
import Currency from "react-currency-formatter";
import productService from "../../../services/product.service";

interface ConfirmProductDisplayProps {
  lineItem: LineItem;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    container: { display: "flex" },
    image: {
      width: 60,
      height: 60,
      aspectRatio: "1 / 1",
      objectFit: "cover",
    },
    infoBox: {
      marginLeft: theme.spacing(1),
    },
    productName: {
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.h1.fontFamily,
      color: theme.palette.grey[700],
      lineHeight: 1.2,
    },
    text: {
      color: neutral.text_white_bg,
      lineHeight: 1,
    },
    productPrice: {
      color: theme.palette.primary.main,
      padding: "2px",
      fontSize: "initial",
    },
  })
);

const ConfirmProductDisplay: FunctionComponent<ConfirmProductDisplayProps> = (props) => {
  const classes = useStyle();
  const { lineItem } = props;
  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid className={classes.container} item xs={6}>
        <img className={classes.image} src={lineItem.xp?.ShownImage} alt={lineItem.Product?.Name}></img>
        <div className={classes.infoBox}>
          <Typography className={classes.productName} variant="body1">
            <Link>{stringService.CapitalizeString(productService.GetLineItemName(lineItem))}</Link>
          </Typography>
          <Typography title="lineItemID" className={classes.text} variant="overline">
            Item # {lineItem.Product?.ID}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">{lineItem.Quantity}</Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        {lineItem.ProductID === "GIFTCARD" ? (
          <Typography variant="h4" className={classes.productPrice}>
            <Currency quantity={lineItem?.xp?.Amount}></Currency>
          </Typography>
        ) : (
          <ProductPrice lineItem={lineItem} />
        )}
      </Grid>
    </Grid>
  );
};
export default ConfirmProductDisplay;
