import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import {
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  Link,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { SessionContext } from "../../providers/session";
import { useHistory, useLocation } from "react-router";
import { regexConstants } from "../../constants/regex.constants";
import appConstants from "../../constants/app.constants";
import { PasswordReset, ForgottenPassword } from "ordercloud-javascript-sdk";
import { PasswordRequirementsList } from "./PasswordRequirementsList";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";

interface ResetPasswordProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: theme.spacing(2),
    },
    root: {
      marginTop: theme.spacing(2),
    },
  })
);

const ResetPassword: FunctionComponent<ResetPasswordProps> = (props) => {
  const classes = useStyle();
  const { login } = useContext(SessionContext);
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleAuth = async () => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email") || "";
    const code = searchParams.get("verificationCode") || "";
    const resetPassword: PasswordReset = {
      ClientID: appConstants.clientID,
      Username: email,
      Password: password,
    };
    try {
      await ForgottenPassword.ResetPasswordByVerificationCode(code, resetPassword);
      if (login) {
        await login(email, password!, false);
      }
      history.push("/");
    } catch (err: any) {
      console.log(err);
    }
  };

  const passwordMisMatch: boolean = useMemo(() => {
    return Boolean(
      password && password.length > 0 && confirmPassword && confirmPassword.length > 0 && password !== confirmPassword
    );
  }, [password, confirmPassword]);

  const passwordValid: boolean = useMemo(() => {
    return regexConstants.validPassword.test(password || "");
  }, [password]);

  return (
    <div className={classes.root}>
      <Container>
        <div style={{ width: isMobile ? "80%" : "50%", margin: "auto" }}>
          <Typography variant="h1">Reset Password</Typography>

          <TextField
            className={classes.input}
            margin="dense"
            variant="outlined"
            fullWidth
            autoFocus
            id="newPassword"
            type="password"
            label="New Password"
            value={password || ""}
            error={Boolean(password && password.length > 0) && passwordValid === false}
            helperText={
              Boolean(password && password.length > 0) &&
              passwordValid === false &&
              "Please ensure your password follows our guidelines"
            }
            onChange={(e: any) => setPassword(e.target.value)}
          ></TextField>
          <Typography variant="body1">
            Your password needs to include a minimum of 10 characters, and each of the following attributes:
          </Typography>
          <PasswordRequirementsList />
          <TextField
            className={classes.input}
            margin="dense"
            variant="outlined"
            fullWidth
            type="password"
            id="confirmPassword"
            label="Confirm Password"
            value={confirmPassword}
            error={passwordMisMatch}
            helperText={passwordMisMatch && "Passwords do not match"}
            onChange={(e: any) => setConfirmPassword(e.target.value)}
          ></TextField>
          <FormControlLabel
            className={classes.input}
            control={
              <Checkbox
                id="termsAndConditions"
                name="termsAndConditions"
                value={acceptTerms}
                checked={acceptTerms}
                onChange={(e: any) => setAcceptTerms(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                I accept all the{" "}
                <Link href="/customer-service/frequently-asked-questions" target="_blank">
                  Terms and Conditions
                </Link>
              </Typography>
            }
          ></FormControlLabel>
          <DoubleOutlinedBtn
            buttonText="submit"
            buttonProps={{
              disabled: passwordMisMatch || !passwordValid || !acceptTerms,
              onClick: handleAuth,
              fullWidth: true,
            }}
            styleProps={{ marginTop: "8px" }}
          />
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
