import { Grid, Container, makeStyles, Theme, createStyles, useMediaQuery } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import stringService from "../../services/string.service";
import CareAdvice from "./CareAdvice";
import GenericStaticPage from "./GenericStaticPage";
import StaticPageNavigation from "./StaticPageNavigation";
import queryString from "querystring";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { StaticPageContext } from "../../providers/contentful";
import { Entry } from "contentful";
import { isUndefined, replace } from "lodash";
import NotFound from "../Shared/NotFound";
import { BreadcrumbsState, setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { useAppSelector } from "../../redux/store-hook";

export interface StaticPageQueryParams {
  tag?: string;
  search?: string;
  page?: number;
  catID?: string;
  contenttype?: any;
}

export interface StaticPageUrlParams {
  section: string;
  rootSlug: string;
  branchSlug?: string;
  leafSlug?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
    },
  })
);

export const StaticPages: React.FunctionComponent = () => {
  const classes = useStyles();
  const staticPages = useContext(StaticPageContext);
  const { section, rootSlug, branchSlug, leafSlug } = useParams<StaticPageUrlParams>();
  const [queryParams, setQueryParams] = useState<StaticPageQueryParams>();
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState<Entry<any>>();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const breadCrumb = useAppSelector((state) => state.breadcrumbs);
  const [isPageLoaded, setPageLoaded] = useState<boolean>(false);

  //sets static page && breadcrumb
  useEffect(() => {
    const section = stringService.GetFirstPath(window.location.pathname);
    if (staticPages && staticPages.ready) {
      var page = staticPages.data?.find(
        (p) =>
          p.fields.section === section && p.fields.slug === (leafSlug ? leafSlug : branchSlug ? branchSlug : rootSlug)
      );
      setSelectedPage(page);
      setPageLoaded(true);
    }
    const query: StaticPageQueryParams = stringService.GetQueryParamsFromLocation(location.search);

    let breadcrumbs: Partial<BreadcrumbsState> = {
      visible: true,
      dark: false,
    };
    if (section === "information") {
      breadcrumbs.links = [{ path: "/information/resource-hub", label: "Resource Hub" }];
      if (query.tag && query.tag !== "" && rootSlug === "resource-hub") {
        breadcrumbs.current = queryParams?.tag ? queryParams?.tag : "";
      }
      if (!query.tag && rootSlug === "resource-hub") {
        breadcrumbs.current = "Care Guides";
      }
    } else {
      breadcrumbs.links = [
        { path: `/${section}`, label: replace(stringService.CapitalizeString(section, ["-"]), "-", " ") },
        // eg turn `customer-service` to `Customer Service`
      ];
      if (branchSlug) {
        breadcrumbs.links.push({
          path: `/${section}/${rootSlug}`,
          label: replace(stringService.CapitalizeString(rootSlug, ["-"]), "-", " "),
        });
      }
    }
    if (rootSlug !== "resource-hub") {
      breadcrumbs.current = selectedPage?.fields?.title ? selectedPage?.fields?.title : "";
    }
    dispatch(setBreadcrumbs(breadcrumbs));
    setQueryParams(query);
  }, [dispatch, section, branchSlug, rootSlug, leafSlug, location.search, staticPages, queryParams?.tag, selectedPage]);

  //handle breadcrumb display mobile vs not
  useEffect(() => {
    //if mobile and breadcrumbs are visible set to false
    if (isMobile && breadCrumb.visible) {
      dispatch(setBreadcrumbs({ visible: false }));
    }
    if (!isMobile && !breadCrumb.visible) {
      dispatch(setBreadcrumbs({ visible: true }));
    }
  }, [isMobile, breadCrumb.visible, dispatch]);

  const innerContent = useMemo(() => {
    const updateParams = (newParams: StaticPageQueryParams) => {
      setQueryParams(newParams);
      history.push({
        search: queryString.stringify(newParams as any),
      });
    };
    if (rootSlug === "resource-hub") {
      return (
        <CareAdvice queryParams={queryParams} updateParams={updateParams} pageSlug={branchSlug ? branchSlug : ""} />
      );
    } else if (!isUndefined(selectedPage)) {
      //TODO: figure out how to get static content to display for "How-to vidoes page"
      return <GenericStaticPage page={selectedPage} />;
    } else {
      if (isPageLoaded) {
        return <NotFound />;
      }
    }
  }, [rootSlug, history, queryParams, selectedPage, branchSlug, isPageLoaded]);

  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <StaticPageNavigation queryParams={queryParams} />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          {innerContent}
        </Grid>
      </Grid>
    </Container>
  );
};

export default StaticPages;
