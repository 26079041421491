import React, { Fragment, FunctionComponent, useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from "@material-ui/core";
import { useEffect } from "react";
import { CreditCard, Me } from "ordercloud-javascript-sdk";
import moment from "moment-timezone";
import { useCallback } from "react";
import EditCCDialog from "./EditCCDialog";
import { useHistory } from "react-router-dom";
import bachmansIntegrationsService from "../../../services/bachmansIntegrations.service";
import { neutral } from "../../../themes/colors";
import BachmansLoading from "../../Shared/BachmansLoading";

interface CreditCardsProps {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    ccCard: {
      flexGrow: 1,
      height: "100%",
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(2),
    },
    ccBody: {
      "& *": {
        fontWeight: theme.typography.fontWeightBold,
        color: neutral.text_white_bg,
        lineHeight: 1.4,
        "&:first-child": {
          marginTop: theme.spacing(2),
        },
      },
    },
    link: {
      cursor: "pointer",
    },
  })
);
const CreditCards: FunctionComponent<CreditCardsProps> = (props) => {
  const classes = useStyles();
  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<CreditCard>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let ccInfo = await Me.ListCreditCards();
      setCreditCards(ccInfo.Items);
      setTimeout(() => {
        setLoading(false);
      }, 750);
    })();
  }, []);

  // EDIT
  const openEditDialog = useCallback(
    (card) => {
      setSelectedCard(card);
      setEditOpen(!editOpen);
    },
    [editOpen]
  );

  const handleEditSubmit = useCallback(
    async (isSubmit: boolean, cc: any) => {
      if (isSubmit) {
        history.go(0);
        // setEditOpen(!editOpen);
      } else {
        setEditOpen(!editOpen);
      }
    },
    [editOpen, history]
  );

  // DELETE
  const openDeleteDialog = useCallback(
    (card) => {
      setSelectedCard(card);
      setDeleteOpen(!deleteOpen);
    },
    [deleteOpen]
  );

  const handleDeleteSubmit = useCallback(
    async (isSubmit: boolean) => {
      if (isSubmit) {
        if (!selectedCard?.ID) return;
        await bachmansIntegrationsService.deleteCCAsync(selectedCard.ID);
        setDeleteOpen(!deleteOpen);
        history.go(0);
      } else {
        setSelectedCard(undefined);
        setDeleteOpen(!deleteOpen);
      }
    },
    [deleteOpen, selectedCard, history]
  );

  return (
    <Fragment>
      {loading ? (
        <BachmansLoading text="Loading Credit Cards..." />
      ) : (
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {creditCards?.length > 0 ? (
                creditCards.map((card: CreditCard, i) => (
                  <Grid item xs={12} md={4} key={i} className={classes.gridItem}>
                    <div className={classes.ccCard}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">{card.CardholderName || ""}</Typography>
                        <Box display="flex" alignItems="center">
                          <Typography color="primary" variant="body2">
                            <Link className={classes.link} color="primary" onClick={() => openEditDialog(card)}>
                              Edit
                            </Link>
                            <Box display="inline-block" px={1.5}>
                              |
                            </Box>
                            <Link className={classes.link} color="primary" onClick={() => openDeleteDialog(card)}>
                              Delete
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.ccBody}>
                        <Typography display="inline" variant="body1">
                          {card?.CardType ? card?.CardType : "card"}
                        </Typography>
                        <Typography display="inline" variant="body1">
                          {" "}
                          ending in {card.PartialAccountNumber}
                        </Typography>
                        {/* <Typography variant="body1">{`${card?.CardType} ending in ${card.PartialAccountNumber}`}</Typography> */}
                        <Typography variant="body1">{`Expires ${moment(card.ExpirationDate)
                          .utc()
                          .format("M/yy")}`}</Typography>
                      </Box>
                    </div>
                    {/* Edit Dialog */}
                    <EditCCDialog
                      open={editOpen}
                      handleClose={handleEditSubmit}
                      creditCard={selectedCard}
                    ></EditCCDialog>
                    {/* Delete Dialog */}
                    <Dialog open={deleteOpen} aria-labelledby="Confirm-Delete-Credit-Card-form">
                      <DialogTitle>{`Are you sure you want to delete your ${
                        selectedCard?.CardType || ""
                      } card ending in ${1111}?`}</DialogTitle>
                      <DialogContent>
                        <DialogActions>
                          <Button type="button" onClick={() => handleDeleteSubmit(false)}>
                            No
                          </Button>
                          <Button type="button" onClick={() => handleDeleteSubmit(true)} color="primary">
                            Yes, Delete
                          </Button>
                        </DialogActions>
                      </DialogContent>
                    </Dialog>
                  </Grid>
                ))
              ) : (
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h5">No credit cards on profile</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
export default CreditCards;
