import { Box, createStyles, IconButton, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { BachmansProduct } from "../../../models/product";
import productService from "../../../services/product.service";
import stringService from "../../../services/string.service";
import { neutral } from "../../../themes/colors";
import ProductPrice from "../ProductPrice";
import VariantSelector from "./VariantSelector";
import { useHistory, useLocation } from "react-router-dom";
import { findKey, includes } from "lodash";
import { LocationState } from ".";

interface ProductSelectorProps {
  product: BachmansProduct;
  group: BachmansProduct[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productSelectorContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(145px, 1fr))",
      gap: theme.spacing(1.5),
    },
    variantColorContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, 32px)",
      gap: theme.spacing(1),
    },
    variantColorButton: {
      height: 32,
      width: 32,
      borderRadius: "50%",
      padding: 0,
      transition: "transform .25s cubic-bezier(0.4, 0, 0.2, 1)",
      "&:hover, &:focus, &:active": {
        transform: "translateY(-2px)",
      },
      "& .MuiIconButton-label": {
        height: "100%",
        width: "100%",
        borderRadius: "50%",
      },
    },
    colorSelectorOption: {
      height: "100%",
      width: "100%",
      backgroundSize: "cover",
      borderRadius: "50%",
      border: `2px solid ${theme.palette.grey[200]}`,
      boxShadow: theme.shadows[1],
    },
  })
);

const ProductSelector: React.FunctionComponent<ProductSelectorProps> = (props) => {
  const { product, group } = props;
  const sizeVariants = group.length > 1 && !!group[0].xp.SpecsOptions.Size;
  const colorVariants = group.length > 1 && !!group[0].xp.SpecsOptions.ColorVarient;

  return (
    <Fragment>
      {sizeVariants && <SizeSelector product={product} group={group} />}
      {colorVariants && <ColorSelector product={product} group={group} />}
    </Fragment>
  );
};

const ColorSelector: React.FunctionComponent<ProductSelectorProps> = (props) => {
  const { group, product } = props;
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const location = useLocation<LocationState>();
  const [colorMap, setColorMap] = useState<any>();

  useEffect(() => {
    productService.GetColorMap().then((response) => setColorMap(response.data));
  }, []);

  const selectVariant = (sku: string) => {
    history.push({
      search: `?sku=${sku}`,
      state: {
        path: location?.state?.path,
        search: location?.state?.search,
      },
    });
  };

  const getColorBG = (variant: BachmansProduct) => {
    var style: any = {};
    const colorVariant = variant.xp.SpecsOptions.ColorVarient;
    var background = findKey(colorMap, function (color) {
      return includes(color, colorVariant);
    });
    if (background && background.indexOf("/") > -1) {
      style.backgroundImage = 'url("' + background + '")';
    } else {
      style.backgroundColor = background ? background : colorVariant;
    }
    if (variant.ID === product.ID) {
      style.borderColor = theme.palette.primary.main;
    }
    return style;
  };

  return (
    <Box>
      <ProductPrice product={product} alignment="left" />
      <Typography variant="overline" style={{ color: neutral.text_white_bg }}>
        {`Color: ${product.xp.SpecsOptions.ColorVarient}`}
      </Typography>
      <Box className={classes.variantColorContainer}>
        {group.map((variant, i) => (
          <IconButton className={classes.variantColorButton} key={i} onClick={() => selectVariant(variant.ID!)}>
            <div className={classes.colorSelectorOption} style={getColorBG(variant)}></div>
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};

const SizeSelector: React.FunctionComponent<ProductSelectorProps> = (props) => {
  const { group, product } = props;
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation<LocationState>();

  const selectVariant = (sku: string) => {
    history.push({
      search: `?sku=${sku}`,
      state: {
        path: location?.state?.path,
        search: location?.state?.search,
      },
    });
  };

  return (
    <Box>
      <Typography variant="overline" style={{ color: neutral.text_white_bg }}>
        Select Size
      </Typography>
      <Box className={classes.productSelectorContainer}>
        {group.map((variant, i) => (
          <VariantSelector variant={variant} selected={variant.ID === product.ID} selectVariant={selectVariant} key={i}>
            <Typography variant="body2">{stringService.CapitalizeString(variant.xp.SpecsOptions.Size)}</Typography>
            <ProductPrice product={variant}></ProductPrice>
          </VariantSelector>
        ))}
      </Box>
    </Box>
  );
};

export default ProductSelector;
