import { Grid, Typography, makeStyles, createStyles, Theme } from "@material-ui/core";
import { filter, map } from "lodash";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { BachmansEvent } from "../../models/product";
import { SessionContext } from "../../providers/session";
import bachDateTimeService from "../../services/bachDateTime.service";
import eventService from "../../services/event.service";
import { neutral } from "../../themes/colors";
import BachmansCard from "../Shared/BachmansCard";
import BachmansLoading from "../Shared/BachmansLoading";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      containerUpcoming: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
      },
      containerPast: {
        backgroundColor: neutral.offwhite_bg,
        marginTop: theme.spacing(2),
      },
      cardPast: {
        opacity: 0.65,
        pointerEvents: "none",
      },
    })
  );

const Events = () => {
  const classes = useStyles()();
  const { user, anonymous } = useContext(SessionContext);
  const [past, setPast] = useState<BachmansEvent[]>([]);
  const [upcoming, setUpcoming] = useState<BachmansEvent[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    if (mounted && user) {
      (async () => {
        let now = bachDateTimeService.Today();
        let purchasedEvents = await eventService.ListPurchasedEventsAsync(user, anonymous);
        let pastEvents = map(
          filter(purchasedEvents, (event) => now.isAfter(event.xp?.ReleaseDate2, "d")),
          (event) => {
            event.IsPast = true;
            return event;
          }
        );
        let upcomingEvents = filter(purchasedEvents, (event) => now.isSameOrBefore(event.xp?.ReleaseDate2, "d"));
        setUpcoming(upcomingEvents);
        setPast(pastEvents);
        setTimeout(() => {
          setLoading(false);
        }, 750);
      })();
    }
    return () => {
      mounted = false;
    };
  }, [user, anonymous]);
  return (
    <Fragment>
      {loading ? (
        <BachmansLoading text="Loading Events..." />
      ) : (
        <Fragment>
          <Typography variant="subtitle1">UPCOMING EVENTS</Typography>
          <Grid container spacing={3} className={classes.containerUpcoming}>
            {upcoming && upcoming.length > 0 ? (
              upcoming.map((event, i) => (
                <Grid item xs={6} sm={4}>
                  <BachmansCard variant="event" key={i} event={event} purchased={true} />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="h5">No Events Found</Typography>
              </Grid>
            )}
          </Grid>

          <Typography variant="subtitle1">PAST EVENTS</Typography>
          <Grid container spacing={3} className={classes.containerPast}>
            {past && past.length > 0 ? (
              past.map((event, i) => (
                <Grid item xs={6} sm={4} className={classes.cardPast}>
                  <BachmansCard variant="event" key={i} event={event} purchased={true} />
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="h5">No Events Found</Typography>
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Events;
