import React, { Fragment, FunctionComponent } from "react";
import { Button, createStyles, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { neutral, purple } from "../../themes/colors";
import { searchType } from "../../models/search";

interface SearchSummaryProps {
  queryTerm?: string;
  products: number;
  events: number;
  infoResults: number;
  activeType: searchType;
  onTypeClick: (searchType: searchType) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      display: "inline-flex",
      margin: theme.spacing(3, 0, 2, 0),
    },
    titleSearch: {
      fontWeight: "normal",
      color: neutral.text_white_bg,
    },
    button: {
      marginRight: theme.spacing(1),
      color: theme.palette.grey[500],
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[500]}`,
      flex: 1,
      [theme.breakpoints.up("md")]: {
        flex: "unset",
        padding: theme.spacing(1, 2),
      },
    },
    buttonGroup: {
      display: "flex",
      "& button:last-child": {
        marginRight: 0,
      },
    },
    queryTerm: {
      marginLeft: theme.spacing(1),
    },
    active: {
      color: purple.regular,
      border: `1px solid ${purple.regular}`,
    },
  })
);

const resultButton = (title: string, resultLength: number, isDesktop: boolean) => {
  return (
    <Fragment>
      <Typography style={{ fontSize: ".9rem" }} variant={"h5"}>
        {title}
      </Typography>
      <Typography style={{ fontSize: ".6rem", margin: "0 0 0 3px" }} variant={"body2"}>
        ({resultLength}
        {isDesktop ? " Results" : ""})
      </Typography>
    </Fragment>
  );
};

const SearchSummary: FunctionComponent<SearchSummaryProps> = (props) => {
  const classes = useStyle();
  const { queryTerm, products, events, infoResults, activeType, onTypeClick } = props;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const handleOnClick = (searchType: searchType) => (event: React.MouseEvent) => {
    onTypeClick(searchType);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography className={classes.titleSearch} variant="h2">
          Search Results for:
        </Typography>
        <Typography className={classes.queryTerm} variant="h2" color="primary">
          "{queryTerm}"
        </Typography>
      </div>
      <div className={classes.buttonGroup}>
        <Button
          onClick={handleOnClick("Products")}
          className={`${classes.button} ${activeType === "Products" ? classes.active : ""}`}
        >
          {resultButton("Products", products, isDesktop)}
        </Button>
        <Button
          onClick={handleOnClick("Events")}
          className={`${classes.button}  ${activeType === "Events" ? classes.active : ""}`}
        >
          {resultButton("Events", events, isDesktop)}
        </Button>
        <Button
          onClick={handleOnClick("CareInfo")}
          className={`${classes.button}  ${activeType === "CareInfo" ? classes.active : ""}`}
        >
          {resultButton("Resource Hub", infoResults, isDesktop)}
        </Button>
      </div>
    </div>
  );
};

export default SearchSummary;
