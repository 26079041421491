import React, { FunctionComponent, useCallback, useContext } from "react";
import { Button, Container, createStyles, List, ListItem, makeStyles, Theme } from "@material-ui/core";
import MobileDrawer from "../../Shared/MobileDrawer";
import { accountLinks } from "../../Account/Account";
import Link from "../../Shared/Link";
import { SessionContext } from "../../../providers/session";
import PurplePerks from "../../Shared/PurplePerksCard";
import { useHistory } from "react-router-dom";
interface MobileAccountMenuProps {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const MobileAccountMenu: FunctionComponent<MobileAccountMenuProps> = (props) => {
  const classes = useStyle();
  const { menuOpen, toggleMenu } = props;
  const { user } = useContext(SessionContext);
  const { logout } = useContext(SessionContext);
  const history = useHistory();

  const handleLogout = useCallback(() => {
    if (logout) {
      logout();
      history.push("/");
      toggleMenu();
    }
  }, [logout, toggleMenu, history]);

  return (
    <div className={classes.root}>
      <MobileDrawer menuOpen={menuOpen} toggleMenu={toggleMenu} menuTitle={"My Account"}>
        <Container maxWidth="lg">
          <List>
            {accountLinks.map((linkInfo, index) => (
              <ListItem key={index}>
                <Link
                  key={index}
                  to={`/account/${linkInfo.path}`}
                  variant="body1"
                  color="textPrimary"
                  display="block"
                  gutterBottom
                  onClick={toggleMenu}
                >
                  {linkInfo.label}
                </Link>
              </ListItem>
            ))}
            {user && (
              <Button onClick={handleLogout} color="primary">
                Sign out
              </Button>
            )}

            <PurplePerks type="stacked" />
          </List>
        </Container>
      </MobileDrawer>
    </div>
  );
};

export default MobileAccountMenu;
