import React, { Fragment, useEffect, useRef, useState } from "react";
import { BuyerProduct, LineItemSpec, Me, Spec, SpecOption } from "ordercloud-javascript-sdk";
import { Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Theme, Typography } from "@material-ui/core";
import { SpecData } from ".";
import { neutral } from "../../../themes/colors";

export interface ProductOptionProps {
  product?: BuyerProduct;
  specData?: SpecData;
  deliveryMethod?: "Delivery" | "PickUp";
  onSpecChange?: (data: SpecData) => void;
}

export interface BachmansFees {
  AssemblyOpts?: any;
  PlacementOpts?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkBox: {
      paddingTop: 2,
      paddingBottom: 2,
    },
    headerText: {
      color: neutral.text_white_bg,
      marginTop: "10px",
    },
  })
);
const ProductOptions: React.FunctionComponent<ProductOptionProps> = (props) => {
  const classes = useStyles();
  const { product, onSpecChange, specData, deliveryMethod } = props;
  const [bachmansFees, setBachmansFees] = useState<BachmansFees>();
  const [assemblySelected, setAssemblySelected] = useState<boolean>();
  const [placementSelected, setPlacementSelected] = useState<boolean>();
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const buildFeeObj = (key: string, type: string, fees?: Spec[]) => {
      let step1 = fees?.filter((fee) => {
        return fee?.Name?.indexOf(type) > -1;
      });
      let step2: any = step1?.length ? step1[0] : {};
      if (step2.Options?.length) {
        const markupOption = step2.Options.filter((option: SpecOption) => {
          return (
            (option?.Value && option.Value.toUpperCase().indexOf("INCLUDED") > -1) ||
            (option?.PriceMarkup && option.PriceMarkup > 0)
          );
        });
        step2.IncludedOpt = markupOption[0];
        let defaultOption = step2.Options.find((opt: SpecOption) => opt.ID === step2.DefaultOptionID);
        step2.ExcludedOpt = defaultOption;
      }
      return step2;
    };

    if (product && product.ID) {
      Me.ListSpecs(product.ID).then((specs) => {
        if (componentMounted.current) {
          const liSpecs: LineItemSpec[] = specs.Items.map((s) => {
            return {
              SpecID: s.ID,
              OptionID: s.DefaultOptionID,
            };
          });
          if (liSpecs?.length && onSpecChange) {
            onSpecChange({
              Specs: liSpecs,
              ASMMarkup: 0,
              PLCMarkup: 0,
            });
          }
          const fees = specs.Items;
          const feeObj: BachmansFees = {};
          feeObj.AssemblyOpts = buildFeeObj("AssemblyOpts", "ASSEMBLY", fees);
          feeObj.PlacementOpts = buildFeeObj("PlacementOpts", "PLACEMENT", fees);
          setBachmansFees(feeObj);
        }
      });
    }
  }, [product, onSpecChange]);

  useEffect(() => {
    const assembly = specData?.Specs?.filter((s) => s.OptionID === bachmansFees?.AssemblyOpts?.IncludedOpt?.ID);
    const placement = specData?.Specs?.filter((s) => s.OptionID === bachmansFees?.PlacementOpts?.IncludedOpt?.ID);
    setPlacementSelected(!!(placement && placement.length > 0));
    setAssemblySelected(!!(assembly && assembly.length > 0));
  }, [specData, bachmansFees]);

  const hasAssembly = (): boolean => bachmansFees && bachmansFees.AssemblyOpts?.Options?.length;

  const hasPlacement = (): boolean =>
    bachmansFees && bachmansFees.PlacementOpts?.Options?.length && deliveryMethod !== "PickUp";

  const getHeaderText = () => {
    if (hasAssembly() && hasPlacement()) {
      return "Assembly & Placement Options";
    } else if (hasAssembly()) {
      return "Assembly Options";
    } else if (hasPlacement()) {
      return "Placement Options";
    }
  };

  const handleSpecChange = (type: "assembly" | "placement") => (event: React.ChangeEvent<HTMLInputElement>) => {
    const assembly = type === "assembly" ? event.target.checked : assemblySelected || false;
    const placement = type === "placement" ? event.target.checked : placementSelected || false;
    updateProductSpecs(assembly, placement);
  };

  const updateProductSpecs = (assembly: boolean, placement: boolean) => {
    let productSpecs = [];
    if (hasAssembly()) {
      productSpecs.push({
        SpecID: bachmansFees?.AssemblyOpts.ID,
        OptionID: assembly ? bachmansFees?.AssemblyOpts.IncludedOpt.ID : bachmansFees?.AssemblyOpts.ExcludedOpt.ID,
      });
    }
    if (hasPlacement()) {
      productSpecs.push({
        SpecID: bachmansFees?.PlacementOpts.ID,
        OptionID: placement ? bachmansFees?.PlacementOpts.IncludedOpt.ID : bachmansFees?.PlacementOpts.ExcludedOpt.ID,
      });
    }
    if (onSpecChange) {
      onSpecChange({
        Specs: productSpecs,
        ASMMarkup: assembly ? bachmansFees?.AssemblyOpts.IncludedOpt.PriceMarkup : null,
        PLCMarkup: placement ? bachmansFees?.PlacementOpts.IncludedOpt.PriceMarkup : null,
      });
    }
  };

  return (
    <Fragment>
      {(hasAssembly() || hasPlacement()) && (
        <Fragment>
          <Typography className={classes.headerText} variant="h4">
            {getHeaderText()}
          </Typography>
          <FormGroup>
            {hasAssembly() && (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={assemblySelected}
                    onChange={handleSpecChange("assembly")}
                  />
                }
                label={`Include Assembly $${bachmansFees?.AssemblyOpts.IncludedOpt.PriceMarkup}`}
              />
            )}
            {hasPlacement() && (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={placementSelected}
                    onChange={handleSpecChange("placement")}
                  />
                }
                label={`Include Placement $${bachmansFees?.PlacementOpts.IncludedOpt.PriceMarkup}`}
              />
            )}
          </FormGroup>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductOptions;
