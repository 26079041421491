import React, { Fragment, FunctionComponent, useCallback, useContext, useMemo } from "react";
import { Collapse, createStyles, List, ListItem, ListItemText, makeStyles, Theme } from "@material-ui/core";
import { Category } from "ordercloud-javascript-sdk";
import { Add, Remove } from "@material-ui/icons";
import { CatalogContext } from "../../../../providers/catalog";
import { purple } from "../../../../themes/colors";
import { contenfulStaticPageInfo, contentfulContentLinkAndTitle } from "../ContentLinksMenu";
import { isOfType } from "../../../../models/generics";
import { StaticPage } from "../../../../models/contentful/staticPage";

interface MobileMenuSubCategoryProps {
  category?: Category;
  categoryClickCallbak?: (
    category: Category,
    showSubCategory?: boolean,
    l3category?: boolean
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  resource?: StaticPage | contentfulContentLinkAndTitle;
  resourceClickCallback?: (
    resource: StaticPage | contentfulContentLinkAndTitle
  ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  expandedIndex: number;
  expandCallback: (index: number) => void;
  index: number;
  selectedID?: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "none",
    },
    categoryTitleWrapper: {},
    categoryTitle: {
      color: purple.regular,
      margin: "0px",
      "&:hover": {
        textDecoration: "underline",
      },
      "& > .MuiTypography-h5": {
        fontSize: "16px",
      },
    },
    subCatTitle: {
      "& > .MuiTypography-body1": {
        fontSize: "16px",
      },
    },
    expandIcon: {
      display: "flex",
      justifyContent: "flex-end",
      width: "50%",
      color: purple.regular,
    },
    viewRelatedArticles: {},
    selected: {
      color: purple.regular,
    },
  })
);

const MobileMenuSubCategory: FunctionComponent<MobileMenuSubCategoryProps> = (props) => {
  const classes = useStyle();
  const { categories } = useContext(CatalogContext);
  const {
    index,
    expandCallback,
    expandedIndex,
    category,
    categoryClickCallbak,
    resource,
    resourceClickCallback,
    selectedID,
  } = props;

  const isExpanded = useMemo(() => {
    return expandedIndex === index;
  }, [expandedIndex, index]);

  const handleExpandClick = useCallback(
    (index: number) => (e: React.MouseEvent) => {
      expandCallback(index);
    },
    [expandCallback]
  );

  const subCategoryChildren = useMemo(() => {
    if (category && categories) {
      let subCategoryChildren = categories.filter((c: Category) => c.ParentID === category.ID);

      return subCategoryChildren.map((cat: Category, index: number) => (
        <List dense disablePadding key={index}>
          <ListItem button onClick={categoryClickCallbak && categoryClickCallbak(cat, false, true)}>
            <ListItemText
              className={classes.subCatTitle}
              primary={cat.Name}
              primaryTypographyProps={{ variant: "body1" }}
            />
          </ListItem>
        </List>
      ));
    }
    if (resource && isOfType<contenfulStaticPageInfo>(resource, "children")) {
      return resource?.children?.map((page: StaticPage, index: number) => (
        <List dense disablePadding key={index}>
          <ListItem
            button
            selected={selectedID === page.fields.slug}
            onClick={resourceClickCallback && resourceClickCallback(page)}
          >
            <ListItemText
              className={`${classes.subCatTitle} ${selectedID === page.fields.slug ? classes.selected : ""}`}
              primary={page.fields?.title}
              primaryTypographyProps={{ variant: "body1" }}
            />
          </ListItem>
        </List>
      ));
    }
  }, [
    category,
    categories,
    categoryClickCallbak,
    classes.subCatTitle,
    resourceClickCallback,
    resource,
    selectedID,
    classes.selected,
  ]);

  const isStaticPageWithKids = (resource: StaticPage | contentfulContentLinkAndTitle) => {
    if (isOfType<contenfulStaticPageInfo>(resource, "children")) {
      if (resource?.children && resource?.children?.length > 0) return true;
    }
    return false;
  };

  const expandIcons = useMemo(() => {
    if (category?.ChildCount || (resource && isStaticPageWithKids(resource))) {
      let displayIcon = isExpanded ? <Remove /> : <Add />;
      return (
        <div className={classes.expandIcon} onClick={handleExpandClick(index)}>
          {displayIcon}
        </div>
      );
    } else {
      return;
    }
  }, [isExpanded, category, handleExpandClick, classes.expandIcon, index, resource]);

  const getPageTitle = (resource: StaticPage | contentfulContentLinkAndTitle) => {
    if (isOfType<contenfulStaticPageInfo>(resource, "fields")) {
      return resource.fields.title;
    }
    if (isOfType<contentfulContentLinkAndTitle>(resource, "link")) {
      return resource.title;
    }
  };

  return (
    <Fragment>
      <ListItem button key={index} selected={isExpanded} className={classes.categoryTitleWrapper}>
        <ListItemText
          className={classes.categoryTitle}
          primary={category ? category?.Name : resource ? getPageTitle(resource) : null}
          primaryTypographyProps={{ variant: "h5" }}
          onClick={
            categoryClickCallbak && category
              ? categoryClickCallbak(category ? category : ({} as Category), false)
              : resourceClickCallback && resource && resourceClickCallback(resource)
          }
        ></ListItemText>
        {expandIcons}
      </ListItem>
      <Collapse in={isExpanded}>
        <List dense>{subCategoryChildren}</List>
      </Collapse>
    </Fragment>
  );
};

export default MobileMenuSubCategory;
