import IframeResizer from "iframe-resizer-react";

const PlantLibrary: React.FunctionComponent = () => {
  return (
    <div className="iframeParent">
      {" "}
      <IframeResizer
        log
        heightCalculationMethod="bodyScroll"
        className="treeShrubsIframe"
        src="https://www.qscaping.com/12070012/"
        autoResize={true}
        scrolling={true}
        style={{ width: "1px", minWidth: "100%", minHeight: "100%", border: "0" }}
      />
    </div>
  );
};

export default PlantLibrary;
