import React, { Fragment, FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  ButtonBase,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { find } from "lodash";
import DropdownMenu from "./DropdownMenu";
import { neutral } from "../../themes/colors";

interface SortbyProps {
  type?: string;
  display?: boolean;
  currentSortState: string;
  sortOptions: SortOption[];
  handleSelection: (option: SortOption) => void;
}
export interface SortOption {
  title: string;
  index: string; //algolia index
  callback?: (option: any) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    btnSortBy: {
      padding: theme.spacing(1),
      display: "flex",
      backgroundColor: neutral.text_utility_bg,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "& svg": {
          marginLeft: "auto",
        },
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: "auto",
        "& svg": {
          marginLeft: 4,
          transform: "translateY(1.5px)", // Feel free to delete this, but I thought the caret position looked too high.
        },
      },
    },
    textContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "center",
      },
    },
    currentSortLabel: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
      lineHeight: 1,
    },
    currentSortTitle: {
      fontSize: theme.typography.h5.fontSize,
    },
  })
);

const Sortby: FunctionComponent<SortbyProps> = (props) => {
  const classes = useStyle();
  const { currentSortState, sortOptions, handleSelection } = props;
  const [sortByMenuOpen, setSortByMenuOpen] = useState(false);
  const containerRef = useRef<any>();
  const [anchorRef, setAnchorRef] = useState<any>();

  const toggleSortByMenu = () => {
    setSortByMenuOpen((state) => !state);
  };

  useEffect(() => {
    if (containerRef.current) {
      setAnchorRef(containerRef.current);
    }
  }, [containerRef]);

  const currentSort: any = useMemo(() => {
    if (currentSortState) {
      return find(sortOptions, { title: currentSortState });
    } else {
      return sortOptions[0];
    }
  }, [currentSortState, sortOptions]);

  const sortOptionSelection = (option: SortOption) => {
    handleSelection(option);
    toggleSortByMenu();
  };

  return (
    <Fragment>
      {currentSort && (
        <Fragment>
          <ButtonBase className={classes.btnSortBy} ref={containerRef} onClick={toggleSortByMenu}>
            <div className={classes.textContainer}>
              <Typography className={classes.currentSortLabel} variant="body1">
                Sort By:
              </Typography>
              <Typography className={classes.currentSortTitle} variant="h4" color="primary">
                {currentSort.title}
              </Typography>
            </div>
            {sortByMenuOpen ? (
              <KeyboardArrowUp fontSize="small" color="primary" />
            ) : (
              <KeyboardArrowDown fontSize="small" color="primary" />
            )}
          </ButtonBase>
          <DropdownMenu open={sortByMenuOpen} anchorEl={anchorRef} arrowPosition={"right"}>
            <Box onMouseLeave={() => setSortByMenuOpen(false)}>
              <List>
                {sortOptions &&
                  sortOptions.map((option, index) => (
                    <ListItem button key={index} onClick={(e: any) => sortOptionSelection(option)}>
                      <ListItemText
                        primary={option.title}
                        primaryTypographyProps={{ variant: "h5", color: "textSecondary" }}
                      ></ListItemText>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </DropdownMenu>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Sortby;
