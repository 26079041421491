export interface RegexConstants {
  validPassword: RegExp;
  validEmail: RegExp;
  validCardMessage: RegExp;
  validName: RegExp; // true if name is valid
  invalidName: RegExp; // true if name is invalid
  nonNumeric: RegExp;
  alphaNumeric: RegExp;
  validPhone: RegExp;
}

export const regexConstants: RegexConstants = {
  validPassword: /^(?=.*\d)(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%])(.{10,})$/,
  validEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  validCardMessage: /^[a-zA-Z0-9_ !@#$%\^*+=\-_;:",.?]*$/,
  validName: /[a-zA-Z_ .'-]$/g,
  invalidName: /[^a-zA-Z_ .'-]/g,
  nonNumeric: /[^0-9]/g,
  alphaNumeric: /^[a-zA-Z0-9]*$/,
  validPhone: /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/,
};
