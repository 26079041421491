export const PageTitles = {
  PlantLibrary: {
    title: "Plant, Tree & Shrub Library",
  },
  Employment: {
    title: "Employment",
  },
  GiftCards: {
    title: "Gift Cards",
  },
  OnSaleThisWeek: {
    slug: "on-sale-this-week",
  },
  BachmansBlog: {
    title: "Bachman's Blog",
  },
  WorkshopsEvents: {
    title: "Workshops & Events",
  },
  PurplePerks: {
    slug: "purple-perks-loyalty-program",
  },
};
