import React, { Fragment, FunctionComponent, useCallback } from "react";
import {
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  Theme,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { CreditCard } from "ordercloud-javascript-sdk";
import { Close } from "@material-ui/icons";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import moment from "moment-timezone";
import { useState } from "react";
import creditCardService from "../../../services/creditCard.service";

interface EditCCDialogProps {
  open: boolean;
  creditCard: CreditCard | undefined;
  handleClose: (isSubmitting: boolean, card?: CreditCard) => {};
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dialogPaper: {
      maxWidth: "100%",
      width: 600,
    },
    title: {},
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);
interface FormValues {
  name: string;
  month: string;
  year: string;
}

const EditCCDialog: FunctionComponent<EditCCDialogProps> = (props) => {
  const classes = useStyle();
  const { open, handleClose, creditCard } = props;
  const [expMonth, setExpMonth] = useState<string>("");
  const [expYear, setExpYear] = useState<any>("");
  const [years, setYears] = useState<string[]>([]);
  const [months, setMonths] = useState<string[]>([]);

  const validationSchema = yup.object({
    name: yup.string(),
    month: yup.string(),
    year: yup.string(),
  });

  const handleSubmit = useCallback(
    async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>): Promise<void> => {
      if (!creditCard?.ID) return console.error("no cc");
      let newCardInfo = Object.assign(creditCard, {
        CardholderName: values.name,
        ExpMonth: values.month,
        ExpYear: values.year,
      });
      let updatedCC: any = (await creditCardService.UpdateAsync(creditCard?.ID, newCardInfo)) as any;
      handleClose(true, updatedCC?.data as CreditCard);
      setSubmitting(false);
      return;
    },
    [creditCard, handleClose]
  );

  const initYears = (currentYear: number) => {
    let yearsArray = [];
    for (let i = 0; i < 12; i++) {
      yearsArray.push((currentYear + i).toString());
    }
    return yearsArray;
  };

  const initMonths = () => {
    let months: any[] = [];
    for (let i = 1; i <= 12; i++) {
      let display = i.toString();
      if (i < 10) display = "0" + display;
      months.push(display);
    }
    return months;
  };

  useEffect(() => {
    if (creditCard) {
      let now = moment.utc();
      let currentYear = Number(now.format("YYYY"));
      let expDate = moment.utc(creditCard?.ExpirationDate);
      setExpMonth(expDate.format("MM"));
      setExpYear(Number(expDate.format("YYYY")));
      setYears(initYears(currentYear));
      setMonths(initMonths());
    }
  }, [creditCard]);

  return creditCard ? (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={() => handleClose(false)}
      aria-labelledby="Edit-Credit-Card-form"
      className={classes.root}
      maxWidth="md"
    >
      <DialogTitle>
        <MuiDialogTitle className="title">
          <Typography>{`Editing ${creditCard?.CardType || ""} ending in ${
            creditCard.PartialAccountNumber
          }`}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
            <Close />
          </IconButton>
        </MuiDialogTitle>
      </DialogTitle>
      <Formik
        initialValues={{
          name: creditCard?.CardholderName || "",
          month: expMonth || months[0],
          year: expYear || years[0],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  margin="dense"
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  label="Cardholder Name*"
                  type="text"
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />

                <FormControl className={classes.formControl}>
                  <InputLabel id="expiration-month-label">Expiration Month</InputLabel>
                  <Select
                    labelId="expiration-month-label"
                    id="expiration-month-label"
                    name="month"
                    value={values.month}
                    onChange={handleChange}
                    label="Expiration Month"
                  >
                    {months &&
                      months.map((value: any, i: number) => (
                        <MenuItem key={i} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel id="expiration-year-label">Expiration Year</InputLabel>
                  <Select
                    label="Expiration Year"
                    labelId="expiration-year-label"
                    id="expiration-year-label"
                    name="year"
                    value={values.year}
                    onChange={handleChange}
                  >
                    {years &&
                      years.map((value: any, i: number) => (
                        <MenuItem key={i} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button type="button" onClick={() => handleClose(false)}>
                  Cancel
                </Button>
                <Button type="submit" onClick={() => handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </Fragment>
        )}
      </Formik>
    </Dialog>
  ) : null;
};
export default EditCCDialog;
