import {
  alpha,
  Breadcrumbs as MaterialBreadcrumbs,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useAppSelector } from "../../../redux/store-hook";
import Link from "../../Shared/Link";
import { neutral } from "../../../themes/colors";

const useStyles = (dark?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: dark ? alpha(theme.palette.grey[900], 0.8) : alpha(neutral.offwhite_bg, 0.8),
        padding: theme.spacing(1, 0),
        boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 5%)",
      },
      textColor: {
        color: dark ? "white" : "inherit",
      },
    })
  );

const Breadcrumbs: FunctionComponent = (props) => {
  const { visible, links, current, dark, hideHome } = useAppSelector((state) => state.breadcrumbs);
  const classes = useStyles(dark)();
  return visible ? (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <MaterialBreadcrumbs separator={">"} className={classes.textColor}>
          {!hideHome && (
            <Link to="/" color="inherit">
              Home
            </Link>
          )}
          {links.map((l, i) => (
            <Link key={i} to={l.path} color="inherit">
              {l.label}
            </Link>
          ))}
          {current && current !== "" && <Typography>{current}</Typography>}
        </MaterialBreadcrumbs>
      </Container>
    </div>
  ) : null;
};

export default Breadcrumbs;
