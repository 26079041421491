import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Address, BuyerAddress, BuyerProduct, LineItem } from "ordercloud-javascript-sdk";
import { BachmansShipmentWithItems } from "../../models/shipment";
import { CatalogContext } from "../../providers/catalog";
import deliveryResourceService from "../../services/delivery-resource.service";
import deliveryService, { DeliveryDateData } from "../../services/delivery.service";
import { BuyerXp, BuyerXpDeliveryRun, BuyerXpDeliveryRunKey, DeliveryTypes } from "../../models/buyerXp";
import bachDateTime from "../../services/bachDateTime.service";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import { cloneDeep, forIn, isUndefined, sumBy } from "lodash";
import { neutral } from "../../themes/colors";
import CloseIcon from "@material-ui/icons/Close";
import productService from "../../services/product.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "50vw",
        minHeight: "50vh",
      },
      "& .MuiDialogContent-root": {
        padding: theme.spacing(3),
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(1, 3, 3, 3),
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(3),
        flexFlow: "row nowrap",
      },
    },
    dialogContent: {
      display: "flex",
      justifyContent: "center",
    },
    addressBox: {
      marginTop: theme.spacing(1),
    },
    placeholderText: {
      color: neutral.strikethrough,
    },
    boldText: {
      fontWeight: "bold",
    },
    addressName: {
      display: "inline",
      fontWeight: "bold",
      color: neutral.text_colored_bg,
    },
    addressBody: {
      fontStyle: "italic",
      display: "inline",
      marginLeft: "5px",
      color: neutral.text_colored_bg,
    },
    header: {
      backgroundColor: neutral.grey_background,
      padding: theme.spacing(3),
      position: "relative",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    buttonClose: {
      position: "relative",
      marginTop: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      alignSelf: "flex-end",
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        top: 4,
        right: 4,
        margin: 0,
      },
    },
    headerConfirmation: {
      display: "flex",
      flexFlow: "row nowrap",
      gap: theme.spacing(4),
      alignItems: "stretch",
      justifyContent: "center",
    },
    headerTextContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    toggleButton: {
      borderRadius: 0,
      minHeight: 66,
      "& .MuiToggleButton-label": {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "flex-start",
      },
    },
    gridCalendar: {
      flexGrow: 0,
      "& .MuiPickersCalendarHeader-transitionContainer": {
        // Month Year
        height: 31,
        "& p": { fontSize: 18 },
      },
      "& .MuiPickersStaticWrapper-staticWrapperRoot": {
        boxShadow: theme.shadows[1],
      },
      "& .MuiPickersDay-daySelected.MuiPickersDay-dayDisabled": {
        // Selected Date
        color: "rgba(255, 255, 255, 0.75)",
      },
      "& .MuiPickersCalendarHeader-dayLabel": {
        // Days of the week
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    productInfo: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      "& img": {
        width: "60px",
        aspectRatio: "1 / 1",
        objectFit: "cover",
      },
    },
    formRadio: {
      "& .MuiFormLabel-root": {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
        "&.Mui-focused": {
          color: theme.palette.text.secondary,
        },
      },
      "& .MuiFormGroup-root": {
        gap: theme.spacing(0.5),
        "& .MuiFormControlLabel-root": {
          margin: 0,
          "&:not(:first-child)": {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          },
        },
      },
    },
    formLabel: {
      width: "100%",
      minHeight: 66,
      margin: "-1px 0 0 0",
      border: `1px solid ${neutral.grey_background}`,
      color: neutral.text_white_bg,
      padding: theme.spacing(1),
    },
    formLabelChecked: {
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
    },
    surchargeLable: {
      fontWeight: "bold",
      display: "inline-block",
    },
  })
);

interface DeliveryModalProps {
  open: boolean;
  products: BuyerProduct[];
  address?: BuyerAddress;
  shipment: BachmansShipmentWithItems;
  lineItem?: LineItem;
  onClose: () => void;
  onSubmit?: (partial: Partial<LineItem>) => Promise<void>;
}
type DateOptions = "FirstAvailable" | "Select";
const DeliveryDateModal: React.FunctionComponent<DeliveryModalProps> = (props) => {
  const classes = useStyles();
  const { open, products, shipment, address, lineItem, onClose, onSubmit } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { DCFcategories } = useContext(CatalogContext);
  const [lyndale, setLyndale] = useState<Address>();
  const [buyerXp, setBuyerXp] = useState<BuyerXp>();
  const [dateOption, setDateOption] = useState<DateOptions>();
  const [bachmansDateOptions, setBachmansDateOptions] = useState<DeliveryDateData>();
  const [dateEditable, setDateEditable] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(false);
  const [timePreference, setTimePreference] = useState<BuyerXpDeliveryRunKey>();
  const [availableRuns, setAvailableRuns] = useState<BuyerXpDeliveryRun>();
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (componentMounted.current) {
      setTimePreference(
        shipment?.xp?.TimePreference ? (shipment?.xp?.TimePreference as BuyerXpDeliveryRunKey) : undefined
      );
    }
    if (DCFcategories && products && shipment) {
      (async () => {
        const [buyerXpObj, lyndaleStore] = await Promise.all([
          deliveryResourceService.GetBuyerXp(),
          deliveryResourceService.GetLyndaleStore(),
        ]);
        const methods = deliveryService.GetAvailableDeliveryMethods(products, DCFcategories);
        const options = await deliveryService.GetDateOptions({
          products: products,
          deliveryMethods: methods,
          shippingAddress: shipment.ToAddress,
        });
        if (componentMounted.current) {
          setLyndale(lyndaleStore);
          setBuyerXp(buyerXpObj);
          var shipperIdObj = shipment.Shipper;
          if (shipment.Shipper == "TFE") {
            shipperIdObj = "Wired";
          }
          const bachDateOptions = options[shipperIdObj as DeliveryTypes];

          setBachmansDateOptions(bachDateOptions);
          if (bachDateOptions?.minDate) {
            setDateEditable(new Date(moment(bachDateOptions?.minDate).format("MMMM DD YYYY")));
            setDateOption("FirstAvailable");
          }
        }
      })();
    }
  }, [DCFcategories, products, shipment]);

  const dateDisabled = (day: MaterialUiPickersDate): boolean => {
    if (buyerXp && lyndale) {
      let disabled = deliveryService.IsDateDisabled(
        address as Address,
        products,
        buyerXp,
        lyndale,
        shipment.Shipper as DeliveryTypes,
        {
          mode: "day",
          date: day as Date,
        }
      );
      return disabled;
    } else return false;
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date && date != null) {
      setDateEditable(date);
      if (bachmansDateOptions && bachDateTime.IsSameDay(date, bachmansDateOptions.minDate)) {
        setDateOption("FirstAvailable");
      } else {
        setDateOption("Select");
      }
    }
  };

  const disableRun = useCallback(
    (key: string, runs: BuyerXpDeliveryRun) => {
      runs[key as BuyerXpDeliveryRunKey].disabled = true;
      if (timePreference === key && runs[key as BuyerXpDeliveryRunKey].disabled) {
        setTimePreference(undefined);
      }
      return runs;
    },
    [timePreference]
  );

  const disableRunsForLocalDelivery = useCallback(
    (requestedMoment: moment.Moment, runKey: string, runs: BuyerXpDeliveryRun) => {
      if (buyerXp) {
        let preference = buyerXp.DeliveryDatesN[requestedMoment.utc().format("YYYY-MM-DD")];
        runs[runKey as BuyerXpDeliveryRunKey].disabled =
          preference && !preference[runKey as BuyerXpDeliveryRunKey]?.checkedRun;
        if (timePreference === runKey && runs[runKey as BuyerXpDeliveryRunKey].disabled) {
          setTimePreference(undefined);
        }
      }
    },
    [buyerXp, timePreference]
  );

  const checkDisabledRuns = useCallback(
    (requestedMoment: moment.Moment, runKey: string, isLocal: boolean) => {
      if (buyerXp) {
        let preference = buyerXp.DeliveryDatesN[requestedMoment.utc().format("YYYY-MM-DD")];
        if (
          preference &&
          preference[runKey as BuyerXpDeliveryRunKey] &&
          preference[runKey as BuyerXpDeliveryRunKey].checkedRun
        ) {
          return false;
        }
        if (isLocal && requestedMoment.utc().day() === 0) {
          return true;
        }
        return false;
      } else return false;
    },
    [buyerXp]
  );

  useEffect(() => {
    const today = bachDateTime.Today();
    const tomorrow = bachDateTime.Tomorrow();
    let requestedMoment = moment(dateEditable);
    const productIDs = products?.map((p) => p?.ID);
    if (buyerXp && dateEditable) {
      const deliveryRuns = cloneDeep(
        buyerXp.DeliveryDatesN[requestedMoment.utc().format("YYYY-MM-DD")] || buyerXp.DeliveryRuns[0]
      );
      forIn(deliveryRuns, function (value, key) {
        if (!requestedMoment) {
          return disableRun(key, deliveryRuns);
        } else if (!value.checkedRun) {
          return disableRun(key, deliveryRuns);
        } else if (key === "AM") {
          //Disable AM on same day delivery BAC-1368
          if (bachDateTime.IsSameDay(today.toDate(), requestedMoment.toDate())) {
            return disableRun(key, deliveryRuns);
          }
          if (
            bachDateTime.IsSameDay(tomorrow.toDate(), requestedMoment.toDate()) &&
            bachDateTime.IsAfterNextDayAMCutoff(buyerXp)
          ) {
            //Disable AM on next day delivery if it's past same day cutoff BAC-1441
            return disableRun(key, deliveryRuns);
          }
        } else if (key === "PM") {
          //Disable AM on same day delivery BAC-1368
          if (bachDateTime.IsSameDay(today.toDate(), requestedMoment.toDate())) {
            return disableRun(key, deliveryRuns);
          }
        } else if (key !== "NO PREF") {
          if (shipment.Shipper === "USPS" && productIDs.includes("GIFTCARD")) {
            return disableRun(key, deliveryRuns);
          } else if (shipment.Shipper === "Wired") {
            return disableRun(key, deliveryRuns);
          }
        } else if (checkDisabledRuns(requestedMoment, key, shipment.Shipper === "LocalDelivery")) {
          return disableRunsForLocalDelivery(requestedMoment, key, deliveryRuns);
        }
        deliveryRuns[key as BuyerXpDeliveryRunKey].disabled = false;
      });
      if (componentMounted.current) {
        setAvailableRuns(deliveryRuns);
      }
    }
  }, [buyerXp, checkDisabledRuns, dateEditable, disableRun, disableRunsForLocalDelivery, products, shipment.Shipper]);

  const allRunsDisabled: boolean = useMemo(() => {
    if (!availableRuns) {
      return true;
    } else {
      return Boolean(availableRuns?.AM.disabled && availableRuns.PM.disabled && availableRuns["NO PREF"].disabled);
    }
  }, [availableRuns]);

  const handleClose = () => {
    setDateOption("FirstAvailable");
    onClose();
  };

  const handleSubmit = async () => {
    if (onSubmit && dateEditable) {
      setLoading(true);
      const partial: Partial<LineItem> = {
        DateNeeded: dateEditable.toDateString(),
        xp: {
          TimePreference: timePreference,
        },
      };
      if (availableRuns && timePreference) {
        partial.xp.TimeWindow = availableRuns[timePreference].TimeSlot;
      }
      await onSubmit(partial);
      setLoading(false);
      handleClose();
    }
  };

  const isDisabled: boolean = useMemo(() => {
    return (
      isUndefined(dateEditable) ||
      loading ||
      (shipment.xp?.DeliveryMethod === "LocalDelivery" && isUndefined(timePreference))
    );
  }, [dateEditable, loading, timePreference, shipment.xp?.DeliveryMethod]);

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      PaperProps={{ square: true }}
      fullScreen={isMobile}
      onClose={handleClose}
      maxWidth="xl"
    >
      <div className={classes.header}>
        <IconButton onClick={onClose} className={classes.buttonClose} disableRipple>
          <CloseIcon color="action" />
        </IconButton>
        <Box className={classes.headerConfirmation}>
          <Box className={classes.headerTextContainer} alignItems={"flex-end"}>
            <Typography variant="h4" color="primary">
              Select a {deliveryService.IsPickUp(shipment.Shipper) ? "Pickup" : "Delivery"} Date
            </Typography>
          </Box>
          <Fragment>
            <Divider orientation="vertical" flexItem />
            {lineItem ? (
              <Box display="flex" className={classes.productInfo}>
                <img src={lineItem.xp?.ShownImage} alt={lineItem.Product?.Name}></img>
                <div>
                  <Typography variant="h4">{productService.GetLineItemName(lineItem)}</Typography>
                  {address && (
                    <div className={classes.addressBox}>
                      <Address1Line address={address as Address} />
                    </div>
                  )}
                </div>
              </Box>
            ) : (
              <Box>
                <Typography variant="h4">
                  {`${sumBy(shipment.LineItems, function (l) {
                    return l.Quantity;
                  })} Item(s)`}
                </Typography>
                {address && (
                  <div className={classes.addressBox}>
                    <Address1Line address={address as Address} />
                  </div>
                )}
              </Box>
            )}
          </Fragment>
        </Box>
      </div>
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <Box style={{ gap: 24 }} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
            <Typography display="inline" variant="caption">
              Saving Delivery Date...
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            <Grid item sm={5} xs={12}>
              {bachmansDateOptions && (
                <Box height="100%">
                  <FormControl component="fieldset" style={{ width: "100%" }}>
                    <RadioGroup value={dateOption} onChange={(e) => setDateOption(e.target.value as DateOptions)}>
                      <FormControlLabel
                        className={`${classes.formLabel} ${
                          dateOption === "FirstAvailable" && classes.formLabelChecked
                        }`}
                        value="FirstAvailable"
                        label={
                          <Fragment>
                            <Typography variant="body1">
                              {moment(bachmansDateOptions.minDate) <= bachDateTime.Today()
                                ? "Same Day"
                                : "First Available"}
                            </Typography>
                            <Typography
                              variant="body1"
                              className={dateOption === "FirstAvailable" ? classes.boldText : ""}
                            >
                              {moment(bachmansDateOptions.minDate).format("MMMM D, YYYY")}
                            </Typography>
                          </Fragment>
                        }
                        labelPlacement="end"
                        control={
                          <Radio
                            size="small"
                            color="primary"
                            onClick={() =>
                              setDateEditable(new Date(moment(bachmansDateOptions.minDate).format("MMMM D, YYYY")))
                            }
                          />
                        }
                      />
                      {/* CUSTOM DATE RADIO */}
                      <FormControlLabel
                        className={`${classes.formLabel} ${dateOption === "Select" && classes.formLabelChecked}`}
                        value="Select"
                        label={
                          <div>
                            <Typography variant="body1">Select A Date</Typography>
                            {dateOption === "Select" ? (
                              <Typography variant="body1" className={classes.boldText}>
                                {moment(dateEditable).format("MMMM D, YYYY")}
                              </Typography>
                            ) : (
                              <Typography aria-hidden="true" variant="body2" className={classes.placeholderText}>
                                Choose Your Preferred Delivery Date
                              </Typography>
                            )}
                          </div>
                        }
                        labelPlacement="end"
                        control={<Radio size="small" color="primary" />}
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* DELIVERY RUN SELECT */}
                  {shipment.Shipper === "LocalDelivery" && (
                    <Box padding={2} border={1} borderColor="grey.300" mt={1}>
                      {allRunsDisabled ? (
                        <Typography variant="body1">
                          There aren't any available delivery timeslots for this day. Please select a different delivery
                          date.
                        </Typography>
                      ) : (
                        <FormControl component="fieldset" className={classes.formRadio}>
                          <FormLabel component="legend">Delivery Time Preference (required)</FormLabel>
                          <RadioGroup
                            aria-label="Delivery Time Preference (required)"
                            value={timePreference || ""}
                            onChange={(e: any) => {
                              setTimePreference(e.target.value);
                            }}
                          >
                            {availableRuns &&
                              Object.keys(availableRuns).map((run: string, index: number) => (
                                <FormControlLabel
                                  key={index}
                                  value={run}
                                  control={<Radio size="small" />}
                                  label={
                                    <Fragment>
                                      <Typography variant="body2">
                                        {availableRuns[run as BuyerXpDeliveryRunKey].Name}
                                      </Typography>
                                      <Typography color="textSecondary" variant="body2">
                                        {availableRuns[run as BuyerXpDeliveryRunKey].TimeSlot}{" "}
                                        {availableRuns[run as BuyerXpDeliveryRunKey].Surcharge &&
                                          availableRuns[run as BuyerXpDeliveryRunKey].Surcharge != "" && (
                                            <span
                                              className={
                                                !availableRuns[run as BuyerXpDeliveryRunKey].disabled
                                                  ? classes.surchargeLable
                                                  : ""
                                              }
                                            >
                                              {availableRuns[run as BuyerXpDeliveryRunKey].Surcharge === "$0.00"
                                                ? availableRuns[run as BuyerXpDeliveryRunKey].Message
                                                : availableRuns[run as BuyerXpDeliveryRunKey].Message}
                                            </span>
                                          )}
                                      </Typography>
                                    </Fragment>
                                  }
                                  disabled={availableRuns[run as BuyerXpDeliveryRunKey].disabled}
                                ></FormControlLabel>
                              ))}
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item sm xs={12} className={classes.gridCalendar}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  minDate={bachmansDateOptions && new Date(bachmansDateOptions?.minDate)}
                  variant="static"
                  openTo="date"
                  value={dateEditable}
                  onChange={handleDateChange}
                  shouldDisableDate={(day: MaterialUiPickersDate) => dateDisabled(day)}
                  disableToolbar={true}
                  animateYearScrolling={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions disableSpacing>
        <Button variant="text" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <DoubleOutlinedBtn
          buttonText="Save"
          buttonProps={{ color: "primary", disabled: isDisabled, onClick: handleSubmit }}
          styleProps={{ paddingLeft: 40, paddingRight: 40 }}
        />
      </DialogActions>
    </Dialog>
  );
};

interface Address1LineProps {
  address: Address;
}
const Address1Line: React.FunctionComponent<Address1LineProps> = (props) => {
  const { address } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.addressName} variant="body1">
        {deliveryService.GetAddressName(address)}
      </Typography>
      <Typography className={classes.addressBody} variant="body2">
        {`${address.Street1}, ${address.City}, ${address.State}, ${address.Zip}`}
      </Typography>
    </Fragment>
  );
};

export default DeliveryDateModal;
