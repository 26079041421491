import React, { FunctionComponent } from "react";
import { createStyles, List, ListItem, ListItemText, makeStyles, Theme } from "@material-ui/core";
import MobileDrawer from "../../Shared/MobileDrawer";

interface MobileContactUsProps {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const MobileContactUs: FunctionComponent<MobileContactUsProps> = (props) => {
  const classes = useStyle();
  const { menuOpen, toggleMenu } = props;
  // TODO: figure out if this information is coming from static Pages-
  return (
    <div className={classes.root}>
      <MobileDrawer menuOpen={menuOpen} toggleMenu={toggleMenu} menuTitle={"Contact Us"}>
        <List>
          <ListItem>
            <ListItemText primaryTypographyProps={{ variant: "body1" }}>Frequently Asked Questions</ListItemText>
          </ListItem>
        </List>
      </MobileDrawer>
    </div>
  );
};

export default MobileContactUs;
