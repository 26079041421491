import { createStyles, makeStyles, useTheme, Typography, Theme } from "@material-ui/core";
import React, { Fragment, FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { SimpleContentModel } from "../../../models/contentful/SimpleContent";
import { neutral } from "../../../themes/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      outline: `1px solid ${neutral.disabled}`,
      padding: theme.spacing(1.5),
      cursor: "pointer",
      margin: theme.spacing(2, 0, 0, 0),
      "&:first-child": {
        marginTop: 0,
      },
    },
    whiteText: {
      color: "white",
    },
  })
);

interface StackedContentProps {
  content?: SimpleContentModel[];
}

const StackedContentBlock: FunctionComponent<StackedContentProps> = (props) => {
  const { content } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const getThemeColor = (color: string) => {
    if (color === "Default") {
      return neutral.text_white_bg;
    } else if (color === "White") {
      return "white";
    } else {
      return color === "Purple" ? theme.palette.primary.main : theme.palette.secondary.main;
    }
  };

  const buildPromoStyle = (item: SimpleContentModel) => {
    const style: any = {
      padding: theme.spacing(3, 2),
      outlineColor: getThemeColor(item.borderColor),
      outlineStyle: "solid",
      outlineWidth: "3px",
    };
    if (item.backgroundColor && item.backgroundColor !== "Transparent") {
      style.backgroundColor = getThemeColor(item.backgroundColor);
    }
    return style;
  };

  return (
    <Fragment>
      {content?.map((c, i) => (
        <div className={classes.root} key={i} onClick={() => history.push(c.linkUrl)}>
          <div style={buildPromoStyle(c)}>
            <Typography variant={"h4"} style={{ color: getThemeColor(c.line1Color) }}>
              {c.line1Text}
            </Typography>
            <Typography variant={"body2"} style={{ color: getThemeColor(c.line2Color) }}>
              {c.line2Text}
            </Typography>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default StackedContentBlock;
