import { Box, createStyles, Grid, makeStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { WorkWithBachmansContext } from "../../providers/contentful";
import { HomeContentContext } from "../../providers/contentful";
import gfm from "remark-gfm";
import { DoubleOutlinedBtn } from "./DoubleOutlinedBtn";
import { SessionContext } from "../../providers/session";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { BachmansMeUser } from "../../models/user";
import { Me } from "ordercloud-javascript-sdk";
import { clone } from "lodash";
import jitterbitService from "../../services/jitterbit.service";
import BachmansLoading from "./BachmansLoading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      textTransform: "capitalize",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        // width: "88%",
        width: "100%",
        textAlign: "left",
        marginBottom: theme.spacing(4),
      },
    },
    gridContainerWorkWithBachmans: {
      justifyContent: "center",
    },
    gridItemWorkWithBachmans: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3),
      flexWrap: "wrap",
    },
    imgWorkWithBachmans: {
      display: "block",
      width: "clamp(100px, 20vw, 200px) !important",
      height: "clamp(100px, 20vw, 110px) !important",
      [theme.breakpoints.up("md")]: {
        height: "clamp(100px, 20vw, 140px) !important",
      },
    },
    imgWorkWithBachmansAuth: {
      display: "block",
      marginRight: theme.spacing(2),
    },
    titleWorkWithBachmans: {
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "19ch",
      },
    },
    listWorkWithBachmans: {
      padding: theme.spacing(1, 2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1, 5),
      },
      "& > ul": {
        marginTop: "0",
        marginBottom: "0",
      },
      "& * li": {
        listStyleType: "disc",
        display: "list-item",
        padding: theme.spacing(1, 0),
        "&::marker": {
          color: theme.palette.primary.main,
        },
      },
    },
    listWorkWithBachmansAuth: {
      "& > ul": {
        marginTop: "0",
        marginBottom: "0",
      },
      "& * li": {
        listStyleType: "disc",
        display: "list-item",
        padding: theme.spacing(1, 0),
        "&::marker": {
          color: theme.palette.primary.main,
        },
      },
    },
    fullWidth: {
      width: "100%",
    },
  })
);

interface WorkWithBachmansProps {
  isAuthenticating?: boolean; //in authdialog -
  displayTitle?: boolean;
}

const WorkWithBachmans: FunctionComponent<WorkWithBachmansProps> = (propsArgs: WorkWithBachmansProps) => {
  const classes = useStyles();
  const args = { ...{ displayTitle: true, isAuthenticating: false, ...propsArgs } }; /// setting default value to optional props.
  const { displayTitle, isAuthenticating } = args; // pulling out args to make a bit cleaner
  const workWithBachmans = useContext(WorkWithBachmansContext);
  const [preBoldText, setPreBoldText] = useState<string>();
  const [postBoldText, setPostBoldText] = useState<string>();
  const [boldText, setBoldText] = useState<string>();
  const { anonymous, user } = useContext(SessionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const history = useHistory();
  // const nav = useNavigate();

  const theme = useTheme();

  useEffect(() => {
    if (workWithBachmans?.data?.fields?.title) {
      const title = workWithBachmans?.data?.fields?.title;
      setPreBoldText(title.substring(0, title.indexOf("|")));
      setBoldText(title.substring(title.indexOf("|") + 1, title.lastIndexOf("|")));
      setPostBoldText(title.substring(title.lastIndexOf("|") + 1, title.length));
    }
  }, [workWithBachmans, displayTitle]);

  const handleRegisterClick = async (redirectLink: string) => {
    // history.push("/corporate/employment");
    window.location.replace(redirectLink);

    // if (anonymous) {
    //   dispatch(setBreadcrumbs({ authDialogOpen: true, authDialogState: "register" }));
    // } else if (user?.xp?.LoyaltyID) {
    //   history.push("/corporate/employment");
    // } else {
    //   //user is authenticated but has not signed up for purple perks
    //   await registerForWorkWithBachmans();
    // }
  };

  const getLoyaltyID = (user?: BachmansMeUser) => {
    if (user?.Phone) {
      return clone(user.Phone)
        .replace(/[^\w\s]/gi, "")
        .replace(" ", "");
    }
  };

  //   const   = async () => {
  //     try {
  //       setLoading(true);
  //       let updatedUser = await Me.Patch({ xp: { LoyaltyID: getLoyaltyID(user) } });
  //       await jitterbitService.RegisterWorkWithBachmans(updatedUser, "registerpp");
  //     } finally {
  //       setLoading(false);
  //       history.push("/account/purple-perks");
  //     }
  //   };

  return !isAuthenticating ? ( //isAuth = false
    <Fragment>
      {displayTitle && (
        <Typography variant="h3" className={classes.title}>
          Work With Bachman's
        </Typography>
      )}
      <Paper square className={classes.fullWidth}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} className={classes.gridItemWorkWithBachmans}>
            <img
              className={classes.imgWorkWithBachmans}
              src={workWithBachmans.data?.fields.image?.fields.file.url}
              alt="Purple Perks Badge"
            />
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="h4" className={classes.titleWorkWithBachmans}>
                {preBoldText}
                <strong>{boldText}</strong>
                {postBoldText}
              </Typography>
              <DoubleOutlinedBtn
                buttonText={
                  !anonymous && user?.xp?.LoyaltyID
                    ? "Apply Now"
                    : workWithBachmans.data?.fields.linkText || "Learn More"
                }
                buttonProps={{
                  variant: "outlined",
                  onClick: () => handleRegisterClick(workWithBachmans.data?.fields.linkUrl as string),
                }}
                styleProps={{
                  padding: theme.spacing(0.5, 5),
                  margin: theme.spacing(2, 0),
                  whiteSpace: "nowrap",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  ) : (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flex-wrap="wrap" align-items="center" alignItems="center" justifyContent="center">
        <img
          className={classes.imgWorkWithBachmansAuth}
          width="100"
          src={workWithBachmans.data?.fields.image?.fields.file.url}
          alt="Purple Perks Badge"
        />
        <Typography variant="h4" className={classes.titleWorkWithBachmans}>
          {preBoldText}
          <strong>{boldText}</strong>
          {postBoldText}
        </Typography>
      </Box>
      {workWithBachmans.data?.fields?.info && (
        <ReactMarkdown
          className={classes.listWorkWithBachmansAuth}
          plugins={[gfm]}
          children={workWithBachmans.data?.fields?.info}
        />
      )}
    </Box>
  );
};

export default WorkWithBachmans;
