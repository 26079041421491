import { BuyerProduct, Categories, LineItem, ListPageWithFacets, ShipmentItem } from "ordercloud-javascript-sdk";
import { BachmansProduct, ContentfulImage } from "../models/product";
import algolia from "../services/algolia.service";
import bachDateTime from "../services/bachDateTime.service";
import { map, flatten, compact, sortBy, filter, reduce, assign, pick, cloneDeep, uniq } from "lodash";
import { Category, Me, RequiredDeep } from "ordercloud-javascript-sdk";
import bachmansIntegrationsService from "./bachmansIntegrations.service";
import { OrderState } from "../redux/slices/order";
import { listAll } from "../helpers/listAll";
import productFilterService from "../services/productFilter.service";
import categoryService from "./category.service";
import AlgoliaAnalytics from "search-insights";

var colorMapCache: any;

export type FeaturedType = "products" | "events";
let userTokenn: any;
AlgoliaAnalytics("init", {
  appId: process.env.REACT_APP_ALGOLIA_APP as string,
  apiKey: process.env.REACT_APP_ALGOLIA_KEY as string,
  useCookie: true,
});
AlgoliaAnalytics("getUserToken", null, (err, newUserToken) => {
  if (err) {
    console.error(err);
    return;
  }
  userTokenn = newUserToken;
});
// Always use this function to list products from OC
const listOcProducts = async (listOptions?: any): Promise<RequiredDeep<ListPageWithFacets<BachmansProduct, any>>> => {
  //1. List Products
  if (!listOptions) listOptions = {};
  listOptions.filters = { ...listOptions?.filters, "xp.Visible": true };

  const productsObject = await Me.ListProducts(listOptions);
  //console.log("Listoptions--->", listOptions);
  //2. Filter Products that should be visible
  const inDateProducts = listOptions.pastEvents
    ? productsObject.Items
    : productFilterService.ProductInventoryDateFilter(productsObject.Items);
  const inInventoryProducts = productFilterService.InventoryCodeB3(inDateProducts);
  const result: RequiredDeep<ListPageWithFacets<BachmansProduct>> = {
    ...productsObject,
    Items: inInventoryProducts,
  };
  // console.log("Result of original search--->", result);
  return result;
};

function getDefaultProduct(productGroup?: BachmansProduct[]) {
  if (!productGroup) {
    return;
  }
  if (productGroup.length > 1) {
    var result = productGroup.find((p) => p.xp.IsDefaultProduct);
    return result || productGroup[0];
  } else {
    return productGroup[0];
  }
}

function getProductName(product: any) {
  if (!(product && product.Name)) return;
  //if (product.xp && product.xp.WebFacingProductTitle) return product.xp.WebFacingProductTitle;
  if (
    window.location.search.includes("dotcom_products_asc") == true ||
    window.location.search.includes("dotcom_products_desc") == true
  ) {
    if (product && product.Name)
      return product.Name.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  } else {
    if (product.xp && product.xp.WebFacingProductTitle) return product.xp.WebFacingProductTitle;
  }
  return product.Name.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  //return product.Name;
}

async function listProductsByCodes(
  productCodes: string[],
  onlyDefault?: boolean,
  all?: boolean
): Promise<BuyerProduct[]> {
  var options: any = {
    catalogID: "Bachmans",
    filters: {
      "xp.ProductCode": productCodes?.join("|"),
    },
  };
  if (onlyDefault) {
    options.filters["xp.IsDefaultProduct"] = "true";
  }
  if (all) {
    return listAll(listOcProducts, options);
  } else {
    options.page = 1;
    options.pageSize = 100;
    const events = await listOcProducts(options);
    return events.Items;
  }
}
export interface ContentfulOptions {
  query: string;
  searchOptions: any;
}
export interface AlgoliaListOptions {
  query: string;
  searchOptions: any;
  // userToken: any;
}

// Function to create a payload to fetch contentful data from algolia

function buildContentfulOptions(filters?: any): ContentfulOptions {
  let query: string = ``;

  if (filters) {
    if (filters.query) query = filters.query;
  }
  let searchOptions: any = {
    contenttype: "article",
  };
  return {
    query: query,
    searchOptions: searchOptions,
  };
}

function buildListOptions(
  filters?: any,
  l2categoryChildren?: Category[],
  categoryFilters?: string[]
): AlgoliaListOptions {
  let timestamp = bachDateTime.GetLocalTimeStamp();
  let dateFilter = "StartDate <=" + timestamp + " AND EndDate >=" + timestamp;
  //let userTokenn:any;
  // Algolia Init method for sending Events

  let searchOptions: any = {
    filters: ["Visible = 1", "(InfiniteInventory = 1 OR Inventory > 0)", "Active = 1", dateFilter],
    facetFilters: ["IsWorkShopEvent:false"],
    facets: algolia.facetToCollect,
    distinct: true,
    hitsPerPage: 54,
    // sortFacetValuesBy: "alpha",
    userToken: userTokenn,
  };

  searchOptions.clickAnalytics = true;
  searchOptions.userToken = userTokenn;
  searchOptions.filters = searchOptions.filters.join(" AND ");
  let query: string = ``;
  if (filters) {
    if (filters.query) query = filters.query;
    if (filters.pageSize) searchOptions.hitsPerPage = filters.pageSize;
    if (filters.page) searchOptions.page = filters.page - 1;
    if (filters.facets) {
      const keys = uniq(filters?.facets?.map((f: string) => f?.split(":")[0]));

      // group facets in a nested array to create correct AND/ OR logic
      //https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filters-and-facetfilters/#multiple-filters
      let facetsLogic: string[][] = [];
      keys.forEach((key) => {
        const facetsInKey = filters.facets.filter((facet: string) => facet.split(":")[0] === key);
        facetsLogic.push(facetsInKey);
      });

      searchOptions.facetFilters = [...searchOptions.facetFilters, ...facetsLogic];
    }
    if (filters.categoryid) {
      let categorylist: any = []; // set in sub array so it triggers OR logic for algolia
      if (!categoryFilters || categoryFilters.length === 0) {
        if (l2categoryChildren && l2categoryChildren.length) {
          //show all children
          l2categoryChildren.forEach((category) => {
            categorylist.push("CategoryId:" + category.ID);
          });
        }
        categorylist.push("CategoryId:" + filters.categoryid);
      } else {
        categoryFilters.forEach((catID) => {
          categorylist.push("CategoryId:" + catID);
        });
      }
      searchOptions.facetFilters.push(categorylist);
    }
    if (filters.onSale) {
      searchOptions.filters = filters.onSale
        ? searchOptions.filters.concat(" AND OnSale = 1")
        : searchOptions.filters.concat(" AND OnSale = 0");
    }
  }
  // Get the userToken and send it in payload for dynamic synonms

  return {
    query: query,
    searchOptions: searchOptions,
  };
}

async function listProducts(
  filters?: any,
  l2categoryChildren?: Category[],
  categoryFilters?: string[],
  quickSearchFlag?: any
) {
  let sortIndex: any;
  if (
    (window.location.href.includes("?query") == true && window.location.href.includes("&category") !== true) ||
    quickSearchFlag == true
  ) {
    sortIndex = algolia.SearchResultSortIndex(filters);
  } else {
    sortIndex = algolia.getSortIndex(filters);
  }

  let index = algolia.getProductIndex(sortIndex);
  const noFacetFilters = cloneDeep(filters);
  noFacetFilters.facets = [];
  const options = buildListOptions(filters, l2categoryChildren, categoryFilters);
  const noFacetOptions = buildListOptions(noFacetFilters, l2categoryChildren, categoryFilters);
  const [res, noFacetRes] = await Promise.all([
    index.search(options.query, options.searchOptions),
    index.search(noFacetOptions.query, noFacetOptions.searchOptions),
  ]);
  (res as any).emptyFacets = noFacetRes.facets;
  return await decorateAlgoliaResults(res);
  //return index.search(options.query, options.searchOptions).then(decorateAlgoliaResults);
}

// For BI-479: Algolia Multiple Indices Search
async function buildNoFacetOptions(filters?: any, l2categoryChildren?: Category[], categoryFilters?: string[]) {
  const noFacetFilters = cloneDeep(filters);
  noFacetFilters.facets = [];
  const noFacetOptions = await buildListOptions(noFacetFilters, l2categoryChildren, categoryFilters);
  return noFacetOptions;
}

// Passes Algolia results for OC reponse integration
async function integrateOCAlgolia(res: any) {
  return await decorateAlgoliaResults(res);
}

// Product suggestions

async function listProductsSuggestions(filters?: any) {
  let searchOptions: any = {
    clickAnalytics: true,
    userToken: userTokenn,
  };
  const sortIndex = algolia.getQuerySuggestionIndex(filters);
  let index = algolia.getQueryIndex(sortIndex);
  const options = buildListOptions(filters);
  const [res] = await Promise.all([index.search(options.query, searchOptions)]);
  console.log("Product suggestion data-->", res);
  return await res;
}

// Call to fetch contentfull date from algolia

async function listContentFulData(filters?: any) {
  let searchOptions: any = {
    clickAnalytics: true,
    userToken: userTokenn,
    //filters: "contenttype:article",
  };
  const sortIndex = algolia.getAlgoliaContentIndex(filters);
  let index = algolia.getProductIndex(sortIndex);
  const options = buildContentfulOptions(filters);
  const [res] = await Promise.all([index.search(options.query, searchOptions)]);
  return await res;
}

async function decorateAlgoliaResults(response: any) {
  var algoliaResponse = cloneDeep(response);
  algoliaResponse.page++;
  if (algoliaResponse && algoliaResponse.hits && algoliaResponse.hits.length) {
    let productCodeFilter = map(algoliaResponse.hits, "ProductCode").join("|");
    const ocProducts = await listAll(listOcProducts, { filters: { "xp.ProductCode": productCodeFilter } });
    let productsToList = compact(
      map(algoliaResponse.hits, function (hit, index) {
        let matchingProducts = sortBy(
          map(
            filter(ocProducts, function (p) {
              return p.xp.ProductCode === hit.ProductCode;
            }),
            function (p) {
              p.BestAlgoliaMatch = (p.ID === hit.ID)!;
              return p;
            }
          ),
          function (p) {
            return p.PriceSchedule.PriceBreaks[0].Price;
          },
          function (p) {
            return (p.Position = index);
          }
        );
        //console.log('Product-->',hit.Name + ' '+ "Index-->",index);
        return matchingProducts.length ? matchingProducts : null;
      })
    );
    algoliaResponse.hits = productsToList;
    algoliaResponse.queryID = response.queryID;
    return algoliaResponse;
  } else {
    return algoliaResponse;
  }
}

interface facetValues {
  [key: string]: number;
}
interface facetList {
  [key: string]: facetValues;
  //AlgoliaAttribute : { facet: number}
}

//goal is to update all facet values that are apart of the new Facet Results, and set the remaining facet values that werent returned from new facet results to 0;
function updateFacet(facetValues: facetValues, FacetNameKey: string, index: number, facetResults: facetList) {
  let updatedFacetValues = Object.fromEntries(Object.entries(facetValues).map(([key, fctVal]) => [key, 0])); // default facet values to zero,
  return assign(updatedFacetValues, pick(facetResults[FacetNameKey], Object.keys(facetValues))); // then updating facets that are apart of new results.
}
function mapFacetResultsToInitFacetList(facetList: facetList, facetResults: facetList) {
  let origFacetList = cloneDeep(facetList);
  let newFacetResults = cloneDeep(facetResults);
  return Object.fromEntries(
    Object.entries(origFacetList)?.map(([key, value], i) => [key, updateFacet(value, key, i, newFacetResults)])
  );
}

function getProductGroup(productCode: string) {
  return listOcProducts({
    pageSize: 100,
    filters: { "xp.ProductCode": productCode },
  }).then((productListData) => {
    if (productListData.Items.length) {
      return sortBy(productListData.Items, function (item) {
        return item.PriceSchedule.PriceBreaks[0].Price;
      });
    } else {
      //history.push('/404')
      Promise.reject("Product group not found");
    }
  });
}

function getColorMap(): Promise<any> {
  if (colorMapCache) {
    return Promise.resolve(colorMapCache);
  } else {
    return bachmansIntegrationsService.getColorMap().then(function (colorMap) {
      colorMapCache = colorMap;
      return colorMapCache;
    });
  }
}

function determineAddedQuantity(item: any, currentOrder: OrderState) {
  var productID = item.Product ? item.Product.ID : item.ID;
  var otherLines = filter(flatten(map(currentOrder.shipments, "LineItems")), function (line: ShipmentItem) {
    if (item.Product) return line.LineItemID !== item.ID && productID === line.Product?.ID;
    return productID === line.Product?.ID;
  });
  return otherLines.length > 0
    ? reduce(
        map(otherLines, "Quantity"),
        function (qty, n) {
          return qty + n;
        },
        0
      )
    : 0;
}

function finishMaxQuantity(item: any, max: number, currentOrder: OrderState) {
  var maxQuantity = max - determineAddedQuantity(item, currentOrder);
  return maxQuantity;
}

function determineMaxLineItemQuantity(lineItem: any, currentOrder: OrderState) {
  const product = lineItem.Product;
  if (Number(product?.xp?.SafetyStock) > 0) {
    return finishMaxQuantity(
      lineItem,
      product?.Inventory?.QuantityAvailable - Number(product.xp.SafetyStock),
      currentOrder
    );
  }
  if (product.Inventory.QuantityAvailable)
    return finishMaxQuantity(lineItem, product.Inventory.QuantityAvailable, currentOrder);
  return finishMaxQuantity(lineItem, product.PriceSchedule.MaxQuantity || 10000000, currentOrder);
}

function determineMaxQuantity(item: any, currentOrder: OrderState) {
  var product = item.Product ? item.Product : item;
  if (!product?.Inventory?.Enabled || product.xp.CodeB3 === "9") return 10000;
  if (item.Product) {
    return determineMaxLineItemQuantity(item, currentOrder);
  } else {
    if (Number(product.xp.SafetyStock) > 0) {
      return finishMaxQuantity(
        item,
        product.Inventory.QuantityAvailable - Number(product.xp.SafetyStock),
        currentOrder
      );
    }
    if (product.Inventory.QuantityAvailable)
      return finishMaxQuantity(item, product.Inventory.QuantityAvailable, currentOrder);
    return finishMaxQuantity(item, product.PriceSchedule.MaxQuantity || 10000000, currentOrder);
  }
}

function determineMinQuantity(item: any) {
  var product = item.Product ? item.Product : item;
  if (product && product.PriceSchedule && product.PriceSchedule.MinQuantity) return product.PriceSchedule.MinQuantity;
  return 1;
}

function productNameFilter(product: BuyerProduct) {
  if (!(product && product.Name)) return;
  if (product.xp && product.xp.WebFacingProductTitle) return product.xp.WebFacingProductTitle;
  return product.Name.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function getDefaultImage(product: BuyerProduct) {
  return product.xp &&
    product.xp.Contentful &&
    product.xp.Contentful &&
    product.xp.Contentful.Images &&
    product.xp.Contentful.Images.length
    ? product.xp.Contentful.Images[0].url
    : undefined;
}
export interface frequentCategoriesInfoResponse {
  categoryL2: Category | null;
  categoryL1: Category | null;
}

function getMostFrequentCategory(
  categoryIdFacets: { [index: string]: number },
  allCategories: RequiredDeep<Category>[]
): Category {
  if (!categoryIdFacets) categoryIdFacets = {};
  const topResults = Object.keys(categoryIdFacets).sort((a, b) => categoryIdFacets[b] - categoryIdFacets[a]);
  const topL2s = topResults.filter((id) => id.split("_").length === 2);
  const mostFrequentCatID = topL2s.find((id) => allCategories.map((c) => c.ID).includes(id));
  const result = allCategories.find((cat) => cat.ID === mostFrequentCatID);
  return result as Category;
}

const getProductCategory = async (productID: string, categories: Category[]) => {
  const activeCatIDs = categories.map((c) => c.ID);
  const assignments = await Categories.ListProductAssignments("Bachmans", { pageSize: 100, productID: productID });
  const validAssignments = assignments.Items.filter((assignment) => activeCatIDs.includes(assignment.CategoryID));
  var bestMatch = "";
  validAssignments.forEach((assignment) => {
    if (assignment.CategoryID.split("_").length >= bestMatch.split("_").length) {
      bestMatch = assignment.CategoryID;
    }
  });
  return categories.find((c) => c.ID === bestMatch) || categories[0];
};

const getBreadCrumbsFromProduct = async (productID: string, categories: Category[]): Promise<any[]> => {
  const cat = await getProductCategory(productID, categories);
  return await categoryService.BuildBreadCrumb(cat.ID!, productID, categories);
};

const getProductImage = (product: BuyerProduct): ContentfulImage => {
  if (product.ID === "GFITCARD") {
    var image = product.xp?.Contentful?.Images?.find((i: ContentfulImage) => i.title === "GiftCard");
  } else {
    image = product.xp?.Contentful?.Images?.find((i: ContentfulImage) => i.title === product.ID);
  }
  return image || product.xp?.Contentful?.Images[0];
};

const isNationWideAvailable = (product?: BuyerProduct): boolean => {
  return product?.xp?.CodeB2 === "Y";
};

const productOutOfStock = (product: BuyerProduct): boolean => {
  return !!(
    product?.xp?.CodeB3 !== "9" &&
    product?.Inventory &&
    (product?.Inventory?.QuantityAvailable || 0) - Number(product?.xp?.SafetyStock) <= 0
  );
};

const getLineItemColorType = (lineItem: LineItem) => {
  return lineItem?.Product?.xp?.SpecsOptions?.ColorVarient
    ? ` Color: ${lineItem?.Product?.xp?.SpecsOptions?.ColorVarient}`
    : "";
};

const getLineItemSizeType = (lineItem: LineItem) => {
  return lineItem?.Product?.xp?.SpecsOptions?.Size ? ` Type: ${lineItem?.Product?.xp?.SpecsOptions?.Size}` : "";
};

const getLineItemName = (lineItem?: LineItem) => {
  const titleOrName = lineItem?.Product?.xp?.WebFacingProductTitle || lineItem?.Product?.Name;
  if (lineItem?.Product?.xp?.IsWorkShopEvent) {
    return lineItem?.xp?.EventName || titleOrName;
  } else return titleOrName;
};

const service = {
  GetDefaultProduct: getDefaultProduct,
  GetProductName: getProductName,
  GetProductGroup: getProductGroup,
  GetColorMap: getColorMap,
  GetMostFrequentCategory: getMostFrequentCategory,
  ListProductsByCodes: listProductsByCodes,
  ListProducts: listProducts,
  ListContentFulData: listContentFulData,
  ListProductsSuggestions: listProductsSuggestions,
  ListOcProducts: listOcProducts,
  DetermineMaxQuantity: determineMaxQuantity,
  ProductNameFilter: productNameFilter,
  GetDefaultImage: getDefaultImage,
  DetermineMinQuantity: determineMinQuantity,
  MapFacetResultsToInitFacetList: mapFacetResultsToInitFacetList,
  GetProductCategory: getProductCategory,
  GetBreadCrumbsFromProduct: getBreadCrumbsFromProduct,
  IsNationWideAvailable: isNationWideAvailable,
  GetProductImage: getProductImage,
  ProductOutOfStock: productOutOfStock,
  GetLineItemColorType: getLineItemColorType,
  GetLineItemSizeType: getLineItemSizeType,
  GetLineItemName: getLineItemName,
  BuildNoFacetOptions: buildNoFacetOptions,
  IntegrateOCAlgolia: integrateOCAlgolia,
};

export default service;
