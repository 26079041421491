import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlProps,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Box,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import AddressDialog from "../Account/AddressDialog";
import { Address, BuyerAddress, BuyerProduct, LineItem, LineItems, Me } from "ordercloud-javascript-sdk";
import { useAppSelector } from "../../redux/store-hook";
import { DeliveryTypes } from "../../models/buyerXp";
import { SessionContext } from "../../providers/session";
import deliveryService, { DeliveryDateOptions } from "../../services/delivery.service";
import { includes, isUndefined, map } from "lodash";
import { Add } from "@material-ui/icons";
import { CatalogContext } from "../../providers/catalog";
import { calculateOrder, SetLineItemAddressPayload, setLineItemShippingAddress } from "../../redux/slices/order";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../components/Shared/ConfirmDialog";
import { DeliveryMethods } from "../../models/delivery";
import deliveryResourceService from "../../services/delivery-resource.service";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { OrderAddress } from "../../models/address";

interface LocationDropDownProps {
  address?: Address;
  shipper?: DeliveryTypes;
  formHelperText?: string;
  products?: BuyerProduct[];
  lineItems?: LineItem[];
  formControlProps?: FormControlProps;
  onAddressSubmit?: (address: Address, routeCode?: string) => Promise<void>;
  addressesNotAvailable?: Address[];
  isInLocationEditMode?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
    },
    menu: {
      display: "flex",
      justifyContent: "flex-start",
      position: "relative",
      "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        "& .MuiTypography-root": {
          lineHeight: 1,
        },
      },
      [theme.breakpoints.up("md")]: {
        "&::before": {
          // adds a dissolve effect on the "Where's It Going" Address
          right: 0,
          content: "''",
          position: "absolute",
          height: "100%",
          width: "50%",
          background: "linear-gradient(-90deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0))",
          pointerEvents: "none",
        },
      },
    },
    Email: {
      display: "none",
    },
    menuItem: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      whiteSpace: "break-spaces",
      "& p:first-child": {
        lineHeight: 1.2,
      },
    },
    menuItemPreview: {
      flexDirection: "row",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    addressSubHeader: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
      color: `${theme.palette.text.primary} !important`,
    },
    link: {
      cursor: "pointer",
      float: "right",
      paddingTop: "0px",
      paddingBottom: "0px",
      top: "-3px",
    },
    lblSelectWhereIsThisGoing: {
      color: "#9262b8",
      "& > .MuiInputLabel-asterisk:not(.Mui-error)": {
        color: "#9262b8",
      },
    },
    ddlWhereIsThisGoing: {
      color: "#9262b8",
    },
    smallSelectWhereIsThisGoing: {
      color: "#9262b8",
    },
  })
);

const LocationDropDown: React.FunctionComponent<LocationDropDownProps> = (props) => {
  const classes = useStyles();
  const {
    address,
    onAddressSubmit,
    shipper,
    formHelperText,
    formControlProps,
    addressesNotAvailable,
    products,
    lineItems,
    isInLocationEditMode,
  } = props;

  const [selectedAddress, setSelectedAddress] = useState<string>();
  const { anonymous } = useContext(SessionContext);
  const currentOrder = useAppSelector((state) => state.order);
  const [addressType, setAddressType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [orderAddresses, setOrderAddresses] = useState<Address[]>();
  const [addressBook, setAddressBook] = useState<Address[]>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { DCFcategories } = useContext(CatalogContext);
  const dispatch = useDispatch<AppDispatch>();
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryDateOptions>();
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethods>();
  const [switchToPickUp, setSwitchToPickUp] = useState<boolean>(false);
  const componentMounted = useRef(true);
  const [selectedEditAddress, setSelectedEditAddress] = useState<BuyerAddress>();
  const history = useHistory();

  // some addresses have IDs and some don't. We need to get a value to use in the select drop down
  const getAddressValue = useCallback((address?: Address) => {
    if (address?.xp?.addressType !== undefined && address?.xp?.addressType === "Email") {
      return address?.FirstName + "-" + address?.LastName;
    } else {
      return address?.ID ? address?.ID : (address?.FirstName || "") + (address?.LastName || "") + address?.Street1;
    }
  }, []);

  const isAvailableAddress = useCallback(
    (address: Address) => {
      if (addressesNotAvailable?.length) {
        let match = includes(
          map(addressesNotAvailable, (address: Address) => address.Street1),
          address.Street1
        );
        if (match) {
          return false;
        }
      }
      return true;
    },
    [addressesNotAvailable]
  );

  useEffect(() => {
    return () => {
      componentMounted.current = false;
      //console.log(isInLocationEditMode);
    };
  }, []);

  useEffect(() => {
    if (address) {
      setSelectedAddress(getAddressValue(address));
    }
  }, [getAddressValue, address]);

  useEffect(() => {
    const shipments = currentOrder?.shipments?.filter((s) => s.ToAddress !== null);
    const addresses: Address[] = [];

    shipments?.forEach((shipment) => {
      if (shipment.ToAddress && !deliveryService.IsPickUp(shipment.Shipper) && isAvailableAddress(shipment.ToAddress)) {
        const orderAddress = setOrderAddressData(shipment.ToAddress);
        var line = shipment.LineItems[0];
        if (shipment.xp?.AddressType) {
          orderAddress.xp = {
            addressType: shipment.xp?.AddressType,
            Email: line?.xp?.Email,
          };
        }
        addresses.push(orderAddress as Address);
      }
    });

    setOrderAddresses(addresses);
    (async () => {
      if (!anonymous) {
        setLoading(true);
        let savedAddresses = await Me.ListAddresses({ filters: { Editable: true, IsShipping: true } });
        // make sure we only display addresses that have an address type
        savedAddresses.Items = savedAddresses.Items?.filter(
          (item) => !isUndefined(item.xp?.addressType) && item.xp?.addressType !== null
        );
        if (products?.every((p) => p.xp.CodeB2 === "Y")) {
          setAddressBook(savedAddresses?.Items);
        } else {
          setAddressBook(savedAddresses.Items.filter((a) => deliveryService.IsLocalDelivery(a.Zip, a.City)));
        }
        setLoading(false);
      }
    })();
  }, [anonymous, currentOrder, currentOrder?.shipments, history, isAvailableAddress, products]);

  useEffect(() => {
    if (DCFcategories) {
      (async () => {
        const methods = deliveryService.GetAvailableDeliveryMethods(products || [], DCFcategories);
        const options = await deliveryService.GetDateOptions({
          products: products || [],
          deliveryMethods: methods,
        });
        if (componentMounted.current) {
          setDeliveryOptions(options);
          setDeliveryMethods(methods);
        }
      })();
    }
  }, [DCFcategories, products]);

  const setOrderAddressData = (addressTo: Address): OrderAddress => {
    const orderAddress: OrderAddress = {
      ID: addressTo.ID,
      DateCreated: addressTo.DateCreated,
      CompanyName: addressTo.CompanyName,
      FirstName: addressTo.FirstName,
      LastName: addressTo.LastName,
      Street1: addressTo.Street1,
      Street2: addressTo.Street2,
      City: addressTo.City,
      State: addressTo.State,
      Zip: addressTo.Zip,
      Country: addressTo.Country,
      Phone: addressTo.Phone,
      AddressName: addressTo.AddressName,
    };
    return orderAddress;
  };

  const handleAddressSelect = async (e: any) => {
    setSelectedAddress(e.target.value);
    if (e.target.value !== "CreateNew" && onAddressSubmit) {
      const available = [...(orderAddresses || []), ...(addressBook || [])];
      const address = available?.find((a) => getAddressValue(a) === e.target.value);
      if (address) {
        const localCities = await deliveryResourceService.GetLocalCities();
        const localAddress = deliveryService.IsAddressLocal(address, localCities);
        setAddressType(undefined);
        await onAddressSubmit(address, localAddress.routeCode);
      }
    } else {
      setAddressType("");
    }
  };

  const handleAddressFormSubmit = async (newAddress: BuyerAddress, routeCode?: string) => {
    if (onAddressSubmit) {
      var updatedAddress = newAddress as Address;
      console.log("Updated address", updatedAddress);
      await onAddressSubmit(updatedAddress, routeCode);
    }
  };

  const getAddressLabel = (isForEdit: boolean, address?: Address): any => {
    if (address?.xp?.NickName && address?.xp?.DeliveryMethod !== "Email") {
      return (
        <Fragment>
          <Box>
            <Typography variant="body1" display="inline">
              {address?.xp?.NickName}{" "}
            </Typography>
            {(address?.xp?.addressType || "Residence") && (
              <Typography variant="body1" display="inline" color="textSecondary">
                ({address?.xp?.addressType || "Residence"})
              </Typography>
            )}
          </Box>
          <Typography id={address.ID} variant="body2" color="textSecondary">
            {address?.Street1}
          </Typography>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Typography variant="body1" display="inline">
            {address?.FirstName} {address?.LastName}{" "}
          </Typography>
          {address?.xp?.addressType && address?.xp?.addressType !== "Email" && (
            <Typography variant="body1" display="inline" color="textSecondary">
              ({address?.xp?.addressType})
            </Typography>
          )}

          <Typography variant="body2" color="textSecondary">
            {address?.xp?.addressType !== "Email" && <span id={address!.ID}>{address?.Street1}</span>}
            {address?.xp?.addressType === "Email" && <span id={address!.ID}>Email Delivery</span>}
            {isForEdit && !addressBook?.some((item) => item.ID === address!.ID!) && (
              <Fragment>
                <Box display="inline-block" px={1.5}>
                  |
                </Box>
                <Link className={classes.link} color="primary" onClick={handleOpenDialog(address)}>
                  Edit
                </Link>
              </Fragment>
            )}
          </Typography>
        </Fragment>
      );
    }
  };

  const handleOpenDialog = (address?: BuyerAddress) => (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    if (address) {
      setSelectedEditAddress(address);
    }
  };

  const handleOpenEditAddressBook = () => (event: React.MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    var addressBookAnchor = $("a[href^='/account/address-book']");
    if (addressBookAnchor.length > 0) {
      let href = $(addressBookAnchor).attr("href");
      let navBackTo = window.location.pathname;
      window.open(`${href}?navBackTo=${navBackTo}`, "_self");
    }
  };

  const handleDestinationClick = async (destinationType: string) => {
    if (deliveryService.IsPickUp(destinationType)) {
      setSwitchToPickUp(true);
    } else {
      setAddressType(destinationType);
      setSelectedAddress(destinationType);
      setSwitchToPickUp(false);
    }
  };

  const switchToPickup = async () => {
    if (lineItems?.length) {
      const ISPShipment = currentOrder.shipments?.find((s) => deliveryService.IsPickUp(s.Shipper));
      if (ISPShipment && ISPShipment.ToAddress && ISPShipment.ToAddress !== null) {
        const payload: SetLineItemAddressPayload[] = lineItems.map((li) => ({
          lineItemID: li.ID!,
          address: ISPShipment.ToAddress as Address,
          deliveryMethod: ISPShipment.Shipper as DeliveryTypes,
        }));
        await dispatch(setLineItemShippingAddress(payload));
      } else {
        const pickupMethod = currentOrder.shipments?.some((s) => s.Shipper === "CurbsidePickUp")
          ? "CurbsidePickUp"
          : "InStorePickUp";
        const partialUpdate: Partial<LineItem> = {
          DateNeeded: deliveryOptions?.InStorePickUp?.standardMinDate?.toDateString(),
          xp: {
            DeliveryMethod: pickupMethod,
            AddressType: pickupMethod,
          },
        };
        const reqs: Promise<any>[] = lineItems?.map((item: LineItem) =>
          LineItems.Patch("Outgoing", currentOrder.order?.ID!, item.ID!, partialUpdate)
        );
        await Promise.all(reqs);
        await dispatch(calculateOrder(currentOrder.order?.ID!));
      }
    }
  };

  const handleClose = () => {
    setSelectedAddress(getAddressValue(address));
    setSwitchToPickUp(false);
    setAddressType(undefined);
    setSelectedEditAddress(undefined);
  };

  const addressesToDisplay = () =>
    (orderAddresses && orderAddresses.length > 0) || (addressBook && addressBook.length > 0);
  const destinationOptions = useMemo(() => {
    // only allow ISP as an option if the products and line items are provided as props.
    if (
      products?.length &&
      lineItems?.length &&
      deliveryOptions?.InStorePickUp?.minDate &&
      !deliveryService.IsPickUp(shipper)
    ) {
      return deliveryService.GetDestinationTypes();
    } else return deliveryService.GetDestinationTypes("*");
  }, [deliveryOptions?.InStorePickUp?.minDate, lineItems?.length, products?.length, shipper]);

  return (
    <Fragment>
      <ConfirmDialog
        open={switchToPickUp}
        title={`Are you sure you want to switch the items in this shipment to in-store pickup?`}
        loadingText={"updating"}
        onClose={handleClose}
        onAccept={switchToPickup}
      />
      <FormControl {...formControlProps}>
        <InputLabel variant="outlined" id="shipmentWhereIsThisGoing" className={`${classes.lblSelectWhereIsThisGoing}`}>
          Where is this going?
        </InputLabel>
        <Select
          className={`${classes.menu} ${classes.ddlWhereIsThisGoing}`}
          labelId={formControlProps?.className}
          id="shipmentWhereIsThisGoingSelect"
          label="Where is this going?"
          value={selectedAddress || ""}
          onChange={(e: any) => handleAddressSelect(e)}
          autoFocus={true}
          //open={isInLocationEditMode}
          MenuProps={{
            PaperProps: {
              square: true,
              style: { maxWidth: !isMobile ? "275px" : "100%" },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {!loading &&
            !addressesToDisplay() &&
            destinationOptions?.map((destination, index) => (
              <MenuItem key={index} value={destination.type} onClick={() => handleDestinationClick(destination.type)}>
                {destination.label}{" "}
              </MenuItem>
            ))}

          {addressesToDisplay() && (
            <MenuItem value="CreateNew" className={classes.menu + " " + formControlProps?.className}>
              <Box display="flex" justifyContent="flex-start">
                <Add className={classes.icon} color="primary" />
                <Typography color="primary" variant="body1">
                  Create New Address
                </Typography>{" "}
              </Box>
            </MenuItem>
          )}
          {orderAddresses &&
            orderAddresses?.map((address, index) =>
              address?.xp?.addressType !== "Email" ? (
                <MenuItem className={classes.menuItem} key={index} value={getAddressValue(address)}>
                  {getAddressLabel(true, address)}
                </MenuItem>
              ) : (
                <MenuItem
                  className={classes.menuItem + " " + "email-delivery"}
                  key={index}
                  value={getAddressValue(address)}
                >
                  {getAddressLabel(true, address)}
                </MenuItem>
              )
            )}
          {addressBook && addressBook.length > 0 && (
            <MenuItem
              divider
              className={classes.addressSubHeader}
              id="nested-list-subheader"
              disabled
              onClick={handleOpenEditAddressBook()}
            >
              <span id="address-book-title">Address Book</span>
              {
                //Add this code block back if you wish to enable edit on the Saved Address Book addresses,
                //and remove the disable attribute on the MenuItem above, d
                //(i.e. will navigate to Address Book and back to cart after adding and/or editing addresses).
                /*                 
                <Fragment>
                  <Box display="inline-block" px={1.5}>
                    |
                  </Box>
                  <Link className={classes.link} color="primary" onClick={handleOpenEditAddressBook()}>
                    Edit Address Book
                  </Link>
                </Fragment> 
                */
              }
            </MenuItem>
          )}
          {addressBook &&
            addressBook?.map((address, index) => (
              <MenuItem className={classes.menuItem} key={index} value={getAddressValue(address)}>
                {getAddressLabel(false, address)}
              </MenuItem>
            ))}
        </Select>

        {formHelperText && (
          <FormHelperText
            margin="dense"
            component="small"
            style={{ paddingLeft: 2, fontSize: 10 }}
            className={`${classes.smallSelectWhereIsThisGoing}`}
          >
            {formHelperText}
          </FormHelperText>
        )}
        <AddressDialog
          variant="Shipping"
          open={!isUndefined(addressType) || !isUndefined(selectedEditAddress)}
          onClose={handleClose}
          onSubmit={handleAddressFormSubmit}
          address={!isUndefined(selectedEditAddress) ? selectedEditAddress : { xp: { addressType: addressType } }}
          products={products}
          deliveryMethods={deliveryMethods}
        />
      </FormControl>
    </Fragment>
  );
};

export default LocationDropDown;
