import React, { FunctionComponent } from "react";
import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";

import TopLevelCategories from "./MegaMenu/TopLevelCategories";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      "& .MuiLink-root": {
        "&:not(:last-child)": {
          marginRight: theme.spacing(3),
        },
      },
      alignItems: "stretch",
    },
    container: {
      display: "flex",
    },
  })
);

interface CategoryToolbarProps {}

const CategoryToolbar: FunctionComponent<CategoryToolbarProps> = (props) => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.root} variant="dense">
      <Container maxWidth="lg" className={classes.container}>
        <TopLevelCategories />
      </Container>
    </Toolbar>
  );
};

export default CategoryToolbar;
