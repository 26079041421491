import React, { Fragment } from "react";
import { Box, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import { useAppSelector } from "../../redux/store-hook";
import Currency from "react-currency-formatter";
import { BachmansShipmentWithLineItems } from "../../models/shipment";
import { neutral } from "../../themes/colors";
import { deliveryTextMap } from "../../constants/DeliveryText";
import Link from "../Shared/Link";
import deliveryService from "../../services/delivery.service";
import { isUndefined, sumBy } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: neutral.offwhite_bg,
      boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 15%)",
      padding: theme.spacing(1),
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(1),
    },
    shipmentSection: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    deliveryText: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.2,
    },
    deliveryDescription: {
      color: neutral.text_light_bg,
      lineHeight: 0.75,
    },
    deliveryFees: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.2,
    },
    orderSummaryTitle: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(1, 0),
      color: theme.palette.grey[800],
    },
    currencyText: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 14,
    },
    divider: {
      margin: theme.spacing(1.5, 0),
    },
    darkDivider: {
      backgroundColor: theme.palette.grey[400],
      margin: theme.spacing(1.5, 0),
    },
    boldCurrencyText: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "14px",
    },
    orderTotal: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
    },
    textSummaryCurrency: {
      marginTop: "auto",
      marginBottom: "auto",
      fontSize: "14px",
    },
    total: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "20px",
    },
    flexGap1: {
      gap: theme.spacing(1),
    },
  })
);

const OrderSummary: React.FunctionComponent = () => {
  const classes = useStyles();
  const currentOrder = useAppSelector((state) => state.order);

  const getShipmentName = (shipment: BachmansShipmentWithLineItems) => {
    if (deliveryService.IsPickUp(shipment.Shipper)) {
      return shipment.ToAddress?.AddressName ? shipment.ToAddress?.AddressName + " Store" : "Pickup";
    } else if (shipment.ToAddress) {
      return shipment.ToAddress.xp?.NickName || `${shipment.ToAddress?.FirstName} ${shipment.ToAddress?.LastName}`;
    } else return deliveryTextMap[shipment.Shipper as string]?.text;
  };

  const getShipmentItemsText = (shipment: BachmansShipmentWithLineItems) => {
    var quantity = sumBy(shipment.LineItems, "Quantity");
    return quantity > 1 ? quantity + " Items" : quantity + " Item";
  };

  const hasAddress = (shipment: BachmansShipmentWithLineItems): boolean =>
    !isUndefined(shipment.ToAddress) && shipment.ToAddress !== null;

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography component="h2" className={classes.orderSummaryTitle}>
          Order Summary
        </Typography>
        {currentOrder?.shipments?.map((shipment, i) => (
          <Fragment key={i}>
            <div id="orderSummaryDelivery">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.deliveryText} variant="overline">
                  {getShipmentName(shipment)}
                </Typography>
                <Typography className={classes.currencyText} variant="h4">
                  <Currency quantity={shipment.Subtotal || 0} />
                </Typography>
              </Box>
              {hasAddress(shipment) && deliveryTextMap[shipment.Shipper as string]?.description ? (
                <Typography className={classes.deliveryDescription} variant="body2">
                  {deliveryTextMap[shipment.Shipper as string]?.description + " - " + getShipmentItemsText(shipment)}
                </Typography>
              ) : (
                <Typography className={classes.deliveryDescription} variant="body2">
                  {getShipmentItemsText(shipment)}
                </Typography>
              )}
            </div>
            {!!(shipment.AssemblyCharges && shipment.AssemblyCharges > 0) && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.deliveryFees} variant="body2">
                  Assembly Charges
                </Typography>
                <Typography className={classes.currencyText} variant="h4">
                  +<Currency quantity={shipment.AssemblyCharges} />
                </Typography>
              </Box>
            )}
            {!!(shipment.PlacementCharges && shipment.PlacementCharges > 0) && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.deliveryFees} variant="body2">
                  Placement Charges
                </Typography>
                <Typography className={classes.currencyText} variant="h4">
                  +<Currency quantity={shipment.PlacementCharges} />
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.deliveryFees} variant="body2">
                {deliveryTextMap[shipment.Shipper as string]?.fee}
              </Typography>
              <Typography className={classes.currencyText} variant="h4">
                +
                <Currency
                  quantity={
                    (shipment.Cost || 0) - (shipment?.xp?.HandlingCharges || 0) - (shipment?.xp?.WiredServiceFees || 0)
                  }
                />
              </Typography>
            </Box>
            {!!(shipment.xp?.HandlingCharges && shipment.xp.HandlingCharges > 0) && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.deliveryFees} variant="body2">
                  Handling Charges
                </Typography>
                <Typography className={classes.currencyText} variant="h4">
                  +
                  <Currency quantity={shipment.xp?.HandlingCharges} />
                </Typography>
              </Box>
            )}
            {!!(shipment.xp?.WiredServiceFees && shipment.xp.WiredServiceFees > 0) && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.deliveryFees} variant="body2">
                  Service Fees
                </Typography>
                <Typography className={classes.currencyText} variant="h4">
                  +
                  <Currency quantity={shipment.xp?.WiredServiceFees} />
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography variant="h4" className={classes.boldCurrencyText}>
                <Currency quantity={shipment.Total || 0} />
              </Typography>
            </Box>

            {currentOrder?.shipments && (
              <Divider className={i === currentOrder?.shipments?.length - 1 ? classes.darkDivider : classes.divider} />
            )}
          </Fragment>
        ))}
        <Box display="flex" flexDirection="column" className={classes.flexGap1} id="orderSummaryPrice">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.deliveryFees} variant="body2">
              Subtotal
            </Typography>
            <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
              <Currency quantity={(currentOrder.order?.Subtotal || 0) + (currentOrder.order?.ShippingCost || 0)} />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.deliveryFees} variant="body2">
              Tax
            </Typography>
            <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
              +<Currency quantity={currentOrder.order?.TaxCost || 0} />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <Typography variant="overline" className={classes.orderTotal}>
              Total
            </Typography>
            <Typography variant="h4" color="primary" className={classes.total}>
              <Currency quantity={currentOrder.order?.Total || 0} />
            </Typography>
          </Box>
        </Box>
      </div>
      <Box display="flex" flexDirection="column" mt={3}>
        <Typography variant="h3" color="textSecondary" style={{ textTransform: "initial" }} gutterBottom>
          Need Help?
        </Typography>
        <Link to="/customer-service/frequently-asked-questions">FAQ</Link>
        <Link to="/customer-service/delivery">Billing & Shipping Delivery Rates</Link>
        <Link to="/customer-service/frequently-asked-questions/">Returns & Exchanges</Link>
        <Link to="/customer-service/frequently-asked-questions/">Terms & Conditions</Link>
      </Box>
    </Fragment>
  );
};

export default OrderSummary;
