import { flatten, intersection, isUndefined, keys, uniq, values } from "lodash";
import { Address, BuyerAddress, BuyerProduct, Category, LineItem, Me } from "ordercloud-javascript-sdk";
import { BuyerXp, DeliveryTypes } from "../models/buyerXp";
import bachDateTime from "./bachDateTime.service";
import dcfService from "./dcf.service";
import { Moment } from "moment";
import { defaultTypes, Destination } from "../constants/destinations";
import deliveryResourceService from "./delivery-resource.service";
import stringService from "./string.service";
import moment from "moment";
import { DeliveryMethods } from "../models/delivery";
import productService from "./product.service";
import { OrderState } from "../redux/slices/order";
import { DeliveryOption } from "../components/Product/ProductDetail";

const getDestinationTypes = (methods?: any): Destination[] => {
  if (!methods) {
    return defaultTypes;
  } else if (isPickUp(Object.keys(methods).join(""))) {
    return defaultTypes.filter((d) => isPickUp(d.type));
  } else {
    return defaultTypes.filter((d) => {
      var result = methods ? d.validMethods === "*" || intersection(d.validMethods, keys(methods)).length > 0 : true;
      return result;
    });
  }
};

const getDeliveryTypeFromAddress = (
  products: BuyerProduct[],
  methods: DeliveryMethods,
  address: BuyerAddress,
  currentMethod?: DeliveryTypes
): DeliveryTypes | undefined => {
  if (address.xp?.addressType === "InStorePickUp" && methods.InStorePickUp?.enabled) {
    return isPickUp(currentMethod || "") ? currentMethod : "InStorePickUp";
  }
  if (currentMethod === "USPS" && products.every((p) => p.ID === "GIFTCARD")) {
    return currentMethod;
  } else if (
    isLocalDelivery(address.Zip || "", address.City || "") &&
    methods.LocalDelivery?.enabled &&
    currentMethod !== "Email"
  ) {
    return "LocalDelivery";
  } else if (currentMethod === "Email") {
    return "Email";
  } else if (!isLocalDelivery(address.Zip || "", address.City || "")) {
    const allProdsNationWideEligible = products.every((p) => canDeliverNationwide(methods, p));
    if (allProdsNationWideEligible) {
      if (methods.Courier) {
        if (products.every((p) => canDeliveryWire(p))) {
          return "Wired";
        } else if (products.some((p) => canDeliveryWire(p))) {
          // if some but not all can be wired do not return a method
          return undefined;
        }
      }
      if (products.every((p) => p.ID === "GIFTCARD") && methods.USPS) {
        return "USPS";
      } else if (methods.UPS) {
        return "UPS";
      } else if (methods.USPS) {
        return "USPS";
      }
    }
  }
};

const getAvailableDeliveryMethods = (products: BuyerProduct[], dcfCategories: Category<any>[]): DeliveryMethods => {
  const methodsArray: DeliveryMethods[] = products.map((p) => dcfService.GetDcfSetting(p, "Delivery", dcfCategories));
  const methods: DeliveryMethods = {};
  let types: DeliveryTypes[] = ["Wired", "Courier", "InStorePickUp", "CurbsidePickUp", "LocalDelivery", "USPS", "UPS"];
  if (products.some((p) => p?.ID === "GIFTCARD")) {
    types = ["CurbsidePickUp", "InStorePickUp", "LocalDelivery", "USPS"];
  }
  types.forEach((type) => {
    const thisType = methodsArray.map((m) => m[type]).filter((item) => item !== undefined);
    if (thisType && thisType.length) {
      const enabled = !(thisType.filter((t) => !t?.enabled).length >= 1);
      if (enabled) {
        methods[type] = {
          enabled: !(thisType.filter((t) => !t?.enabled).length >= 1),
          leadDays: Math.max(...thisType.map((t) => t?.leadDays || 0)),
        };
      }
    }
  });
  return methods;
};

const getRouteCode = (zipCode: string, city: string, localAddresses?: any[]): boolean | string => {
  if (localAddresses === null) {
    localAddresses = deliveryResourceService.GetMatchingAddress(zipCode);
  }
  var routeCode = false;
  localAddresses?.forEach((address: any) => {
    if (
      stringService.PreCompare(address.City) === "minnesota" ||
      stringService.PreCompare(address.City) === stringService.PreCompare(city)
    ) {
      routeCode = address.CompanyName;
    }
  });
  return routeCode;
};

const isLocalDelivery = (zipCode: string, city: string): boolean | string => {
  var addressMatchingZip = deliveryResourceService.GetMatchingAddress(zipCode);
  return !city ? !!addressMatchingZip.length : getRouteCode(zipCode, city, addressMatchingZip);
};

export interface DeliveryMethodAndRouteCode {
  method?: DeliveryTypes;
  routeCode?: boolean | string;
}

const isClosedHoliday = (holidays: any[], currentMoment: Moment) => {
  var closedHolidays = holidays?.filter((holiday) => holiday.time.toLowerCase() === "closed").map((h) => h.day);
  return bachDateTime.IsHoliday(currentMoment, closedHolidays);
};

const isDateDisabled = (
  shippingAddress: Address,
  products: BuyerProduct[],
  buyerXp: BuyerXp,
  lyndaleStore: Address,
  delMethod?: DeliveryTypes,
  current?: CurrentDateObj
) => {
  if (!current) return false;
  if (current.mode !== "day") return false;
  var isDisabled = false;
  var currentMoment = bachDateTime.GetUTC(current.date);
  var week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  var weekday = week[currentMoment.isoWeekday() - 1];
  if (isPickUp(delMethod)) {
    shippingAddress?.xp?.WorkingHrs?.forEach((workingRange: any) => {
      if (currentMoment.isBetween(workingRange.StartDate, workingRange.EndDate, null, "[]")) {
        isDisabled = workingRange[weekday].Closed;
      }
      if (isClosedHoliday(shippingAddress.xp.Holidays, currentMoment)) {
        isDisabled = true;
      }
    });
  } else if (delMethod !== "Courier") {
    //DISABLE EVERY SUNDAY (BACHMAN'S / FLORISTS DO NOT DELIVER SUNDAYS)
    if (weekday === "Sun") isDisabled = true;

    //DISABLE HOLIDAYS WHERE LYNDALE STORE (delivery store) IS CLOSED
    if (isClosedHoliday(lyndaleStore.xp.Holidays, currentMoment)) isDisabled = true;

    //DISABLE DAYS WHERE ALL DELIVERY RUNS ARE DISABLED (IF LOCAL DELIVERY)
    if (delMethod === "LocalDelivery") {
      if (buyerXp.DeliveryDatesN[currentMoment.format("YYYY-MM-DD")]) {
        isDisabled = areDeliveryRunsDisabled(buyerXp, currentMoment);
      }
    }
  }
  var skuDisabled = flatten(products.map((p) => p?.xp?.NonDeliveryDates || []));
  //var skuDisabled = product?.xp?.NonDeliveryDates?.length ? product?.xp?.NonDeliveryDates?.length : [];
  if (skuDisabled && skuDisabled.length && delMethod !== "Courier") {
    skuDisabled.forEach((date: any) => {
      if (currentMoment.isSame(date, "day")) isDisabled = true;
    });
  }

  return isDisabled;
};

export interface DeliveryDateData {
  minDate: Date;
  standardMinDate: Date;
  commonMinDate?: Date;
}

export type DeliveryDateOptions = {
  [key in DeliveryTypes]?: DeliveryDateData;
};

export interface DateOptionParameters {
  products: BuyerProduct[];
  deliveryMethods: DeliveryMethods;
  currentOrder?: OrderState;
  shippingAddress?: any;
}
const getDateOptions = async (params: DateOptionParameters): Promise<DeliveryDateOptions> => {
  const { products, deliveryMethods, currentOrder, shippingAddress } = params;
  const [buyerXp, lyndaleStore] = await Promise.all([
    deliveryResourceService.GetBuyerXp(),
    deliveryResourceService.GetLyndaleStore(),
  ]);

  const determineMinimumDate = (
    deliveryType: DeliveryTypes,
    shippingAddress?: any,
    includeSameDay: boolean = true
  ): Date => {
    if (!shippingAddress && isPickUp(deliveryType)) shippingAddress = lyndaleStore;
    let dateFormat = "MM-DD-YYYY";
    let deliveryMethodBuffer = deliveryMethods[deliveryType]?.leadDays;
    var minimumDate;
    if (includeSameDay && bachDateTime.SameDay.CountDown(buyerXp)) {
      minimumDate = bachDateTime.Today();
    } else {
      minimumDate = bachDateTime.Tomorrow();
    }
    if (
      isDateDisabled(shippingAddress, products, buyerXp, lyndaleStore, deliveryType, {
        mode: "day",
        date: minimumDate.toDate(),
      })
    ) {
      var minimumDisabled = true;
      while (minimumDisabled) {
        minimumDate = minimumDate.add(1, "day");
        minimumDisabled = isDateDisabled(shippingAddress, products, buyerXp, lyndaleStore, deliveryType, {
          mode: "day",
          date: minimumDate.toDate(),
        });
      }
    }
    if (deliveryMethodBuffer) {
      //Ignore disabled days when applying the delivery method buffer
      //Example: deliverMethodBuffer is 4 days, if bachmans does not deliver or is closed on one of those days
      //         the deliveryMethodBuffer should not count that day as part of the MinDays.
      while (deliveryMethodBuffer > 0) {
        minimumDate.add(1, "d");
        if (
          !isDateDisabled(shippingAddress, products, buyerXp, lyndaleStore, deliveryType, {
            mode: "day",
            date: minimumDate.toDate(),
          })
        ) {
          deliveryMethodBuffer--;
        }
      }
    }
    return moment(minimumDate.format(dateFormat), dateFormat).toDate();
  };

  const determineCommonDate = (deliveryType: DeliveryTypes, minDate: Date): Date | undefined => {
    let commonDate;
    if (currentOrder?.shipments?.filter((s) => s.Shipper === deliveryType)?.length === 1) {
      const commonShipment = currentOrder.shipments.find((s) => s.Shipper === deliveryType);
      if (moment(commonShipment?.xp?.RequestedDeliveryDate) >= moment(minDate)) {
        commonDate = moment(commonShipment?.xp?.RequestedDeliveryDate).toDate();
      }
    }
    return commonDate;
  };
  const obj: DeliveryDateOptions = {};
  Object.keys(deliveryMethods)?.forEach((type) => {
    const minDate = determineMinimumDate(type as DeliveryTypes, shippingAddress, true);
    obj[type as DeliveryTypes] = {
      minDate: minDate,
      standardMinDate: determineMinimumDate(type as DeliveryTypes, shippingAddress, false),
      commonMinDate: determineCommonDate(type as DeliveryTypes, minDate),
    };
    if (obj.InStorePickUp) {
      obj.CurbsidePickUp = obj.InStorePickUp;
    }
  });
  return obj;
};

export interface CurrentDateObj {
  mode: string;
  date: Date;
}

const areDeliveryRunsDisabled = (buyerXp: BuyerXp, currentMoment: Moment): boolean => {
  const deliverySetting = buyerXp.DeliveryDatesN[currentMoment.format("YYYY-MM-DD")];
  const runsDisabled = (settingsArray: any[], disabledAmount: number) =>
    settingsArray.filter((v) => !v.checkedRun)?.length === disabledAmount;
  if (deliverySetting) {
    var settingsArray = values(deliverySetting);
    return (
      runsDisabled(settingsArray, 3) ||
      (runsDisabled(settingsArray, 2) &&
        deliverySetting.AM.checkedRun &&
        (bachDateTime.IsSameDay(currentMoment.toDate(), bachDateTime.Today().toDate()) ||
          (bachDateTime.IsSameDay(currentMoment.toDate(), bachDateTime.Tomorrow().toDate()) &&
            bachDateTime.IsAfterNextDayAMCutoff(buyerXp))))
    );
  } else return false;
};
export interface LocalAddressData {
  isLocal: boolean;
  routeCode?: string;
}
const preCompare = (text?: string) => {
  //remove whitespace, periods and lowercase text
  return text && typeof text === "string" ? text.replace(/\s/g, "").replace(/\./g, "").toLowerCase() : "";
};

const isAddressLocal = (address: Address, localAddresses: Address[]): LocalAddressData => {
  const addressesMatchingZip = localAddresses.filter((a) => a.Zip === address?.Zip);
  let rc;
  addressesMatchingZip.forEach((a) => {
    if (preCompare(a.City) === "minnesota" || preCompare(a.City) === preCompare(address?.City)) {
      rc = a.CompanyName;
    }
  });
  return {
    isLocal: address?.City ? !isUndefined(rc) : !!addressesMatchingZip.length,
    routeCode: rc,
  };
};

const getPickUpStores = async () => {
  const storeDestination = getDestinationTypes().find((d) => isPickUp(d.type));
  const stores = await Me.ListAddresses({
    filters: storeDestination?.filters,
  });
  return stores.Items;
};

const canDeliveryWire = (product?: BuyerProduct): boolean => {
  return !isUndefined(product) && product.xp.CodeB2 === "Y" && ["E", "F", "T"].includes(product.xp?.CodeB4 || "");
};

const canDeliverNationwide = (methods?: DeliveryMethods, product?: BuyerProduct) => {
  return (methods?.UPS || methods?.USPS) && productService.IsNationWideAvailable(product);
};

const canDeliver = (methods?: DeliveryMethods, product?: BuyerProduct) => {
  return methods?.LocalDelivery || canDeliverNationwide();
};

const getDeliveryRun = (date: moment.Moment, buyerXp: BuyerXp) => {
  return buyerXp.DeliveryDatesN[date.utc().format("YYYY-MM-DD")] || buyerXp.DeliveryRuns[0];
};

const getAddressName = (address: Address, isPickUp?: boolean) => {
  if (address.xp?.NickName) {
    return address.xp.NickName;
  } else if (address.CompanyName && isPickUp === false) {
    return `${address.CompanyName}, ${address.FirstName} ${address.LastName}`;
  } else if (address.CompanyName) {
    return address.CompanyName;
  } else {
    return `${address.FirstName} ${address.LastName}`;
  }
};

const isPickUp = (val?: string) => ["InStorePickUp", "CurbsidePickUp"].includes(val || "");

const allShipmentsArePickUp = (order: OrderState) => {
  return order.shipments?.length && order.shipments.every((s) => isPickUp(s.Shipper));
};

const getMatchingDeliveryMethod = (methods: DeliveryMethods, currentOrder: OrderState): DeliveryTypes | undefined => {
  const deliveryShipments = currentOrder.shipments?.filter((s) => !isPickUp(s.Shipper));
  const currentDeliveryMethods = uniq(deliveryShipments?.map((s) => s.Shipper || ""));
  if (currentDeliveryMethods.length === 1 && methods && methods[currentDeliveryMethods[0] as DeliveryTypes]) {
    return currentDeliveryMethods[0] as DeliveryTypes;
  } else {
    return undefined;
  }
};

const ensureDateNeededAfterDateAdded = (partial: Partial<LineItem>, dateAdded?: string): Partial<LineItem> => {
  if (partial.DateNeeded) {
    const added = moment(dateAdded);
    const newDate = moment(partial.DateNeeded);
    if (added.day() === newDate.day()) {
      const hours = added.hours();
      const minutes = added.minutes();
      newDate.add(hours, "hours");
      newDate.add(minutes + 1, "minutes");
      partial.DateNeeded = newDate.toISOString();
    }
  }
  return partial;
};

const buildDeliveryOption = (type: DeliveryTypes, options?: DeliveryDateOptions): DeliveryOption => {
  let date: string | undefined;
  if (options) {
    date = options[type]?.commonMinDate
      ? options[type]?.commonMinDate?.toDateString()
      : options[type]?.minDate.toDateString();
  }
  return {
    selectedOption: isPickUp(type) ? "PickUp" : "Delivery",
    type: type,
    date: date,
  };
};

const service = {
  GetAvailableDeliveryMethods: getAvailableDeliveryMethods,
  GetDestinationTypes: getDestinationTypes,
  GetDateOptions: getDateOptions,
  IsLocalDelivery: isLocalDelivery,
  IsDateDisabled: isDateDisabled,
  IsAddressLocal: isAddressLocal,
  GetPickUpStores: getPickUpStores,
  CanDeliver: canDeliver,
  CanDeliveryNationWide: canDeliverNationwide,
  CanDeliverWire: canDeliveryWire,
  GetDeliveryRun: getDeliveryRun,
  GetAddressName: getAddressName,
  EnsureDateNeededAfterDateAdded: ensureDateNeededAfterDateAdded,
  IsPickUp: isPickUp,
  AllShipmentsArePickUp: allShipmentsArePickUp,
  GetMatchingDeliveryMethod: getMatchingDeliveryMethod,
  BuildDeliveryDateOption: buildDeliveryOption,
  GetDeliveryTypeFromAddress: getDeliveryTypeFromAddress,
};

export default service;
