import {
  Button,
  Divider,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  alpha,
  CircularProgress,
} from "@material-ui/core";
import { Add, CardGiftcard, Remove } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/store-hook";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import { deliveryTextMap } from "../../../constants/DeliveryText";
import CheckoutLineItemInfo from "./CheckoutLineItemInfo";
import CheckoutLineItemInfoTable from "./CheckoutLineItemInfoTable";
import { BachmansCardMessage, ValidatedBachmansShipmentWithLineItems } from "../../../models/shipment";
import { cloneDeep, flatten, isEqual, isUndefined } from "lodash";
import { LineItem, LineItems } from "ordercloud-javascript-sdk";
import { calculateOrder } from "../../../redux/slices/order";
import { useDispatch } from "react-redux";
import DeliveryInstructions from "./DeliveryInstructions";
import CardMessageForm from "./CardMessage";
import { AppDispatch } from "../../../redux/store";
import { neutral } from "../../../themes/colors";
import Link from "../../Shared/Link";
import deliveryService from "../../../services/delivery.service";
import checkoutService from "../../../services/checkout.service";
import { useHistory, useLocation } from "react-router-dom";
import stringService from "../../../services/string.service";
import $ from "jquery";

export interface DeliveryProps {
  shipments?: ValidatedBachmansShipmentWithLineItems[];
  deliveryValid?: boolean;
  continueToPayment: (updatedShipments: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orDivider: {
      flexGrow: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        "& hr": {
          width: "100%",
        },
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: 60,
        height: "auto",
        alignSelf: "stretch",
      },
      "& .MuiTypography-root": {
        position: "absolute",
        zIndex: 1,
        left: "calc(50vw - 60px)",
        right: 0,
        backgroundColor: "white",
        textAlign: "center",
        maxWidth: 60,
        [theme.breakpoints.up("sm")]: {
          left: 0,
        },
      },
    },
    shipmentAccordion: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    shipmentAccordionSummary: {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
      "& .MuiAccordionSummary-content": {
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column-reverse",
          gap: theme.spacing(1),
        },
      },
    },
    DeliveryAccordionText: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      [theme.breakpoints.up("sm")]: {
        gap: theme.spacing(0.5),
        alignItems: "center",
        flexDirection: "row",
      },
    },
    shipmentStatus: {
      borderRadius: 0,
      padding: theme.spacing(0.5),
      display: "flex",
      alignSelf: "flex-start",
      [theme.breakpoints.up("sm")]: {
        alignSelf: "center",
        marginLeft: "auto",
      },
      "& > *": {
        fontSize: 10,
        padding: 0,
      },
      "& .MuiAlert-icon": {
        fontSize: 14,
      },
    },
    marginLeftAuto: {
      marginLeft: "auto",
    },
    accordionDetails: {
      justifyContent: "center",
      position: "relative",
    },
    listItem: {
      display: "grid",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 2fr",
      },
      "& .MuiListItemText-root": {
        flexGrow: 0,
      },
      "& .MuiButtonBase-root": {
        fontSize: 10,
        minWidth: 50,
        textDecoration: "underline",
        marginLeft: theme.spacing(1),
      },
      "& .MuiTypography-overline": {
        lineHeight: 1.5,
      },
      "& address": {
        fontStyle: "normal",
      },
    },
    lineItemInfoWrapper: {
      maxHeight: 350,
      overflowY: "auto",
    },
    loadingOverlay: {
      backgroundColor: alpha(neutral.offwhite_bg, 0.9),
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      zIndex: 2,
      top: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3),
    },
    giftCardSectionLabelIcon: {
      fontWeight: 600,
      position: "relative",
      top: "5px",
      left: "-2px",
      marginRight: "5px",
    },
    giftCardSectionLabel: {
      fontWeight: 600,
      fontSize: "16px",
      fontFamily:
        "Roboto Slab, -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
      lineHeight: "1.235",
      letterSpacing: "0.00735em",
      textTransform: "capitalize",
    },
  })
);

export interface DeliveryInfo {
  shipment?: ValidatedBachmansShipmentWithLineItems;
  deliveryNoteData?: DeliveryNoteData;
  cardMessageData?: CardMessageData;
}

export interface CardMessageData {
  cardMessage?: BachmansCardMessage | null;
  showField?: boolean;
  readOnly?: boolean;
  applyToAll?: boolean;
}

export interface DeliveryNoteData {
  deliveryNote?: string | null;
  showField?: boolean;
  readOnly?: boolean;
}

const DeliveryInformation: React.FunctionComponent<DeliveryProps> = (props) => {
  const { shipments, deliveryValid, continueToPayment } = props;
  const currentOrder = useAppSelector((state) => state.order);
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [selectedShipment, setSelectedShipment] = useState<ValidatedBachmansShipmentWithLineItems>();
  const [deliveryInfoState, setDeliveryInfoState] = useState<DeliveryInfo[]>();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = stringService.GetQueryParamsFromLocation(location.search);

  const resetData = useCallback((shipments: ValidatedBachmansShipmentWithLineItems[]) => {
    const infoState: DeliveryInfo[] = shipments?.map((shipment) => {
      const hasCardMessage = checkoutService.HasCardMessage(shipment);
      const hasNote = Boolean(shipment.xp?.DeliveryNote && shipment.xp.DeliveryNote.length > 0);
      const hasPlacement = !!(shipment?.PlacementCharges && shipment?.PlacementCharges > 0);
      return {
        shipment: shipment,
        deliveryNoteData: {
          readOnly: hasNote,
          showField: hasNote || hasPlacement,
          deliveryNote: shipment.xp?.DeliveryNote,
        },
        cardMessageData: {
          readOnly: hasCardMessage,
          showField: shipment.xp?.NoCardMessage === true ? false : true,
          cardMessage: shipment.xp?.CardMessage,
          applyToAll:
            (hasCardMessage && shipments.every((s) => isEqual(s.xp?.CardMessage, shipment.xp?.CardMessage))) ||
            shipments.every((s) => s.xp?.NoCardMessage === true),
        },
      };
    });
    setDeliveryInfoState(infoState);
  }, []);

  useEffect(() => {
    if (shipments && shipments.length) {
      resetData(shipments);
    }
    if (!isUndefined(queryParams.completedDeliveryInfo) && String(queryParams.completedDeliveryInfo) === "true") {
      continueToPayment(shipments);
    } else {
    }
  }, [resetData, shipments, location.search]);

  const placementAndNoNote = (shipment?: ValidatedBachmansShipmentWithLineItems) => {
    return shipment?.PlacementCharges && shipment?.PlacementCharges > 0 && !shipment.xp?.DeliveryNote;
  };

  const allItemsValid = (shipment?: ValidatedBachmansShipmentWithLineItems) => {
    return shipment && shipment?.LineItems.every((li) => li.Validation?.valid === true);
  };

  const handleShipmentChange = (shipmentID?: string) => {
    if (shipments && shipments.length) {
      //resetData(shipments);
    }
    if (shipmentID) {
      if (shipmentID === selectedShipment?.ID) {
        setSelectedShipment(undefined);
      } else {
        const newShipment = shipments?.find((s) => s.ID === shipmentID);
        setSelectedShipment(newShipment);
      }
    }
  };

  const saveCardMessage = async (
    shipmentID: string,
    messageData?: CardMessageData,
    moveNext?: boolean | false,
    moveLast?: boolean | false
  ) => {
    const shipment = selectedShipment ? selectedShipment : shipments?.find((s) => s.ID === shipmentID);
    if (currentOrder?.order?.ID && shipment && shipments) {
      const currentState = cloneDeep(deliveryInfoState)?.find((info) => info.shipment?.ID === shipmentID);
      let reqs: Promise<any>[] = [];
      if (currentState?.cardMessageData?.applyToAll && messageData?.applyToAll === false) {
        // now we need to remove card message from all other shipments
        const shipmentIDsToUpdate = shipments.filter((s) => s.ID !== shipmentID).map((s) => s.ID!);
        reqs = buildLineItemReqs(shipmentIDsToUpdate, { xp: { CardMessage: null } });
        reqs.concat(
          buildLineItemReqs([shipmentID], {
            xp: { CardMessage: messageData?.cardMessage, NoCardMessage: messageData?.cardMessage === null },
          })
        );
      } else {
        const shipmentIDsToUpdate = messageData?.applyToAll ? shipments?.map((s) => s.ID!) : [shipmentID];
        reqs = buildLineItemReqs(shipmentIDsToUpdate, {
          xp: { CardMessage: messageData?.cardMessage, noCardMessage: messageData?.cardMessage === null },
        });
      }
      await updateCardMessage(shipmentID, {
        showField: messageData?.showField,
        readOnly: messageData?.showField,
        cardMessage: messageData?.cardMessage,
        applyToAll: messageData?.applyToAll,
      });
      await Promise.all(reqs);
      await dispatch(calculateOrder(currentOrder.order.ID));
      const shipmentIDs = shipments.map((s) => s.ID!);
      const curIndex = selectedShipment ? shipmentIDs.indexOf(selectedShipment.ID!) : 0;
      if (curIndex === shipments.length - 1) {
        const allOtherShipments = shipments.filter((s, i) => i !== curIndex);
        const allOtherShipmentsValid =
          allOtherShipments.length === 0 ||
          allOtherShipments.every(
            (s) => s.xp?.NoCardMessage === true || (!isUndefined(s.xp?.CardMessage) && s.xp?.CardMessage !== null)
          );
        if (deliveryValid && allOtherShipmentsValid) {
          continueToPayment(shipments);
        }
      } else {
        let lastIndex = shipments.length - 1;
        let urlSearchParams = new URLSearchParams(location.search);

        if (moveNext === true) {
          setSelectedShipment(shipments[curIndex + 1]);
        }
        if (moveLast === true) {
          setSelectedShipment(shipments[lastIndex]);
          if (!isUndefined(urlSearchParams)) {
            if (urlSearchParams.has("currentShipmentIndex")) {
              urlSearchParams.set("currentShipmentIndex", String(lastIndex));
            } else {
              urlSearchParams.append("currentShipmentIndex", String(lastIndex));
            }
            if (urlSearchParams.has("completedDeliveryInfo")) {
              urlSearchParams.set("completedDeliveryInfo", "true");
            } else {
              urlSearchParams.append("completedDeliveryInfo", "true");
            }
          }
          sessionStorage.setItem("checkoutActiveUrlParams", urlSearchParams.toString());
          history.push({
            pathname: location.pathname,
            search: urlSearchParams.toString(),
          });
        }
      }
    }
  };

  const buildLineItemReqs = (shipmentIDs: string[], partial: Partial<LineItem>): Promise<any>[] => {
    const flatLines = flatten(shipments?.filter((s) => shipmentIDs.includes(s.ID!)).map((s) => s.LineItems));
    const requests = flatLines.map((li) => LineItems.Patch("Outgoing", currentOrder.order?.ID!, li.ID!, partial));
    return requests;
  };

  const saveDeliveryNote = async (shipmentID: string, deliveryNote?: string | null) => {
    await updateDeliveryNote(shipmentID, {
      showField: false,
      readOnly: true,
      deliveryNote: deliveryNote,
    });
    const shipment = selectedShipment ? selectedShipment : shipments?.find((s) => shipmentID);
    if (currentOrder.order?.ID && shipment) {
      let reqs = await shipment?.LineItems?.map((li) =>
        LineItems.Patch("Outgoing", currentOrder.order?.ID!, li.ID!, { xp: { DeliveryNote: deliveryNote } })
      );
      await Promise.all(reqs!);
      await dispatch(calculateOrder(currentOrder.order.ID));
    }
  };

  const updateCardMessage = async (shipmentID: string, cardMessageData: CardMessageData) => {
    const state = cloneDeep(deliveryInfoState);
    const newInfo = state?.map((info) => {
      if (info.shipment?.ID === shipmentID) {
        info.cardMessageData = cardMessageData;
      }
      return info;
    });
    setDeliveryInfoState(newInfo);
  };

  const updateDeliveryNote = async (shipmentID: string, deliveryNoteData: DeliveryNoteData) => {
    const state = cloneDeep(deliveryInfoState);
    const newInfo = state?.map((info) => {
      if (info.shipment?.ID === shipmentID) {
        info.deliveryNoteData = deliveryNoteData;
      }
      return info;
    });
    setDeliveryInfoState(newInfo);
  };

  const LinkBackToCart = (props: any) => <Link to="/cart" {...props} />;

  const isExpanded = (info: DeliveryInfo, index: number): boolean => {
    if (isUndefined(queryParams)) {
      return !isUndefined(selectedShipment) ? selectedShipment?.ID === info.shipment?.ID : index === 0;
    } else {
      return !isUndefined(selectedShipment)
        ? selectedShipment?.ID === info.shipment?.ID
        : index === parseInt(queryParams.currentShipmentIndex, 10);
    }
  };

  const isEvent = (info: DeliveryInfo): boolean =>
    info?.shipment?.LineItems?.every((li) => li?.Product?.xp?.IsWorkShopEvent)!;

  const formatPhoneNumber = (info: DeliveryInfo) => {
    const maskedTelephone = info.shipment?.ToAddress?.Phone!.replace(/\D+/g, "").replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return maskedTelephone;
  };

  const getUpdatedCardMessageData = (
    line1Val: string,
    line2Val: string,
    line3Val: string,
    line4Val: string
  ): BachmansCardMessage => {
    return {
      line1: line1Val.replace("undefined", "").replace(/['’]/g, ""),
      line2: line2Val.replace("undefined", "").replace(/['’]/g, ""),
      line3: line3Val.replace("undefined", "").replace(/['’]/g, ""),
      line4: line4Val.replace("undefined", "").replace(/['’]/g, ""),
    };
  };

  const isCardMessageDataEmpty = (line1Val: string, line2Val: string, line3Val: string, line4Val: string): boolean => {
    return (
      line1Val.trim().length === 0 &&
      line2Val.trim().length === 0 &&
      line3Val.trim().length === 0 &&
      line4Val.trim().length === 0
    );
  };

  const SaveAllCardMessages = async (shipmentID: string, cardMessageData: CardMessageData) => {
    $(".delivery-info-invalid-error").removeClass("show");
    var shipmentItems = $("[aria-labelledby*='shipmentAccordionSummary']");
    if (isUndefined(deliveryInfoState) || isUndefined(shipmentItems) || isUndefined(queryParams.currentShipmentIndex)) {
      $("#delivery-info-session-invalid").addClass("show");
      setLoading(false);
      return;
    }

    const updatedCardMessage: BachmansCardMessage = cardMessageData!.cardMessage!;
    for (var index = 0; index < shipmentItems.length; index++) {
      var currentDelInfoState = deliveryInfoState![index];
      var itemData: CardMessageData = {
        cardMessage: updatedCardMessage,
        readOnly: true,
        applyToAll: true,
      };
      await updateAllCardMessages(currentDelInfoState!.shipment!.ID!, itemData);
      console.log(`updateAllCardMessages completed for recipient ${index + 1}`);
    }
    console.log(`continueToPayment completed for all recipients.`);
  };

  const updateAllCardMessages = async (shipmentID: string, cardMessageData: CardMessageData) => {
    await saveCardMessage(shipmentID, cardMessageData, false, true);
  };

  const reSavePrevCardMessages = async (deliveryInfoItems: DeliveryInfo[]) => {
    for (var i = 0; i < deliveryInfoItems.length; i++) {
      var infoItem = deliveryInfoItems[i];
      await saveCardMessage(infoItem!.shipment!.ID!, infoItem!.cardMessageData!, false, false);
    }
  };

  return (
    <Fragment>
      {deliveryInfoState?.map((info, index) => {
        return (
          <Accordion
            key={index}
            square
            onChange={() => handleShipmentChange(info.shipment?.ID)}
            className={classes.shipmentAccordion}
            expanded={isExpanded(info, index)}
          >
            <AccordionSummary
              expandIcon={isExpanded(info, index) ? <Remove /> : <Add />}
              className={classes.shipmentAccordionSummary}
              aria-controls="panel1a-content"
              id={`shipmentAccordionSummary_${index}`}
            >
              <Box display="flex" flexDirection="column" alignSelf="flex-start">
                {isEvent(info) && (
                  <Typography variant="body2" color="primary">
                    ({info.shipment?.LineItems?.length}) Ticket{info.shipment!.LineItems.length > 1 && "s"}
                  </Typography>
                )}
                {!isEvent(info) && (
                  <Typography variant="body2" color="primary">
                    ({info.shipment?.LineItems?.length}) Item{info.shipment!.LineItems.length > 1 && "s"} for{" "}
                    {deliveryTextMap[info.shipment?.xp?.DeliveryMethod || ""]?.description}
                  </Typography>
                )}
                <Box className={classes.DeliveryAccordionText}>
                  <Typography variant="h5">
                    {!deliveryService.IsPickUp(info.shipment?.Shipper)
                      ? `${info.shipment?.ToAddress?.FirstName} ${info.shipment?.ToAddress?.LastName}`
                      : info.shipment?.ToAddress?.CompanyName + " Store"}
                  </Typography>
                  {info.shipment?.xp?.DeliveryMethod === "Email" && (
                    <Typography variant="h5" color="primary">
                      {" | " + info.shipment?.LineItems[0].xp?.Email}
                    </Typography>
                  )}
                  {info.shipment?.xp?.DeliveryMethod !== "Email" && (
                    <Typography variant="h5" color="primary">
                      {info.shipment?.ToAddress?.CompanyName &&
                        !deliveryService.IsPickUp(info.shipment?.Shipper) &&
                        " | " + info.shipment?.ToAddress?.CompanyName}
                      {info.shipment?.xp?.AddressType === "Residence" && " | " + info.shipment?.xp?.AddressType}
                    </Typography>
                  )}
                </Box>
              </Box>
              {(placementAndNoNote(info.shipment) || !allItemsValid(info.shipment)) &&
                info.shipment?.xp?.DeliveryMethod !== "Email" && (
                  <Alert variant="outlined" severity="error" className={classes.shipmentStatus}>
                    Incomplete
                  </Alert>
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Grid container spacing={3}>
                {loading && (
                  <Grid item xs={12} className={classes.loadingOverlay}>
                    <CircularProgress />
                    <Typography display="inline" variant="caption">
                      Updating Delivery Info...
                    </Typography>
                  </Grid>
                )}
                <Grid xs={12} sm item>
                  <List disablePadding>
                    {info?.shipment?.Shipper !== "Email" && (
                      <ListItem disableGutters className={classes.listItem}>
                        <ListItemText
                          primary={isEvent(info) ? "Event Date" : "Delivery Date"}
                          primaryTypographyProps={{ variant: "overline", color: "textSecondary" }}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                          <ListItemText
                            primary={moment(info.shipment?.xp?.RequestedDeliveryDate).format("MM/DD/YYYY")}
                            primaryTypographyProps={{ variant: "body1" }}
                          />
                          {!isEvent(info) && (
                            <Button component={LinkBackToCart} variant="text" color="primary" size="small">
                              Edit
                            </Button>
                          )}
                        </Box>
                      </ListItem>
                    )}

                    <ListItem disableGutters className={classes.listItem}>
                      <ListItemText
                        primary="Delivery Method"
                        primaryTypographyProps={{ variant: "overline", color: "textSecondary" }}
                      />
                      {info?.shipment?.Shipper == "Email" && (
                        <ListItemText primary="E-mail" primaryTypographyProps={{ variant: "body1" }} />
                      )}
                      <ListItemText
                        primary={
                          isEvent(info) && info?.shipment?.Shipper !== "Email"
                            ? "E-mail"
                            : deliveryTextMap[info.shipment?.xp?.DeliveryMethod || ""]?.description
                        }
                        secondary={
                          <Fragment>
                            {info?.shipment?.Shipper === "LocalDelivery" && (
                              <Typography component="p" variant="body2" color="textSecondary">
                                {info.shipment.xp?.TimePreference === "AM" && "Morning"}
                                {info.shipment.xp?.TimePreference === "PM" && "Afternoon"}
                                {info.shipment.xp?.TimePreference !== "NO PREF" && " "}({info.shipment?.xp?.TimeWindow})
                              </Typography>
                            )}
                          </Fragment>
                        }
                        primaryTypographyProps={{ variant: "body1" }}
                      />
                    </ListItem>
                    {!isEvent(info) && info?.shipment?.Shipper !== "Email" && (
                      <ListItem disableGutters className={classes.listItem}>
                        <ListItemText
                          primary={
                            deliveryService.IsPickUp(info.shipment?.Shipper) ? "Pickup Location" : "Delivery Address"
                          }
                          primaryTypographyProps={{ variant: "overline", color: "textSecondary" }}
                        />
                        <ListItemText
                          primary={
                            <address>
                              {info.shipment?.ToAddress?.Street1} {info.shipment?.ToAddress?.Street2 && <br />}{" "}
                              {info.shipment?.ToAddress?.Street2} <br />
                              {info.shipment?.ToAddress?.City}, {info.shipment?.ToAddress?.State}{" "}
                              {info.shipment?.ToAddress?.Zip}
                            </address>
                          }
                          primaryTypographyProps={{ variant: "body1" }}
                        />
                      </ListItem>
                    )}
                    {info?.shipment?.Shipper === "Email" && (
                      <ListItem disableGutters className={classes.listItem}>
                        <ListItemText
                          primary={"Recipient Information"}
                          primaryTypographyProps={{ variant: "overline", color: "textSecondary" }}
                        />
                        <ListItemText
                          primary={`${info.shipment?.ToAddress?.FirstName} ${info.shipment?.ToAddress?.LastName}`}
                          primaryTypographyProps={{ variant: "body1" }}
                          secondary={info.shipment?.LineItems[0].xp?.Email}
                        />
                      </ListItem>
                    )}
                    {deliveryService.IsPickUp(info.shipment?.Shipper) && (
                      <ListItem disableGutters className={classes.listItem}>
                        <ListItemText
                          primary={isEvent(info) ? "Attendee Information" : "Pickup Person Information"}
                          primaryTypographyProps={{ variant: "overline", color: "textSecondary" }}
                        />
                        <ListItemText
                          primary={`${info.shipment?.ToAddress?.FirstName} ${info.shipment?.ToAddress?.LastName}`}
                          secondary={
                            <Fragment>
                              {deliveryService.IsPickUp(info?.shipment?.Shipper) && (
                                <Typography variant="body2" color="textSecondary">
                                  {formatPhoneNumber(info)}
                                </Typography>
                              )}
                            </Fragment>
                          }
                        ></ListItemText>
                      </ListItem>
                    )}
                  </List>
                  {!isEvent(info) && info?.shipment?.Shipper !== "Email" && (
                    <Fragment>
                      {deliveryService.IsPickUp(info.shipment?.Shipper) ? (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          {info.shipment?.Shipper === "InStorePickUp"
                            ? "For pickup orders, please check-in at the floral counter"
                            : "Upon arrival to the store, call the number on the curbside pickup sign for assistance."}
                        </Typography>
                      ) : (
                        <DeliveryInstructions
                          shipment={info.shipment}
                          deliveryNoteData={info.deliveryNoteData}
                          saveDeliveryNote={async (deliveryNote?: string | null) =>
                            await saveDeliveryNote(info.shipment?.ID!, deliveryNote)
                          }
                          updateDeliveryNote={async (data: DeliveryNoteData) =>
                            await updateDeliveryNote(info.shipment?.ID!, data)
                          }
                        />
                      )}
                    </Fragment>
                  )}
                  <div className={classes.lineItemInfoWrapper}>
                    {info.shipment?.LineItems?.map((lineItem) => (
                      <Fragment>
                        {info.shipment?.LineItems && info.shipment?.LineItems?.length < 3 ? (
                          <CheckoutLineItemInfo lineItem={lineItem} />
                        ) : (
                          <CheckoutLineItemInfoTable lineItem={lineItem} />
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm className={classes.orDivider}>
                  <Divider orientation={!isMobile ? "vertical" : "horizontal"} />
                </Grid>
                <Grid item xs={12} sm>
                  <Typography
                    variant="overline"
                    display="block"
                    color="textPrimary"
                    className={classes.giftCardSectionLabel}
                  >
                    <CardGiftcard color="action" className={classes.giftCardSectionLabelIcon} /> Is This A Gift?
                  </Typography>
                  <CardMessageForm
                    cardMessageData={info.cardMessageData}
                    deliveryInfoStateArray={deliveryInfoState}
                    updateCardMessage={updateCardMessage}
                    saveCardMessage={saveCardMessage}
                  />
                  <DoubleOutlinedBtn
                    buttonText="Save and Continue"
                    buttonProps={{
                      id: `btnSaveAndContinue_${index}`,
                      type: "submit",
                      onClick: async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        console.clear();
                        setLoading(true);

                        $(".delivery-info-invalid-error").removeClass("show");
                        var currentIndex = index;
                        var shipmentAccordionSummaryItem = $(
                          `[aria-labelledby='shipmentAccordionSummary_${currentIndex}']`
                        );
                        var shipmentItemsLen = $("[aria-labelledby*='shipmentAccordionSummary']").length;
                        let nextShipmentAccordionSummaryItem = $(
                          `[aria-labelledby='shipmentAccordionSummary_${currentIndex + 1}']`
                        );
                        if (isUndefined(shipmentAccordionSummaryItem) || $(shipmentAccordionSummaryItem).length !== 1) {
                          $("#delivery-info-session-invalid").addClass("show");
                          $(shipmentAccordionSummaryItem).find("#delivery-info-session-invalid").get(0)!.focus();
                          setLoading(false);
                          return;
                        }

                        var isAllRecipientsValid = true;
                        var cardMsgApplyToAllFlag = $(shipmentAccordionSummaryItem)
                          .find("#applyCardMessageToAllShipments")
                          .is(":checked");
                        var deliveryInstrFlag = $(shipmentAccordionSummaryItem)
                          .find("#deliveryInstructionsCheckbox")
                          .is(":checked");
                        var includeMsgeFlag = $(shipmentAccordionSummaryItem)
                          .find("#cardMessageCheckbox")
                          .is(":checked");
                        var currentDelInfoState = deliveryInfoState![index];

                        if (currentDelInfoState === null || currentDelInfoState?.shipment === null) {
                          $(shipmentAccordionSummaryItem).find("#delivery-info-session-invalid").addClass("show");
                          $(shipmentAccordionSummaryItem).find("#delivery-info-session-invalid").get(0)!.focus();
                          setLoading(false);
                          return;
                        } else {
                          var cardMsgLine1 = String(
                            $(shipmentAccordionSummaryItem).find("#cardMessageLine1").val() ||
                              $(shipmentAccordionSummaryItem).find(".cardMessageLine1").text()
                          );
                          var cardMsgLine2 = String(
                            $(shipmentAccordionSummaryItem).find("#cardMessageLine2").val() ||
                              $(shipmentAccordionSummaryItem).find(".cardMessageLine2").text()
                          );
                          var cardMsgLine3 = String(
                            $(shipmentAccordionSummaryItem).find("#cardMessageLine3").val() ||
                              $(shipmentAccordionSummaryItem).find(".cardMessageLine3").text()
                          );
                          var cardMsgLine4 = String(
                            $(shipmentAccordionSummaryItem).find("#cardMessageLine4").val() ||
                              $(shipmentAccordionSummaryItem).find(".cardMessageLine4").text()
                          );
                          if (cardMsgApplyToAllFlag === true) {
                            currentDelInfoState.cardMessageData!.cardMessage = getUpdatedCardMessageData(
                              cardMsgLine1,
                              cardMsgLine2,
                              cardMsgLine3,
                              cardMsgLine4
                            );
                            let deliveryInstrValue =
                              $(shipmentAccordionSummaryItem).find("#deliveryInstructions").val() ||
                              $(shipmentAccordionSummaryItem).find(".deliveryInstructions").text();
                            if (deliveryInstrFlag === true && String(deliveryInstrValue).length === 0) {
                              $(shipmentAccordionSummaryItem)
                                .find("#delivery-info-instructions-invalid")
                                .addClass("show");
                              $(shipmentAccordionSummaryItem).find("#deliveryInstructions").get(0)!.focus();
                              isAllRecipientsValid = false;
                              return;
                            } else {
                              await saveDeliveryNote(
                                currentDelInfoState!.shipment!.ID!,
                                deliveryInstrValue ? (deliveryInstrValue as string) : null
                              );
                              //console.log(`deliveryInstrValue: ${deliveryInstrValue}`);
                              console.log(`saveDeliveryNote completed for recipient ${index + 1}`);
                            }

                            if (isCardMessageDataEmpty(cardMsgLine1, cardMsgLine2, cardMsgLine3, cardMsgLine4)) {
                              $(shipmentAccordionSummaryItem)
                                .find("#delivery-info-card-message-invalid")
                                .addClass("show");
                              $(shipmentAccordionSummaryItem).find("#cardMessageLine1").get(0)!.focus();
                              isAllRecipientsValid = false;
                              return;
                            }
                            await SaveAllCardMessages(
                              currentDelInfoState!.shipment!.ID!,
                              currentDelInfoState!.cardMessageData!
                            );
                          } else {
                            if (isAllRecipientsValid === true) {
                              //If the current 'queryParams?.currentShipmentIndex' is not the same as the active item's index and the active item's 'applyToAll'
                              //flag is false then, trigger the 'reSavePrevCardMessages' action before saving current/new item.
                              if (
                                queryParams?.currentShipmentIndex < currentIndex &&
                                currentDelInfoState?.cardMessageData?.applyToAll !== true
                              ) {
                                const deliveryInfoItems = deliveryInfoState.filter(
                                  (item) => item.shipment?.ID !== currentDelInfoState!.shipment!.ID!
                                );
                                await reSavePrevCardMessages(deliveryInfoItems);
                              }

                              let deliveryInstrValue =
                                $(shipmentAccordionSummaryItem).find("#deliveryInstructions").val() ||
                                $(shipmentAccordionSummaryItem).find(".deliveryInstructions").text();
                              if (deliveryInstrFlag === true && String(deliveryInstrValue).length === 0) {
                                $(shipmentAccordionSummaryItem)
                                  .find("#delivery-info-instructions-invalid")
                                  .addClass("show");
                                $(shipmentAccordionSummaryItem).find("#deliveryInstructions").get(0)!.focus();
                                isAllRecipientsValid = false;
                                setLoading(false);
                                return;
                              } else {
                                await saveDeliveryNote(
                                  currentDelInfoState!.shipment!.ID!,
                                  deliveryInstrValue ? (deliveryInstrValue as string) : null
                                );
                                //console.log(`deliveryInstrValue: ${deliveryInstrValue}`);
                                console.log(`saveDeliveryNote completed for recipient ${index + 1}`);
                              }

                              if (
                                includeMsgeFlag === true &&
                                isCardMessageDataEmpty(cardMsgLine1, cardMsgLine2, cardMsgLine3, cardMsgLine4)
                              ) {
                                $(shipmentAccordionSummaryItem)
                                  .find("#delivery-info-card-message-invalid")
                                  .addClass("show");
                                isAllRecipientsValid = false;
                                setLoading(false);
                                return;
                              } else {
                                currentDelInfoState.cardMessageData!.cardMessage = getUpdatedCardMessageData(
                                  cardMsgLine1,
                                  cardMsgLine2,
                                  cardMsgLine3,
                                  cardMsgLine4
                                );

                                currentDelInfoState!.cardMessageData!.showField = includeMsgeFlag;
                                currentDelInfoState!.cardMessageData!.readOnly = includeMsgeFlag;
                                currentDelInfoState!.cardMessageData!.applyToAll = cardMsgApplyToAllFlag;
                                if (includeMsgeFlag === false) {
                                  currentDelInfoState!.cardMessageData!.cardMessage = null;
                                }
                                await updateCardMessage(
                                  currentDelInfoState!.shipment!.ID!,
                                  currentDelInfoState.cardMessageData!
                                );
                                await saveCardMessage(
                                  currentDelInfoState!.shipment!.ID!,
                                  currentDelInfoState!.cardMessageData,
                                  true
                                );
                                //console.log(`deliveryInfoState: ${currentDelInfoState.cardMessageData!.cardMessage.line1}`);
                                console.log(`saveCardMessage completed for recipient ${index + 1}`);
                              }
                            }

                            let urlSearchParams = new URLSearchParams(location.search);
                            if (isAllRecipientsValid === true && currentIndex + 1 === shipmentItemsLen) {
                              if (!isUndefined(urlSearchParams)) {
                                if (urlSearchParams.has("currentShipmentIndex")) {
                                  urlSearchParams.set("currentShipmentIndex", String(shipmentItemsLen - 1));
                                } else {
                                  urlSearchParams.append("currentShipmentIndex", String(shipmentItemsLen - 1));
                                }
                                if (urlSearchParams.has("completedDeliveryInfo")) {
                                  urlSearchParams.set("completedDeliveryInfo", "true");
                                } else {
                                  urlSearchParams.append("completedDeliveryInfo", "true");
                                }
                                sessionStorage.setItem("checkoutActiveUrlParams", urlSearchParams.toString());
                                history.push({
                                  search: urlSearchParams.toString(),
                                });
                              }
                              console.log(`continueToPayment completed for all recipients.`);
                              continueToPayment(shipments);
                            } else {
                              if (!isUndefined(urlSearchParams)) {
                                if (urlSearchParams.has("currentShipmentIndex")) {
                                  urlSearchParams.set("currentShipmentIndex", String(currentIndex + 1));
                                } else {
                                  urlSearchParams.append("currentShipmentIndex", String(currentIndex + 1));
                                }
                                sessionStorage.setItem("checkoutActiveUrlParams", urlSearchParams.toString());
                                history.push({
                                  search: urlSearchParams.toString(),
                                });

                                if (
                                  !isUndefined(nextShipmentAccordionSummaryItem) &&
                                  $(nextShipmentAccordionSummaryItem).length === 1
                                ) {
                                  if ($(nextShipmentAccordionSummaryItem).find("#cardMessageLine1")) {
                                    $(nextShipmentAccordionSummaryItem).find("#cardMessageLine1").get(0)?.focus();
                                  }
                                }
                              }
                            }
                          }
                          setLoading(false);
                        }
                      },
                    }}
                    styleProps={{
                      marginRight: isMobile ? null : "0px",
                      marginTop: 32,
                      padding: "4px 40px",
                      width: isMobile ? "100%" : "auto",
                      float: "right",
                    }}
                  />
                </Grid>
                <p
                  id="delivery-info-session-invalid"
                  className="MuiTypography-root MuiTypography-body2 MuiTypography-colorError"
                >
                  Something went wrong, your checkout session has timed out. Please restart the checkout process...
                </p>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Fragment>
  );
};

export default DeliveryInformation;
