import React, { ChangeEvent, Fragment, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { neutral } from "../../themes/colors";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      display: "block",
      marginTop: theme.spacing(1),
    },
    disabledTextfield: {
      display: "block",
      marginTop: theme.spacing(1),
      cursor: "not-allowed",
      backgroundColor: neutral.grey_background,
    },
    accordion: {
      boxShadow: "none",
    },
    accordionSummary: {
      background: neutral.grey_background,
      minHeight: "48px",
      "& > .Mui-expanded": {
        minHeight: "48px",
      },
    },
    accordionDetails: {
      padding: 0,
    },
    details: {
      width: "100%",
    },
    checkIcon: {
      fontSize: "18px",
      marginLeft: "8px",
      position: "absolute",
      top: "33%",
    },
    selectService: {
      marginBottom: theme.spacing(1),
    },
    label: {
      marginLeft: "-5px",
      "& span": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    checkbox: {
      padding: "5px",
    },
    buttonBorder: {
      border: "1px solid black",
      padding: "1px",
      marginBottom: "5px",
    },
    buttonContainer: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "80%",
      },
    },
    selectLabel: {
      marginTop: theme.spacing(2),
      color: neutral.text_light_bg,
    },
  })
);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: neutral.search_bg,
    height: 48,
    minHeight: 48,
    "&$expanded": {
      height: 48,
      minHeight: 48,
    },
  },
  content: {},
  expanded: {},
})(MuiAccordionSummary);

export interface SearchData {
  city?: string;
  zip?: string;
}

export interface StoreFilterProps {
  services?: string[];
  submitSearch?: (data: SearchData) => Promise<void>;
  filterServices?: (data: string[]) => void;
  resetStores?: () => void;
}

export const StoreFilter: React.FunctionComponent<StoreFilterProps> = (props) => {
  const { services, submitSearch, filterServices, resetStores } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [city, setCity] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const classes = useStyles();

  const handleZipChange = (event: ChangeEvent<HTMLInputElement>) => setZip(event.currentTarget.value);

  const handleCityChange = (event: ChangeEvent<HTMLInputElement>) => setCity(event.currentTarget.value);

  const handlekeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (submitSearch && (city.length || zip.length)) {
      submitSearch({
        city: city,
        zip: zip,
      });
      handleChange();
    }
  };

  const reset = () => {
    setCity("");
    setZip("");
    setSelectedServices([]);
    if (resetStores) {
      resetStores();
    }
  };

  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const service = e.currentTarget.name;
    const newServices = selectedServices.includes(service)
      ? selectedServices.filter((s) => s !== service)
      : [...selectedServices, service];
    setSelectedServices(newServices);
    if (filterServices) {
      filterServices(newServices);
    }
  };

  const handleChange = () => setIsExpanded(!isExpanded);

  return (
    <Fragment>
      <Accordion className={classes.accordion} expanded={isExpanded} onChange={handleChange} square={true}>
        <AccordionSummary expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="body1">Filter Stores</Typography>
          <div>{(city !== "" || zip !== "") && <CheckCircleIcon className={classes.checkIcon} color="primary" />}</div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.details}>
            <TextField
              className={city !== "" ? classes.disabledTextfield : classes.textField}
              fullWidth={true}
              label="Enter Zip Code"
              variant="outlined"
              size="small"
              disabled={city !== "" && zip === ""}
              onKeyUp={handlekeyDown}
              value={zip}
              onChange={handleZipChange}
            />
            <TextField
              className={zip !== "" ? classes.disabledTextfield : classes.textField}
              fullWidth={true}
              label="Enter City"
              variant="outlined"
              size="small"
              disabled={zip !== "" && city === ""}
              onKeyUp={handlekeyDown}
              value={city}
              onChange={handleCityChange}
            />
            <div className={classes.selectService}>
              <Typography className={classes.selectLabel} variant="overline" display="block">
                Select services you are looking for
              </Typography>
              <FormGroup className={classes.selectService}>
                {services &&
                  services.length &&
                  services.map((service: string, i) => (
                    <FormControlLabel
                      key={i}
                      className={classes.label}
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          name={service}
                          size="small"
                          checked={selectedServices.includes(service)}
                          onChange={handleServiceChange}
                        />
                      }
                      label={service}
                      labelPlacement="end"
                    />
                  ))}
              </FormGroup>
              <div className={classes.buttonContainer}>
                <div className={classes.buttonBorder}>
                  <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                    Find a Store
                  </Button>
                </div>
                <Button variant="outlined" color="primary" onClick={reset} fullWidth>
                  Reset Filters
                </Button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};

export default StoreFilter;
