export const sortOptions = [
  {
    title: "Relevance",
    index: "dotcom_products",
  },
  {
    title: "New Products",
    index: "new_products",
  },
  {
    title: "Price: High - Low",
    index: "products_price_desc",
  },
  {
    title: "Price: Low - High",
    index: "products_price_asc",
  },
];

export const sortingCategoryOptions = [
  {
    title: "Relevance",
    index: "products",
  },
  {
    title: "New Products",
    index: "new_products",
  },
  {
    title: "Price: High - Low",
    index: "products_price_desc",
  },
  {
    title: "Price: Low - High",
    index: "products_price_asc",
  },
  {
    title: "Name: A - Z",
    index: "dotcom_products_asc",
  },
  {
    title: "Name: Z - A",
    index: "dotcom_products_desc",
  },
];

export default sortOptions;
