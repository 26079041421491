//used in ContactUsDialog && static Page contact us //TODO: create a ContactUs dialog compomponent

import React, { FunctionComponent, useState } from "react";
import {
  createStyles,
  FormControl,
  makeStyles,
  Theme,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Dialog,
  IconButton,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";
import { useContext } from "react";
import { StaticPageContext } from "../../providers/contentful";
import { useEffect } from "react";
import contentful from "../../services/contentful.service";
import IframeResizer from "iframe-resizer-react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { find } from "lodash";
import { Entry } from "contentful";
import { Close } from "@material-ui/icons";

interface ContactUsProps {
  page?: Entry<any>;
  hideForm?: boolean;
  formOnly?: boolean;
  greyMode?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 0, 2, 0),
        width: "75%",
      },
    },
    formContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      "& .MuiDialogContent-root": {
        flex: "0 0 auto",
      },
    },
    iframe: {
      border: "none",
      boxShadow: theme.shadows[1],
    },
    dialogPaper: {
      maxWidth: "100%",
      width: 900,
      maxHeight: "700px",
    },
    closeButton: {
      top: "8px",
      right: "8px",
      position: "absolute",
    },
  })
);

const ContactUs: FunctionComponent<ContactUsProps> = (props) => {
  const { page } = props;
  const classes = useStyle(); //TODO: ability to switch background color for markdown content
  const [selectedForm, setSelectedForm] = useState<any>("");
  const [contactForms, setContactForms] = useState<any[]>();
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [contactUsStaticPage, setContactUsStaticPage] = useState<any>();
  const staticPages = useContext(StaticPageContext);

  //grab contact us static content
  useEffect(() => {
    if (staticPages?.ready) {
      let contactPage = find(staticPages.data, (page: any) => {
        return page?.fields?.slug === "contact-us";
      });
      if (contactPage) setContactUsStaticPage(contactPage);
    }
  }, [staticPages.data, staticPages?.ready]);

  // grab all the different types of form iframes  // could potentiallly be a service [ {contactData}]
  useEffect(() => {
    let ready = true;
    if (ready) {
      contentful.getEntries({ content_type: "contactForm", limit: 20 }).then((response) => {
        setContactForms(response.items);
      });
    }
    return () => {
      ready = false;
    };
  }, []);

  // if contact info passed in grab / set corresponding form
  useEffect(() => {
    if (contactForms && page) {
      let result = find(contactForms, (contactInfo: any) => {
        return page.fields.sidebarContact?.fields?.name === contactInfo?.fields?.name;
      });
      if (result) {
        setSelectedForm(result);
        setDisplayForm(true);
      }
    }
  }, [contactForms, page]);

  // function to handle iframe selection and grab the corresponding contact form data.
  const handleSelectChange = (event: React.ChangeEvent<any>) => {
    setSelectedForm(event.target.value);
    setDisplayForm(true);
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      {!props.hideForm && (
        <Grid className={classes.formContainer} item sm={12} md={contactUsStaticPage && !props.formOnly ? 8 : 12}>
          {!props.formOnly && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="help-with-label">What Can We Help You With?</InputLabel>
              <Select
                label="What Can We Help You With?"
                labelId="help-with-label"
                id="help-with"
                value={selectedForm}
                onChange={handleSelectChange}
              >
                {contactForms &&
                  contactForms.map((info, index) => (
                    <MenuItem value={info} key={index}>
                      {info?.fields?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {displayForm && selectedForm?.fields?.formUrl && (
            <IframeResizer
              className={classes.iframe}
              heightCalculationMethod="lowestElement"
              src={selectedForm?.fields?.formUrl}
              style={{ width: "1px", minWidth: "100%", height: "600px" }}
              scrolling={true}
            />
          )}
        </Grid>
      )}

      {!props.formOnly && contactUsStaticPage && (
        <Grid item sm={props.hideForm ? 12 : 4}>
          <ReactMarkdown escapeHtml={false} plugins={[[gfm]]} children={contactUsStaticPage.fields.content} />
        </Grid>
      )}
    </Grid>
  );
};

interface ContactUsDialogProps {
  open: boolean;
  page?: Entry<any>;
  onClose: () => void;
}
export const ContactUsDialog: React.FunctionComponent<ContactUsDialogProps> = (props) => {
  const { open, onClose, page } = props;
  const classes = useStyle();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      PaperProps={{
        square: true,
      }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullScreen={isMobile}
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <ContactUs page={page} formOnly={true} />
      </DialogContent>
    </Dialog>
  );
};
export default ContactUs;
