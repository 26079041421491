import { Button, Container, Typography, makeStyles, createStyles, Theme } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
    item: {
      margin: theme.spacing(2),
    },
  })
);

const ProductNotFound: React.FunctionComponent = () => {
  const history = useHistory();
  const match = useRouteMatch<any>();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h1" color="primary" className={classes.item}>
        Whoops, We're Sorry!
      </Typography>
      <Typography variant="h3" className={classes.item}>
        {match.params?.productCode}
      </Typography>
      <Typography variant="h4" className={classes.item}>
        This product is currently out of stock or no longer available
      </Typography>
      <Button color="primary" variant="contained" onClick={() => history.push("/home")}>
        Continue Shopping
      </Button>
    </Container>
  );
};

export default ProductNotFound;
