import { BuyerProduct } from "ordercloud-javascript-sdk";
import React from "react";
import { Box, ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectorContainer: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(0.5, 1.5),
      cursor: "pointer",
    },
    selected: {
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0.5),
      backgroundColor: theme.palette.secondary.main,
      maxWidth: theme.spacing(3),
    },
    selectedIcon: {
      color: "white",
      position: "relative",
      fontSize: "1rem",
    },
    selectorText: {
      textAlign: "left",
      padding: theme.spacing(0.5, 0),
      marginRight: "auto",
      marginLeft: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        margin: "0 auto",
      },
    },
  })
);

export interface VariantSelectorProps {
  variant?: BuyerProduct;
  selected?: boolean;
  selectVariant?: (id: string) => void;
}

const VariantSelector: React.FunctionComponent<VariantSelectorProps> = (props) => {
  const { variant, selected, selectVariant } = props;
  const classes = useStyles();

  const handleSelectVariant = (sku?: string) => (e: React.MouseEvent) => {
    if (selectVariant && sku) {
      selectVariant(sku);
    }
  };

  return (
    <ButtonBase className={classes.selectorContainer} onClick={handleSelectVariant(variant?.ID)}>
      {selected && (
        <div className={classes.selected}>
          <CheckIcon className={classes.selectedIcon} />
        </div>
      )}
      <Box className={classes.selectorText}>{props.children}</Box>
    </ButtonBase>
  );
};

export default VariantSelector;
