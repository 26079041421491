import { Typography } from "@material-ui/core";

interface PasswordRequirementsProps {
  variant?: "body1" | "body2";
}

export const PasswordRequirementsList: React.FunctionComponent<PasswordRequirementsProps> = (props) => {
  return (
    <ul style={{ margin: 1 }}>
      <li>
        <Typography variant={props.variant || "body2"}>1 of the following special characters ! @ # $ %</Typography>{" "}
      </li>
      <li>
        <Typography variant={props.variant || "body2"}>1 Uppercase letter (A-Z)</Typography>
      </li>
      <li>
        <Typography variant={props.variant || "body2"}>1 Digit (0-9)</Typography>
      </li>
    </ul>
  );
};

export default PasswordRequirementsList;
