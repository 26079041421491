import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { createStyles, FormControl, makeStyles, Theme } from "@material-ui/core";
import Facet from "./Facet";
import { find, isNumber, startCase } from "lodash";
import algoliaService from "../../../services/algolia.service";

interface FacetListProps {
  facets: { [propName: string]: { [propName: string]: any } };
  facetFilters: string[];
  facetChangeCallback: (facet: string, checked: boolean) => void;
  expandedIndex?: number;
  searchQuery?: string;
  hideFacetCheckbox?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      display: "block",
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(0, 0, 0, 2),
    },
  })
);

const FacetList: FunctionComponent<FacetListProps> = (props) => {
  const classes = useStyle();
  const { facets, facetFilters, hideFacetCheckbox, facetChangeCallback, expandedIndex, searchQuery } = props;
  const [orderedFacetList, setOrderedFacetList] = useState<any>({});
  const [expandedIndices, setExpandedIndices] = React.useState<any>(isNumber(expandedIndex) ? [expandedIndex] : []);

  const getOrderedFacets = useCallback(() => {
    let facetList: any = {};
    algoliaService.facetsToDisplay.forEach((facetName, index) => {
      if (facets[facetName]) {
        let updatedFacetName = facetName.slice();
        if (facetName.includes("Attributes.")) {
          //remove attributes out of facet title
          updatedFacetName = facetName.replace("Attributes.", "");
        }
        facetList[startCase(updatedFacetName)] = facets[facetName];
      }
    });
    if (facets["IsWorkshop"]) {
      //map event facets so that its readable
      let mappedFacet = {
        "Ticketed Events": facets["IsWorkshop"]["true"] || "0",
        "Free Events": facets["IsWorkshop"]["false"] || "0",
      };
      facetList["Type"] = mappedFacet;
    }
    if (searchQuery) {
      const searchFacetList = facetList["Price Range"] ? { "Price Range": facetList["Price Range"] } : {};
      setOrderedFacetList(searchFacetList);
    } else {
      setOrderedFacetList(facetList);
    }
  }, [facets, searchQuery]);

  const handleExpandClick = useCallback(
    (selectedIndex: number) => {
      let updatedIndices = [...expandedIndices];
      // look through the array if the index exists- take it out, if it doesn't add it in
      let isAnExpandedIndex = find(updatedIndices, (openIndex) => openIndex === selectedIndex);
      if (isNumber(isAnExpandedIndex)) {
        updatedIndices = updatedIndices.filter((openIndex: any) => openIndex !== selectedIndex);
      } else {
        updatedIndices.push(selectedIndex);
      }
      setExpandedIndices(updatedIndices);
    },
    [expandedIndices]
  );

  useEffect(() => {
    if (facets) {
      getOrderedFacets();
    }
  }, [facets, getOrderedFacets]);

  const facetlistinfo = useMemo(() => {
    return Object.entries(orderedFacetList).map(([key, value], index) => (
      <FormControl key={index} className={classes.formControl}>
        <Facet
          key={index}
          index={index}
          expandedIndices={expandedIndices}
          title={key}
          facet={value}
          facetFilters={facetFilters}
          expandCallback={handleExpandClick}
          facetChangeCallback={facetChangeCallback}
          hideFacetCheckbox={hideFacetCheckbox}
        ></Facet>
      </FormControl>
    ));
  }, [
    orderedFacetList,
    classes,
    expandedIndices,
    facetFilters,
    hideFacetCheckbox,
    handleExpandClick,
    facetChangeCallback,
  ]);

  return <div className={classes.root}>{facetlistinfo}</div>;
};

export default FacetList;
