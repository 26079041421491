import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { LineItem } from "ordercloud-javascript-sdk";
import { createStyles, makeStyles } from "@material-ui/styles";
import { neutral } from "../../themes/colors";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/store-hook";
import { AppDispatch } from "../../redux/store";
import { patchLineItems } from "../../redux/slices/order";
import $ from "jquery";

interface SpecialProductRequestProps {
  lineItem: LineItem;
  enabled?: boolean;
  onCancel?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      backgroundColor: "transparent",
      marginTop: theme.spacing(-1),
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        flexWrap: "nowrap",
        gap: theme.spacing(2),
      },
    },
    specialRequestButtonGroup: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
    buttonCancelorDelete: {
      color: theme.palette.text.secondary,
      width: "max-content",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    button: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
    },
    note: {
      color: neutral.text_light_bg,
      flexGrow: 1,
      fontStyle: "italic",
    },
    input: {
      "& .MuiInputBase-input": {
        backgroundColor: neutral.search_bg,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[300],
      },
    },
  })
);

const SpecialProductRequest: React.FunctionComponent<SpecialProductRequestProps> = (props) => {
  const { lineItem, enabled, onCancel } = props;
  const currentOrder = useAppSelector((state) => state.order);
  const [request, setRequest] = useState<string>();
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setRequest(lineItem.xp.ProductNote);
    setLoading(false);
  }, [lineItem]);

  useEffect(() => {
    if (enabled) {
      $(`#SpecialRequestInput_${lineItem.ID}`).get(0)!.focus();
    }
  }, [enabled, lineItem.ID]);

  const onSaveOrEdit = async () => {
    if (!(enabled || editing)) {
      setEditing(true);
    } else {
      await handleUpdate({
        xp: { ProductNote: request },
      });
    }
  };

  const cancelOrDelete = async () => {
    if (enabled || editing) {
      setRequest(lineItem.xp?.ProductNote);
      setEditing(false);
      if (onCancel) onCancel();
    } else {
      await handleUpdate({
        xp: { ProductNote: null },
      });
    }
  };

  const handleUpdate = async (partial: Partial<LineItem>) => {
    if (currentOrder.order?.ID && lineItem.ID) {
      setLoading(true);
      await dispatch(patchLineItems({ lineItemIDs: [lineItem.ID], partial: partial }));
      setEditing(false);
      if (onCancel) onCancel();
    }
  };

  return (
    <Box display="flex" className={classes.root}>
      {enabled || editing ? (
        <TextField
          variant="outlined"
          title="SpecialRequestInput"
          className={classes.input}
          label="Special Request"
          placeholder="Enter style/color notes and our designers will do their best to accommodate your request."
          value={request}
          onChange={(e: any) => setRequest(e.target.value)}
          fullWidth
          inputProps={{ id: `SpecialRequestInput_${lineItem.ID}`, maxLength: 100 }}
          margin="dense"
          disabled={!(enabled || editing) || loading}
        >
          {request}
        </TextField>
      ) : (
        <Typography variant="body2" className={classes.note}>
          "{lineItem.xp?.ProductNote}"
        </Typography>
      )}
      <div className={classes.specialRequestButtonGroup}>
        <Button
          className={classes.button}
          variant="text"
          color="primary"
          disabled={loading || !request}
          onClick={onSaveOrEdit}
        >
          {enabled || editing ? "Save" : "Edit"}
        </Button>
        <Button
          className={`${classes.button} ${classes.buttonCancelorDelete}`}
          variant="text"
          disabled={loading}
          onClick={cancelOrDelete}
        >
          {enabled || editing ? "Cancel" : "Delete Note"}
        </Button>
      </div>
    </Box>
  );
};

export default SpecialProductRequest;
