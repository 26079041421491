import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as OrderCloud from "ordercloud-javascript-sdk";
import appConstants from "../../constants/app.constants";

export interface SeoSettings {
  seo: {
    MetaTitle: string;
    MetaDescription: string;
  };
}

export interface SeoPages {
  "Care, Advice and Info": SeoSettings;
  "Store Locator": SeoSettings;
  "Event Calendar": SeoSettings;
}

export interface BuyerXpDeliveryDates {
  [date: string]: BuyerXpDeliveryRun;
}

export type BuyerXpDeliveryRunKey = "AM" | "PM" | "NO PREF";

export interface BuyerXpDeliveryRun {
  AM: BuyerXpDeliveryRunPreference;
  PM: BuyerXpDeliveryRunPreference;
  "NO PREF": BuyerXpDeliveryRunPreference;
}

export interface BuyerXpDeliveryRunPreference {
  Name: string;
  TimeSlot: string;
  charge: string;
  checkedRun: boolean;
  Surcharge: string;
}

export interface BuyerXpShippers {
  LocalDelivery: {
    StandardDeliveryFees: string;
    SameDayDeliveryFees: string;
    SpecialDeliverySameDayFees: string;
    Funeral_ChurchFees: string;
    SameDayDelivery: boolean;
    SameDayDeliveryCountDownTimer: string;
    DeliveryMessage: string;
    NextDayMessage: string;
    FuneralFees: string;
    ChurchFees: string;
    CemeteryFees: string;
    SameDayDeliveryUpCharge: string;
    NextDayAMDeliveryCutoff: string;
  };
  InStorePickUp: {
    CurbsideCharges: string;
  };
  UPS: {
    UPSCharges: string;
    StandardUPSCharges: string;
  };
  DirectShip: {
    StandardDelivery: string;
  };
  Courier: {
    OMS: string;
  };
  USPS: {
    USPSCharges: string;
  };
  Faster: {
    StandardDelivery: string;
    FirstRule: string;
    SecondRule: string;
  };
  Handling: {
    Quantity: string;
  };
}

export interface GiftCardSettingsI {
  ThresholdSetting: {
    GiftcardThresholdLimit: number;
    EGiftcardThresholdLimit: number;
  };
  PurchaseSetting: {
    MaxPurchaseAmt: number;
    MinPurchaseAmt: number;
  };
}

export interface BuyerXp {
  Shippers: BuyerXpShippers;
  DeliveryDatesN: BuyerXpDeliveryDates;
  DeliveryRuns: BuyerXpDeliveryRun[];
  GiftCardSettings: GiftCardSettingsI;
  SEOPages: SeoPages;
  StoreServices: string[];
  BachmansPicks: string[];
  FeaturedEvents: string[];
  wireOrder: WireOrderData;
}

interface WireOrderData {
  IncomingOrders: WiredIncoming;
  OutgoingOrders: WiredOutgoing;
}
interface WiredIncoming {
  WiredDeliveryFees: string;
  Destinations: any[];
}

interface WiredOutgoing {
  WiredServiceFees: number;
  WiredDeliveryFees: string;
  Destinations: any[];
}

export interface RedirectsShape {
  [originalUrl: string]: string;
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

class BachmansMiddleware {
  private session: AxiosInstance;
  constructor() {
    this.session = axios.create({
      baseURL: `${appConstants.bachmansIntegrationsUrl}/api`,
    });

    // append auth token to all outgoing requests
    this.session.interceptors.request.use((config) => {
      const token = OrderCloud.Tokens.GetAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  public getBuyerXp() {
    const url = appConstants.blobStorageUrl as string;
    return axios.get<BuyerXp>(`${url}/buyerxp.json`, {
      params: { guid: guid() },
    });
  }

  public saveBuyerXp(update: BuyerXp) {
    return this.put("/webdata/buyerxp", update);
  }

  public getSitemap() {
    const url = appConstants.blobStorageUrl as string;
    return axios.get(`${url}/sitemap.xml`, {
      params: { guid: guid() },
    });
  }

  public getRedirects() {
    const url = appConstants.blobStorageUrl as string;
    return axios.get<RedirectsShape>(`${url}/redirects.json`, {
      params: { guid: guid() },
    });
  }

  public removeRedirect(key: string) {
    const url = appConstants.bachmansIntegrationsUrl as string;
    return this.session.delete(`${url}/api/redirects`, {
      params: { slug: key },
    });
  }

  public addRedirect(newRedirect: any) {
    const url = appConstants.bachmansIntegrationsUrl as string;
    return this.session.put(`${url}/api/redirects`, newRedirect);
  }

  public get(url: string, config?: AxiosRequestConfig) {
    return this.session.get(url, config).then((r) => r.data);
  }

  public post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.session.post(url, data, config).then((r) => r.data);
  }
  public put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.session.put(url, data, config).then((r) => r.data);
  }

  public patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.session.patch(url, data, config).then((r) => r.data);
  }

  public delete(url: string, config?: AxiosRequestConfig) {
    return this.session.delete(url, config).then((r) => r.data);
  }

  public refreshCategoryCache(catalogID: string) {
    const url = appConstants.bachmansIntegrationsUrl as string;
    return this.session.post(`${url}/api/catalogs/${catalogID}/refresh`);
  }
}

export default new BachmansMiddleware();
