import moment from "moment";
import { Errors } from "../constants/error.constants";
import bachmansIntegrationsService from "./bachmansIntegrations.service";

export interface PurplePerksAccount {
  Balance?: number;
  PointsEarned?: number;
  CardNumber?: string;
  LoyaltyID?: string;
  ExpirationDate?: string;
}

async function checkBalanceAsync(user: any): Promise<PurplePerksAccount> {
  try {
    if (!user?.xp?.LoyaltyID) console.error("No user.xp.LoyaltyID");
    let perks = await bachmansIntegrationsService.GetPurplePerks(user?.xp?.LoyaltyID);
    var date = new Date();
    var expirationDate = new Date(date.getFullYear(), 3 * Math.ceil((date.getMonth() + 1) / 3), 1);
    const usePerks = perks.data && perks.data.card_value !== Errors.giftCardNotAvailable && perks.data.card_value > 0;
    return {
      Balance: usePerks ? Number(perks.data.card_value) : 0,
      PointsEarned: usePerks ? perks.data.card_value : 0,
      CardNumber: "777777" + user?.xp?.LoyaltyID,
      LoyaltyID: user?.xp?.LoyaltyID,
      ExpirationDate: moment(expirationDate).format("MM/DD/yyyy"),
    };
  } catch (err) {
    throw console.error(err);
  }
}

const service = {
  CheckBalanceAsync: checkBalanceAsync,
};

export default service;
