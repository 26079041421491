import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { BachmansAddress } from "../../../models/address";
import { Address } from "ordercloud-javascript-sdk";

interface AddressProps {
  address: BachmansAddress | Address | undefined;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontStyle: "normal",
      marginTop: 4,
      "& > *": {
        lineHeight: 1.25,
      },
    },
  })
);

const AddressDisplay: FunctionComponent<AddressProps> = (props) => {
  const classes = useStyle();
  const { address } = props;
  return address ? (
    <address className={classes.root}>
      <Typography variant="subtitle2">{address.CompanyName}</Typography>
      <Typography variant="subtitle2">{address.Street1}</Typography>
      <Typography variant="subtitle2">
        {address.City}, {address.State} {address.Zip}
      </Typography>
      <Typography variant="subtitle2">{address.Phone}</Typography>
    </address>
  ) : null;
};

export default AddressDisplay;
