import { find } from "lodash";
import { Category } from "ordercloud-javascript-sdk";
import { BachmansProduct } from "../models/product";

const getDcfSetting = (product: BachmansProduct, setting: string, dcfCategories: Category[]) => {
  if (product && product.xp && product.xp[setting] && notEmptyArray(product.xp[setting])) {
    return product.xp[setting];
  } else {
    var fnln = findSetting(product?.xp?.CurrentFineline, setting, dcfCategories);
    if (fnln) {
      return fnln;
    }
    var cls = findSetting(product?.xp?.CurrentClass, setting, dcfCategories);
    if (cls) {
      return cls;
    }
    var dept = findSetting(product?.xp?.CurrentDepartment, setting, dcfCategories);
    if (dept) {
      return dept;
    }
    var d = findSetting("DCF", setting, dcfCategories);
    if (d) {
      return d;
    }
    return [];
  }
};

const notEmptyArray = (element: any) => {
  return !(Array.isArray(element) && element.length === 0);
};

const findSetting = (id: string, setting: string, dcfCatalog: any[]) => {
  var cat = find(dcfCatalog, function (c) {
    return c.ID === id;
  });
  if (cat && cat.xp && cat.xp[setting] && notEmptyArray(cat.xp[setting])) {
    return cat.xp[setting];
  }
};

const service = {
  GetDcfSetting: getDcfSetting,
};
export default service;
