import {
  BraintreePayPalButtons,
  BraintreePayPalButtonsComponentProps,
  OnApproveBraintreeData,
} from "@paypal/react-paypal-js";
import { Address } from "ordercloud-javascript-sdk";
import React, { FunctionComponent } from "react";
import { AddressXp } from "../../../models/address";
import {
  BraintreeAddress,
  BraintreeTokenizePayload,
} from "@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes";
import { Box } from "@material-ui/core";

interface BraintreeButtonWrapperProps {
  amount?: number | string | undefined;
  buttonStyle?: BraintreePayPalButtonsComponentProps["style"];
  shippingAddress?: Address<AddressXp> | undefined;
  disabled?: boolean;
  onApproveCallBack?: (
    data: OnApproveBraintreeData & {
      paymentSource?: "paypal" | "venmo";
    },
    payLoad: BraintreeTokenizePayload
  ) => void;
  fundingSource?: BraintreePayPalButtonsComponentProps["fundingSource"];
}

const BraintreeButtonWrapper: FunctionComponent<BraintreeButtonWrapperProps> = (props) => {
  const { amount, buttonStyle, shippingAddress, onApproveCallBack, fundingSource, disabled = false } = props;

  let shippingAddressOverride: BraintreeAddress | undefined = undefined;
  if (shippingAddress) {
    const { FirstName, LastName, Phone, Street1, Street2, City, Country, Zip, State } = shippingAddress;

    shippingAddressOverride = {
      recipientName: `${FirstName} ${LastName}`,
      line1: Street1,
      line2: Street2 || "",
      city: City || "",
      countryCode: Country || "US",
      postalCode: Zip || "",
      state: State || "",
      phone: Phone || "",
    };
  }

  return (
    <Box width="85%" margin="auto">
      <BraintreePayPalButtons
        onError={(err) => console.log(err, "on ERR")}
        onCancel={(data) => console.log(data, "on Cancel")}
        style={buttonStyle}
        disabled={disabled}
        fundingSource={fundingSource || "paypal"} // Available values are: ["paypal", "card", "credit", "paylater", "venmo"]
        forceReRender={[amount]}
        createOrder={function (data, actions) {
          return actions.braintree
            .createPayment({
              flow: "checkout",
              amount: amount,
              currency: "USD",
              intent: "authorize",
              enableShippingAddress: true,
              shippingAddressEditable: false,
              shippingAddressOverride: shippingAddressOverride,
            })
            .then((orderId) => {
              // code here after create the order
              console.log({ orderId, data });
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.braintree.tokenizePayment(data).then((payload) => {
            // code here after capture the order
            console.log({ data, payload }, "@@ on approve payload");
            onApproveCallBack && onApproveCallBack(data, payload);
          });
        }}
      />
    </Box>
  );
};

export default BraintreeButtonWrapper;
