import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router";
import { ChevronLeft } from "@material-ui/icons";
import { purple } from "../../themes/colors";
import { ComplexContentBlocksContext } from "../../providers/contentful";
import { ComplexContentModel } from "../../models/contentful/ComplexContent";
import { WorkingHours, BachmansAddress } from "../../models/address";
import StoreHours from "./StoreHours";
import InlineContentBlock from "../Shared/Content/InlineContentBlock";
import { find } from "lodash";
import { Entry } from "contentful";
import noImg from "../../assets/images/noimg.jpg";
import { Addresses } from "ordercloud-javascript-sdk";
import locationService from "../../services/locationService";
import Map from "./GoogleMaps";
import { Location } from "../StoreLocator/GoogleMaps";
import theme from "../../themes/main";
import DOMPurify from "dompurify";
import { DoubleOutlinedLinkBtn } from "../Shared/DoubleOutlinedBtn";
import BachmansCardMedia from "../Shared/BachmansCardMedia";

interface StoreDetailProps {}
interface StoreDetailMatch {
  storeId: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    backToStore: {
      color: purple.regular,
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.h6.fontSize,
      paddingLeft: 0,
      marginTop: theme.spacing(1.5),
    },

    title: {
      margin: theme.spacing(1, 0, 2.5, 0),
      fontSize: theme.typography.h3.fontSize,
    },
    addressTitle: {
      fontWeight: theme.typography.body1.fontWeight,
    },
    addressContainer: {
      display: "flex",
      "& > div": { flex: "1 0 0" },
    },
    getDirections: {
      color: purple.regular,
      paddingLeft: 0,
      fontSize: theme.typography.body2.fontSize,
    },
    storeHours: {
      margin: theme.spacing(1, 0),
    },
    serviceContainer: {
      padding: theme.spacing(0, 0, 0, 3),
    },
    image: {
      border: `solid 1px ${theme.palette.grey[300]}`,
    },
  })
);

const StoreDetail: FunctionComponent<StoreDetailProps> = (props) => {
  const classes = useStyle();
  const match = useRouteMatch<StoreDetailMatch>();
  const complexContent = useContext(ComplexContentBlocksContext);
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [store, setStore] = useState<BachmansAddress>();
  const [contentBlock, setContentBlock] = useState<any>();
  const [distance, setDistance] = useState<any>();
  const directionsUrl = locationService.DirectionsUrlPrefix;

  useEffect(() => {
    if (complexContent.ready && store?.xp?.Content) {
      let entryinfo: any = find(
        complexContent.data,
        (entry: Entry<ComplexContentModel>) => entry.sys.id === store.xp?.Content?.value[0]
      );

      if (entryinfo?.fields) setContentBlock(entryinfo.fields);
    }
  }, [store, complexContent]);

  useEffect(() => {
    (async function () {
      if (match.params.storeId && !store) {
        try {
          let selectedStore = await Addresses.Get("Bachmans", match.params.storeId);
          setStore(selectedStore);
          const defaultStore = await Addresses.Get("Bachmans", "Store-Lyndale-6010--612-861-7676");
          let currentPosition = await locationService.GetCurrentPosition({
            lat: defaultStore?.xp.Latitude,
            lng: defaultStore?.xp.Longitude,
          });
          setDistance(await locationService.GetDistance(currentPosition, selectedStore?.xp?.Location));
        } catch (ex) {
          console.log(ex); //TODO: better error handling
        }
      }
    })();
  }, [match.params.storeId, store]);

  const handleOnBackClick = useCallback(() => history.push("/store-locator"), [history]);

  return store ? (
    <Container>
      <Button className={classes.backToStore} onClick={handleOnBackClick}>
        <ChevronLeft />
        <Typography variant="h5">Back to Store Locator</Typography>
      </Button>
      <Typography className={classes.title} variant="h1">
        {store.CompanyName}
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={12} md={3}>
          <div className={classes.addressContainer}>
            <div>
              <Typography variant="body2">{store.Street1}</Typography>
              <Typography variant="body2">
                {store.City}, {store.State} {store.Zip}
              </Typography>
              <Typography variant="body2">{store.Phone}</Typography>
            </div>
            {distance && <Typography variant="body2">{distance} mi</Typography>}
          </div>
          <Button
            className={classes.getDirections}
            target="_blank"
            href={`${directionsUrl + store?.xp?.PlaceID}&destination=${store.xp?.Location.lat},${
              store.xp?.Location.lng
            }`}
          >
            Get Directions
          </Button>

          {store.xp?.WorkingHrs.map((hrs: WorkingHours, index: number) => (
            <StoreHours key={index} variant="Working" workingHours={hrs} />
          ))}
          <StoreHours variant="Holiday" holidayHours={store.xp?.Holidays} />

          <Typography className={classes.addressTitle} variant="h6">
            PRODUCTS & SERVICES OFFERED
          </Typography>
          <ul className={classes.serviceContainer}>
            {store.xp?.ServiceOffered.map((service: string, index: number) => (
              <li key={index}>
                <Typography>{service}</Typography>
              </li>
            ))}
          </ul>
        </Grid>

        <Grid item sm={12} md={6}>
          {store && !isMobile && <Map stores={[store]} center={store?.xp?.Location as Location} zoomLevel={12} />}
          {contentBlock && (
            <Box margin={theme.spacing(1, 0)}>
              <InlineContentBlock inlineContent={contentBlock} />
            </Box>
          )}
        </Grid>
        <Grid item sm={12} md={3}>
          <BachmansCardMedia
            aspectRatio={"1.42"}
            alt={"Product Image For " + store.CompanyName}
            image={store?.xp?.Image?.url ? store?.xp?.Image?.url : noImg}
          />
          {store.xp?.AboutUs && (
            <Fragment>
              <Typography variant="h5">About {store.CompanyName}</Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(store.xp?.AboutUs),
                }}
              ></div>
            </Fragment>
          )}
          <DoubleOutlinedLinkBtn
            href="/corporate/employment"
            target="_blank"
            buttonText="View Employment Opportunities"
            buttonProps={{
              variant: "outlined",
            }}
            styleProps={{
              padding: theme.spacing(1, 3),
              margin: theme.spacing(2, 0),
            }}
          />
        </Grid>
      </Grid>
      {/* TODO:// Update Button to route to employment */}
    </Container>
  ) : (
    <Container>
      <Button className={classes.backToStore} onClick={handleOnBackClick}>
        <ChevronLeft />
        <Typography variant="h5">Back to Store Locator</Typography>
      </Button>
    </Container>
  );
};

export default StoreDetail;
