import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Container,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { neutral } from "../../../themes/colors";
import SocialLinks from "../SocialLinks";
import contentfulService from "../../../services/contentful.service";
import CareAdviceList from "./CareAdviceList";
import { Pagination } from "@material-ui/lab";
import Sortby from "../../Shared/SortBy";
import { StaticPageQueryParams } from "..";
import queryString from "querystring";
import { useHistory } from "react-router-dom";
import ArticleDetail from "../../Articles/ArticleDetail";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import { toNumber } from "lodash";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
let careGuideStaticData: any;
let careGuideStaticBanner: any;
let careGuideSEO: any;
let careGuideTitle: any;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchComponent: {
      marginBottom: theme.spacing(2),
      gap: theme.spacing(2),
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.up("sm")]: {
        flexWrap: "nowrap",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: theme.spacing(3),
      },
    },
    searchInput: {
      flex: "1 1 100%",
      margin: 0,
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        width: 300,
      },
      "& .MuiInputBase-root": {
        minHeight: 44,
      },
      "& div": {
        backgroundColor: neutral.search_bg,
        width: "100%",
      },
    },
    link: {
      cursor: "pointer",
    },
    search: {
      background: neutral.search_bg,
      flex: "1 1 auto",
      maxWidth: "100%",
      [theme.breakpoints.up("md")]: {
        backgroundColor: neutral.grey_background,
        minWidth: 300,
        flexGrow: 0,
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: 500,
      },
    },
    onSaleGridItem: {
      display: "flex",
    },
    CareAdviceWrapper: {
      margin: "15px -12px",
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      maxWidth: "unset",
    },
    jumbotron: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      alignItems: "flex-end",
      gap: theme.spacing(2),
      height: "20vh",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(1.5),
      "& div": {
        flexShrink: 0,
        alignSelf: "flex-end",
      },
    },
    jumbotronTitle: {
      color: neutral.offwhite_bg,
      textShadow: ".5px .5px 2px rgba(0,0,0,.85)",
      fontWeight: 500,
      display: "flex",
      flexFlow: "row wrap",
      alignSelf: "flex-start",
      fontSize: "clamp(.75em, 5vw, 32px)",
    },
    introWrapper: {
      paddingLeft: "20px",
      margin: "10px 0px",
      "& h2": {
        margin: "0px",
        fontSize: 18,
        fontFamily:
          "Roboto Slab, -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
      },
      "& p": {
        fontFamily:
          "Roboto Slab, -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
        fontWeight: "light",
        fontSize: 18,
        opacity: "0.4",
        margin: "0px",
      },
    },
  })
);

export interface CareAdviceProps {
  queryParams?: StaticPageQueryParams;
  updateParams?: (newParams: StaticPageQueryParams) => void;
  pageSlug?: string;
}
const CareAdvice: React.FunctionComponent<CareAdviceProps> = (props) => {
  const { queryParams, updateParams, pageSlug } = props;
  const classes = useStyles();
  const [total, setTotal] = useState<number>();
  const [searchText, setSearchText] = useState<string>();
  const [contentDisplayList, setContentDisplayList] = useState<any[]>([]); // list of tags and their contentful data to display
  const [sortState, setSortState] = useState<string>("Title A-Z");
  const history = useHistory();
  const pageSize = 200;

  useEffect(() => {
    contentfulService.getEntries({ content_type: "tagContentDisplay", limit: 20 }).then((response) => {
      setContentDisplayList(response.items);
    });
    contentfulService.getEntries({ content_type: "careguide" }).then((response) => {
      careGuideStaticBanner = response?.items[0]?.fields.bannerImage.fields.file.url;
      careGuideStaticData = response?.items[0]?.fields.description;
      careGuideSEO = response?.items[0]?.fields;
      careGuideTitle = response?.items[0]?.fields?.title;
    });
  }, []);

  useEffect(() => {
    handleSearchClear();
  }, [queryParams?.tag]);

  const handleSearchChange = (event: React.ChangeEvent<any>) => {
    setSearchText(event.target.value);
  };

  const updateTotal = useCallback((total?: number) => {
    setTotal(total);
  }, []);

  const callUpdateParams = (params: StaticPageQueryParams) => {
    if (updateParams) {
      updateParams(params);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
    callUpdateParams({
      ...queryParams,
      page: value,
    });
    window.scrollTo(0, 0);
  };

  const triggerSearch = () => {
    callUpdateParams({
      ...queryParams,
      search: searchText,
      page: 1,
      contenttype: "article",
    });
    setSortState("Search Results");
  };

  const handleSearchClear = () => {
    setSortState("Title A-Z"); //BI-456
    setSearchText("");
    if (queryParams?.search && queryParams.search !== "") {
      callUpdateParams({
        ...queryParams,
        search: undefined,
      });
    }
  };
  // triggers upon searching when no text or upon Clear search icon
  const deleteSearch = () => {
    setSortState("Title A-Z"); //BI-456
    setSearchText("");
    callUpdateParams({});
  };

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === "Enter" && (!searchText || searchText.trim().length == 0)) {
      // handleSearchClear();
      deleteSearch();
    }
    if (e.key === "Enter" && searchText && searchText?.trim().length >= 3) {
      triggerSearch();
    }
  };

  const handleArticleSelect = useCallback(
    (slug?: string) => {
      let tagQueryParam = queryParams?.tag;
      let route: any = { pathname: `/information/resource-hub${slug ? `/${slug}` : ""}` };
      if (tagQueryParam) {
        route.search = queryString.stringify({ tag: tagQueryParam } as any);
      }
      history.push(route);
    },
    [history, queryParams]
  );

  return pageSlug ? (
    <ArticleDetail articleSlug={pageSlug} tag={queryParams?.tag} backToList={() => handleArticleSelect()} />
  ) : (
    <Fragment>
      <Box
        marginBottom={2}
        className={`bannerBox resourceHub ${classes.jumbotron}`}
        style={{ backgroundImage: `url("${careGuideStaticBanner}")` }}
      >
        {/* {careGuideSEO && <SocialLinks seoInfo={careGuideSEO?.seo} />} */}

        <Typography variant="h1" className={`bannerTitle ${classes.jumbotronTitle}`}>
          {queryParams?.tag && queryParams?.tag !== undefined
            ? careGuideTitle + " | " + queryParams?.tag
            : careGuideTitle}
        </Typography>
      </Box>
      <Box className={classes.searchComponent} display="flex" justifyContent="flex-end">
        <TextField
          className={classes.searchInput}
          variant="outlined"
          margin="dense"
          placeholder="Search Bachman’s Resource Hub"
          onChange={handleSearchChange}
          value={searchText}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: searchText && (
              <IconButton onClick={deleteSearch}>
                <Close color="action" fontSize="small" />
              </IconButton>
            ),
          }}
        ></TextField>
        <DoubleOutlinedBtn
          buttonText="Search"
          buttonProps={{ onClick: triggerSearch }}
          styleProps={{
            padding: "4px 40px",
            minHeight: 40,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography color="primary" variant="h4">
          {total !== undefined
            ? queryParams?.search
              ? `Showing ${total} Results for ${queryParams.search}`
              : `${total} Results`
            : ""}
        </Typography>
        <Sortby
          currentSortState={sortState}
          handleSelection={(option: any) => {
            if (option.title !== sortState) {
              setSortState(option.title);
            }
            return;
          }}
          sortOptions={[
            { title: "Title A-Z", index: "0" },
            { title: "Title Z-A", index: "1" },
            { title: "Newest to Oldest", index: "2" },
            { title: "Oldest to Newest", index: "3" },
            { title: "Search Results", index: "4" },
          ]}
        ></Sortby>
      </Box>
      <Box className={classes.introWrapper}>
        <ReactMarkdown escapeHtml={false} plugins={[gfm]} children={careGuideStaticData} />
      </Box>
      <Grid item xs={12} sm={12} className={classes.CareAdviceWrapper}>
        <CareAdviceList
          queryParams={queryParams}
          pageSize={pageSize}
          updateTotal={updateTotal}
          contentList={contentDisplayList}
          sortState={sortState}
          articleSelect={handleArticleSelect}
        />
      </Grid>{" "}
      {/* TODO: update pagination display to match sketch - for all pagination? */}
      {Math.ceil((total || 0) / pageSize) > 1 && (
        <Box display="flex" justifyContent="center" my={3}>
          <Pagination
            count={Math.ceil((total || 0) / pageSize)}
            page={toNumber(queryParams?.page) || 1}
            onChange={handlePageChange}
          ></Pagination>
        </Box>
      )}
    </Fragment>
  );
};

export default CareAdvice;
