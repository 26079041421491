import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const CreditCardMaskInput = (props: any) => {
  // https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
  const { inputRef, ...other } = props;

  const getMask = () => {
    if (!props?.value)
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "\u2000",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "\u2000",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "\u2000",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    if (props?.value) {
      const firstChar = props?.value?.charAt(0)!;
      if (firstChar === "3") {
        return [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "\u2000",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "\u2000",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      } else {
        return [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "\u2000",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "\u2000",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "\u2000",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }
    }
  };

  return (
    <MaskedInput
      maxLength={20}
      guide={false}
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={getMask()}
      showMask
    />
  );
};

CreditCardMaskInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CreditCardMaskInput;
