import {
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";

interface LinkProps extends MaterialLinkProps {
  to: string;
}

const Link: FunctionComponent<LinkProps> = (props) => {
  return <MaterialLink {...props} component={RouterLink}></MaterialLink>;
};

export default Link;
