import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

interface BachmansLoadingProps {
  text?: string;
}

const BachmansLoading: React.FunctionComponent<BachmansLoadingProps> = (props) => {
  return (
    <Box style={{ gap: 24 }} display="flex" justifyContent="center" alignItems="center" minHeight="40vh">
      <CircularProgress />
      <Typography display="inline" variant="caption">
        {props.text || "Loading..."}
      </Typography>
    </Box>
  );
};

export default BachmansLoading;
