import React, { Fragment } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import Link from "../Shared/Link";
import { neutral } from "../../themes/colors";
import { CategoryProps } from "./CategoryFeaturedContent";
import { useContext } from "react";
import { StaticPageContext } from "../../providers/contentful";
import { useMemo } from "react";
import { Entry } from "contentful";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    relatedServices: {
      flexBasis: "100%",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        flexBasis: "30%",
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        textAlign: "left",
        padding: theme.spacing(0, 2),
      },
    },
    categoryDescription: {
      color: neutral.text_colored_bg,
      fontWeight: theme.typography.fontWeightRegular,
      flexBasis: "100%",
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 3, 0, 0),
        flexBasis: "70%",
      },
    },
    relatedServiceHeader: {
      marginBottom: theme.spacing(1),
    },
    relatedServiceLinks: {
      flexWrap: "wrap",
    },
    link: {
      margin: theme.spacing(0, 2),
    },
    seoAccordion: {
      margin: theme.spacing(2, 0),
      boxShadow: "none",
    },
  })
);

const DescriptionRelatedServices: React.FunctionComponent<CategoryProps> = (props) => {
  const { category } = props;
  const staticPages = useContext(StaticPageContext);
  const classes = useStyles();

  const relatedServices = useMemo(() => {
    if (staticPages && staticPages.ready) {
      return staticPages.data?.filter((page) => category?.xp?.Content?.services?.value?.includes(page.sys.id));
    }
  }, [staticPages, category]);

  const categoryDescription: string = useMemo(() => {
    return (category?.Description || "") + (category?.xp?.DescriptionOverflow || "");
  }, [category?.Description, category?.xp?.DescriptionOverflow]);

  return (
    <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" mt={4}>
      {categoryDescription && categoryDescription.length > 30 ? (
        <Accordion className={classes.seoAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="small" color="disabled" />}
            aria-controls="panel1a-content-seo"
            id="panel1a-header-seo"
          >
            <Typography component="em" color="textSecondary" variant="body1">
              {`Read more about ${category?.Name}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="caption"
              className={classes.categoryDescription}
              dangerouslySetInnerHTML={{
                __html: categoryDescription,
              }}
            ></Typography>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography variant="caption" className={classes.categoryDescription}>
          {categoryDescription}
        </Typography>
      )}
      {relatedServices && relatedServices.length > 0 && (
        <div className={classes.relatedServices}>
          <Typography variant="h6" className={classes.relatedServiceHeader}>
            Related Services
          </Typography>
          <Box>
            {relatedServices?.map((service: Entry<any>, i) => (
              <Fragment key={i}>
                <Link to={service?.fields?.link}>{service?.fields?.title}</Link>
                {i !== relatedServices.length - 1 && <span className={classes.link}>|</span>}
              </Fragment>
            ))}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default DescriptionRelatedServices;
