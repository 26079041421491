import React, { FunctionComponent } from "react";
import { Container, createStyles, Drawer, IconButton, makeStyles, Theme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { neutral } from "../../../themes/colors";

interface MobileFiltersProps {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > .MuiDrawer-paperAnchorBottom": {
        height: "100%",
      },
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        position: "sticky",
        top: 0,
        backgroundColor: "#fff",
        zIndex: 1,
      },
    },
    closeButton: {
      color: neutral.text_white_bg,
    },
  })
);

const MobileFilters: FunctionComponent<MobileFiltersProps> = (props) => {
  const classes = useStyle();
  const { menuOpen, toggleMenu, children } = props;
  return (
    <Drawer className={classes.root} anchor={"bottom"} open={menuOpen} onClose={toggleMenu}>
      <Container maxWidth="lg">
        <div className={classes.buttonWrapper}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleMenu}>
            <Close fontSize={"default"} />
          </IconButton>
        </div>
        {children}
      </Container>
    </Drawer>
  );
};

export default MobileFilters;
