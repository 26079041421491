export interface Destination {
  type: string;
  label: string;
  validMethods: ValidMethodType;
  search: "G" | "O" | "S";
  searchLabel: string;
  searchHelp?: string;
  errorText?: string;
  manualEntry?: boolean;
  filters?: any;
  hasPopover?: boolean;
  popoverTemplate?: string;
  defaultRecipientType?: string;
  options?: any;
  googleOptions?: GoogleOptions;
}

interface GoogleOptions {
  types?: string[];
  country?: string[];
}

type ValidMethodType = "*" | string[];

export const defaultTypes: Destination[] = [
  {
    type: "Residence",
    label: "Residence",
    validMethods: "*",
    search: "G",
    searchLabel: "Address 1",
    errorText: "Address 1 is required",
    googleOptions: {
      types: ["address"],
      country: ["US"],
    },
  },
  {
    type: "Hospital",
    label: "Hospital",
    validMethods: "*",
    search: "G",
    searchLabel: "Enter Hospital Name",
    errorText: "Hospital name is required",
    searchHelp: "Search the hospitals Bachman's can deliver to",
    manualEntry: true,
    filters: {
      ID: "Hospitals-*",
      "xp.Active": true,
    },
    options: ["Staff", "Patient"],
    googleOptions: {
      types: ["establishment"],
      country: ["US"],
    },
    hasPopover: true,
    popoverTemplate: "common/services/recipients/templates/hospital.popover.tpl.html",
    defaultRecipientType: "Staff",
  },
  {
    type: "Church",
    label: "Church",
    validMethods: "*",
    search: "G",
    searchLabel: "Enter Church Name",
    errorText: "Church name is required",
    searchHelp: "Search the churches Bachman's can deliver to",
    manualEntry: true,
    filters: {
      ID: "Church-*",
      "xp.Active": true,
    },
    options: ["Staff", "In Memory Of"],
    googleOptions: {
      types: ["establishment"],
    },
    hasPopover: true,
    popoverTemplate: "common/services/recipients/templates/church.popover.tpl.html",
    defaultRecipientType: "Staff",
  },
  {
    type: "FuneralHome",
    label: "Funeral Home",
    validMethods: "*",
    search: "G",
    searchLabel: "Enter Funeral Home Name",
    errorText: "Funeral Home name is required",
    searchHelp: "Search the funeral homes Bachman's can deliver to",
    manualEntry: true,
    filters: {
      ID: "FuneralHome-*",
      "xp.Active": true,
    },
    googleOptions: {
      types: ["establishment"],
    },
    defaultRecipientType: "InMemoryOf",
  },
  {
    type: "Cemetery",
    label: "Cemetery",
    validMethods: "*",
    search: "G",
    searchLabel: "Enter Cemetery Name",
    errorText: "Cemetery name is required",
    searchHelp: "Search the cemeteries Bachman's can deliver to",
    manualEntry: true,
    filters: {
      ID: "Cemetery-*",
      "xp.Active": true,
    },
    googleOptions: {
      types: ["establishment"],
    },
    defaultRecipientType: "InMemoryOf",
  },
  {
    type: "School",
    label: "School",
    validMethods: "*",
    search: "G",
    errorText: "School name is required",
    searchLabel: "Enter School Name",
    googleOptions: {
      types: ["establishment"],
      country: ["US"],
    },
  },
  {
    type: "Business",
    label: "Business",
    validMethods: "*",
    search: "G",
    searchLabel: "Enter Business Name",
    errorText: "Business name is required",
    googleOptions: {
      types: ["establishment"],
      country: ["US"],
    },
  },
  {
    type: "InStorePickUp",
    label: "In-Store or Curbside Pickup",
    validMethods: ["InStorePickUp", "CurbsidePickUp"],
    searchLabel: "Select a Store",
    search: "S",
    searchHelp: "Select the Bachman's store where you will pick up your order",
    hasPopover: true,
    popoverTemplate: "common/services/recipients/templates/store.popover.tpl.html",
    filters: {
      ID: "Store-*",
      "xp.StoreType": "Flagship",
      "xp.WillCall": true,
      "xp.Active": true,
    },
  },
];
