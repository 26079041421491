import Case from "case";
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { CatalogContext } from "../../providers/catalog";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { AppDispatch } from "../../redux/store";
import CategoryList from "../Category/CategoryList";
import NotFound from "../Shared/NotFound";
import ProductList from "../Product/ProductList/ProductList";
import categoryService from "../../services/category.service";
import BachmansLoading from "../Shared/BachmansLoading";
import { Categories, Category } from "ordercloud-javascript-sdk";
import seoService from "../../services/seo-service";

export interface CatalogRouteMatch {
  partOne: string;
  partTwo?: string;
}

const buildCategoryIdFromParts = (parts: string[]): string => {
  return parts.map(Case.pascal).join("_");
};

const Catalog: FunctionComponent = () => {
  const { categories } = useContext(CatalogContext);
  const dispatch = useDispatch<AppDispatch>();
  const match = useRouteMatch<CatalogRouteMatch>();
  const [currentCategory, setCurrentCategory] = useState<Category<any>>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const categoryId = useMemo(() => {
    if (match.params.partTwo) {
      return buildCategoryIdFromParts([match.params.partOne, match.params.partTwo]);
    } else {
      return buildCategoryIdFromParts([match.params.partOne]);
    }
  }, [match]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (categories) {
        let currentCat =
          location.search.length > 0
            ? categories.find((c) => c.ID === categoryId)
            : await Categories.Get("Bachmans", categoryId);

        if (!currentCat) {
          try {
            currentCat = await Categories.Get("Bachmans", categoryId);
          } finally {
            setLoading(false);
          }
        }
        if (currentCat) {
          setCurrentCategory(currentCat);
          setLoading(false);
          seoService.ImportIntoSeoDataProps(currentCat, "category");
        }
      }
    })();
  }, [categoryId, categories, location]);

  const siblings = useMemo(() => {
    if (categories && currentCategory && currentCategory.ParentID) {
      return categories.filter((c) => c.ParentID === currentCategory.ParentID);
    }
  }, [categories, currentCategory]);

  const children = useMemo(() => {
    if (categories) {
      return categories.filter((c) => c.ParentID === categoryId);
    }
  }, [categoryId, categories]);

  useEffect(() => {
    if (categories && currentCategory && currentCategory.ParentID) {
      const parent = categories.find((c) => c.ID === currentCategory.ParentID);
      if (parent) {
        dispatch(
          setBreadcrumbs({
            visible: true,
            links: [
              {
                path: categoryService.BuildCategoryUrlFromId(parent.ID, "c"),
                label: parent.Name,
              },
            ],
            current: currentCategory.Name,
          })
        );
        return;
      }
    }
    if (currentCategory) {
      dispatch(
        setBreadcrumbs({
          visible: true,
          links: [],
          current: currentCategory.Name,
          dark: true,
        })
      );
    }
  }, [categories, currentCategory, dispatch]);

  return loading ? (
    // <Box minHeight="40vh" width="100%" display="flex" alignItems="center">
    <BachmansLoading text="Loading..." />
  ) : currentCategory ? (
    match.params.partTwo ? (
      //PRODUCT LIST
      <div>
        <ProductList
          category={currentCategory}
          children={children ? children : []}
          siblings={siblings ? siblings : []}
        />
      </div>
    ) : (
      //CATEGORY LIST
      <CategoryList children={children} category={currentCategory}></CategoryList>
    )
  ) : (
    <NotFound />
  );
};

export default Catalog;
