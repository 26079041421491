import { Box, createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { SessionContext } from "../../providers/session";
import UserInputForm, { UserInputFields } from "../Shared/UserInputForm";
import PurplePerks from "./PurplePerks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomMargin: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        maxWidth: "80%",
      },
    },
    cancelButton: {
      margin: "2px",
      background: "white",
      border: "1px solid black",
    },
    saveButton: {
      margin: "3px",
      border: `1px solid ${theme.palette.primary.main}`,
    },
    editText: {
      marginLeft: "5px",
      cursor: "pointer",
    },
    userInfo: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(2),
      },
    },
  })
);

const Profile: React.FunctionComponent = () => {
  const classes = useStyles();
  const { user } = useContext(SessionContext);
  const [editingField, setEditingField] = useState<UserInputFields>();

  useEffect(() => {
    (async () => {
      setEditingField(undefined);
    })();
  }, [user]);

  const handleSetEdit = (item: UserInputFields) => (event: React.MouseEvent) => {
    setEditingField(item);
  };

  const onCancel = () => setEditingField(undefined);

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.userInfo}>
          <div className={classes.bottomMargin}>
            <Typography variant="overline" color="textSecondary">
              Name
            </Typography>
            {editingField === "FirstName" ? (
              ""
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  {user?.FirstName + " " + user?.LastName}
                  <Link id="name" className={classes.editText} onClick={handleSetEdit("FirstName")}>
                    {"(edit)"}
                  </Link>
                </Typography>
              </Box>
            )}
            {editingField === "FirstName" && (
              <UserInputForm
                type="update"
                initialUser={user}
                buttonPosition="right"
                fields={["FirstName", "LastName"]}
                onCancel={onCancel}
              ></UserInputForm>
            )}
          </div>
          <div className={classes.bottomMargin}>
            <Typography variant="overline" color="textSecondary">
              Email Address
            </Typography>
            {editingField === "Email" ? (
              ""
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  {user?.Email}
                  <Link className={classes.editText} onClick={handleSetEdit("Email")}>
                    {"(edit)"}
                  </Link>
                </Typography>
              </Box>
            )}
            {editingField === "Email" && (
              <UserInputForm
                type="update"
                initialUser={user}
                buttonPosition="right"
                fields={["Email", "CurrentPassword"]}
                onCancel={onCancel}
              ></UserInputForm>
            )}
          </div>
          <div className={classes.bottomMargin}>
            <Typography variant="overline" color="textSecondary">
              Password
            </Typography>
            {editingField === "Password" ? (
              ""
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body1">•••••••••••</Typography>
                <Typography variant="body1">
                  {user?.Password}
                  <Link className={classes.editText} onClick={handleSetEdit("Password")}>
                    {"(edit)"}
                  </Link>
                </Typography>
              </Box>
            )}
            {editingField === "Password" && (
              <UserInputForm
                type="update"
                initialUser={user}
                fields={["Password", "CurrentPassword"]}
                buttonPosition="right"
                onCancel={onCancel}
              ></UserInputForm>
            )}
          </div>
          <div className={classes.bottomMargin}>
            <Typography variant="overline" color="textSecondary">
              Phone Number
            </Typography>
            {editingField === "Phone" ? (
              ""
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  {user?.Phone}
                  <Link className={classes.editText} onClick={handleSetEdit("Phone")}>
                    {"(edit)"}
                  </Link>
                </Typography>
              </Box>
            )}
            {editingField === "Phone" && (
              <UserInputForm
                type="update"
                initialUser={user}
                fields={["Phone"]}
                buttonPosition="right"
                onCancel={onCancel}
              ></UserInputForm>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <PurplePerks />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Profile;
