import { alpha, createStyles, CardContent, makeStyles, Typography, Card, CardActions, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { ComplexContentModel } from "../../../models/contentful/ComplexContent";
import theme from "../../../themes/main";
import { DoubleOutlinedLinkBtn } from "../DoubleOutlinedBtn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderRadius: "0px",
      border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
      height: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    topLineText: {
      fontStyle: "italic",
      marginBottom: "5px",
    },
    headerText: {
      marginBottom: "15px",
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    cardAction: {
      padding: theme.spacing(2),
    },
  })
);
export interface InlineContentBlockProps {
  inlineContent?: ComplexContentModel;
}

const InlineContentBlock: FunctionComponent<InlineContentBlockProps> = (props) => {
  const classes = useStyles();
  const { inlineContent } = props;

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.cardContent}>
        <Typography variant="body1" color="primary" className={classes.topLineText}>
          {inlineContent?.superTitle}
        </Typography>
        <Typography variant="h2" color="primary" className={classes.headerText}>
          {inlineContent?.title}
        </Typography>
        <Typography variant="body2">{inlineContent?.subTitle}</Typography>
      </CardContent>
      <CardActions className={classes.cardAction}>
        <DoubleOutlinedLinkBtn
          href={inlineContent?.linkUrl ? inlineContent.linkUrl : ""}
          target="_blank"
          buttonText={inlineContent?.linkText ? inlineContent.linkText : "Learn More"}
          buttonProps={{
            variant: "outlined",
          }}
          styleProps={{
            padding: theme.spacing(0.5, 2),
          }}
        />
      </CardActions>
    </Card>
  );
};

export default InlineContentBlock;
