export const purple = {
  hover: "#824DAC",
  regular: "#9262B8",
  price: "#9574C1",
  utility: "#AA82C8",
  inline: "#E1D5EC",
  light: "#e2d6ed",
};

export const green = {
  regular: "#91A339",
  hover: "#7A8B29",
  special: "#AFD833",
  promo: "#F0F8DA",
};

export const red = {
  regular: "#FF0000",
};

export const neutral = {
  text_colored_bg: "#323232",
  text_light_bg: "#999999",
  text_white_bg: "#666666",
  grey_background: "#ededed",
  strikethrough: "#A9A9A9",
  disabled: "#D4D4D4",
  text_utility_bg: "#F1F1F1",
  offwhite_bg: "#FBFBFB",
  search_bg: "#f7f7f7",
};
