import { Box, Card, CardContent, createStyles, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { Entry } from "contentful";
import react, { useState } from "react";
import { useMemo } from "react";
import BachmansCardMedia from "../../Shared/BachmansCardMedia";
import { DoubleOutlinedLinkBtn } from "../../Shared/DoubleOutlinedBtn";
import { DynamicElement } from ".";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface OnSaleItemProps {
  item?: Entry<DynamicElement>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column nowrap",
      borderRadius: "0",
      border: 0,
      transition: "box-shadow .5s, transform .5s",
      width: "100%",
      "&:hover, &:active, &:focus": {
        boxShadow: theme.shadows[1],
        transform: "translateY(-2px)",
      },
      cursor: "pointer",
    },
    Disclaimer: {
      fontSize: 8,
      marginBottom: theme.spacing(1),
    },
    toggleDisclaimer: {
      fontWeight: theme.typography.fontWeightMedium,
      marginLeft: theme.spacing(1),
      fontSize: 8,
    },
    CardContent: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(1),
      flexGrow: 1,
    },
  })
);

const OnSaleItem: react.FunctionComponent<OnSaleItemProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const classes = useStyles();
  const { item } = props;
  const theme = useTheme();

  const truncatedDisclaimer = useMemo(() => {
    return item?.fields?.disclaimer && item?.fields?.disclaimer?.length > 100
      ? item?.fields?.disclaimer?.substring(0, 100) + "..."
      : item?.fields.disclaimer;
  }, [item?.fields?.disclaimer]);

  const disclaimerStyle: any = useMemo(() => {
    let defaultStyle: CSSProperties = {
      marginBottom: theme.spacing(1),
    };
    if (item?.fields?.style?.disclaimer) {
      defaultStyle.color = item?.fields?.style?.disclaimer?.color;
      defaultStyle["fontFamily"] = item?.fields?.style?.disclaimer?.font;
      defaultStyle.fontSize = item.fields?.style?.disclaimer?.size ? item.fields?.style?.disclaimer?.size + "em" : 8;
    }
    return defaultStyle;
  }, [item?.fields?.style?.disclaimer, theme]);

  const subTextStyle = useMemo(() => {
    let defaultStyle: any = {};
    if (item?.fields?.style?.subtext) {
      defaultStyle.color = item?.fields?.style?.subtext?.color;
      defaultStyle["fontFamily"] = item?.fields?.style?.subtext?.font;
      defaultStyle.fontSize = item.fields?.style?.subtext?.size + "em";
    }
    return defaultStyle;
  }, [item?.fields?.style?.subtext]);

  const titleStyle = useMemo(() => {
    let defaultStyle: any = {};
    if (item?.fields?.style?.title) {
      defaultStyle.color = item?.fields?.style?.title?.color || "primary";
      defaultStyle["font-family"] = item?.fields?.style?.title?.font;
      defaultStyle.fontSize = item.fields?.style?.title?.size + "em";
    }
    return defaultStyle;
  }, [item?.fields?.style?.title]);

  const buttonColor = useMemo(() => {
    //options from contentful are "" (purple), "default" (white), "success" (green), "warning" (orange), "red" (red)
    let color = theme.palette.primary.main;
    if (item?.fields?.style?.button) {
      if (item?.fields?.style?.button.color === "default") {
        color = "white";
      } else if (item?.fields?.style?.button.color === "success") {
        color = theme.palette.secondary.main;
      } else if (item?.fields?.style?.button.color === "warning") {
        color = "orange";
      } else if (item?.fields?.style?.button.color === "red") {
        color = "red";
      }
    }
    return color;
  }, [item?.fields?.style?.button, theme.palette.primary.main, theme.palette.secondary.main]);

  return (
    <Card className={classes.root}>
      <BachmansCardMedia
        aspectRatio="1.42"
        image={item?.fields?.backgroundImage?.fields?.file?.url}
        alt={item?.fields?.title}
      />
      <CardContent className={classes.CardContent}>
        <Typography variant="h2" style={titleStyle} gutterBottom>
          {item?.fields?.title}
        </Typography>
        <Typography variant="body1" style={subTextStyle} gutterBottom>
          {item?.fields?.subtext}
        </Typography>
        {item?.fields?.disclaimer && (
          <Box display="flex">
            <Typography variant="body2" style={disclaimerStyle}>
              {`* ${expanded ? item?.fields?.disclaimer : truncatedDisclaimer}`}
              {truncatedDisclaimer && truncatedDisclaimer?.length < item?.fields?.disclaimer?.length && (
                <Typography
                  display="inline"
                  color="primary"
                  className={classes.toggleDisclaimer}
                  onClick={() => setExpanded(!expanded)}
                >
                  See {expanded ? "Less" : "More"}
                </Typography>
              )}
            </Typography>
          </Box>
        )}
        {item?.fields?.link && (
          <DoubleOutlinedLinkBtn
            href={item?.fields?.link}
            buttonText={item?.fields?.buttonText || "Shop Now"}
            buttonProps={{
              variant: "contained",
            }}
            styleProps={{
              backgroundColor: buttonColor,
              width: "100%",
              marginTop: "auto",
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default OnSaleItem;
