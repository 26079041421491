import { createStyles, makeStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import { Category } from "ordercloud-javascript-sdk";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { green, neutral, purple } from "../../themes/colors";
import { SimpleContentBlocksContext } from "../../providers/contentful";
import { SimpleContentModel } from "../../models/contentful/SimpleContent";
import contentfulService from "../../services/contentful.service";
import { DoubleOutlinedBtn } from "./DoubleOutlinedBtn";
interface BannerPromotionProps {
  text?: string;
  url?: string;
  category?: Category; //if category - add L1 category
  variant: "categories" | "productlist" | "home"; //page banner is displayed
}

const useStyles = (variant?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      banner: {
        transform: variant === "productlist" ? "" : `translateY(${theme.spacing(-4.5)}px)`,
        width: "100%",
        backgroundColor: green.promo,
        padding: theme.spacing(1, 1.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },
      },
    })
  );

const BannerPromotion: FunctionComponent<BannerPromotionProps> = (props) => {
  const { category, variant } = props;
  const classes = useStyles(variant)();
  const simpleBlockContent = useContext(SimpleContentBlocksContext);
  const theme = useTheme();
  const [bannerContent, setBannerContent] = useState<SimpleContentModel>();

  useEffect(() => {
    var banners = contentfulService.getSimpleContentFromIDs(category?.xp?.Content?.banner?.value, simpleBlockContent);
    if (banners && banners.length) {
      setBannerContent(banners[0]);
    }
  }, [category, simpleBlockContent]);

  const getThemeColor = (color: string) => {
    let style = "";
    switch (color) {
      case "Default":
        style = neutral.text_white_bg;
        break;
      case "Purple":
        style = purple.regular;
        break;
      case "Green":
        style = green.promo;
        break;
      case "White":
        style = neutral.offwhite_bg;
        break;
      default:
        style = neutral.text_utility_bg;
    }
    return style;
  };

  //TODO: Update variant response
  const getVariant: any = (variant: any) => {
    let variantStyle = "h1";

    switch (variant) {
      case "Header 01":
        variantStyle = "h1";
        break;
      case "Header 02":
        variantStyle = "h2";
        break;
      case "Header 03":
        variantStyle = "h3";
        break;
      case "Header 04":
        variantStyle = "h4";
        break;
      case "Header 05":
        variantStyle = "h5";
        break;
      case "Header 06":
        variantStyle = "h6";
        break;
      case "Title 01":
        variantStyle = "overline";
        break;
      case "Title 02":
        variantStyle = "subtitle1";
        break;
      case "Title 03":
        variantStyle = "body2";
        break;
      case "Title 04":
        variantStyle = "subtitle2";
        break;
      case "Body Copy":
        variantStyle = "body1";
        break;
      case "SEO Copy":
        variantStyle = "overline";
        break;
      default:
        variantStyle = "h1";
    }
    return variantStyle;
  };

  return bannerContent ? (
    <Paper
      square
      className={classes.banner}
      style={{
        backgroundColor: getThemeColor(bannerContent.backgroundColor),
        borderStyle: bannerContent.borderColor ? "solid" : "none",
        borderColor: getThemeColor(bannerContent.borderColor),
      }}
    >
      <Typography
        variant={getVariant(bannerContent.line1Variant)}
        align="center"
        style={{
          marginLeft: "8px",
          marginRight: "8px",
          color: getThemeColor(bannerContent.line1Color),
        }}
      >
        {bannerContent?.line1Text}
      </Typography>
      {bannerContent.linkText && bannerContent.linkUrl && (
        <DoubleOutlinedBtn
          buttonText={bannerContent.linkText}
          buttonProps={{
            variant: "outlined",
            color: "secondary",
            size: "small",
            onClick: (event) => (window.location.href = `${bannerContent.linkUrl}`),
          }}
          styleProps={{
            padding: theme.spacing(0.5, 3),
            marginTop: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
              marginTop: "unset",
              marginLeft: theme.spacing(2),
            },
          }}
        />
      )}
    </Paper>
  ) : null;
};

export default BannerPromotion;
