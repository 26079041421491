import {
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import PurplePerksFull from "../Shared/PurplePerksFull";
import { useEffect } from "react";
import { isUndefined } from "lodash";

interface AuthDialogProps {
  open: boolean;
  onClose: () => void;
  state?: AuthDialogTab;
}

export type AuthDialogTab = "login" | "register" | "forgot";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      maxWidth: "100%",
      width: 950,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    container: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        height: "50vh",
        minHeight: 500,
        transition: "height .25s cubic-bezier(0.4, 0, 0.2, 1)",
      },
    },
    containerRegister: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        height: "60vh",
        minHeight: 500,
        transition: "height .25s cubic-bezier(0.4, 0, 0.2, 1)",
      },
    },
    formContainer: {
      backgroundColor: theme.palette.background.default,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 8),
      },
      "& .MuiDialogContent-root": {
        flex: "0 0 auto",
      },
    },
  })
);

const AuthDialog: FunctionComponent<AuthDialogProps> = ({ open, onClose, state }) => {
  const classes = useStyles();
  const [tab, setTab] = useState<AuthDialogTab>(state ? state : "login");
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [mustResetPassword, setMustResetPassword] = useState<boolean>(false);

  useEffect(() => {
    if (state) {
      setTab(state);
    }
  }, [state]);

  const changeTab = (newTab: AuthDialogTab, resetPassword?: boolean) => {
    if (!isUndefined(resetPassword)) {
      setMustResetPassword(resetPassword);
    }
    setTab(newTab);
  };

  const handleTabChange = (newTab: AuthDialogTab) => (e: React.MouseEvent) => {
    e.preventDefault();
    setTab(newTab);
  };

  const handleClose = () => {
    setMustResetPassword(false);
    setTab("login");
    onClose();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      PaperProps={{
        square: true,
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullScreen={isMobile}
      aria-labelledby="form-dialog-title"
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <Close />
      </IconButton>

      <Grid container className={tab === "register" ? classes.containerRegister : classes.container}>
        <Grid item sm className={classes.formContainer}>
          {tab === "login" && <Login onTabChange={handleTabChange} onClose={handleClose} changeTab={changeTab} />}
          {tab === "register" && <Register onTabChange={handleTabChange} onClose={handleClose} />}
          {tab === "forgot" && <ForgotPassword onTabChange={handleTabChange} mustResetPassword={mustResetPassword} />}
        </Grid>
        <Hidden smDown>
          <Grid item sm={5}>
            <DialogContent>
              <PurplePerksFull isAuthenticating={true} />
            </DialogContent>
          </Grid>
        </Hidden>
      </Grid>
    </Dialog>
  );
};

export default AuthDialog;
