import React, { FunctionComponent, useMemo } from "react";
import { Button, createStyles, List, ListItem, ListItemText, makeStyles, Theme } from "@material-ui/core";
import { purple } from "../../../../themes/colors";
import { KeyboardArrowRightSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

interface article {
  title: string;
  id: string;
  slug: string;
}
interface RelatedArticlesProps {
  articles: article[];
  categoryId: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      color: purple.utility,
    },
    articleItem: {
      margin: 0,
      padding: theme.spacing(0, 2),
    },
    articleTitle: {},
    ButtonViewAll: {
      flex: "none",
      color: theme.palette.primary.main,
      paddingLeft: 0,
      paddingRight: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);

const RelatedArticles: FunctionComponent<RelatedArticlesProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyle();
  const { className, articles, categoryId } = props;
  const history = useHistory();

  const articleLinks = useMemo(() => {
    if (articles.length > 0) {
      return articles.map((article: article, index: number) => (
        <List dense disablePadding key={index}>
          <ListItem classes={{ root: classes.articleItem }} button>
            <ListItemText
              id={article.title.replace(" ", "-")}
              className={classes.articleTitle}
              primary={article.title}
              primaryTypographyProps={{ variant: "body2" }}
              onClick={() => {
                if (article?.slug) {
                  history.push(`/information/resource-hub/${article.slug}`);
                  return;
                }
                handleViewAllArticles();
              }}
            />
          </ListItem>
        </List>
      ));
    } else {
      return null;
    }
  }, [articles, classes.articleTitle, classes.articleItem]);

  const handleViewAllArticles = () => {
    history.push(`/information/resource-hub?catID=${categoryId}`);
  };
  //TODO: Link to related Articles
  //TODO: link to ALL related Articles
  return (
    <div className={className}>
      <List dense>
        <ListItem button>
          <ListItemText
            className={classes.title}
            primary={"Related Articles"}
            primaryTypographyProps={{ variant: "h5" }}
          ></ListItemText>
        </ListItem>
        {articleLinks}
        <ListItem button>
          <Button
            disableRipple
            disableFocusRipple
            variant="text"
            className={classes.ButtonViewAll}
            onClick={handleViewAllArticles}
            endIcon={<KeyboardArrowRightSharp color="primary" fontSize="small"></KeyboardArrowRightSharp>}
          >
            View All Articles
          </Button>
        </ListItem>
      </List>
    </div>
  );
};

export default RelatedArticles;
