import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { Close } from "@material-ui/icons";
import { CheckoutError } from ".";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      maxWidth: "100%",
      width: 600,
      padding: theme.spacing(3, 0),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
);

interface CheckoutErrorDialogProps {
  open: boolean;
  onClose: () => void;
  message?: CheckoutError;
}

const CheckoutErrorDialog: React.FunctionComponent<CheckoutErrorDialogProps> = (props) => {
  const { open, message, onClose } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const history = useHistory();

  const handleAccept = () => {
    if (message?.route) {
      history.push(message?.route);
    }
    onClose();
  };

  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{ square: true }}
      open={open}
      maxWidth="lg"
      fullScreen={isMobile}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <div style={{ marginTop: "16px", marginBottom: "16px", textAlign: "center" }}>
          <Typography variant="h4">{message?.line1 || "An error occured."}</Typography>
          <Typography variant="h4">{message?.line2}</Typography>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "16px" }}>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutErrorDialog;
