import { Box, createStyles, IconButton, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Person } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { Fragment, useContext, useEffect, useRef, useState, FunctionComponent } from "react";
import AccountMenu from "./AccountMenu";
import { SessionContext } from "../../../providers/session";

import MobileAccountMenu from "./MobileAccountMenu";

interface UserProps {
  onAuthDialogOpen: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
    },
  })
);

const User: FunctionComponent<UserProps> = (props) => {
  const classes = useStyles();
  const { onAuthDialogOpen } = props;
  const containerRef = useRef<any>();
  const [anchorRef, setAnchorRef] = useState<any>();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [accountMobileMenuOpen, setAccountMobileMenuOpen] = useState(false);
  const { user, anonymous } = useContext(SessionContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const toggleAccountMobileMenu = () => {
    setAccountMobileMenuOpen(!accountMobileMenuOpen);
  };

  const toggleAccountMenu = () => {
    setAccountMenuOpen((state) => !state);
  };

  useEffect(() => {
    if (containerRef.current) {
      setAnchorRef(containerRef.current);
    }
  }, [containerRef]);

  return (
    <Fragment>
      {isMobile ? (
        <IconButton onClick={anonymous ? onAuthDialogOpen : toggleAccountMobileMenu} ref={containerRef}>
          <Person color="action" />
        </IconButton>
      ) : (
        <Fragment>
          <div ref={containerRef} className={classes.root} onClick={anonymous ? onAuthDialogOpen : toggleAccountMenu}>
            <Box display="flex" alignItems="end">
              <Box>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: -5 }}>
                  Hello
                </Typography>
                <Typography variant="h4" color="primary">
                  {anonymous ? (
                    "Login or Sign Up"
                  ) : user ? (
                    <Fragment>
                      <span>{`${user.FirstName} ${user.LastName}`}</span>
                    </Fragment>
                  ) : (
                    <Skeleton width={200} />
                  )}
                </Typography>
              </Box>
              {!anonymous && (
                <Box alignSelf="flex-end" display="flex">
                  {accountMenuOpen ? <ArrowDropUp color="primary" /> : <ArrowDropDown color="primary" />}
                </Box>
              )}
            </Box>
            <AccountMenu anchorEl={anchorRef} open={accountMenuOpen} />
          </div>
        </Fragment>
      )}
      <MobileAccountMenu menuOpen={accountMobileMenuOpen} toggleMenu={toggleAccountMobileMenu} />
    </Fragment>
  );
};
export default User;
