import React, { Fragment, FunctionComponent, useContext, useMemo } from "react";
import { createStyles, List, ListItem, makeStyles, Theme } from "@material-ui/core";
import { Category } from "ordercloud-javascript-sdk";
import { neutral, purple } from "../../../../themes/colors";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../../../providers/catalog";
import categoryService from "../../../../services/category.service";

interface MegaMenuSubCategoryProps {
  subcategory: Category;
  onItemClick: any;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0, 2, 0, 4),
    },
    listHeader: {
      padding: 0,
    },
    subCatTitle: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: purple.utility,
      textDecoration: "none",
      "&:hover": {
        color: purple.regular,
      },
    },
    subCatListItem: {
      padding: "4px 0",
      "& a": {
        color: neutral.text_white_bg,
        fontSize: theme.typography.body1.fontSize,
        textDecoration: "none",
        "&:hover": {
          color: neutral.strikethrough,
        },
      },
    },
  })
);

const MegaMenuSubCategory: FunctionComponent<MegaMenuSubCategoryProps> = (props) => {
  const { categories } = useContext(CatalogContext);
  const { subcategory, onItemClick } = props;
  const classes = useStyle();

  const subCategoryChildren = useMemo(() => {
    if (subcategory && categories) {
      let subCategoryChildren = categories.filter((c: Category) => c.ParentID === subcategory.ID);

      //TODO: link to second level category page with query params.
      return subCategoryChildren.map((cat: Category, index: number) => (
        <ListItem key={index} classes={{ root: classes.subCatListItem }} onClick={onItemClick}>
          <Link to={`${categoryService.BuildCategoryUrlFromId(cat.ID!, "c")}`}>{cat.Name}</Link>
        </ListItem>
      ));
    }
  }, [subcategory, categories, classes.subCatListItem, onItemClick]);
  return (
    <Fragment>
      <List
        subheader={
          <li className={classes.listHeader}>
            <Link
              className={classes.subCatTitle}
              to={categoryService.BuildCategoryUrlFromId(subcategory.ID!, "c", subcategory.Description!)}
            >
              {subcategory.Name}
            </Link>
          </li>
        }
      >
        {subCategoryChildren}
      </List>
    </Fragment>
  );
};

export default MegaMenuSubCategory;
