import {
  Button,
  Divider,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { Me } from "ordercloud-javascript-sdk";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import bachDateTime from "../../../services/bachDateTime.service";
import { SessionContext } from "../../../providers/session";
import AuthDialog from "../../Authentication/AuthDialog";

export interface CheckoutProps {
  onNextStep: () => void;
  setAcknowledgeAnonymous: (val: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orDivider: {
      flexGrow: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        "& hr": {
          width: "100%",
        },
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: 60,
        height: "auto",
        alignSelf: "stretch",
      },
      "& .MuiTypography-root": {
        position: "absolute",
        zIndex: 1,
        left: "calc(50vw - 60px)",
        right: 0,
        backgroundColor: "white",
        textAlign: "center",
        maxWidth: 60,
        [theme.breakpoints.up("sm")]: {
          left: 0,
        },
      },
    },
    loginForm: {
      marginBlock: theme.spacing(2),
    },
    checkoutAsGuest: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const CheckoutLogin: React.FunctionComponent<CheckoutProps> = (props) => {
  const { onNextStep, setAcknowledgeAnonymous } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login, cookiePrefix } = useContext(SessionContext);
  const [cookies, removeCookie] = useCookies();
  const [authDialogOpen, setAuthDialogOpen] = useState<boolean>(false);
  const [loginCount, setLoginCount] = useState(0);
  const [loginError, setloginError] = useState<boolean>(false);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (login) {
      // setLoading(true);
      //TODO: transfer anon order
      try {
        setloginError(false);
        await login(email, password, false);
        if (cookies[`${cookiePrefix}.loginfailed`]) {
          removeCookie(`${cookiePrefix}.loginfailed`, cookies[`${cookiePrefix}.loginfailed`]);
        }
        await Me.Patch({ xp: { LastLoginTimeStamp: bachDateTime.Today().format("YYYY-MM-DD") } });
        // setLoading(false);
        onNextStep();
      } catch (err: any) {
        setloginError(true);
        setLoginCount(loginCount + 1);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm item>
        <Typography variant="h4">Registered Customers</Typography>
        <form className={classes.loginForm} noValidate autoComplete="off" onSubmit={handleLogin}>
          <TextField
            margin="dense"
            autoFocus
            variant="outlined"
            id="emailRegistered"
            name="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            variant="outlined"
            id="password"
            name="password"
            label="Password"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            helperText={
              loginError && (
                <Typography variant="body2" color="error">
                  Invalid username or password.
                </Typography>
              )
            }
          />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button variant="text" size="small" color="primary" onClick={() => setAuthDialogOpen(true)}>
              Reset password
            </Button>
          </Box>
          <DoubleOutlinedBtn
            buttonText="Log In"
            buttonProps={{
              type: "submit",
              onClick: handleLogin,
              disabled: !password || !email,
            }}
            styleProps={{
              margin: "16px 0",
              padding: "4px 48px",
              width: isMobile && "100%",
            }}
          />
        </form>
      </Grid>
      <Grid item xs={12} sm className={classes.orDivider}>
        <Typography variant="h2">or</Typography>
        <Divider orientation={!isMobile ? "vertical" : "horizontal"} />
      </Grid>
      <Grid item xs={12} sm className={classes.checkoutAsGuest}>
        <DoubleOutlinedBtn
          buttonText="Continue As Guest"
          buttonProps={{
            variant: "outlined",
            type: "submit",
            size: "small",
            onClick: (e) => {
              e.stopPropagation();
              setAcknowledgeAnonymous(true);
              onNextStep();
            },
          }}
          styleProps={{
            margin: "16px 0",
            padding: "4px 40px",
            width: isMobile ? "100%" : "55%",
          }}
        />
      </Grid>
      <AuthDialog open={authDialogOpen} onClose={() => setAuthDialogOpen(false)} state="forgot" />
    </Grid>
  );
};

export default CheckoutLogin;
