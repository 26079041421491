export interface DeliveryMap {
  [key: string]: any;
}
export const deliveryTextMap: DeliveryMap = {
  LocalDelivery: {
    text: "Delivery",
    fee: "Delivery Fees",
    description: "Twin Cities Delivery",
  },
  Email: {
    text: "Delivery",
    fee: "",
    description: "Email Delivery",
  },
  UPS: {
    text: "Delivery",
    fee: "Delivery Fees",
    description: "UPS Shipping",
  },
  USPS: {
    text: "Delivery",
    fee: "Delivery Fees",
    description: "USPS Shipping",
  },
  Wired: {
    text: "Delivery",
    fee: "Delivery Fees",
    description: "Partner Florist Delivery",
  },
  TFE: {
    text: "Delivery",
    fee: "Delivery Fees",
    description: "Partner Florist Delivery",
  },
  FTD: {
    text: "Delivery",
    fee: "Delivery Fees",
    description: "Partner Florist Delivery",
  },
  InStorePickUp: {
    text: "Pickup",
    fee: "Picking Fee",
    description: "In-Store Pickup",
  },
  CurbsidePickUp: {
    text: "Pickup",
    fee: "Picking Fee",
    description: "Curbside Pickup",
  },
};
