import moment, { Moment } from "moment-timezone";
import { BuyerXp, DeliveryTypes } from "../models/buyerXp";
import Holidays from "date-holidays";
import { HolidayMatch } from "../constants/Holidays";

export const dateFormat = "MM-DD-YYYY";

var hd: any;

const getCST = () => moment().tz("America/Chicago");
const getUTC = (date: Date) => moment(moment.utc(date).format(dateFormat), dateFormat);

const getLocalTimeStamp = () => {
  return today().valueOf();
};

const today = () => moment(getCST().startOf("d"));

const tomorrow = () => moment(getCST().startOf("d").add(1, "d"));
/**
 *'MMM d, y' (e.g. September 3, 2010)
 */
const getMediumDate = (date: string) => {
  if (!date) return;
  return moment(date).utc().format("LL");
};

const sameDayCutoff = (buyerXp: BuyerXp) => {
  var sameDayCutoff = buyerXp.Shippers.LocalDelivery.SameDayDeliveryCountDownTimer.split(":");
  var sameDay = getCST();
  sameDay.startOf("day").add(sameDayCutoff[0], "hour").add(sameDayCutoff[1], "minutes");
  return sameDay;
};

const sameDayEnabled = (buyerXp: BuyerXp, method?: DeliveryTypes): boolean => {
  if (method === "Courier") return true;
  var cutoff = sameDayCutoff(buyerXp);
  var cst = getCST();
  return !cutoff.isSameOrBefore(cst) && buyerXp.Shippers.LocalDelivery.SameDayDelivery;
};

const countDown = (buyerXp: BuyerXp) => {
  var cutoff = sameDayCutoff(buyerXp);
  var cst = getCST();
  var timeLeftSuffix = " Left For<br>Same-Day Delivery";
  if (!sameDayEnabled(buyerXp)) {
    return false;
  } else {
    if (cutoff.diff(cst, "hours")) return moment.utc(cutoff.diff(cst)).format("h:mm") + timeLeftSuffix;
    return moment.utc(cutoff.diff(cst)).format("0:mm") + timeLeftSuffix;
  }
};

const initHolidays = () => {
  hd = new Holidays("US");
};

const isHoliday = (date: Moment, holidayArray: string[]) => {
  if (!hd) initHolidays();
  var thisHoliday = hd.isHoliday(date);
  const holidayObj = Array.isArray(thisHoliday) ? thisHoliday[0] : thisHoliday;
  if (holidayObj && holidayObj.name) {
    var thisHolidayName = getMyHolidays([holidayObj.name]);
    var allHolidays = getMyHolidays(holidayArray);
    return thisHolidayName[0] && allHolidays?.includes(thisHolidayName[0]);
  } else {
    return false;
  }
};

const getMyHolidays = (holidayArray: string[]) => {
  const myHolidays = holidayArray?.map((holiday) => {
    return Object.keys(HolidayMatch).find((h) => {
      const holidayNoPunct = holiday.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "").toLowerCase();
      const holidayList = HolidayMatch[h];
      return holidayList.includes(holidayNoPunct);
    });
  });
  return myHolidays;
};

const isSameDay = (dayMoment: Date, dateNeeded: Date) => {
  var utcDateNeeded = getUTC(dateNeeded);
  var utcMoment = getUTC(dayMoment);
  return utcMoment.isSame(utcDateNeeded, "day");
};

const isAfterNextDayAMCutoff = (buyerXp: BuyerXp) => {
  let momentMins = getCST().minutes();
  let momentHours = getCST().hour();
  let updatedmomentMins = moment(momentMins, "m").format("mm");
  // let updatedmomentHours = moment(momentHours, "h").format("hh");  // converts into 12 hr format instead of 24
  let updatedmomentHours = moment(momentHours, "h").format("HH");
  let getCurrentTime = updatedmomentHours + ":" + updatedmomentMins;
  let getTomorrowsCutoffTime =
    buyerXp.Shippers.LocalDelivery.NextDayAMDeliveryCutoff.split(":")[0] +
    ":" +
    buyerXp.Shippers.LocalDelivery.NextDayAMDeliveryCutoff.split(":")[1];
  return getCurrentTime >= getTomorrowsCutoffTime;
};

const isBeforeMinDate = (dateNeeded: Date, minDateOption?: Date) => {
  var utcDateNeeded = getUTC(dateNeeded);
  return utcDateNeeded.isBefore(minDateOption);
};

const service = {
  GetCST: getCST,
  GetUTC: getUTC,
  GetLocalTimeStamp: getLocalTimeStamp,
  Today: today,
  Tomorrow: tomorrow,
  GetMediumDate: getMediumDate,
  IsHoliday: isHoliday,
  IsSameDay: isSameDay,
  IsAfterNextDayAMCutoff: isAfterNextDayAMCutoff,
  IsBeforeMinDate: isBeforeMinDate,
  SameDay: {
    SameDayCutoff: sameDayCutoff,
    SameDayEnabled: sameDayEnabled,
    CountDown: countDown,
  },
};

export default service;
