import React, { Fragment, FunctionComponent, useCallback, useState } from "react";
import {
  Container,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import MobileDrawer from "../../Shared/MobileDrawer";
import { ContentLinks, contentfulContentLinkAndTitle, contenfulStaticPageInfo } from "../Header/ContentLinksMenu";
import { isOfType } from "../../../models/generics";
import { ChevronLeft, ChevronRightSharp } from "@material-ui/icons";
import { useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import MobileMenuSubCategory from "../Header/MegaMenu/MobileMenuSubCategory";
import { StaticPage } from "../../../models/contentful/staticPage";
import { useEffect } from "react";
import { each } from "lodash";
import { neutral } from "../../../themes/colors";
interface MobileContentLinksMenuProps {
  contentLinks: ContentLinks[];
  open: boolean;
  toggleMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    menuItemHeading: {
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      color: neutral.text_white_bg,
      padding: 0,
    },
    title: {
      paddingTop: theme.spacing(1.5),
      fontSize: theme.typography.h5.fontSize,
    },
    containerDrawer: {
      padding: 0,
    },
    link: {},
    sectionIconButton: {},
    backToResources: {
      color: neutral.text_white_bg,
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiListItemText-root": {
        flex: "unset",
      },
    },
    categoryTitle: {
      width: "50%",
      flexGrow: 1,
      margin: "0",
      "& > .MuiTypography-h5": {
        fontSize: "19px",
        paddingLeft: theme.spacing(3),
      },
    },
  })
);

const MobileContentLinksMenu: FunctionComponent<MobileContentLinksMenuProps> = (props) => {
  const classes = useStyle();
  const { contentLinks, open, toggleMenu } = props;
  const [displaySubCat, setDisplaySubCat] = useState(false);
  const [selectedSubCats, setSelectedSubCats] = useState<any>();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [title, setTitle] = useState("Resources");
  let routeInfo = useRouteMatch<any>("/:section/:rootSlug/:branchSlug?/:leafSlug?");
  let params = routeInfo?.params;
  let history = useHistory();

  const handleHeaderClick = useCallback(
    (list: ContentLinks) => (e: React.MouseEvent) => {
      if (isOfType<contenfulStaticPageInfo>(list.Links[0], "fields")) {
        history.push(list?.Links[0]?.fields?.link);
      }
      if (isOfType<contentfulContentLinkAndTitle>(list.Links[0], "link")) {
        history.push(list?.Links[0]?.link);
      }
      toggleMenu();
    },
    [history, toggleMenu]
  );

  const handleSectionListSelect = useCallback((list: any) => {
    setDisplaySubCat(true);
    let sortedReferenceToolsArr: any[] = [];
    if (list.Title === "References & Tools") {
      list.Links.forEach((value: any, index: any) => {
        if (value.fields?.slug === "plant-tree-and-shrub-library") {
          sortedReferenceToolsArr.unshift(value);
        } else {
          sortedReferenceToolsArr.push(value);
        }
      });
      list.Links = sortedReferenceToolsArr;
    }
    setSelectedSubCats(list);
    setTitle(list.Title);
  }, []);

  const handleExpandClick = useCallback((selectedIndex: number) => {
    setExpandedIndex((currentState) => (currentState === selectedIndex ? -1 : selectedIndex));
  }, []);
  const handleResourceSelect = useCallback(
    (resource: StaticPage | contentfulContentLinkAndTitle) => () => {
      if (isOfType<StaticPage>(resource, "fields")) {
        if (!resource.fields?.link) {
          //assuming its a nested static page
          history.push(`${resource.fields?.parentPage?.fields?.link}/${resource.fields?.slug}`);
        } else {
          history.push(resource?.fields?.link || "");
        }
      }
      if (isOfType<contentfulContentLinkAndTitle>(resource, "link")) {
        // if (resource.title !== "All Care Guides") {
        //   history.push(window.location.pathname);
        // } else {
        //   history.push(window.location.pathname + "?tag=" + encodeURIComponent(resource.title));
        // }
        // resource.link = resource.link.replace("&", "%26");
        // resource.link = .stringify({
        // tag: newParams.tag,
        // });

        if (resource.title === "All Care Guides") {
          history.push(resource.link);
        } else {
          history.push(`/information/resource-hub?tag=` + encodeURIComponent(resource.title));
        }
      }
      toggleMenu();
    },
    [history, toggleMenu]
  );
  // check url and open the corresponding section in nav
  useEffect(() => {
    if (params?.section && contentLinks.length) {
      each(contentLinks, (pageInfo: ContentLinks) => {
        //if params is info - check if query param tag is there - then find pageinfo with type article / else do type static page.
        if (params?.section === "information" && params.rootSlug === "resource-hub") {
          if (pageInfo.Type === "article") {
            handleSectionListSelect(pageInfo);
            setTitle(pageInfo?.Title ? pageInfo.Title : "Resources");
          }
        } else if (isOfType<StaticPage>(pageInfo.Links[0], "fields")) {
          //look at first child
          if (params.section === pageInfo.Links[0].fields?.section) {
            handleSectionListSelect(pageInfo);
            setTitle(pageInfo?.Title ? pageInfo.Title : "Resources");
          }
        }
      });
    }
  }, [open, contentLinks, handleSectionListSelect, params?.rootSlug, params?.section]);
  const subCategoryList = useMemo(() => {
    return (
      <div>
        <List disablePadding>
          {/* go back to resources */}
          <ListItem
            button
            onClick={() => {
              setDisplaySubCat(false);
              setSelectedSubCats(null);
              setTitle("Resources");
            }}
            className={classes.backToResources}
          >
            <ChevronLeft color="action" />
            <ListItemText primary={"View All Resources"} primaryTypographyProps={{ variant: "subtitle1" }} />
          </ListItem>

          {selectedSubCats &&
            selectedSubCats.Links.map((pageLinkInfo: StaticPage | contentfulContentLinkAndTitle, index: number) => (
              <MobileMenuSubCategory
                key={index}
                resource={pageLinkInfo}
                resourceClickCallback={handleResourceSelect}
                expandedIndex={expandedIndex}
                expandCallback={handleExpandClick}
                index={index}
                selectedID={params?.branchSlug ? params.branchSlug : ""}
              />
            ))}
        </List>
      </div>
    );
  }, [selectedSubCats, classes.backToResources, expandedIndex, handleResourceSelect, handleExpandClick, params]);

  const resourceLinks = useMemo(() => {
    return (
      <List disablePadding>
        {contentLinks.map((list, index) => (
          <Fragment key={`header-${index}`}>
            <ListItem disableGutters button className={classes.menuItemHeading}>
              <ListItemText
                className={classes.categoryTitle}
                onClick={handleHeaderClick(list)}
                primary={list.Title}
                primaryTypographyProps={{ variant: "h5" }}
              ></ListItemText>

              <IconButton
                aria-label={list.Title}
                onClick={() => handleSectionListSelect(list)}
                className={classes.sectionIconButton}
              >
                <ChevronRightSharp fontSize="large" />
              </IconButton>
            </ListItem>
          </Fragment>
        ))}
      </List>
    );
  }, [
    contentLinks,
    handleSectionListSelect,
    handleHeaderClick,
    classes.categoryTitle,
    classes.menuItemHeading,
    classes.sectionIconButton,
  ]);

  return (
    <Fragment>
      <MobileDrawer menuOpen={open} toggleMenu={toggleMenu} menuTitle={title}>
        <Container className={classes.containerDrawer}>{displaySubCat ? subCategoryList : resourceLinks}</Container>
      </MobileDrawer>
    </Fragment>
  );
};

export default MobileContentLinksMenu;
