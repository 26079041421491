import { Container, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { Address, Addresses } from "ordercloud-javascript-sdk";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { AppDispatch } from "../../redux/store";
import Map, { Location } from "./GoogleMaps";
import { StoreList } from "./StoreList";
import StoreFilter, { SearchData } from "./StoreSearch";
import locationService from "../../services/locationService";
import bachmansIntegrationsService from "../../services/bachmansIntegrations.service";
import { intersection } from "lodash";
import deliveryResourceService from "../../services/delivery-resource.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
    },
    containerLeft: {
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(1),
        height: `calc(100vh - 276px)`,
        overflow: "auto",
      },
    },
    resultsLabel: {
      marginTop: theme.spacing(1),
      fontStyle: "italic",
    },
  })
);

export interface StoreData {
  bachmansStores?: Address[];
  filteredStores?: Address[];
  searchedStores?: Address[];
}

const StoreLocator: React.FunctionComponent = () => {
  const classes = useStyles();
  const [storeData, setStoreData] = useState<StoreData>();
  const [userLocation, setUserLocation] = useState<Location>();
  const [currentPosition, setCurrentPosition] = useState<Location>();
  const [mapCenter, setMapCenter] = useState<Location>();
  const [storesInView, setStoresInView] = useState<Address[]>();
  const [services, setServies] = useState<string[]>();
  const [zoom, setZoom] = useState<number>(10);
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        current: "Store Locator",
        dark: false,
        visible: true,
      })
    );
  }, [dispatch]);

  const onStoreSelect = (location: Location, id: string) => {
    setMapCenter(location);
    const container = document.getElementById("storeListContainer");
    const scrollTo = document.getElementById(id);
    if (scrollTo && container) {
      container.scrollTo({ top: scrollTo.offsetTop - container.offsetTop, behavior: "smooth" });
    }
  };

  useEffect(() => {
    (async function () {
      const [stores, buyerXp] = await Promise.all([
        Addresses.List("Bachmans", {
          pageSize: 100,
          filters: { ID: "Store-*", "xp.Active": true, "xp.CustomLocation": false },
        }),
        deliveryResourceService.GetBuyerXp(),
      ]);
      setServies(buyerXp?.StoreServices);
      const lyndaleStore = stores.Items.find((store) => store.ID === "Store-Lyndale-6010--612-861-7676");
      locationService.GetCurrentPosition(lyndaleStore?.xp?.Location).then((loc) => {
        setUserLocation(loc);
        setCurrentPosition(loc);
        setMapCenter(loc);
        setStoreData({
          bachmansStores: stores.Items,
          filteredStores: stores.Items,
          searchedStores: stores.Items,
        });
      });
    })();
  }, []);

  useEffect(() => {
    //  whenever we update the storeData. This should run and get the stores in view by intersection
    const intersect = storeData?.searchedStores?.filter((store) =>
      storeData?.filteredStores?.map((store) => store.ID).includes(store.ID)
    );
    setStoresInView(intersect);
  }, [storeData]);

  const handleSearch = async (searchData: SearchData) => {
    var addressSearch = "";
    if (searchData.city) addressSearch += searchData.city + " Minnesota ";
    if (searchData.zip) addressSearch += searchData.zip;
    if (addressSearch.length) {
      const res = await bachmansIntegrationsService.searchGoogleAddress(addressSearch);
      if (res.data.status === "OK") {
        const newCenter: Location = {
          lat: parseFloat(res.data.results[0].geometry.location.lat),
          lng: parseFloat(res.data.results[0].geometry.location.lng),
        };
        setMapCenter(newCenter);
        setCurrentPosition(newCenter);
        const inScope = storeData?.bachmansStores?.filter((store) => storeinSearchRadius(store, newCenter));
        setStoreData({
          ...storeData,
          searchedStores: inScope,
        });
      }
    } else {
      setStoreData({
        ...storeData,
        searchedStores: storeData?.bachmansStores,
      });
    }
  };

  const filterByServices = (selected: string[]) => {
    var filteredStores;
    if (selected.length === 0) {
      filteredStores = storeData?.bachmansStores;
    } else {
      filteredStores = storeData?.searchedStores?.filter((store) => {
        const availServices = intersection(store?.xp?.ServiceOffered, selected);
        return availServices && availServices.length > 0;
      });
    }
    setStoreData({
      ...storeData,
      filteredStores: filteredStores,
    });
  };

  const resetStores = () => {
    setCurrentPosition(userLocation);
    setStoreData({
      ...storeData,
      filteredStores: storeData?.bachmansStores,
      searchedStores: storeData?.bachmansStores,
    });
    setMapCenter(userLocation);
    setZoom(10);
  };

  const storeinSearchRadius = (store: Address, location?: Location) => {
    const loc = location ? location : mapCenter;
    if (!loc) return true;
    const checkPoint: Location = store?.xp?.Location;
    if (!checkPoint) return false;
    var ky = 40000 / 360;
    var kx = Math.cos((Math.PI * loc.lat) / 180.0) * ky;
    var dx = Math.abs(loc.lng - checkPoint.lng) * kx;
    var dy = Math.abs(loc.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= 30;
  };

  const updateMapCenter = (loc: Location) => setMapCenter(loc);

  const updateZoom = (z: number) => setZoom(z);

  const hasSearched = () =>
    storesInView && storeData?.bachmansStores && storesInView.length < storeData?.bachmansStores.length;

  return (
    <Container className={classes.root}>
      <Typography variant="h1" component="h2" gutterBottom>
        Find A Bachman's Store
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={4} className={classes.containerLeft} id="storeListContainer">
          <StoreFilter
            services={services}
            submitSearch={handleSearch}
            filterServices={filterByServices}
            resetStores={resetStores}
          />
          {hasSearched() && (
            <Fragment>
              <Typography variant="body1">{"We found " + storesInView!.length + " results"}</Typography>
              <Typography variant="body2" className={classes.resultsLabel}>
                {"Results based on your query"}
              </Typography>
            </Fragment>
          )}
          {!hasSearched() && (
            <Typography variant="body2" className={classes.resultsLabel}>
              {"Results based on your location"}
            </Typography>
          )}
          <StoreList
            storeData={storesInView}
            currentPosition={currentPosition}
            updateCenter={updateMapCenter}
            updateZoom={updateZoom}
          ></StoreList>
        </Grid>
        <Grid item sm={8}>
          {mapCenter && !isMobile && (
            <Map
              stores={storesInView}
              center={mapCenter}
              selectMarker={onStoreSelect}
              updateZoom={updateZoom}
              zoomLevel={zoom}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default StoreLocator;
