import { Badge, createStyles, makeStyles, Theme, Typography, IconButton, Hidden, SvgIcon } from "@material-ui/core";
import { isUndefined } from "lodash";
import React, { Fragment, FunctionComponent, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../redux/store-hook";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    desktop: {
      display: "none",
      alignItems: "center",
      cursor: "pointer",
      "& > div": {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  })
);

const MiniCart: FunctionComponent = () => {
  const currentOrder = useAppSelector((state) => state.order);
  const classes = useStyles();
  const history = useHistory();

  const cartCount = useMemo(() => {
    return currentOrder?.order?.CartCount || 0;
  }, [currentOrder]);

  const goToCart = () => history.push("/cart");

  return (
    <Fragment>
      <Hidden smUp>
        <IconButton aria-label="View Cart" onClick={goToCart}>
          <Badge color="secondary" badgeContent={cartCount} className={classes.mobile}>
            <SvgIcon color="action" viewBox="0 0 28.5 28.1" style={{ padding: 1.5 }}>
              <circle cx="22.2" cy="25.1" r="2.6" />
              <circle cx="9.8" cy="25.1" r="2.6" />
              <path d="M25.5 4.9H8c-.9 0-1.6-.8-1.6-1.6C6.3 1.8 5.1.4 3.6.4H1.1C.7.4.3.7.3 1.2c.1.4.3.8.8.8h2.5c.9 0 1.5.7 1.5 1.5V19c0 1.6 1.3 2.8 2.8 2.8h17.5c.4 0 .8-.3.8-.8 0-.4-.3-.8-.8-.8H7.8c-.9 0-1.4-.7-1.4-1.5V16c.4.2.8.3 1.1.3h17.9c1.4 0 2.6-1.1 2.7-2.7V7.5c0-1.5-1.2-2.6-2.6-2.6z" />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Hidden>
      <div className={classes.desktop} onClick={goToCart}>
        <SvgIcon color="action" viewBox="0 0 28.5 28.1">
          <circle cx="22.2" cy="25.1" r="2.6" />
          <circle cx="9.8" cy="25.1" r="2.6" />
          <path d="M25.5 4.9H8c-.9 0-1.6-.8-1.6-1.6C6.3 1.8 5.1.4 3.6.4H1.1C.7.4.3.7.3 1.2c.1.4.3.8.8.8h2.5c.9 0 1.5.7 1.5 1.5V19c0 1.6 1.3 2.8 2.8 2.8h17.5c.4 0 .8-.3.8-.8 0-.4-.3-.8-.8-.8H7.8c-.9 0-1.4-.7-1.4-1.5V16c.4.2.8.3 1.1.3h17.9c1.4 0 2.6-1.1 2.7-2.7V7.5c0-1.5-1.2-2.6-2.6-2.6z" />
        </SvgIcon>
        <div>
          <Typography variant="body2" color="textSecondary" style={{ marginBottom: -5 }}>
            Cart
          </Typography>
          {!isUndefined(cartCount) && (
            <Typography variant="h4" color="secondary">
              {`${cartCount} Item(s)`}
            </Typography>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MiniCart;
