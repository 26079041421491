import React, { Fragment, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { Typography, Box, Divider, makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import logoPurple from "../../assets/svg/logoPurple.svg";
import Currency from "react-currency-formatter";
import { BachmansShipmentWithLineItems } from "../../models/shipment";
import { neutral } from "../../themes/colors";
import moment from "moment-timezone";
import theme from "../../themes/main";
import { Order } from "ordercloud-javascript-sdk";
import { deliveryTextMap } from "../../constants/DeliveryText";
import { sumBy } from "lodash";
import deliveryService from "../../services/delivery.service";
import eventService from "../../services/event.service";
import productService from "../../services/product.service";

export interface OrderConfirmationRouteMatch {
  userID: string;
  orderID: string;
}
interface PrintOrderConfirmationProps {
  order: Order;
  shipments: BachmansShipmentWithLineItems[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    printContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: theme.spacing(4, 4, 1, 4),
      position: "relative",
    },
    logo: {
      display: "block",
      width: 225,
      height: "auto",
    },
    root: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(1),
      flexGrow: 1,
      "& h1": {
        marginBottom: theme.spacing(2),
      },
    },
    shipmentSection: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    deliveryText: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.2,
    },
    deliveryDescription: {
      color: neutral.text_light_bg,
    },
    textDeliveryFee: {
      color: neutral.text_white_bg,
      textTransform: "uppercase",
    },
    orderSummaryTitle: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(1, 0),
      color: theme.palette.grey[800],
    },
    textCurrency: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 12,
    },
    textCurrencyShipmentTotal: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 14,
      lineHeight: 1.43,
    },
    divider: {
      margin: theme.spacing(1.5, 0),
    },
    darkDivider: {
      backgroundColor: theme.palette.grey[400],
      margin: theme.spacing(1.5, 0),
    },
    orderTotal: {
      color: neutral.text_white_bg,
      fontWeight: theme.typography.fontWeightBold,
    },
    textSummaryCurrency: {
      marginTop: "auto",
      marginBottom: "auto",
      fontSize: 14,
    },
    total: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 20,
    },
    flexGap1: {
      gap: theme.spacing(1),
    },
    bgLight: {
      marginTop: "auto",
      backgroundColor: neutral.search_bg,
    },
    specialRequest: {
      fontStyle: "italic",
      padding: 0,
      width: "100%",
      marginBlock: theme.spacing(2),
      "& .MuiOutlinedInput-multiline": {
        backgroundColor: neutral.search_bg,
        padding: theme.spacing(1),
      },
      "& .MuiButtonBase-root": {
        padding: theme.spacing(0.5),
        opacity: 0.5,
        "&:hover": {
          opacity: 1,
        },
        "& .MuiSvgIcon-root": {
          fontSize: 12,
        },
      },
    },
    productInfoTable: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      "& img": {
        width: 40,
        height: 40,
        aspectRatio: "1 / 1",
        objectFit: "cover",
        boxShadow: theme.shadows[1],
        alignSelf: "flex-start",
        [theme.breakpoints.up("sm")]: {
          alignSelf: "center",
        },
      },
      "& h6": {
        alignSelf: "flex-start",
        marginLeft: "auto",
      },
    },
    subtext: {
      color: theme.palette.grey[600],
      lineHeight: 1,
    },
    fragmentation: {
      display: "table",
      position: "relative",
      breakInside: "avoid",
      pageBreakInside: "avoid",
    },
    derpFace: {
      color: "red",
    },
  })
);

const PrintOrderConfirmation: React.FunctionComponent<PrintOrderConfirmationProps> = (props) => {
  const classes = useStyles();
  const match = useRouteMatch<OrderConfirmationRouteMatch>();
  const { order, shipments } = props;

  const getShipmentItemsText = (shipment: BachmansShipmentWithLineItems) => {
    return shipment.LineItems?.length + " " + (shipment.LineItems?.length > 1 ? "items" : "item");
  };

  const totalHandling = useMemo(() => {
    return sumBy(shipments, (s) => s?.xp?.HandlingCharges || 0);
  }, [shipments]);

  const totalPicking = useMemo(() => {
    const pickupShipments = shipments?.filter((s) => deliveryService.IsPickUp(s.Shipper));
    return sumBy(
      pickupShipments,
      (s) => (s.Cost || 0) - (s?.xp?.HandlingCharges || 0) - (s?.xp?.WiredServiceFees || 0)
    );
  }, [shipments]);

  return (
    <Fragment>
      <Box className={classes.printContainer}>
        <Box display="flex" flexDirection="column" mb={3}>
          <Box mr="auto" display="flex" alignItems="center">
            <img src={logoPurple} alt="Bachman's Logo" className={classes.logo}></img>
            <Divider flexItem orientation="vertical" style={{ marginInline: 24 }} />
            <Typography variant="h1">Order Summary</Typography>
          </Box>
          <Box display="flex" mt={1}>
            <Typography>
              Order Number: <strong>{match.params.orderID}</strong>
            </Typography>
            <Divider light flexItem orientation="vertical" style={{ marginInline: 8 }} />
            <Typography>
              Order Placed: <strong>{moment(order?.DateSubmitted).format("MM/DD/YYYY")}</strong>
            </Typography>
          </Box>
        </Box>
        <div className={classes.root}>
          {shipments?.map((shipment, i) => (
            <Fragment key={i}>
              <Box className={classes.fragmentation} id={`orderSummaryDelivery__${shipment.ID}`}>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography color="textSecondary">
                          {deliveryTextMap[shipment.Shipper as string]?.description}
                        </Typography>
                        <Typography color="textSecondary">
                          Recipient: {++i} of {shipments?.length}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Box display="flex" justifyContent="space-between" flexDirection="column">
                        {/* RECIPIENT DETAILS START */}
                        <Fragment>
                          <Box display="flex" flexDirection="column" mb={2}>
                            {!deliveryService.IsPickUp(shipment.xp?.DeliveryMethod) &&
                              shipment?.LineItems[0].xp?.DeliveryMethod !== "Email" && (
                                <Typography variant="body1" className={classes.deliveryText}>
                                  {shipment.ToAddress?.CompanyName}
                                </Typography>
                              )}
                            <Typography className={classes.deliveryText} variant="body1">
                              {shipment.xp?.DeliveryMethod !== "InStorePickUp"
                                ? shipment.ToAddress?.xp?.NickName && shipment.ToAddress?.xp?.NickName
                                : shipment.ToAddress?.CompanyName + " Store"}
                            </Typography>
                            <Typography variant="body1">
                              {`${shipment.ToAddress?.FirstName} ${shipment.ToAddress?.LastName}`}
                            </Typography>
                            {shipment?.LineItems[0].xp?.DeliveryMethod !== "Email" && (
                              <Typography variant="body1">
                                {shipment.ToAddress?.Street1}{" "}
                                {shipment.ToAddress?.Street2 && `, ${shipment.ToAddress?.Street2}`}
                              </Typography>
                            )}
                            {shipment?.LineItems[0].xp?.DeliveryMethod !== "Email" && (
                              <Typography variant="body1">
                                {shipment.ToAddress?.City}, {shipment.ToAddress?.State} {shipment.ToAddress?.Zip}
                              </Typography>
                            )}
                            {shipment?.LineItems[0].xp?.DeliveryMethod === "Email" && (
                              <Typography variant="body1">{shipment?.LineItems[0].xp.Email}</Typography>
                            )}
                          </Box>
                        </Fragment>
                        {/* RECIPIENT DETAILS END */}

                        {/* Hide Date while printing EGiftcard */}
                        {shipment?.LineItems[0].xp?.DeliveryMethod !== "Email" && (
                          <Typography color="textSecondary">
                            {shipment?.LineItems[0]?.Product?.xp?.IsWorkShopEvent
                              ? `Event Date: ${moment(shipment.xp?.RequestedDeliveryDate).format("MM/DD/YYYY")}`
                              : `Requested ${deliveryTextMap[shipment.Shipper as string]?.text} Date: ${moment(
                                  shipment.xp?.RequestedDeliveryDate
                                ).format("MM/DD/YYYY")}`}
                          </Typography>
                        )}

                        {/* LINE ITEM DETAILS START */}
                        {shipment.LineItems.map((lineItem, index) => (
                          <div className={classes.fragmentation} key={index}>
                            <Box className={classes.productInfoTable}>
                              <img src={lineItem?.xp?.ShownImage} alt={lineItem.Product?.Name} />
                              <div>
                                <Typography variant="h5" display="inline">
                                  {productService.GetLineItemName(lineItem)}
                                </Typography>
                                <Typography
                                  display="inline"
                                  variant="subtitle2"
                                  color="primary"
                                  style={{ marginLeft: 8 }}
                                >
                                  <Currency quantity={lineItem.LineTotal || 0} />
                                </Typography>
                                {lineItem.Product?.xp?.IsWorkShopEvent ? (
                                  <Typography gutterBottom variant="body2" className={classes.subtext}>
                                    {"Item # " + lineItem.ProductID + eventService.GetLineItemEventTimeRange(lineItem)}
                                  </Typography>
                                ) : (
                                  <Typography gutterBottom variant="body2" className={classes.subtext}>
                                    {"Item # " +
                                      lineItem.ProductID +
                                      productService.GetLineItemColorType(lineItem) +
                                      productService.GetLineItemSizeType(lineItem)}
                                  </Typography>
                                )}
                                <Typography variant="body2" className={classes.subtext}>
                                  <Currency quantity={lineItem.UnitPrice || 0} /> x {lineItem.Quantity}
                                </Typography>
                              </div>
                            </Box>
                            {lineItem.xp?.ProductNote && (
                              <Typography variant="body2">"{lineItem.xp?.ProductNote}"</Typography>
                            )}
                          </div>
                        ))}
                        {/* LINE ITEM DETAILS END */}
                      </Box>
                    </Grid>

                    <Grid item xs={5}>
                      {shipment.xp?.CardMessage && (
                        <Fragment>
                          <Typography variant="body2" color="textSecondary">
                            Card Message
                          </Typography>
                          <Box mb={2} p={1} bgcolor={theme.palette.grey[200]}>
                            <Typography variant="body2">{shipment.xp?.CardMessage?.line1}</Typography>
                            <Typography variant="body2">{shipment.xp?.CardMessage?.line2}</Typography>
                            <Typography variant="body2">{shipment.xp?.CardMessage?.line3}</Typography>
                            <Typography variant="body2">{shipment.xp?.CardMessage?.line4}</Typography>
                          </Box>
                        </Fragment>
                      )}
                      {shipment.xp?.DeliveryNote && (
                        <Fragment>
                          <Typography variant="body2" color="textSecondary">
                            Instructions
                          </Typography>
                          <Box mb={2} p={1} bgcolor={theme.palette.grey[200]}>
                            <Typography variant="body2">{shipment.xp?.DeliveryNote}</Typography>
                          </Box>
                        </Fragment>
                      )}
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        {deliveryTextMap[shipment.Shipper as string]?.description ? (
                          <Typography className={classes.textDeliveryFee} variant="body2">
                            {deliveryTextMap[shipment.Shipper as string]?.description +
                              " - " +
                              getShipmentItemsText(shipment)}
                          </Typography>
                        ) : (
                          <Typography className={classes.textDeliveryFee} variant="body2">
                            {getShipmentItemsText(shipment)}
                          </Typography>
                        )}
                        <Typography className={classes.textCurrency} variant="h4">
                          <Currency quantity={shipment.Subtotal || 0} />
                        </Typography>
                      </Box>

                      {!!(shipment.AssemblyCharges && shipment.AssemblyCharges > 0) && (
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography className={classes.textDeliveryFee} variant="body2">
                            Assembly Charges
                          </Typography>
                          <Typography className={classes.textCurrency} variant="h4">
                            <Currency quantity={shipment.AssemblyCharges} />
                          </Typography>
                        </Box>
                      )}
                      {!!(shipment.PlacementCharges && shipment.PlacementCharges > 0) && (
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography className={classes.textDeliveryFee} variant="body2">
                            Placement Charges
                          </Typography>
                          <Typography className={classes.textCurrency} variant="h4">
                            <Currency quantity={shipment.PlacementCharges} />
                          </Typography>
                        </Box>
                      )}
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.textDeliveryFee} variant="body2">
                          {deliveryTextMap[shipment.Shipper as string]?.fee}
                        </Typography>
                        <Typography className={classes.textCurrency} variant="h4">
                          +
                          <Currency
                            quantity={
                              (shipment.Cost || 0) -
                              (shipment?.xp?.HandlingCharges || 0) -
                              (shipment?.xp?.WiredServiceFees || 0)
                            }
                          />
                        </Typography>
                      </Box>

                      {!!(shipment.xp?.WiredServiceFees && shipment.xp?.WiredServiceFees > 0) && (
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography className={classes.textDeliveryFee} variant="body2">
                            SERVICE FEE
                          </Typography>
                          <Typography className={classes.textCurrency} variant="h4">
                            +
                            <Currency quantity={shipment.xp?.WiredServiceFees || 0} />
                          </Typography>
                        </Box>
                      )}

                      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Typography className={classes.textDeliveryFee} variant="body2">
                          Shipment Total
                        </Typography>
                        <Typography variant="h4" className={classes.textCurrencyShipmentTotal}>
                          <Currency quantity={shipment.Total || 0} />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {shipments && <Divider className={i === shipments?.length - 1 ? classes.darkDivider : classes.divider} />}
            </Fragment>
          ))}
          <Box display="flex" flexDirection="column" className={classes.flexGap1} id="orderSummaryPrice">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.textDeliveryFee} variant="body2">
                Subtotal
              </Typography>
              <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
                <Currency quantity={order?.Subtotal || 0} />
              </Typography>
            </Box>
            {totalPicking > 0 && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.textDeliveryFee} variant="body2">
                  Picking Fee
                </Typography>
                <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
                  +<Currency quantity={totalPicking} />
                </Typography>
              </Box>
            )}
            {totalHandling > 0 && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.textDeliveryFee} variant="body2">
                  Handling Fee
                </Typography>
                <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
                  +<Currency quantity={totalHandling} />
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.textDeliveryFee} variant="body2">
                Shipping
              </Typography>
              <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
                +<Currency quantity={(order?.ShippingCost || 0) - totalHandling - totalPicking} />
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.textDeliveryFee} variant="body2">
                Tax
              </Typography>
              <Typography className={classes.textSummaryCurrency} variant="h4" color="primary">
                +<Currency quantity={order?.TaxCost || 0} />
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Typography variant="overline" className={classes.orderTotal}>
                Total
              </Typography>
              <Typography variant="h4" color="primary" className={classes.total}>
                <Currency quantity={order?.Total || 0} />
              </Typography>
            </Box>
          </Box>
        </div>

        <Grid className={classes.bgLight} container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Locations
            </Typography>
            <Typography variant="body2">
              Bachman's brings beauty to life throughout the metro with six retail locations, a shop at the Galleria
              Edina, and floral departments within Lunds and Byerlys stores.
              <br />
              <br />
              For store hours and information please visit our Store Locator.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" gutterBottom>
              Customer Service
            </Typography>
            <Typography variant="body2">
              For questions or concerns regarding your order,
              <br />
              contact our customer service team.
              <br />
              Local: (612) 861-8818
              <br />
              Toll Free: 1 (877) 222-4626
              <br />
              Email: CustomerService@bachmans.com
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PrintOrderConfirmation;
