import appConstants from "../constants/app.constants";
import $ from "jquery";
import deliveryResourceService from "./delivery-resource.service";
import { cloneDeep } from "lodash";
import productService from "./product.service";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import { HomeContentState } from "../providers/contentful";
import { BachmansCategory } from "../models/Category";
import { SeoInfo } from "../components/StaticPages/SocialLinks";

var seoPages: any;
const seoDataProps = {
  MetaTitle: "",
  MetaDescription: "",
  MetaType: "website",
  MetaSiteName: window.location.host.toLowerCase().trim().replace("www.", ""),
  MetaImage: "assets/images/default-seo-img.jpg",
  CanonicalUrl: "",
};
const homepageSEO = cloneDeep(seoDataProps);
const errorSEO = cloneDeep(seoDataProps);

async function initialize() {
  try {
    await initDefaultPageConstants();
    const buyerXp = await deliveryResourceService.GetBuyerXp();

    if (buyerXp && buyerXp.SEOPages) {
      seoPages = buyerXp.SEOPages;
      if (seoPages["Home Page"]?.seo) {
        seoPages["Home Page"].seo.MetaTitle = homepageSEO.MetaTitle;
        seoPages["Home Page"].seo.MetaDescription = homepageSEO.MetaDescription;
      }
    } else {
      console.log("Could not find the buyer with buyerId:", appConstants.buyerId);
      setSeoDataProps(errorSEO);
    }
  } catch (exception) {
    console.log("SEO INIT ERROR", exception);
    setSeoDataProps(errorSEO);
  }
}

async function initDefaultPageConstants() {
  //errorSEO.props data
  errorSEO.MetaTitle = "Not found";
  errorSEO.MetaDescription = "The page you are looking for could not be found.";
  errorSEO.MetaImage = "assets/images/default-seo-img.jpg";

  //homepageSEO.props data
  homepageSEO.MetaTitle = "Welcome to Bachmans.com";
  homepageSEO.MetaDescription =
    "Shop for floral arrangements, indoor plants, gifts, decor, and garden plants and supplies from your trusted neighborhood garden center.";
  homepageSEO.MetaImage = "assets/images/default-seo-img.jpg";
}

async function setSeoData(seoData: any, seoDataContentful?: HomeContentState) {
  await initialize();

  if (seoData === "404") {
    return setSeoDataProps(errorSEO);
  } else if (seoDataContentful?.data?.fields?.seo) {
    return setSeoDataProps(seoDataContentful.data.fields.seo);
  } else if (seoPages && seoPages[seoData]) {
    return setSeoDataProps(seoPages[seoData].seo);
  } else if (typeof seoData === "object") {
    return setSeoDataProps(seoData);
  } else {
    return setSeoDataProps(homepageSEO);
  }
}

async function setSeoDataProps(seoObject: any) {
  seoDataProps.CanonicalUrl = window.location.href.toLowerCase().trim().replace("http://", "https://");

  if (seoObject) {
    seoDataProps.MetaTitle = seoObject.MetaTitle || seoObject.title || seoDataProps.MetaTitle;
    seoDataProps.MetaDescription = seoObject.MetaDescription || seoObject.description || seoDataProps.MetaDescription;

    var metaImage = seoObject.MetaImage || seoObject.image || seoDataProps.MetaImage;
    if (metaImage.toLowerCase().trim().includes("images.ctfassets.net")) {
      metaImage = metaImage.trim();
      metaImage =
        !metaImage.includes("http:") && !metaImage.includes("https:")
          ? "https:" + metaImage
          : metaImage.replace("http:", "https:");
      seoDataProps.MetaImage = metaImage;
    } else {
      seoDataProps.MetaImage = homepageSEO.MetaImage;
    }
    seoDataProps.MetaType = seoObject.MetaType ? seoObject.MetaType : seoDataProps.MetaType;
  } else {
    seoDataProps.MetaTitle = homepageSEO.MetaTitle;
    seoDataProps.MetaDescription = homepageSEO.MetaDescription;
    seoDataProps.MetaImage = homepageSEO.MetaImage;
    seoDataProps.MetaType = homepageSEO.MetaType;
  }

  $("title").text(seoDataProps.MetaTitle);
  $("[property='og:title'], [property='twitter:title']").attr("content", seoDataProps.MetaTitle);
  $("[name='description'], [property='twitter:description']").attr("content", seoDataProps.MetaDescription);
  $("[property='og:image'], [property='twitter:image']").attr("content", seoDataProps.MetaImage);
  $("[property='og:type']").attr("content", seoDataProps.MetaType);
  $("[property='og:site_name'], [property='twitter:site']").attr("content", seoDataProps.MetaSiteName);
  $("#canonical-tag, [property='og:url']").attr("href", seoDataProps.CanonicalUrl);

  //console.log(seoDataProps);
  return seoDataProps;
}

const getSeoDataProps = () => {
  return setSeoDataProps(null);
};

export type PageGroupKey = "category" | "product" | "services-pages";

async function importIntoSeoDataProps(seoObject: any, pageGroupKey: PageGroupKey) {
  if (seoObject && pageGroupKey) {
    await initialize();
    const pageSeo = cloneDeep(homepageSEO);
    if (pageGroupKey === "category") {
      const currentCat = seoObject as BachmansCategory;
      pageSeo.MetaTitle = currentCat.xp?.seo?.MetaTitle || currentCat.Name || pageSeo.MetaTitle;
      pageSeo.MetaDescription =
        currentCat.xp?.seo?.MetaDescription || currentCat.Description || pageSeo.MetaDescription;
      setSeoDataProps(pageSeo);
    } else if (pageGroupKey === "product") {
      const currentProd = seoObject as BuyerProduct;
      pageSeo.MetaImage = productService.GetDefaultImage(currentProd) || pageSeo.MetaImage;
      pageSeo.MetaTitle = currentProd?.xp?.SEO?.MetaTitle || currentProd.Name || pageSeo.MetaTitle;
      pageSeo.MetaDescription =
        currentProd.xp?.SEO?.MetaDescription || currentProd.Description || pageSeo.MetaDescription;
      setSeoDataProps(pageSeo);
    } else if (pageGroupKey === "services-pages") {
      let currentPathname = window.location.pathname.toLowerCase().trim();
      const currServicePage = seoObject as SeoInfo;
      pageSeo.MetaImage = currServicePage.image || pageSeo.MetaImage;
      if (currentPathname.includes("/p/")) {
        pageSeo.MetaTitle = $("[property='og:title']").attr("content") || pageSeo.MetaTitle;
        pageSeo.MetaDescription = $("[name='description']").attr("content") || pageSeo.MetaDescription;
      } else {
        pageSeo.MetaTitle = currServicePage.title || pageSeo.MetaTitle;
        pageSeo.MetaDescription = currServicePage.description || pageSeo.MetaDescription;
      }

      setSeoDataProps(pageSeo);
    }
  }
}

const service = {
  SetSeoData: setSeoData,
  GetSeoDataProps: getSeoDataProps,
  ImportIntoSeoDataProps: importIntoSeoDataProps,
};

export default service;
