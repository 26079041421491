import React, { FunctionComponent } from "react";
import { createStyles, Drawer, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { neutral, purple } from "../../themes/colors";

interface MobileDrawerProps {
  menuOpen: boolean;
  toggleMenu: () => void;
  menuTitle: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > .MuiDrawer-paperAnchorLeft": {
        height: "100%",
        width: "100%",
        backgroundColor: neutral.offwhite_bg,
      },
      "& > .MuiDrawer-paperAnchorRight": {
        height: "100%",
        width: "100%",
        backgroundColor: neutral.offwhite_bg,
      },
    },
    mobileMenuBottom: {
      boxShadow: `0px -2px 10px rgba(0,0,0,0.2)`,
      position: "fixed",
      bottom: 0,
      display: "flex",
      zIndex: 1,
      "& > div": {
        flex: "1 0 0",
      },
      alignItems: "center",
      background: neutral.offwhite_bg,
      width: "100%",
    },
    closeButton: {
      borderRadius: "0",
      background: purple.regular,
      color: neutral.offwhite_bg,
      "&:hover": {
        color: purple.regular,
      },
    },
    mobileMenuTitle: {
      margin: theme.spacing(0, 0, 0, 3),
      "& > .MuiTypography-h1": {
        fontSize: "21px",
        color: neutral.text_colored_bg,
      },
    },
  })
);

const MobileDrawer: FunctionComponent<MobileDrawerProps> = (props) => {
  const classes = useStyle();
  const { menuOpen, toggleMenu, menuTitle, children } = props;
  return (
    <Drawer classes={{ root: classes.root }} anchor={"right"} open={menuOpen} onClose={toggleMenu}>
      <div>
        {children}
        <div className={classes.mobileMenuBottom}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleMenu}>
            <Close fontSize={"large"} />
          </IconButton>
          <div className={classes.mobileMenuTitle}>
            <Typography variant="h1">Close Menu</Typography>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileDrawer;
