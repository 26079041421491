import bachmansIntegrationsService from "./bachmansIntegrations.service";

function expirationDateFormat(month: any, year: any) {
  var stringYear = year.toString();
  return month + stringYear.slice(-2);
}

//Use this function to update a credit card for an existing customer profile.
async function updateAsync(cardId: string, card: any) {
  let ExpirationDate = expirationDateFormat(card.ExpMonth, card.ExpYear);
  // letterSpacing token = OrderCloudSDK.GetToken();
  let cc = {
    name: card.CardholderName,
    date: ExpirationDate,
  };
  try {
    return await bachmansIntegrationsService.updateCCAsync(cardId, cc);
  } catch (ex) {
    console.error(ex);
  }
}

const service = {
  UpdateAsync: updateAsync,
  ExpirationDateFormat: expirationDateFormat,
};

export default service;
