import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { CheckCircle, ExpandMore } from "@material-ui/icons";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { neutral } from "../../themes/colors";
import CheckoutHeader from "./CheckoutHeader";
import CheckoutLogin from "./Authentication";
import DeliveryInformation from "./Delivery";
import PaymentInformation from "./Payment/Payment";
import { SessionContext } from "../../providers/session";
import { ValidatedBachmansShipmentWithLineItems } from "../../models/shipment";
import { useAppSelector } from "../../redux/store-hook";
import { CatalogContext } from "../../providers/catalog";
import productService from "../../services/product.service";
import cartService from "../../services/cart.service";
import { cloneDeep, flatten, isUndefined, union } from "lodash";
import { BachmansProduct } from "../../models/product";
import checkoutService from "../../services/checkout.service";
import { BachmansPay } from "../../models/Payment";
import bachmansIntegrationsService, { OrderCalculateResponse } from "../../services/bachmansIntegrations.service";
import { useHistory } from "react-router";
import { BuyerAddress, Me, MeUser, Order, Orders } from "ordercloud-javascript-sdk";
import { calculateOrder, initOrder } from "../../redux/slices/order";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import CheckoutErrorDialog from "./CheckoutErrorDialog";
import { Errors } from "../../constants/error.constants";
import OrderSummary from "../Cart/OrderSummary";
import BachmansOrder from "../../models/order";
import googleAnalyticsService from "../../services/googleAnalytics.service";
import { RouteComponentProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
    },
    accordionSummary: {
      minHeight: 45,
      backgroundColor: neutral.grey_background,
      "&.Mui-expanded": {
        minHeight: "inherit",
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: "12px 0",
        },
      },
      "& .MuiSvgIcon-root": {
        alignSelf: "center",
        marginLeft: "auto",
      },
    },
    accordionSummaryLogin: {
      "& .MuiTypography-root": {
        lineHeight: 1.4,
      },
    },
    accordionSummarySuccess: {
      borderColor: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.light, 0.25),
    },
  })
);

export enum CheckoutPanel {
  Authentication = "Authentication",
  Delivery = "Delivery",
  Payment = "Payment",
}
declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}
export interface CheckoutState {
  authenticationValid: boolean;
  deliveryValid: boolean;
  paymentValid: boolean;
}

export interface CheckoutError {
  line1?: string;
  line2?: string;
  route?: string; //Optionally designate to route users upon hitting OK
}

export interface SubmitOrderParams {
  paymentObj: BachmansPay;
  address: BuyerAddress;
  anonUserEmail?: string;
  poNumber?: string;
}
const Checkout: React.FunctionComponent<RouteComponentProps> = () => {
  const { user, anonymous, patchUser } = useContext(SessionContext);
  const currentOrder = useAppSelector((state) => state.order);
  const { DCFcategories } = useContext(CatalogContext);
  const [productsOnOrder, setProductsOnOrder] = useState<BachmansProduct[]>();
  const [checkoutState, setCheckoutState] = useState<CheckoutState>();
  const [validatedShipments, setValidatedShipments] = useState<ValidatedBachmansShipmentWithLineItems[]>();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [checkoutError, setCheckoutError] = useState<CheckoutError>();
  const [creditCardError, setCreditCardError] = useState<string>();
  const [activePanel, setActivePanel] = useState<CheckoutPanel>();
  const [acknowledgeAnonymous, setAcknowledgeAnonymous] = useState<boolean>(false);
  const [acknowledgeDelivery, setAcknowledgeDelivery] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setActivePanel(anonymous ? CheckoutPanel.Authentication : CheckoutPanel.Delivery);
  }, [anonymous]);

  useEffect(() => {
    (async () => {
      if (DCFcategories && currentOrder?.shipments) {
        const productIDs: string[] = [];
        currentOrder.shipments?.forEach((shipment) => {
          shipment.LineItems.forEach((item) => productIDs.push(item.ProductID));
        });
        const products = await productService.ListOcProducts({ filters: { ID: productIDs.join("|") } });

        setProductsOnOrder(products.Items);
        const validated = await cartService.ValidateAllShipments(DCFcategories, currentOrder.shipments, products.Items);

        setValidatedShipments(validated);
        const shipmentDeliveryNotesValid = validated.every(
          (shipment) => !(shipment?.PlacementCharges && shipment?.PlacementCharges > 0 && !shipment.xp?.DeliveryNote)
        );
        const cardMessagesValid = validated.every(
          (shipment) => shipment.xp?.NoCardMessage || checkoutService.HasCardMessage(shipment)
        );
        const validatedLi = flatten(validated.map((s) => s.LineItems));
        const allItemsValid = validatedLi.every((li) => li.Validation?.valid === true);
        setCheckoutState({
          authenticationValid: !anonymous || (anonymous && acknowledgeAnonymous), // currentOrder.order?.xp?.FromUserEmail gets set later
          deliveryValid: shipmentDeliveryNotesValid && cardMessagesValid && allItemsValid,
          paymentValid: false,
        });
      }
    })();
  }, [
    DCFcategories,
    anonymous,
    acknowledgeAnonymous,
    currentOrder.order?.xp?.FromUserEmail,
    currentOrder.shipments,
    currentOrder.shipments?.filter,
  ]);

  const goToNextStep = (panel: CheckoutPanel) => setActivePanel(panel);

  interface PresubmitResponse extends CheckoutError {
    valid: boolean;
    order?: BachmansOrder;
  }

  //PRE-SBMIT TASKS
  //1. Validates all line items in all shipments on order
  //2. Validates the gift card on order if there is one.
  //3. Patches and increments order.
  //4. Sets billing address
  const preSubmitTasks = async (params: SubmitOrderParams): Promise<PresubmitResponse> => {
    if (DCFcategories && currentOrder?.order?.ID && currentOrder?.order?.Total) {
      const validated = await cartService.ValidateAllShipments(DCFcategories, currentOrder.shipments, productsOnOrder);
      if (!cartService.AllItemsValid(validated)) {
        return {
          order: currentOrder.order,
          line1: Errors.shipmentsNotValidLine1,
          line2: Errors.shipmentsNotValidLine2,
          route: "/cart",
          valid: false,
        };
      }
      try {
        await checkoutService.ValidateGiftCardOnOrder(currentOrder);
      } catch (err: any) {
        return {
          order: currentOrder.order,
          line1: err.Message || "An Error Occured",
          route: "/cart",
          valid: false,
        };
      }
      const patchedOrder = await patchOrder(params);
      const billing = { ...params.address };
      billing.xp = {
        Email: patchedOrder?.xp?.FromUserEmail,
      };

      var saveAsNewAddress =
        params?.paymentObj?.CreditCard?.CardDetails?.Save && patchedOrder.ShippingAddressID !== billing.ID;
      await checkoutService.SetBillingAddress(patchedOrder, billing, saveAsNewAddress);
      return {
        valid: true,
        order: patchedOrder,
      };
    } else
      return {
        order: currentOrder.order,
        line1: Errors.genericLine1Error,
        line2: Errors.genericLine2Error,
        route: "/cart",
        valid: false,
      };
  };

  const submitOrderHandleResponse = async (
    orderID: string,
    payments: BachmansPay
  ): Promise<OrderCalculateResponse | void> => {
    try {
      const orderSubmitResponse = await bachmansIntegrationsService.order.submit(
        orderID,
        currentOrder?.order?.Total!,
        payments
      );

      return orderSubmitResponse.data;
    } catch (err: any) {
      await dispatch(calculateOrder(orderID));
      setSubmitLoading(false);
      if (err?.response?.data?.ordercloudSDKErrors?.length) {
        console.log("orderCloud Error");
        setCheckoutError({
          line1: Errors.genericLine1Error,
          line2: Errors.genericLine2Error,
        });
      } else if (err?.response?.data?.AuthNetResponse) {
        console.log("Auth net error");
        handleAuthNetResponse(err?.response?.data?.AuthNetResponse);
      } else if (err?.response?.data?.message && err?.response?.data?.message === Errors.inventoryError) {
        console.log("inventory error");
        setCheckoutError({
          line1: Errors.inventoryError,
          route: "/cart",
        });
      } else {
        console.log("unknown error");
        setCheckoutError({
          line1: Errors.genericLine1Error,
          line2: Errors.genericLine2Error,
        });
      }
    }
  };

  const submitOrder = async (params: SubmitOrderParams) => {
    var isInvalidCheckout = currentOrder?.shipments?.some(
      (s) =>
        (s.xp?.NoCardMessage !== true && s.xp?.CardMessage === null) ||
        (s.xp?.NoCardMessage === true && s.xp?.CardMessage !== null)
    );
    if (isInvalidCheckout) {
      //setActivePanel(CheckoutPanel.Delivery)
      setCheckoutError({
        line1: "Please click OK to review or remove your card message.",
      });
      return;
    }

    setSubmitLoading(true);
    const preSubmitTasksResponse = await preSubmitTasks(params);
    if (!preSubmitTasksResponse.valid) {
      if (preSubmitTasksResponse.order?.ID) {
        await dispatch(calculateOrder(preSubmitTasksResponse.order?.ID));
      }
      setCheckoutError(preSubmitTasksResponse as CheckoutError);
      setSubmitLoading(false);
    } else {
      const payments = cloneDeep(params.paymentObj);
      if (payments.CreditCard?.AcctNumber && payments?.CreditCard?.CardDetails) {
        delete payments.CreditCard.CardDetails;
      }
      trackCheckout(4);
      const submitResponse = await submitOrderHandleResponse(
        preSubmitTasksResponse.order?.ID || currentOrder.order?.ID!,
        payments
      );
      if (submitResponse) {
        dispatch(initOrder());
        if (!anonymous) {
          addEventsToUser(submitResponse);
          checkAndSaveBillingOptions(submitResponse);
        }
        setSubmitLoading(false);
        history.push(`orderConfirmation/${user?.ID}/${submitResponse.Order.ID}`);
      }
    }
  };

  const addEventsToUser = (orderSubmitResponse: OrderCalculateResponse) => {
    if (patchUser) {
      const items = flatten(orderSubmitResponse.CalculatorShipments.map((s) => s.LineItems));
      const purchasedEventIDs = items
        .filter((item) => item.Product?.xp?.IsWorkShopEvent)
        ?.map((item) => item.ProductID);
      let patchObj: Partial<MeUser> = {
        xp: { LastOrderSubmitted: orderSubmitResponse.Order.DateSubmitted },
      };
      if (purchasedEventIDs.length) {
        patchObj.xp.PurchasedEvents = user?.xp?.PurchasedEvents
          ? union(user.xp.PurchasedEvents, purchasedEventIDs)
          : purchasedEventIDs;
      }
      patchUser(patchObj);
    }
  };

  const checkAndSaveBillingOptions = (orderSubmitResponse: OrderCalculateResponse) => {
    const orderHasAssignablePaymentMethods = () => {
      let result: any = {
        BC: false,
        CC: false,
      };
      orderSubmitResponse.CalculatorShipments.forEach((s) => {
        s.xp?.PaymentsForThisShipment?.forEach((p) => {
          if (p.CreditCardID) {
            result.CC = p.CreditCardID;
          }
          if (p.SpendingAccountID) {
            result.BC = p.SpendingAccountID;
          }
        });
      });
      return result;
    };

    if (orderSubmitResponse.Order.BillingAddressID) {
      const am = orderHasAssignablePaymentMethods();
      if (am.CC) {
        Me.PatchCreditCard(am.CC, {
          xp: {
            BillingAddressID: orderSubmitResponse.Order.BillingAddressID,
          },
        });
      } else if (am.BC && patchUser) {
        patchUser({
          xp: {
            BcBillingAddressID: orderSubmitResponse.Order.BillingAddressID,
          },
        });
      }
    }
  };

  const handleAuthNetResponse = (response: any) => {
    if (response?.transactionResponse?.errors?.length) {
      if (response.transactionResponse.errors[0].errorCode === "0") {
        setCheckoutError({
          line1: Errors.genericLine1Error,
          line2: Errors.genericLine2Error,
        });
      } else {
        setCreditCardError(response.transactionResponse.errors[0].errorText);
        setCheckoutError({
          line1: response.transactionResponse.errors[0].errorText,
        });
      }
    }
  };

  const countLocalDeliveryShipments = () => {
    const shipments = currentOrder.shipments;
    let count = 0;
    shipments?.forEach((s) => {
      if (s?.xp?.DeliveryMethod === "LocalDelivery") {
        count++;
      }
    });
    return count;
  };

  const patchOrder = async (params: SubmitOrderParams) => {
    const partial: Partial<Order> = {
      xp: {
        ShipmentCount: countLocalDeliveryShipments(),
        confirmationstatus: true, //triggers confirmation email update after order submit goes through - email is sent to the FromUserEmail
        OrderSource: "StoreFront",
        PaymentStatus: "Completed",
        PONumber: params.poNumber,
        OrphanedAccount: user?.Orphaned,
      },
    };
    if (!currentOrder.order?.xp?.WasIncremented) {
      partial.ID = "{orderIncrementor}";
      partial.xp.WasIncremented = true;
    }
    partial.xp.FromUserEmail = anonymous ? params.anonUserEmail : user?.Email;
    return await Orders.Patch("Outgoing", currentOrder?.order?.ID!, partial);
  };

  const continueToPayment = (updatedShipments: any) => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    setValidatedShipments(updatedShipments);
    setAcknowledgeDelivery(true);
    setActivePanel(CheckoutPanel.Payment);
  };

  const trackCheckout = useCallback(
    (step: number) => {
      const items = flatten(currentOrder.shipments?.map((s) => s.LineItems));
      googleAnalyticsService.Checkout.AddItemsForPurchase(items);
      if (step === 4) {
        googleAnalyticsService.Checkout.Purchase(currentOrder.order);
      } else {
        googleAnalyticsService.Checkout.TrackCheckout(step);
      }
    },
    [currentOrder.order, currentOrder.shipments]
  );

  useEffect(() => {
    if (activePanel === CheckoutPanel.Authentication) {
      trackCheckout(1);
    } else if (activePanel === CheckoutPanel.Delivery) {
      trackCheckout(2);
    } else if (activePanel === CheckoutPanel.Payment) {
      trackCheckout(3);
    }
  }, [activePanel, trackCheckout]);

  useEffect(() => {
    let flattenedArray;
    let isShippingtracked = false;
    if (currentOrder && currentOrder?.shipments && currentOrder?.order) {
      let checkoutOrderobj = currentOrder?.shipments.map(function (item: any) {
        return item.LineItems.map(function (i: any) {
          if (i) {
            return {
              item_name: i.Product.Name,
              item_id: i.Product.ID,
              price: i.UnitPrice,
              quantity: i.Quantity,
            };
          }
        });
      });
      flattenedArray = checkoutOrderobj.reduce((acc, curr) => acc.concat(curr), []);

      if (activePanel === CheckoutPanel.Authentication) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            currency: "USD",
            value: currentOrder?.order.Total,
            items: flattenedArray,
          },
        });
      } else if (activePanel === CheckoutPanel.Delivery && isShippingtracked == false) {
        isShippingtracked = true;
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: "USD",
            value: currentOrder?.order.Total,
            items: flattenedArray,
          },
        });
      }
    }
  }, [activePanel]);

  return (
    <Fragment>
      <CheckoutHeader />
      <Container className={classes.root}>
        <Grid container spacing={3}>
          <Grid item sm={9}>
            <CheckoutErrorDialog
              open={!isUndefined(checkoutError)}
              onClose={() => setCheckoutError(undefined)}
              message={checkoutError}
            />
            <Accordion
              key="Authentication"
              square
              elevation={0}
              onClick={() => (!anonymous ? null : setActivePanel(CheckoutPanel.Authentication))}
              expanded={activePanel === CheckoutPanel.Authentication}
            >
              <AccordionSummary
                className={`${classes.accordionSummary} ${
                  (!anonymous || (anonymous && acknowledgeAnonymous)) && classes.accordionSummarySuccess
                }`}
                expandIcon={!anonymous ? null : <ExpandMore />}
                aria-controls="panel1a-content"
                id="accordionSummaryLogin"
              >
                {anonymous ? (
                  <Typography variant="subtitle1">Login or Continue as Guest</Typography>
                ) : (
                  <div className={classes.accordionSummaryLogin}>
                    <Typography variant="overline" color="textSecondary">
                      Welcome back,
                    </Typography>
                    <Typography variant="subtitle1">
                      {user?.FirstName} {user?.LastName} ({user?.Email})
                    </Typography>
                  </div>
                )}
                {(!anonymous || (anonymous && acknowledgeAnonymous)) && (
                  <CheckCircle fontSize="small" color="secondary" aria-hidden="true" />
                )}
              </AccordionSummary>
              {!anonymous ? null : (
                <AccordionDetails>
                  <CheckoutLogin
                    onNextStep={() => goToNextStep(CheckoutPanel.Delivery)}
                    setAcknowledgeAnonymous={setAcknowledgeAnonymous}
                  />
                </AccordionDetails>
              )}
            </Accordion>
            <Accordion
              key="Delivery"
              square
              elevation={0}
              onClick={() =>
                (!anonymous || (anonymous && acknowledgeAnonymous)) && checkoutState?.deliveryValid
                  ? setActivePanel(CheckoutPanel.Delivery)
                  : null
              }
              expanded={activePanel === CheckoutPanel.Delivery}
            >
              <AccordionSummary
                className={`${classes.accordionSummary} ${
                  checkoutState?.deliveryValid && acknowledgeDelivery && classes.accordionSummarySuccess
                }`}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="accordionSummaryDelivery"
              >
                <Typography variant="subtitle1">Delivery Information</Typography>
                {checkoutState?.deliveryValid && acknowledgeDelivery && (
                  <CheckCircle fontSize="small" color="secondary" aria-hidden="true" />
                )}
              </AccordionSummary>
              <AccordionDetails style={{ display: "inherit" }}>
                <DeliveryInformation
                  shipments={validatedShipments}
                  deliveryValid={checkoutState?.deliveryValid}
                  continueToPayment={continueToPayment}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              key="Payment"
              square
              elevation={0}
              onClick={async () => {
                if (checkoutState?.deliveryValid) {
                  setAcknowledgeDelivery(true);
                  setActivePanel(CheckoutPanel.Payment);
                }
              }}
              expanded={activePanel === CheckoutPanel.Payment}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="accordionSummaryPayment"
              >
                <Typography variant="subtitle1">Payment Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PaymentInformation
                  loading={submitLoading}
                  submitOrder={submitOrder}
                  updateCreditCardError={(val: any) => setCreditCardError(val)}
                  // updateBachPayState={(val: BachmansPay) => {
                  //   setBachPayState(val);
                  //   setCreditCardError(undefined);
                  // }}
                  creditCardError={creditCardError}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box position={!isMobile ? "sticky" : "block"} top="96px">
              <OrderSummary />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Checkout;
