import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useMemo,
  useState,
  Fragment,
  useCallback,
  MouseEvent,
  ChangeEvent,
  useRef,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Category } from "ordercloud-javascript-sdk";
import { CatalogContext } from "../../../providers/catalog";
import CategoryService from "../../../services/category.service";
import productService from "../../../services/product.service";
import { cloneDeep, filter, find, isEqual, uniq } from "lodash";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { neutral } from "../../../themes/colors";
import FacetList from "./FacetList";
import ItemList from "../../Shared/ItemList";
import SiblingCategories from "./SiblingCategories";
import ChildrenCategories from "./ChildrenCategories";
import BannerPromotion from "../../Shared/BannerPromotion";
import StackedContentBlock from "../../Shared/Content/StackedContentBlock";
import contentfulService from "../../../services/contentful.service";
import { HomeContentContext, SimpleContentBlocksContext } from "../../../providers/contentful";
import { SimpleContentModel } from "../../../models/contentful/SimpleContent";
import MobileFilters from "./MobileFilters";
import ListActionHeader from "../../Shared/ListActionHeader";
import stringService from "../../../services/string.service";
import BachmansLoading from "../../Shared/BachmansLoading";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import sortOptions from "../../../constants/sortOptions";
import svgNoResults from "../../../assets/svg/no-results.svg";
import { CatalogRouteMatch } from "../../Catalog/Catalog";
import categoryService from "../../../services/category.service";

export interface ProductListingRouteMatch {
  query: string;
}
declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}
interface ProductListProps {
  category?: Category;
  children?: Category[];
  siblings?: Category[];
  loading?: boolean;
}

export interface ProductListState {
  filters?: FilterData;
  children?: Category[];
  categoryFilters?: string[];
}

export interface FilterData {
  categoryid?: string;
  facets?: any[];
  page?: any;
  pageSize?: number;
  sort?: any;
  query?: any;
  onSale?: any;
  featured?: boolean;
  queryid?: any;
}

interface AlgoliaResponse {
  hits?: any[];
  facets?: any;
  emptyFacets?: any;
  page?: number;
  nbPages?: number;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        flexFlow: "column wrap",
        marginTop: theme.spacing(3),
      },
    },
    title: {
      fontWeight: "inherit",
      fontSize: theme.typography.h1.fontSize,
      margin: theme.spacing(2, 0, 2, 0),
    },
    filters: {
      flex: "1 0 25%",
      width: "25%",
      padding: theme.spacing(2, 2, 2, 0),
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        flex: "1 0 0",
        width: "100%",
        order: 2,
      },
    },
    truncateTitle: {
      maxHeight: 50,
      overflow: "hidden",
    },
    toggleDisclaimer: {
      fontWeight: theme.typography.fontWeightMedium,
      marginLeft: theme.spacing(1),
      fontSize: 8,
    },
    productList: {
      flex: "1 0 75%",
      width: "75%",
      [theme.breakpoints.down("sm")]: {
        flex: "1 0 0",
        width: "100%",
        order: 1,
      },
    },
    pagination: {
      marginTop: theme.spacing(5),
      "& ul": { justifyContent: "center" },
    },
    sortby: {
      background: neutral.text_utility_bg,
      margin: theme.spacing(3, 0),
    },
    filterLabel: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.grey[600],
      margin: theme.spacing(0, 0, 1, 0),
    },
    mobileButtons: {
      display: "flex",
      alignItems: "stretch",
      margin: theme.spacing(3, 0),
    },
    seoAccordion: {
      margin: theme.spacing(2, 0),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: "none",
    },
    seoMetaDescription: {
      flexFlow: "column nowrap",
      fontSize: 10,
    },
    formControlLabelOnSale: { padding: theme.spacing(2, 0, 2, 2) },
    checkboxOnSale: {},
  })
);

const ProductList: FunctionComponent<ProductListProps> = (props) => {
  const classes = useStyle();
  const { categories } = useContext(CatalogContext);
  const homeContent = useContext(HomeContentContext);
  const simpleBlockContent = useContext(SimpleContentBlocksContext);
  const { category, children, siblings, loading } = props;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [productList, setProductList] = useState<AlgoliaResponse>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [stackedContent, setStackedContent] = useState<SimpleContentModel[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [facetList, setFacetList] = useState<any | null>(null);
  const [mobileFacetFilters, setMobileFacetFilters] = useState<string[]>([]); // since facets are applied differently in mobile UX
  const [filtersUpdated, setFiltersUpdated] = useState<boolean>(false);
  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  const [listCategory, setListCategory] = useState<Category>();
  const [productListState, setProductListState] = useState<ProductListState>();
  const [latestFacet, setLatestFacet] = useState<string>();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<CatalogRouteMatch>();
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const parentCategory = useMemo(() => {
    return categories?.find((c) => category?.ParentID === c.ID);
  }, [categories, category?.ParentID]);

  // takes changes to query params and updates our filters state
  useEffect(() => {
    if (categories) {
      let categoryFilters: string[] = [];
      if (location.pathname.includes("/c/")) {
        const urlSearchParams = new URLSearchParams(location.search);
        categoryFilters = urlSearchParams
          .getAll("c")
          ?.map((partThree) =>
            categoryService.BuildCategoryIdFromParts([match.params.partOne, match.params.partTwo as string, partThree])
          );
      }

      const queryParams = stringService.GetQueryParamsFromLocation(location.search);
      const updatedListState = cloneDeep(productListState) || {};
      updatedListState.filters = {
        ...updatedListState?.filters,
        page: queryParams.page,
        sort: queryParams.sort,
        query: queryParams.query,
        queryid: queryParams.queryid,
      };

      if ((category && parentCategory) || queryParams.query) {
        updatedListState.filters.categoryid = category?.ID;
        updatedListState.children = children;
        updatedListState.categoryFilters = categoryFilters;
        if (!isEqual(updatedListState, productListState)) {
          setProductListState(updatedListState);
        }
      }
    }
  }, [
    location.search,
    category,
    categories,
    productListState,
    children,
    parentCategory,
    productList,
    location.pathname,
    match.params,
  ]);

  //sets product list
  const getProducts = async (query?: string) => {
    setProductsLoading(true);

    document.body.scrollTop = document.documentElement.scrollTop = 0; // scroll to top of page when relisting products
    try {
      const results = await productService.ListProducts(
        productListState?.filters,
        productListState?.children,
        productListState?.categoryFilters
      );
      if (
        location.pathname.includes("/c/") &&
        !window.location.href.includes("sort") &&
        !location.search.includes("&c") &&
        !location.search.includes("?c")
      ) {
        history.push({
          pathname: window.location.pathname,
          search: `queryid=${results.queryID}&categorypage=true`,
        });
      }
      let pageName = "";
      let pageId = "";
      if (componentMounted.current) {
        setProductsLoading(false);
        setProductList(results);
        setFiltersUpdated(false);
        setProductsLoading(false);
        window.location.href.indexOf("search") > -1 ? (pageName = "Search Results") : (pageName = "Product List Page");
        window.location.href.indexOf("search") > -1 ? (pageId = "search_results") : (pageId = "product_list_page");
        var parsed = results.hits.map(function (item: any) {
          return {
            item_name: item[0].Name,
            item_id: item[0].ID,
            price: item[0].PriceSchedule.PriceBreaks[0].Price,
            item_list_id: pageId,
            item_list_name: pageName,
            index: item[0].Position,
            quantity: 1,
          };
        });
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_id: pageId,
          item_list_name: pageName,
          items: parsed,
        },
      });
      if (location.search.includes("?c") && !location.search.includes("&queryid")) {
        history.push(`${window.location.search}&queryid=${results.queryID}&categorypage=true`);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  useEffect(() => {
    (async () => {
      if (productListState) {
        setProductList(undefined);
        await getProducts();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productListState]);

  useEffect(() => {
    let newFacets = cloneDeep(productList?.facets);
    if (latestFacet) {
      if (newFacets) {
        newFacets[latestFacet] = facetList[latestFacet];
      }
    } else if (productListState?.filters?.facets) {
      const keys = uniq(productListState.filters.facets.map((facet) => facet.split(":")[0]));
      if (keys && keys.length === 1 && newFacets) {
        //  if there is only one facet type selected reset those facets to the empty facet version
        newFacets[keys[0]] = productList?.emptyFacets[keys[0]];
      }
    }

    if (!isEqual(newFacets, facetList)) {
      if (newFacets) {
        setFacetList(newFacets);
      }
    }
  }, [facetList, latestFacet, productList, productListState?.filters?.facets]);

  useEffect(() => {
    if (homeContent.ready && categories) {
      let stacked = homeContent.data?.fields?.stackedContent?.map((home) => home.fields);
      if (productListState?.filters?.query && !filtersUpdated) {
        // use the most frequent category from search
        let mostFrequentCat = productService.GetMostFrequentCategory(productList?.facets?.CategoryId, categories);
        const searchCategoryStacked = contentfulService.getSimpleContentFromIDs(
          mostFrequentCat?.xp?.Content?.stacked?.value,
          simpleBlockContent
        );
        setListCategory(mostFrequentCat);
        if (searchCategoryStacked?.length) {
          stacked = searchCategoryStacked;
        }
      }
      if (category) {
        const categoryStacked = contentfulService.getSimpleContentFromIDs(
          category?.xp?.Content?.stacked?.value,
          simpleBlockContent
        );
        setListCategory(category);
        if (categoryStacked?.length) {
          stacked = categoryStacked;
        }
      }
      setStackedContent(stacked || []);
    }
  }, [
    productList,
    filtersUpdated,
    categories,
    homeContent,
    simpleBlockContent,
    category,
    productListState?.filters?.query,
  ]);

  //set loading indicator when search is updating
  useEffect(() => {
    if (loading) {
      setProductsLoading(loading);
    }
  }, [loading]);

  //mobile panel
  const toggleMenu = useCallback(() => {
    if (menuOpen) {
      setMobileFacetFilters((filters) => []); // menu will close- so clear mobilefacets
    } else {
      let copyFacetFilers = cloneDeep(productListState?.filters?.facets); //menu will open- create copy of facet filters
      setMobileFacetFilters(copyFacetFilers || []);
    }
    setMenuOpen(!menuOpen);
  }, [menuOpen, productListState?.filters?.facets]);

  const handleCategoryFilterChange = useCallback(
    (categoryID: string) => (event: React.ChangeEvent, checked: boolean) => {
      let urlSearchParams = new URLSearchParams(location.search);

      //catFilter contains state of categories before checkbox clicked
      if (urlSearchParams.has("page")) {
        urlSearchParams.delete("page");
      }

      let categoryIdLowerCase = categoryID.toLowerCase().trim();
      if (
        !!find(
          productListState?.categoryFilters,
          (cid) => cid === categoryID || cid.toLowerCase().trim() === categoryIdLowerCase
        )
      ) {
        //if cat id matches catfilter, then take it out,
        let categoriesParams = urlSearchParams.getAll("c"); //urlSearchParam.delete method only deletes all instances of c param, as a work around , we have to get all values of c, and only add back the c params that we need
        urlSearchParams.delete(`c`);
        categoriesParams.forEach((categoryParam) => {
          if (categoryParam === CategoryService.BuildCategoryIdforQuery(categoryID)) {
            return;
          }
          urlSearchParams.append(`c`, categoryParam);
        });
      } else {
        // add that id to the query
        urlSearchParams.append("c", CategoryService.BuildCategoryIdforQuery(categoryID));
      }
      setMenuOpen(false);
      history.push(`${location.pathname}?${urlSearchParams.toString()}`);
    },
    [productListState?.categoryFilters, history, location]
  );

  const handleFacetChange = useCallback(
    (facet: string, checked: boolean) => {
      setLatestFacet(checked ? facet.split(":")[0] : undefined);
      let updatedFacets = (isDesktop ? productListState?.filters?.facets : [...mobileFacetFilters]) || [];
      if (checked) updatedFacets.push(facet);
      else {
        updatedFacets = filter(updatedFacets, (existingFacet: string) => existingFacet !== facet);
      }
      //format [ ["facetName: value"], ["facetName: value"]]
      //if mobile hold on to a copy of the facets filters - create when mobile menu is open / clear when button is selected or mobile panel is exited.
      let urlSearchParams = new URLSearchParams(location.search);
      setProductListState({
        ...productListState,
        filters: {
          ...productListState?.filters,
          facets: updatedFacets,
          page: urlSearchParams.has("page") ? undefined : productListState?.filters?.page,
        },
      });
      if (!isDesktop) {
        setMobileFacetFilters(updatedFacets);
      } else {
        if (urlSearchParams.has("page")) {
          urlSearchParams.delete("page");
          history.push(`${location.pathname}?${urlSearchParams.toString()}`);
        }
      }
      setFiltersUpdated(true);
    },
    [history, isDesktop, location.pathname, location.search, mobileFacetFilters, productListState]
  );

  const handleMobileFacetRefine = useCallback(() => {
    setProductListState({
      ...productListState,
      filters: {
        ...productListState?.filters,
        facets: mobileFacetFilters,
      },
    });
    toggleMenu();
  }, [mobileFacetFilters, productListState, toggleMenu]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    let urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set("page", value.toString());
    history.push(`${location.pathname}?${urlSearchParams.toString()}`);
  };

  const handleSiblingChange = useCallback(
    (catID: string) => (e: MouseEvent) => {
      setMenuOpen(false);
      history.push(CategoryService.BuildCategoryUrlFromId(catID, "c"));
    },
    [history]
  );

  const handleOnSaleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let updatedFilters = { ...productListState?.filters };
      if (productListState?.filters?.onSale) {
        updatedFilters.onSale = !updatedFilters.onSale;
      } else {
        updatedFilters.onSale = true;
      }
      setProductListState({
        ...productListState,
        filters: updatedFilters,
      });
    },
    [productListState]
  );

  const handleSortByChange = (option: any) => {
    let urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set("sort", option.index === sortOptions[0].index ? "" : option.index);
    history.push(`${location.pathname}?${urlSearchParams.toString()}`);
  };

  const filtersDisplay = useCallback(
    (isMobile: boolean) => {
      return (
        <Fragment>
          {!!(children?.length && children?.length > 0 && category) && (
            <Fragment>
              <Typography className={classes.filterLabel} variant="body2">
                Filter Results
              </Typography>
              <ChildrenCategories
                childrenCategories={children}
                currentCategory={category}
                categoryFilters={productListState?.categoryFilters}
                categorySelectChange={handleCategoryFilterChange}
              />
            </Fragment>
          )}
          <FormControlLabel
            className={classes.formControlLabelOnSale}
            control={
              <Checkbox
                className={classes.checkboxOnSale}
                size="small"
                checked={productListState?.filters?.onSale || false}
                onChange={handleOnSaleChange}
              />
            }
            label="Show Only Items On Sale"
          />
          {!isDesktop && (
            <DoubleOutlinedBtn
              buttonText="Refine & View Results"
              buttonProps={{ fullWidth: true, onClick: () => handleMobileFacetRefine() }}
            ></DoubleOutlinedBtn>
          )}
          <FacetList
            facets={facetList || {}}
            facetFilters={(isMobile ? mobileFacetFilters : productListState?.filters?.facets) || []}
            facetChangeCallback={handleFacetChange}
            searchQuery={productListState?.filters?.query}
          />

          {parentCategory && siblings && category && (
            <SiblingCategories
              parentCategory={parentCategory}
              siblings={siblings}
              currentCategory={category}
              handleChange={handleSiblingChange}
            ></SiblingCategories>
          )}
        </Fragment>
      );
    },
    [
      children,
      category,
      classes.filterLabel,
      classes.formControlLabelOnSale,
      classes.checkboxOnSale,
      productListState?.categoryFilters,
      productListState?.filters,
      handleCategoryFilterChange,
      handleOnSaleChange,
      isDesktop,
      facetList,
      mobileFacetFilters,
      handleFacetChange,
      parentCategory,
      siblings,
      handleSiblingChange,
      handleMobileFacetRefine,
    ]
  );

  return (
    <Fragment>
      <Container maxWidth="lg">
        <Typography variant="h2" className={classes.title}>
          {category?.Name}
        </Typography>
      </Container>

      <ListActionHeader handleSortSelection={handleSortByChange} handleToggleSortMenu={toggleMenu} />

      <Container maxWidth="lg">
        <BannerPromotion variant="productlist" category={listCategory} />
        <div className={classes.root}>
          <div className={classes.filters}>
            {isDesktop ? (
              filtersDisplay(false)
            ) : (
              <MobileFilters menuOpen={menuOpen} toggleMenu={toggleMenu}>
                {filtersDisplay ? filtersDisplay(true) : null}
              </MobileFilters>
            )}

            {stackedContent && (
              <Box>
                <StackedContentBlock content={stackedContent}></StackedContentBlock>
              </Box>
            )}
            {(category?.xp?.seo?.MetaDescription || category?.Description) && (
              <Accordion className={classes.seoAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="small" color="disabled" />}
                  aria-controls="panel1a-content-seo"
                  id="panel1a-header-seo"
                >
                  <Typography
                    className={classes.truncateTitle}
                    component="em"
                    color="textSecondary"
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: category?.xp?.seo?.MetaDescription || category?.Description,
                    }}
                  ></Typography>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.seoMetaDescription}
                  dangerouslySetInnerHTML={{
                    __html: category?.xp?.seo?.MetaDescription || category?.Description,
                  }}
                ></AccordionDetails>
              </Accordion>
            )}
          </div>

          <div className={classes.productList}>
            {productsLoading ? (
              <BachmansLoading text="Loading Products..." />
            ) : productList?.hits?.length ? (
              <ItemList listData={productList.hits} variant="product" category={listCategory} />
            ) : (
              <Box
                display="flex"
                my={isMobile ? 4 : 0}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <img height="200" src={svgNoResults} alt="illustration of woman watering plants" aria-hidden="true" />
                <Typography variant="h3" component="h2">
                  No Results For This Category
                </Typography>
              </Box>
            )}
            {!!(productList?.nbPages && productList?.nbPages > 1) && (
              <Pagination
                className={classes.pagination}
                count={productList?.nbPages}
                page={productList?.page}
                onChange={handlePaginationChange}
              ></Pagination>
            )}
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default ProductList;
