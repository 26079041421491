import { createStyles, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import BachmansCard, { CardVariant } from "../Shared/BachmansCard";

const useStyles = (props?: CarouselProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      // Channel Log: these changes perfect the "Featured Workshops & Event" carousel.
      // Tread very lightly with these styling changes because omitting even one line could break a carousel instance.
      root: {
        margin: theme.spacing(0),
        "& .slick-slide": {
          height: "auto !important",
          "& > div": {
            height: "100%",
          },
        },
        "& .slick-track": {
          marginLeft: props?.alignLeft ? 0 : "auto",
          marginRight: props?.alignLeft ? 0 : "auto",
          display: "flex",
          gap: theme.spacing(3),
        },
        "& button": {
          cursor: "pointer",
          "&.slick-next": {
            marginRight: theme.spacing(4),
            zIndex: 1,
            opacity: 0.25,
            transition: "opacity .25s",
            "&:hover": {
              opacity: 1,
            },
          },
          "&.slick-prev": {
            marginLeft: theme.spacing(4),
            zIndex: 1,
            opacity: 0.25,
            transition: "opacity .25s",
            "&:hover": {
              opacity: 1,
            },
          },
          "&.slick-next:before": {
            color: theme.palette.primary.dark,
            fontSize: "30px",
            opacity: 0.9,
          },
          "&.slick-prev:before": {
            color: theme.palette.primary.dark,
            fontSize: "30px",
            opacity: 0.9,
          },
        },
        [theme.breakpoints.up("sm")]: {
          marginLeft: "-16px",
          marginRight: "-16px",
          "& button:hover": {
            cursor: "pointer",
          },
        },
        "& .slick-dots": {
          position: "relative",
          bottom: -6,
          // bottom: props?.dotsInside ? theme.spacing(1) : theme.spacing(-5),
          "& li": {
            margin: 0,
            "&.slick-active button:before": {
              color: theme.palette.primary.dark,
              opacity: 1,
            },
            "& button:before": {
              opacity: 0.75,
              fontSize: 12,
              color: "grey",
            },
          },
        },
      },
      carouselCard: {
        display: "flex !important", // need to override the inline styles from slick-slider
        height: "100%",
      },
      title: {
        textAlign: "center",
        textTransform: "capitalize",
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
          textAlign: "left",
        },
      },
    })
  );

export interface BachmansCarouselProps extends CarouselProps {
  carouselData: any[];
  carouselTitle?: string;
  variant: CardVariant;
  featured?: boolean;
}

const BachmansCarousel: FunctionComponent<BachmansCarouselProps> = (props) => {
  const { carouselData, variant, settings, featured, carouselTitle } = props;
  const [currentItem, setCurrentItem] = useState<any>();
  const classes = useStyles(props)();

  useEffect(() => {
    if (carouselData && carouselData[0]) {
      setCurrentItem(carouselData[0]);
    }
  }, [carouselData]);

  const onCarouselChange = (oldIndex: number, newIndex: number) => setCurrentItem(carouselData[newIndex]);

  const getContent = (c: any): any => {
    if (variant !== "content") return;
    let curItem = settings?.slidesToScroll === 1 ? currentItem : c;
    return curItem?.fields ? curItem.fields : curItem;
  };

  return (
    <Fragment>
      {carouselTitle && (
        <Typography variant="h3" className={classes.title}>
          {carouselTitle}
        </Typography>
      )}
      <CarouselRoot
        settings={settings}
        dotsInside={props.dotsInside}
        alignLeft={props.alignLeft}
        onChange={onCarouselChange}
      >
        {carouselData.map((item, index) => {
          return (
            <div className={classes.carouselCard} key={index}>
              <BachmansCard
                variant={variant}
                productGroup={variant === "product" ? item : undefined}
                event={variant === "event" ? (settings?.slidesToScroll === 1 ? currentItem : item) : undefined}
                content={getContent(item)}
                featured={featured}
              />
            </div>
          );
        })}
      </CarouselRoot>
    </Fragment>
  );
};

export interface CarouselProps {
  settings?: any;
  dotsInside?: boolean;
  alignLeft?: boolean;
  onChange?: (oldIndex: number, newIndex: number) => void;
}

export const CarouselRoot: React.FunctionComponent<CarouselProps> = (props) => {
  const { settings } = props;
  const classes = useStyles(props)();
  const theme = useTheme();
  const responsiveSettings = (theme: Theme) => ({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    easing: "ease",
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
        },
      },
    ],
  });

  const sliderSettings = settings ? settings : responsiveSettings(theme);

  const handleChange = (ondIndex: number, newIndex: number) => {
    if (props.onChange) props.onChange(ondIndex, newIndex);
  };

  return (
    <Slider className={classes.root} {...sliderSettings} beforeChange={handleChange}>
      {props.children}
    </Slider>
  );
};

export default BachmansCarousel;
