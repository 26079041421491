import React from "react";
import { createStyles, makeStyles, Tab, Tabs, Theme, withStyles } from "@material-ui/core";
import { neutral } from "../../themes/colors";

export interface StyledTabProps {
  label: string;
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1 1 0px",
      textTransform: "none",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      color: neutral.text_white_bg,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      paddingLeft: "2px",
      paddingRight: "2px",
      [theme.breakpoints.up("sm")]: {
        flexGrow: 1,
        maxWidth: "unset",
      },
      "& .MuiTab-wrapper": {
        lineHeight: 1.2,
      },
    },
    selected: {
      backgroundColor: "white",
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} />);

export interface TabPanelProps {
  value: number;
  index: number;
  styleProps?: any;
}

const useStyles = (styleProps: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      tabPanelRoot: {
        backgroundColor: styleProps?.backgroundColor || "white",
        border: styleProps?.border || `1px solid ${theme.palette.grey[400]}`,
        marginTop: "-1px",
        ...styleProps,
      },
    })
  );

export const StyledTabPannel: React.FunctionComponent<TabPanelProps> = (props) => {
  const classes = useStyles(props.styleProps)();
  return (
    <div
      className={classes.tabPanelRoot}
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.children}
    </div>
  );
};
