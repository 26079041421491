import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { green } from '../../../themes/colors';
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
          width: "100%",
          position: 'absolute',
          top: '10px'
      },
      onSaleFlag: {
          backgroundColor: green.promo,
          textAlign: 'center',
          display: 'inline-block',
          padding: '4px',
          margin: '0 auto',
          float: 'right',
          fontWeight: 700
      }
  })
);

export interface OnSaleProps {
    saleText: string
}

const OnSaleFlag: FunctionComponent<OnSaleProps> = (props) => {
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <small className={classes.onSaleFlag}>{`${props.saleText}% Off`}</small> 
    </div>
    )   
}

export default OnSaleFlag;