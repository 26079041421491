import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme, Popover, ClickAwayListener } from "@material-ui/core";

interface StaticPageMobileNavProps {
  open: boolean;
  anchorEl: any;
  onClickAway: (event: React.MouseEvent<Document, MouseEvent>) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {},
    paperRoot: {
      minWidth: "40%",
    },
  })
);
const StaticPageMobileNav: FunctionComponent<StaticPageMobileNavProps> = (props) => {
  const classes = useStyle();
  const { open, anchorEl, children, onClickAway } = props;
  return (
    <Popover
      className={classes.popoverRoot}
      elevation={3}
      PaperProps={{
        square: true,
        classes: {
          root: classes.paperRoot,
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={open}
    >
      <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
    </Popover>
  );
};
export default StaticPageMobileNav;
