import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CircularProgress,
  Container,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { cloneDeep, filter, isEqual } from "lodash";
import ListLayoutWithFilters from "../Shared/ListLayoutWithFilters";
import ItemList from "../Shared/ItemList";
import eventService from "../../services/event.service";
import ListActionHeader from "../Shared/ListActionHeader";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { CatalogContext } from "../../providers/catalog";
import { Category } from "ordercloud-javascript-sdk";
import BannerPromotion from "../Shared/BannerPromotion";
import contentfulService from "../../services/contentful.service";
import { HomeContentContext, SimpleContentBlocksContext } from "../../providers/contentful";
import { SimpleContentModel } from "../../models/contentful/SimpleContent";
import StackedContentBlock from "../Shared/Content/StackedContentBlock";
import FacetList from "../Product/ProductList/FacetList";
import PurplePerks from "../Shared/PurplePerksCard";
import ChildrenCategories from "../Product/ProductList/ChildrenCategories";
import ProductService from "../../services/product.service";
import CategoryService from "../../services/category.service";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { useDispatch } from "react-redux";
import { neutral } from "../../themes/colors";
import stringService from "../../services/string.service";
import BachmansLoading from "../Shared/BachmansLoading";
import categoryService from "../../services/category.service";
import { CatalogRouteMatch } from "../Catalog/Catalog";
import { ProductListState } from "../Product/ProductList/ProductList";
import { AppDispatch } from "../../redux/store";

interface EventListProps {
  hideTitle?: boolean;
  eventList?: any;
  isContainer?: boolean; // doesn't have parent passing events
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: "inherit",
      fontSize: theme.typography.h1.fontSize,
      margin: theme.spacing(2, 0, 2, 0),
    },
    actionHeaderBanner: {
      marginBottom: theme.spacing(2),
    },
    link: {
      color: neutral.text_white_bg,
      margin: theme.spacing(0, 0, 0.25, 2),
    },
    filterSidebar: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
  })
);
interface EventFacetDictionary {
  [key: string]: string;
}
const EventFacetDict: EventFacetDictionary = {
  "Type:Free Events": "WorkshopsEvents_FreeEvents",
  "Type:Ticketed Events": "WorkshopsEvents_TicktedEvents",
};
const EventList: FunctionComponent<EventListProps> = ({ eventList, hideTitle, isContainer }) => {
  const classes = useStyle();
  const [events, setEvents] = useState(eventList);
  const [menuOpen, setMenuOpen] = useState(false);
  const { categories } = useContext(CatalogContext);
  const [currentCategory, setCurrentCategory] = useState<Category>({});
  const homeContent = useContext(HomeContentContext);
  const simpleBlockContent = useContext(SimpleContentBlocksContext);
  const [stackedContent, setStackedContent] = useState<SimpleContentModel[]>([]);
  const [facetFilters, setFacetFilters] = useState<string[]>([]);
  const [initialFacetState, setInitialFacetState] = useState<{}>();
  const [facets, setFacets] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [eventListState, setEventListState] = useState<ProductListState>();
  const componentMounted = useRef(true);
  const match = useRouteMatch<CatalogRouteMatch>();

  const topLevelCat: Category | undefined = useMemo(() => {
    if (categories) {
      let arr: string[] = [match.params.partOne];
      if (match.params.partTwo) {
        arr.push(match.params.partTwo);
      }
      const id = categoryService.BuildCategoryIdFromParts(arr);
      return categories.find((c) => c.ID === id);
    }
  }, [categories, match.params.partOne, match.params.partTwo]);

  const selectedL3Cats = useMemo(() => {
    if (categories) {
      const urlSearchParams = new URLSearchParams(location.search);
      const ids: string[] = urlSearchParams
        .getAll("c")
        ?.map((partThree: string) =>
          categoryService.BuildCategoryIdFromParts([match.params.partOne, match.params.partTwo as string, partThree])
        );
      return categories.filter((c) => ids.includes(c.ID));
    }
  }, [categories, location.search, match.params.partOne, match.params.partTwo]);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        visible: true,
        links: [],
        current: topLevelCat?.Name,
        dark: false,
      })
    );
  }, [dispatch, topLevelCat?.Name]);

  const children = useMemo(() => {
    if (categories) {
      return categories.filter((c) => {
        if (topLevelCat?.ID === "WorkshopsEvents") {
          return c.ParentID === "WorkshopsEvents_FreeEvents" || c.ParentID === "WorkshopsEvents_TicktedEvents";
        }
        return c.ParentID === topLevelCat?.ID;
      });
    }
  }, [topLevelCat?.ID, categories]);

  // const queryMatchesFilter = (locationSearch: string, filters: AlgoliaEventFilters) => {
  //   let queryParams = stringService.GetQueryParamsFromLocation(locationSearch);
  //   return filters.query === queryParams.query;
  // };

  //Contentful Data
  useEffect(() => {
    let filteredCat;
    let stacked = homeContent.data?.fields?.stackedContent?.map((home) => home.fields);
    if (categories) {
      filteredCat = categories.find((c) => c.ID === "WorkshopsEvents") as Category;
      setCurrentCategory(filteredCat);
    }
    if (filteredCat) {
      const categoryStacked = contentfulService.getSimpleContentFromIDs(
        filteredCat?.xp?.Content?.stacked?.value,
        simpleBlockContent
      );
      if (categoryStacked?.length) stacked = categoryStacked;
    }
    setStackedContent(stacked || []);
    return () => {};
  }, [categories, homeContent, simpleBlockContent]);

  //no events passed in get, grab them
  useEffect(() => {
    let categoryFilters: string[] = [];
    if (location.pathname.includes("/e/")) {
      const urlSearchParams = new URLSearchParams(location.search);
      categoryFilters = urlSearchParams
        .getAll("c")
        ?.map((partThree) =>
          categoryService.BuildCategoryIdFromParts([match.params.partOne, match.params.partTwo as string, partThree])
        );
    }
    const queryParams = stringService.GetQueryParamsFromLocation(location.search);
    const updatedListState = cloneDeep(eventListState) || {};
    updatedListState.filters = {
      ...updatedListState?.filters,
      page: queryParams.page,
      sort: queryParams.sort,
      query: queryParams.query,
    };
    if (topLevelCat?.ID) {
      updatedListState.filters.categoryid = topLevelCat?.ID;
    }
    updatedListState.categoryFilters = categoryFilters;
    if (!isEqual(updatedListState, eventListState)) {
      setEventListState(updatedListState);
    }
  }, [eventListState, location.pathname, location.search, match.params.partOne, match.params.partTwo, topLevelCat?.ID]);

  //sets event list
  useEffect(() => {
    const getEvents = async () => {
      setLoading(true);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      try {
        let eventInfo = await eventService.ListAsync(eventListState?.filters, eventListState?.categoryFilters);
        if (componentMounted.current) {
          if (!initialFacetState) {
            if (isContainer) {
              let allEvents = await eventService.ListAsync();
              setInitialFacetState(allEvents.facets);
            } else {
              setInitialFacetState(eventInfo.facets);
            }
            setFacets(eventInfo.facets);
          } else {
            let facets = ProductService.MapFacetResultsToInitFacetList(initialFacetState, eventInfo.facets);
            setFacets(facets);
          }
          setEvents(eventInfo?.events);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
        throw err;
      }
    };
    if (eventListState) {
      getEvents();

      //Validates updatedFilteredFacets from sessionStorage
      var updatedFilteredFacets = sessionStorage.getItem("updatedFilteredFacets");
      if (updatedFilteredFacets !== null) {
        let updatedFacets = JSON.parse(updatedFilteredFacets);
        if (updatedFacets.length > 0) {
          setFacetFilters(updatedFacets);
        }
      }
      //Validates updatedFilteredFacets from sessionStorage
    }
  }, [eventListState, initialFacetState, isContainer]);

  const handleSortByChange = useCallback((option: any) => {
    option.callback(option.index);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  // applies category filter
  const applyFacetFilters = useCallback(
    (facet) => {
      if (isContainer) {
        let url = "";
        setEvents(undefined);
        if (!facet.length) {
          url = "/e/workshops-events"; // add "/" before "e" otherwise path is relative and appends to the page
          history.replace({ pathname: `${url}` });
        } else {
          url = CategoryService.BuildCategoryUrlFromId(EventFacetDict[facet[0]], "e");
          history.push(url);
        }
      }
    },
    [setEvents, isContainer, history]
  );

  const handleFacetChange = useCallback(
    (facet: string, checked: boolean) => {
      let updatedFacets = [...facetFilters];
      if (checked) {
        updatedFacets = [facet];
      } else {
        updatedFacets = filter(updatedFacets, (existingFacet: string) => {
          return existingFacet !== facet;
        });
      }

      //Captures updatedFilteredFacets into sessionStorage
      sessionStorage.setItem("updatedFilteredFacets", JSON.stringify(updatedFacets));
      setFacetFilters(updatedFacets);
      applyFacetFilters(updatedFacets);
    },
    [facetFilters, applyFacetFilters]
  );

  const handleCategoryFilterChange = useCallback(
    (categoryID: string) => (event: React.ChangeEvent, checked: boolean) => {
      let urlSearchParams = new URLSearchParams(location.search);
      //catFilter contains state of categories before checkbox clicked
      const selectedIDs = selectedL3Cats?.map((c) => c.ID!);
      if (selectedIDs?.includes(categoryID)) {
        let categoriesParams = urlSearchParams.getAll("c");
        urlSearchParams.delete(`c`);
        categoriesParams.forEach((categoryParam) => {
          if (categoryParam === CategoryService.BuildCategoryIdforQuery(categoryID)) {
            return;
          }
          urlSearchParams.append(`c`, categoryParam);
        });
      } else {
        // add that id to the query
        urlSearchParams.append("c", CategoryService.BuildCategoryIdforQuery(categoryID));
      }
      setMenuOpen(false);
      setEvents(undefined);
      history.push(`${location.pathname}?${urlSearchParams.toString()}`);
    },
    [history, location.pathname, location.search, selectedL3Cats]
  );

  /***********************Components being added to list Layout.***********************/
  const filters = useMemo(() => {
    return (
      facets && (
        <Fragment>
          <Typography variant="body1">Filter Results</Typography>
          <FacetList
            facets={facets}
            facetFilters={facetFilters}
            expandedIndex={0}
            facetChangeCallback={handleFacetChange}
            hideFacetCheckbox={isContainer ? false : true}
          />
          {children && children.length > 0 && match.params.partTwo && (
            <ChildrenCategories
              childrenCategories={children}
              currentCategory={topLevelCat}
              categoryFilters={selectedL3Cats?.map((c) => c.ID)}
              categorySelectChange={handleCategoryFilterChange}
            />
          )}
          {!isContainer && (
            <List>
              <ListItem
                button
                onClick={() => {
                  history.push(`/e/workshops-events`);
                }}
                disableGutters
              >
                <ListItemText
                  className={classes.link}
                  primary={"See All Events"}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                ></ListItemText>
              </ListItem>
            </List>
          )}
        </Fragment>
      )
    );
  }, [
    facets,
    facetFilters,
    handleFacetChange,
    isContainer,
    children,
    match.params.partTwo,
    topLevelCat,
    selectedL3Cats,
    handleCategoryFilterChange,
    classes.link,
    history,
  ]);

  const stackedContentContainer = useMemo(() => {
    return (
      stackedContent && (
        <Fragment>
          <PurplePerks type="stacked" />
          <StackedContentBlock content={stackedContent}></StackedContentBlock>
        </Fragment>
      )
    );
  }, [stackedContent]);

  const titleContainer = useMemo(() => {
    return (
      <Container maxWidth="lg">
        <Typography className={classes.title} variant="h2">
          Workshop & Events
        </Typography>
      </Container>
    );
  }, [classes.title]);

  const actionHeader = useMemo(() => {
    return (
      <Fragment>
        <ListActionHeader handleSortSelection={handleSortByChange} handleToggleSortMenu={toggleMenu} />
        <Container className={classes.actionHeaderBanner} maxWidth="lg">
          <BannerPromotion category={currentCategory} variant="productlist" />
        </Container>
      </Fragment>
    );
  }, [currentCategory, classes.actionHeaderBanner, handleSortByChange, toggleMenu]);
  //
  const eventListContainer = useMemo(() => {
    if (events?.events) {
      setEvents(events.events);
      setFacets(events.facets);
    }
    return loading ? (
      <BachmansLoading text="Loading Events" />
    ) : events ? (
      <ItemList
        variant={"event"}
        listData={events.events ? events.events : events}
        category={currentCategory}
      ></ItemList>
    ) : (
      <CircularProgress />
    );
  }, [events, currentCategory, loading]);

  return (
    <Fragment>
      <ListLayoutWithFilters
        title={hideTitle ? null : titleContainer}
        actionHeader={actionHeader}
        filters={filters}
        stackedContent={stackedContentContainer}
        itemList={eventListContainer}
        pagination={<div></div>}
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
      />
    </Fragment>
  );
};

export default EventList;
