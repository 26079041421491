import React, { Fragment, useEffect, useState } from "react";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import VariantSelector from "../../Product/ProductDetail/VariantSelector";
import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import eventService from "../../../services/event.service";
import { groupBy, keys, sortBy } from "lodash";
import { useHistory } from "react-router-dom";
let sortTime = [];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventSelectorContainer: {
      display: "grid",
      gap: theme.spacing(1.5),
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat(auto-fit, minmax(190px, 1fr))",
        maxWidth: 275,
      },
    },
    formControl: {
      width: "80%",
      marginBottom: theme.spacing(2),
    },
    variantSelectorText: {
      fontWeight: 500,
      lineHeight: 1.4,
    },
    variantSelectorSubtitle: {
      fontSize: ".8em",
    },
  })
);

interface EventDisplayData {
  ID?: string;
  date?: string;
  timeRange?: string;
  ticketCount?: number;
}

export interface EventSlotSelectorProps {
  onDateChange: (val: string) => void;
  onTimeChange: (val: string) => void;
  selectedDate?: string;
  selectedTime?: string;
  eventGroup?: BuyerProduct[];
  event?: BuyerProduct;
  defaultEvent?: BuyerProduct;
}

const EventSlotSelector: React.FunctionComponent<EventSlotSelectorProps> = (props) => {
  const classes = useStyles();
  const { eventGroup, defaultEvent, event, onDateChange, onTimeChange, selectedDate, selectedTime } = props;
  const [eventDays, setEventDays] = useState<any>();
  const [availableDays, setAvailableDays] = useState<string[]>();
  const history = useHistory();

  useEffect(() => {
    const days = groupBy(
      eventGroup?.map((e) => ({
        ID: e.ID,
        date: eventService.GetFormattedEventDate(e), //eventDate(e),
        timeRange: e?.xp?.SpecsOptions?.Size,
        ticketCount:
          defaultEvent?.xp?.IsWorkshop && e?.Inventory?.QuantityAvailable && e?.Inventory?.QuantityAvailable > 0
            ? e?.Inventory?.QuantityAvailable
            : 0,
      })),
      "date"
    );
    setEventDays(days);
    const available = sortBy(keys(days), function (d) {
      return new Date(d);
    });
    setAvailableDays(available);
  }, [eventGroup, defaultEvent]);

  const getInventory = (event: BuyerProduct) => {
    const available =
      event?.Inventory?.QuantityAvailable && event?.Inventory?.QuantityAvailable > 0
        ? event.Inventory?.QuantityAvailable
        : 0;
    return available > 0 ? `${available} openings` : "Sold Out";
  };

  const handleDayChange = (event: React.ChangeEvent<any>) => {
    onDateChange(event.target.value);
  };

  const handleTimeChange = (event: React.ChangeEvent<any>) => {
    onTimeChange(event.target.value);
  };

  const selectVariant = (sku: string) => {
    history.push({
      search: `?sku=${sku}`,
    });
    // return sku;
  };
  if (eventDays) {
    Object.values(eventDays).map((item: any, index: any) => {
      item.sort(function (a: any, b: any) {
        return new Date("1970/01/01 " + a.timeRange).getTime() - new Date("1970/01/01 " + b.timeRange).getTime();
      });
    });
  }

  return (
    <Fragment>
      {eventGroup && eventGroup.length < 10 && (
        <Box className={classes.eventSelectorContainer}>
          {eventGroup?.length &&
            eventGroup?.map((e, i) => (
              <VariantSelector key={i} variant={e} selected={e.ID === event?.ID} selectVariant={selectVariant}>
                <Typography variant="body2" color="primary" className={classes.variantSelectorText}>
                  {eventService.GetFormattedEventDate(e)}
                </Typography>
                <Typography variant="body1" color="textPrimary" className={classes.variantSelectorText}>
                  {e?.xp?.SpecsOptions?.Size + " - " + e?.xp?.SpecsOptions?.Color}
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.variantSelectorSubtitle}>
                  {getInventory(e)}
                </Typography>
              </VariantSelector>
            ))}
        </Box>
      )}
      {eventGroup && eventGroup.length >= 10 && (
        <Box mt={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="daySelectLabel">Select a Date</InputLabel>
            <Select
              label="Select a Date"
              labelId="daySelectLabel"
              id="day-select"
              value={selectedDate}
              onChange={handleDayChange}
            >
              {availableDays?.map((day) => (
                <MenuItem value={day}>{day}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedDate && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="timeSelectLabel">Select Time</InputLabel>
              <Select
                label="Select Time"
                labelId="timeSelectLabel"
                id="time-select"
                value={selectedTime}
                onChange={handleTimeChange}
              >
                {eventDays &&
                  eventDays[selectedDate] &&
                  eventDays[selectedDate].map((selected: EventDisplayData) => (
                    <MenuItem value={selected.timeRange}>{selected.timeRange}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default EventSlotSelector;
