import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import { BachmansEvent, BachmansProduct } from "../../models/product";
import productService from "../../services/product.service";
import ProductService from "../../services/product.service";
import ProductPrice from "../Product/ProductPrice";
import ProductFlag from "../Product/ProductFlags/ProductFlag";
import OnSaleFlag from "../Product/ProductFlags/OnSaleFlag";
import CategoryService from "../../services/category.service";
import { Product } from "ordercloud-javascript-sdk";
import EventCard from "../Events/EventCard";
import ContentCard from "./Content/ContentCard";
import { ComplexContentModel } from "../../models/contentful/ComplexContent";
import noImg from "../../assets/images/noimg.jpg";
import { useHistory } from "react-router-dom";
import { isArray } from "lodash";
import Link from "../Shared/Link";
import GrownExclusive from "../Product/ProductFlags/GrownExclusive";
import BachmansCardMedia from "../Shared/BachmansCardMedia";
import { SessionContext } from "../../providers/session";
import moment from "moment-timezone";
import { BachmansCategory } from "../../models/Category";
import AlgoliaAnalytics from "search-insights";
import algolia from "../../services/algolia.service";

export type CardVariant = "product" | "category" | "content" | "event";
interface BachmansCardProps {
  variant: CardVariant;
  productGroup?: BachmansProduct[];
  category?: BachmansCategory;
  event?: Product | BachmansEvent;
  content?: ComplexContentModel;
  featured?: boolean;
  purchased?: boolean;
}
declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}
const BachmansCard: FunctionComponent<BachmansCardProps> = (props) => {
  const { variant, productGroup, category, event, content, featured, purchased } = props;
  return (
    <Fragment>
      {variant === "product" && productGroup && (
        <ProductCard productGroup={isArray(productGroup) ? productGroup : [productGroup]} />
      )}
      {variant === "category" && category && <CategoryCard category={category} />}
      {variant === "event" && event && (
        <EventCard event={event} featured={featured} purchased={purchased ? purchased : false} />
      )}
      {variant === "content" && content && <ContentCard content={content} />}
    </Fragment>
  );
};

export interface ProductCardProps {
  productGroup: BachmansProduct[];
}

const useStyles = (variant: CardVariant) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        flexFlow: "column nowrap",
        borderRadius: "0",
        border: `1px solid ${theme.palette.grey[200]}`,
        transition: "box-shadow .5s, transform .5s",
        width: "100%",
        "&:hover, &:active, &:focus": {
          boxShadow: theme.shadows[1],
          transform: "translateY(-2px)",
        },
        cursor: "pointer",
      },
      cardWrapper: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        display: "flex",
        flexFlow: "column nowrap",
        flexGrow: 1,
        "&:hover": {
          "&.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
            opacity: 0,
          },
        },
      },
      cardContent: {
        position: "relative",
        textAlign: variant === "product" ? "center" : "left",
        padding: theme.spacing(2),
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: variant === "product" ? "center" : "flex-start",
        flex: "1 1 auto",
        width: "100%",
        "&>h6": {
          lineHeight: "1.2",
        },
      },
      cardTitle: {
        marginTop: theme.spacing(2),
      },
      mb0: { marginBottom: theme.spacing(0.5) },
      mb3: { marginBottom: theme.spacing(3) },
      cardProductSelector: {
        "& p": {
          marginBottom: theme.spacing(1),
          color: theme.palette.text.secondary,
        },
      },
      link: {
        cursor: "pointer",
        display: "block",
      },
      removeLinkContainer: {
        cursor: "pointer",
        display: "block",
        width: "100%",
        border: `1px solid ${theme.palette.grey[200]}`,
      },
    })
  );

export interface CategoryCardProps {
  category: BachmansCategory;
}

interface WishListItem {
  ProductCode: string;
}

const ProductCard: FunctionComponent<ProductCardProps> = (props) => {
  const classes = useStyles("product")();
  const [onSale, setOnSale] = useState<string>();
  const [colorMap, setColorMap] = useState<any>();
  const { productGroup } = props;
  const colorVariants = productGroup.length > 1 && !!productGroup[0].xp.SpecsOptions.ColorVarient;
  const defaultProduct = productService.GetDefaultProduct(productGroup);
  const sizeVariants = productGroup.length > 1 && !!productGroup[0].xp.SpecsOptions.Size;
  const history = useHistory();
  const componentMounted = useRef(true);
  const { user, anonymous, patchUser } = useContext(SessionContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const checkProductXP = (key: string) => {
    return productGroup.filter((p) => p && p.xp && p.xp[key]).length > 0;
  };

  const isFeatured = checkProductXP("IsFeatured");

  const isAnyNew = (key: string) => {
    var dateNowMinus2Weeks = moment().subtract(4, "weeks");
    return productGroup.filter((p) => p && p.xp && p.xp[key] && dateNowMinus2Weeks < moment(p.xp[key])).length > 0;
  };

  const handleOnSaleChange = (value: string) => setOnSale(value);

  const routeToProduct = () => {
    const name = defaultProduct?.xp?.WebFacingProductTitle || defaultProduct?.Name;
    // Below code is to track is user is going to product from search result using quick search
    let urlSearchParams = new URLSearchParams(window.location.search);
    let currentQuery = urlSearchParams.get("queryid");

    let categoryFlag: any = urlSearchParams.get("categorypage");
    let userToken: any;
    AlgoliaAnalytics("init", {
      appId: process.env.REACT_APP_ALGOLIA_APP as string,
      apiKey: process.env.REACT_APP_ALGOLIA_KEY as string,
      useCookie: true,
    });

    // Get AlgoliaUser Token from here
    AlgoliaAnalytics("getUserToken", null, (err, newUserToken) => {
      if (err) {
        console.error(err);
        return;
      }
      userToken = newUserToken;
    });

    // Fetch Product Index and store it in variable
    const sortIndex = algolia.getQuickSearchProducts();
    let index = algolia.getProductIndex(sortIndex);

    const categorySortIndex = algolia.getCategoryProducts();
    let categoryIndex = algolia.getProductIndex(categorySortIndex);

    if (currentQuery !== undefined && currentQuery && currentQuery.length && categoryFlag == null) {
      AlgoliaAnalytics("clickedObjectIDsAfterSearch", {
        userToken: userToken, // required for Node.js
        eventName: "Product clicked from search result page",
        index: index.indexName,
        queryID: currentQuery,
        objectIDs: [defaultProduct?.ID as string],
        positions: [defaultProduct?.Position + 1],
      });
    } else if (currentQuery !== undefined && currentQuery && currentQuery.length && categoryFlag === "true") {
      AlgoliaAnalytics("clickedObjectIDsAfterSearch", {
        userToken: userToken, // required for Node.js
        eventName: "Product clicked from category page",
        index: categoryIndex.indexName,
        queryID: currentQuery,
        objectIDs: [defaultProduct?.ID as string],
        positions: [defaultProduct?.Position + 1],
      });
    }
    if (categoryFlag === "true") {
      history.push(
        `/p/${defaultProduct?.xp.ProductCode}/${name?.replaceAll(" ", "-")}?queryid=${currentQuery}&categorypage=true`,
        {
          path: window.location.pathname,
          search: window.location.search,
        }
      );
    } else {
      history.push(`/p/${defaultProduct?.xp.ProductCode}/${name?.replaceAll(" ", "-")}?queryid=${currentQuery}`, {
        path: window.location.pathname,
        search: window.location.search,
      });
    }

    // Code for tracking product clicks through DataLayer GA4
    if (defaultProduct && defaultProduct?.PriceSchedule && defaultProduct.PriceSchedule?.PriceBreaks) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            products: [
              {
                name: defaultProduct.Name, // Name or ID is required.
                id: defaultProduct.ID,
                position: defaultProduct.Position,
                price: defaultProduct.PriceSchedule?.PriceBreaks[0].Price,
              },
            ],
          },
        },
      });
    }
  };

  useEffect(() => {
    productService.GetColorMap().then((response) => {
      if (componentMounted.current) {
        setColorMap(response.data);
      }
    });
    return () => {
      componentMounted.current = false;
    };
  }, []);

  // DELETE
  const deleteWishListItem = (prodItem: any) => {
    if (!anonymous && user?.xp.WishList) {
      const currentCode = prodItem !== null && prodItem[0] ? prodItem[0].xp?.ProductCode : "";
      const updatedWishList = user.xp.WishList?.filter((w: WishListItem) => w.ProductCode !== currentCode);
      if (patchUser && updatedWishList) {
        patchUser({ xp: { WishList: updatedWishList } });
      }
    }
  };

  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea className={classes.cardWrapper} onClick={routeToProduct}>
        <BachmansCardMedia
          aspectRatio="1.42"
          alt={"Product Image for " + ProductService.GetProductName(defaultProduct)}
          image={
            defaultProduct?.xp?.Contentful?.Images && defaultProduct?.xp?.Contentful?.Images[0]
              ? defaultProduct?.xp?.Contentful?.Images[0].url
              : noImg
          }
        />
        {onSale && <OnSaleFlag saleText={onSale}></OnSaleFlag>}
        <CardContent className={classes.cardContent}>
          {isFeatured && <ProductFlag label="Featured" />}
          {!isFeatured && isAnyNew("ReleaseDate1") && <ProductFlag label="New" />}
          <Typography
            variant="h4"
            className={`${sizeVariants || (colorVariants && colorMap) ? classes.mb0 : classes.mb3} ${
              classes.cardTitle
            }`}
          >
            {ProductService.GetProductName(defaultProduct)}
          </Typography>
          <Box className={classes.cardProductSelector}>
            <Typography variant="body2">{sizeVariants && `${productGroup.length} options available`}</Typography>
            <Typography variant="body2">
              {colorVariants && colorMap && `${productGroup.length} colors available`}
            </Typography>
          </Box>
          <GrownExclusive productGroup={productGroup} />
          <ProductPrice productGroup={productGroup} onSaleChange={handleOnSaleChange}></ProductPrice>
        </CardContent>
        {window.location.pathname.includes("wishlist") && (
          <Button
            className={classes.removeLinkContainer}
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              deleteWishListItem(productGroup);
            }}
          >
            {isMobile ? `Remove` : `Remove From Wish List`}
          </Button>
        )}
      </CardActionArea>
    </Card>
  );
};

const CategoryCard: FunctionComponent<CategoryCardProps> = (props) => {
  const classes = useStyles("category")();
  const { category } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Link
      to={CategoryService.BuildCategoryUrlFromId(category.ID!, "c", category.Description!)}
      style={{ textDecoration: "none" }}
    >
      <Card className={classes.root} elevation={0}>
        <CardActionArea className={classes.cardWrapper}>
          <BachmansCardMedia
            aspectRatio="1.42"
            alt={"Category Image for " + category.Name}
            image={category.xp?.Contentful?.Images?.List?.url}
          />
          <CardContent className={classes.cardContent}>
            <Typography variant="subtitle1">{category.Name}</Typography>
            {!isMobile && <Typography variant="body2">{category?.xp?.ShortDescription}</Typography>}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

interface CardRootProps {
  imageUrl?: string;
  linkUrl?: string;
  onClick?: () => void;
}
export const CardRoot: React.FunctionComponent<CardRootProps> = (props) => {
  const classes = useStyles("product")();
  return (
    <Card
      className={classes.root}
      elevation={0}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.linkUrl ? (
        // TODO: choose link component dynamically based on interval vs external url:
        // https://github.com/remix-run/react-router/issues/1147#issuecomment-283684226
        <a className={classes.cardWrapper} href={props?.linkUrl || ""}>
          <BachmansCardMedia aspectRatio={"1.42"} alt="Event Card Image" image={props?.imageUrl} />
          {props.children}
        </a>
      ) : (
        <Fragment>
          <BachmansCardMedia aspectRatio={"1.42"} alt="Event Card Image" image={props?.imageUrl} />
          {props.children}
        </Fragment>
      )}
    </Card>
  );
};

export default BachmansCard;
