import React, { FunctionComponent, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { WorkingHours, HolidayHours, dayinfo } from "../../models/address";
import stringService from "../../services/string.service";
import moment from "moment-timezone";

type StoreHoursProps =
  | { variant: "Holiday"; workingHours?: never; holidayHours?: HolidayHours[] }
  | { variant: "Working"; workingHours?: WorkingHours; holidayHours?: never };

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1.5, 0),
    },
    title: {
      margin: theme.spacing(0.5, 0),
    },
    hours: {
      display: "flex",
    },
    time: {
      margin: theme.spacing(0, 1),
    },
    holiday: {
      width: "115px",
    },
    dayOfWeek: {
      width: "40px",
    },
  })
);

const StoreHours: FunctionComponent<StoreHoursProps> = (props) => {
  const classes = useStyle();
  const { variant, workingHours, holidayHours } = props;
  const [hoursDisplay, setHoursDisplay] = useState(false);

  useEffect(() => {
    //BAC-2790 dont display store hours if title begins with **
    if (variant === "Working" && workingHours) {
      return setHoursDisplay(workingHours.Title.substring(0, 2) !== "**");
    }
    setHoursDisplay(true);
  }, [workingHours, variant]);

  const displayHours = (day: dayinfo) => {
    let result = "";
    if (day?.StartTime?.length) {
      result += stringService.AmPmFormat(moment(day.StartTime, "h:mm").format("h:mm a"));
    }
    result += " - ";
    if (day?.EndTime?.length) {
      result += stringService.AmPmFormat(moment(day.EndTime, "hh:mm").format("h:mm a"));
    }
    return result;
  };

  const title = (variant: string) => {
    if (variant === "Holiday") {
      if (holidayHours?.length) return "Holiday Hours";
      return "";
    }
    if (["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]) {
      let timeDuration = "";
      //Jira Ticket BAS-3533 - FYI - May re-introduction special hours title in future
      // if (workingHours?.StartDate || workingHours?.EndDate) {
      //   timeDuration = `(
      //     ${workingHours?.StartDate ? moment(workingHours?.StartDate).format("MMM D") : ""} -
      //     ${workingHours?.EndDate ? moment(workingHours?.EndDate).format("MMM D") : ""} )`;
      // }
      //Jira Ticket BAS-3533 - FYI - May re-introduction special hours title in future
      return `${workingHours?.Title} ${timeDuration}`;
    }
  };

  return hoursDisplay ? (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {title(variant)}
      </Typography>

      {variant === "Holiday" &&
        holidayHours &&
        holidayHours.map((holiday: HolidayHours, index: number) => (
          <div className={classes.hours} key={index}>
            <Typography className={classes.holiday} variant="body1">
              {holiday.day}:
            </Typography>
            <Typography className={classes.time} variant="body1">
              {holiday.time}
            </Typography>
          </div>
        ))}

      {variant === "Working" &&
        workingHours &&
        Object.entries(workingHours).map(([key, value], index) => {
          if (["Title", "StartDate", "EndDate", "$$hashKey", "opened1", "opened2"].includes(key)) {
            return null;
          }
          return (
            <div className={classes.hours} key={index}>
              <Typography className={classes.dayOfWeek} variant="body1">
                {" "}
                {key}:{" "}
              </Typography>
              <Typography className={classes.time} variant="body1">
                {value.Closed ? "Closed" : displayHours(value)}
              </Typography>
            </div>
          );
        })}
    </div>
  ) : null;
};

export default StoreHours;
