import React, { FunctionComponent, useEffect, useState } from "react";
import { PayPalScriptProvider as ScriptProvider } from "@paypal/react-paypal-js";
import config from "../constants/app.constants";
import bachmansIntegrationsService from "../services/bachmansIntegrations.service";

const PayPalScriptProvider: FunctionComponent = ({ children }) => {
  const [clientToken, setClientToken] = useState(null);

  useEffect(() => {
    (async () => {
      const Braintreeresponse = await bachmansIntegrationsService.BraintreeToken();
      console.log(Braintreeresponse);
      setClientToken(Braintreeresponse?.clientToken || Braintreeresponse?.clientToken);
    })();
  }, []);

  return (
    <>
      {clientToken ? (
        <ScriptProvider
          options={{
            clientId: config.paypalClientID,
            dataClientToken: clientToken!,
            enableFunding: ["venmo"],
            commit: false,
            components: "buttons",
            intent: "authorize",
            vault: false,
            //buyerCountry: "US",
          }}
        >
          {children}
        </ScriptProvider>
      ) : (
        <></>
      )}
    </>
  );
};

export default PayPalScriptProvider;
