import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, Typography, Box, Button } from "@material-ui/core";
import { ArticlesContext } from "../../providers/contentful";
import { filter } from "lodash";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { ChevronLeft } from "@material-ui/icons";
import "../../themes/bootstrap-tables.css";
import BachmansLoading from "../Shared/BachmansLoading";
import SocialLinks from "../StaticPages/SocialLinks";
import $ from "jquery";
import { neutral } from "../../themes/colors";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      color: theme.palette.grey[800],
      overflowWrap: "break-word",
      "& iframe[src]": {
        width: "100%",
        aspectRatio: "16 / 9",
        [theme.breakpoints.up("md")]: {
          minHeight: 300,
        },
        [theme.breakpoints.up("lg")]: {
          minHeight: 500,
        },
      },
      "& h1": {
        fontWeight: 500,
        fontSize: 28,
      },
      "& h2": {
        fontWeight: 700,
        fontSize: 24,
        borderBottom: `double ${theme.palette.grey[300]}`,
      },
      "& h3": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 18,
        fontSmooth: "always",
      },
      "& h4": {
        fontWeight: 600,
        fontSize: 16,
      },
      "& h5": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 14,
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
      "& h6": {
        fontWeight: 500,
        textTransform: "uppercase",
        fontSize: 12,
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
      "& label, small": {
        fontSize: 12,
      },
      "& li, p, td, th": {
        fontSize: 14,
      },
      "& a": {
        color: theme.palette.primary.main,
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
        },
      },
      "& hr": {
        color: theme.palette.grey[200],
        marginTop: 16,
        marginBottom: 16,
        borderTop: "unset",
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      "& img": {
        maxWidth: "100%",
        height: "auto",
        display: "block",
      },
    },
    jumbotron: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      alignItems: "flex-end",
      gap: theme.spacing(2),
      height: "20vh",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(1.5),
      "& div": {
        flexShrink: 0,
        alignSelf: "flex-end",
      },
    },
    jumbotronTitle: {
      color: neutral.offwhite_bg,
      textShadow: ".5px .5px 2px rgba(0,0,0,.85)",
      fontWeight: 500,
      display: "flex",
      flexFlow: "row wrap",
      alignSelf: "flex-start",
      fontSize: "clamp(.75em, 5vw, 32px)",
    },
    footer: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      "& > *": {
        color: theme.palette.grey[700],
        textAlign: "center",
        lineHeight: 1.5,
        margin: 0,
        padding: 0,
      },
      "& h4": {
        fontSize: 16,
      },
      "& h5": {
        fontSize: 14,
      },
      "& h6": {
        fontSize: 12,
      },
    },
    noResults: {},
    image: {
      width: "100%",
      height: "30vh",
      display: "flex",
      padding: "12px",
      "flex-flow": "column nowrap",
      "align-items": "flex-end",
      left: 0,
      top: 0,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      zIndex: -1,
      "margin-bottom": "16px",
    },
  })
);

interface ArticleDetailProps {
  articleSlug: string;
  tag?: string;
  backToList: () => void;
}

const ArticleDetail: FunctionComponent<ArticleDetailProps> = (props) => {
  const classes = useStyle();
  const { articleSlug, tag, backToList } = props;
  const articlesContext = useContext(ArticlesContext);
  const [article, setArticle] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  // TODO: Style Content
  // TODO: figure out how classes applied to html in contentful can be applied.

  useEffect(() => {
    if (articleSlug && articlesContext.ready) {
      let selectedArticle = filter(articlesContext.data, (articleInfo) => articleInfo.fields.slug === articleSlug);
      if (selectedArticle) {
        setArticle(selectedArticle[0]);
      }
      setLoading(false);
    }
  }, [articleSlug, articlesContext]);

  useEffect(() => {
    //**************AutoParseArticleHtmlForCorrectingAnchorElements**************/
    if (article && article?.fields?.content) {
      var achors = $("li span a");
      $(achors).each(function () {
        var liRoot = $(this).parents("li");
        var $spanParent = $(this).parent();
        if ($($spanParent).length === 1) {
          var anchorText = liRoot.text();
          $(this).text(anchorText);
          var newHtmlWrapper = $("<div>").html($spanParent.html());
          liRoot.html(newHtmlWrapper[0]);
        }
      });
    }
    //**************AutoParseArticleHtmlForCorrectingAnchorElements**************/
  }, [article]);
  return loading ? (
    <BachmansLoading />
  ) : article ? (
    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        my={2}
        className={`bannerBox resourceHub ${classes.jumbotron}`}
        style={{ backgroundImage: "url(" + article?.fields?.image?.image + ")" }}
      >
        {/* <Button size="small" color="primary" variant="text" onClick={backToList} startIcon={<ChevronLeft />}>
          Back to all {tag} Guides
        </Button> */}

        {/* <SocialLinks seoInfo={article.fields.seo} /> */}
        <Typography variant="h1" className={`bannerTitle ${classes.jumbotronTitle}`}>
          {article?.fields?.title}
        </Typography>
      </Box>

      <ReactMarkdown
        escapeHtml={false}
        className={classes.content}
        plugins={[[gfm]]}
        children={article?.fields?.content}
      />
      <ReactMarkdown className={classes.footer} plugins={[gfm]} children={article?.fields?.footer?.fields?.content} />
    </Fragment>
  ) : (
    <div className={classes.noResults}>
      <Typography variant="h1">No article could be found</Typography>
    </div>
  );
};
export default ArticleDetail;
