import React, { FunctionComponent } from "react";
import {
  Container,
  createStyles,
  Drawer,
  IconButton,
  InputBase,
  List,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { neutral, purple } from "../../themes/colors";
import SearchResults from "./SearchResults";

interface MobileSearchMenuProps {
  menuOpen: boolean;
  toggleMenu: () => void;
  queryTerm: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputClear: () => void;
  products: any[];
  events: any;
  careInfo: any;
  suggestions: any;
  categories: any;
  contentfulCandA: any;
  clickAll?: () => string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > .MuiDrawer-paperAnchorBottom": {
        height: "100%",
      },
    },
    header: {
      display: "flex",
      padding: theme.spacing(1, 2),
      boxShadow: `0px -2px 10px rgba(0,0,0,0.5)`,
    },
    closeButton: {
      borderRadius: "0",
      background: purple.regular,
      color: "white",
      "&:hover": {
        color: purple.regular,
      },
    },
    input: {
      width: "100%",
      margin: theme.spacing(0, 0, 0, 1.5),
      padding: theme.spacing(0, 2),
      backgroundColor: theme.palette.grey[200],
      [theme.breakpoints.down("sm")]: {
        fontSize: 16, // prevent iOS zooming by explicitly setting input height to 16px.
      },
    },
    tabs: {
      "& .MuiTabs-flexContainer": {
        marginTop: theme.spacing(1),
        background: neutral.text_utility_bg,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
      },
      "& .MuiTab-root": {
        flexGrow: 1,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: 1.2,
        margin: theme.spacing(0, 0.5),
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: theme.palette.grey[400],
        borderBottom: "none",
      },
    },
    activeTab: {
      background: "white",
      borderBottom: "1px solid white !important",
      marginBottom: "-1px !important",
    },
  })
);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

const MobileSearchMenu: FunctionComponent<MobileSearchMenuProps> = (props) => {
  const classes = useStyle();
  const {
    menuOpen,
    toggleMenu,
    queryTerm,
    onInputChange,
    products,
    onInputClear,
    events,
    careInfo,
    suggestions,
    categories,
    contentfulCandA,
    clickAll,
  } = props;
  const [tabValue, setTabValue] = React.useState("products");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <Drawer className={classes.root} anchor={"bottom"} open={menuOpen} onClose={toggleMenu}>
      <div className={classes.header}>
        <IconButton className={classes.closeButton} onClick={toggleMenu} aria-label="close">
          <Close />
        </IconButton>

        <InputBase
          placeholder="Search"
          onChange={onInputChange}
          autoFocus={true}
          className={classes.input}
          value={queryTerm}
          endAdornment={
            queryTerm ? (
              <IconButton aria-label="delete" onClick={onInputClear}>
                <Close color="action" fontSize="small" />
              </IconButton>
            ) : (
              <Search color="action" />
            )
          }
        />
      </div>

      <Tabs onChange={handleChange} className={classes.tabs} centered textColor="primary" indicatorColor="primary">
        <Tab className={`${tabValue === "products" ? classes.activeTab : ""}`} value="products" label="Products"></Tab>
        <Tab className={`${tabValue === "events" ? classes.activeTab : ""}`} value="events" label="Events"></Tab>
        <Tab
          className={`${tabValue === "careinfo" ? classes.activeTab : ""}`}
          value="careinfo"
          label="Care, Advice & Information"
        ></Tab>
      </Tabs>
      <Container className="SearchMobileTabs">
        <TabPanel value={tabValue} index="products">
          <List>
            <SearchResults
              variant={"Query Suggestions"}
              results={suggestions.hits}
              toggleMenu={toggleMenu}
              queryTerm={queryTerm}
            />
          </List>
          <List className="categories">
            <SearchResults
              variant={"Categories"}
              results={categories.hits}
              toggleMenu={toggleMenu}
              queryTerm={queryTerm}
              clickAll={clickAll}
            />
          </List>
          <List>
            <SearchResults variant="Products" results={products} queryTerm={queryTerm} toggleMenu={toggleMenu} />
          </List>
        </TabPanel>
        <TabPanel value={tabValue} index="events">
          <List>
            <SearchResults variant="Events" results={events.events} queryTerm={queryTerm} toggleMenu={toggleMenu} />
          </List>
        </TabPanel>
        <TabPanel value={tabValue} index="careinfo">
          <List>
            <SearchResults
              variant="CareInfo"
              results={contentfulCandA.hits}
              queryTerm={queryTerm}
              toggleMenu={toggleMenu}
            />
          </List>
        </TabPanel>
      </Container>
    </Drawer>
  );
};

export default MobileSearchMenu;
