import React, { FunctionComponent } from "react";
import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { neutral } from "../../../themes/colors";

interface FiltersProps {
  toggleMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1 0 50%",
      padding: theme.spacing(0, 1, 0, 0),
      display: "flex",
      alignItems: "stretch",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      background: neutral.text_utility_bg,
      padding: theme.spacing(1),
      minHeight: 49, // magic number to match the same height as "Sort By"
    },
    textWrapper: {
      display: "flex",
      alignItems: "center",
    },
    iconCheckCircle: {
      marginLeft: theme.spacing(0.5),
      marginRight: "auto",
    },
  })
);

const Filters: FunctionComponent<FiltersProps> = (props) => {
  const classes = useStyle();
  const { toggleMenu } = props;
  return (
    <Button className={classes.wrapper} onClick={toggleMenu}>
      <Typography variant="body1">Filter</Typography>
      {/* <CheckCircle color="primary" fontSize="small" className={classes.iconCheckCircle} /> */}
      <Add color="primary" fontSize="small" />
    </Button>
  );
};

export default Filters;
