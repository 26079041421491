import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, createStyles, List, ListItem, ListItemText, makeStyles, Theme, useTheme } from "@material-ui/core";
import productService from "../../../services/product.service";
import { BachmansProduct } from "../../../models/product";
import dcfService from "../../../services/dcf.service";
import contentfulService from "../../../services/contentful.service";
import { map, values } from "lodash";
import { CatalogContext } from "../../../providers/catalog";
import stringService from "../../../services/string.service";
import { neutral } from "../../../themes/colors";
import { a11yProps, StyledTab, StyledTabPannel, StyledTabs } from "../../Shared/Tabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconImage: {
      maxWidth: "50px",
    },
    attributeList: {
      padding: 0,
      display: "inline-block",
      width: "100%",
    },
    attributeListItem: {
      marginRight: theme.spacing(2),
      maxWidth: "33%",
      padding: 0,
    },
    attributeTitle: {
      color: theme.palette.primary.main,
    },
    prodHtml: {
      color: neutral.text_white_bg,
    },
  })
);

interface ProductInfoProps {
  product?: null | BachmansProduct;
  group?: BachmansProduct[];
}

export interface AttributeImage {
  attribute: string;
  svg: string;
  title: string;
}

const ProductInfo: React.FunctionComponent<ProductInfoProps> = (props) => {
  const { product } = props;
  const { DCFcategories } = useContext(CatalogContext);
  const [value, setValue] = useState(0);
  const [warranty, setWarranty] = useState<string>();
  const [attributes, setAttributes] = useState<AttributeImage[]>();

  useEffect(() => {
    if (product && product != null && DCFcategories) {
      const war = dcfService.GetDcfSetting(product, "Warranty", DCFcategories);
      setWarranty(war);
      if (product && product.xp && values(product.xp.KeyAttributes).length) {
        contentfulService.getAssets({ "fields.description": "key-attribute-svg" }).then((response) => {
          const attributeImages = map(product.xp.KeyAttributes, function (val, key) {
            var svg = response.items.find((item) => item.fields.title === key);
            return {
              attribute: stringService.Camel2Dash(key, "space"),
              svg: svg ? svg.fields.file.url : "assets/images/Occasion.svg",
              title: val,
            };
          });
          setAttributes(attributeImages);
        });
      }
    }
  }, [product, DCFcategories]);

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => setValue(tab);

  return (
    <Fragment>
      <StyledTabs value={value} onChange={handleTabChange}>
        <StyledTab label="Description" {...a11yProps(0)} />
        {warranty && warranty.length && <StyledTab label="Order Details & Warranty" {...a11yProps(1)} />}
      </StyledTabs>
      <TabPanel value={value} index={0} {...props} attributes={attributes}></TabPanel>
      <TabPanel value={value} index={1} {...props} warranty={warranty}></TabPanel>
    </Fragment>
  );
};

interface TabPanelProps extends ProductInfoProps {
  index: any;
  value: any;
  warranty?: string;
  attributes?: AttributeImage[];
}

const TabPanel: React.FunctionComponent<TabPanelProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { value, index, group, product, warranty, attributes } = props;
  const defaultProduct = productService.GetDefaultProduct(group);

  return (
    <StyledTabPannel value={props.value} index={props.index}>
      {value === index && (
        <Box p={2}>
          {index === 0 ? (
            <Fragment>
              <List className={classes.attributeList}>
                {attributes &&
                  attributes.length &&
                  attributes.map((attribute, i) => (
                    <ListItem
                      key={i}
                      className={classes.attributeListItem}
                      style={{
                        borderRight: i !== attributes.length - 1 ? `1px solid ${theme.palette.primary.main}` : "none",
                      }}
                    >
                      <img src={attribute.svg} className={classes.iconImage} alt="not found"></img>
                      <ListItemText className={classes.attributeTitle} primary={attribute.title}></ListItemText>
                    </ListItem>
                  ))}
              </List>
              <div
                className={classes.prodHtml}
                dangerouslySetInnerHTML={{ __html: product?.xp?.WebDescription || defaultProduct?.xp?.WebDescription }}
              ></div>
            </Fragment>
          ) : (
            <Fragment>
              {warranty && <div className={classes.prodHtml} dangerouslySetInnerHTML={{ __html: warranty }}></div>}
            </Fragment>
          )}
        </Box>
      )}
    </StyledTabPannel>
  );
};
export default ProductInfo;
