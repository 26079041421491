import React, { FunctionComponent, useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import FullCalendar, { ViewContentArg, EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import EventService from "../../services/event.service";
import { useHistory } from "react-router";
import { FiberManualRecord } from "@material-ui/icons";
import { neutral } from "../../themes/colors";
interface EventCalendarProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      padding: theme.spacing(2, 0),
    },
    disabledEventColor: {
      color: neutral.grey_background,
    },
    loading: {
      position: "absolute",
      top: "60%",
      left: "50%",
      zIndex: 9999,
    },
  })
);

const EventCalendar: FunctionComponent<EventCalendarProps> = (props) => {
  const classes = useStyle();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const [spinner, setSpinner] = useState(true);
  const handleSize = (event: ViewContentArg): void => {
    let contentAPi = event.view.calendar;
    let viewDisplay = isMobile ? "listMonth" : "dayGridMonth";

    contentAPi.changeView(viewDisplay);
  };

  const calendarView = useCallback(() => {
    return window.innerWidth < 992 ? "listMonth" : "dayGridMonth"; //TODO: update the size
  }, []);

  const eventClick = useCallback(
    (eventInfo: EventClickArg) => {
      history.push(`/event/${eventInfo.event.id}`);
    },
    [history]
  );

  const loading = useCallback((isLoading) => {
    if (isLoading) {
      setSpinner(true);
    } else {
      setSpinner(false);
    }
  }, []);
  //TODO: GetCalendarEventsAsync gets invoked everytime next/prev is hit: look into a better way of handling that
  return (
    <Container maxWidth="lg" id="EVENT_CALENDAR">
      <Typography className={classes.title} variant="h1">
        Events Calendar
      </Typography>
      <Box display="flex">
        <FiberManualRecord color="primary" />
        <Typography variant="body1">Ticketed Events</Typography>
        <FiberManualRecord color="secondary" />
        <Typography variant="body1">Free Events</Typography>
        <FiberManualRecord className={classes.disabledEventColor} />
        <Typography variant="body1">Past Events</Typography>
      </Box>
      {spinner && <CircularProgress className={classes.loading} />}
      <FullCalendar
        displayEventTime={false}
        loading={loading}
        plugins={[dayGridPlugin, listPlugin]}
        editable={false}
        timeZone="UTC"
        initialView={calendarView()}
        headerToolbar={{ left: "prev", center: "title", right: "next" }}
        events={EventService.GetCalendarEventsAsync}
        windowResize={handleSize}
        eventClick={eventClick}
        eventDisplay="block"
      ></FullCalendar>
    </Container>
  );
};

export default EventCalendar;
