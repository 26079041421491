import { Box, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { Category } from "ordercloud-javascript-sdk";
import React, { FunctionComponent, useEffect, useContext, useState, Fragment } from "react";
import contentfulService from "../../services/contentful.service";
import BachmansCard from "./BachmansCard";
import { ComplexContentBlocksContext, HomeContentContext } from "../../providers/contentful";
import { ComplexContentModel } from "../../models/contentful/ComplexContent";
import InlineContentBlock from "./Content/InlineContentBlock";
import FullWidthContentBlock from "./Content/FullWidthContentBlock";
import svgNoResults from "../../assets/svg/no-results.svg";

const useStyles = (variant?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      cardWrapper: {
        display: "flex",
        "&>a": {
          display: "flex",
          flexGrow: 1,
        },
      },
      fullWidthContent: {
        padding: theme.spacing(0, 2),
      },
      link: {
        cursor: "pointer",
        display: "block",
        border: "1px solid #eeeeee",
      },
    })
  );

export interface ItemListProps {
  listData?: any[];
  category?: Category;
  // L1category?: Category;
  // L2category?: Category;
  variant: "product" | "category" | "event";
}

const ItemList: FunctionComponent<ItemListProps> = (props) => {
  const { listData, variant, category } = props;
  const classes = useStyles(variant)();
  const homeContent = useContext(HomeContentContext);
  const complexContentBlock = useContext(ComplexContentBlocksContext);
  const [inlineContent, setInlineContnet] = useState<ComplexContentModel | null>();
  const [fullWidthContent, setfullWidthContent] = useState<ComplexContentModel | null>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (variant === "product" || variant === "event") {
      if (category && category?.xp?.Content) {
        const inline = contentfulService.getComplexContentFromIDs(
          category?.xp?.Content?.inline?.value,
          complexContentBlock
        );
        const fullWidth = contentfulService.getComplexContentFromIDs(
          category?.xp?.Content?.full?.value,
          complexContentBlock
        );
        setInlineContnet(inline[0]);
        setfullWidthContent(fullWidth[0]);
      }
    }
  }, [variant, category, homeContent, complexContentBlock]);

  return (
    <Box my={3}>
      <Grid container spacing={isMobile ? 1 : 3}>
        {listData && listData.length > 0 ? (
          listData.map((item, i) => (
            <Fragment key={i}>
              <Grid item xs={6} sm={4} className={classes.cardWrapper}>
                <BachmansCard
                  variant={variant}
                  productGroup={variant === "product" ? item : undefined}
                  category={variant === "category" ? item : undefined}
                  event={variant === "event" ? item : undefined}
                />
              </Grid>
              {i === 4 && inlineContent && (
                <Grid item key={item.id} xs={12} sm={4}>
                  <InlineContentBlock inlineContent={inlineContent}></InlineContentBlock>
                </Grid>
              )}
              {i === 4 && fullWidthContent && !isMobile && (
                <Grid item xs={12} key={item.id}>
                  <FullWidthContentBlock fullWidthContent={fullWidthContent}></FullWidthContentBlock>
                </Grid>
              )}
            </Fragment>
          ))
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
            <img height="200" src={svgNoResults} alt="illustrsation of woman watering plants" aria-hidden="true" />
            <Typography variant="h1">No Results</Typography>
          </Box>
        )}
        {inlineContent && listData && listData.length <= 4 && (
          <Grid item xs={12} sm={4}>
            <InlineContentBlock inlineContent={inlineContent}></InlineContentBlock>
          </Grid>
        )}
        {fullWidthContent && listData && (listData.length <= 4 || isMobile) && (
          <Grid item xs={12}>
            <FullWidthContentBlock fullWidthContent={fullWidthContent}></FullWidthContentBlock>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ItemList;
