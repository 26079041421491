import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Entry } from "contentful";
import { cloneDeepWith, filter, first, groupBy, isUndefined, omitBy, pick, replace, sortBy } from "lodash";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StaticPageContext } from "../../providers/contentful";
import contentfulService from "../../services/contentful.service";
import { Add, Remove, RemoveSharp } from "@material-ui/icons";
import { neutral } from "../../themes/colors";
import queryString from "querystring";
import { StaticPageQueryParams, StaticPageUrlParams } from ".";
import stringService from "../../services/string.service";
import { PageTitles } from "./StaticPageConstants";
import StaticPageMobileNav from "./StaticPageMobileNav";
import ResourceDrawerButton from "./ResourceDrawerButton";
import ArticleTagNav from "./ArticleTagNav";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/store-hook";
import { AppDispatch } from "../../redux/store";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
export interface StaticPageRouteData {
  tag?: string;
  search?: string;
  page?: number;
  section?: string;
  rootslug?: string;
  branchslug?: string;
  leafslug?: string;
}

export interface ActiveEntry extends Entry<any> {
  active?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerNavbar: {
      padding: 0,
      // backgroundColor: neutral.offwhite_bg,
    },
    spaceBottom: {
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(3),
      },
    },
    AccordionSidebar: {
      padding: 0,
      backgroundColor: neutral.offwhite_bg,
    },
    menuItemHeading: {
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
    },
    menuItem: {
      padding: theme.spacing(0),
      cursor: "pointer",
      "&.Mui-selected": {
        padding: theme.spacing(0),
        cursor: "pointer",
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    menuListNested: {
      padding: 0,
    },
    menuItemNested: {
      paddingTop: 0,
      paddingBottom: 0,
      "&>a": {
        paddingLeft: theme.spacing(4),
      },
    },
    menuLink: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1.5, 2),
      lineHeight: "normal",
    },
    MenuList: {
      width: "100%",
      padding: 0,
      "&>.MuiList-root": {
        padding: 0,
      },
    },
    fullWidth: {
      width: "100%",
    },
    mobileMenuNested: {
      paddingLeft: theme.spacing(4),
    },
    mobileNavItemWrapper: {
      alignItems: "stretch",
      "& > div:first-of-type": {
        display: "flex",
        alignItems: "center",
      },
    },
    mobileNavItem: {
      width: "50%",
      flexGrow: 1,
      margin: "0",
    },
    mobileNavIcon: {
      display: "flex",
      justifyContent: "flex-end",
      flexGrow: 0,
      padding: 0,
      color: "inherit",
    },
  })
);

interface StaticPageNavigationProps {
  queryParams?: StaticPageQueryParams;
}

interface navItem {
  active: boolean;
  children?: any[];
  collapsed: boolean;
  fields: any;
  metadata: any;
  sys: any;
}

const StaticPageNavigation: React.FunctionComponent<StaticPageNavigationProps> = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const staticPages = useContext(StaticPageContext);
  const [navGroups, setNavGroups] = useState<any>();
  const [articleTags, setArticleTags] = useState<string[]>();
  const [bachmansBlog, setBachmansBlog] = useState<any>();
  const history = useHistory();
  const { section, rootSlug, branchSlug, leafSlug } = useParams<StaticPageUrlParams>();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const containerRef = useRef<any>();
  const breadCrumbState = useAppSelector((state) => state.breadcrumbs);
  const dispatch = useDispatch<AppDispatch>();

  const [expanded, setExpanded] = useState<any>();
  const handleChange = (panelIndex: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panelIndex : -1);
  };

  useEffect(() => {
    if (section && navGroups) {
      Object.keys(navGroups).map((key, index) => {
        if (section === key) {
          return setExpanded(index);
        }
        return key;
      });
    }
  }, [navGroups, section]);

  //builds up nav groups
  useEffect(() => {
    const section = stringService.GetFirstPath(window.location.pathname);

    const isNavItemActive = (item: any, root?: string, branch?: string, leaf?: string): boolean => {
      var active = !isUndefined(root) && rootSlug === root;
      if (active && branch) active = branchSlug === branch;
      if (active && leaf) active = leafSlug === leaf;
      item.collapsed = !active;
      return active;
    };

    const addChildPages = (parentPage: any) => {
      parentPage.active = isNavItemActive(parentPage, parentPage.fields.slug);
      const step1 = staticPages.data?.filter(
        (sp) => sp.fields.parentPage && sp.fields.navigation && sp.fields.parentPage.sys.id === parentPage.sys.id
      );
      const step2 = step1?.map((sp: ActiveEntry) => {
        sp.active = isNavItemActive(sp, parentPage.fields.slug, sp.fields.slug);
        return sp;
      });
      parentPage.children = sortBy(step2, (sp) => sp.fields.listOrder);
      return parentPage;
    };

    if (staticPages && staticPages.ready) {
      const topLevelNavItems = staticPages?.data?.filter((page) => !page.fields.parentPage && page.fields.navigation);
      const sortedItems = sortBy(topLevelNavItems, function (item) {
        return item.fields.listOrder;
      });
      const mapped = sortedItems.map((item) => addChildPages(item));
      const grouped = groupBy(mapped, (page) => page.fields.section);
      let result = section === "information" ? pick(grouped, "information") : (omitBy(grouped, "information") as any);
      // Code below is written to have plant,tree shrub link as the first list item
      let sortedInfoArr: any[] = [];
      result.information.forEach((value: any, index: any) => {
        if (value.fields?.slug === "plant-tree-and-shrub-library") {
          sortedInfoArr.unshift(value);
        } else {
          sortedInfoArr.push(value);
        }
      });
      result.information = sortedInfoArr;
      setNavGroups(result);
    }
  }, [staticPages, rootSlug, branchSlug, leafSlug]);
  //gets article data if needs and sets blog flag
  useEffect(() => {
    if (stringService.GetFirstPath(window.location.pathname) === "information") {
      contentfulService.getContentTypeAsync("article").then((response) => {
        const tags = response?.fields?.find((f) => f.id === "tags");
        setArticleTags(tags?.items?.validations[0]?.in);
        let blog = filter(tags?.items?.validations[0]?.in, (tagTitle) => tagTitle === PageTitles.BachmansBlog.title);
        if (blog) setBachmansBlog(blog);
      });
    } else {
      setArticleTags(undefined);
    }
  }, [window.location.pathname]);
  const updateSearchParams = (newParams: StaticPageRouteData) => {
    var routeObj: any = {
      pathname: `/information/${newParams.rootslug ? newParams.rootslug : "resource-hub"}`,
    };
    if (newParams.tag) {
      routeObj.search = queryString.stringify({
        tag: newParams.tag,
      });
    }
    history.push(routeObj);
  };
  const handleDropdown = (event: React.ChangeEvent<any>, child?: any) => {
    let openState = breadCrumbState.resourceDrawerOpen;
    dispatch(
      setBreadcrumbs({
        resourceDrawerOpen: !openState,
      })
    );
  };

  //used for collapsing and openining mobile nav static pages
  const handleMobileNavIcon = (page: any, indexNav: number) => (event: React.MouseEvent<any>) => {
    if (navGroups[section]) {
      let copyNav = cloneDeepWith(navGroups);
      copyNav[section][indexNav].collapsed = !navGroups[section][indexNav].collapsed;
      setNavGroups(copyNav);
    }
  };

  return (
    <div className={classes.containerNavbar}>
      {articleTags && (
        <div className={classes.spaceBottom}>
          <ArticleTagNav
            isMobile={isMobile}
            rootSlug={rootSlug}
            params={props.queryParams}
            availableTags={articleTags}
            updateParams={updateSearchParams}
            navGroups={navGroups}
          ></ArticleTagNav>
        </div>
      )}
      {/* References & Tools */}
      {navGroups &&
        !isMobile &&
        Object.keys(navGroups).map((key, index) => {
          const group: any[] = navGroups[key];
          return (
            <Accordion
              key={index}
              className={classes.AccordionSidebar}
              onChange={handleChange(index)}
              expanded={expanded === index}
            >
              {section !== "information" && key === "information" ? null : (
                <AccordionSummary expandIcon={expanded === index ? <Remove /> : <Add />}>
                  <Typography variant="h4" color="textSecondary" className={classes.menuItemHeading}>
                    {navGroups[key][0]?.active}
                    {key == "information"
                      ? "References & Tools"
                      : replace(stringService.CapitalizeString(key, ["-"]), "-", " ")}
                  </Typography>
                </AccordionSummary>
              )}
              <AccordionDetails className={classes.MenuList}>
                {section !== "information" && key === "information" ? null : (
                  <List className={classes.fullWidth}>
                    {/* Below code was was creating additional space in Resource hub side navigation (Reference and Tools Section) and didn't had any content has it has been removed as per request */}
                    {/* {bachmansBlog && key === "information" && !isMobile ? (
                      <ArticleTagNav
                        displayTagOnly={true}
                        isMobile={isMobile}
                        rootSlug={rootSlug}
                        params={props.queryParams}
                        availableTags={[bachmansBlog]}
                        updateParams={updateSearchParams}
                      ></ArticleTagNav>
                    ) : null} */}

                    {group &&
                      group.map((g, i) =>
                        g.fields?.slug === "on-sale-this-week" ? null : (
                          <Fragment key={i}>
                            <ListItem
                              disableGutters={true}
                              key={i}
                              selected={rootSlug === g.fields.slug}
                              className={classes.menuItem}
                            >
                              <ListItemLink
                                onClick={() => history.push(`/${key}/${g.fields.slug}`)}
                                className={classes.menuLink}
                              >
                                {g?.fields?.title}
                                {g.children?.length
                                  ? rootSlug === g?.fields?.slug && (
                                      <Fragment>
                                        {expanded ? (
                                          <RemoveSharp fontSize="small" color="action" />
                                        ) : (
                                          <Add fontSize="small" color="action" />
                                        )}
                                      </Fragment>
                                    )
                                  : null}
                              </ListItemLink>
                            </ListItem>
                            {g.children?.length > 0 && (
                              <Collapse in={rootSlug === g?.fields?.slug}>
                                <List className={classes.menuListNested}>
                                  {g.children.map((child: any, index: number) => (
                                    <ListItem
                                      disableGutters
                                      className={classes.menuItemNested}
                                      selected={branchSlug === child.fields?.slug}
                                      key={`${g.fields?.title}-${index}`}
                                    >
                                      <ListItemLink
                                        className={classes.menuLink}
                                        onClick={() => history.push(`/${key}/${g.fields.slug}/${child.fields?.slug}`)}
                                      >
                                        {child?.fields?.title}
                                      </ListItemLink>
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            )}
                          </Fragment>
                        )
                      )}
                  </List>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      {navGroups && isMobile && section !== "information" && (
        <Fragment>
          <ResourceDrawerButton handleClick={handleDropdown} />
          <StaticPageMobileNav
            open={dropDownOpen}
            anchorEl={() => containerRef.current}
            onClickAway={() => {
              setDropDownOpen((dropdown) => !dropdown);
            }}
          >
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {stringService.CapitalizeString(section)}
                </ListSubheader>
              }
            >
              {navGroups?.[section]?.map((p: navItem, indexNav: number) => (
                <div key={indexNav}>
                  <ListItem className={classes.mobileNavItemWrapper} button>
                    <ListItemText
                      primary={p?.fields?.title}
                      className={classes.mobileNavItem}
                      onClick={() => {
                        history.push(`/${section}/${p.fields.slug}`);
                        return setDropDownOpen((dropdown) => !dropdown);
                      }}
                    />
                    {p.children?.length ? (
                      <IconButton
                        className={classes.mobileNavIcon}
                        onClick={handleMobileNavIcon(p, indexNav)}
                        aria-label={p?.fields?.title}
                      >
                        {rootSlug === p?.fields?.slug ? <RemoveSharp /> : <Add />}
                      </IconButton>
                    ) : null}
                  </ListItem>
                  {p.children &&
                    p.children.length > 0 &&
                    p?.children?.map((childPage: navItem, indexChild: number) => (
                      <Collapse in={!p.collapsed} timeout="auto" unmountOnExit key={indexChild}>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            className={classes.mobileMenuNested}
                            onClick={() => {
                              history.push(`/${section}/${p.fields.slug}/${childPage.fields.slug}`);
                              return setDropDownOpen((dropdown) => !dropdown);
                            }}
                          >
                            {childPage.fields?.title}
                          </ListItem>
                        </List>
                      </Collapse>
                    ))}
                </div>
              ))}
            </List>
          </StaticPageMobileNav>
        </Fragment>
      )}
    </div>
  );
};

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export default StaticPageNavigation;
