import React, { Fragment, useContext, useEffect, useState } from "react";
import { alpha, Box, Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import BannerPromotion from "../../Shared/BannerPromotion";
import { Category } from "ordercloud-javascript-sdk";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import { Entry } from "contentful";
import { PageTitles } from "../StaticPageConstants";
import { StaticPageContext } from "../../../providers/contentful";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    jumbotron: {
      height: 350,
      marginTop: -36,
      overflow: "hidden",
      width: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    categoryName: {
      position: "relative",
      top: 0,
      color: "white",
    },
    bannerText: {
      color: "white",
    },
    slidePromotion: {
      textAlign: "center",
      background: alpha(theme.palette.primary.main, 0.9),
      padding: theme.spacing(4),
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      position: "relative",
      top: 40,
      [theme.breakpoints.up("md")]: {
        width: 400,
      },
    },
  })
);

export interface OnSaleHeaderProps {
  category?: Category;
}

const OnSaleHeader: React.FunctionComponent<OnSaleHeaderProps> = (props) => {
  const { category } = props;
  const [onSalePage, setOnSalePage] = useState<Entry<any>>();
  const staticPages = useContext(StaticPageContext);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (staticPages && staticPages.ready) {
      const page = staticPages?.data?.find((p) => p.fields.slug === PageTitles.OnSaleThisWeek.slug);
      setOnSalePage(page);
    }
  }, [staticPages]);

  const handleButtonClick = () => {
    if (onSalePage?.fields?.buttonUrl) {
      history.push(onSalePage?.fields?.buttonUrl);
    }
  };

  return (
    <Fragment>
      <Box
        className={classes.jumbotron}
        style={{
          backgroundImage: "url(" + category?.xp?.Contentful?.Images?.Banner?.url + ")",
        }}
      >
        <Container>
          <Typography variant="h1" className={classes.categoryName}>
            On Sale This Week
          </Typography>
          <div className={classes.slidePromotion}>
            <Typography className={classes.bannerText} variant="h4">
              {onSalePage?.fields?.bannerDescription}
            </Typography>
            <Typography className={classes.bannerText} variant="body1">
              {onSalePage?.fields?.shortDescription}
            </Typography>
            {onSalePage?.fields?.link && onSalePage?.fields?.bannerButtonText && (
              <DoubleOutlinedBtn
                buttonText={onSalePage?.fields?.bannerButtonText}
                buttonProps={{
                  variant: "outlined",
                  onClick: handleButtonClick,
                }}
                styleProps={{
                  color: "white",
                  borderColor: "white",
                  width: "65%",
                  margin: "0 auto",
                }}
              />
            )}
          </div>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <BannerPromotion variant="categories" category={category} />
      </Container>
    </Fragment>
  );
};

export default OnSaleHeader;
