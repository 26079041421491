import React, { Fragment } from "react";
import ReCAPTCHA from "react-google-recaptcha";
//import bachmansIntegrationsService from "../../services/bachmansIntegrations.service";

interface RecaptchaProps {
  onChange: (value: string) => void;
  onError: (value: string) => void;
}

const Recaptcha: React.FunctionComponent<RecaptchaProps> = (props) => {
  return (
    <Fragment>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
        onChange={(value) => {
          if (value) {
            // Uncomment this once we have the recaptcha account updated with new domains.

            // bachmansIntegrationsService.verifyRecaptcha(value).then((res: any) => {
            //   if(res.success) {
            //     props.onChange(value)
            //   } else {
            //     props.onError("Recaptcha validation failed")
            //   }
            // })
            props.onChange(value);
          } else {
            props.onError("Recaptcha validation failed");
          }
        }}
      />
    </Fragment>
  );
};

export default Recaptcha;
