import { isUndefined } from "lodash";
import { Address } from "ordercloud-javascript-sdk";
import { states } from "../constants/OcGeography";
import { Location } from "../components/StoreLocator/GoogleMaps";

const rad = (x: number) => {
  return (x * Math.PI) / 180;
};
const directionsUrlPrefix = "https://www.google.com/maps/dir/?api=1&destination_place_id=";
const getDistance = (p1: Location, p2: Location) => {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return parseFloat((d * 0.000621371).toFixed(1));
};

const getPosition = () => {
  return new Promise((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));
};

async function getCurrentPosition(defaultLocation: Location): Promise<Location> {
  if (!navigator) {
    return defaultLocation;
  } else {
    try {
      var position: any = await getPosition();
      return {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    } catch {
      return defaultLocation;
    }
  }
}

type AddressOption = "full" | "store" | "full-alt" | "fullOneLine" | "one-line";

const buildAddressLines = (address: any, option: AddressOption, nophone?: boolean, noStoreName?: boolean): string => {
  if (!address) return "";
  var result = [];
  if (option === "full" || option === "store") {
    //address name
    if (address.AddressName && !address.CompanyName && !nophone) result.push(address.AddressName);
    //address first/last
    if (option !== "store" && (address.FirstName || address.LastName)) {
      result.push(
        address.FirstName && !address.LastName
          ? address.FirstName
          : !address.FirstName && address.LastName
          ? address.LastName
          : address.FirstName + " " + address.LastName
      );
    }
    //company name
    if (address.CompanyName && (option !== "store" || (option === "store" && !noStoreName)))
      result.push(address.CompanyName);
    //street 1 (required)
    result.push(address.Street1);
    //street 1 (optional)
    if (address.Street2) result.push(address.Street2);
    //city, state zip
    result.push(address.City + ", " + address.State + " " + address.Zip);
    if (address.Phone && !nophone) result.push(address.Phone);
    return result.join("<br>");
  } else if (option === "full-alt") {
    if (address.CompanyName && !nophone) result.push(address.CompanyName);
    //street 1 (required)
    result.push(address.Street1);
    //street 1 (optional)
    if (address.Street2) result.push(address.Street2);
    var state;
    if (address.State.length > 2) state = states.find((s) => s.label === address.state)?.value;
    //city, state zip
    result.push(address.City + ", " + (state || address.State) + " " + address.Zip);
    if (address.Phone && !nophone) result.push(address.Phone);
    return result.join("<br/>");
  } else if (option === "fullOneLine") {
    if (address.FullAddress) return address.FullAddress;
    return (
      address.Street1 +
      (address.Street2 ? ", " + address.Street2 : "") +
      " " +
      address.City +
      ", " +
      address.State +
      " " +
      address.Zip
    );
  } else {
    return address.Street1 + (address.Street2 ? ", " + address.Street2 : "");
  }
};

const cType = (components: any[], componentType: string): string => {
  const compMatch = components.find((c) => c.types.indexOf(componentType) > -1);
  if (!isUndefined(compMatch)) {
    return compMatch.short_name;
  } else return "";
};

const mapGoogleToOrderCloud = (gItem: any, isCompany: boolean): Address => {
  var components = gItem.address_components;
  var streetNumber = cType(components, "street_number");
  var route = cType(components, "route");
  return {
    Phone: gItem.formatted_phone_number || null,
    CompanyName: isCompany ? gItem.name : null,
    Street1: streetNumber && route ? streetNumber + " " + route : "",
    City: cType(components, "locality"),
    State: cType(components, "administrative_area_level_1"),
    Country: cType(components, "country"),
    Zip: cType(components, "postal_code"),
  };
};

const service = {
  DirectionsUrlPrefix: directionsUrlPrefix,
  GetDistance: getDistance,
  GetCurrentPosition: getCurrentPosition,
  BuildAddressLines: buildAddressLines,
  MapGoogleToOrderCloud: mapGoogleToOrderCloud,
};

export default service;
