import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthDialogTab } from "../../components/Authentication/AuthDialog";

export interface BreadcrumbLink {
  path: string;
  label: string;
}

export interface BreadcrumbsState {
  visible: boolean;
  hideHome: boolean;
  links: BreadcrumbLink[];
  current: string;
  dark?: boolean;
  resourceDrawerOpen: boolean; /// didn't want to make a new slice for this one state.//probably a better way of handling this.
  authDialogOpen: boolean;
  authDialogState: AuthDialogTab;
}

const initialState: BreadcrumbsState = {
  visible: false,
  hideHome: false,
  links: [],
  current: "",
  resourceDrawerOpen: false,
  authDialogOpen: false,
  authDialogState: "login",
};

const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState: initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Partial<BreadcrumbsState>>) => {
      const {
        current,
        visible,
        hideHome,
        links,
        dark,
        resourceDrawerOpen,
        authDialogOpen,
        authDialogState,
      } = action.payload;
      if (links !== undefined) {
        state.links = links;
      }
      if (current !== undefined) {
        state.current = current;
      }
      if (visible !== undefined) {
        state.visible = visible;
      }
      state.hideHome = hideHome || false;
      if (dark !== undefined) {
        state.dark = dark;
      }
      if (resourceDrawerOpen !== undefined) {
        state.resourceDrawerOpen = resourceDrawerOpen;
      }
      if (authDialogState !== undefined) {
        state.authDialogState = authDialogState;
      }
      if (authDialogOpen !== undefined) {
        state.authDialogOpen = authDialogOpen;
        if (!state.authDialogOpen && state.authDialogState !== "login") state.authDialogState = "login";
      }
    },
  },
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
