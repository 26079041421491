import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";

import breadcrumbsReducer from "./slices/breadcrumbs";
import orderReducer from "./slices/order";

export const store = configureStore({
  reducer: {
    breadcrumbs: breadcrumbsReducer,
    order: orderReducer,
  }, // combines reducers if we pass an object
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>; // will allow typescript to know what types are on rootstate

export type AppThunk<R> = ThunkAction<R, RootState, unknown, Action<string>>;

export default store;
