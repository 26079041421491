import { Box, makeStyles, Theme, createStyles, Typography, IconButton, Tooltip, Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment } from "react";
import QuantityInput from "../../Shared/QuantityInput";
import { DeliveryTypes } from "../../../models/buyerXp";
import productService from "../../../services/product.service";
import { neutral } from "../../../themes/colors";
import { DynamicFeed } from "@material-ui/icons";
import { AseItem } from ".";
import Currency from "react-currency-formatter";
import { DrawerProduct } from "../../../models/aseDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
      "& p": {
        margin: 0,
      },
    },
    paper: {
      maxWidth: 250,
      padding: theme.spacing(1),
    },
    actionContainer: {
      width: "100%",
      alignItems: "center",
      marginTop: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 420,
      },
    },
    buttonContainer: {
      flexBasis: "75%",
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      height: 40,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    quantityContainer: {
      width: 75,
      "&>div": {
        width: "100%",
        height: "100%",
        "&>div": {
          height: "100%",
        },
      },
    },
    ToggleButtonBody: {
      flexBasis: "50%",
      textAlign: "left",
      borderRadius: 0,
      padding: theme.spacing(2),
      "& .MuiToggleButton-label": {
        alignSelf: "flex-start",
        color: theme.palette.text.secondary,
        display: "flex",
        alignItems: "flex-start",
        flexFlow: "column nowrap",
        pointerEvents: "none",
        "& .MuiTypography-subtitle1": {
          lineHeight: 1,
        },
      },
    },
    aseMenu: {
      backgroundColor: neutral.offwhite_bg,
      boxShadow: theme.shadows[1],
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(3),
      padding: theme.spacing(1, 2),
      "& .MuiIconButton-root": {
        marginLeft: "auto",
      },
    },
    flexGap1: {
      gap: theme.spacing(1),
    },
    flexGap2: {
      gap: theme.spacing(2),
    },
    textItalic: {
      fontStyle: "italic",
    },
    wishListContainer: {
      flexBasis: "25%",
      height: 40,
      "&>button": {
        width: "100%",
        height: "100%",
      },
    },
  })
);

export type DeliveryMethods = {
  [key in DeliveryTypes]?: DeliveryMethodData;
};

export interface DeliveryMethodData {
  enabled: boolean;
  leadDays: number;
}
export interface UpsellDrawerActionsProps {
  selectedItem: AseItem;
  onDrawerUpdate?: (drawerID: string, quantity: number) => void;
  onRemoveDrawerSelect: (product: DrawerProduct) => void;
}

const UpsellDrawerActions: React.FunctionComponent<UpsellDrawerActionsProps> = (props) => {
  const { selectedItem, onDrawerUpdate, onRemoveDrawerSelect } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (selectedQuantity: number) => {
    if (onDrawerUpdate) {
      if (selectedItem?.product.ID) {
        onDrawerUpdate(selectedItem?.product.ID, selectedQuantity);
      }
    }
  };

  return (
    <Fragment>
      {selectedItem && (
        <Box className={classes.aseMenu}>
          {selectedItem && (
            <div className={classes.quantityContainer}>
              <QuantityInput product={selectedItem.product} quantity={selectedItem.quantity} onChange={handleChange} />
            </div>
          )}
          <div>
            <Typography variant="body2" color="textSecondary">
              {selectedItem.product.DrawerName}
            </Typography>
            <Typography variant="subtitle2">{productService.ProductNameFilter(selectedItem?.product)}</Typography>
            {selectedItem?.product?.PriceSchedule?.PriceBreaks &&
              selectedItem?.product.PriceSchedule.PriceBreaks[0] &&
              selectedItem?.product.PriceSchedule.PriceBreaks[0].Price && (
                <Typography variant="subtitle2" color="primary">
                  <Currency quantity={selectedItem?.product.PriceSchedule.PriceBreaks[0].Price}>
                    {selectedItem?.product.PriceSchedule.PriceBreaks[0].Price}
                  </Currency>
                </Typography>
              )}
          </div>
          <Box ml="auto">
            {selectedItem?.product?.xp?.WebDescription && (
              <Fragment>
                <IconButton
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                >
                  <DynamicFeed color="action" fontSize="small" />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography variant="body2" color="textSecondary">
                    Product Description:
                  </Typography>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: selectedItem?.product?.xp?.WebDescription }}
                  />
                </Popover>
              </Fragment>
            )}
            <Tooltip title="Remove From Order" enterDelay={400}>
              <IconButton onClick={() => onRemoveDrawerSelect(selectedItem?.product!)} aria-label="Remove Menu Item">
                <CloseIcon color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export default UpsellDrawerActions;
