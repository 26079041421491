import React, { useCallback } from "react";
import { AseDrawer, DrawerProduct } from "../../../models/aseDrawer";
import {
  Avatar,
  Box,
  Button,
  createStyles,
  ListItemAvatar,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductPrice from "../ProductPrice";
import productService from "../../../services/product.service";
import { green, neutral } from "../../../themes/colors";
import { AseItem } from ".";
import { includes } from "lodash";

interface AseDrawerProps {
  aseDrawer: AseDrawer;
  selectedProducts?: AseItem[];
  onDrawerChange?: (drawer: AseDrawer) => void;
}

interface StyledMenuProps {
  open: boolean;
  id: string;
  keepMounted: true;
  anchorEl: any;
  theme: Theme;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonToggle: {
      color: neutral.grey_background,
      display: "block",
      width: "100%",
    },
    buttonText: {
      display: "flex",
      color: neutral.text_colored_bg,
      justifyContent: "space-between",
      alignItems: "center",
    },
    productName: {
      marginRight: "5px",
      fontSize: "12px",
      wordBreak: "break-word",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    aseMenuItem: {
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      "& button": {
        marginLeft: "auto",
      },
    },
    buttonAdd: {
      lineHeight: 1.5,
      minWidth: 50,
      fontSize: ".75rem",
      padding: theme.spacing(0.25, 0.5),
      color: neutral.strikethrough,
      borderColor: neutral.strikethrough,
    },
    added: {
      lineHeight: 1.5,
      minWidth: 50,
      fontSize: ".75rem",
      padding: theme.spacing(0.25, 0.5),
      color: neutral.offwhite_bg,
      backgroundColor: green.regular,
    },
    selectedProduct: {
      backgroundColor: green.promo,
      marginTop: "1px",
      padding: "5px",
    },
  })
);

const StyledMenu = withStyles((theme) => ({
  paper: {
    width: "100%",
    marginTop: 8,
    borderRadius: 0,
    border: `1px solid ${theme.palette.grey[300]}`,
    paddingRight: 0,
    maxHeight: 275,
    [theme.breakpoints.up("sm")]: {
      maxHeight: 200,
      width: "unset",
    },
  },
}))((props: StyledMenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    MenuListProps={{ disablePadding: true }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: "100%",
    whiteSpace: "pre-wrap",
    padding: 16,
    "&:focus": {
      backgroundColor: theme.palette.grey[200],
    },
    [theme.breakpoints.up("sm")]: {
      padding: 8,
      width: (document.getElementById("drawer")?.clientWidth || 100) * 1.5,
    },
  },
}))(MenuItem);

const UpsellDrawer: React.FunctionComponent<AseDrawerProps> = (props) => {
  const classes = useStyles();
  const { aseDrawer, onDrawerChange, selectedProducts } = props;
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProductSelect = (selectedProd?: DrawerProduct) => (event: React.MouseEvent) => {
    if (onDrawerChange && selectedProd) {
      selectedProd.DrawerName = aseDrawer.Name;
      onDrawerChange(selectedProd);
    }
    handleClose();
  };
  const isProductSelected = useCallback(
    (product: DrawerProduct | undefined) => {
      if (!product || !selectedProducts) return false;
      if (product.ID === "GIFTCARD") {
        return includes(
          selectedProducts.map((i) => `${i.product.ID}${i.product.Amount}`),
          `${product.ID}${product.Amount}`
        );
      } else {
        return includes(
          selectedProducts.map((i) => i.product.ID),
          product.ID
        );
      }
    },
    [selectedProducts]
  );

  const getButton = useCallback(
    (product: DrawerProduct | undefined) => {
      let text, selected;
      if (product) {
        selected = isProductSelected(product);
      }
      text = selected ? "Added" : "Select";

      return (
        <Button variant="outlined" className={selected ? classes.added : classes.buttonAdd} size="small">
          {text}
        </Button>
      );
    },
    [isProductSelected, classes.added, classes.buttonAdd]
  );

  const getDisplayString = () => {
    const selectionsFromCurrentDrawer = selectedProducts?.filter((p) => p.product.DrawerName === aseDrawer.Name);
    return selectionsFromCurrentDrawer?.length! > 1
      ? `${
          selectionsFromCurrentDrawer?.[0]?.product?.xp?.WebFacingProductTitle! ||
          selectionsFromCurrentDrawer?.[0]?.product?.Name!
        } + ${selectionsFromCurrentDrawer?.length! - 1} more`
      : selectionsFromCurrentDrawer?.[0]?.product?.xp?.WebFacingProductTitle! ||
          selectionsFromCurrentDrawer?.[0]?.product?.Name!;
  };

  return (
    <Box display="flex" id="drawer">
      <Button
        className={classes.buttonToggle}
        variant="contained"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <Box className={classes.buttonText}>
          <div>
            <Typography variant="body1" align="left" style={{ fontWeight: "bold" }}>
              {aseDrawer?.Name}
            </Typography>
            <Typography variant="body2" align="left">
              {selectedProducts?.length! > 0 ? getDisplayString() : "Select"}
            </Typography>
          </div>
          <ExpandMoreIcon />
        </Box>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        theme={theme}
        onClose={handleClose}
      >
        {aseDrawer?.products?.map((p, i) => (
          <div id="menuItemWrapper" key={i}>
            <StyledMenuItem
              className={classes.aseMenuItem}
              disabled={isProductSelected(p)}
              key={i}
              onClick={handleProductSelect(p)}
            >
              <ListItemAvatar style={{ minWidth: "50px" }}>
                <Avatar src={p.ImageUrl} variant="square"></Avatar>
              </ListItemAvatar>
              <Box display="flex" justifyContent="space-between" flexDirection="column">
                <Typography variant="h4" className={classes.productName} style={{ wordWrap: "break-word" }}>
                  {productService.ProductNameFilter(p)}
                </Typography>
                <ProductPrice product={p} alignment="left" fontSize="14px" />
              </Box>

              {getButton(p)}
            </StyledMenuItem>
          </div>
        ))}
      </StyledMenu>
    </Box>
  );
};

export default UpsellDrawer;
