import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  createStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import productService from "../../services/product.service";
import noImg from "../../assets/images/noimg.jpg";
import ProductPrice from "../Product/ProductPrice";
import { useHistory } from "react-router-dom";
import AlgoliaAnalytics from "search-insights";
import algolia from "../../services/algolia.service";
interface SearchResultProps {
  variant: "Products" | "Events" | "CareInfo" | "Query Suggestions" | "Categories";
  result: any;
  queryTerm?: string;
  toggleMenu: () => void;
  results: any;
  queryID?: any;
  index?: any;
  clickAll?: (params: any) => any;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    imgWrapper: {
      marginRight: theme.spacing(1),
    },
    imgAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      objectFit: "cover",
    },
    resultTitle: {
      fontSize: theme.typography.overline.fontSize,
      width: 200,
      overflowWrap: "normal",
    },
    careInfoTitle: {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: "normal",
    },
  })
);

const SearchResult: FunctionComponent<SearchResultProps> = (props) => {
  const classes = useStyle();
  const { variant, result, toggleMenu, queryTerm, results, queryID, index, clickAll } = props;
  const defaultProduct = productService.GetDefaultProduct(result);
  const [filteredCategories, setfilteredCategories] = useState<any>([]);
  let history = useHistory();

  const handleResultSelection = useCallback(
    (e) => {
      let userToken;

      // Algolia Init method for sending Events
      AlgoliaAnalytics("init", {
        appId: process.env.REACT_APP_ALGOLIA_APP as string,
        apiKey: process.env.REACT_APP_ALGOLIA_KEY as string,
        useCookie: true,
      });

      // Get AlgoliaUser Token from here
      AlgoliaAnalytics("getUserToken", null, (err, newUserToken) => {
        if (err) {
          console.error(err);
          return;
        }
        userToken = newUserToken;
      });

      // Fetch Product Index and store it in variable
      const sortIndex = algolia.getQuickSearchProducts();
      let index = algolia.getProductIndex(sortIndex);

      // Fetch CareInfor Index and store it in variable
      const careinfosortIndex = algolia.getAlgoliaContentIndex();
      let careinfoindex = algolia.getProductIndex(careinfosortIndex);

      // Fetch Indexes for Popular Searches and Category

      const querySuggestionsortIndex = algolia.getQuerySuggestionIndex();
      let querySuggestionindex = algolia.getProductIndex(querySuggestionsortIndex);

      switch (variant) {
        case "Products":
          AlgoliaAnalytics("clickedObjectIDsAfterSearch", {
            userToken: userToken, // required for Node.js
            eventName: "Product clicked from quick search",
            index: index.indexName,
            queryID: queryID,
            objectIDs: [result[0].ID],
            positions: [result[0].Position + 1],
          });
          history.push(
            `/p/${defaultProduct?.xp.ProductCode}/${defaultProduct?.Name?.replaceAll(" ", "-")}?queryid=${queryID}`,
            {
              path: "/search",
              search: queryTerm ? `?query=${encodeURIComponent(queryTerm)}` : undefined,
            }
          );
          break;
        case "Events":
          history.push(`/event/${result?.xp?.ProductCode}`, {
            path: "/search",
            search: queryTerm ? `?query=${encodeURIComponent(queryTerm)}` : undefined,
          });
          break;
        case "CareInfo":
          let pos = parseInt(e.currentTarget.getAttribute("data-index"));
          pos = pos + 1;
          AlgoliaAnalytics("clickedObjectIDsAfterSearch", {
            userToken: userToken, // required for Node.jsired for Node.js
            eventName: "CareInfo item",
            index: careinfoindex.indexName,
            queryID: queryID,
            objectIDs: [result.objectID],
            positions: [pos],
          });
          if (result?.section) {
            history.push(`/${result?.section}/${result.slug}`);
          } else {
            history.push(`/information/resource-hub/${result.slug}`);
          }
          break;
        case "Query Suggestions":
          let Suggestionpos = parseInt(e.currentTarget.getAttribute("data-index"));
          Suggestionpos = Suggestionpos + 1;
          AlgoliaAnalytics("clickedObjectIDsAfterSearch", {
            userToken: userToken, // required for Node.jsired for Node.js
            eventName: "Query Suggestions item clicked",
            index: querySuggestionindex.indexName,
            queryID: queryID,
            objectIDs: [result.objectID],
            positions: [Suggestionpos],
          });
          history.push(`/search?query=${result?.query}&queryid=${queryID}`, {
            path: "/search",
            search: queryTerm ? `?query=${encodeURIComponent(result?.query)}` : undefined,
          });
          if (clickAll) {
            clickAll(result?.query);
          }

          break;
        case "Categories":
          let Catpos = parseInt(e.currentTarget.getAttribute("data-index"));
          Catpos = Catpos + 1;
          AlgoliaAnalytics("clickedObjectIDsAfterSearch", {
            userToken: userToken, // required for Node.jsired for Node.js
            eventName: "Category item clicked",
            index: querySuggestionindex.indexName,
            queryID: queryID,
            objectIDs: [result.objectID],
            positions: [Catpos],
          });
          history.push(
            `/search?query=${encodeURIComponent(
              result?.products?.facets?.exact_matches?.Category[0]?.value
            )}&queryid=${queryID}`,
            {
              path: "/search",
              search: queryTerm
                ? `?query=${encodeURIComponent(result?.products?.facets?.exact_matches?.Category[0]?.value)}`
                : undefined,
            }
          );

          if (clickAll) {
            clickAll(encodeURIComponent(result?.products?.facets?.exact_matches?.Category[0]?.value));
          }
          break;
      }

      toggleMenu();
    },
    [
      variant,
      toggleMenu,
      history,
      defaultProduct?.xp?.ProductCode,
      defaultProduct?.Name,
      queryTerm,
      result?.xp?.ProductCode,
      result?.fields?.section,
      result?.fields?.slug,
    ]
  );
  // let filteredCatArr : any=[];
  // useEffect(() => {
  //  if(variant == "Categories"){
  //   results.map(
  //     (result: any, index: number) => {
  //       filteredCatArr.push(result?.products?.facets?.exact_matches?.Category[0]?.value);
  //     })

  //   filteredCatArr = Array.from(new Set(filteredCatArr));
  //   setfilteredCategories(filteredCatArr);
  //  }
  // }, [variant])
  // console.log("Variant------>",variant);
  // console.log("Result------->",result);
  // commenting this unnecessary useEffect that does not exectute anything and overheads the js exectuion
  // useEffect(() => {}, [result]);
  return (
    <ListItem
      button
      className={classes.root + " " + variant}
      divider
      disableGutters
      alignItems="center"
      onClick={handleResultSelection}
      data-index={index}
    >
      {variant === "Categories" && result?.products ? (
        <ListItemText primary={result?.products?.facets?.exact_matches?.Category[0]?.value} />
      ) : null}
      {variant === "Query Suggestions" && result?.query && <ListItemText primary={result?.query} onClick={clickAll} />}
      {variant === "Products" && (
        <ListItemAvatar className={classes.imgWrapper}>
          <Avatar
            className={classes.imgAvatar}
            variant="square"
            alt={!defaultProduct ? result.Name : null}
            src={defaultProduct?.xp?.Contentful?.Images[0].url ? defaultProduct?.xp?.Contentful?.Images[0].url : noImg}
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <Typography variant="h4" className={classes.resultTitle}>
            {productService.GetProductName(defaultProduct)}
            {!defaultProduct ? result.Name : null}
          </Typography>
        }
        secondary={
          <React.Fragment>
            {variant === "CareInfo" ? (
              <Typography className={classes.careInfoTitle + " " + "careinfo-" + index} variant="h5">
                {result.title}
              </Typography>
            ) : null}
            {["Products", "Events"].includes(variant) && (
              <span>{defaultProduct ? `Item # ${defaultProduct.ID}` : ""}</span>
            )}
          </React.Fragment>
        }
      />
      {["Products", "Events"].includes(variant) && (
        <React.Fragment>
          {variant === "Products" && <ProductPrice productGroup={result}></ProductPrice>}
          {variant === "Events" &&
            (result.xp.IsWorkshop ? <ProductPrice product={result}></ProductPrice> : <span>{"Free to attend"}</span>)}
        </React.Fragment>
      )}
    </ListItem>
  );
};

export default SearchResult;
