import React, { FunctionComponent } from "react";
import { ClickAwayListener, createStyles, makeStyles, Popover, Theme } from "@material-ui/core";

interface SearchDropdownProps {
  anchorEl: any;
  open: boolean;
  onClickAway: (event: React.MouseEvent<Document, MouseEvent>) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paperRoot: {},
  })
);

const SearchDropdown: FunctionComponent<SearchDropdownProps> = (props) => {
  const classes = useStyle();
  const { anchorEl, open, children, onClickAway } = props;
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      className={classes.root}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      elevation={3}
      PaperProps={{
        square: true,
        classes: {
          root: classes.paperRoot,
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
    </Popover>
  );
};

export default SearchDropdown;
