import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  makeStyles,
  Switch,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowBackIos, InfoOutlined } from "@material-ui/icons";
import React, { Fragment, FunctionComponent, useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/store-hook";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { AppDispatch } from "../../redux/store";
import ShipmentList from "./ShipmentList";
import ShoppingCartHeading from "./ShoppingCartHeading";
import {
  BachmansShipmentWithItems,
  BachmansShipmentWithLineItems,
  ValidatedBachmansShipmentWithLineItems,
} from "../../models/shipment";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import Link from "../Shared/Link";
import { BuyerProduct, LineItems, Orders, Products } from "ordercloud-javascript-sdk";
import { calculateOrder, initOrder, patchLineItems, patchOrder } from "../../redux/slices/order";
import OrderSummary from "./OrderSummary";
import ConfirmDialog from "../Shared/ConfirmDialog";
import visa from "../../assets/svg/payment-icons/mono/visa.svg";
import mastercard from "../../assets/svg/payment-icons/mono/mastercard.svg";
import discover from "../../assets/svg/payment-icons/mono/discover.svg";
import amex from "../../assets/svg/payment-icons/mono/amex.svg";
import paypal from "../../assets/svg/payment-icons/mono/paypal.svg";
import venmo from "../../assets/svg/payment-icons/mono/venmo.svg";
import theme from "../../themes/main";
import { AuthDialogTab } from "../Authentication/AuthDialog";
import { cloneDeep, flatten, isUndefined, sumBy, uniq } from "lodash";
import { DeliveryTypes } from "../../models/buyerXp";
import { CatalogContext } from "../../providers/catalog";
import cartService from "../../services/cart.service";
import { useHistory } from "react-router";
import { Alert } from "@material-ui/lab";
import deliveryService from "../../services/delivery.service";
import { SessionContext } from "../../providers/session";

interface CartIndexProps {
  open: boolean;
  onClose: () => void;
  state?: AuthDialogTab;
}

declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slide: {
      height: 150,
      position: "relative",
      [theme.breakpoints.up("md")]: {
        height: 350,
      },
    },
    cartMain: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(1.5),
      marginBottom: theme.spacing(5),
    },

    switchMultipleRecipient: {
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        "& .MuiTypography-root": {
          width: "min-content",
          lineHeight: 1.25,
        },
      },
      "& .MuiFormControlLabel-label": {
        "& div": {
          display: "flex",
          alignItems: "center",
          "& svg": {
            fontSize: 12,
            marginLeft: 4,
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    cartShipmentHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    categoryName: {
      position: "relative",
      top: 60,
      color: "white",
      [theme.breakpoints.up("md")]: {
        top: 200,
      },
    },
    cartAlert: {
      borderRadius: 0,
      display: "flex",
      alignItems: "center",
    },
    emptyCart: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    paymentBox: {
      display: "grid",
      placeItems: "center",
      gap: theme.spacing(1),
      gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr) )",
      [theme.breakpoints.up("md")]: {
        width: "90%",
        gridTemplateColumns: "repeat(6, minmax(30px, 1fr) )",
      },
      margin: "16px auto",
      "& img": {
        maxWidth: 40,
        width: "100%",
        height: "100%",
      },
    },
    divider: {
      margin: theme.spacing(5, 0, 4, 0),
      height: "1px",
    },
    iconExtraSmall: {
      fontSize: 16,
    },
    buttonClearCart: {
      padding: theme.spacing(0, 3),
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[700],
      "&:hover, &:focus": {
        color: "white",
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
    },
    center: {
      marginTop: "auto",
      marginBottom: "auto",
    },
    loadingOverlay: {
      backgroundColor: alpha(theme.palette.grey[100], 0.75),
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      zIndex: 2,
      top: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3),
    },
    curbsideTooltip: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.secondary,
      boxShadow: theme.shadows[1],
      padding: theme.spacing(3),
      fontFamily: theme.typography.body1.fontFamily,
      maxWidth: 400,
    },
  })
);

export type CartState = {
  [key in DeliveryTypes]?: ShipMethodState;
};
export interface ShipMethodState {
  shipments: ValidatedBachmansShipmentWithLineItems[];
  shipToMultiple: boolean;
}

const Cart: FunctionComponent<CartIndexProps> = ({ open, onClose, state }) => {
  const classes = useStyles();
  const { anonymous } = useContext(SessionContext);
  const currentOrder = useAppSelector((state) => state.order);
  const [productsOnOrder, setProductsOnOrder] = useState<BuyerProduct[]>();
  const dispatch = useDispatch<AppDispatch>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [cartLoading, setCartLoading] = useState<boolean>(true);
  const { DCFcategories } = useContext(CatalogContext);
  const [cartState, setCartState] = useState<CartState>();
  const [confirmResetDialog, setConfirmResetDialog] = useState<DeliveryTypes>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const [isEgiftcardavail, setisEgiftcardavail] = useState<any>();

  let shipToMultiple = false;
  useEffect(() => {
    let cartObject;
    if (currentOrder?.shipments?.some((item) => item.ToAddress === null)) {
      sessionStorage.removeItem("checkoutActiveUrlParams");
    }
    if (currentOrder && currentOrder?.shipments && currentOrder?.order) {
      let checkoutCartobj = currentOrder?.shipments.map(function (item: any) {
        return item.LineItems.map(function (i: any) {
          if (i) {
            return {
              item_name: i.Product.Name,
              item_id: i.Product.ID,
              price: i.UnitPrice,
              quantity: i.Quantity,
            };
          }
        });
      });
      cartObject = checkoutCartobj.reduce((acc, curr) => acc.concat(curr), []);
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
          currency: "USD",
          value: currentOrder?.order.Subtotal,
          items: cartObject,
        },
      });
    }
  }, [currentOrder, currentOrder?.shipments]);

  useEffect(() => {
    const multipleAddresses = (shipper: string): boolean => {
      let shipmentsByMethod: BachmansShipmentWithLineItems[];
      if (["FTD", "TFE"].includes(shipper || "")) {
        shipmentsByMethod = currentOrder?.shipments?.filter((s) => ["FTD", "TFE"].includes(s.Shipper || "")) || [];
      } else {
        shipmentsByMethod = currentOrder?.shipments?.filter((s) => s.Shipper === shipper) || [];
      }
      return shipmentsByMethod?.length > 1;
    };

    (async () => {
      if (DCFcategories && currentOrder?.shipments) {
        setCartLoading(true);
        const productIDs: string[] = [];
        currentOrder.shipments?.forEach((shipment) => {
          shipment.LineItems.forEach((item) => productIDs.push(item.ProductID));
        });
        const products = await Products.List({ filters: { ID: productIDs.join("|") }, pageSize: 100 });
        setProductsOnOrder(products.Items);
        const validatedShipments = await cartService.ValidateAllShipments(
          DCFcategories,
          currentOrder.shipments,
          products.Items
        );
        const state: CartState = {};
        validatedShipments?.forEach((shipment) => {
          if (shipment.Shipper === "UPS") {
            state.UPS = {
              shipToMultiple: multipleAddresses(shipment.Shipper),
              shipments: [...(state?.UPS?.shipments || []), shipment],
            };
          } else if (shipment.Shipper === "USPS") {
            state.USPS = {
              shipToMultiple: multipleAddresses(shipment.Shipper),
              shipments: [...(state?.USPS?.shipments || []), shipment],
            };
          } else if (shipment.Shipper === "LocalDelivery") {
            state.LocalDelivery = {
              shipToMultiple: multipleAddresses(shipment.Shipper),
              shipments: [...(state?.LocalDelivery?.shipments || []), shipment],
            };
          } else if (shipment.Shipper === "InStorePickUp") {
            state.InStorePickUp = {
              shipToMultiple: multipleAddresses(shipment.Shipper || ""),
              shipments: [...(state?.InStorePickUp?.shipments || []), shipment],
            };
          } else if (shipment.Shipper === "CurbsidePickUp") {
            state.CurbsidePickUp = {
              shipToMultiple: multipleAddresses(shipment.Shipper || ""),
              shipments: [...(state?.CurbsidePickUp?.shipments || []), shipment],
            };
          } else if (shipment.Shipper === "Email") {
            state.Email = {
              shipToMultiple: multipleAddresses(shipment.Shipper || ""),
              shipments: [...(state?.Email?.shipments || []), shipment],
            };
          } else if (["FTD", "TFE"].includes(shipment.Shipper || "")) {
            state.Wired = {
              shipToMultiple: multipleAddresses(shipment.Shipper || ""),
              shipments: [...(state?.Wired?.shipments || []), shipment],
            };
          }
        });
        setCartState(state);
      }
      setTimeout(() => {
        setCartLoading(false);
        document.body.removeAttribute("style"); // enable page scrolling after cartLoading
      }, 700);
    })();
  }, [currentOrder.shipments, DCFcategories]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        visible: true,
        current: "Cart",
      })
    );
  }, [dispatch]);

  // get available stores
  useEffect(() => {
    if (cartLoading) {
      document.body.style.overflow = "hidden"; // disable page scrolling while cartLoading
      window.scrollTo(0, 0);
    }
  }, [cartLoading]);

  const cartCount = useMemo(() => {
    return currentOrder?.order?.LineItemCount || 0;
  }, [currentOrder]);

  const checkoutActivePane =
    !anonymous && !isUndefined(sessionStorage.getItem("checkoutActiveUrlParams"))
      ? sessionStorage.getItem("checkoutActiveUrlParams")
      : "?currentShipmentIndex=0";

  const handleDeleteOrder = async () => {
    await Orders.Delete("Outgoing", currentOrder.order?.ID!);
    sessionStorage.removeItem("checkoutActiveUrlParams");
    setConfirmDialogOpen(false);
    dispatch(initOrder());
  };

  const shouldShowMultipleRecipientToggle = (shipments?: BachmansShipmentWithItems[]) => {
    if (!shipments) {
      return false;
    } else {
      if (shipments[0].Shipper && ["LocalDelivery", "UPS", "USPS"].includes(shipments[0].Shipper)) {
        if (
          sumBy(shipments, function (s) {
            return s.LineItems.length;
          }) > 1
        ) {
          return true;
        } else return false;
      } else return false;
    }
  };
  const shouldShowCurbsideToggle = (shipments?: BachmansShipmentWithItems[]) => {
    if (!shipments) {
      return false;
    } else {
      if (shipments[0].Shipper && deliveryService.IsPickUp(shipments[0].Shipper)) {
        return true;
      } else return false;
    }
  };

  const handleMultipleAddressesToggle = async (e: any, method: DeliveryTypes, currentOrder: any) => {
    const state = cloneDeep(cartState);

    if (e.target.checked) {
      if (method === "LocalDelivery" && state?.LocalDelivery) {
        state.LocalDelivery.shipToMultiple = true;
      } else if (method === "UPS" && state?.UPS) {
        state.UPS.shipToMultiple = true;
      } else if (method === "USPS" && state?.USPS) {
        state.USPS.shipToMultiple = true;
      }

      shipToMultiple = (state?.LocalDelivery?.shipToMultiple ||
        state?.UPS?.shipToMultiple ||
        state?.USPS?.shipToMultiple) as boolean;
      if (shipToMultiple) {
        await dispatch(
          patchOrder({
            partial: { xp: { shipToMultiple } },
            orderID: currentOrder?.order?.ID,
          })
        );

        setCartState(state);
      }
    } else {
      shipToMultiple = false as boolean;
      if (shipToMultiple == false) {
        await dispatch(
          patchOrder({
            partial: { xp: { shipToMultiple } },
            orderID: currentOrder?.order?.ID,
          })
        );
        setCartState(state);
      }
      setConfirmResetDialog(method);
    }
  };

  const handleCurbsidePickupToggle = async (e: any, method: DeliveryTypes) => {
    const state = cloneDeep(cartState);
    if (deliveryService.IsPickUp(method as string) && (state?.InStorePickUp || state?.CurbsidePickUp)) {
      const methodState = state[method];
      const shipments = methodState?.shipments;
      const liIDs: string[] = [];
      shipments?.forEach((shipment) => {
        shipment.LineItems.forEach((li) => {
          if (li.ID) {
            liIDs.push(li.ID!);
          }
        });
      });
      const newMethod = e.target.checked ? "CurbsidePickUp" : "InStorePickUp";
      await dispatch(
        patchLineItems({ lineItemIDs: liIDs, partial: { xp: { DeliveryMethod: newMethod, AddressType: newMethod } } })
      );
    }
  };

  const resetDeliveryMethod = async () => {
    if (currentOrder?.order?.ID) {
      const shipmentsToClear = currentOrder.shipments?.filter((s) => s.Shipper === confirmResetDialog);
      const lineItems = flatten(shipmentsToClear?.map((s) => s.LineItems));
      const reqs = lineItems.map((li) => {
        if (li.ShippingAddressID) {
          return LineItems.Patch("Outgoing", currentOrder.order?.ID!, li.ID!, { ShippingAddressID: "" });
        } else {
          const newLi = {
            ...li,
            ShippingAddress: undefined,
          };
          // Only way to remove a shipping address is with a PUT
          return LineItems.Save("Outgoing", currentOrder?.order?.ID!, li.ID!, newLi);
        }
      });
      await Promise.all(reqs);
      await dispatch(calculateOrder(currentOrder?.order?.ID));
    }
  };

  let canCheckout: boolean = useMemo(() => {
    if (cartState) {
      const allItems = flatten(flatten(Object.values(cartState).map((c) => c.shipments))?.map((s) => s.LineItems));
      //console.log('cartState',flatten(flatten(Object.values(cartState).map((c) => c.shipments))?.map((s) => s.Shipper)));
      return allItems.every((item) => item.Validation?.valid === true);
    } else return false;
  }, [cartState]);

  const cartErrors: (string | undefined)[] | undefined = useMemo(() => {
    if (cartState) {
      const allItems = flatten(flatten(Object.values(cartState).map((c) => c.shipments))?.map((s) => s.LineItems));
      const allErrors = flatten(flatten(Object.values(allItems)?.map((i) => i.Validation?.message)));

      return uniq(allErrors.filter((err) => err !== undefined));
    }
  }, [cartState]);

  const getDeliveryHeaderText = (
    method: DeliveryTypes,
    shipments?: ValidatedBachmansShipmentWithLineItems[]
  ): string => {
    const addressesSet = shipments && shipments?.every((s) => !isUndefined(s.ToAddress) && s.ToAddress !== null);
    console.log("method", method);
    if (method === "InStorePickUp") {
      return "Items for In-Store Pickup";
    } else if (method === "CurbsidePickUp") {
      return "Items for Curbside Pickup";
    } else if (method === "LocalDelivery") {
      return "Items for Local Delivery";
    } else if (!addressesSet) {
      return "Items for Delivery";
    } else if (method === "Wired") {
      return "Items for Partner Florist Delivery";
    } else if (method === "Email") {
      return "ITEMS FOR EMAIL DELIVERY";
    } else return `Items for ${method} Delivery`;
  };

  return (
    <Fragment>
      {(cartLoading || currentOrder.status === "pending") && (
        <Box className={classes.loadingOverlay}>
          <CircularProgress />
          <Typography display="inline" variant="caption">
            Updating Cart...
          </Typography>
        </Box>
      )}
      <Container>
        {cartCount > 0 ? (
          <Grid container spacing={3}>
            <ShoppingCartHeading />
            <Grid item xs={12} sm={9}>
              {isMobile && (
                <div id="mobileCheckoutBlock">
                  <DoubleOutlinedBtn
                    buttonProps={{
                      onClick: () =>
                        history.push({
                          pathname: "/checkout",
                          search: checkoutActivePane ? checkoutActivePane : "?currentShipmentIndex=0",
                        }),
                      color: "secondary",
                      fullWidth: true,
                      disabled: !canCheckout,
                    }}
                    styleProps={{
                      marginBottom: theme.spacing(4),
                    }}
                    buttonText="Checkout Now"
                  />
                </div>
              )}
              {cartState &&
                Object.keys(cartState)?.map((method, index) => {
                  const shipState = cartState[method as DeliveryTypes];
                  return (
                    <Fragment key={index}>
                      <Box className={classes.cartMain + " " + shipState?.shipments[0].Shipper} key={index}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h3" color="textSecondary">
                            {getDeliveryHeaderText(method as DeliveryTypes, shipState?.shipments)}
                          </Typography>
                          {shouldShowMultipleRecipientToggle(shipState?.shipments) && (
                            <Fragment>
                              {currentOrder && (
                                <FormControlLabel
                                  className={classes.switchMultipleRecipient}
                                  control={
                                    <Switch
                                      checked={currentOrder?.order?.xp?.ShipToMultiple || shipState?.shipToMultiple}
                                      onChange={(e: any) =>
                                        handleMultipleAddressesToggle(e, method as DeliveryTypes, currentOrder)
                                      }
                                    />
                                  }
                                  label="Send to Multiple Recipients"
                                ></FormControlLabel>
                              )}

                              <ConfirmDialog
                                open={!isUndefined(confirmResetDialog)}
                                title="Are you sure you want to reset your selected deliveries?"
                                text="Your cart will not empty and your saved addresses will still be available."
                                loadingText="Resetting Delivery..."
                                onAccept={resetDeliveryMethod}
                                onClose={() => setConfirmResetDialog(undefined)}
                              />
                            </Fragment>
                          )}
                          {/* Curbside pickup toggle */}
                          {shouldShowCurbsideToggle(shipState?.shipments) &&
                            !shipState?.shipments[0]?.LineItems[0]?.Product?.xp?.IsWorkShopEvent && (
                              <Fragment>
                                <FormControlLabel
                                  className={classes.switchMultipleRecipient}
                                  control={
                                    <Switch
                                      checked={shipState?.shipments?.some((s) => s.Shipper === "CurbsidePickUp")}
                                      onChange={(e: any) => handleCurbsidePickupToggle(e, method as DeliveryTypes)}
                                    />
                                  }
                                  label={
                                    <div>
                                      Use curbside pickup
                                      <Hidden smDown>
                                        <Tooltip
                                          classes={{ tooltip: classes.curbsideTooltip }}
                                          title={
                                            <Fragment>
                                              <Typography variant="h5">What's Non-Contact Curbside Pick-Up?</Typography>
                                              Our new non-contact curbside pick-up program makes it easy for you to
                                              bring home beautiful plants and gardening supplies while practicing social
                                              distancing. Here’s how it works:
                                              <ul>
                                                <li>Place your order at Bachmans.com or by calling 612-861-7311.</li>
                                                <li>
                                                  Swing by your Bachman’s store on your chosen pick-up date. Please
                                                  refer to store hours and curbside hours here.
                                                </li>
                                                <li>
                                                  Call the number on our curbside pick-up sign outside and provide store
                                                  staff with your name and order number.
                                                </li>
                                                <li>
                                                  Choose whether you want to load your own vehicle or have us do it for
                                                  you. Either way, we’ll bring your items out to you, all while keeping
                                                  a safe distance.
                                                </li>
                                              </ul>
                                            </Fragment>
                                          }
                                        >
                                          <InfoOutlined fontSize="small" />
                                        </Tooltip>
                                      </Hidden>
                                    </div>
                                  }
                                ></FormControlLabel>
                              </Fragment>
                            )}
                        </Box>
                        {productsOnOrder && (
                          <ShipmentList shipState={shipState} productsInOrder={productsOnOrder} cartState={cartState} />
                        )}
                      </Box>
                    </Fragment>
                  );
                })}
              <Divider light className={classes.divider} />
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="text"
                  color="primary"
                  href="/"
                  disableRipple
                  startIcon={<ArrowBackIos className={classes.iconExtraSmall} />}
                >
                  Continue Shopping
                </Button>
                <Button
                  className={classes.buttonClearCart}
                  variant="outlined"
                  size="small"
                  disabled={cartLoading}
                  onClick={() => setConfirmDialogOpen(true)}
                >
                  Clear Cart
                </Button>
                <ConfirmDialog
                  open={confirmDialogOpen}
                  title="Are you sure you want to clear your cart?"
                  loadingText="Clearing Cart..."
                  onClose={() => setConfirmDialogOpen(false)}
                  onAccept={handleDeleteOrder}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box position={!isMobile ? "sticky" : "block"} top="210px">
                {cartCount >= 1 &&
                  cartErrors?.map((err, i) => (
                    <Box key={i} margin="0.5rem 0">
                      <Alert className={classes.cartAlert} key={i} severity="error">
                        {err}
                      </Alert>
                    </Box>
                  ))}
                <DoubleOutlinedBtn
                  buttonProps={{
                    color: "secondary",
                    fullWidth: true,
                    disabled: !canCheckout,
                    onClick: () => {
                      history.push({
                        pathname: "/checkout",
                        search: checkoutActivePane ? checkoutActivePane : "?currentShipmentIndex=0",
                      });
                    },
                  }}
                  styleProps={{
                    marginBottom: theme.spacing(1),
                  }}
                  buttonText="Checkout Now"
                />
                <Box className={classes.paymentBox}>
                  <img src={visa} alt="visa" />
                  <img src={mastercard} alt="mastercard" />
                  <img src={discover} alt="discover" />
                  <img src={amex} alt="amex" />
                  <img src={paypal} alt="paypal" />
                  <img src={venmo} alt="venmo" />
                </Box>
                <OrderSummary />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <div className={classes.emptyCart}>
            <Typography variant="h1" color="primary">
              Your Shopping Cart is Empty
            </Typography>
            <Typography variant="subtitle1">
              <Link to="/">Continue Shopping</Link> to add products to your cart.
            </Typography>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default Cart;
