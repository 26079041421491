import React, { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import BachmansCarousel from "../Shared/BachmansCarousel";
import { ComplexContentModel } from "../../models/contentful/ComplexContent";
import {
  ComplexContentBlocksContext,
  HomeContentContext,
  SimpleContentBlocksContext,
} from "../../providers/contentful";
import ContentRow from "../Shared/Content/ContentRow";
import { carouselSettings } from "../Home/Home";
import { SimpleContentModel } from "../../models/contentful/SimpleContent";
import StackedContentBlock from "../Shared/Content/StackedContentBlock";
import FeaturedEventsCarousel from "../Shared/FeaturedEventsCarousel";
import contentfulService from "../../services/contentful.service";
import { Category } from "ordercloud-javascript-sdk";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     boxOuter: {
//       border: `1px solid ${theme.palette.grey[200]}`,
//       padding: theme.spacing(1),
//     },
//     boxInner: {
//       border: `3px solid ${theme.palette.primary.main}`,
//       padding: theme.spacing(1),
//     },
//   })
// );

export interface CategoryProps {
  category?: Category;
}

const CategoryFeaturedContent: FunctionComponent<CategoryProps> = (props) => {
  const { category } = props;
  const [stackedContent, setStackedContent] = useState<SimpleContentModel[]>();
  const [dynamicContent, setDynamicContent] = useState<ComplexContentModel[]>();
  const homeContent = useContext(HomeContentContext);
  const simpleContent = useContext(SimpleContentBlocksContext);
  const complexContent = useContext(ComplexContentBlocksContext);

  useEffect(() => {
    const homeStacked = homeContent.data?.fields?.stackedContent?.map((home) => home.fields);
    const homeDynamic = homeContent.data?.fields?.featuredBlocks?.map((home) => home.fields);

    var stacked = contentfulService.getSimpleContentFromIDs(category?.xp?.Content?.stacked?.value, simpleContent);
    setStackedContent(stacked && stacked.length > 0 ? stacked : homeStacked);

    var dynamic = contentfulService.getComplexContentFromIDs(category?.xp?.Content?.dynamic?.value, complexContent);
    setDynamicContent(dynamic && dynamic.length > 0 ? dynamic : homeDynamic);
  }, [category, homeContent, simpleContent, complexContent]);

  const slot2 = useMemo(() => {
    return <StackedContentBlock content={stackedContent} />;
  }, [stackedContent]);

  const slot3 = useMemo(() => {
    return (
      <BachmansCarousel
        variant="content"
        carouselData={dynamicContent || []}
        dotsInside={true}
        settings={carouselSettings}
      />
    );
  }, [dynamicContent]);

  return (
    <Fragment>
      <ContentRow title="Inspiration & Information to Bring Beautiful Ideas to Life">
        <FeaturedEventsCarousel />
        {slot2}
        {slot3}
      </ContentRow>
    </Fragment>
  );
};

export default CategoryFeaturedContent;
