import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  createStyles,
  useMediaQuery,
  Fade,
  Link as MaterialLink,
} from "@material-ui/core";
import { BachmansShipmentWithItems, ValidatedLineItem } from "../../models/shipment";
import ProductPrice from "../Product/ProductPrice";
import { Address, BuyerAddress, BuyerProduct, LineItem, LineItems, Orders } from "ordercloud-javascript-sdk";
import stringService from "../../services/string.service";
import QuantityInput from "../Shared/QuantityInput";
import Currency from "react-currency-formatter";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/store-hook";
import {
  calculateOrder,
  initOrder,
  patchLiQuanity,
  patchLiQuantityParams,
  setLineItemShippingAddress,
} from "../../redux/slices/order";
import { AppDispatch } from "../../redux/store";
import ConfirmDialog from "../Shared/ConfirmDialog";
import { CallSplit } from "@material-ui/icons";
import SpecialProductRequest from "./SpecialProductRequest";
import { SessionContext } from "../../providers/session";
import AuthDialog from "../Authentication/AuthDialog";
import DeliveryDateModal from "./DeliveryDateModal";
import SplitQuantityModal from "./SplitQuantityModal";
import { useHistory } from "react-router";
import Link from "../Shared/Link";
import { GridWidths } from "./ShipmentList";
import { neutral } from "../../themes/colors";
import { DeliveryTypes } from "../../models/buyerXp";
import { CatalogContext } from "../../providers/catalog";
import deliveryService, { DeliveryDateData, DeliveryDateOptions } from "../../services/delivery.service";
import LineItemActions from "./LineItemActions/index";
import { BachmansLineItem } from "../../models/lineItem";
import { DeliveryType } from "./ShipmentList";
import productService from "../../services/product.service";
import LocationDropDown from "./LocationDropDown";
import BachmansBadge from "../../assets/svg/logoBadge.svg";
import { DeliveryMethods } from "../../models/delivery";
import { Quantity } from "../../models/product";
import { isUndefined } from "lodash";
import googleAnalyticsService from "../../services/googleAnalytics.service";
import StoreDropDown from "./StoreDropDown";

interface LineItemRowProps {
  lineItem: ValidatedLineItem;
  shipment: BachmansShipmentWithItems;
  product: BuyerProduct;
  multipleRecipients?: boolean;
  updateDeliveryType: (type: DeliveryType, lineItemID: string, date: string) => void;
}

declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileLineItemActionBar: {
      "& li": {
        fontSize: "12px",
        "& .MuiSvgIcon-root": {
          marginRight: 8,
        },
      },
    },
    lineItemActionBar: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    productInfoPlaceholderImage: {
      backgroundColor: neutral.disabled,
      padding: theme.spacing(2),
      width: 100,
      height: 100,
    },
    productInfoProductImage: {
      width: 150,
      cursor: "pointer",
      maxHeight: 125,
      objectFit: "contain",
    },
    shipmentItem: {
      position: "relative",
      padding: theme.spacing(2, 2, 1, 2),
      boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 15%)",
      transition: "all .5s cubic-bezier(0.4, 0, 0.2, 1)",
      gap: theme.spacing(2),
      "&:hover, &:focus": {
        transform: "translateY(-.5px)",
        boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 15%)",
      },
      "& .MuiGrid-item:last-child": {
        marginLeft: "auto",
      },
      [theme.breakpoints.up("sm")]: {
        boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 5%)",
        padding: theme.spacing(1),
        gap: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        gap: 0,
      },
      "& #qtyCol > *": {
        width: 80,
      },
    },
    infoBox: {
      display: "flex",
      flexFlow: "column nowrap",
      flexGrow: 1,
      alignItems: "flex-start",
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      overflow: "hidden",
    },
    productName: {
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.h1.fontFamily,
      lineHeight: 1,
      "& a": {
        color: theme.palette.grey[700],
      },
    },
    text: {
      color: neutral.text_white_bg,
    },
    subtext: {
      color: theme.palette.grey[600],
      lineHeight: 1.5,
    },
    pullGridRight: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
        marginLeft: "auto",
      },
    },
    textLocalDelivery: {
      marginTop: theme.spacing(1),
      fontSize: ".65rem",
      lineHeight: 1,
    },
    labelMobile: {
      display: "block",
      fontSize: ".65rem",
      lineHeight: 1,
      color: theme.palette.grey[500],
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    buttonDeliverOn: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      borderColor: theme.palette.grey[400],
      minWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        minWidth: "80%",
      },
    },
    lineItemIconButton: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",
      padding: theme.spacing(0.5),
      backgroundColor: neutral.grey_background,
      opacity: 0.5,
      "& .MuiButton-startIcon": {
        marginRight: 4,
      },
      "& svg": {
        fontSize: "1.25rem",
      },
      "&:hover, &:focus": {
        opacity: 1,
      },
    },
    menuPaper: {
      borderRadius: 0,
    },
    fasterDeliveryDate: {
      lineHeight: 1,
      marginTop: theme.spacing(1),
      maxWidth: "90%",
      "& strong": {
        marginLeft: theme.spacing(0.5),
      },
    },
    btnXS: {
      textAlign: "left",
      fontSize: 9,
      lineHeight: 1,
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
      opacity: 0.5,
      "&:hover, &:focus, &:active": {
        opacity: 1,
      },
    },
    lineItemSelect: {
      width: "100%",
      marginTop: "auto",
      maxWidth: "100%",
      position: "relative",
      [theme.breakpoints.up("md")]: {
        minWidth: 240,
      },
      "& .MuiSelect-selectMenu": {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
      },
    },
    materialLink: {
      cursor: "pointer",
    },
    selectWhereIsThisGoing: {
      margin: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 300,
      },
    },
    invisible: {
      opacity: 1,
      visibility: "visible",
    },
  })
);

const LineItemRow: React.FunctionComponent<LineItemRowProps> = (props) => {
  const { lineItem, shipment, product, multipleRecipients, updateDeliveryType } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [lineItemDateDialog, setLineItemDateDialog] = useState<string>();
  const [liToDelete, setLiToDelete] = useState<LineItem>();
  const [liDisabled, setLiDisabled] = useState<boolean>(false);
  const [deliveryTypeChange, setDeliveryTypeChange] = useState<DeliveryType>();
  const [wishListConfirm, setWishListConfirm] = useState<boolean>(false);
  const [updatingWishList, setUpdatingWishList] = useState<boolean>(false);
  const [productRequestEnabled, setProductRequestEnabled] = useState<string>();
  const [authDialogOpen, setAuthDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const currentOrder = useAppSelector((state) => state.order);
  const history = useHistory();
  const { DCFcategories } = useContext(CatalogContext);
  const { user, patchUser } = useContext(SessionContext);
  const [deliveryServiceOpts, setDeliveryServiceOpts] = useState<DeliveryDateOptions>();
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethods>();
  const [lineItemSplitQuantity, setLineItemSplitQuantity] = useState<string>();
  const [productDeliveryData, setProductDeliveryData] = useState<DeliveryDateData>();
  const componentMounted = useRef(true);
  const [currentDate, SetcurrentDate] = useState<string>();
  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (DCFcategories) {
      (async () => {
        const methods = deliveryService.GetAvailableDeliveryMethods([product], DCFcategories);
        const options = await deliveryService.GetDateOptions({
          products: [product],
          deliveryMethods: methods,
          shippingAddress: shipment.ToAddress,
        });
        if (componentMounted.current === true) {
          setDeliveryServiceOpts(options);
          setDeliveryMethods(methods);
          setProductDeliveryData(options[shipment.Shipper as DeliveryTypes]);
        }
      })();
    }
  }, [DCFcategories, product, shipment.Shipper, shipment.ToAddress]);

  const updateDeliveryDateAndTime = async (partial: Partial<LineItem>) => {
    const updatedPartial = deliveryService.EnsureDateNeededAfterDateAdded(partial, lineItem.DateAdded);
    await LineItems.Patch("Outgoing", currentOrder.order?.ID!, lineItem.ID!, updatedPartial);
    await dispatch(calculateOrder(currentOrder.order?.ID!));
  };

  const updateLiQuantity = async (quantity: number, liID?: string, disableCalcOrder?: boolean) => {
    if (currentOrder?.order?.ID && liID) {
      setLiDisabled(true);
      let patch: patchLiQuantityParams = { orderID: currentOrder?.order?.ID, liID: liID, quantity: quantity };
      if (disableCalcOrder) patch.disableCalcOrder = true;
      await dispatch(patchLiQuanity(patch)).unwrap(); //unwrap will returns promise value or error
      setLiDisabled(false);
    }
  };

  const productNotInWishList = (item: LineItem) => {
    if (!user?.xp?.WishList) return true;
    return !user.xp.WishList.map((w: any) => w.ProductCode).includes(item.Product?.xp?.ProductCode);
  };

  const addToWishList = async () => {
    if (lineItem.Product?.xp?.ProductCode) {
      let updatedWishList = user?.xp.WishList?.concat({ ProductCode: lineItem.Product?.xp?.ProductCode });

      if (updatedWishList && updatedWishList.length && patchUser) {
        try {
          setUpdatingWishList(true);
          await patchUser({ xp: { WishList: updatedWishList } });
          await handleDeleteLineItem();
        } finally {
          setUpdatingWishList(false);
        }
      }
    }
  };

  const handleDeleteLineItem = async () => {
    googleAnalyticsService.RemoveItemFromCart(lineItem);
    if (currentOrder.order?.LineItemCount === 1) {
      await Orders.Delete("Outgoing", currentOrder?.order?.ID!);
      await dispatch(initOrder());
      sessionStorage.removeItem("checkoutActiveUrlParams");
    } else {
      await LineItems.Delete("Outgoing", currentOrder?.order?.ID!, lineItem?.ID!);
      await dispatch(calculateOrder(currentOrder.order?.ID!));
    }

    if (currentOrder && currentOrder?.shipments) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "removeFromCart",
        ecommerce: {
          remove: {
            // 'add' actionFieldObject measures.
            products: [
              {
                //  adding a product to a shopping cart.
                name: currentOrder?.shipments[0].LineItems[0].Product?.Name,
                id: currentOrder?.order?.ID,
                price: currentOrder?.shipments[0].LineItems[0].UnitPrice,
                // brand: "Bachman's",
                quantity: currentOrder?.shipments[0].LineItems[0].Quantity,
              },
            ],
          },
        },
      });
    }
  };

  const updateLiAddress = async (address: BuyerAddress, routeCode?: string) => {
    if (deliveryMethods && currentOrder.order?.ID && lineItem.ID) {
      const validDelivery = deliveryService.GetDeliveryTypeFromAddress(
        [product],
        deliveryMethods,
        address,
        lineItem.xp.DeliveryMethod
      );
      if (validDelivery) {
        await dispatch(
          setLineItemShippingAddress([
            {
              lineItemID: lineItem.ID,
              address: address as Address,
              routeCode: routeCode,
              deliveryMethod: validDelivery,
            },
          ])
        );
      } else {
        console.log("No valid delivery. Not updating address.");
      }
    }
  };

  const handleUpdateDeliveryType = async (method?: string) => {
    const newMethod = method || deliveryTypeChange;
    if (deliveryService.IsPickUp(newMethod)) {
      let pickUpDate = deliveryServiceOpts?.InStorePickUp?.standardMinDate?.toDateString();
      updateDeliveryType("InStorePickUp", lineItem.ID!, pickUpDate!);
    }
    if (newMethod === "Delivery") {
      let deliveryDate = deliveryServiceOpts?.LocalDelivery?.standardMinDate?.toDateString();
      updateDeliveryType("Delivery", lineItem.ID!, deliveryDate!);
    }
  };

  const readableMethod: string = useMemo(() => {
    if (lineItem.xp?.DeliveryMethod === "LocalDelivery") {
      return "Local Delivery";
    } else if (deliveryService.IsPickUp(lineItem.xp?.DeliveryMethod)) {
      return "Pickup";
    } else if (["Wired", "USPS", "UPS"].includes(lineItem.xp?.DeliveryMethod)) {
      return "Nationwide Delivery";
    } else return lineItem.xp?.DeliveryMethod;
  }, [lineItem.xp?.DeliveryMethod]);

  const quantity: Quantity = useMemo(() => {
    return {
      maxQuantity: productService.DetermineMaxQuantity({ Product: product }, currentOrder),
      minQuantity: productService.DetermineMinQuantity({ Product: product }),
      quantity: lineItem.Quantity,
    };
  }, [product, currentOrder, lineItem]);

  const getProductRoute = () =>
    lineItem.ProductID === "GIFTCARD" ? "/c/gift-cards" : `/p/${lineItem?.Product?.xp?.ProductCode}`;

  const shouldShowLocationDropDown = (): boolean => {
    return (
      multipleRecipients === true &&
      !isUndefined(shipment?.LineItems) &&
      (shipment.LineItems.length > 1 || lineItem.ShippingAddress === null) &&
      !lineItem?.Product?.xp?.IsWorkShopEvent
    );
  };
  const getDropdownAddressesForLineItems = (): JSX.Element => {
    if (deliveryService.IsPickUp(shipment.Shipper)) {
      return (
        <StoreDropDown
          formControlProps={{
            size: "small",
            margin: "dense",
            variant: "outlined",
            required: true,
            className: classes.lineItemSelect,
            error: lineItem.Validation?.valid === false && lineItem.Validation?.reason === "Address",
          }}
          shipment={shipment}
          onAddressSubmit={updateLiAddress}
        />
      );
    } else {
      return (
        <LocationDropDown
          formControlProps={{
            id: "lineItemShipmentLocation",
            error: lineItem.Validation?.valid === false && lineItem.Validation?.reason === "Address",
            size: "small",
            margin: "dense",
            variant: "outlined",
            className: classes.lineItemSelect,
          }}
          lineItems={[lineItem]}
          products={[product]}
          address={lineItem.ShippingAddress}
          shipper={shipment.Shipper as DeliveryTypes}
          onAddressSubmit={updateLiAddress}
        />
      );
    }
  };

  const outOfStock = productService.ProductOutOfStock(product);

  const isEgiftcardAvailable = () => {
    return document.body.classList.contains("block-checkout");
  };
  isEgiftcardAvailable();

  useEffect(() => {
    const today = moment();
    SetcurrentDate(today.format());
  }, []);

  return (
    <Fragment>
      <Grid container className={classes.shipmentItem}>
        {isMobile && shouldShowLocationDropDown() && (
          <Grid item xs={12}>
            <LocationDropDown
              formControlProps={{
                error: lineItem.Validation?.valid === false && lineItem.Validation?.reason === "Address",
                size: "small",
                margin: "dense",
                variant: "outlined",
                className: classes.lineItemSelect,
              }}
              lineItems={[lineItem]}
              products={[product]}
              address={lineItem.ShippingAddress}
              shipper={shipment.Shipper as DeliveryTypes}
              onAddressSubmit={updateLiAddress}
            />
          </Grid>
        )}
        <Grid item xs={12} md={GridWidths.product}>
          <Box position="relative" display="flex" alignItems="flext-start">
            {lineItem?.xp?.ShownImage ? (
              <img
                className={classes.productInfoProductImage}
                src={lineItem?.xp?.ShownImage}
                alt={lineItem?.Product?.Name}
                onClick={() => history.push(getProductRoute())}
              ></img>
            ) : (
              <img
                className={classes.productInfoPlaceholderImage}
                src={BachmansBadge}
                alt="No product image found"
                aria-hidden="true"
              />
            )}
            <div className={classes.infoBox}>
              <Typography className={classes.productName} variant="body1">
                <Link to={getProductRoute()}>
                  {stringService.CapitalizeString(productService.GetLineItemName(lineItem))}
                </Link>
              </Typography>
              <Typography title="lineItemIDCart" className={classes.subtext} variant="overline">
                Item # {lineItem?.Product?.ID}
              </Typography>
              {shipment?.xp?.DeliveryMethod === "Email" && document.body.classList.contains("block-checkout") && (
                <Typography variant="body1" color="error">
                  OUT OF STOCK
                </Typography>
              )}
              {lineItem.Product?.xp?.SpecsOptions?.Color && (
                <Typography title="lineItemIDCart" className={classes.subtext} variant="overline">
                  {lineItem?.Product?.xp?.IsWorkShopEvent
                    ? "Event Time: " +
                      lineItem.Product?.xp?.SpecsOptions?.Size +
                      " - " +
                      lineItem.Product?.xp?.SpecsOptions?.Color
                    : "Color " + lineItem.Product?.xp?.SpecsOptions?.Color}
                </Typography>
              )}

              {lineItem.Product?.xp?.SpecsOptions?.Size && !lineItem?.Product?.xp?.IsWorkShopEvent && (
                <Typography title="lineItemIDCart" className={classes.subtext} variant="overline">
                  Type: {lineItem.Product?.xp?.SpecsOptions?.Size}
                </Typography>
              )}
              {outOfStock && (
                <Typography variant="body1" color="error">
                  Out of Stock
                </Typography>
              )}
              {!outOfStock &&
                lineItem.Validation?.reason === "DeliveryMethod" &&
                lineItem?.xp?.DeliveryMethod !== "Email" &&
                !lineItem?.Product?.xp?.IsWorkShopEvent && (
                  <Fragment>
                    <Typography variant="body1" color="error">
                      Item is not eligible for {readableMethod}
                    </Typography>
                    <Typography variant="body1">
                      {"Switch to "}
                      {!deliveryService.IsPickUp(lineItem.xp?.DeliveryMethod) && deliveryServiceOpts?.InStorePickUp && (
                        <MaterialLink
                          className={classes.materialLink}
                          onClick={(e: any) => handleUpdateDeliveryType("InStorePickUp")}
                        >
                          In-store Pickup
                        </MaterialLink>
                      )}
                      {lineItem?.xp?.DeliveryMethod !== "LocalDelivery" && deliveryServiceOpts?.LocalDelivery && (
                        <MaterialLink
                          className={classes.materialLink}
                          onClick={(e: any) => handleUpdateDeliveryType("LocalDelivery")}
                        >
                          Local Delivery
                        </MaterialLink>
                      )}
                    </Typography>
                  </Fragment>
                )}
              {!isMobile && shouldShowLocationDropDown() && getDropdownAddressesForLineItems()}
              {isMobile && <ProductPrice lineItem={lineItem as LineItem} />}
            </div>
          </Box>
        </Grid>

        <Grid item xs={5} md={GridWidths.delivery} className={classes.invisible}>
          <Typography className={classes.labelMobile} variant="overline">
            {lineItem?.Product?.xp?.IsWorkShopEvent
              ? `Event Date`
              : deliveryService.IsPickUp(shipment?.Shipper)
              ? "Pickup Date"
              : "Delivery Date"}
          </Typography>
          <Fragment>
            <Button
              variant={!multipleRecipients ? "text" : "outlined"}
              onClick={() => setLineItemDateDialog(lineItem.ID)}
              disabled={
                lineItem?.Product?.xp?.IsWorkShopEvent ||
                lineItem?.xp?.DeliveryMethod === "Email" ||
                !multipleRecipients ||
                (!lineItem.Validation?.valid && lineItem.Validation?.reason === "DeliveryMethod")
              }
            >
              {lineItem?.xp?.DeliveryMethod !== "Email" &&
                moment(shipment?.xp?.RequestedDeliveryDate)?.format("MM/DD/YYYY")}
              {console.log("shipment?.xp?.RequestedDeliveryDate", typeof shipment?.xp?.RequestedDeliveryDate)}
              {lineItem?.xp?.DeliveryMethod === "Email" && moment(currentDate)?.format("MM/DD/YYYY")}
              {/* {moment(date)?.format("MM/DD/YYYY")} */}
            </Button>

            <DeliveryDateModal
              open={lineItemDateDialog === lineItem.ID}
              address={shipment.ToAddress}
              shipment={shipment}
              lineItem={lineItem}
              onClose={() => setLineItemDateDialog(undefined)}
              products={[product]}
              onSubmit={updateDeliveryDateAndTime}
            />
          </Fragment>
          {productDeliveryData &&
            moment(productDeliveryData.standardMinDate) < moment(shipment.xp?.RequestedDeliveryDate) && (
              <Fade
                in={
                  productDeliveryData &&
                  moment(productDeliveryData.standardMinDate) < moment(shipment.xp?.RequestedDeliveryDate)
                }
              >
                <Typography className={classes.fasterDeliveryDate} variant="body2" color="secondary">
                  This item can {lineItem.xp?.DeliveryMethod !== "InStorePickUp" ? "ship" : "be ready"} as early as
                  <strong>{moment(productDeliveryData.standardMinDate).format("MM/DD/YYYY")}</strong>
                </Typography>
              </Fade>
            )}
        </Grid>

        {!isMobile && (
          <Grid item md={GridWidths.price}>
            <ProductPrice lineItem={lineItem as LineItem} />
          </Grid>
        )}
        <Grid item xs={3} md={GridWidths.quantity} id="qtyCol">
          <Typography className={classes.labelMobile} variant="overline">
            QTY
          </Typography>
          {shipment?.xp?.DeliveryMethod !== "Email" && (
            <QuantityInput
              hideLabel={true}
              lineItem={lineItem}
              quantity={quantity}
              onChange={updateLiQuantity}
              disabled={liDisabled || outOfStock}
              pending={liDisabled}
            />
          )}
          {shipment?.xp?.DeliveryMethod == "Email" && (
            <QuantityInput
              hideLabel={true}
              lineItem={lineItem}
              quantity={quantity}
              onChange={updateLiQuantity}
              disabled={!liDisabled}
              pending={liDisabled}
            />
          )}

          {lineItem.Quantity > 1 &&
            !deliveryService.IsPickUp(shipment.Shipper) &&
            shipment?.xp?.DeliveryMethod !== "Email" &&
            quantity.quantity < (quantity.maxQuantity || 1000) && (
              <Button
                startIcon={<CallSplit fontSize="small" />}
                className={classes.btnXS}
                onClick={() => setLineItemSplitQuantity(lineItem.ID)}
              >
                Multiple Recipients
              </Button>
            )}
        </Grid>
        {lineItem?.xp?.DeliveryMethod !== "Email" && (
          <Grid item xs={true} sm={2} className={classes.pullGridRight}>
            <Typography className={classes.labelMobile} variant="overline">
              Total
            </Typography>
            {lineItem.LineTotal && (
              <Typography variant="h4" color="primary">
                <Currency quantity={lineItem.LineTotal} />
              </Typography>
            )}
          </Grid>
        )}
        {lineItem?.xp?.DeliveryMethod === "Email" && (
          <Grid item xs={true} sm={2} className={classes.pullGridRight}>
            <Typography className={classes.labelMobile} variant="overline">
              Total
            </Typography>
            {lineItem.LineTotal && (
              <Typography variant="h4" color="primary">
                <Currency quantity={lineItem.LineTotal} />
              </Typography>
            )}
          </Grid>
        )}
        {shipment?.Shipper && (
          <LineItemActions
            shipperType={shipment?.Shipper}
            lineItem={lineItem}
            deliveryOptions={deliveryServiceOpts}
            updating={updatingWishList}
            productNotInWishList={productNotInWishList}
            onProductRequestEnabled={(id: string) => {
              setProductRequestEnabled(id);
            }}
            onWishListUpdate={() => {
              setWishListConfirm(true);
            }}
            onLiToDelete={(item: BachmansLineItem) => {
              setLiToDelete(item);
            }}
            onDeliveryTypeUpdate={(action: DeliveryType) => {
              setDeliveryTypeChange(action);
            }}
          />
        )}
        <AuthDialog open={authDialogOpen} onClose={() => setAuthDialogOpen(false)} />

        {/* Split Quantity Modal for Multiple Recipients */}
        {deliveryMethods && (
          <SplitQuantityModal
            open={lineItemSplitQuantity === lineItem.ID}
            shipment={shipment}
            lineItem={lineItem}
            origQuantity={{
              maxQuantity: productService?.DetermineMaxQuantity({ Product: product }, currentOrder),
              minQuantity: productService?.DetermineMinQuantity({ Product: product }),
              quantity: lineItem.Quantity,
            }}
            deliveryMethods={deliveryMethods}
            onClose={() => setLineItemSplitQuantity(undefined)}
            products={[product]}
            disabled={lineItem.Validation?.reason === "Inventory"}
          />
        )}

        {/* Confirm Delete Dialog */}
        <ConfirmDialog
          open={!!(liToDelete && liToDelete?.ID === lineItem.ID)}
          title={`Are you sure you want to remove ${productService.GetLineItemName(liToDelete)}?`}
          loadingText={"Removing product..."}
          onClose={() => setLiToDelete(undefined)}
          onAccept={handleDeleteLineItem}
        />

        {/* Confirm Delivery Type Move Dialog  */}
        <ConfirmDialog
          open={Boolean(deliveryTypeChange)}
          title={`Are you sure you want to move ${productService.GetLineItemName(lineItem)} to be ${
            deliveryTypeChange === "Delivery" ? "delivered" : "picked up"
          }?`}
          loadingText={"updating"}
          onClose={() => setDeliveryTypeChange(undefined)}
          onAccept={handleUpdateDeliveryType}
        />

        {/* Confirm Wishlist Move Dialog */}
        <ConfirmDialog
          open={wishListConfirm}
          title={`${productService.GetLineItemName(
            lineItem
          )} will be removed from the cart and added to your wish list`}
          loadingText={"adding to wish list, removing from cart"}
          onClose={() => setWishListConfirm(false)}
          onAccept={addToWishList}
        />
      </Grid>
      {(productRequestEnabled === lineItem.ID || lineItem?.xp?.ProductNote) && (
        <SpecialProductRequest
          lineItem={lineItem}
          enabled={productRequestEnabled === lineItem.ID}
          onCancel={() => setProductRequestEnabled(undefined)}
        />
      )}
    </Fragment>
  );
};

export default LineItemRow;
