import { Container, createStyles, makeStyles, Typography, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import ItemList from "../Shared/ItemList";
import { Category } from "ordercloud-javascript-sdk";
import FullWidthContentBlock from "../Shared/Content/FullWidthContentBlock";
import { neutral } from "../../themes/colors";
import BachmansCarousel from "../Shared/BachmansCarousel";
import CategoryFeaturedContent from "./CategoryFeaturedContent";
import { ComplexContentBlocksContext } from "../../providers/contentful";
import CategoryHeader from "./CategoryHeader";
import DescriptionRelatedServices from "./DescriptionRelatedServices";
import { CategoryContent } from "../../models/contentful/CategoryContent";
import contentfulService from "../../services/contentful.service";
import productService from "../../services/product.service";

export interface ParamTypes {
  partOne: string;
  partTwo?: string;
  partThree?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "-36px",
    },
    categoryContainer: {
      transform: `translateY(${theme.spacing(-5)}px)`,
    },
    categoryDescription: {
      color: neutral.text_colored_bg,
      flexBasis: "100%",
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 3, 0, 0),
        flexBasis: "70%",
      },
    },
    darkBackground: {
      backgroundColor: neutral.grey_background,
      padding: theme.spacing(3, 0),
      marginTop: theme.spacing(6),
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    carouselContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    relatedServiceHeader: {
      marginBottom: theme.spacing(1),
    },
    contentContainer: {
      marginTop: theme.spacing(6),
    },
  })
);

export interface CategoryListProps {
  category?: Category;
  children?: Category[];
}

const CategoryList: FunctionComponent<CategoryListProps> = (props) => {
  const classes = useStyles();
  const { children, category } = props;
  const complexContent = useContext(ComplexContentBlocksContext);
  const [categoryContent, setCategoryContent] = useState<CategoryContent>();
  const [featuredProducts, setFeaturedProducts] = useState<[] | null>();

  useEffect(() => {
    const content: CategoryContent = {};
    content.full = contentfulService.getComplexContentFromIDs(category?.xp?.Content?.full?.value, complexContent);
    setCategoryContent(content);
  }, [complexContent, category]);

  useEffect(() => {
    (async function () {
      if (category) {
        let featuredCatProducts: any = await productService.ListOcProducts({
          pageSize: 100,
          categoryID: category.ID,
          filters: {
            "xp.IsFeatured": true,
            "xp.IsDefaultProduct": true,
          },
        });
        let formatedProducts = featuredCatProducts?.Items?.map((product: any) => [product]);
        setFeaturedProducts(formatedProducts);
      }
    })();
  }, [category]);

  return (
    <div className={classes.root}>
      <CategoryHeader category={category} />
      <Container className={classes.categoryContainer} maxWidth="lg">
        <ItemList variant="category" listData={children}></ItemList>
        {categoryContent?.full && categoryContent?.full?.length > 0 && (
          <FullWidthContentBlock fullWidthContent={categoryContent.full[0]} />
        )}
      </Container>
      <div className={classes.darkBackground}>
        <Container className={classes.carouselContainer}>
          <Typography
            variant="h4"
            className={classes.relatedServiceHeader}
          >{`Featured Products in ${category?.Name}`}</Typography>
          <BachmansCarousel
            variant="product"
            carouselData={featuredProducts ? featuredProducts.slice(0, 4) : []}
          ></BachmansCarousel>
          <DescriptionRelatedServices category={category} />
        </Container>
      </div>
      <Container className={classes.contentContainer}>
        <CategoryFeaturedContent category={category} />
      </Container>
    </div>
  );
};

export default CategoryList;
