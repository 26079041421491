import React, { FunctionComponent } from "react";
import Quicklink from "../../models/contentful/quicklink";
import { Box, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import Link from "./Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    item: {
      flex: 1,
      "&:not(:first-child)": {
        borderLeft: "none",
        borderTop: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.up("sm")]: {
          borderLeft: `1px solid ${theme.palette.divider}`,
          borderTop: "none",
        },
      },
      "&:last-child": {
        borderLeft: "none",
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.up("sm")]: {
          borderBottom: "none",
        },
      },
      "&:hover": {
        ".MuiTypography-root": {
          fontWeight: theme.typography.fontWeightBold,
          textDecoration: "none",
        },
      },
    },
    itemCard: {
      flexDirection: "row",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "column",
      },
    },
    itemImage: {
      maxWidth: "100%",
      width: 50,
      height: 50,
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(2),
        marginRight: "initial",
      },
    },
    itemLink: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "column",
      },
      textAlign: "center",
      color: theme.palette.text.primary,
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.primary.main,
        "& > .MuiTypography-caption": {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
    itemTitle: {
      lineHeight: 1.2,
      [theme.breakpoints.up("lg")]: {
        maxWidth: "85%",
      },
    },
  })
);
const Quicklinks: FunctionComponent<{ links: Quicklink[] }> = ({ links }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="stretch" spacing={2} wrap="nowrap" className={classes.root}>
      {links.map((l, i) => {
        return (
          <Grid item zeroMinWidth key={i} className={classes.item}>
            <Box className={classes.itemCard} display="flex" justifyContent="center" alignItems="center">
              <Link to={l.fields.link} className={classes.itemLink}>
                <img
                  className={classes.itemImage}
                  title={l.fields.title}
                  alt={l.fields.image.fields.description}
                  src={l.fields.image.fields.file.url}
                />
                <Typography className={classes.itemTitle} variant="h6" display="block" align="center">
                  {ReactHtmlParser(l.fields.title)}
                </Typography>
                {l.fields.subtext && (
                  <Typography variant="body2" display="block" align="center">
                    {l.fields.subtext}
                  </Typography>
                )}
              </Link>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Quicklinks;
