import { Box, Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import Carousel from "./Carousel";
import BannerPromotion from "../Shared/BannerPromotion";
import { ComplexContentBlocksContext, HomeContentContext } from "../../providers/contentful";
import Quicklinks from "../Shared/Quicklinks";
import ContentRow from "../Shared/Content/ContentRow";
import productService from "../../services/product.service";
import { BuyerProduct, Product } from "ordercloud-javascript-sdk";
import BachmansCarousel from "../Shared/BachmansCarousel";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { find, forEach, map } from "lodash";
import BachmansContentBlock from "../Shared/Content/BachmansContentBlock";
import { ComplexContent, ComplexContentModel } from "../../models/contentful/ComplexContent";
import { Entry } from "contentful";
import { neutral } from "../../themes/colors";
import HomeStackedContent from "./HomeStackedContent";
import HomeServices from "./HomeServices";
import deliveryResourceService from "../../services/delivery-resource.service";
import WorkWithBachmans from "../Shared/WorkWithBachmans";
import PurplePerksWithoutText from "../Shared/PurplePerksWithoutText";

export const carouselSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  fade: true,
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    updates: {
      textAlign: "center",
      margin: `${theme.spacing(5)}px auto`,
      [theme.breakpoints.up("md")]: {
        maxWidth: "55vw",
      },
      "& a": {
        textDecoration: "none",
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
      },
      "& h1,h2": {
        fontFamily: theme.typography.h3.fontFamily,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: 900,
        color: theme.palette.error.main,
        margin: theme.spacing(2, 0, 0, 0),
      },
      "& p": {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
      },
      "& p>img": {
        width: "100%",
      },
    },
    greySection: {
      position: "relative",
      backgroundColor: neutral.text_utility_bg,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    container: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(33),
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(22),
      },
    },
    containerSlider: {
      marginBottom: theme.spacing(9),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    title: {
      textAlign: "center",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    gutterBottom: {
      marginBottom: theme.spacing(3),
    },
    paddingBottom: {
      paddingBottom: theme.spacing(15),
      [theme.breakpoints.up("sm")]: {
        paddingBottom: theme.spacing(12),
      },
    },
    containerPurplePerks: {
      bottom: theme.spacing(-28),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", // Adjust this according to your layout needs
      alignItems: "center", // Adjust this according to your layout needs
      // padding: theme.spacing(8),
      paddingLeft: 0,
      paddingRight: 0,
      "&:nth-child(2)": {
        paddingTop: "36px", // Add padding-top to the second div
      },
      "& img": {
        padding: "12px 0", // Add padding to the img element
      },
      [theme.breakpoints.up("sm")]: {
        bottom: theme.spacing(7),
        maxHeight: "85px",
        flexDirection: "column", // Adjust this according to your layout needs
        "& img": {
          padding: "12px", // Add padding to the img element
        },
        "& div": {
          // padding: "12px", // Add padding to the img element
          padding: "0px 4px", // Add padding to the img element
          [theme.breakpoints.up("lg")]: {
            padding: "5px 12px", // Add padding to the img element
          },
        },
        "&:nth-child(2)": {
          paddingTop: "0px", // Add padding-top to the second div
        },
      },
    },
    // working well on desk
    // customWrapper: {
    //   position: "absolute",
    //   bottom: theme.spacing(-24),
    //   left: 0,
    //   right: 0,
    //   display: "flex",
    //   flexDirection: "column",
    //   flexWrap: "wrap",
    //   // paddingTop: theme.spacing(12),
    //   [theme.breakpoints.up("sm")]: {
    //     flexWrap: "nowrap",
    //     bottom: theme.spacing(7),
    //     maxHeight: "85px",
    //     flexDirection: "row", // Adjust this according to your layout needs
    //   },
    // },

    // adjusting
    customWrapper: {
      position: "absolute",
      bottom: theme.spacing(-11),
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      // paddingTop: theme.spacing(12),
      // "&:nth-child(1)": {
      //   paddingTop: "16px", // Add padding-top to the second div
      // },
      [theme.breakpoints.up("sm")]: {
        flexWrap: "nowrap",
        bottom: theme.spacing(7),
        maxHeight: "85px",
        flexDirection: "row", // Adjust this according to your layout needs
      },
    },
    gridContainerPurplePerks: {
      justifyContent: "center",
    },
    gridItemPurplePerks: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    imgPurplePerks: {
      display: "block",
      width: "clamp(100px, 20vh, 175px)",
    },
    listPurplePerks: {
      padding: theme.spacing(1, 5),
      "& > .MuiListItem-root": {
        listStyleType: "disc",
        display: "list-item",
        paddingLeft: 0,
        "&::marker": {
          color: theme.palette.primary.main,
        },
      },
    },
    getInspired: {
      // marginTop: theme.spacing(38),
      marginTop: theme.spacing(29),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(-2),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(-12),
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.spacing(-15),
      },
    },
    wrapper2: {
      marginTop: theme.spacing(4), // 24px for small devices
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(0),
      },
    },
    fixedMarginTop: {
      // marginTop: theme.spacing(-8),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(18),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(22),
      },
    },
  })
);

const Home: FunctionComponent<any> = (props) => {
  const classes = useStyles();
  const { setBreadcrumbs } = props;
  const homeContent = useContext(HomeContentContext);
  const complexContent = useContext(ComplexContentBlocksContext);
  const [featuredEvents, setFeaturedEvents] = useState<BuyerProduct[]>();
  const [bachmansPicks, setBachmansPicks] = useState<Product[][]>();
  const [featuredBlocks, setFeaturedBlocks] = useState<ComplexContent[]>();

  useEffect(() => {
    setBreadcrumbs({ visible: false });
  }, [setBreadcrumbs]);

  useEffect(() => {
    (async function getInfo() {
      if (homeContent.ready && complexContent.ready) {
        let complexContentIDs = homeContent.data?.fields?.featuredBlocks.map((contentInfo) => contentInfo.sys.id);
        let complexList: any = [];
        forEach(complexContentIDs, (id) => {
          let entryinfo = find(complexContent.data, (entry: Entry<ComplexContentModel>) => entry.sys.id === id);
          if (entryinfo) complexList.push(entryinfo as ComplexContent);
        });
        setFeaturedBlocks(complexList);
      }
      let buyerInfo = await deliveryResourceService.GetBuyerXp();
      // Fix for BI-457: Sort carousel cards as per order from admin panel.
      const eventsToShow = buyerInfo?.RenderFeaturedEvents
        ? buyerInfo?.RenderFeaturedEvents
        : buyerInfo?.FeaturedEvents;

      const bachmanPicksToShow = buyerInfo?.RenderBachmansPicks
        ? buyerInfo?.RenderBachmansPicks
        : buyerInfo?.BachmansPicks;

      let [events, products] = await Promise.all([
        // productService.ListProductsByCodes(buyerInfo?.FeaturedEvents, true),
        // productService.ListProductsByCodes(buyerInfo?.BachmansPicks, true),
        productService.ListProductsByCodes(eventsToShow, true),
        productService.ListProductsByCodes(bachmanPicksToShow, true),
      ]);
      // sort events based on order:
      const sortedFeaturedEvents = getDesiredSorting(events, eventsToShow);
      setFeaturedEvents(sortedFeaturedEvents);
      if (events) {
        let sortedBachmanPicks = getDesiredSorting(products, bachmanPicksToShow);
        let formatedProducts = map(sortedBachmanPicks as Product[], (p: Product) => {
          return [p];
        });
        setBachmansPicks(formatedProducts);
      }
    })();
  }, [complexContent, homeContent]);

  // function to sort original array as per desired array:
  const getDesiredSorting = (originalArray: any[], desiredArray: any[]) => {
    return [...originalArray].sort((a, b) => {
      const indexA = desiredArray.indexOf(a?.xp?.ProductCode);
      const indexB = desiredArray.indexOf(b?.xp?.ProductCode);
      return indexA - indexB;
    });
  };

  const slot1 = useMemo(() => {
    if (featuredEvents && featuredEvents.length) {
      return (
        <BachmansCarousel
          variant="event"
          featured={true}
          carouselData={featuredEvents}
          dotsInside={true}
          settings={carouselSettings}
        />
      );
    }
  }, [featuredEvents]);

  const slot3 = useMemo(() => {
    if (homeContent && homeContent.data?.fields.contentCarousel) {
      return (
        <BachmansCarousel
          variant="content"
          carouselData={homeContent.data?.fields.contentCarousel}
          dotsInside={true}
          settings={carouselSettings}
        />
      );
    }
  }, [homeContent]);

  return homeContent.ready && homeContent.data ? (
    <Fragment>
      <Carousel slides={homeContent.data?.fields.imageSlider || []} />
      <Container maxWidth="lg">
        <BannerPromotion variant="home" />
      </Container>
      <Container maxWidth="lg">
        <Quicklinks links={homeContent.data.fields.quicklinks} />
        {/* COVID ALERT */}
        {homeContent.data?.fields?.updates && (
          <ReactMarkdown className={classes.updates} plugins={[gfm]} children={homeContent.data?.fields?.updates} />
        )}
      </Container>
      <div className={`${classes.paddingBottom} ${classes.greySection}`}>
        {/* 1st Block: Bachman's Picks */}
        {bachmansPicks && (
          <Container maxWidth="lg" className={classes.containerSlider}>
            <BachmansCarousel variant="product" carouselData={bachmansPicks} carouselTitle="Bachman's Picks" />
          </Container>
        )}
        {/* 2nd Block: Bachman's Features */}
        {featuredBlocks && featuredBlocks.length && (
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.title}>
                  Bachman's Features
                </Typography>
              </Grid>
              {featuredBlocks.map((featuredBlock, index) => (
                <Grid item xs={12} sm={featuredBlocks.length === 3 ? 4 : 6} key={index}>
                  <BachmansContentBlock content={featuredBlock} />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
        {/* Loyalty Program Card */}
        {/* <Container className={classes.containerPurplePerks}>
          <PurplePerksFull />
        </Container> */}

        {/* works well in Desktop */}
        {/* <Container className={classes.customWrapper}>
          <Container className={classes.containerPurplePerks}>
            <PurplePerksWithoutText />
          </Container>
          <Container className={classes.containerPurplePerks}>
            <WorkWithBachmans />
          </Container>
        </Container> */}

        {/* Working not well in Desktop */}
        <Grid container spacing={1} className={classes.customWrapper}>
          <Container className={classes.customWrapper}>
            <Grid item xs={12} md={6}>
              <Container className={classes.containerPurplePerks}>
                <PurplePerksWithoutText />
              </Container>
            </Grid>

            <Grid item xs={12} md={1}></Grid>

            <Grid item xs={12} md={6} className={classes.wrapper2}>
              <Container className={classes.containerPurplePerks}>
                <WorkWithBachmans />
              </Container>
            </Grid>
          </Container>
        </Grid>
      </div>
      {/* 3rd Block: Get Inspired, Stay Informed */}
      <Box mt={24} mb={4} className={classes.fixedMarginTop}>
        <Container maxWidth="lg" className={classes.getInspired}>
          <ContentRow
            title="Inspiration & Information to Bring Beautiful Ideas to Life"
            subTitle="Watch helpful how-to videos on our YouTube channel, browse our catalog of informative care sheets, or join us for fun and inspirational events!"
          >
            {slot1}
            <HomeStackedContent />
            {slot3}
          </ContentRow>
        </Container>
      </Box>
      <div className={`${classes.paddingBottom} ${classes.greySection}`}>
        <Container>
          <Typography variant="h3" className={`${classes.title} ${classes.gutterBottom}`}>
            Explore Our Services
          </Typography>
          <HomeServices />
        </Container>
      </div>
    </Fragment>
  ) : null;
};

const mapDispatchToProps = { setBreadcrumbs };

export default connect(null, mapDispatchToProps)(Home);
