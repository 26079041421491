import {
  Grid,
  Typography,
  createStyles,
  Theme,
  makeStyles,
  Box,
  useTheme,
  Tab,
  withStyles,
  useMediaQuery,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Tabs,
} from "@material-ui/core";
import { Entry } from "contentful";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Link from "../Shared/Link";
import { groupBy, sortBy } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { StaticPageContext } from "../../providers/contentful";
import { a11yProps, StyledTabPannel, StyledTabProps, StyledTabsProps } from "../Shared/Tabs";
import DoubleOutlinedBtn, { DoubleOutlinedLinkBtn } from "../Shared/DoubleOutlinedBtn";
import { neutral } from "../../themes/colors";
import { ContactUsDialog } from "../StaticPages/ContactUs";

export interface HomePageServiceElement extends Entry<any> {
  children?: Entry<any>[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    containerRoot: {
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    containerContent: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "center",
    },
    buttonBox: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      justifyContent: "space-between",
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(3),
        justifyContent: "flex-start",
      },
    },
    linkBox: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexFlow: "row wrap",
      gap: theme.spacing(1.5),
    },
    header: {
      fontSize: "18px",
      color: neutral.text_white_bg,
    },
    description: {
      fontWeight: 250,
    },
    imageContainer: {
      textAlign: "center",
      maxHeight: "35vh",
      overflow: "hidden",
    },
    image: {
      objectFit: "cover",
      width: "100%",
    },
    content: {
      color: theme.palette.grey[800],
      "& h1": {
        fontWeight: 500,
        fontSize: "28px",
      },
      "& h2": {
        fontWeight: 700,
        fontSize: "24px",
      },
      "& h3": {
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "uppercase",
        fontSize: "18px",
        fontSmooth: "always",
      },
      "& h4": {
        fontWeight: 600,
        fontSize: "16px",
      },
      "& h5": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "14px",
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
      "& h6": {
        fontWeight: 500,
        textTransform: "uppercase",
        fontSize: "12px",
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
      "& label, small": {
        fontSize: 12,
      },
      "& li, p, td, th": {
        fontSize: 14,
      },
    },
  })
);
const HomeServices: React.FunctionComponent = () => {
  const staticPages = useContext(StaticPageContext);
  const [services, setServices] = useState<Entry<any>[]>();
  const [value, setValue] = useState<number | undefined>(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();

  useEffect(() => {
    if (staticPages && staticPages.ready) {
      const sorted = sortBy(
        staticPages.data?.filter((page) => page.fields.navigation && !page.fields.parentPage),
        (service) => service.fields.listOrder
      );
      const baseServices = groupBy(sorted, (service) => service.fields.section);

      const homeServices: HomePageServiceElement[] = baseServices.services.map((s) => {
        const item: HomePageServiceElement = {
          ...s,
          children: staticPages.data?.filter(
            (page) => page?.fields?.parentPage?.sys?.id && page?.fields?.parentPage?.sys?.id === s?.sys?.id
          ),
        };
        return item;
      });
      setServices(homeServices);
    }
  }, [staticPages]);

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => setValue(tab);

  const handleAccordionChange = (index: number) => (event: any, isExpanded: boolean) => {
    setValue(isExpanded ? index : undefined);
  };

  return isMobile ? (
    <Fragment>
      {services &&
        services.map((service, index) => (
          <Accordion expanded={value === index} onChange={handleAccordionChange(index)} key={index}>
            <AccordionSummary
              expandIcon={value === index ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
              id="panel1bh-header"
            >
              <Typography variant="body1" style={{ fontWeight: "bold", color: neutral.text_white_bg }}>
                {service?.fields?.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ServiceDisplay page={service} />
            </AccordionDetails>
          </Accordion>
        ))}
    </Fragment>
  ) : (
    <Fragment>
      <ServicesTabSet value={value || 0} onChange={handleTabChange}>
        {services &&
          services?.map((service, index) => (
            <ServicesTab label={service.fields.title} {...a11yProps(index)} key={index} />
          ))}
      </ServicesTabSet>
      {services &&
        services?.map((service, index) => (
          <StyledTabPannel
            key={index}
            value={value || 0}
            index={index}
            styleProps={{
              border: `1px solid ${theme.palette.grey[400]}`,
            }}
          >
            <ServiceDisplay page={service} />
          </StyledTabPannel>
        ))}
    </Fragment>
  );
};

export interface ServiceDisplayProps {
  page?: HomePageServiceElement;
}

const ServiceDisplay: React.FunctionComponent<ServiceDisplayProps> = (props) => {
  const classes = useStyle();
  const { page } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <Grid container spacing={3} className={classes.containerRoot}>
      <Grid item xs={12} sm={6} className={classes.containerContent}>
        <Typography gutterBottom className={classes.header} variant="h5">
          {page?.fields?.title}
        </Typography>
        <Typography className={classes.description} variant="h2">
          {page?.fields?.bannerDescription}
        </Typography>
        <ReactMarkdown className={classes.content} escapeHtml={false} children={page?.fields?.homePageContent} />
        <div className={classes.linkBox}>
          {page?.children &&
            page?.children?.map((child, index) => (
              <Fragment key={index}>
                {index !== 0 && (
                  <Typography variant="body1" color="primary" style={{ display: "inline", fontWeight: "bold" }}>
                    {" "}
                    |{" "}
                  </Typography>
                )}
                <Link variant="body1" key={index} to={`/services/${page.fields.slug}/${child.fields.slug}`}>
                  {child?.fields?.title}
                </Link>
              </Fragment>
            ))}
        </div>
        <Box display="flex" className={classes.buttonBox}>
          <DoubleOutlinedLinkBtn
            styleProps={{
              marginRight: theme.spacing(2),
            }}
            buttonProps={{ variant: "outlined" }}
            buttonText={page?.fields?.bannerButtonText || "Learn More"}
            href={`/services/${page?.fields?.slug}`}
          />
          <DoubleOutlinedBtn
            buttonProps={{
              variant: "outlined",
              onClick: () => setDialogOpen(true),
            }}
            buttonText="Contact Us"
          />
          <ContactUsDialog page={page} open={dialogOpen} onClose={() => setDialogOpen(false)} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.imageContainer}>
        <img className={classes.image} src={page?.fields?.bannerImage?.fields?.file?.url} alt=""></img>
      </Grid>
    </Grid>
  );
};

const ServicesTabSet = withStyles({
  root: {
    marginLeft: "-2px",
    marginRight: "-2px",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} />);

const ServicesTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      color: neutral.text_white_bg,
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
      paddingLeft: "2px",
      paddingRight: "2px",
      marginLeft: "2px",
      marginRight: "2px",
      paddingTop: "0px",
      paddingBottom: "0px",
      minWidth: "20px",
      [theme.breakpoints.up("sm")]: {
        flexGrow: 1,
        maxWidth: "unset",
      },
    },
    selected: {
      backgroundColor: "white",
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export default HomeServices;
