import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { ComplexContentModel } from "../../../models/contentful/ComplexContent";
import { SimpleContentModel } from "../../../models/contentful/SimpleContent";
import { BachmansProduct } from "../../../models/product";
import { CatalogContext } from "../../../providers/catalog";
import { ComplexContentBlocksContext, SimpleContentBlocksContext } from "../../../providers/contentful";
import contentfulService from "../../../services/contentful.service";
import dcfService from "../../../services/dcf.service";
import HomeStackedContent from "../../Home/HomeStackedContent";
import BachmansCarousel from "../../Shared/BachmansCarousel";
import ContentRow from "../../Shared/Content/ContentRow";
import StackedContentBlock from "../../Shared/Content/StackedContentBlock";
import FeaturedEventsCarousel, { carouselSettings } from "../../Shared/FeaturedEventsCarousel";

interface ProductFeaturedContentProps {
  product?: BachmansProduct;
}

const ProductFeaturedContent: React.FunctionComponent<ProductFeaturedContentProps> = (props) => {
  const { product } = props;
  const { DCFcategories } = useContext(CatalogContext);
  const simpleContent = useContext(SimpleContentBlocksContext);
  const complexContent = useContext(ComplexContentBlocksContext);
  const [stackedContent, setStackedContent] = useState<SimpleContentModel[]>();
  const [dynamicContent, setDynamicContent] = useState<ComplexContentModel[]>();

  useEffect(() => {
    if (product && product !== null && DCFcategories) {
      const content = dcfService.GetDcfSetting(product, "Content", DCFcategories);
      const stacked = contentfulService.getSimpleContentFromIDs(content?.stacked?.value, simpleContent);
      const dynamic = contentfulService.getComplexContentFromIDs(content?.dynamic?.value, complexContent);
      setStackedContent(stacked);
      setDynamicContent(dynamic);
    }
  }, [product, simpleContent, complexContent, DCFcategories]);

  const slot2 = useMemo(() => {
    if (stackedContent && stackedContent.length) {
      return <StackedContentBlock content={stackedContent}></StackedContentBlock>;
    } else {
      return <HomeStackedContent />;
    }
  }, [stackedContent]);

  const slot3 = useMemo(() => {
    return (
      <BachmansCarousel
        variant="content"
        carouselData={dynamicContent || []}
        dotsInside={true}
        settings={carouselSettings}
      />
    );
  }, [dynamicContent]);

  return (
    <Fragment>
      <ContentRow
        title="Inspiration & Information to Bring Beautiful Ideas to Life"
        subTitle="Watch helpful how-to videos on our YouTube channel, browse our catalog of informative care sheets, or join us for fun and inspirational events!"
      >
        <FeaturedEventsCarousel />
        {slot2}
        {slot3}
      </ContentRow>
    </Fragment>
  );
};

export default ProductFeaturedContent;
