import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Container, Grid, createStyles, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { SortOption } from "../Shared/SortBy";
import SortBy from "../Shared/SortBy";
import Filters from "../Product/ProductList/Filters";
import { neutral } from "../../themes/colors";
import { useLocation } from "react-router";
import stringService from "../../services/string.service";
import { sortOptions, sortingCategoryOptions } from "../../constants/sortOptions";

interface ListActionHeaderProps {
  handleSortSelection: (option: SortOption) => void;
  handleToggleSortMenu: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    sortbyBackground: {
      background: neutral.text_utility_bg,
      margin: theme.spacing(3, 0),
    },
    filterWrapper: {
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
  })
);

const ListActionHeader: FunctionComponent<ListActionHeaderProps> = (props) => {
  const classes = useStyle();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { handleSortSelection, handleToggleSortMenu } = props;
  const [sortState, setSortState] = useState<string>("");
  const [categoryFlag, setcategoryFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const queryParams = stringService.GetQueryParamsFromLocation(location.search);
    setSortState(queryParams?.sort || "");
    if (window.location.href.includes("categorypage") == true) {
      setcategoryFlag(true);
    }
  }, [location.search]);

  return (
    <Fragment>
      {isDesktop ? (
        <div className={classes.sortbyBackground}>
          {categoryFlag && categoryFlag == true ? (
            <Container maxWidth="lg">
              <SortBy
                currentSortState={sortingCategoryOptions.find((option) => option.index === sortState)?.title || ""}
                sortOptions={sortingCategoryOptions}
                handleSelection={handleSortSelection}
              />
            </Container>
          ) : (
            <Container maxWidth="lg">
              <SortBy
                currentSortState={sortOptions.find((option) => option.index === sortState)?.title || ""}
                sortOptions={sortOptions}
                handleSelection={handleSortSelection}
              />
            </Container>
          )}
        </div>
      ) : (
        <Container maxWidth="lg" className={classes.filterWrapper}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={5} sm={6}>
              <Filters toggleMenu={handleToggleSortMenu} />
            </Grid>
            {categoryFlag && categoryFlag == true ? (
              <Grid item xs={7} sm={6}>
                <SortBy
                  currentSortState={sortingCategoryOptions.find((option) => option.index === sortState)?.title || ""}
                  sortOptions={sortingCategoryOptions}
                  handleSelection={handleSortSelection}
                />
              </Grid>
            ) : (
              <Grid item xs={7} sm={6}>
                <SortBy
                  currentSortState={sortOptions.find((option) => option.index === sortState)?.title || ""}
                  sortOptions={sortOptions}
                  handleSelection={handleSortSelection}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </Fragment>
  );
};

export default ListActionHeader;
