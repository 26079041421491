import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Close } from "@material-ui/icons";
import { Quantity } from "../../models/product";
import QuantityInput from "../../components/Shared/QuantityInput";
import DoubleOutlinedBtn from "../../components/Shared/DoubleOutlinedBtn";
import { Address, BuyerProduct } from "ordercloud-javascript-sdk";
import { cloneDeep, get, set } from "lodash";
import stringService from "../../services/string.service";
import PhoneMaskInput from "../../components/Shared/PhoneMaskInput";
import productService from "../../services/product.service";
import { useAppSelector } from "../../redux/store-hook";
import Currency from "react-currency-formatter";
import $ from "jquery";

interface GiftcardEmailtModalProps {
  onSubmit: (
    partial: Partial<Address>,
    eventQuantity: Quantity,
    email: string,
    sendEventEmail: boolean
  ) => Promise<void>;
  open: boolean;
  onClose: () => void;
  quantity: Quantity;
  event?: BuyerProduct;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "45vh",
      [theme.breakpoints.up("sm")]: {
        width: "40vw",
      },
    },
    DialogTitle: {
      padding: theme.spacing(4),
      fontSize: theme.typography.h1.fontSize,
    },
    TicketedEventModal: {
      padding: theme.spacing(0, 4),
      "& .MuiFormControl-root:first-child": {
        minWidth: 85,
      },
      "& .MuiTextField-root": {
        margin: 0,
      },
    },
    DialogActions: {
      padding: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        width: "50%",
        marginLeft: "auto",
      },
    },
  })
);

// Interface for Egiftcard user popup

type EventRequiredFields = "FirstName" | "LastName" | "Phone" | "Email";

const requiredFields: EventRequiredFields[] = ["FirstName", "LastName", "Phone", "Email"];

type EditedTracker = {
  [key in EventRequiredFields]: boolean;
};

const initialEditedTracker: EditedTracker = {
  FirstName: false,
  LastName: false,
  Phone: false,
  Email: false,
};
const GiftCardEmailTpl: React.FunctionComponent<GiftcardEmailtModalProps> = (props) => {
  const [eventRecipient, setEventRecipient] = useState<Partial<Address>>();
  const [edited, setEdited] = useState<EditedTracker>(initialEditedTracker);
  const [eventQuantity, setEventQuantity] = useState<Quantity>();
  const phoneInputRef = useRef<any>(null);
  const [email, setEmail] = useState<string>();
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const currentOrder = useAppSelector((state) => state.order);

  const { onSubmit, open, onClose, quantity, event, disabled } = props;

  const initQuantity = useCallback(() => {
    const minQuantity = productService.DetermineMinQuantity(event);

    const qu = quantity || {
      maxQuantity: productService.DetermineMaxQuantity(event, currentOrder),
      minQuantity: minQuantity,
      quantity: minQuantity ? minQuantity : 1,
    };
    setEventQuantity(qu);
  }, [currentOrder, event, quantity]);

  useEffect(() => {
    if (event && currentOrder) {
      initQuantity();
    }
  }, [event, currentOrder, quantity, initQuantity]);

  const getAmount = () => {
    const price = Number(event?.PriceSchedule?.PriceBreaks?.length ? event?.PriceSchedule?.PriceBreaks[0].Price : 0);
    return (eventQuantity?.quantity || 0) * price;
  };

  const handleClose = () => {
    onClose();
  };
  const handleQuantityChange = (selectedQuantity: number) => {
    setEventQuantity({
      ...eventQuantity,
      quantity: selectedQuantity,
    });
  };

  const handleAddressChange = (path: string) => (event: React.ChangeEvent<any>) => {
    if (edited) {
      setEdited(set(cloneDeep(edited), path, true));
    }
    const addressToUpdate = cloneDeep(eventRecipient);
    let val = event.target.value;
    if (["Zip", "Phone"].includes(path)) {
      val = stringService.OnlyNumbers(event.target.value);
    } else if (["FirstName", "LastName"].includes(path)) {
      val = stringService.OnlyAlpha(event.target.value);
    }
    const updatedAddress = set(addressToUpdate || {}, path, val);
    setEventRecipient(updatedAddress);
  };

  const displayError = (field: EventRequiredFields) => {
    const editedValue = get(eventRecipient, field) || "";
    return (
      edited &&
      edited[field] &&
      requiredFields.includes(field) &&
      (editedValue === "" || (field === "Phone" && editedValue.length < 10))
    );
  };

  const resetData = () => {
    setEventRecipient(undefined);
    setEdited(initialEditedTracker);
    setSendEmail(false);
    initQuantity();
    setEmail(undefined);
  };
  const handleSubmit = async () => {
    if (eventRecipient && eventQuantity && email) {
      await onSubmit(eventRecipient, eventQuantity, email, sendEmail);
      resetData();
    } else {
      throw new Error("Please fill out required fields");
    }
  };

  const isValid: boolean = useMemo(() => {
    let selectedQuantity = parseInt(String($("#standard-number").val()), 10);
    return !!(
      eventRecipient &&
      eventRecipient.FirstName &&
      eventRecipient.FirstName.length >= 1 &&
      eventRecipient.LastName &&
      eventRecipient.LastName.length >= 1 &&
      eventRecipient.Phone &&
      eventRecipient.Phone.length === 10 &&
      email &&
      stringService.ValidEmail(email)
    );
  }, [email, eventRecipient]);

  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.root }}
      PaperProps={{ square: true }}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.DialogTitle} disableTypography>
        <Typography variant="h2">Recipient Information</Typography>
        <Typography color="textSecondary">
          We'll email the gift card directly to the recipient entered below within an hour of placing your order.
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.TicketedEventModal}>
        <Box display="flex" alignItems="center" my={2} style={{ gap: 16 }}>
          <QuantityInput product={event} quantity={quantity} onChange={handleQuantityChange} disabled={!disabled} />
          <Close fontSize="small" />
          <div>
            <Typography style={{ textTransform: "capitalize" }} variant="h3">
              Email Gift Card
            </Typography>
          </div>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              margin="dense"
              variant="outlined"
              id="first"
              label="First Name"
              value={eventRecipient?.FirstName || ""}
              error={displayError("FirstName")}
              helperText={displayError("FirstName") && "First name is required"}
              onChange={handleAddressChange("FirstName")}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              margin="dense"
              variant="outlined"
              id="last"
              label="Last Name"
              value={eventRecipient?.LastName || ""}
              error={displayError("LastName")}
              helperText={displayError("LastName") && "Last name is required"}
              onChange={handleAddressChange("LastName")}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              //disabled={disabled}
              required
              margin="dense"
              variant="outlined"
              id="phone"
              label="Phone"
              InputProps={{ inputComponent: PhoneMaskInput as any }}
              inputRef={phoneInputRef}
              value={eventRecipient?.Phone || ""}
              error={displayError("Phone")}
              helperText={displayError("Phone") && "Please enter a 10 digit phone number"}
              onChange={handleAddressChange("Phone")}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              margin="dense"
              variant="outlined"
              id="email"
              label="Email"
              value={email || ""}
              error={!!(email && !stringService.ValidEmail(email))}
              helperText={!!(email && !stringService.ValidEmail(email)) && "Please enter a valid email"}
              onChange={(e: any) => setEmail(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        <DoubleOutlinedBtn
          buttonText={`Proceed`}
          buttonProps={{
            id: "btnAddEventTicketToCart",
            variant: "contained",
            fullWidth: true,
            disabled: !isValid,
            onClick: handleSubmit,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default GiftCardEmailTpl;
