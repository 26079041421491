import React, { Fragment, useState } from "react";
import { Box, Button, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import Recaptcha from "../Shared/Recaptcha";
import { neutral } from "../../themes/colors";
import jitterbitService from "../../services/jitterbit.service";
import LoopIcon from "@material-ui/icons/Loop";
import { Errors } from "../../constants/error.constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "16px",
      color: neutral.text_white_bg,
      marginBottom: theme.spacing(1),
    },
    mb: {
      marginBottom: theme.spacing(1),
    },
    fieldContainer: {
      marginBottom: theme.spacing(1),
    },
  })
);

const GiftCardBalanceCheck: React.FunctionComponent = () => {
  const classes = useStyles();
  const [gcNumber, setGcNumber] = useState<string>("");
  const [recaptchaVal, setRecaptchaVal] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [cardBalance, setCardBalance] = useState<number>();

  const handleSubmit = () => {
    jitterbitService.checkGcBalance(gcNumber).then((res) => {
      setRecaptchaVal(undefined);
      if (res.data.card_value !== Errors.giftCardNotAvailable && parseFloat(res.data.card_value)) {
        setCardBalance(res.data.card_value);
      } else {
        setErrorMessage("The card number you entered was not recognized as a valid Bachman's Gift Card.");
      }
    });
  };

  const handleReset = () => {
    setGcNumber("");
    setErrorMessage(undefined);
    setRecaptchaVal(undefined);
  };

  return (
    <Box>
      <Typography className={classes.header} variant="body1">
        Check your gift card balance
      </Typography>
      <div className={classes.mb}>
        <TextField
          fullWidth
          label="Check Balance"
          variant="outlined"
          size="small"
          value={gcNumber}
          onChange={(e: any) => setGcNumber(e.target.value)}
        />
      </div>
      {errorMessage && (
        <Typography color="error" variant="body1">
          {errorMessage}
        </Typography>
      )}
      {cardBalance && (
        <Fragment>
          <Typography variant="body1" style={{ display: "inline-block" }}>
            The card number entered has a balance of
          </Typography>
          <Typography variant="body1" style={{ display: "inline-block", fontWeight: "bold" }}>
            ${cardBalance}
          </Typography>
        </Fragment>
      )}
      <div className={classes.mb}>
        {!recaptchaVal && (
          <Recaptcha onChange={(val: string) => setRecaptchaVal(val)} onError={(val: string) => setErrorMessage(val)} />
        )}
      </div>
      <Button
        onClick={handleSubmit}
        disabled={!recaptchaVal || !gcNumber || gcNumber === ""}
        color="primary"
        variant="contained"
      >
        Check Balance
      </Button>
      {errorMessage && (
        <Button onClick={handleReset}>
          Try Again <LoopIcon />
        </Button>
      )}
    </Box>
  );
};

export default GiftCardBalanceCheck;
