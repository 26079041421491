import { cloneDeep, filter, flatten, isUndefined, map, sumBy } from "lodash";
import { Address, BuyerAddress, Me, Order, Orders } from "ordercloud-javascript-sdk";
import BachmansOrder from "../models/order";
import BachmansShipment, { ValidatedBachmansShipmentWithLineItems } from "../models/shipment";
import { OrderState } from "../redux/slices/order";
import bachmansIntegrationsService from "./bachmansIntegrations.service";
import { Errors } from "../constants/error.constants";
import deliveryService from "./delivery.service";

function _arrSum(arr: any[]) {
  return arr.reduce(function (a, b) {
    return a + b;
  }, 0);
}

//BAC-2785 Get picking fee from array of shipments
function getOrderPickingFee(shipmentArray: BachmansShipment[]) {
  return _arrSum(
    map(
      filter(shipmentArray, function (s: BachmansShipment) {
        return deliveryService.IsPickUp(s?.Shipper);
      }),
      "Cost"
    )
  );
}

const validateGiftCardOnOrder = async (order: OrderState): Promise<any> => {
  const giftCardLis = flatten(order.shipments?.map((s) => s.LineItems)).filter((li) => li.ProductID === "GIFTCARD");
  const giftCardQuantity = sumBy(giftCardLis, (li) => li.Quantity);

  let GiftCardInventoryExceeded: any = {
    Message: Errors.giftCardInventoryExceeded,
  };
  let GiftCardProcessingError = {
    Message: Errors.giftCardProcessingError,
  };

  if (!giftCardQuantity || giftCardQuantity === 0) {
    return Promise.resolve();
  } else {
    try {
      var validationResult = await bachmansIntegrationsService.validateGiftCard(giftCardQuantity);
      if (validationResult.IsValid) return Promise.resolve();
      if (validationResult.NumberAvailable <= 0) {
        GiftCardInventoryExceeded.MaxQuantity = validationResult.NumberAvailable;
        return Promise.reject(GiftCardInventoryExceeded);
      } else {
        return Promise.reject(GiftCardProcessingError);
      }
    } catch (err: any) {
      if (err?.Message) return Promise.reject(err.Message);
      return Promise.reject(GiftCardProcessingError);
    }
  }
};

const setBillingAddress = async (
  order: BachmansOrder,
  address: BuyerAddress,
  saveAsNewAddress?: boolean
): Promise<Order> => {
  let billingAddress = cloneDeep(address);
  billingAddress.Billing = true;

  if (!billingAddress.ID) {
    billingAddress.Country = "US";
    if (saveAsNewAddress) {
      billingAddress.Shipping = false;
      const savedaddress = await Me.CreateAddress(billingAddress);
      return Orders.Patch("Outgoing", order.ID!, { BillingAddressID: savedaddress.ID });
    }
    return Orders.SetBillingAddress("Outgoing", order.ID!, billingAddress as Address);
  } else {
    await Me.PatchAddress(billingAddress.ID, billingAddress);
    return Orders.Patch("Outgoing", order.ID!, { BillingAddressID: billingAddress.ID });
  }
};

const hasCardMessage = (shipment: ValidatedBachmansShipmentWithLineItems) => {
  return (
    !isUndefined(shipment.xp?.CardMessage?.line1) ||
    !isUndefined(shipment.xp?.CardMessage?.line2) ||
    !isUndefined(shipment.xp?.CardMessage?.line3) ||
    !isUndefined(shipment.xp?.CardMessage?.line4)
  );
};

const service = {
  GetOrderPickingFee: getOrderPickingFee,
  ValidateGiftCardOnOrder: validateGiftCardOnOrder,
  SetBillingAddress: setBillingAddress,
  HasCardMessage: hasCardMessage,
};

export default service;
