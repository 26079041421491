import React from "react";
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faTwitterSquare, faPinterestSquare } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: "2px",
    },
    icon: {
      color: "#BCBCBC",
    },
  })
);

const FilledSocialMedia: React.FunctionComponent = () => {
  const classes = useStyle();
  return (
    <div>
      <IconButton className={classes.button}>
        <FontAwesomeIcon className={classes.icon} icon={faTwitterSquare} />
      </IconButton>
      <IconButton className={classes.button}>
        <FontAwesomeIcon className={classes.icon} icon={faFacebookSquare} />
      </IconButton>
      <IconButton className={classes.button}>
        <FontAwesomeIcon className={classes.icon} icon={faPinterestSquare} />
      </IconButton>
      <IconButton className={classes.button}>
        <FontAwesomeIcon className={classes.icon} icon={faEnvelopeSquare} />
      </IconButton>
    </div>
  );
};

export default FilledSocialMedia;
