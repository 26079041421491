import { Category, Me } from "ordercloud-javascript-sdk";
import dcfService from "./dcf.service";
import { BachmansProduct } from "../models/product";
import productService from "./product.service";
import { cloneDeep, map } from "lodash";
import { DrawerProduct } from "../models/aseDrawer";
import { OrderState } from "../redux/slices/order";
import categoryService from "./category.service";

const getUpsellCategories = (product: BachmansProduct, dcfCategories: Category[]) => {
  var ids = dcfService.GetDcfSetting(product, "ASE", dcfCategories);
  return categoryService.GetAseCategoriesFromIDs(ids);
};

const getUpsellProducts = (category: Category, currentOrder: OrderState) => {
  if (category.ID === "GIFTCARDS") {
    return createMockGiftcards();
  } else {
    return productService
      .ListOcProducts({ catalogID: "ASE", categoryID: category.ID, pageSize: 100 })
      .then((productList) => {
        var mappedProds: DrawerProduct[] = productList.Items.map((p: any) => {
          p.ImageUrl =
            p.xp.Contentful && p.xp.Contentful.Images && p.xp.Contentful.Images.length
              ? p.xp.Contentful.Images[0].url
              : "";
          return p;
        });
        //TODO: add product filtering on list call and set DisableAddToCart
        //return mappedProds.filter(p => !p.DisableAddtoCart && productService.DetermineMaxQuantity( p ) > 0)
        return mappedProds.filter((p) => productService.DetermineMaxQuantity(p, currentOrder) > 0);
      });
  }
};

const createMockGiftcards = () => {
  var amounts = [25, 50, 75, 100, 150, 250, 500];
  return Me.GetProduct("GIFTCARD").then((giftCardProduct: any) => {
    giftCardProduct.Mock = true;
    giftCardProduct.ImageUrl =
      giftCardProduct.xp.Contentful &&
      giftCardProduct.xp.Contentful.Images &&
      giftCardProduct.xp.Contentful.Images.length
        ? giftCardProduct.xp.Contentful.Images[0].url
        : "";
    return map(amounts, function (amount) {
      var copy: DrawerProduct = cloneDeep(giftCardProduct);
      copy.Amount = amount;
      if (copy.PriceSchedule) {
        copy.PriceSchedule.xp.Price.RetailPrice = amount;
        if (copy.PriceSchedule.PriceBreaks) {
          copy.PriceSchedule.PriceBreaks[0].Price = amount;
        }
      }
      copy.Name = "$" + amount + " " + copy.Name;
      copy.xp.WebFacingProductTitle = copy.Name;
      return copy;
    });
  });
};

const service = {
  GetUpsellCategories: getUpsellCategories,
  GetUpsellProducts: getUpsellProducts,
};

export default service;
