import Case from "case";
import bachDateTime from "./bachDateTime.service";
import moment from "moment-timezone";
import productService from "./product.service";
import { Category, Me, RequiredDeep } from "ordercloud-javascript-sdk";
import { findIndex, isNull, isUndefined } from "lodash";

const buildCategoryUrlFromId = (id: string, urlPrefixKey: string, pageDesc?: string, queryID?: any): string => {
  let url = id.split("_").map(Case.kebab).join("/");
  var cleanPageDesc = pageDesc ? stripHtml(pageDesc).toLowerCase() : "";
  if (cleanPageDesc && cleanPageDesc.includes("redirect:")) {
    var redirectSuffix = cleanPageDesc.replace("redirect:", "");
    return `/${redirectSuffix}`;
  }
  var finalUrl = `/${urlPrefixKey}/${url}`;
  if (isL3Category(id)) {
    return finalUrl.replace(/.(?!.*\/)/, "?c=");
  }
  return finalUrl;
};

const stripHtml = (value: string): string => {
  return value.replace(/(<([^>]+)>)/gi, "").trim();
};

const isL3Category = (id: string) => id.split("_").length >= 3;

//to return l3 from this eg. BachmansDesignStudio  to this bachmans-design-studio
const buildCategoryIdforQuery = (id: string) => {
  let param = id.split("_").map(Case.kebab).join("/");
  let formattedCatID = param.match(/[^/]*$/);
  return formattedCatID ? formattedCatID[0] : "";
};

const categoriesFilter = (items: RequiredDeep<Category>[]) => {
  var now = bachDateTime.Today();
  var newArray: RequiredDeep<Category>[] = [];
  if (items && items.length > 0) {
    items.forEach((category) => {
      if (category) {
        var startDate = moment(category.xp.StartDate).tz("America/Chicago").startOf("d");
        var endDate = moment(category.xp.EndDate).tz("America/Chicago").endOf("d");
        if (now.isBetween(startDate, endDate) && category.Active) {
          newArray.push(category);
        }
      }
    });
  }
  return newArray;
};

const hasContentOnCategory = (category: Category) => {
  if (isUndefined(category?.xp?.Content) || isNull(category?.xp?.Content)) return false;
  return true;
};

const getAseCategoriesFromIDs = (aseCategoryIDs: string[]) => {
  if (aseCategoryIDs && aseCategoryIDs.length > 0) {
    return Me.ListCategories({ catalogID: "ASE", filters: { ID: aseCategoryIDs.join("|") } }).then((response) => {
      if (response.Items.length === 0) return [];
      var orderedArray: Category[] = [];
      aseCategoryIDs.forEach((id) => {
        var cat = response?.Items?.find((item) => item.ID === id);
        if (cat) {
          orderedArray.push(cat);
        }
      });
      return orderedArray;
    });
  } else {
    return Promise.resolve([]);
  }
};
const buildCategoryIdFromParts = (parts: string[]): string => {
  return parts.map(Case.pascal).join("_");
};

const buildBreadcrumb = async (categoryID: string, productID: string, categories: Category[]): Promise<any[]> => {
  var eventLanding = { Name: "Workshops & Events", ID: "workshopEventLanding", IsPage: true };
  var breadcrumb: any[] = [];

  const build = (categories: Category[], catID: string): any[] => {
    const category = categories.find((c) => c.ID === catID);
    if (!category && breadcrumb.length) return breadcrumb;
    (category as any).event = category?.ID && category?.ID?.indexOf("WorkshopsEvents") > -1;
    breadcrumb?.unshift(category);
    if (category?.ParentID) return build(categories, category.ParentID);
    return breadcrumb;
  };

  const complete = (currentCat: any): any[] => {
    if (currentCat.ID === "WorkshopsEvents") {
      currentCat.Name = "All Seminars & Events";
      breadcrumb.push(eventLanding);
    } else if (currentCat.event) {
      breadcrumb[findIndex(breadcrumb, { ID: "WorkshopsEvents" })] = eventLanding;
    }
    breadcrumb.push(currentCat);
    return breadcrumb;
  };

  var myCrumbs = build(categories, categoryID);
  if (myCrumbs.length) {
    return complete(myCrumbs.pop());
  } else if (productID) {
    const cat = await productService.GetProductCategory(productID, categories);
    return complete(cat);
  } else return [];
};

const service = {
  BuildCategoryUrlFromId: buildCategoryUrlFromId,
  BuildCategoryIdforQuery: buildCategoryIdforQuery,
  BuildCategoryIdFromParts: buildCategoryIdFromParts,
  CategoriesFilter: categoriesFilter,
  HasContentOnCategory: hasContentOnCategory,
  GetAseCategoriesFromIDs: getAseCategoriesFromIDs,
  BuildBreadCrumb: buildBreadcrumb,
};
export default service;
