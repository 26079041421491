import {
  createStyles,
  makeStyles,
  Popover,
  PopoverOrigin,
  Theme,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface DropdownMenuProps {
  open: boolean;
  anchorEl: any;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  arrowPosition?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {
      pointerEvents: "none",
    },
    arrow: {
      top: -17,
      // left: "25%",
      width: 0,
      height: 0,
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        display: "block",
        width: 0,
        height: 0,
        borderWidth: "13px 12px",
        borderStyle: "solid",
        borderColor: "transparent",
        zIndex: theme.zIndex.modal + 1,
        bottom: -17,
        right: -8,
        borderBottomColor: theme.palette.grey[300],
      },
      "&:after": {
        content: "''",
        position: "absolute",
        display: "block",
        width: 0,
        height: 0,
        borderWidth: "13px 12px",
        borderStyle: "solid",
        borderColor: "transparent",
        zIndex: theme.zIndex.modal + 1,
        bottom: -18,
        right: -8,
        borderBottomColor: theme.palette.background.paper,
      },
    },
    paperRoot: {
      overflow: "visible",
      pointerEvents: "all",
    },
  })
);

const DropdownMenu: FunctionComponent<DropdownMenuProps> = (props) => {
  const {
    children,
    open,
    anchorEl,
    arrowPosition,
    anchorOrigin,
    transformOrigin,
  } = props;
  const classes = useStyles();
  let arrowLeftPosition = "%";
  if (arrowPosition) {
    switch (arrowPosition) {
      case "left":
        arrowLeftPosition = "25%";
        break;
      case "center":
        arrowLeftPosition = "50%";
        break;
      case "right":
        arrowLeftPosition = "75%";
        break;
      default:
        arrowLeftPosition = "75%";
    }
  }

  return (
    <Popover
      className={classes.popoverRoot}
      elevation={3}
      PaperProps={{
        square: true,
        classes: {
          root: classes.paperRoot,
        },
      }}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: "bottom",
              horizontal: "center",
            }
      }
      transformOrigin={
        transformOrigin
          ? transformOrigin
          : {
              vertical: "top",
              horizontal: "center",
            }
      }
      anchorEl={anchorEl}
      open={open}
    >
      <div className={classes.arrow} style={{ left: arrowLeftPosition }}></div>
      {children}
    </Popover>
  );
};

export default DropdownMenu;
