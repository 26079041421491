import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Container, Typography, Box, makeStyles, Theme, createStyles } from "@material-ui/core";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import ReactToPrint from "react-to-print";
import PrintOrderConfirmation from "./PrintOrderConfirmation";
import bachmansIntegrationsService, { OrderCalculateResponse } from "../../services/bachmansIntegrations.service";
import { decorateCalcResponse } from "../../redux/slices/order";
import { ValidatedLineItem } from "../../models/shipment";

export interface OrderConfirmationRouteMatch {
  userID: string;
  orderID: string;
}
export interface OrderConfirmationProps {
  lineItem: ValidatedLineItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderConfirmationContainer: {
      display: "grid",
      placeItems: "center center",
      minHeight: "50vh",
    },
    cardBody: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      "& .MuiTypography-h1": {
        fontSize: 32,
        fontWeight: theme.typography.fontWeightBold,
      },
      "& .MuiTypography-body1:first-of-type": {
        marginTop: theme.spacing(3),
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h4.fontSize,
      },
      "& .MuiTypography-body1:last-of-type": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    cardButtonGroup: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      gap: theme.spacing(3),
      "& button": {
        minWidth: 225,
      },
    },
  })
);

const OrderConfirmation: React.FunctionComponent<OrderConfirmationProps> = () => {
  const history = useHistory();
  const match = useRouteMatch<OrderConfirmationRouteMatch>();
  const classes = useStyles();
  const componentRef = React.useRef(null);
  const [placedOrder, setPlacedOrder] = useState<OrderCalculateResponse>();

  useEffect(() => {
    (async () => {
      const calcResponse = await bachmansIntegrationsService.order.calculate(match.params.orderID);
      const decoratedResponse = await decorateCalcResponse(calcResponse);
      setPlacedOrder(decoratedResponse);
    })();
  }, [match.params.orderID]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div>
        <DoubleOutlinedBtn
          buttonText="Print Order Confirmation"
          buttonProps={{ variant: "contained", color: "primary" }}
        />
      </div>
    );
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  return (
    <Container className={classes.orderConfirmationContainer}>
      <Box className={classes.cardBody}>
        <Typography variant="h1" color="primary">
          Thank you for your order!
        </Typography>
        <Typography variant="body1">Look for an email shortly from Bachman's</Typography>
        <Typography variant="body1">Your Order Confirmation #: {match.params.orderID}</Typography>
        {placedOrder && (
          <Box className={classes.cardButtonGroup}>
            <ReactToPrint
              trigger={reactToPrintTrigger}
              content={reactToPrintContent}
              documentTitle={`Bachman's-Order-Confirmation-${match.params.orderID}`}
            />
            <DoubleOutlinedBtn
              buttonText="Continue Shopping"
              buttonProps={{ variant: "outlined", onClick: () => history.push("/") }}
            />
          </Box>
        )}
      </Box>
      <Box displayPrint="block" display="none">
        {placedOrder && (
          <div ref={componentRef}>
            <PrintOrderConfirmation order={placedOrder.Order} shipments={placedOrder.CalculatorShipments} />
          </div>
        )}
      </Box>
    </Container>
  );
};

export default OrderConfirmation;
