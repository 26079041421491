export interface HolidayNamesType {
  [key: string]: string[];
}

export const HolidayMatch: HolidayNamesType = {
  "New Years Day": ["new years", "new years day"],
  "Martin Luther King Jr Day": ["martin luther king jr day", "martin luther king day", "martin luther day"],
  "Independence Day": ["independence day", "july 4th", "4th of july", "fourth of july", "july fourth"],
  "Labor Day": ["labor day"],
  "Veterans Day": ["veterans day"],
  Thanksgiving: ["thanksgiving", "thanksgiving day"],
  "Black Friday": ["black friday", "day after thanksgiving"],
  "Christmas Eve": ["christmas eve", "christmas evening", "christmas night"],
  "Christmas Day": ["christmas day", "christmas"],
  "Valentines Day": ["valentines day", "valentines"],
  "Presidents Day": ["presidents day"],
  Easter: ["easter", "easter sunday"],
  "Cinco de Mayo": ["cinco de mayo"],
  "Mothers Day": ["mothers day"],
  "Memorial Day": ["memorial day"],
  Halloween: ["halloween"],
};
