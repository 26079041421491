import { AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Address, LineItem, LineItems, Me, Order, Orders, Tokens } from "ordercloud-javascript-sdk";
import BachmansOrder from "../../models/order";
import { BachmansShipmentWithLineItems } from "../../models/shipment";
import bachmansIntegrations, { OrderCalculateResponse } from "../../services/bachmansIntegrations.service";
import { AppThunk } from "../store";
import jwtDecode from "jwt-decode";
import { flatten, sumBy, uniq } from "lodash";
import { DeliveryTypes } from "../../models/buyerXp";
import productService from "../../services/product.service";
import DecorateCalcResponseError from "../../models/DecorateCalcResponseError";
export interface OrderState {
  status: "pending" | "success" | "failed";
  order?: BachmansOrder;
  shipments?: BachmansShipmentWithLineItems[];
  error?: any;
  
}


const initialState: OrderState = {
  status: "pending",
  
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setPending(state) {
      state.status = "pending";
    },
    setOrderSuccess(state, action: PayloadAction<Order | undefined>) {
      state.status = "success";
      state.order = action.payload;
      state.error = undefined;
    },
    setOrderFailed(state, action: PayloadAction<any>) {
      state.status = "failed";
      state.error = action.payload;
    },
    setCalcSuccess(state, action: PayloadAction<OrderCalculateResponse>) {
      state.status = "success";
      state.order = action.payload.Order;
      state.shipments = action.payload.CalculatorShipments;
      state.error = undefined;
    },
    setCalcFailed(state, action: PayloadAction<any>) {
      state.status = "failed";
      state.error = action.payload;
    },
    extraReducers: (builder: any) => {
      builder.addCase(calculateOrder.pending, (state: any, action: any) => {
        state.status = "pending";
      });
    },
  },
});

const { setPending, setOrderSuccess, setOrderFailed, setCalcSuccess, setCalcFailed } = orderSlice.actions;

export default orderSlice.reducer;

export const transferAndInitOrder = (anonUserToken: string): AppThunk<void> => async (dispatch) => {
  dispatch(setPending());
  try {
    await Me.TransferAnonUserOrder({ anonUserToken: anonUserToken });
  } finally {
    await dispatch(initOrder());
  }
};

export const initOrder = (): AppThunk<void> => async (dispatch) => {
  const token = Tokens.GetAccessToken();
  if (token) {
    try {
      dispatch(setPending());
      // Step 1 get order
      const decoded: any = jwtDecode(token);
      var isAnon = decoded?.usr === "anon-template-user";
      const currentOrder = await getOrder(isAnon, token, decoded.orderid);
      dispatch(setOrderSuccess(currentOrder));

      // step 2 calculate order if there are line items
      if (currentOrder && currentOrder?.LineItemCount && currentOrder.LineItemCount > 0) {
          
        await dispatch(calculateOrder(currentOrder.ID!));
      } else {
        // no line items on order, reset shipments
        dispatch(
          setCalcSuccess({
            Order: currentOrder,
            CalculatorShipments: [],

          })
        );
      }
    } catch (ex) {
      dispatch(setOrderFailed(ex));
    }
  }
};

const getOrder = async (isAnon: boolean, token: string, orderid?: string): Promise<Order> => {
  let order;
  if (isAnon) {
    try {
      order = orderid ? await Orders.Get("Outgoing", orderid, { accessToken: token }) : makeOrder(isAnon, orderid);
    } catch {
      order = makeOrder(isAnon, orderid);
    }
  } else {
    const orderList = await Me.ListOrders<BachmansOrder>({
      sortBy: "!DateCreated" as any,
      filters: { status: "Unsubmitted" as any },
    });
    order = orderList?.Items[0];
  }
  return order ? order : makeOrder(isAnon, orderid);
};

const makeOrder = async (isAnon: boolean, orderID?: string): Promise<Order> => {
  if (isAnon) {
    return {
      ID: orderID,
    };
  } else {
    return await Orders.Create("Outgoing", {});
  }
};

export const calculateOrder = createAsyncThunk("order/calculate", async (orderID: string,ThunkAPI) => {
  try {

    const calcResponse = await bachmansIntegrations.order.calculate(orderID);
    const decoratedResponse = await decorateCalcResponse(calcResponse);
    ThunkAPI.dispatch(setCalcSuccess(decoratedResponse));
  } catch (ex: any) {
    if (ex.LineItemIDs) {
      await ThunkAPI.dispatch(deleteLi({ idsToDelete: ex.LineItemIDs, orderID: orderID }));
    }
    ThunkAPI.dispatch(setCalcFailed(ex));
  }
});

export interface DecorateCalcError {
  Message?: string;
  LineItemIDS?: string[];
}

export const decorateCalcResponse = async (calcResponse: OrderCalculateResponse): Promise<OrderCalculateResponse> => {
  let lineItemDeleteQueue: string[] = [];
  const uniqueProdIds = uniq(
    flatten(calcResponse.CalculatorShipments.map((s) => s.LineItems.map((li) => li.ProductID)))
  );

  const productsOnOrder = await productService.ListOcProducts({ filters: { ID: uniqueProdIds.join("|") } });

  const lineItems = flatten(calcResponse.CalculatorShipments.map((s) => s.LineItems));
  lineItems.forEach((li) => {
    const matchingPrd = productsOnOrder.Items.find((p) => p.ID === li.ProductID);
    if (!matchingPrd && li.ID && uniqueProdIds[0] !== "EGIFTCARD") {
      lineItemDeleteQueue.push(li.ID!);
    }
  });
  if (lineItemDeleteQueue.length > 0) {
    throw new DecorateCalcResponseError("Products not available", lineItemDeleteQueue);
  }
  const productSpecReqs = uniqueProdIds?.map((id) => Me.ListSpecs(id));
  const productSpecResulsts = flatten((await Promise.all(productSpecReqs))?.map((res) => res.Items));
  calcResponse.Order.CartCount = 0;
  calcResponse.Order.WiredServiceCharges = 0;
  calcResponse.Order.HandlingCharges = 0;
  calcResponse.Order.AssemblyCharges = 0;
  calcResponse.Order.PlacementCharges = 0;
  calcResponse.Order.HasUnavailableProducts = false;
  calcResponse.CalculatorShipments.forEach(async (shipment) => {
    let plcMarkup = 0;
    let asMarkup = 0;
    shipment.LineItems.forEach((li) => {
      li?.Specs?.forEach((spec) => {
        const matchingSpec = productSpecResulsts.find((s) => s.ID === spec.SpecID);
        const matchingOption = matchingSpec?.Options.find((opt) => opt.ID === spec.OptionID);
        if (matchingOption?.ID === "AssemblyCharge") {
          asMarkup += matchingOption?.PriceMarkup || 0;
        } else if (matchingOption?.ID === "PlacementCharge") {
          plcMarkup += matchingOption?.PriceMarkup || 0;
        }
      });
      calcResponse.Order.CartCount! += li.Quantity;
    });
    shipment.AssemblyCharges = asMarkup;
    shipment.PlacementCharges = plcMarkup;
    shipment.Subtotal = sumBy(shipment.LineItems, (item) => item.LineTotal || 0);
    shipment.Total = shipment.Subtotal + (shipment.Cost || 0) + asMarkup + plcMarkup;
  });
  return calcResponse;
};

export interface patchLiQuantityParams {
  orderID: string;
  liID: string;
  quantity: number;
  disableCalcOrder?: boolean;
}

export const patchLiQuanity = createAsyncThunk(
  "order/patchLiQuantity",
  async (args: patchLiQuantityParams, thunkAPI) => {
    const { orderID, liID, quantity, disableCalcOrder } = args;
    try {
      await LineItems.Patch("Outgoing", orderID, liID, { Quantity: quantity });
      if (!disableCalcOrder) await thunkAPI.dispatch(calculateOrder(orderID));
    } catch (ex) {
      return thunkAPI.rejectWithValue(ex);
    }
  }
);

export interface SetShippingAddressPayload {
  shipmentID: string;
  address: Address;
  routeCode?: string;
  deliveryMethod?: DeliveryTypes;
}

export const setShipmentShippingAddress = createAsyncThunk(
  "order/shippingAddress",
  async (payload: SetShippingAddressPayload, thunkAPI) => {
    const { shipmentID, address, routeCode, deliveryMethod } = payload;
    const { order } = thunkAPI.getState() as {
      order: OrderState;
    };
    let partial: Partial<LineItem> = {
      xp: {
        RouteCode: routeCode,
        AddressType: address.xp?.addressType,
      },
    };
    if (deliveryMethod && deliveryMethod !== "Email") {
      partial.xp.DeliveryMethod = deliveryMethod;
    }
    if (address.ID && deliveryMethod !== "Email") {
      partial.ShippingAddressID = address.ID;
      await thunkAPI.dispatch(patchLineItems({ shipmentID: shipmentID, partial: partial }));
    } else {
      let reqs: Promise<any>[] = [];
      const itemsToUpdate = order?.shipments?.find((shipment) => shipment.ID === shipmentID)?.LineItems;

      itemsToUpdate?.forEach((item) => {
        reqs.push(LineItems.SetShippingAddress("Outgoing", order?.order?.ID!, item.ID!, address));
        reqs.push(LineItems.Patch("Outgoing", order?.order?.ID!, item.ID!, partial));
      });
      thunkAPI.dispatch(setPending());
      await Promise.all(reqs);
      await thunkAPI.dispatch(calculateOrder(order?.order?.ID!));
    }
    if (deliveryMethod === "Email") {
      let reqs: Promise<any>[] = [];
      const itemsToUpdate = order?.shipments?.find((shipment) => shipment.ID === shipmentID)?.LineItems;

      itemsToUpdate?.forEach((item) => {
        reqs.push(LineItems.SetShippingAddress("Outgoing", order?.order?.ID!, item.ID!, address));
        reqs.push(LineItems.Patch("Outgoing", order?.order?.ID!, item.ID!, address));
      });
      thunkAPI.dispatch(setPending());
      await Promise.all(reqs);
      await thunkAPI.dispatch(calculateOrder(order?.order?.ID!));
    }
  }
);

export interface SetLineItemAddressPayload {
  lineItemID: string;
  address: Address;
  routeCode?: string;
  deliveryMethod?: DeliveryTypes;
  AddressType?: any;
}

const getRequests = (
  order: OrderState,
  lineItemID: string,
  address: Address,
  routeCode?: string,
  deliveryMethod?: DeliveryTypes
): Promise<any>[] => {
  let promises: Promise<any>[] = [];
  const partialLineItem: Partial<LineItem> = {
    xp: {
      RouteCode: routeCode,
      AddressType: address?.xp?.addressType,
    },
  };
  if (deliveryMethod) {
    partialLineItem.xp.DeliveryMethod = deliveryMethod;
  }
  if (address.ID) {
    partialLineItem.ShippingAddressID = address.ID;
    promises.push(LineItems.Patch("Outgoing", order.order?.ID!, lineItemID, partialLineItem));
  } else {
    promises = promises.concat([
      LineItems.SetShippingAddress("Outgoing", order.order?.ID!, lineItemID, address as Address),
      LineItems.Patch("Outgoing", order.order?.ID!, lineItemID, partialLineItem),
    ]);
  }
  return promises;
};

export const setLineItemShippingAddress = createAsyncThunk(
  "order/lineItem/shippingAddress",
  async (payload: SetLineItemAddressPayload[], thunkAPI) => {
    let requests: Promise<any>[] = [];
    const { order } = thunkAPI.getState() as {
      order: OrderState;
    };

    payload.forEach((p) => {
      if (p.deliveryMethod !== "Email") {
        const { lineItemID, address, routeCode, deliveryMethod } = p;
        const reqs = getRequests(order, lineItemID, address, routeCode, deliveryMethod);
        requests = requests.concat(reqs);
      }
    });
    thunkAPI.dispatch(setPending());
    await Promise.all(requests);
    await thunkAPI.dispatch(calculateOrder(order.order?.ID!));
  }
);

export interface PatchLineItemPayload {
  lineItemIDs?: string[];
  shipmentID?: string;
  partial: Partial<LineItem>;
}

export const patchLineItems = createAsyncThunk(
  "order/patchLineItems",
  async (payload: PatchLineItemPayload, thunkAPI) => {
    const { lineItemIDs, shipmentID, partial } = payload;
    const { order } = thunkAPI.getState() as {
      order: OrderState;
    };
    let reqs: Promise<LineItem>[] | undefined = [];
    if (shipmentID) {
      const itemsToUpdate = order?.shipments?.find((shipment) => shipment.ID === shipmentID)?.LineItems;
      reqs = itemsToUpdate?.map((item) => LineItems.Patch("Outgoing", order?.order?.ID!, item.ID!, partial));
    } else if (lineItemIDs) {
      reqs = lineItemIDs.map((id) => LineItems.Patch("Outgoing", order?.order?.ID!, id, partial));
    }
    thunkAPI.dispatch(setPending());
    await Promise.all(reqs || []);
    await thunkAPI.dispatch(calculateOrder(order?.order?.ID!));
  }
);

interface OrderPatchArgs {
  orderID: string;
  partial: Partial<Order>;
  
}
export const patchOrder = createAsyncThunk(
  "order/patch",
  async (args: OrderPatchArgs, ThunkAPI): Promise<Order> => {
    const patchedOrder = await Orders.Patch("Outgoing", args.orderID, args.partial);
    if(patchedOrder.xp.shipToMultiple)
    {
       await dispatch(calculateOrder(patchedOrder.ID));
       return patchedOrder;
    }
    else{ await ThunkAPI.dispatch(calculateOrder(patchedOrder.ID));
     
      return patchedOrder;
    }
  
  }
);

interface DeleteLiArgs {
  idsToDelete: string[];
  orderID: string;
}

export const deleteLi = createAsyncThunk(
  "lineitem/delete",
  async (args: DeleteLiArgs, ThunkAPI): Promise<any> => {
    if (args.idsToDelete.length > 0) {
      alert(
        "Shopping Cart Notice: One item in your cart is no longer available for purchase. This item has been removed from your cart. "
      );
      const deleteQueue = args.idsToDelete.map((id) => LineItems.Delete("Outgoing", args.orderID, id));
      await Promise.all(deleteQueue);
      await ThunkAPI.dispatch(calculateOrder(args.orderID));
    }
  }
);
function dispatch(arg0: AsyncThunkAction<void, string, {}>) {
  throw new Error("Function not implemented.");
}

