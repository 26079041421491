import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import SearchResultsHeader from "./SearchResultHeader";
import SearchResult from "./SearchResult";
import { searchType } from "../../models/search";

interface SearchResultsProps {
  variant: searchType;
  results: any[];
  queryTerm: string;
  toggleMenu: () => void;
  queryID?: any;
  clickAll?: (params: any) => any;
}

// const useStyle = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//     },
//   })
// );

const SearchResults: FunctionComponent<SearchResultsProps> = (props) => {
  const { variant, results, queryTerm, toggleMenu, queryID, clickAll } = props;
  const [limitedResults, setLimitedResults] = useState<any>([]);
  const [limitedSuggestions, setLimitedSuggestions] = useState<any>([]);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  let displayItems = 5;
  let displaySuggestions = 3;
  let processedResultsForCat: any = [];
  useEffect(() => {
    if (results) {
      if (variant === "Categories") {
        results.forEach((item, index) => {
          if (index === 0) {
            processedResultsForCat.push(item);
          } else {
            const found = processedResultsForCat.some(
              (someItem: any) =>
                item?.products?.facets?.exact_matches?.Category[0]?.value ===
                someItem?.products?.facets?.exact_matches?.Category[0]?.value
            );
            if (!found) processedResultsForCat.push(item);
          }
        });
        setLimitedResults([...processedResultsForCat].splice(0, isDesktop ? displayItems : 10));
        setLimitedSuggestions([...processedResultsForCat].splice(0, isDesktop ? displaySuggestions : 2));
      } else {
        setLimitedResults([...results].splice(0, isDesktop ? displayItems : 10));
        setLimitedSuggestions([...results].splice(0, isDesktop ? displaySuggestions : 2));
      }
    }
  }, [results, isDesktop, displayItems]);
  return (
    <React.Fragment>
      {results && variant !== "Query Suggestions" && variant !== "Categories" ? (
        <SearchResultsHeader
          variant={variant}
          queryTerm={queryTerm}
          toggleMenu={toggleMenu}
          result={limitedResults}
          queryID={queryID}
        />
      ) : (
        <SearchResultsHeader variant={variant} queryTerm={queryTerm} toggleMenu={toggleMenu} result={limitedResults} />
      )}
      {limitedResults && variant !== "Query Suggestions" && variant !== "Categories"
        ? limitedResults.map((result: any, index: number) => (
            <Fragment key={index}>
              <SearchResult
                key={`${variant}-${index}`}
                variant={variant}
                result={result}
                toggleMenu={toggleMenu}
                queryTerm={queryTerm}
                results={limitedResults}
                queryID={queryID}
                index={index}
              />
              {/* <Divider /> */}
            </Fragment>
          ))
        : null}
      {limitedSuggestions && variant === "Query Suggestions"
        ? limitedSuggestions.map((result: any, index: number) => (
            <Fragment key={index}>
              <SearchResult
                key={`${variant}-${index}`}
                variant={variant}
                result={result}
                toggleMenu={toggleMenu}
                queryTerm={queryTerm}
                results={limitedSuggestions}
                queryID={queryID}
                clickAll={clickAll}
                index={index}
              />
              {/* <Divider /> */}
            </Fragment>
          ))
        : null}
      {/* {limitedSuggestions && variant === "Query Suggestions" && (
          console.log("Filtered categoris which is 3 in number---?",limitedSuggestions),
          limitedSuggestions.map(
            (result: any, index: number) =>
               setfilteredCategories(result?.products?.facets?.exact_matches?.Category[0]?.value)
             )
          )
          // <p>ad</p>
        } */}
      {isDesktop
        ? limitedSuggestions && variant === "Categories"
          ? limitedSuggestions.map(
              (result: any, index: number) =>
                result &&
                result.products && (
                  <Fragment key={index}>
                    <SearchResult
                      key={`${variant}-${index}`}
                      variant={variant}
                      result={result}
                      toggleMenu={toggleMenu}
                      queryTerm={queryTerm}
                      results={limitedSuggestions}
                      queryID={queryID}
                      index={index}
                      clickAll={clickAll}
                    />
                    {/* <Divider /> */}
                  </Fragment>
                )
            )
          : null
        : null}
    </React.Fragment>
  );
};

export default SearchResults;
