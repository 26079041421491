import React, { FunctionComponent, useMemo } from "react";
import { Box, createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { Quantity } from "../../models/product";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import QuantityInput from "../Shared/QuantityInput";
import WishListButton from "./ProductDetail/WishListButton";
import { DoubleOutlinedBtn } from "../Shared/DoubleOutlinedBtn";
import $ from "jquery";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      width: "100%",
      alignItems: "center",
      marginTop: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 420,
      },
    },
    buttonContainer: {
      flexBasis: "75%",
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      height: 40,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    quantityContainer: {
      flexBasis: "33.33%",
      height: 40,
      "&>div": {
        width: "100%",
        height: "100%",
        "&>div": {
          height: "100%",
        },
      },
    },
    wishListContainer: {
      flexBasis: "25%",
      height: 40,
      "&>button": {
        width: "100%",
        height: "100%",
      },
    },
  })
);
interface ProductActionsProps {
  product?: BuyerProduct;
  quantity?: Quantity;
  buttonText?: string;
  disabled?: boolean;
  hideWishList?: boolean;
  disableQuantity?: boolean;
  onSubmit?: () => void;
  quantityChanged?: (quantity: number) => void;
}

const BasicAddCartActions: FunctionComponent<ProductActionsProps> = (props) => {
  const classes = useStyle();
  const { product, buttonText, disabled, hideWishList, quantity, disableQuantity, quantityChanged, onSubmit } = props;

  const theme = useTheme();
  const handleChange = (selectedQuantity: number) => {
    if (quantityChanged) {
      quantityChanged(selectedQuantity);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };

  const isGreaterThanMaxQuantityAllowed: boolean = useMemo(() => {
    let selectedQuantity = parseInt(String($("#standard-number").val()), 10);
    return !!(quantity?.maxQuantity && selectedQuantity > quantity!.maxQuantity!);
  }, [quantity]);

  return (
    <Box display="flex" className={classes.actionContainer}>
      {quantity && disableQuantity != false && (
        <div className={classes.quantityContainer}>
          <QuantityInput product={product} quantity={quantity} onChange={handleChange} />
        </div>
      )}
      {quantity && disableQuantity == false && (
        <div className={classes.quantityContainer}>
          <QuantityInput product={product} quantity={quantity} onChange={handleChange} disabled={!disabled} />
        </div>
      )}

      {product && (
        <div className={classes.buttonContainer}>
          <DoubleOutlinedBtn
            buttonText={buttonText || "Add to Cart"}
            buttonProps={{
              color: "secondary",
              onClick: handleSubmit,
              disabled: disabled || isGreaterThanMaxQuantityAllowed,
            }}
            styleProps={{
              height: "calc(100% - 6px)",
              width: "100%",
              padding: theme.spacing(1, 3),
              outlineColor: theme.palette.grey[400],
            }}
          />
        </div>
      )}
      {!hideWishList && (
        <div className={classes.wishListContainer}>
          <WishListButton product={product} />
        </div>
      )}
    </Box>
  );
};
export default BasicAddCartActions;
