import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import ProductImages from "../../Product/ProductImages";
import { neutral } from "../../../themes/colors";
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import useQuery from "../../Shared/hooks/useQuery";
import productService from "../../../services/product.service";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Quantity, BachmansProduct } from "../../../models/product";
import { find, isUndefined } from "lodash";
import locationService from "../../../services/locationService";
import eventService from "../../../services/event.service";
import ProductPrice from "../../Product/ProductPrice";
import EventSlotSelector from "./EventSlotSelector";
import BasicAddCartActions from "../../Product/BasicAddCartActions";
import EventTabs from "./EventTabs";
import { EventsLandingContext } from "../../../providers/contentful";
import FullWidthContentBlock from "../../Shared/Content/FullWidthContentBlock";
import { Address, BuyerProduct, LineItem, LineItems, Orders } from "ordercloud-javascript-sdk";
import BachmansCarousel from "../../Shared/BachmansCarousel";
import { setBreadcrumbs } from "../../../redux/slices/breadcrumbs";
import EventSlotViewer from "./EventSlotViewer";
import { useAppSelector } from "../../../redux/store-hook";
import { LocationState } from "../../Product/ProductDetail";
import SocialLinks from "../../StaticPages/SocialLinks";
import addToCartService from "../../../services/addToCart.service";
import bachmansIntegrationsService from "../../../services/bachmansIntegrations.service";
import { calculateOrder } from "../../../redux/slices/order";
import AddToCartConfirmation from "../../Product/ProductDetail/AddToCartConfirmation";
import { FreeEventDateTime, EventDatesXp } from "../../../models/Events";
import TicketedEventModal from "./TicketedEventModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
    },
    drawer: {
      flexBasis: "100%",
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: { flexBasis: "33.333%" },
    },
    gridLeft: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(1),
      },
    },
    gridRight: {
      width: "100%",
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        paddingLeft: theme.spacing(2),
      },
    },
    checkLabel: {
      color: neutral.text_white_bg,
    },
    submit: {
      color: theme.palette.secondary.main,
    },
    input: {
      width: "75px",
    },
    submitContainer: {
      marginTop: "30px",
    },
    greyBackground: {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      backgroundColor: neutral.grey_background,
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(4),
      },
    },
    relatedEventsTitle: {
      marginBottom: theme.spacing(4),
    },
    topMargin: {
      marginTop: theme.spacing(4),
    },
    label: {
      lineHeight: 1.5,
      marginTop: theme.spacing(2),
      color: neutral.text_light_bg,
    },
    boldText: {
      lineHeight: 1.4,
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  })
);

interface EventDetailProps {
  eventGroupData?: BachmansProduct[];
}

export interface EventRouteMatch {
  eventCode: string;
  sku?: string;
}

const EventDetail: React.FunctionComponent<EventDetailProps> = (props) => {
  const eventsContent = useContext(EventsLandingContext);
  const [eventGroup, setEventGroup] = useState<BachmansProduct[]>();
  const [relatedEvents, setRelatedEvents] = useState<BuyerProduct[]>();
  const [quantity, setQuantity] = useState<Quantity>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [lineItem, setLineItem] = useState<LineItem>();
  const [eventDialogOpen, setEventDialogOpen] = useState<boolean>(false);
  const [slotDate, setSlotDate] = useState<string>();
  const [slotTime, setSlotTime] = useState<string>();

  const currentOrder = useAppSelector((state) => state.order);
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch<EventRouteMatch>();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch<AppDispatch>();

  let event = useMemo(() => {
    const sku = query.get("sku");
    return sku
      ? find(eventGroup, function (e) {
          return e.ID === sku;
        })
      : productService.GetDefaultProduct(eventGroup);
  }, [eventGroup, query]);

  const defaultdisplayevent = useMemo(() => {
    return productService.GetDefaultProduct(eventGroup);
  }, [eventGroup, query]);
  //sets breadcrumbs
  useEffect(() => {
    if (location?.state?.path) {
      dispatch(
        setBreadcrumbs({
          visible: true,
          hideHome: true,
          links: [
            {
              path: location?.state?.path + location?.state?.search,
              label: location?.state?.path === "/" ? "Home" : "Back to Results",
            },
          ],
          current: "",
        })
      );
    } else if (event && event !== null) {
      dispatch(
        setBreadcrumbs({
          visible: true,
          links: [],
          current: productService.ProductNameFilter(event),
          dark: false,
        })
      );
    }
  }, [event, dispatch, location]);
  // sets Event Group
  useEffect(() => {
    if (props.eventGroupData) {
      setEventGroup(props.eventGroupData);
    } else {
      (async () => {
        try {
          const group = await eventService.GetEvent(match.params.eventCode);
          if (!group || group.length === 0) {
            history.push(`/item-not-found/${match.params.eventCode}`);
          } else {
            setEventGroup(group);
          }
        } catch {
          history.push(`/item-not-found/${match.params.eventCode}`);
        }
      })();
    }
  }, [props.eventGroupData, match.params, history]);

  //sets related Events
  useEffect(() => {
    if (event?.xp?.Related) {
      productService.ListProductsByCodes(event.xp.Related, false, false).then((related) => setRelatedEvents(related));
    }
  }, [event]);

  const handleQuantityChange = (selectedQuantity: number) => {
    setQuantity((qtyInfo) => {
      return {
        ...qtyInfo,
        quantity: selectedQuantity,
      };
    });
  };

  const matchingEvent = useMemo(() => {
    if (!slotDate || !slotTime) {
      return;
    }
    const match = eventGroup?.find(
      (e) => eventService.GetFormattedEventDate(e) === slotDate && e.xp?.SpecsOptions?.Size === slotTime
    );
    if (match && currentOrder) {
      const minQuantity = productService.DetermineMinQuantity(match);
      setQuantity({
        maxQuantity: productService.DetermineMaxQuantity(match, currentOrder),
        minQuantity: minQuantity,
        quantity: minQuantity,
      });
    }
    return match;
  }, [currentOrder, eventGroup, slotDate, slotTime]);

  const submitTicketedEvent = async (
    partial: Partial<Address>,
    quantity: Quantity,
    email: string,
    sendEventEmail: boolean
  ) => {
    if (event) {
      setIsSubmitting(true);
      const shippingAddress = event?.xp.Location as Address;
      shippingAddress.FirstName = partial.FirstName;
      shippingAddress.LastName = partial.LastName;
      shippingAddress.Phone = partial.Phone;
      shippingAddress.xp = {};

      const lineItem = addToCartService.ConstructEventLineItem({
        selectedEvent: matchingEvent || event,
        defaultEvent: event,
        quantity: quantity.quantity,
        shippingAddress: shippingAddress,
        sendEventEmail: sendEventEmail,
        email: email,
        primaryEvent: defaultdisplayevent,
      });
      const order = isUndefined(currentOrder.order?.DateCreated)
        ? await Orders.Create("Outgoing", currentOrder?.order || {})
        : currentOrder.order;

      if (order?.ID) {
        let item = await bachmansIntegrationsService.upsertLineItem(order.ID, lineItem);
        await LineItems.SetShippingAddress("Outgoing", currentOrder.order?.ID!, item.ID!, shippingAddress);
        let defaultImage = {
          xp: {
            ShownImage: defaultdisplayevent?.xp?.Contentful.Images[0].url,
          },
        };
        item = { ...item, ...defaultImage };
        setLineItem(item);
        setEventDialogOpen(false);
        setConfirmDialogOpen(true);
        await dispatch(calculateOrder(order.ID));
      }

      setIsSubmitting(false);
    } else {
      throw new Error("missing Event");
    }
  };

  const transformedDates: FreeEventDateTime[] = useMemo(() => {
    if (!event?.xp?.EventDateTimes) {
      return [];
    } else if (Array.isArray(event?.xp?.EventDateTimes)) {
      return event?.xp?.EventDateTimes;
    } else {
      // handle events that have old data structure
      const dates = Object.keys(event?.xp?.EventDateTimes as EventDatesXp);
      let arr: FreeEventDateTime[] = [];
      dates.forEach((date) => {
        const slots = (event?.xp?.EventDateTimes as EventDatesXp)[date];
        slots.forEach((slot: string[]) => {
          arr.push({
            Date: date,
            Start: slot[0],
            End: slot[1],
          });
        });
      });
      return arr;
    }
  }, [event]);

  const isSoldOut = (selectedEvent: BuyerProduct) => {
    return !selectedEvent?.Inventory?.QuantityAvailable || (selectedEvent?.Inventory?.QuantityAvailable || 0) < 1;
  };

  const shouldDisable = (): boolean => {
    if (matchingEvent) {
      return isSoldOut(matchingEvent);
    } else {
      if (eventGroup && eventGroup?.length >= 1) {
        return isSoldOut(eventGroup[0]);
      }
      if (!matchingEvent || isSubmitting) {
        return true;
      }
    }
    return isSoldOut(matchingEvent);
  };

  return (
    <Fragment>
      <Container className={classes.container}>
        <Grid container>
          <Grid item md={6} xs={12} className={classes.gridLeft}>
            {defaultdisplayevent && <ProductImages product={defaultdisplayevent} multiSku={false} />}
          </Grid>
          <Grid item md={6} xs={12} className={classes.gridRight}>
            {/* {event && defaultdisplayevent?.xp?.SEO && (
              <SocialLinks
                seoInfo={{
                  title: defaultdisplayevent.xp.SEO.Title,
                  description: defaultdisplayevent.xp.SEO.Description,
                  image: productService.GetDefaultImage(defaultdisplayevent),
                }}
              />
            )} */}
            <Typography variant="h2" style={{ color: neutral.text_white_bg, marginTop: "10px" }}>
              {defaultdisplayevent?.xp?.WebFacingProductTitle || defaultdisplayevent?.Name}
            </Typography>
            {event?.xp?.IsWorkshop === true && <ProductPrice product={defaultdisplayevent} alignment="left" />}
            <Typography className={classes.label} variant="overline" display="block">
              Location
            </Typography>
            <Typography
              className={classes.boldText}
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: locationService.BuildAddressLines(defaultdisplayevent?.xp?.Location, "store", true),
              }}
            ></Typography>
            {defaultdisplayevent?.xp?.Room && (
              <Fragment>
                <Typography className={classes.label} variant="overline" display="block">
                  Details
                </Typography>
                <Typography variant="body1" className={classes.boldText}>
                  {"Room " + defaultdisplayevent?.xp?.Room}
                </Typography>
              </Fragment>
            )}
            <Typography className={classes.label} variant="overline" display="block">
              Phone Number
            </Typography>
            <Typography variant="body1" className={classes.boldText}>
              {defaultdisplayevent?.xp?.Location?.Phone}
            </Typography>
            {event &&
              (event?.xp?.IsWorkshop ? (
                <Fragment>
                  <Typography className={classes.label} variant="overline" display="block">
                    Select a Date and Time
                  </Typography>
                  <EventSlotSelector
                    eventGroup={eventGroup}
                    event={event}
                    selectedDate={slotDate}
                    selectedTime={slotTime}
                    onDateChange={setSlotDate}
                    onTimeChange={setSlotTime}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Typography className={classes.label} variant="overline" display="block">
                    Dates And Times
                  </Typography>
                  <EventSlotViewer defaultEvent={event} eventDateTimes={transformedDates}></EventSlotViewer>
                </Fragment>
              ))}
            {event && event.xp?.IsWorkshop && defaultdisplayevent && (
              <Fragment>
                <BasicAddCartActions
                  product={defaultdisplayevent}
                  quantity={quantity}
                  quantityChanged={handleQuantityChange}
                  buttonText={matchingEvent && isSoldOut(matchingEvent) ? "Sold Out" : "Register"}
                  disabled={shouldDisable()}
                  onSubmit={() => setEventDialogOpen(true)}
                />
                <TicketedEventModal
                  open={eventDialogOpen}
                  event={defaultdisplayevent}
                  onSubmit={submitTicketedEvent}
                  quantity={quantity}
                  disabled={isSubmitting}
                  onClose={() => setEventDialogOpen(false)}
                />
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
      <div className={classes.greyBackground}>
        <Container>
          <Grid container>
            <Grid item md={8} className={classes.gridLeft}>
              <EventTabs event={defaultdisplayevent} group={eventGroup} />
            </Grid>
            <Grid item md={4} className={classes.gridRight}></Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        <div className={classes.topMargin}>
          {eventsContent.data?.fields?.fullWidthContent?.fields && (
            <FullWidthContentBlock fullWidthContent={eventsContent.data?.fields.fullWidthContent.fields} />
          )}
        </div>
        {relatedEvents && (
          <div className={classes.topMargin}>
            <Typography className={classes.relatedEventsTitle} variant="h4">
              You Might Also Like...
            </Typography>
            <BachmansCarousel variant="event" carouselData={relatedEvents} />
          </div>
        )}
      </Container>
      <AddToCartConfirmation
        open={confirmDialogOpen}
        lineItems={lineItem ? [lineItem] : []}
        quantity={{ quantity: lineItem?.Quantity || 1 }}
        onClose={() => {
          setConfirmDialogOpen(false);
          setLineItem(undefined);
        }}
      />
    </Fragment>
  );
};

export default EventDetail;
