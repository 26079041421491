import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme, Popover, ClickAwayListener } from "@material-ui/core";

interface MegaMenuProps {
  open: boolean;
  anchorEl: any;
  onClickAway: (event: React.MouseEvent<Document, MouseEvent>) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {
      pointerEvents: "none",
    },
    paperRoot: {
      pointerEvents: "all",
      display: "flex",
      width: "100%",
      maxHeight: 550,
      position: "relative",
      zIndex: theme.zIndex.appBar + 1,
      maxWidth: "1280px !important",
      transition: "height 3s ease",
    },
  })
);

const MegaMenu: FunctionComponent<MegaMenuProps> = (props) => {
  const classes = useStyle();
  const { children, open, anchorEl, onClickAway } = props;

  return (
    <Popover
      elevation={3}
      className={classes.popoverRoot}
      PaperProps={{
        square: true,
        classes: {
          root: classes.paperRoot,
        },
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
    </Popover>
  );
};

export default MegaMenu;
