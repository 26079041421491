import {
  createStyles,
  FormControl,
  List,
  ListItem,
  ListItemProps,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Fragment, useEffect } from "react";
import { neutral } from "../../themes/colors";
import { StaticPageQueryParams } from ".";
import { StaticPageRouteData } from "./StaticPageNavigation";
import { PageTitles } from "./StaticPageConstants";
import { StaticPage } from "../../models/contentful/staticPage";
import ResourceDrawerButton from "./ResourceDrawerButton";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/store-hook";
import { AppDispatch } from "../../redux/store";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";

export interface ArticleTagNavProps {
  params?: StaticPageQueryParams;
  availableTags?: string[];
  rootSlug?: string;
  isMobile?: boolean;
  displayTagOnly?: boolean;
  updateParams: (params: StaticPageRouteData) => void;
  navGroups?: navGroupStaticPage;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      padding: 0,
      backgroundColor: neutral.offwhite_bg,
    },
    spaceBottom: {
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(3),
      },
    },
    AccordionSidebar: {
      padding: 0,
      backgroundColor: neutral.offwhite_bg,
    },
    menuItemHeading: {
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
    },
    menuItem: {
      padding: theme.spacing(0),
      cursor: "pointer",
      "&.Mui-selected": {
        padding: theme.spacing(0),
        cursor: "pointer",
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    menuListNested: {
      padding: 0,
    },
    menuItemNested: {
      paddingTop: 0,
      paddingBottom: 0,
      "&>a": {
        paddingLeft: theme.spacing(4),
      },
    },
    menuLink: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1.5, 2),
      lineHeight: "normal",
    },
    MenuList: {
      width: "100%",
      padding: 0,
      "&>.MuiList-root": {
        padding: 0,
      },
    },
    fullWidth: {
      width: "100%",
    },
    mobileMenuNested: {
      paddingLeft: theme.spacing(4),
    },
    mobileNavItemWrapper: {
      alignItems: "stretch",
      "& > div:first-of-type": {
        display: "flex",
        alignItems: "center",
      },
    },
    mobileNavItem: {
      width: "50%",
      flexGrow: 1,
      margin: "0",
    },
    mobileNavIcon: {
      display: "flex",
      justifyContent: "flex-end",
      flexGrow: 0,
      padding: 0,
      color: "inherit",
    },
  })
);

interface navGroupStaticPage {
  [key: string]: StaticPage[];
}

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const ArticleTagNav: React.FunctionComponent<ArticleTagNavProps> = (props) => {
  const classes = useStyles();
  const { params, availableTags, updateParams, rootSlug, isMobile, displayTagOnly } = props;
  const breadCrumbState = useAppSelector((state) => state.breadcrumbs);
  const dispatch = useDispatch<AppDispatch>();

  const handleDropdown = (event: React.ChangeEvent<any>, child?: any) => {
    let openState = breadCrumbState.resourceDrawerOpen;
    dispatch(
      setBreadcrumbs({
        resourceDrawerOpen: !openState,
      })
    );
  };

  const handleLinkClick = (value?: string) => (event: React.MouseEvent<any>) => {
    updateParams({
      tag: value,
    });
  };

  const isAllGuidesSelected = (tag?: string) => {
    if (!tag && rootSlug === "resource-hub") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("Navigation loaded");
  }, []);

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          {displayTagOnly ? null : (
            <Typography variant="h5" gutterBottom>
              Resource Hub
            </Typography>
          )}
          <FormControl className={classes.fullWidth}>
            <ResourceDrawerButton handleClick={handleDropdown} />
          </FormControl>
        </Fragment>
      ) : displayTagOnly ? (
        <Fragment>
          {/* Assumes that you'll be putting this element in a list, so only give back a ListItem with ListItemLink */}
          {availableTags?.map((tag, i) => (
            <ListItem disableGutters={true} key={i} selected={params?.tag === tag} className={classes.menuItem}>
              <ListItemLink className={classes.menuLink} disableGutters={true} onClick={handleLinkClick(tag)} id={tag}>
                {tag}
              </ListItemLink>
            </ListItem>
          ))}
        </Fragment>
      ) : (
        <List
          className={classes.navigation}
          subheader={
            <Fragment>
              <ListSubheader className={classes.menuItemHeading}>Resource Hub</ListSubheader>
            </Fragment>
          }
        >
          <ListItem disableGutters={true} selected={isAllGuidesSelected(params?.tag)} className={classes.menuItem}>
            <ListItemLink disableGutters={true} onClick={handleLinkClick(undefined)} className={classes.menuLink}>
              All Care Guides
            </ListItemLink>
          </ListItem>
          {availableTags?.map((tag, i) =>
            tag === PageTitles.BachmansBlog.title ? null : (
              <ListItem disableGutters={true} key={i} selected={params?.tag === tag} className={classes.menuItem}>
                <ListItemLink
                  className={classes.menuLink}
                  disableGutters={true}
                  onClick={handleLinkClick(tag)}
                  id={tag}
                >
                  {tag}
                </ListItemLink>
              </ListItem>
            )
          )}
        </List>
      )}
    </Fragment>
  );
};

export default ArticleTagNav;
