import React, { FunctionComponent, MouseEvent } from "react";
import { createStyles, List, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { Category, RequiredDeep } from "ordercloud-javascript-sdk";
import { neutral, purple } from "../../../themes/colors";

interface SiblingCategoriesProps {
  parentCategory: RequiredDeep<Category<any>>;
  siblings: Category[];
  currentCategory: Category;
  handleChange: (catID: string) => (event: MouseEvent) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    siblingCategories: {
      margin: theme.spacing(2, 0, 0, 0),
      // padding: theme.spacing(0, 2, 2, 2),
    },
    title: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.grey[600],
      margin: theme.spacing(0, 0, 1, 0),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    link: {
      padding: theme.spacing(0.5, 2),
      color: neutral.text_white_bg,
    },
    selected: {
      color: purple.regular,
    },
    listItem: {
      padding: 0,
    },
  })
);

const SiblingCategories: FunctionComponent<SiblingCategoriesProps> = (props) => {
  const classes = useStyle();
  const { parentCategory, siblings, currentCategory, handleChange } = props;

  return (
    <div className={classes.siblingCategories}>
      {parentCategory && (
        <Typography className={classes.title} variant="subtitle1">
          More Categories in {parentCategory.Name}
        </Typography>
      )}
      {/* todo turn into list list item and have this logic updatit */}
      <List>
        {siblings &&
          siblings.map((c: Category, i: number) => (
            <ListItem
              className={classes.listItem}
              button
              onClick={handleChange(c.ID ? c.ID : "")}
              key={i}
              disableGutters
            >
              <ListItemText
                className={`${classes.link} ${c.ID === currentCategory.ID ? classes.selected : ""}`}
                primary={c.Name}
                primaryTypographyProps={{ variant: "subtitle2" }}
              ></ListItemText>
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default SiblingCategories;
