import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { purple } from "../../themes/colors";
import ReactMarkdown from "react-markdown";
import { PurplePerksContext } from "../../providers/contentful";
import gfm from "remark-gfm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unorderedList: {
      "& ul > li": {
        marginBottom: theme.spacing(2),
        "&::marker": {
          color: purple.price,
        },
      },
    },
  })
);

const PurpleBullets: React.FunctionComponent = (props) => {
  const classes = useStyles();
  const purplePerks = useContext(PurplePerksContext);
  return (
    <Fragment>
      {purplePerks.data?.fields?.info && (
        <ReactMarkdown className={classes.unorderedList} plugins={[gfm]} children={purplePerks.data?.fields?.info} />
      )}
      {props.children}
    </Fragment>
  );
};

export default PurpleBullets;
