import { useEffect, useState } from "react";

// Hook
function useDebounce(
  value: any,
  delay: number,
  options?: {
    toggle?: "default" | "on" | "off";
  }
) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      if (typeof value === "boolean") {
        if (options && options.toggle === "on" && !value) {
          return setDebouncedValue(value);
        }
        if (options && options.toggle === "off" && value) {
          return setDebouncedValue(value);
        }
      }

      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, options] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export default useDebounce;
