import { filter, find, result } from "lodash";
import productService from "./product.service";
import eventService from "./event.service";
import contentfulService from "./contentful.service";

function bestMatch(group: any) {
  return find(group, { BestAlgoliaMatch: true });
}

async function searchProductsAsync(filters: any) {
  let productListData = await productService.ListProducts(filters, [], []);
  productListData.hits = filter(productListData.hits, (group) => bestMatch(group));
  return productListData;
}
async function searchEventsAsync(filters: any) {
  return await eventService.ListAsync(filters);
}

async function searchArticlesAsync(filters: any) {
  try {
    let response: any = await contentfulService.getEntries({
      content_type: "article",
      query: filters.query,
      include: 1,
    });
    return response?.items;
  } catch (ex) {
    return [];
  }
}

async function searchInformationAsync(filters: any) {
  try {
    let response: any = await contentfulService.getEntries({
      content_type: "staticPage",
      query: filters.query,
      include: 1,
    });
    return response?.items;
  } catch (ex) {
    return [];
  }
}

async function getAllSearchResultsAsync(filters: any) {
  if (!filters.query) return;

  const results = await Promise.all([
    searchProductsAsync(filters),
    searchEventsAsync(filters),
    searchArticlesAsync(filters),
    searchInformationAsync(filters),
  ]);

  return {
    products: results[0],
    events: results[1],
    articles: results[2],
    information: results[3],
  };
}

const service = {
  GetAllSearchResultsAsync: getAllSearchResultsAsync,
};
export default service;
