import React, { Fragment, useState } from "react";
import {
  Dialog,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
  DialogContent,
} from "@material-ui/core";
import { neutral } from "../../themes/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      "& .MuiPaper-root": {
        borderRadius: 0,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(1),
        minHeight: 175,
        [theme.breakpoints.up("md")]: {
          width: "40vw",
          padding: theme.spacing(3),
        },
      },
      "& .MuiDialogTitle-root": {
        textAlign: "center",
      },
    },
    dialogActions: {
      gap: theme.spacing(1),
    },
    text: {
      color: neutral.text_white_bg,
    },
    divider: {
      width: "90%",
      alignSelf: "center",
    },
  })
);

export interface ConfirmDialgProps {
  open: boolean;
  title?: string;
  text?: string;
  loadingText?: string;
  onClose?: () => void;
  onAccept?: () => Promise<void>;
}

const ConfirmDialog: React.FunctionComponent<ConfirmDialgProps> = (props) => {
  const classes = useStyles();
  const { open, title, onClose, onAccept, loadingText, text } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    setLoading(false);
    if (onClose) {
      onClose();
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    if (onAccept) {
      await onAccept();
      handleClose();
    }
    setLoading(false);
  };

  return (
    <Dialog className={classes.dialogContainer} open={open} onClose={handleClose} disableEscapeKeyDown={true}>
      {loading ? (
        <Box style={{ gap: 24 }} display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
          <CircularProgress />
          <Typography display="inline" variant="caption">
            {loadingText}
          </Typography>
        </Box>
      ) : (
        <Fragment>
          <DialogTitle>{title}</DialogTitle>
          {text && (
            <DialogContent>
              <Typography className={classes.text} variant="body1">
                {text}
              </Typography>
            </DialogContent>
          )}
          <Divider className={classes.divider} />
          <DialogActions className={classes.dialogActions}>
            <Button disabled={loading} onClick={handleClose}>
              No
            </Button>
            <Button variant="contained" color="primary" disabled={loading} onClick={handleAccept}>
              Yes
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
