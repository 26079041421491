import axios, { AxiosInstance } from "axios";
import { BachmansMeUser } from "../models/user";
import appConfig from "../constants/app.constants";

type EagleAction = "registerpp";
class JitterbitIntegrations {
  private axios: AxiosInstance;
  constructor() {
    this.axios = axios.create({
      baseURL: appConfig.jitterbitUrl,
    });
  }

  public checkGcBalance = async (gcNumber: string) => {
    return this.axios.post("/GiftCardBalance", { card_number: gcNumber });
  };

  public RegisterPurplePerks = async (user: BachmansMeUser, action: EagleAction) => {
    let data = {
      CustomerID: user.xp && user.xp.EagleCustomerNumber ? user.xp.EagleCustomerNumber.toString() : user.ID,
      OMSAccountNumber: user.ID,
      Action: action,
    };
    return this.axios.post("/four51_to_eagle_filecreate", data);
  };
}

export default new JitterbitIntegrations();
