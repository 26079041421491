import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import React from 'react';
import { Address } from 'ordercloud-javascript-sdk';
import {ReactComponent as MarkerIcon} from "../../assets/images/markerIcon.svg"
import { SvgIcon } from '@material-ui/core';

export interface Location {
  lat: number;
  lng: number;
  address?: string;
}
 
const LocationPin = (props: any) => (
    <div className="pin">
        <SvgIcon component={MarkerIcon} 
            onClick={props?.onClick} 
            viewBox="0 0 50 110"
            style={{cursor: 'pointer'}}/>
    </div>
)

export interface MapProps {
    center: Location,
    stores?: Address[],
    zoomLevel: number,
    selectMarker?: (loc: Location, id: string) => void,
    updateZoom?: (z: number) => void
}



const Map: React.FunctionComponent<MapProps> = (props) => {
    const {center, stores, zoomLevel, selectMarker, updateZoom} = props

    const handleMarkerSelect = (storeLocation: Location, id: string) => (e: React.MouseEvent) => {
        if(selectMarker) {
            selectMarker(storeLocation, id)
        }
    }

    const viewChange = (change: ChangeEventValue) => {
        if(updateZoom) {
            updateZoom(change.zoom)
        }
    }

    return(
    <div className="map">
      {process.env.REACT_APP_GOOGLE_API_KEY && 
        <div style={{height: '500px', width: '100%'}}> 
        <GoogleMapReact
            onChange={viewChange}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }} 
            center={center}
            zoom={zoomLevel}
        >
            {stores && stores.map(store => (
                <LocationPin
                key={store.ID}
                lat={store.xp.Location.lat}
                lng={store.xp.Location.lng} 
                onClick={handleMarkerSelect(store.xp?.Location, store.ID!)}
                />
            ))}
        </GoogleMapReact>
        </div>
      }
      
    </div>
    )
}

export default Map;
