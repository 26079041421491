import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  Grid,
  Paper,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import AccountService from "../../../services/account.service";
import { AccountOrderListResponse } from "../../../services/account.service";
import Currency from "react-currency-formatter";
import moment from "moment-timezone";
import { ArrowDropDown } from "@material-ui/icons";
import bachDateTimeService from "../../../services/bachDateTime.service";
import { BachmansShipmentWithLineItems } from "../../../models/shipment";

import OrderDetails from "./OrderDetail";
import { Pagination } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router";
import BachmansLoading from "../../Shared/BachmansLoading";
import { CompletedOrder } from "../../../models/order";

interface OrderHistoryProps {
  shipment?: BachmansShipmentWithLineItems;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    mobileHeader: {
      display: "flex",
      flex: "1",
      "& > *:not(:first-child)": { marginLeft: theme.spacing(1) },
    },
    gap3: {
      gap: theme.spacing(3),
    },
    pagination: {
      "& > .MuiPagination-ul": {
        justifyContent: "center",
      },
      margin: theme.spacing(3, 0),
    },
    textRight: {
      textAlign: "right",
    },
    accordionSummary: {
      "& .MuiAccordionSummary-content": {
        gap: theme.spacing(3),
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  })
);

const OrderHistory: FunctionComponent<OrderHistoryProps> = (props) => {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const [ordersInfo, setOrderInfo] = useState<AccountOrderListResponse>();
  const [selectedOrderID, setSelectedOrderID] = useState<string | false>(false); //handles accordin expand
  const [loading, setLoading] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    (async () => {
      setLoading(true);
      let urlSearchParams = new URLSearchParams(location.search);
      let pageParam = parseInt(urlSearchParams.get("page") || "1");
      let orderIDParam = urlSearchParams.get("orderid");
      let orders = await AccountService.ListOrdersAsync(pageParam, orderIDParam);
      if (orders && orders?.Items?.length) {
        setOrderInfo(orders);
        setSelectedOrderID(orders.Items[0].ID || false);
      }
      setLoading(false);
    })();
  }, [location.search]);

  const getRecipients = (order: CompletedOrder) => {
    let recipients =
      (order?.Shipments?.length || 0) > 1
        ? "Recipients: Multiple Recipients"
        : order?.Shipments?.length === 1
        ? `Recipients: ${order?.Shipments[0].ToAddress?.FirstName} ${order?.Shipments[0].ToAddress?.LastName}`
        : "";
    return recipients;
  };

  const displayDate = (date: string) => {
    if (!date) return;
    return moment(date).format("MMMM-DD-YYYY");
  };

  const handleChange = useCallback(
    (orderID: string) => async (event: React.ChangeEvent<{}>, expanded: boolean) => {
      if (!orderID) return;
      if (orderID === selectedOrderID) return setSelectedOrderID(false);
      setSelectedOrderID(orderID);
    },
    [selectedOrderID]
  );

  const updateSelectedOrderID = useCallback((orderID: string | undefined) => {
    if (!orderID) return setSelectedOrderID(false);
    setSelectedOrderID(orderID);
  }, []);

  const handlePageChange = useCallback(
    (event: object, page: number) => {
      let urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set("page", page.toString());
      history.push(`${location.pathname}?${urlSearchParams.toString()}`);
      setOrderInfo(undefined);
    },
    [history, location.pathname, location.search]
  );
  return (
    <Fragment>
      {isDesktop ? (
        <Fragment>
          <Box>
            {ordersInfo && ordersInfo.Items.length > 0 ? (
              <Grid container spacing={3}>
                {/* order summary List */}
                <Grid item xs={3}>
                  <List subheader={<ListSubheader id="nested-list-subheader">Order Summary</ListSubheader>}>
                    {ordersInfo.Items.map((order, i) => (
                      <ListItem
                        selected={selectedOrderID === order.ID}
                        button
                        divider
                        key={i}
                        onClick={() => updateSelectedOrderID(order?.ID)}
                      >
                        <ListItemText
                          secondary={
                            <Box display="flex" flexDirection="column">
                              <Typography variant="body2">
                                <strong>{`Order Number: ${order?.ID}`}</strong>
                              </Typography>
                              {bachDateTimeService.GetMediumDate(order.DateSubmitted || "")}
                              <Typography variant="body2">
                                {`From: ${order?.FromUser?.FirstName} ${order?.FromUser?.LastName}`}
                              </Typography>
                              <Typography variant="body2">{`${getRecipients(order)}`}</Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Hidden smDown>
                    {(ordersInfo?.Meta?.TotalPages || 0) > 1 && (
                      <Pagination
                        className={classes.pagination}
                        page={ordersInfo?.Meta?.Page}
                        count={ordersInfo?.Meta?.TotalPages}
                        onChange={handlePageChange}
                      />
                    )}
                  </Hidden>
                </Grid>
                <Grid item xs={9}>
                  {/* order details */}
                  <Paper square>
                    <Box p={3}>
                      {ordersInfo.Items.map(
                        (order, i) => selectedOrderID === order.ID && <OrderDetails order={order} />
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            ) : loading ? (
              <BachmansLoading text="Loading Orders..." />
            ) : (
              <Typography style={{ textAlign: "center" }} variant="h3" component="h2">
                No Orders for this user
              </Typography>
            )}
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          {ordersInfo && ordersInfo.Items.length > 0 ? (
            ordersInfo.Items.map((order) => (
              <Accordion
                expanded={selectedOrderID === order.ID}
                square
                key={order.ID}
                onChange={handleChange(order.ID ? order.ID : "")}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ArrowDropDown />}
                  aria-controls={`orderID ${order.ID}`}
                  id={order.ID}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2"> {`Order #${order.ID}`}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {order.Shipments && order.Shipments?.length > 1
                        ? `Multiple Recipients`
                        : `${order?.Shipments?.[0]?.ToAddress?.FirstName} ${order?.Shipments?.[0]?.ToAddress?.LastName}`}
                      {` |  ${order?.Shipments?.[0]?.xp?.AddressType}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {displayDate(order.DateSubmitted ? order.DateSubmitted : "")}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">
                      <Currency quantity={order.Total ? order.Total : 0} />
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`${order.LineItemCount} item${order?.LineItemCount && order?.LineItemCount > 1 ? "s" : ""}`}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <OrderDetails order={order} />
                </AccordionDetails>
              </Accordion>
            ))
          ) : loading ? (
            <CircularProgress />
          ) : (
            <Fragment>No Orders</Fragment>
          )}
        </Fragment>
      )}
      <Hidden smUp>
        {(ordersInfo?.Meta?.TotalPages || 0) > 1 && (
          <Pagination
            className={classes.pagination}
            page={ordersInfo?.Meta?.Page}
            count={ordersInfo?.Meta?.TotalPages}
            onChange={handlePageChange}
          />
        )}
      </Hidden>
    </Fragment>
  );
};

export default OrderHistory;
