import React, { Fragment, FunctionComponent } from "react";
import { Box, createStyles, Divider, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { CompletedOrder } from "../../../models/order";
import { BachmansShipmentWithLineItems } from "../../../models/shipment";
import bachDateTimeService from "../../../services/bachDateTime.service";
import accountService from "../../../services/account.service";
import AddressDisplay from "../../Shared/Address/Address";
import BachmansLineItems from "../../Shared/BachmansLineItems";
import Currency from "react-currency-formatter";
import { neutral } from "../../../themes/colors";
import { deliveryTextMap } from "../../../constants/DeliveryText";
import deliveryService from "../../../services/delivery.service";

interface OrderShipmentProps {
  order: CompletedOrder;
  shipment: BachmansShipmentWithLineItems;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    shipmentHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: `${theme.palette.primary.light}33`, // This 33 adds 20% transparancy to the hex code
      border: `1px solid ${theme.palette.primary.light}`,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    deliveryInfo: {
      gap: theme.spacing(3),
    },
    deliveryMessage: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      "& p:last-child": {
        fontStyle: "italic",
      },
    },
    cardMessage: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      backgroundColor: neutral.offwhite_bg,
    },
    orderTotals: {
      maxWidth: 225,
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
    },
    orderInfo: {
      gap: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-end",
      },
      alignItems: "center",
    },
    addressContainer: {},
    summary: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(0.25),
      marginTop: theme.spacing(0.25),
    },
  })
);

const OrderShipment: FunctionComponent<OrderShipmentProps> = (props) => {
  const classes = useStyle();
  const { shipment } = props;

  const getShipmentName = (shipment: BachmansShipmentWithLineItems) => {
    if (!deliveryService.IsPickUp(shipment.Shipper)) {
      if (shipment.ToAddress?.xp?.NickName) {
        return shipment.ToAddress?.xp?.NickName;
      } else if (shipment.ToAddress && !shipment.ToAddress?.xp?.Nickname) {
        return `${shipment.ToAddress?.FirstName} ${shipment.ToAddress?.LastName}`;
      } else {
        return deliveryTextMap[shipment.Shipper as string]?.text;
      }
    } else {
      return shipment.ToAddress?.AddressName + " Store";
    }
  };

  const getAddressType = (shipment: BachmansShipmentWithLineItems) => {
    if (shipment.Shipper === "InStorePickUp") {
      return "(In-store Pickup)";
    } else if (shipment.Shipper === "CurbsidePickUp") {
      return "(Curbside Pickup)";
    } else if (shipment.ToAddress?.xp?.addressType !== undefined) {
      return `(${shipment.ToAddress?.xp?.addressType})`;
    } else return null;
  };

  return shipment ? (
    <Fragment>
      <Paper square color="primary" variant="outlined" className={classes.shipmentHeader}>
        <div>
          <Typography display="inline">{getShipmentName(shipment)}</Typography>
          <Typography display="inline" color="textSecondary">
            {" "}
            {getAddressType(shipment)}
          </Typography>
        </div>
        <Typography variant="body2">{`#${shipment.ID}`}</Typography>
      </Paper>
      <Grid container className={classes.deliveryInfo}>
        <Grid item xs={2}>
          <Typography gutterBottom variant="body2">
            Status
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {shipment?.xp?.DeliveryMethod === "Email"
              ? "Email Delivery"
              : accountService.OrderStatusFilter(shipment.xp?.Status, true)}
          </Typography>
        </Grid>
        {shipment.LineItems?.[0]?.Product?.xp?.IsWorkshop ? (
          <Grid item xs={4}>
            <Typography variant="subtitle2" gutterBottom>
              Event Address
            </Typography>
            <AddressDisplay address={shipment?.ToAddress} />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <Typography variant="body2" gutterBottom>
              {deliveryService.IsPickUp(shipment.Shipper) ? "Pickup Address" : "Delivery Address"}
            </Typography>
            <Typography variant="subtitle2">
              {shipment.Shipper !== "Email" ? (
                <AddressDisplay address={shipment?.ToAddress} />
              ) : (
                shipment?.LineItems[0]?.xp?.Email
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <Typography variant="body2" gutterBottom>
            {deliveryService.IsPickUp(shipment.Shipper) ? "Pickup Date" : "Delivery Date"}
          </Typography>
          <Typography variant="subtitle2">
            {bachDateTimeService.GetMediumDate(shipment.xp?.RequestedDeliveryDate || "")}
          </Typography>
          {shipment?.Shipper === "LocalDelivery" && (
            <Typography gutterBottom variant="body2" color="textSecondary" style={{ lineHeight: 1 }}>
              {shipment.xp?.TimePreference === "AM" && "Morning"}
              {shipment.xp?.TimePreference === "PM" && "Afternoon"}
              {shipment.xp?.TimePreference !== "NO PREF" && " "}({shipment.xp?.TimeWindow})
            </Typography>
          )}
        </Grid>
      </Grid>
      <BachmansLineItems lineItems={shipment.LineItems} />
      {shipment.xp?.DeliveryNote && (
        <Paper variant="outlined" square className={classes.deliveryMessage}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {`${
              deliveryService.IsPickUp(shipment.Shipper)
                ? "Pickup"
                : shipment.xp?.PlacementCharges
                ? "Delivery & Placement"
                : "Delivery"
            }`}
          </Typography>
          <Typography variant="body2">{shipment.xp?.DeliveryNote}</Typography>
        </Paper>
      )}
      <Grid container className={classes.orderInfo}>
        {shipment.xp?.CardMessage && (
          <Grid item sm={7} xs={12}>
            <Paper square className={classes.cardMessage} variant="outlined">
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Card Message
              </Typography>
              <Typography variant="body2">{shipment.xp?.CardMessage?.line1}</Typography>
              <Typography variant="body2">{shipment.xp?.CardMessage?.line2}</Typography>
              <Typography variant="body2">{shipment.xp?.CardMessage?.line3}</Typography>
              <Typography variant="body2">{shipment.xp?.CardMessage?.line4}</Typography>
            </Paper>
          </Grid>
        )}
        <Grid item sm xs={12}>
          <Box className={classes.orderTotals}>
            <Box className={classes.summary}>
              <Typography variant="body2">SUBTOTAL</Typography>
              <Typography variant="body2" color="primary">
                <Currency quantity={shipment.Subtotal || 0} />
              </Typography>
            </Box>

            {!deliveryService.IsPickUp(shipment.Shipper) && (
              <Box className={classes.summary}>
                <Typography variant="body2">DELIVERY</Typography>
                <Typography variant="body2" color="primary">
                  <Currency quantity={(shipment.Cost || 0) - (shipment.xp?.HandlingCharges || 0)} />
                </Typography>
              </Box>
            )}

            {deliveryService.IsPickUp(shipment.Shipper) &&
              (shipment.Cost || 0) - (shipment.xp?.HandlingCharges || 0) > 0 && (
                <Box className={classes.summary}>
                  <Typography variant="body2">PICKING FEE</Typography>
                  <Typography variant="body2" color="primary">
                    <Currency quantity={(shipment.Cost || 0) - (shipment.xp?.HandlingCharges || 0)} />
                  </Typography>
                </Box>
              )}

            {Boolean(shipment.xp?.HandlingCharges) && (
              <Box className={classes.summary}>
                <Typography variant="body2">HANDLING</Typography>
                <Typography variant="body2" color="primary">
                  <Currency quantity={shipment.xp?.HandlingCharges || 0} />
                </Typography>
              </Box>
            )}

            {Boolean(shipment.xp?.WiredServiceFees) && (
              <Box className={classes.summary}>
                <Typography variant="body2">SERVICE FEE</Typography>
                <Typography variant="body2" color="primary">
                  <Currency quantity={shipment.xp?.WiredServiceFees || 0} />
                </Typography>
              </Box>
            )}
            <Box className={classes.summary}>
              <Typography variant="body2">SHIPMENT TOTAL</Typography>
              <Typography variant="body2" color="primary">
                <Currency quantity={shipment.Total || 0} />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box my={3}>
        <Divider light variant="fullWidth" />
      </Box>
    </Fragment>
  ) : null;
};

export default OrderShipment;
