import React, { FunctionComponent } from "react";
import { Box, Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import svg404 from "../../assets/svg/bachmans-404.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      placeItems: "center center",
      minHeight: "40vh",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "10vh",
      },
    },
    text: {
      marginTop: theme.spacing(-2),
    },
  })
);

const NotFound: FunctionComponent<any> = (props) => {
  const classes = useStyles();
  const { setBreadcrumbs } = props;
  setBreadcrumbs({ visible: true, links: [], current: "Page Not Found" });
  return (
    <Container className={classes.container} maxWidth="lg">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <img height="300" src={svg404} alt="illustrsation of woman watering plants" aria-hidden="true" />
        <Typography id="page-not-found-h1" className={classes.text} variant="h1" align="center">
          404: Page Not Found
        </Typography>
      </Box>
    </Container>
  );
};

export default connect(null, { setBreadcrumbs })(NotFound);
