import BachmansMeUser, { RegisterUserResponse } from "../models/user";
import bachmansIntegrations from "../services/bachmansIntegrations.service";
import { ForgottenPassword } from "ordercloud-javascript-sdk";
import appConfig from "../constants/app.constants";
export interface RegisterFormValues {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  CurrentPassword: string;
  Password: string;
  PasswordConfirmation: string;
}

async function registerAsync(userInfo: RegisterFormValues): Promise<RegisterUserResponse> {
  let ocUser: BachmansMeUser = {
    TermsAccepted: new Date().toISOString(),
    Username: userInfo.Email,
    Active: true,
    ...userInfo,
  };
  return await bachmansIntegrations.registerUserAsync(ocUser);
}
async function sendVerificationCodeAsync(userNameEmail: string): Promise<void> {
  let passwordResetRequest = {
    Email: userNameEmail,
    ClientID: appConfig.clientID,
    URL: `${window.location.origin}/reset-password`,
    Username: userNameEmail,
  };
  return await ForgottenPassword.SendVerificationCode(passwordResetRequest);
}

const service = {
  RegisterAsync: registerAsync,
  SendVerificationCodeAsync: sendVerificationCodeAsync,
};
export default service;
