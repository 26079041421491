import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { neutral } from "../../../themes/colors";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import visa from "../../../assets/svg/payment-icons/color/visa.svg";
import discover from "../../../assets/svg/payment-icons/color/discover.svg";
import mastercard from "../../../assets/svg/payment-icons/color/mastercard.svg";
import amex from "../../../assets/svg/payment-icons/color/amex.svg";
import { CardDetails } from "../../../models/Payment";
import stringService from "../../../services/string.service";
import { SessionContext } from "../../../providers/session";
import { isEqual } from "lodash";
import CreditCardMaskInput from "../../Shared/CreditCartMaskInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ccForm: {
      marginTop: 0,
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(0),
      },
      "& .MuiButton-outlined": {
        alignSelf: "flex-end",
        fontSize: 10,
        marginTop: theme.spacing(1),
        marginLeft: "auto",
      },
    },
    ccFormSuccess: {
      "& .MuiTextField-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.success.main,
        },
        "& .MuiFormLabel-root": {
          color: theme.palette.success.dark,
        },
      },
    },
    ccInputSuccess: {
      "& .MuiInputLabel, .MuiFormLabel-root": {
        color: theme.palette.success.main,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.success.main,
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.success.main} !important`,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.success.main} !important`,
      },
    },
    ccSummaryCard: {
      display: "flex",
      alignItems: "flex-start",
      position: "relative",
      backgroundColor: neutral.offwhite_bg,
      boxShadow: theme.shadows[1],
    },
    ccCardType: {
      width: 30,
      marginLeft: theme.spacing(3),
    },
    ccIcon: {
      maxWidth: 32,
    },
    btnMicro: {
      fontSize: 10,
      textDecoration: "underline",
      alignSelf: "flex-end",
      margin: 3,
    },
  })
);

interface CheckoutCreditCardFormProps {
  cardDetails?: CardDetails;
  readOnly?: boolean;
  disabled?: boolean;
  disableSave: boolean;
  onReadOnlyChange?: (val: boolean) => void;
  onChange: (val?: CardDetails) => void;
  onValidationChange?: (val: boolean) => void;
  creditCardError?: string;
}

export type CreditCardFormErrors = "Card Number" | "Name" | "CVV" | "Expiration Date";

const CheckoutCreditCardForm: FunctionComponent<CheckoutCreditCardFormProps> = (props) => {
  const {
    cardDetails,
    onChange,
    readOnly,
    onReadOnlyChange,
    creditCardError,
    disabled,
    disableSave,
    onValidationChange,
  } = props;
  const { anonymous } = useContext(SessionContext);
  const classes = useStyles();

  const handleEditClick = () => {
    if (onReadOnlyChange) {
      onReadOnlyChange(!readOnly);
    }
  };

  interface CardTypeData {
    svg: string;
    text: string;
  }
  const getCardType = useCallback((): CardTypeData | undefined => {
    if (!cardDetails?.CardNumber) {
      return undefined;
    }
    if (cardDetails?.CardNumber) {
      const firstChar = cardDetails?.CardNumber?.charAt(0)!;
      if (firstChar === "6") {
        return {
          svg: discover,
          text: "Discover",
        };
      } else if (firstChar === "5") {
        return {
          svg: mastercard,
          text: "Mastercard",
        };
      } else if (firstChar === "4") {
        return {
          svg: visa,
          text: "Visa",
        };
      } else if (firstChar === "3") {
        return {
          svg: amex,
          text: "Amex",
        };
      } else {
        return undefined;
      }
    }
  }, [cardDetails?.CardNumber]);

  const onExpiratioDateChange = (e: any) => {
    let newvalue = stringService.OnlyNumbers(e.target.value);
    let month, year;
    if (newvalue.length >= 2) {
      newvalue = newvalue.substring(0, 2) + " / " + newvalue.substring(2);
    }

    if ((cardDetails?.ExpirationDate?.length || 0) < newvalue.length) {
      // user added text
      if (newvalue.length === 2) {
        newvalue += " / ";
      }
    } else {
      //user is removing text
      if (newvalue.length === 5) {
        newvalue = newvalue.replace(" / ", "");
      }
    }

    if (newvalue.length === 7) {
      month = parseInt(newvalue.split(" / ")[0]);
      year = parseInt(newvalue.split(" / ")[1]);
    }
    onChange({
      ...cardDetails,
      ExpirationDate: newvalue,
      ExpireMonth: month,
      ExpireYear: year && year + 2000,
    });
  };

  const toggleSaveCC = (e: any) => {
    onChange({
      ...cardDetails,
      CardType: getCardType()?.text,
      Save: e.target.checked,
    });
  };

  const ErrorList: CreditCardFormErrors[] = useMemo(() => {
    let errorList: CreditCardFormErrors[] = [];
    if ((!cardDetails?.CardNumber || cardDetails.CardNumber?.length < 16) && getCardType()?.svg !== amex) {
      errorList.push("Card Number");
    }
    if ((!cardDetails?.CardNumber || cardDetails.CardNumber?.length < 14) && getCardType()?.svg === amex) {
      errorList.push("Card Number");
    }
    if (!cardDetails?.CardholderName || cardDetails.CardholderName === "") {
      errorList.push("Name");
    }
    if (
      !cardDetails?.ExpirationDate ||
      cardDetails.ExpirationDate.length !== 7 ||
      (cardDetails?.ExpireMonth || 0) > 12
    ) {
      errorList.push("Expiration Date");
    }
    if (cardDetails?.ExpireMonth && cardDetails.ExpireYear) {
      // now check if the entered card is expired
      const enteredYear = cardDetails.ExpireYear + 2000;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (enteredYear < currentYear || (enteredYear === currentYear && cardDetails.ExpireMonth < currentMonth)) {
        errorList.push("Expiration Date");
      }
    }
    if (!cardDetails?.CVV || cardDetails.CVV.length < 3) {
      errorList.push("CVV");
    }
    return errorList;
  }, [
    getCardType,
    cardDetails?.CVV,
    cardDetails?.CardNumber,
    cardDetails?.CardholderName,
    cardDetails?.ExpirationDate,
    cardDetails?.ExpireMonth,
    cardDetails?.ExpireYear,
  ]);

  useEffect(() => {
    if (onValidationChange) {
      if (ErrorList && ErrorList.length === 0) {
        onValidationChange(true);
      } else if (ErrorList && ErrorList.length > 0) {
        onValidationChange(false);
      }
    }
  }, [ErrorList, onValidationChange]);

  return (
    <Fragment>
      {!disabled && ErrorList && ErrorList.length > 0 && (
        <Typography variant="body2" color="error">
          {"Please enter a valid " + ErrorList.join(", ")}
        </Typography>
      )}
      {!readOnly ? (
        <form className={classes.ccForm} noValidate autoComplete="off">
          {/* 0. CC NUMBER */}
          <TextField
            className={ErrorList.includes("Card Number") ? "" : classes.ccInputSuccess}
            disabled={disabled}
            value={cardDetails?.CardNumber || ""}
            onChange={(e: any) =>
              onChange({
                ...cardDetails,
                CardNumber: stringService.OnlyNumbers(e.target.value),
              })
            }
            InputProps={{
              inputComponent: CreditCardMaskInput,
              endAdornment: (
                <Fragment>
                  {getCardType() !== undefined && (
                    <img
                      className={classes.ccIcon}
                      src={getCardType()?.svg}
                      alt={`${getCardType()?.text?.split("/media/")!?.pop()!?.split(".")!?.shift()} card logo`}
                    />
                  )}
                </Fragment>
              ),
            }}
            inputProps={{
              inputComponent: (cardType: any) => <CreditCardMaskInput inputRef={cardType} />,
            }}
            margin="dense"
            autoFocus={true}
            variant="outlined"
            id="ccNumber"
            name="Card Number"
            label="Card Number"
            required
            type="text"
            fullWidth
          />
          {/* 1. CC NAME */}
          <TextField
            className={ErrorList.includes("Name") ? "" : classes.ccInputSuccess}
            disabled={disabled}
            value={cardDetails?.CardholderName || ""}
            onChange={(e: any) =>
              onChange({
                ...cardDetails,
                CardholderName: e.target.value,
              })
            }
            margin="dense"
            variant="outlined"
            id="ccName"
            name="Cardholder Name"
            label="Cardholder Name"
            required
            type="text"
            fullWidth
          />
          <Grid container spacing={3}>
            <Grid item xs sm={6}>
              {/* CC EXP DATE */}
              <TextField
                className={ErrorList.includes("Expiration Date") ? "" : classes.ccInputSuccess}
                disabled={disabled}
                value={cardDetails?.ExpirationDate || ""}
                placeholder="MM / YY"
                onChange={onExpiratioDateChange}
                inputProps={{ maxLength: 7 }}
                margin="dense"
                variant="outlined"
                id="ccExpirationDate"
                name="Expiration Date"
                label="Expiration Date"
                required
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs sm={6}>
              {/* CC CVV */}
              <TextField
                className={ErrorList.includes("CVV") ? "" : classes.ccInputSuccess}
                disabled={disabled}
                value={cardDetails?.CVV || ""}
                onChange={(e: any) =>
                  onChange({
                    ...cardDetails,
                    CVV: stringService.OnlyNumbers(e.target.value),
                  })
                }
                margin="dense"
                inputProps={{ maxLength: 4 }}
                variant="outlined"
                id="ccCCV"
                name="CVV"
                label="CVV"
                required
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
          {!anonymous && !disableSave && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  checked={cardDetails?.Save}
                  onChange={toggleSaveCC}
                  name="saveCreditCard"
                  color="primary"
                  size="small"
                ></Checkbox>
              }
              label={<Typography variant="body1">Save credit card info for future use.</Typography>}
            />
          )}
          {creditCardError && isEqual(cardDetails, cardDetails) && (
            <Typography variant="body1" color="error">
              {creditCardError}
            </Typography>
          )}
        </form>
      ) : (
        <form className={`${classes.ccForm} ${classes.ccFormSuccess}`} noValidate autoComplete="off">
          <TextField
            disabled
            value={cardDetails?.CardNumber || ""}
            onChange={(e: any) =>
              onChange({
                ...cardDetails,
                CardNumber: stringService.OnlyNumbers(e.target.value),
              })
            }
            InputProps={{
              inputComponent: CreditCardMaskInput,
              endAdornment: (
                <Fragment>
                  {getCardType() !== undefined && (
                    <img className={classes.ccIcon} src={getCardType()?.svg} alt={getCardType()?.text + " card logo"} />
                  )}
                </Fragment>
              ),
            }}
            inputProps={{
              inputComponent: (cardType: any) => <CreditCardMaskInput inputRef={cardType} />,
            }}
            margin="dense"
            autoFocus={true}
            variant="outlined"
            id="ccNumber"
            name="Card Number"
            label="Card Number"
            required
            type="text"
            fullWidth
          />
          <TextField
            disabled
            value={cardDetails?.CardholderName || ""}
            onChange={(e: any) =>
              onChange({
                ...cardDetails,
                CardholderName: e.target.value,
              })
            }
            margin="dense"
            variant="outlined"
            id="ccName"
            name="Cardholder Name"
            label="Cardholder Name"
            required
            type="text"
            fullWidth
          />
          <Grid container spacing={3}>
            <Grid item xs sm={6}>
              <TextField
                disabled
                value={cardDetails?.ExpirationDate || ""}
                placeholder="MM / YY"
                onChange={onExpiratioDateChange}
                inputProps={{ maxLength: 7 }}
                margin="dense"
                autoFocus
                variant="outlined"
                id="ccExpirationDate"
                name="Expiration Date"
                label="Expiration Date"
                required
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs sm={6}>
              <TextField
                disabled
                value={cardDetails?.CVV || ""}
                onChange={(e: any) =>
                  onChange({
                    ...cardDetails,
                    CVV: stringService.OnlyNumbers(e.target.value),
                  })
                }
                margin="dense"
                inputProps={{ maxLength: 4 }}
                variant="outlined"
                id="ccCCV"
                name="CVV"
                label="CVV"
                required
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
          {creditCardError && isEqual(cardDetails, cardDetails) && (
            <Typography variant="body1" color="error">
              {creditCardError}
            </Typography>
          )}
          {cardDetails?.Save && !anonymous && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={cardDetails.Save}
                  disabled={true}
                  name="saveCreditCard"
                  color="primary"
                  size="small"
                ></Checkbox>
              }
              label={<Typography variant="body1">Save credit card info for future use.</Typography>}
            />
          )}
          <DoubleOutlinedBtn
            buttonText="Add New Card"
            buttonProps={{
              variant: "outlined",
              size: "small",
              disabled: !cardDetails || disabled,
              onClick: handleEditClick,
            }}
          ></DoubleOutlinedBtn>
        </form>
      )}
    </Fragment>
  );
};

export default CheckoutCreditCardForm;
