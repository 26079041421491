import { Box, Button, makeStyles, createStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../../providers/session";
import { accountLinks } from "../../Account/Account";
import DropdownMenu from "../../Shared/DropdownMenu";
import Link from "../../Shared/Link";
interface MenuProps {
  open: boolean;
  anchorEl: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signOutButton: {
      padding: 0,
      margin: 0,
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
  })
);

const AccountMenu: FunctionComponent<MenuProps> = (props) => {
  const classes = useStyles();
  const { logout } = useContext(SessionContext);
  const { open, anchorEl } = props;
  const history = useHistory();

  const handleLogout = useCallback(() => {
    if (logout) {
      logout();
      history.push("/");
    }
  }, [logout, history]);

  return (
    <DropdownMenu open={open} anchorEl={anchorEl} arrowPosition={"right"}>
      <Box px={2} py={1.5} width={200}>
        {accountLinks.map((l, i) => (
          <Link key={i} to={`/account/${l.path}`} variant="body1" color="textPrimary" display="block" gutterBottom>
            {l.label}
          </Link>
        ))}
        <Button onClick={handleLogout} className={classes.signOutButton} variant="text" color="primary">
          Sign Out
        </Button>
      </Box>
    </DropdownMenu>
  );
};

export default AccountMenu;
