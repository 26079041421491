import { groupBy, values } from "lodash";
import { BuyerProduct, Me } from "ordercloud-javascript-sdk";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { SessionContext } from "../../providers/session";
import productService from "../../services/product.service";
import BachmansLoading from "../Shared/BachmansLoading";
import ItemList from "../Shared/ItemList";

const Wishlist: React.FunctionComponent = () => {
  const { user } = useContext(SessionContext);
  const [wishlistProducts, setWishListProducts] = useState<BuyerProduct[][]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var codes = user?.xp?.WishList?.map((w: any) => w.ProductCode);
    if (codes?.length > 0) {
      setLoading(true);
      productService.ListProductsByCodes(codes || []).then((products) => {
        if (products?.length < codes?.length) {
          const updatedWishList = user?.xp?.WishList?.filter((w: any) =>
            products.map((p) => p.xp.ProductCode).includes(w.ProductCode)
          );
          Me.Patch({ xp: { WishList: updatedWishList } });
        }
        const groupedProducts = values(groupBy(products, (p) => p.xp.ProductCode));
        setWishListProducts(groupedProducts);
        setTimeout(() => {
          setLoading(false);
        }, 750);
      });
    } else {
      setWishListProducts([]);
    }
  }, [user]);

  return (
    <Fragment>
      {loading ? (
        <BachmansLoading text="Loading Wish List..." />
      ) : (
        <ItemList listData={wishlistProducts} variant="product"></ItemList>
      )}
    </Fragment>
  );
};

export default Wishlist;
