import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "../../../providers/session";
import bachPP, { PurplePerksAccount } from "../../../services/bachmansPurplePerks.service";
import { Box, Checkbox, FormControlLabel, Theme, Typography } from "@material-ui/core";
import { BachmansPay, PaymentTypes, UIPayment } from "../../../models/Payment";
import { createStyles, makeStyles } from "@material-ui/styles";
import { isUndefined } from "lodash";

interface CheckoutPurplePerksProps {
  updatePaymentAmounts: (type: PaymentTypes, payment?: UIPayment) => void;
  bachmansPay: BachmansPay;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textPaymentMethod: {
      textTransform: "capitalize",
      lineHeight: 1,
    },
  })
);

const CheckoutPurplePerks: React.FunctionComponent<CheckoutPurplePerksProps> = (props) => {
  const { updatePaymentAmounts, bachmansPay } = props;
  const classes = useStyles();
  const { anonymous, user } = useContext(SessionContext);
  const [purplePerksAccount, setPurplePerksAccount] = useState<PurplePerksAccount>();
  const [usePurplePerks, setUsePurplePerks] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!anonymous) {
        const ppAccount = await bachPP.CheckBalanceAsync(user);
        setPurplePerksAccount(ppAccount);
      }
    })();
  }, [anonymous, user]);

  const togglePP = (event: any) => {
    setUsePurplePerks(event.target.checked);
    const payment: UIPayment | undefined = event.target.checked
      ? {
          Amount: purplePerksAccount?.Balance,
          AcctNumber: purplePerksAccount?.LoyaltyID,
          Balance: purplePerksAccount?.Balance,
        }
      : undefined;
    updatePaymentAmounts("PurplePerks", payment);
  };

  const remainingBalance = useMemo(() => {
    if (purplePerksAccount?.Balance) {
      return Math.round((purplePerksAccount.Balance - (bachmansPay.PurplePerks?.Amount || 0)) * 100) / 100;
    }
  }, [purplePerksAccount?.Balance, bachmansPay.PurplePerks?.Amount]);

  return (
    <Fragment>
      {Boolean(!anonymous && !isUndefined(purplePerksAccount?.Balance)) && (
        <Box display="flex" justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                checked={usePurplePerks}
                onChange={togglePP}
                disabled={(purplePerksAccount?.Balance || 0) <= 0}
                name="usePurplePerks"
                color="primary"
                size="small"
              />
            }
            label={
              <Fragment>
                <Typography variant="h3" className={classes.textPaymentMethod} color="textSecondary">
                  Purple Perks{" "}
                  <Typography style={{ marginLeft: 4 }} variant="h3" component="span" color="primary">
                    {`$${purplePerksAccount?.Balance}`}
                  </Typography>
                </Typography>
                {usePurplePerks && remainingBalance ? (
                  <Typography variant="body2">Remaining Balance: ${remainingBalance}</Typography>
                ) : (
                  <Typography variant="body2">Apply my available Purple Perks balance</Typography>
                )}
              </Fragment>
            }
          />
        </Box>
      )}
    </Fragment>
  );
};

export default CheckoutPurplePerks;
