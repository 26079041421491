import { Box, createStyles, Grid, makeStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { PurplePerksContext } from "../../providers/contentful";
import gfm from "remark-gfm";
import { DoubleOutlinedBtn } from "./DoubleOutlinedBtn";
import { SessionContext } from "../../providers/session";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { BachmansMeUser } from "../../models/user";
import { Me } from "ordercloud-javascript-sdk";
import { clone } from "lodash";
import jitterbitService from "../../services/jitterbit.service";
import BachmansLoading from "./BachmansLoading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      textTransform: "capitalize",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
    },
    gridContainerPurplePerks: {
      justifyContent: "center",
    },
    gridItemPurplePerks: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3),
      flexWrap: "wrap",
    },
    imgPurplePerks: {
      display: "block",
      width: "clamp(100px, 20vw, 200px) !important",
    },
    imgPurplePerksAuth: {
      display: "block",
      marginRight: theme.spacing(2),
    },
    titlePurplePerks: {
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "19ch",
      },
    },
    listPurplePerks: {
      padding: theme.spacing(1, 2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1, 5),
      },
      "& > ul": {
        marginTop: "0",
        marginBottom: "0",
      },
      "& * li": {
        listStyleType: "disc",
        display: "list-item",
        padding: theme.spacing(1, 0),
        "&::marker": {
          color: theme.palette.primary.main,
        },
      },
    },
    listPurplePerksAuth: {
      "& > ul": {
        marginTop: "0",
        marginBottom: "0",
      },
      "& * li": {
        listStyleType: "disc",
        display: "list-item",
        padding: theme.spacing(1, 0),
        "&::marker": {
          color: theme.palette.primary.main,
        },
      },
    },
  })
);

interface PurplePerksFullProps {
  isAuthenticating?: boolean; //in authdialog -
  displayTitle?: boolean;
}

const PurplePerksFull: FunctionComponent<PurplePerksFullProps> = (propsArgs: PurplePerksFullProps) => {
  const classes = useStyles();
  const args = { ...{ displayTitle: true, isAuthenticating: false, ...propsArgs } }; /// setting default value to optional props.
  const { displayTitle, isAuthenticating } = args; // pulling out args to make a bit cleaner
  const purplePerks = useContext(PurplePerksContext);
  const [preBoldText, setPreBoldText] = useState<string>();
  const [postBoldText, setPostBoldText] = useState<string>();
  const [boldText, setBoldText] = useState<string>();
  const { anonymous, user } = useContext(SessionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const history = useHistory();

  const theme = useTheme();

  useEffect(() => {
    if (purplePerks?.data?.fields?.title) {
      const title = purplePerks?.data?.fields?.title;
      setPreBoldText(title.substring(0, title.indexOf("|")));
      setBoldText(title.substring(title.indexOf("|") + 1, title.lastIndexOf("|")));
      setPostBoldText(title.substring(title.lastIndexOf("|") + 1, title.length));
    }
  }, [purplePerks, displayTitle]);

  const handleRegisterClick = async () => {
    if (anonymous) {
      dispatch(setBreadcrumbs({ authDialogOpen: true, authDialogState: "register" }));
    } else if (user?.xp?.LoyaltyID) {
      history.push("/account/purple-perks");
    } else {
      //user is authenticated but has not signed up for purple perks
      await registerForPurplePerks();
    }
  };

  const getLoyaltyID = (user?: BachmansMeUser) => {
    if (user?.Phone) {
      return clone(user.Phone)
        .replace(/[^\w\s]/gi, "")
        .replace(" ", "");
    }
  };

  const registerForPurplePerks = async () => {
    try {
      setLoading(true);
      let updatedUser = await Me.Patch({ xp: { LoyaltyID: getLoyaltyID(user) } });
      await jitterbitService.RegisterPurplePerks(updatedUser, "registerpp");
    } finally {
      setLoading(false);
      history.push("/account/purple-perks");
    }
  };

  return !isAuthenticating ? (
    <Fragment>
      {displayTitle && (
        <Typography variant="h3" className={classes.title}>
          Bachman's Loyalty Program
        </Typography>
      )}
      {loading ? (
        <BachmansLoading text="Registering User..." />
      ) : (
        <Paper square>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} className={classes.gridItemPurplePerks}>
              <img
                className={classes.imgPurplePerks}
                src={purplePerks.data?.fields.image?.fields.file.url}
                alt="Purple Perks Badge"
              />
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="h4" className={classes.titlePurplePerks}>
                  {preBoldText}
                  <strong>{boldText}</strong>
                  {postBoldText}
                </Typography>
                <DoubleOutlinedBtn
                  buttonText={
                    !anonymous && user?.xp?.LoyaltyID
                      ? "View My Purple Perks"
                      : purplePerks.data?.fields.linkText || "Learn More"
                  }
                  buttonProps={{ variant: "outlined", onClick: () => handleRegisterClick() }}
                  styleProps={{
                    padding: theme.spacing(0.5, 5),
                    margin: theme.spacing(2, 0),
                    whiteSpace: "nowrap",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {purplePerks.data?.fields?.info && (
                <ReactMarkdown
                  className={classes.listPurplePerks}
                  plugins={[gfm]}
                  children={purplePerks.data?.fields?.info}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </Fragment>
  ) : (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flex-wrap="wrap" align-items="center" alignItems="center" justifyContent="center">
        <img
          className={classes.imgPurplePerksAuth}
          width="100"
          src={purplePerks.data?.fields.image?.fields.file.url}
          alt="Purple Perks Badge"
        />
        <Typography variant="h4" className={classes.titlePurplePerks}>
          {preBoldText}
          <strong>{boldText}</strong>
          {postBoldText}
        </Typography>
      </Box>
      {purplePerks.data?.fields?.info && (
        <ReactMarkdown
          className={classes.listPurplePerksAuth}
          plugins={[gfm]}
          children={purplePerks.data?.fields?.info}
        />
      )}
    </Box>
  );
};

export default PurplePerksFull;
