import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Collapse,
  TextField,
  FormControlLabel,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import bachmansIntegrations from "../../../services/bachmansIntegrations.service";
import { Errors } from "../../../constants/error.constants";
import { AppliedGiftCard, BachmansPay, PaymentTypes, UIPayment } from "../../../models/Payment";
import { isUndefined } from "lodash";
import stringService from "../../../services/string.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textPaymentMethod: {
      textTransform: "capitalize",
      lineHeight: 1,
    },
    cardMessageForm: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      "& .MuiFormControl-root": {
        width: "100%",
        margin: 0,
      },
      "& button:only-of-type": {
        alignSelf: "flex-end",
        fontSize: 10,
        marginTop: theme.spacing(1),
      },
    },
  })
);

interface CheckoutGiftCardProps {
  bachmansPay: BachmansPay;
  disabled?: boolean;
  updatePaymentAmounts: (type: PaymentTypes, payment?: UIPayment) => void;
}

const CheckoutGiftCardForm: React.FunctionComponent<CheckoutGiftCardProps> = (props) => {
  const { bachmansPay, updatePaymentAmounts, disabled } = props;
  const classes = useStyles();
  const [useGiftCard, setUseGiftCard] = useState<boolean>(false);
  const [appliedGiftCard, setAppliedGiftCard] = useState<AppliedGiftCard>();
  const [cardNumber, setCardNumber] = useState<string>();
  const [gcError, setGCError] = useState<string>();

  useEffect(() => {
    const amount = bachmansPay.GiftCard?.Amount || 0;
    if (amount === 0) {
      setUseGiftCard(false);
    }
  }, [bachmansPay.GiftCard?.Amount]);

  const applyGiftCard = async () => {
    if (cardNumber) {
      const trimmedCardNumber = cardNumber.split(" ").join("");
      const result = await bachmansIntegrations.giftCardBalance(trimmedCardNumber);
      if (result?.data?.card_value === Errors.giftCardNotAvailable) {
        setGCError("Invalid Gift Card");
        setCardNumber("");
      } else if (result?.data === "") {
        // If an empty string is returned from the jitterbit request,
        // something went wrong, but the api doesn't error.  Show generic error message.
        setGCError("Gift Card Not Found");
        setCardNumber("");
      } else if (setAppliedGiftCard) {
        updatePaymentAmounts("GiftCard", {
          Amount: parseFloat(result.data.card_value),
          AcctNumber: cardNumber,
          Balance: parseFloat(result.data.card_value),
        });
        setAppliedGiftCard({
          Number: cardNumber,
          Balance: result?.data.card_value,
        });
      }
    }
  };

  const onCardNumberChange = (e: any) => {
    setGCError(undefined);
    setCardNumber(stringService.OnlyNumbers(e.target.value));
  };

  const checkBoxChange = (e: any) => {
    setGCError(undefined);
    setUseGiftCard(e.target.checked);
    if (!e.target.checked) {
      setGCError(undefined);
      updatePaymentAmounts("GiftCard", undefined);
      setCardNumber("");
    }
    setAppliedGiftCard(undefined);
  };

  const remainingBalance = useMemo(() => {
    if (appliedGiftCard?.Balance) {
      return Math.round((appliedGiftCard.Balance - (bachmansPay.GiftCard?.Amount || 0)) * 100) / 100;
    }
  }, [appliedGiftCard?.Balance, bachmansPay.GiftCard?.Amount]);

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={useGiftCard}
            onChange={checkBoxChange}
            name="useBachmansGiftCard"
            color="primary"
            size="small"
          />
        }
        label={
          <Fragment>
            <Typography variant="h3" className={classes.textPaymentMethod} color="textSecondary">
              Use Bachman's Gift Card{" "}
              {appliedGiftCard?.Balance && (
                <Typography style={{ marginLeft: 4 }} variant="h3" component="span" color="primary">
                  {`$${appliedGiftCard?.Balance}`}
                </Typography>
              )}
            </Typography>
            {useGiftCard && !isUndefined(remainingBalance) && (
              <Typography variant="body2">Remaining Balance: ${remainingBalance}</Typography>
            )}
            {useGiftCard && remainingBalance === 0 && (
              <Typography variant="body1" color="secondary">
                Gift Card Applied.
              </Typography>
            )}
          </Fragment>
        }
      />
      <Collapse in={useGiftCard === true} unmountOnExit>
        <form className={classes.cardMessageForm} noValidate autoComplete="off">
          {!appliedGiftCard?.Balance && (
            <Fragment>
              <TextField
                disabled={disabled}
                margin="dense"
                autoFocus
                variant="outlined"
                id="useBachmansGiftCard"
                name="Enter Gift Card Number"
                label="Enter Gift Card Number"
                type="text"
                value={cardNumber || ""}
                onChange={onCardNumberChange}
                fullWidth
              />
              {gcError && (
                <Typography variant="body1" color="error">
                  {gcError}
                </Typography>
              )}
              <DoubleOutlinedBtn
                buttonText="Apply"
                buttonProps={{
                  onClick: applyGiftCard,
                  disabled: disabled,
                  variant: "outlined",
                  size: "small",
                }}
              ></DoubleOutlinedBtn>
            </Fragment>
          )}
        </form>
      </Collapse>
    </Box>
  );
};

export default CheckoutGiftCardForm;
