import {
  Container,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { Address, LineItem, Me, Orders, BuyerProduct, LineItems } from "ordercloud-javascript-sdk";
import { ChangeEvent, Fragment, useContext, useEffect, useState, useMemo } from "react";
import { AppDispatch } from "../../redux/store";
import bachmansIntegrationsService from "../../services/bachmansIntegrations.service";
import { neutral } from "../../themes/colors";
import CareAdviceInformation from "../Articles/CareAdviceInformation";
import StackedContentBlock from "../Shared/Content/StackedContentBlock";
import FilledSocialMedia from "./ProductDetail/FilledSocialMedia";
import BasicAddCartActions from "./BasicAddCartActions";
import ProductInfo from "./ProductDetail/ProductInfo";
import ProductSpecTable from "./ProductDetail/ProductSpecTable";
import ProductImages from "./ProductImages";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import addToCartService from "../../services/addToCart.service";
import deliveryResourceService from "../../services/delivery-resource.service";
import { BuyerXp, DeliveryTypes } from "../../models/buyerXp";
import { filter, flatten, isUndefined, map, find } from "lodash";
import { useAppSelector } from "../../redux/store-hook";
import { calculateOrder } from "../../redux/slices/order";
import AddToCartConfirmation from "./ProductDetail/AddToCartConfirmation";
import { BachmansProduct } from "../../models/product";
import deliveryService, { DeliveryDateOptions } from "../../services/delivery.service";
import { CatalogContext } from "../../providers/catalog";
import { DeliveryOption } from "./ProductDetail";
// Things needed for Egiftcard
import TicketedEventModal from "../Events/EventDetail/TicketedEventModal";
import { Quantity } from "../../models/product";
import useQuery from "../Shared/hooks/useQuery";
import GiftCardEmailTpl from "./GiftCardEmailTpl";
import productService from "../../services/product.service";
import { truncateSync } from "fs";
import { addressGiftcard } from "../../models/addressGiftcard";
import $ from "jquery";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
    },
    drawer: {
      flexBasis: "100%",
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: { flexBasis: "33.333%" },
    },
    gridLeft: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(1),
      },
    },
    gridRight: {
      width: "100%",
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        paddingLeft: theme.spacing(2),
      },
    },
    flexColumn: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
    },
    checkLabel: {
      color: neutral.text_white_bg,
    },
    submit: {
      color: theme.palette.secondary.main,
    },
    marginTop: {
      marginTop: theme.spacing(3),
    },
    greyBackground: {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      backgroundColor: neutral.grey_background,
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(4),
      },
    },
    minMax: {
      display: "inline",
      fontSize: "18px",
    },
    boldMinMax: {
      display: "inline",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "18px",
    },
    input: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    giftCardInput: {
      "& .MuiInputBase-input": {
        width: 75,
      },
    },
    DeliveryOptionsLabel: {
      ...theme.typography.overline,
    },
    mt2: {
      marginTop: theme.spacing(1),
    },
  })
);

interface EventDetailProps {
  eventGroupData?: BachmansProduct[];
}

export interface EventRouteMatch {
  eventCode: string;
  sku?: string;
}

const GiftCard: React.FunctionComponent = () => {
  const classes = useStyles();
  const currentOrder = useAppSelector((state) => state.order);
  const [giftCard, setGiftCard] = useState<BachmansProduct>();
  const [amount, setAmount] = useState<number | undefined>(50);
  const [min, setMin] = useState<number>();
  const [max, setMax] = useState<number>();
  const [selectedDelivery, setSelectedDelivery] = useState<DeliveryOption>();
  const [deliveryDateOptions, setDeliveryDateOptions] = useState<DeliveryDateOptions>();
  const [quantity, setQuantity] = useState<number>(1);
  const [Emailquantity, setEmailQuantity] = useState<Quantity>();
  const [lyndaleStore, setLyndaleStore] = useState<Address>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEmailgiftcard, setisEmailgiftcard] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [newLiID, setNewLiID] = useState<string>();
  const [lineItem, setLineItem] = useState<LineItem>();
  const [buyerXp, setBuyerXp] = useState<BuyerXp>();
  const { DCFcategories } = useContext(CatalogContext);
  const [showEmailPopup, setEmailPopup] = useState<boolean>(false);
  const [eventGroup, setEventGroup] = useState<BachmansProduct[]>();
  const [newGiftCard, setNewGiftCard] = useState<any>({ Items: [] });
  const [isEmailEnable, setisEmailEnable] = useState<boolean>(false);
  const [giftCardindex, setgiftCardindex] = useState<number>(1);
  const query = useQuery();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        visible: true,
        current: "Gift Cards",
      })
    );
  }, [dispatch]);

  const event = useMemo(() => {
    const sku = query.get("sku");
    return sku
      ? find(eventGroup, function (e) {
          return e.ID === sku;
        })
      : productService.GetDefaultProduct(eventGroup);
  }, [eventGroup, query]);

  useEffect(() => {
    let _newGiftCard;
    let indexNumber;
    if (DCFcategories && currentOrder) {
      (async function () {
        const [gc, bxp] = await Promise.all([
          Me.ListProducts({ filters: { "xp.ProductCode": "G0000GIFTCARD" } }).then((productList) => {
            _newGiftCard = productList;
            if (_newGiftCard) {
              setNewGiftCard({ ..._newGiftCard });
            }
          }),
          deliveryResourceService.GetBuyerXp(),
        ]);
        var validationResult = await bachmansIntegrationsService.validateGiftCard(1, true);
        if (_newGiftCard) {
          // console.log("Giftcard length",_newGiftCard["Items"]));
          if (validationResult.NumberAvailable == 0) {
            setisEmailgiftcard(true);
          }
          if (Object.keys(_newGiftCard["Items"]).length === 2) {
            setgiftCardindex(1);
            indexNumber = 1;
          } else {
            setgiftCardindex(0);
            indexNumber = 0;
            setisEmailgiftcard(true);
          }
          const methods = deliveryService.GetAvailableDeliveryMethods(
            [_newGiftCard["Items"][indexNumber]],
            DCFcategories
          );
          const options = await deliveryService.GetDateOptions({
            products: [newGiftCard?.Items[indexNumber]],
            deliveryMethods: methods,
            currentOrder: currentOrder,
          });

          if (
            Object.keys(_newGiftCard["Items"]).length == 2 &&
            _newGiftCard["Items"][0]["Active"] == true &&
            validationResult.NumberAvailable > 0
          ) {
            setisEmailEnable(true);
          }
          //Set default delivery type. ISP if all existing shipments are ISP else Delivery
          if (deliveryService.AllShipmentsArePickUp(currentOrder)) {
            const pickupMethod = currentOrder?.shipments?.some((s) => s.Shipper === "CurbsidePickUp")
              ? "CurbsidePickUp"
              : "InStorePickUp";
            setSelectedDelivery({
              selectedOption: "PickUp",
              type: pickupMethod,
              date: options.InStorePickUp?.standardMinDate.toDateString(),
            });
          } else {
            const matchingDeliveryMethod = deliveryService.GetMatchingDeliveryMethod(methods, currentOrder);
            // if (matchingDeliveryMethod) {
            //   setSelectedDelivery(deliveryService.BuildDeliveryDateOption(matchingDeliveryMethod, options));
            //   setSelectedDelivery(deliveryService.BuildDeliveryDateOption("Email", options));
            // } else if (isEmailEnable == true) {
            //   setSelectedDelivery(deliveryService.BuildDeliveryDateOption("Email", options));
            // }
            if (Object.keys(_newGiftCard["Items"]).length > 1 && validationResult.NumberAvailable > 0) {
              setSelectedDelivery(deliveryService.BuildDeliveryDateOption("Email", options));
            } else {
              setSelectedDelivery(deliveryService.BuildDeliveryDateOption("USPS", options));
            }
          }

          setDeliveryDateOptions(options);
          setGiftCard(_newGiftCard["Items"][indexNumber]);
          setBuyerXp(bxp);
          setMin(bxp?.GiftCardSettings?.PurchaseSetting?.MinPurchaseAmt || 5);
          setMax(bxp?.GiftCardSettings?.PurchaseSetting?.MaxPurchaseAmt || 500);
        }
      })();
    }
  }, [DCFcategories, currentOrder]);

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value.match(/[a-z]/i) && !event.currentTarget.value.match(/[^\w\s]|_/)) {
      setAmount(event.currentTarget.value ? Number(event.currentTarget.value) : undefined);
    }
  };

  useEffect(() => {
    (async () => {
      const lyndale = await deliveryResourceService.GetLyndaleStore();
      setLyndaleStore(lyndale);
    })();
  }, []);

  const onSubmit = async () => {
    console.log("selectedDelivery", selectedDelivery?.type);
    if (selectedDelivery?.type == "Email") {
      setQuantity(1);
      setIsSubmitting(false);
      setEmailPopup(true);
    } else {
      if (!giftCard) throw new Error("no giftCard set");
      setIsSubmitting(true);
      giftCard.Amount = amount;
      let lineItem = addToCartService.ConstructLineItem(
        {
          product: newGiftCard.Items[giftCardindex],
          isBaseItem: true,
          quantity: quantity,
          storeID: lyndaleStore?.ID || "",
          delivery: selectedDelivery,
          productCategory: { ID: "GiftCards" },
        },
        buyerXp
      );
      const order = isUndefined(currentOrder.order?.DateCreated)
        ? await Orders.Create("Outgoing", currentOrder?.order || {})
        : currentOrder.order;

      if (order?.ID) {
        let newLi = await bachmansIntegrationsService.upsertLineItem(order.ID, lineItem);
        setNewLiID(newLi.ID);
        await dispatch(calculateOrder(order.ID));
      }
      setIsSubmitting(false);
      setQuantity(1);
      setisEmailgiftcard(false);
    }
  };
  // when newLineItem ID is set && the currentShipment Slice updates -
  // find && set it to the addToCartConfirmation Modal.
  useEffect(() => {
    if (newLiID && currentOrder?.shipments?.length) {
      let li = filter(flatten(map(currentOrder.shipments, "LineItems")), (li) => {
        return newLiID === li.ID;
      });

      if (li[0]) {
        setLineItem(li[0]);
        setConfirmDialogOpen(true);
      }
    }
  }, [currentOrder.shipments, newLiID]);

  const deliveryOptionChange = (type: DeliveryTypes) => {
    if (deliveryService.IsPickUp(type)) {
      const pickupMethod = currentOrder?.shipments?.some((s) => s.Shipper === "CurbsidePickUp")
        ? "CurbsidePickUp"
        : "InStorePickUp";
      setSelectedDelivery(deliveryService.BuildDeliveryDateOption(pickupMethod, deliveryDateOptions));
    } else {
      setSelectedDelivery(deliveryService.BuildDeliveryDateOption(type, deliveryDateOptions));
    }
    if (type != "Email") {
      setisEmailgiftcard(true);
    } else {
      setisEmailgiftcard(false);
      setQuantity(1);
      $("#standard-number").val(1);
    }
  };

  const submitEgiftCard = () => {
    setIsSubmitting(false);
  };

  const submitTicketedEvent = async (
    partial: Partial<addressGiftcard>,
    quantity: Quantity,
    email: string,
    sendEventEmail: boolean
  ) => {
    console.log("partial address", partial);
    console.log("quantity", quantity);
    console.log("email", email);
    if (!giftCard) throw new Error(" no giftCard set");
    setIsSubmitting(true);
    newGiftCard.Items[0].Amount = amount;

    console.log("Giftcard", giftCard);
    const shippingAddress = giftCard?.xp.Location as addressGiftcard;
    console.log("Address object from ordercloud", shippingAddress);
    shippingAddress.FirstName = partial.FirstName;
    shippingAddress.LastName = partial.LastName;
    shippingAddress.Phone = partial.Phone;
    shippingAddress.Email = email;
    shippingAddress.xp = {};

    let lineItem = addToCartService.ConstructLineItem(
      {
        product: newGiftCard.Items[0],
        isBaseItem: true,
        quantity: quantity.quantity,
        storeID: lyndaleStore?.ID || "",
        delivery: selectedDelivery,
        shippingAddress: shippingAddress,
        productCategory: { ID: "GiftCards" },
        email: email,
      },
      buyerXp
    );
    const order = isUndefined(currentOrder.order?.DateCreated)
      ? await Orders.Create("Outgoing", currentOrder?.order || {})
      : currentOrder.order;

    if (order?.ID) {
      let newLi = await bachmansIntegrationsService.upsertLineItem(order.ID, lineItem);
      await LineItems.SetShippingAddress("Outgoing", currentOrder.order?.ID!, newLi.ID!, shippingAddress);
      setNewLiID(newLi.ID);
      await dispatch(calculateOrder(order.ID));
    }
    setIsSubmitting(false);

    // throw new Error("missing Event");
  };
  // Function for Tickiet popup diouge

  return (
    <Fragment>
      <Container className={classes.container}>
        <Grid container>
          <Grid item md={6} xs={12} className={classes.gridLeft}>
            {giftCard && <ProductImages product={giftCard} multiSku={false} />}
          </Grid>
          <Grid item md={6} xs={12} className={`${classes.gridRight} ${classes.flexColumn}`}>
            <FilledSocialMedia />
            <Typography variant="h2" style={{ color: neutral.text_white_bg, marginTop: "10px" }}>
              {giftCard?.xp?.WebFacingProductTitle || giftCard?.Name}
            </Typography>
            <TextField
              className={classes.giftCardInput}
              margin="dense"
              type="number"
              label="Amount"
              id="filled-start-adornment"
              value={amount ? amount.toString() : ""}
              error={amount! < min! || amount! > max!}
              InputProps={{
                inputProps: { min: min, max: max },
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="outlined"
              onChange={handleAmountChange}
            />
            {(amount! < min! || amount! > max!) && (
              <FormHelperText error>
                {(amount! < min! && "The minimum gift card amount is $5") ||
                  (amount! > max! && "The maximum gift card amount is $500")}
              </FormHelperText>
            )}
            {min && max && (
              <Box display="flex" alignItems="center" mt={1} style={{ gap: 8 }}>
                <Typography variant="body2" color="textSecondary">
                  Minimum: <strong>${min}</strong>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  |
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Maximum: <strong>${max}</strong>
                </Typography>
              </Box>
            )}
            <FormControl component="fieldset" className={classes.mt2}>
              <FormLabel component="legend" className={classes.DeliveryOptionsLabel}>
                Select Delivery Options
              </FormLabel>
              <RadioGroup
                row
                value={selectedDelivery?.type || ""}
                onChange={(e) => deliveryOptionChange(e.target.value as DeliveryTypes)}
              >
                {isEmailEnable && (
                  <FormControlLabel
                    className="EmailRadio"
                    value="Email"
                    control={<Radio color="primary" />}
                    label="Email"
                    labelPlacement="end"
                    onChange={() => {
                      setQuantity(1);
                    }}
                  />
                )}
                <FormControlLabel value="USPS" control={<Radio color="primary" />} label="USPS" labelPlacement="end" />
                <FormControlLabel
                  value="LocalDelivery"
                  control={<Radio color="primary" />}
                  label="Local Delivery"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="InStorePickUp"
                  control={<Radio color="primary" />}
                  label="Store Pickup"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <BasicAddCartActions
              product={giftCard}
              quantity={{ minQuantity: 0, maxQuantity: 1000, quantity: quantity }}
              quantityChanged={(value) => setQuantity(value)}
              hideWishList={true}
              disabled={!amount || !min || !max || amount < min || amount > max || isSubmitting}
              onSubmit={onSubmit}
              disableQuantity={isEmailgiftcard}
            />
          </Grid>
        </Grid>
        <GiftCardEmailTpl
          open={showEmailPopup}
          quantity={{ minQuantity: 0, maxQuantity: 1000, quantity: quantity }}
          onClose={() => setEmailPopup(false)}
          event={giftCard}
          disabled={isSubmitting}
          onSubmit={submitTicketedEvent}
        />
      </Container>
      <div className={classes.greyBackground}>
        <Container>
          <Grid container>
            <Grid item md={8} className={classes.gridLeft}>
              <ProductInfo product={giftCard} />
            </Grid>
            <Grid item md={4} className={classes.gridRight}>
              <ProductSpecTable product={giftCard} defaultProduct={giftCard} />
              {giftCard?.xp?.Articles && giftCard?.xp?.Articles.length > 0 ? (
                <CareAdviceInformation articles={giftCard?.xp?.Articles || []} />
              ) : (
                <StackedContentBlock />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <AddToCartConfirmation
        open={confirmDialogOpen}
        lineItems={lineItem ? [lineItem] : []}
        quantity={{ quantity: lineItem?.Quantity || 1 }}
        onClose={() => {
          setConfirmDialogOpen(false);
          setLineItem(undefined);
        }}
      />
    </Fragment>
  );
};

export default GiftCard;
