import {
  Container,
  createStyles,
  IconButton,
  InputBase,
  makeStyles,
  Theme,
  useMediaQuery,
  List,
  Grid,
  Button,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import { neutral } from "../../themes/colors";
import { ChevronRight, Close, MenuOpen, Search } from "@material-ui/icons";
import { filter, find, map, sortBy } from "lodash";
import productService from "../../services/product.service";
import eventService from "../../services/event.service";
import contentfulService from "../../services/contentful.service";
import MobileSearchMenu from "./MobileSearchMenu";
import SearchDropdown from "./SearchDropdown";
import SearchResults from "./SearchResults";
import { useHistory, useLocation } from "react-router-dom";
import BachmansLoading from "../Shared/BachmansLoading";
import algolia from "../../services/algolia.service";
import { Category } from "ordercloud-javascript-sdk";
import bachDateTime from "../../services/bachDateTime.service";
import appConstants from "../../constants/app.constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: neutral.search_bg,
      flex: "1 1 auto",
      maxWidth: "100%",
      padding: 0,
      "& .MuiInputBase-input": {
        padding: theme.spacing(0.5, 2, 0.5, 1),
      },
      "& .MuiIconButton-root": {
        padding: 0,
      },
      [theme.breakpoints.up("md")]: {
        height: 50,
        backgroundColor: neutral.grey_background,
        minWidth: 300,
        flexGrow: 0,
        padding: theme.spacing(1, 2, 0.75),
      },
      [theme.breakpoints.up("xl")]: {
        width: 500,
      },
    },
    searchResultsContainer: {
      width: "1200px",
      height: "690px",
      overflowY: "auto",
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        height: 500,
      },
    },
    searchResultFooter: {
      position: "relative",
      boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
      backgroundColor: neutral.text_utility_bg,
      padding: theme.spacing(1, 2),
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      zIndex: 1,
    },
    footerTitle: {
      fontFamily: theme.typography.h4.fontFamily,
      fontSize: theme.typography.h4.fontSize,
      marginRight: theme.spacing(1),
      display: "flex",
      "&>span>span>svg:last-of-type": {
        // targeting the second chevron to squeeze them together
        marginLeft: -14,
      },
    },
  })
);

interface AlgoliaListOptions {
  query: string;
  searchOptions: any;
}

const SearchBar: FunctionComponent = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const containerRef = useRef<any>();
  const [anchorRef, setAnchorRef] = useState<any>();
  const [products, setProducts] = useState<any>({});
  const [categories, setCategories] = useState<any>({});
  const [suggestions, setSuggestions] = useState<any>({});
  const [contentfulCandA, setcontentfulCandA] = useState<any>({});
  const [queryTerm, setQueryTerm] = useState("");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<any>([]);
  const [articles, setArticles] = useState<any>([]);
  const [information, setInformation] = useState<any>([]);
  const [careInfo, setCareInfo] = useState<any>({ items: [] });
  const [timer, setTimer] = useState<any>(); // this is where we will store our setTimeout function
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let enterPressed = useRef(false);

  const bestMatch = (group: any[]) => {
    return find(group, { BestAlgoliaMatch: true });
  };

  const optionsProducts = (
    filters?: any,
    l2categoryChildren?: Category[],
    categoryFilters?: string[]
  ): AlgoliaListOptions => {
    let timestamp = bachDateTime.GetLocalTimeStamp();
    let dateFilter = "StartDate <=" + timestamp + " AND EndDate >=" + timestamp;
    //let userTokenn:any;

    // Algolia Init method for sending Events
    let searchOptions: any = {
      filters: "Visible = 1 AND  " + dateFilter,
      facetFilters: ["IsDefaultProduct:true", "IsWorkShopEvent:true"],
      facets: ["IsWorkshop"],
      distinct: true,
      hitsPerPage: 1000,
    };

    // Return the searchOptions object or do any additional processing if needed.
    return searchOptions;
  };

  const performMultipleSearch = async (queries: any) => {
    try {
      let resp = await algolia.performMultipleSearch(queries);
      return resp;
    } catch (error) {
      console.error("Error performing multiple search: ", error);
    }
  };

  const callMultipleSearch = async (filters: any, arrayList?: any) => {
    const getProductOptions = await optionsProducts();
    const queryString = filters.query;
    let getQueriesList: any[] = [];

    let queryResp = await algolia.getQueriesInit(filters); // 1. initialize the queries list
    const buildOpts = await productService.BuildNoFacetOptions(filters, [], []);

    queryResp.map((indices: any) => {
      // 2. Build proper query list with buildOptions and other configs:
      if (indices.indexName === appConstants.algoliaIndexPrefix + algolia.getQuickSearchProducts()) {
        //indice: dotcom-products
        getQueriesList.push({
          indexName: indices.indexName,
          params: {
            ...buildOpts.searchOptions,
          },
          query: queryString,
        });
      } else if (indices.indexName === appConstants.algoliaIndexPrefix + algolia.getCategoryProducts()) {
        //indice: products
        getQueriesList.push({
          indexName: indices.indexName,
          ...getProductOptions,
          query: queryString,
        });
      } else {
        // includes  products_query_suggestions & contentful_data;
        getQueriesList.push({
          indexName: indices.indexName,
          query: filters.query,
        });
      }
    });

    // 3. Call MultipleSearch function
    try {
      const resp = await performMultipleSearch(getQueriesList);
      if (resp) {
        // 4. update the states as required from the response
        await Promise.all(
          resp.map(async (responseItem: any) => {
            if (responseItem.index === appConstants.algoliaIndexPrefix + algolia.getQuickSearchProducts()) {
              // dotcom_products
              mapBestMatchProducts(responseItem);
            }
            if (responseItem.index === algolia.getQuerySuggestionIndex()) {
              // products_query_suggestions
              setCategories(responseItem);
              setSuggestions(responseItem);
            }
            if (responseItem.index === appConstants.algoliaIndexPrefix + algolia.getAlgoliaContentIndex()) {
              // "contentful_data"
              setcontentfulCandA(responseItem);
            }
            if (responseItem.index === appConstants.algoliaIndexPrefix + algolia.getCategoryProducts()) {
              let sortedEventResults = responseItem;

              sortedEventResults.hits = sortBy(sortedEventResults.hits, "EndDate");
              const eventResults = await eventService.DecorateEventDetails(responseItem);
              setEvents(eventResults);
            }
          })
        );
      }
    } catch (error: any) {
      console.error("error while multiple search : ", error);
    }
  };

  const mapBestMatchProducts = async (responseList: any) => {
    // 1. Send response to product service to map with OC.
    const ocPopulatedResp = await productService.IntegrateOCAlgolia(responseList);

    // 2. Get OC integrated response and filter best match
    ocPopulatedResp.hits = await filter(ocPopulatedResp.hits, (group) => bestMatch(group));
    // 3. Set in the setProducts
    setProducts(ocPopulatedResp);
  };

  // ----> commeting the pre-existed unused code below

  // const searchProducts = async (filters: any, searchFlag?: any) => {
  //   // let quickSearchFlag =
  //   let productListData = await productService.ListProducts(filters, [], [], searchFlag);
  //   productListData.hits = filter(productListData.hits, (group) => bestMatch(group));
  //   setProducts(productListData);
  // };

  // const searchCategories = async (filters: any) => {
  //   let productListCat = await productService.ListProductsSuggestions(filters);
  //   setCategories(productListCat);
  //   setSuggestions(productListCat);
  // };

  // // Fetch contentful data from product service
  // const searchAlgoliaContent = async (filters: any) => {
  //   // filters.contenttype = 'staticpage';
  //   let productListCat = await productService.ListContentFulData(filters);
  //   setcontentfulCandA(productListCat);
  // };

  // const searchEvents = async (filters: any) => {
  //   const eventResults = await eventService.ListAsync(filters);
  //   setEvents(eventResults);
  // };

  const searchArticles = async (filters: any) => {
    try {
      let response: any = await contentfulService.getEntries({
        content_type: "article",
        query: filters.query,
        include: 1,
      });
      setArticles(
        map(response?.items, (i) => {
          i.fields.section = "information/resource-hub";
          return i;
        })
      );
    } catch (ex) {
      setArticles([]);
      return Promise.resolve();
    }
  };

  const searchInformation = async (filters: any) => {
    try {
      let response: any = await contentfulService.getEntries({
        content_type: "staticPage",
        query: filters.query,
        include: 1,
      });
      setInformation(response?.items);
    } catch (ex) {
      setInformation([]);
      return Promise.resolve();
    }
  };

  const autoComplete = async (query: string) => {
    let queue: any = [];
    if (!query) return;
    let updatedFilters = { query: query };
    setLoading(true);
    setIsDropDownOpen(true);
    // Prev Logic to call multiple indices on a keystoke invoking multiple algolia searches
    // queue.push(searchProducts(updatedFilters, true));   //dotcom-products
    // queue.push(searchCategories(updatedFilters)); //products_query_suggestions
    // queue.push(searchAlgoliaContent(updatedFilters)); //test_contentful_data
    // queue.push(searchEvents(updatedFilters)); //test_products
    //setIsDropDownOpen(true);

    queue.push(searchInformation(updatedFilters));
    queue.push(searchArticles(updatedFilters));
    // BI-479: Searching multipl indices at a single keystoke at single instance
    queue.push(callMultipleSearch(updatedFilters));
    setLoading(false);

    //queue.push(searchCategories(updatedFilters));

    return await Promise.all(queue).then(() => {
      if (enterPressed.current) {
        // don't open the drop down
        // setIsDropDownOpen(true);
        enterPressed.current = false; // reset flag
      } else {
        setIsDropDownOpen(true);
      }
    });
  };

  const toggleMenu = () => setIsMenuOpen(!MenuOpen);

  const toggleDropDown = () => setIsDropDownOpen(!isDropDownOpen);

  const handleViewAll = () => {
    toggleDropDown();
    if (products.queryID && products.queryID !== undefined) {
      history.push(`/search?query=${queryTerm}&queryid=${products.queryID}`, {
        products: products,
        events: events,
        information: information,
        articles: articles,
      });
    } else {
      history.push(`/search?query=${queryTerm}`, {
        products: products,
        events: events,
        information: information,
        articles: articles,
      });
    }
  };

  const clickAll = (message: string) => {
    toggleDropDown();

    if (products.queryID && products.queryID !== undefined) {
      history.push(`/search?query=${message}&queryid=${products.queryID}`, {
        products: products,
        events: events,
        information: information,
        articles: articles,
      });
    } else {
      history.push(`/search?query=${message}`, {
        products: products,
        events: events,
        information: information,
        articles: articles,
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setQueryTerm(searchTerm);
    clearTimeout(timer);
    setTimer(setTimeout(() => autoComplete(searchTerm), 1));
  };

  const setMobileDisplay = (info: any) => {
    if (!isDesktop) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleClearInput = () => {
    setQueryTerm("");
    setProducts({ hits: [] });
  };

  const handleKeyPress = (e: { key: string }) => {
    let urlSearchParams = new URLSearchParams(location.search);
    const currentQuery = urlSearchParams.get("query");

    if (e.key === "Enter" && queryTerm.length >= 3) {
      enterPressed.current = true;
      if (currentQuery === queryTerm) return;
      if (products.queryID && products.queryID !== undefined) {
        history.push({
          pathname: "/search",
          search: `?query=${queryTerm}&queryid=${products.queryID}`,
        });
      } else {
        history.push({
          pathname: "/search",
          search: `?query=${queryTerm}`,
        });
      }

      if (isDropDownOpen) toggleDropDown();
      setProducts({ hits: [] });
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      setAnchorRef(containerRef.current);
    }
  }, [containerRef, isDesktop]);

  useEffect(() => {
    if (articles && information) {
      let topArticles = [...articles].splice(0, isDesktop ? 5 : 10);
      let topInformation = [...information].splice(0, isDesktop ? 5 : 10);
      setCareInfo([...topArticles, ...topInformation]);
    }
  }, [articles, information, isDesktop]);
  return (
    <Fragment>
      {!isMobile ? (
        <div ref={containerRef}>
          <InputBase
            placeholder="Search for Products, Articles or Events"
            onChange={handleInputChange}
            className={classes.root}
            value={queryTerm}
            onKeyPress={handleKeyPress}
            endAdornment={
              queryTerm ? (
                <IconButton aria-label="delete" onClick={handleClearInput}>
                  <Close color="action" fontSize="small" />
                </IconButton>
              ) : (
                <Search color="action" />
              )
            }
          />

          {anchorRef && (
            <SearchDropdown anchorEl={anchorRef} open={isDropDownOpen} onClickAway={toggleDropDown}>
              <div>
                <Container className="SearchDropdown">
                  {loading && <BachmansLoading />}
                  <Grid
                    container
                    spacing={5}
                    className={classes.searchResultsContainer + " " + "SearchDropdownContainer"}
                  >
                    <Grid item sm={4}>
                      <List>
                        <SearchResults
                          variant={"Query Suggestions"}
                          results={categories.hits}
                          toggleMenu={toggleDropDown}
                          queryTerm={queryTerm}
                          queryID={categories.queryID}
                          clickAll={clickAll}
                        />
                      </List>
                      <List>
                        <SearchResults
                          variant={"Categories"}
                          results={suggestions.hits}
                          toggleMenu={toggleDropDown}
                          queryTerm={queryTerm}
                          queryID={suggestions.queryID}
                          clickAll={clickAll}
                        />
                      </List>
                    </Grid>
                    <Grid item sm={5}>
                      <List>
                        <SearchResults
                          variant={"Products"}
                          results={products.hits}
                          toggleMenu={toggleDropDown}
                          queryTerm={queryTerm}
                          queryID={products.queryID}
                        />
                      </List>
                    </Grid>
                    <Grid item sm={3}>
                      <List>
                        <SearchResults
                          variant={"Events"}
                          results={events.events}
                          toggleMenu={toggleDropDown}
                          queryTerm={queryTerm}
                          queryID={events.queryID}
                        />
                      </List>
                      <List>
                        <SearchResults
                          variant={"CareInfo"}
                          results={contentfulCandA.hits}
                          toggleMenu={toggleDropDown}
                          queryTerm={queryTerm}
                          queryID={contentfulCandA.queryID}
                        />
                      </List>
                    </Grid>
                  </Grid>
                </Container>
                <div className={classes.searchResultFooter}>
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.footerTitle}
                    endIcon={
                      <React.Fragment>
                        <ChevronRight fontSize="small" color="primary" />
                        <ChevronRight fontSize="small" color="primary" />
                      </React.Fragment>
                    }
                    onClick={handleViewAll}
                  >
                    View All Results
                  </Button>
                </div>
              </div>
            </SearchDropdown>
          )}
        </div>
      ) : (
        <IconButton aria-label="delete" onClick={setMobileDisplay}>
          <Search color="action" />
        </IconButton>
      )}

      {isMobile && (
        <MobileSearchMenu
          menuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          queryTerm={queryTerm}
          onInputChange={handleInputChange}
          onInputClear={handleClearInput}
          suggestions={suggestions}
          categories={categories}
          products={products.hits}
          events={events}
          careInfo={careInfo}
          contentfulCandA={contentfulCandA}
        />
      )}
    </Fragment>
  );
};

export default SearchBar;
