import { compact, filter, find, flatten, groupBy, isUndefined, keys, map, sortBy, uniqBy } from "lodash";
import { Me, LineItems, LineItem, BuyerProduct } from "ordercloud-javascript-sdk";
import algolia from "./algolia.service";
import bachDateTime from "./bachDateTime.service";
import moment from "moment-timezone";
import productService from "./product.service";
import { BachmansEvent, BachmansProduct } from "../models/product";
import { listAll } from "../helpers/listAll";
import { EventInput } from "@fullcalendar/react";
import { purple, neutral, green } from "../themes/colors";
import { BachmansMeUser } from "../models/user";
import { FilterData } from "../components/Product/ProductList/ProductList";

interface eventSearchOptions {
  filters: any; // sends as string,
  facetFilters: string[];
  facets: string[];
  distinct: boolean;
  hitsPerPage: number;
  page?: number;
}

export interface AlgoliaEventFilters {
  featured?: boolean;
  query?: string;
  pageSize?: number;
  page?: number;
  categoryid?: string | string[];
  type?: string[];
}

async function listAsync(filters?: FilterData, categoryFilters?: string[]) {
  let index = algolia.getProductIndex("products");
  let timestamp = bachDateTime.GetLocalTimeStamp();
  let dateFilter = "StartDate <=" + timestamp + " AND EndDate >=" + timestamp;
  let queryTerm = "";
  let searchOptions: eventSearchOptions = {
    filters: ["Visible = 1", dateFilter],
    facetFilters: ["IsDefaultProduct:true", "IsWorkShopEvent:true"], //IsWorkShopEvent = is ticked event
    facets: ["IsWorkshop"], //IsWorkshop == is an event
    distinct: true,
    hitsPerPage: 1000,
  };

  if (filters) {
    if (filters.query) queryTerm = filters.query;
    if (filters.pageSize) searchOptions.hitsPerPage = filters.pageSize;
    if (filters.page) searchOptions.page = filters.page - 1;
    if (filters.categoryid === "WorkshopsEvents_FreeEvents") {
      searchOptions.filters.push("IsWorkshop = 0");
    } else if (filters.categoryid === "WorkshopsEvents_TicktedEvents") {
      searchOptions.filters.push("IsWorkshop = 1");
    }
    let categoryArray: string[] = [];
    categoryFilters?.forEach((catId) => categoryArray.push(`CategoryId:${catId}`));
    searchOptions.facetFilters.push(categoryArray as any);
    if (filters.featured !== undefined) {
      searchOptions.filters.push(`Featured = ${filters.featured}`);
    }
  }
  searchOptions.filters = searchOptions.filters.join(" AND ");
  let algoliaResponse = await index.search(queryTerm, searchOptions);
  algoliaResponse.hits = sortBy(algoliaResponse.hits, "EndDate");
  return await decorateEventDetails(algoliaResponse);
}

async function decorateEventDetails(
  algoliaResponse: any
): Promise<{
  events: BachmansEvent[];
  facets: any;
}> {
  let productCodes = map(algoliaResponse.hits, (e) => e.ProductCode);

  if (!algoliaResponse.hits.length) return Promise.resolve({ events: [], facets: {} });

  let listResponse = await productService.ListProductsByCodes(productCodes, false, true);

  let events = compact(
    map(algoliaResponse.hits, (algoliaEvent) => {
      let ocProducts = filter(listResponse, (op) => {
        return op.xp.ProductCode === algoliaEvent.ProductCode;
      });
      if (!ocProducts.length) return;
      let defaultEvent: any = filter(ocProducts, (op) => {
        return op.xp.IsDefaultProduct;
      })[0]; //TODO: create an interface for default product & its xp, so type checking is enabled properly
      if (!defaultEvent) return;
      return mapToBachmansEvent(defaultEvent, ocProducts);
    })
  ) as any[];
  return { events: events, facets: algoliaResponse?.facets };
}

async function listPurchasedEventsAsync(currentUser: BachmansMeUser, isAnonymous: boolean): Promise<BachmansEvent[]> {
  if (isAnonymous || !currentUser.xp?.PurchasedEvents || !currentUser.xp?.PurchasedEvents.length) return [];
  let orders = await Me.ListOrders({ page: 1, pageSize: 100 });
  let queue: any = [];
  orders.Items.forEach((order) => queue.push(LineItems.List("Outgoing", order.ID)));
  let lineItems = await Promise.all(queue);
  let allLi = flatten(map(lineItems, "Items"));
  let myEvents = map(currentUser.xp?.PurchasedEvents, (eventID) =>
    find(allLi, (li) => li.ProductID === eventID)
  ).filter((e) => !isUndefined(e));
  let eventProducts = map(myEvents, (event) => {
    let eventProd = event?.Product;
    eventProd.Name = event?.xp?.EventName || event?.Product?.Name;
    eventProd.Description = event?.xp?.EventDescription || event?.Product?.Description;
    eventProd.xp.Room = event?.xp?.EventRoom;
    if (eventProd?.xp?.Contentful && eventProd?.xp.Contentful.Images && eventProd.xp.Contentful.Images[0].url) {
      eventProd.xp.Contentful.Images[0].url = event.xp.ShownImage;
    }
    eventProd.xp.DateDisplay = event.xp.EventDateDisplay;
    eventProd.UnitPrice = event.UnitPrice;
    return eventProd;
  });
  return eventProducts as BachmansEvent[];
}
//EventSourceFunc
async function getCalendarEventsAsync(
  arg: {
    // start: Date;
    // end: Date;
    start: any;
    end: any;
    startStr: string;
    endStr: string;
    timeZone: string;
  },
  successCallback: (events: EventInput[]) => void,
  failureCallback: (error: any) => void
): Promise<any> {
  let options = {
    pastEvents: true,
    pageSize: 100,
    filters: {
      "xp.IsWorkShopEvent": true,
      "xp.Visible": true,
    },
  };
  function getEventsInRange(eventArray: any) {
    return filter(eventArray, (e) => {
      return (
        e.xp.ReleaseDate2 > moment().subtract(1, "month").format("YYYY-MM-DD") ||
        e.xp.ReleaseDate1 < moment(arg.end).format("YYYY-MM-DD")
      );
    });
  }

  let productData = await listAll(productService.ListOcProducts, options);
  let allEvents = getEventsInRange(productData);
  let currentDate = bachDateTime.Today();
  let hasDefEvents = filter(allEvents, (event) => {
    //  filter out events that don't have defaults.
    let definedEvent = find(allEvents, (evt) => event.xp.ProductCode === evt.xp.ProductCode && evt.xp.IsDefaultProduct);
    return definedEvent && definedEvent !== null;
  });
  let results = uniqBy(
    flatten(
      map(hasDefEvents, (event) => {
        if (event.xp.IsWorkshop) {
          let definedEvent =
            find(hasDefEvents, (evt) => {
              return event.xp.ProductCode === evt.xp.ProductCode && evt.xp.IsDefaultProduct;
            }) || event;
          let disabled = moment(event.xp.ReleaseDate2).tz("America/Chicago").startOf("d").isBefore(currentDate, "d");
          return {
            id: event.ID,
            title: definedEvent.xp.WebFacingProductTitle || definedEvent.Name,
            start: moment.utc(event.xp.ReleaseDate2).toDate(),
            productcode: event.xp.ProductCode,
            currentTimezone: "CST",
            disable: disabled,
            className: disabled ? "paid-event disable" : "paid-event",
            color: disabled ? neutral.grey_background : purple.regular,
            backgroundColor: disabled ? neutral.grey_background : purple.regular,
            borderColor: disabled ? neutral.grey_background : purple.regular,
            textColor: disabled ? neutral.text_light_bg : neutral.text_utility_bg,
          };
        }
        return map(event.xp.EventDateTimes, (val, key) => {
          var dateToUse = val.Date ? val.Date : key;
          let disabled = moment(dateToUse).tz("America/Chicago").startOf("d").isBefore(currentDate, "d");
          return {
            id: event.ID,
            title: event.xp.WebFacingProductTitle || event.Name,
            start: moment.utc(dateToUse).toDate(),
            productcode: event.xp.ProductCode,
            currentTimezone: "CST",
            disable: disabled,
            className: disabled ? "free-event disable" : "free-event",
            color: disabled ? neutral.grey_background : green.regular,
            backgroundColor: disabled ? neutral.grey_background : green.regular,
            borderColor: disabled ? neutral.grey_background : green.regular,
            textColor: disabled ? neutral.text_light_bg : neutral.text_utility_bg,
          };
        });
      })
    ),
    function (e) {
      return e.productcode + "_" + e.start;
    }
  );
  if (!results) {
    return failureCallback({ message: "Something went wrong" });
  } else {
    return successCallback(results);
  }
}

function eventDateSort(events: any) {
  return sortBy(events, (eventGroup) => {
    var now = moment().tz("America/Chicago").startOf("d");
    var upcomingEvents = sortBy(
      filter(eventGroup.RelatedDates, (rd) => {
        return !now.isAfter(rd.Date, "d");
      }),
      (rd) => {
        return rd.Date;
      }
    );
    return upcomingEvents[0].Date;
  });
}

function mapToBachmansEvent(event: BachmansEvent, eventGroup: BachmansEvent[]) {
  if (event.xp?.IsWorkshop) {
    event.RelatedDates = map(
      keys(
        groupBy(eventGroup, (op) => {
          return op.xp?.ReleaseDate2;
        })
      ),
      (rdate2) => {
        return { Date: rdate2.split("T")[0], Visible: true };
      }
    );
  } else {
    var relatedDateArray = flatten(
      map(eventGroup, (oc) => {
        return keys(oc.xp?.EventDateTimes);
      })
    );
    event.RelatedDates = map(relatedDateArray, (date) => {
      return { Date: date, Visible: true };
    });
  }
  event.EventDate = sortBy(event.RelatedDates, "Date")[0].Date;
  return event;
}

function getDateNeeded(SelectedEvent: BachmansProduct) {
  let dateNeededMoment = moment.utc(SelectedEvent.xp.ReleaseDate2).startOf("d");
  let now = moment.utc();
  if (now.isSame(dateNeededMoment, "d")) {
    dateNeededMoment = now.add(5, "m");
  }
  return dateNeededMoment.toISOString(false);
}

// Function accepts an event ID or an event code and returns the event group with address info
async function getEvent(eventIdOrCode: string): Promise<BachmansEvent[]> {
  var events: BachmansEvent[] = await productService.ListProductsByCodes([eventIdOrCode], false, true);
  if (!events?.length) {
    const event = await Me.GetProduct(eventIdOrCode);
    events = await productService.ListProductsByCodes([event.xp?.ProductCode], false, true);
    return complete(events);
  } else {
    return complete(events);
  }

  async function complete(data: BachmansProduct[]): Promise<BachmansEvent[]> {
    var defaultEvent = productService.GetDefaultProduct(data);

    //Do not show events when the default event is not available
    if (!defaultEvent) return Promise.reject();

    let defaultAddress = await Me.GetAddress(defaultEvent.xp?.LocationID);
    return events.map((e) => {
      const mappedEvent = mapToBachmansEvent(e, events);
      if (mappedEvent?.xp) {
        mappedEvent.xp.Location = defaultAddress;
      }
      return e;
    });
  }
}

function getLineItemEventTimeRage(lineItem: LineItem) {
  return lineItem.Product?.xp?.SpecsOptions?.Size && lineItem.Product?.xp?.SpecsOptions?.Color
    ? ` ${lineItem.Product?.xp?.SpecsOptions?.Size}-${lineItem.Product?.xp?.SpecsOptions?.Color}`
    : "";
}

const getFormattedEventDate = (event: BuyerProduct): string => {
  const dateString = event.xp?.ReleaseDate2?.split("T")[0];
  return moment(dateString).format("dddd") + ", " + moment(dateString).format("MMMM DD");
};

const service = {
  ListAsync: listAsync,
  DecorateEventDetails: decorateEventDetails,
  ListPurchasedEventsAsync: listPurchasedEventsAsync,
  EventDateSort: eventDateSort,
  GetEvent: getEvent,
  GetCalendarEventsAsync: getCalendarEventsAsync,
  MapToBachmansEvent: mapToBachmansEvent,
  GetDateNeeded: getDateNeeded,
  GetLineItemEventTimeRange: getLineItemEventTimeRage,
  GetFormattedEventDate: getFormattedEventDate,
};

export default service;
