import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { LineItem } from "ordercloud-javascript-sdk";
import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from "react";
import { min, max } from "lodash";
import { BachmansProduct } from "../../models/product";

import Currency from "react-currency-formatter";

export interface ProductPriceProps {
  productGroup?: BachmansProduct[];
  product?: BachmansProduct;
  lineItem?: LineItem;
  alignment?: string;
  fontSize?: string;
  onSaleChange?: (value: string) => void;
}

const useStyles = (props: ProductPriceProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        margin: "10px",
      },
      containerPrice: {
        flex: 0,
        [theme.breakpoints.up("md")]: {
          flex: "unset",
        },
      },
      productPrice: {
        color: theme.palette.primary.main,
        padding: "2px",
        fontSize: props?.fontSize ? props.fontSize : "initial",
      },
      retailPrice: {
        textDecoration: "line-through",
        padding: "2px",
        color: "grey",
        fontSize: props?.fontSize ? props.fontSize : "initial",
        fontWeight: theme.typography.fontWeightRegular,
      },
    })
  );
const ProductPrice: FunctionComponent<ProductPriceProps> = (props) => {
  const classes = useStyles(props)();
  const { productGroup, product, lineItem, alignment, onSaleChange } = props;
  const [actualMinMax, setActualMinMax] = useState<number[] | null>(null);
  const [retailMinMax, setRetailMinMax] = useState<number[]>();

  const isGiftCard = useMemo(() => {
    if (productGroup) {
      return productGroup[0]?.ID === "GIFTCARD";
    }
    if (product) {
      return product.ID === "GIFTCARD";
    }
    if (lineItem) {
      if (lineItem.ProductID == "EGIFTCARD") {
        return lineItem.ProductID === "EGIFTCARD";
      } else {
        return lineItem.ProductID === "GIFTCARD";
      }
    }
  }, [productGroup, product, lineItem]);

  useEffect(() => {
    let p = product ? Object.assign(product) : undefined;
    if (isGiftCard) {
      //TODO: display right price for gift card
      // let actualPrice =
      if (lineItem) {
        setActualMinMax([lineItem?.UnitPrice as number]);
      }
      if (p) {
        setActualMinMax([(p as any)?.PriceSchedule?.PriceBreaks?.[0]?.Price as number]);
      }
    } else {
      if (productGroup) {
        if (productGroup.length > 1) {
          const actualPriceRange = productGroup.map((p: any) => p.PriceSchedule.PriceBreaks[0].Price as number);
          const actualMin = Number(min(actualPriceRange));
          const actualMax = Number(max(actualPriceRange));

          const retailPriceRange = productGroup.map((p: any) => p.PriceSchedule.xp.Price?.RetailPrice as number);
          const retailMin = Number(min(retailPriceRange));
          const retailMax = Number(max(retailPriceRange));

          setActualMinMax([actualMin, actualMax]);
          setRetailMinMax([retailMin, retailMax]);
        } else {
          p = Object.assign({}, productGroup[0]);
        }
      }
      if (p || lineItem) {
        let actualPrice, retailPrice;
        if (!(p as any)?.PriceSchedule.xp.Price) {
          setActualMinMax(null); // Reset actualMinMax to null
        }
        if (p) {
          actualPrice = (p as any).PriceSchedule.PriceBreaks[0].Price as number;
          retailPrice = (p as any).PriceSchedule.xp.Price?.RetailPrice as number;
        } else if (lineItem) {
          actualPrice = lineItem.UnitPrice as number;
          retailPrice = lineItem.xp.RetailPrice as number;
          if (!retailPrice) setActualMinMax([actualPrice]);
        }

        if (actualPrice && retailPrice) {
          setActualMinMax([actualPrice]);
          setRetailMinMax([retailPrice]);
        }
      }
    }
  }, [isGiftCard, productGroup, product, lineItem, onSaleChange]);

  useEffect(() => {
    if (!onSaleChange) return;
    if (actualMinMax && retailMinMax && retailMinMax.join("-") !== actualMinMax.join("-")) {
      var minPercentage = Math.round(((retailMinMax[0] - actualMinMax[0]) / retailMinMax[0]) * 100);
      if (actualMinMax.length > 1) {
        var maxPercentage = Math.round(((retailMinMax[1] - actualMinMax[1]) / retailMinMax[1]) * 100);
        onSaleChange(maxPercentage > minPercentage ? `Up to ${maxPercentage}` : String(minPercentage));
      } else {
        onSaleChange(String(minPercentage));
      }
    }
  }, [actualMinMax, retailMinMax, onSaleChange]);

  return (
    <Fragment>
      {lineItem ? (
        <Fragment>
          <Typography id="productPrice" variant="h4" className={classes.productPrice}>
            {actualMinMax && (
              <Fragment>
                <Currency quantity={actualMinMax[0]} />
                {actualMinMax[1] && actualMinMax[0] !== actualMinMax[1] && (
                  <Fragment>
                    <Currency quantity={actualMinMax[1]} />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Typography>

          {retailMinMax && actualMinMax && retailMinMax.join("-") !== actualMinMax.join("-") && (
            <Typography variant="h4" className={classes.retailPrice}>
              <Currency quantity={retailMinMax[0]} />
              {retailMinMax[1] && retailMinMax[0] !== retailMinMax[1] && (
                <Fragment>
                  <Currency quantity={retailMinMax[1]} />
                </Fragment>
              )}
            </Typography>
          )}
        </Fragment>
      ) : (
        <Box className={classes.containerPrice} display="flex" justifyContent={alignment || "center"}>
          <Typography variant="h4" className={classes.productPrice}>
            {actualMinMax && (
              <Fragment>
                <Currency quantity={actualMinMax[0]} />
                {actualMinMax[1] && actualMinMax[0] !== actualMinMax[1] && (
                  <Fragment>
                    {` - `}
                    <Currency quantity={actualMinMax[1]} />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Typography>

          {retailMinMax && actualMinMax && retailMinMax.join("-") !== actualMinMax.join("-") && (
            <Typography variant="h4" className={classes.retailPrice}>
              <Currency quantity={retailMinMax[0]} />
              {retailMinMax[1] && retailMinMax[0] !== retailMinMax[1] && (
                <Fragment>
                  {` - `}
                  <Currency quantity={retailMinMax[1]} />
                </Fragment>
              )}
            </Typography>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default ProductPrice;
