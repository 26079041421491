import queryString from "querystring";
import { StaticPageRouteData } from "../components/StaticPages/StaticPageNavigation";
import { regexConstants } from "../constants/regex.constants";

export type StringOption = "camel" | "dash" | "space";

const camel2Dash = (val: string, stringOption: StringOption) => {
  var result;
  if (!stringOption) {
    return val;
  } else if (stringOption === "camel") {
    result = val.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    return result.charAt(0).toUpperCase() + result.substr(1);
  } else if (stringOption === "dash") {
    result = val.replace(/([A-Z])/g, function (g) {
      return "-" + g[0].toLowerCase();
    });
    return result.substr(1);
  } else if (stringOption === "space") {
    if (val === "TFE" || val === "FTD") return "Nationwide Delivery";
    if (val === "USPS") return val;
    if (val === "InStorePickUp") return "In-Store Pickup";
    if (val === "CurbsidePickUp") return "Curbside Pickup";
    if (val === "UPS") return val;
    return val.charAt(0).toUpperCase() + val.substr(1).replace(/[A-Z]/g, " $&");
  } else {
    return val;
  }
};

const getStaticPageParams = (location: Location): StaticPageRouteData => {
  const queryParams = queryString.parse(location.search.replace("?", ""));
  const pathArray = location.pathname.split("/").filter((p) => p !== "");
  return {
    tag: queryParams?.tag?.toString(),
    search: queryParams?.search?.toString(),
    page: parseInt(queryParams?.page?.toString()),
    section: pathArray && pathArray[0] ? pathArray[0] : undefined,
    rootslug: pathArray && pathArray[1] ? pathArray[1] : undefined,
    branchslug: pathArray && pathArray[2] ? pathArray[2] : undefined,
    leafslug: pathArray && pathArray[3] ? pathArray[3] : undefined,
  };
};

const getQueryParamsFromLocation = (search: string): any => {
  return queryString.parse(search.replace("?", ""));
};

const capitalizeString = (str: string, separators?: string[]) => {
  if (str !== null && str !== undefined) {
    separators = separators || [" "];
    var regex = new RegExp("(^|[" + separators.join("") + "])(\\w)", "g");
    return str.toLowerCase().replace(regex, function (x) {
      return x.toUpperCase();
    });
  }
  return str;
};

const arraysEqual = (arr1?: string[], arr2?: string[]) => {
  var equal = true;
  arr1?.forEach((i) => {
    if (!arr2?.includes(i)) {
      equal = false;
    }
  });
  arr2?.forEach((i) => {
    if (!arr1?.includes(i)) {
      equal = false;
    }
  });
  return equal;
};

const amPmFormat = (input: any, char?: number) => {
  if (input) {
    char = char ? char - 1 : 0;
    let ampm = input.split(" ");
    let letter = ampm[1].toLowerCase();
    let out = "";
    for (let i = 0; i < letter.length; i++) {
      out += letter[i] + ".";
    }
    return ampm[0] + " " + out;
  }
};

const getFirstPath = (pathName: string) => pathName.split("/")[1];

const preCompare = (text: string) => {
  //remove whitespace, periods and lowercase text
  if (text && typeof text === "string") {
    return text.replace(/\s/g, "").replace(/\./g, "").toLowerCase();
  } else {
    return "";
  }
};

const onlyNumbers = (val: string) => val.replace(regexConstants.nonNumeric, "");

const validEmail = (val: string): boolean => regexConstants.validEmail.test(val);

const onlyAlphaNumeric = (val: string) => {
  if (regexConstants.alphaNumeric.test(val)) {
    return val;
  }
};

const onlyAlpha = (val: string) => val.replace(regexConstants.invalidName, "");

const service = {
  Camel2Dash: camel2Dash,
  CapitalizeString: capitalizeString,
  ArraysEqual: arraysEqual,
  AmPmFormat: amPmFormat,
  GetQueryParamsFromLocation: getQueryParamsFromLocation,
  GetStaticPageParams: getStaticPageParams,
  GetFirstPath: getFirstPath,
  PreCompare: preCompare,
  OnlyNumbers: onlyNumbers,
  ValidEmail: validEmail,
  OnlyAlphaNumeric: onlyAlphaNumeric,
  OnlyAlpha: onlyAlpha,
};

export default service;
