class DecorateCalcResponseError extends Error {
  public LineItemIDs?: string[];
  constructor(msg: string, lineItemIDs?: string[]) {
    super(msg);
    this.LineItemIDs = lineItemIDs;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, DecorateCalcResponseError.prototype);
  }
}

export default DecorateCalcResponseError;
