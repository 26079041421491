import { compact } from "lodash";
import moment from "moment";
import { BuyerProduct, RequiredDeep } from "ordercloud-javascript-sdk";
import bachDateTime from "./bachDateTime.service";
import { BachmansProduct } from "../models/product";

const isProductAvailable = (product: BuyerProduct, showPast?: boolean) => {
  const currentDate = bachDateTime.Today();
  if (showPast) return currentDate.isSameOrAfter(product?.xp?.ReleaseDate1, "d");
  return currentDate.isBetween(
    moment(product.xp.ReleaseDate1, "YYYY-MM-DD").tz("America/Chicago").startOf("d"),
    moment(product.xp.ReleaseDate2, "YYYY-MM-DD").tz("America/Chicago").endOf("d"),
    null,
    "[]"
  );
};

const productInventoryDateFilter = (
  products: RequiredDeep<BuyerProduct>[],
  showPast?: boolean
): RequiredDeep<BuyerProduct>[] => {
  return products.filter((p) => isProductAvailable(p));
};

const inventoryCodeB3 = (
  products: RequiredDeep<BuyerProduct>[],
  showPast?: boolean
): RequiredDeep<BachmansProduct>[] => {
  const checkProduct = (product: BuyerProduct) => {
    if (showPast) return true;
    else
      return (
        (product && product.xp && product.xp.CodeB3 === "9") ||
        (product.Inventory && product.Inventory?.QuantityAvailable! - Number(product?.xp?.SafetyStock) > 0)
      );
  };

  const hasOtherProductOptions = (product: BuyerProduct) => {
    return (
      product?.xp?.ProductCode &&
      products.filter((p) => p?.xp?.ProductCode === product?.xp?.ProductCode)?.filter((p) => checkProduct(p))?.length >
        1
    );
  };

  const res1 = products.map((p) => {
    if (checkProduct(p)) {
      return p as RequiredDeep<BachmansProduct>;
    } else {
      if (hasOtherProductOptions(p) || p?.xp?.IsWorkShopEvent) {
        (p as BachmansProduct).DisableAddtoCart = true;
        return p as RequiredDeep<BachmansProduct>;
      } else return null;
    }
  });

  return compact(res1);
};

const service = {
  IsProductAvailable: isProductAvailable,
  ProductInventoryDateFilter: productInventoryDateFilter,
  InventoryCodeB3: inventoryCodeB3,
};

export default service;
