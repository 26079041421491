export const Errors = {
  genericLine1Error: "We cannot process your order at this time.",
  genericLine2Error: "Please try again later.",
  shipmentsNotValidLine1: "Some of your selections are no longer valid",
  shipmentsNotValidLine2: "Please review your cart.",
  inventoryError: "Error submitting order. Item(s) on order are out of stock",
  passwordNotMeetSecurity: "Password does not meet security requirements.",
  giftCardInventoryExceeded:
    "Your order exceeds the number of Gift Cards we have available. <br> Please update your cart or contact (612) 861-7311 for assistance.",
  giftCardProcessingError:
    "We cannot process your Gift Card order at this time. <br> Please contact (612) 861-7311 for assistance.",
  giftCardNotAvailable: "cardNumber not available",
};
