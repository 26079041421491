import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import locationService from "../../../services/locationService";
import { AddressAutoCompleteProps } from ".";
import { Destination } from "../../../constants/destinations";
import { cloneDeep } from "lodash";

export interface GoogleAddress {
  description?: string;
  matched_substrings?: any[];
  place_id?: string;
  reference?: string;
  terms?: GoogleAddressTerms[];
  types?: string[];
  structured_formatting?: StructuredFormatting;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
}
interface GoogleAddressTerms {
  offset: number;
  value: string;
}

function loadScript(src: string, position: HTMLHeadElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

let autocompleteService: any = {};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}));

interface GoogleAddressCompleteProps extends AddressAutoCompleteProps {
  destination?: Destination;
  inputValue: string;
  onInputChange: (val?: string) => void;
  error?: boolean;
}

const GoogleAutoComplete: React.FunctionComponent<GoogleAddressCompleteProps> = (props) => {
  const { destination, inputValue, onInputChange, error } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState<GoogleAddress>({});
  //const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<any[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const getDetails = (address: GoogleAddress) => {
    const formField = document.getElementById("google-map-autocomplete") as HTMLDivElement;
    if (address && address.place_id) {
      var service = new window.google.maps.places.PlacesService(formField);
      service.getDetails({ placeId: address.place_id! }, function (result: any, status: any) {
        const ocAddress = locationService.MapGoogleToOrderCloud(result, props.addressType !== "Residence");
        setValue(address);
        if (props.onAddressSelect) {
          props.onAddressSelect(ocAddress);
        }
        setOptions(address ? [address, ...options] : options);
      });
    } else {
      setValue(address);
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: props?.destination?.googleOptions?.types }, (results: any) => {
      if (active) {
        let newOptions: any[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, props?.destination?.googleOptions?.types]);

  const getInitialValue = (): any => {
    if (inputValue) {
      return inputValue;
    }
    return value || "";
  };

  return (
    <Autocomplete
      id="google-map-autocomplete"
      getOptionLabel={(option) => (typeof option === "string" ? option : option?.description?.split(",")[0] || "")}
      clearOnBlur={false}
      filterOptions={(x) => x}
      options={options}
      includeInputInList
      filterSelectedOptions
      value={getInitialValue()}
      getOptionSelected={(option, value) => option.place_id === value?.place_id}
      onChange={(event, newValue: GoogleAddress) => {
        getDetails(newValue);
      }}
      onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
      renderInput={(params) => (
        <form autoComplete="off">
          <TextField
            required
            {...params}
            label={destination?.searchLabel}
            margin="dense"
            variant="outlined"
            fullWidth
            error={error}
            helperText={error && destination?.errorText}
          />
        </form>
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default GoogleAutoComplete;
