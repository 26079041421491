import React, { FunctionComponent, Fragment, useContext } from "react";
import { createStyles, makeStyles, Theme, useMediaQuery, Tooltip, MenuItem, IconButton } from "@material-ui/core";
import { LIActionBarContext } from "../../../providers/lineItemAction";
import { neutral } from "../../../themes/colors";

interface LineItemActionProps {
  title: string;
  icon: any;
  onClick: any;
  disabled?: boolean;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    lineItemIconButton: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",
      padding: theme.spacing(0.5),
      backgroundColor: neutral.grey_background,
      opacity: 0.7,
      "& .MuiButton-startIcon": {
        marginRight: 4,
      },
      "& svg": {
        fontSize: ".8rem",
      },
      "&:hover, &:focus": {
        opacity: 1,
      },
    },
    lineItemIconBorder: {
      border: "1px solid rgba(0, 0, 0, 0.14);",
    },
  })
);
const LineItemAction: FunctionComponent<LineItemActionProps> = (props) => {
  const classes = useStyle();
  const { title, icon, onClick, disabled } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { toggleOpen } = useContext(LIActionBarContext);

  const handleOnClick = () => {
    //invoke the menu state to its opposite state.
    onClick();
    if (toggleOpen) {
      toggleOpen();
    }
  };
  return (
    <Fragment>
      {isMobile ? (
        <MenuItem onClick={handleOnClick} dense>
          {icon} {title}
        </MenuItem>
      ) : (
        <Tooltip title={title} enterDelay={400}>
          <IconButton
            className={`${classes.lineItemIconButton} ${classes.lineItemIconBorder}`}
            onClick={onClick}
            disabled={disabled}
            aria-label={title}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};
export default LineItemAction;
