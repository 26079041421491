import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import DropdownMenu from "../../Shared/DropdownMenu";
import Link from "../../Shared/Link";
import useDebounce from "../../Shared/hooks/useDebounce";
export interface contenfulStaticPageInfo {
  //TODO: use staticPage data model
  fields: {
    title: string;
    link: string;
    backgroundImage: Object;
  };
  children?: any[];
}
export interface contentfulContentLinkAndTitle {
  link: string;
  title: string;
}
type ContentLinkType = "article" | "staticPage" | "event";
export interface ContentLinks {
  ID: string;
  Title?: string;
  Type: ContentLinkType;
  Links: Array<contenfulStaticPageInfo | contentfulContentLinkAndTitle>;
}

interface ContentLinksMenuProps {
  open?: boolean;
  menuTitle: string;
  menuLink?: string;
  contentLinks: ContentLinks[];
}

export const isContenfulSaticPageInfo = (variableToCheck: any): variableToCheck is contenfulStaticPageInfo =>
  (variableToCheck as contenfulStaticPageInfo).fields !== undefined;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    spacer: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    dropdown: {
      paddingTop: theme.spacing(3),
    },
    title: {
      paddingTop: theme.spacing(1.5),
      fontSize: theme.typography.h5.fontSize,
    },
    link: {
      paddingTop: theme.spacing(1),
    },
  })
);

const ContentLinksMenu: FunctionComponent<ContentLinksMenuProps> = (props) => {
  const classes = useStyle();
  const { contentLinks, menuTitle, menuLink } = props;
  const containerRef = useRef<any>();
  const [anchorRef, setAnchorRef] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const debouncedOpen = useDebounce(isOpen, 400, { toggle: "on" });

  useEffect(() => {
    if (containerRef.current) {
      setAnchorRef(containerRef.current);
    }
  }, [containerRef]);

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div ref={containerRef} className={classes.root} onMouseEnter={handleMenuOpen} onMouseLeave={handleMenuClose}>
      <Link to={menuLink ? menuLink : "#"} variant="body1" color="inherit">
        {menuTitle}
      </Link>
      <span className={classes.spacer}>|</span>
      <DropdownMenu
        open={debouncedOpen}
        anchorEl={anchorRef}
        arrowPosition={"left"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box px={2} py={1.5} width={250}>
          {contentLinks.map((list, index) => (
            <div key={index}>
              {list.Title && (
                <Typography className={classes.title} gutterBottom variant="subtitle2">
                  {list.Title}
                </Typography>
              )}
              <div className={`${!list.Title ? classes.link : ""}`}>
                {list.Type === "article"
                  ? list.Links &&
                    list.Links.map((linkInfo: any, linkIndex: any) => (
                      // console.log("linkInfo", linkInfo),
                      <Link
                        key={linkIndex}
                        to={
                          linkInfo.title === "All Care Guides"
                            ? linkInfo.link
                            : "/information/resource-hub?tag=" + encodeURIComponent(linkInfo.title)
                        }
                        variant="body1"
                        color="textPrimary"
                        display="block"
                        gutterBottom
                      >
                        {linkInfo.fields ? linkInfo.fields.title : linkInfo.title}
                      </Link>
                    ))
                  : list.Links &&
                    list.Links.map((linkInfo: any, linkIndex: any) => (
                      <Link
                        key={linkIndex}
                        to={`${linkInfo?.fields?.link ? linkInfo?.fields?.link : linkInfo.link}`}
                        variant="body1"
                        color="textPrimary"
                        display="block"
                        gutterBottom
                      >
                        {linkInfo.fields ? linkInfo.fields.title : linkInfo.title}
                      </Link>
                    ))}
              </div>
            </div>
          ))}
        </Box>
      </DropdownMenu>
    </div>
  );
};

export default ContentLinksMenu;
