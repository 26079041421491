import React, { Fragment, FunctionComponent, useMemo, useCallback, useEffect, useState } from "react";
import {
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import Link from "../../Shared/Link";
import { Category } from "ordercloud-javascript-sdk";
import { contentfulEventArticleLinks, contenfulStaticPage } from "../../../models/contentful/links";
import { map } from "lodash";
import categoryService from "../../../services/category.service";
import { useHistory } from "react-router-dom";

export type linkCoulmnType = "eventLink" | "categoryLink" | "resourcehublink";
interface LinkColumnProps {
  title: string;
  expandedIndex: number;
  expandCallback: (index: number) => void;
  index: number;
  links: Array<Category | contentfulEventArticleLinks | contenfulStaticPage>;
  isDesktop: boolean;
  type: linkCoulmnType;
}

interface formattedLink {
  Title: string;
  Link: string;
  Type: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    desktopGridColumn: {
      "&:not(:last-child)": {
        "& > div": {
          height: "100%",
          borderRight: `1px solid ${theme.palette.grey[200]}`,
          marginRight: theme.spacing(2.5),
          paddingRight: theme.spacing(2.5),
        },
      },
    },
    desktopColumnHeader: {
      marginBottom: theme.spacing(1.5),
    },
    desktopLinkItem: {
      marginBottom: theme.spacing(1),
    },
  })
);

const LinkColumn: FunctionComponent<LinkColumnProps> = (props) => {
  const { title, expandedIndex, index, expandCallback, links, isDesktop, type } = props;
  const classes = useStyle();
  const [formatedLinks, setFormattedLinks] = useState([]);
  let history = useHistory();
  useEffect(() => {
    if (links && links.length) {
      if ("ID" in links[0]) {
        //logic for category
        let formatted: any = map(links, (cat) => {
          if ("ID" in cat) {
            if (cat.ID == "OnSale") {
              return { Link: `${cat.xp?.Link}`, Title: cat.Name };
            }
            return { Link: `${cat.ID}`, Title: cat.Name };
          }
        });
        if (formatted) {
          setFormattedLinks(formatted);
        }
      }
      if ("fields" in links[0]) {
        //logic for contentfulStaticPage
        let formatted: any = map(links, (page) => {
          if ("fields" in page) {
            return { Link: `${page.fields.link}`, Title: page.fields.title };
          }
        });
        if (formatted) {
          if (type === "resourcehublink") {
            formatted.unshift({ Link: "/information/resource-hub", Title: "All Care Guides" });
          }
          setFormattedLinks(formatted);
        }
      }
      if ("title" in links[0]) {
        //logic for events & article
        let formatted: any = map(links, (info) => {
          if ("title" in info) {
            return { Link: `${info.link}`, Title: info.title };
          }
        });
        if (formatted) {
          setFormattedLinks(formatted);
        }
      }
    }
  }, [links]);

  const handleExpandClick = useCallback(
    (index: number) => (e: React.MouseEvent) => {
      expandCallback(index);
    },
    [expandCallback]
  );

  const getRoute = (link: formattedLink) => {
    return link?.Link.includes("/") ? link?.Link || "" : `${categoryService.BuildCategoryUrlFromId(link.Link, "c")}`;
  };

  const goToRoute = (link: formattedLink) => {
    const route = getRoute(link);
    history.push(route);
  };

  const isExpanded = useMemo(() => {
    return expandedIndex === index;
  }, [expandedIndex, index]);

  return isDesktop ? (
    <Grid className={classes.desktopGridColumn} container direction="column" item xs={12} md={2}>
      <div>
        <Typography variant="h4" color="inherit" className={classes.desktopColumnHeader}>
          {title}
        </Typography>
        {formatedLinks &&
          formatedLinks.map((link: formattedLink, i: number) => (
            <Link
              to={getRoute(link)}
              variant="body2"
              display="block"
              color="inherit"
              className={classes.desktopLinkItem}
              key={i}
            >
              {link.Title}
            </Link>
          ))}
      </div>
    </Grid>
  ) : (
    <Fragment>
      <ListItem button onClick={handleExpandClick(index)}>
        <ListItemText primary={title} primaryTypographyProps={{ variant: "h4" }}></ListItemText>
        {isExpanded ? <Remove /> : <Add />}
      </ListItem>
      <Collapse in={isExpanded}>
        <List dense>
          {formatedLinks &&
            formatedLinks.map((link: formattedLink, index) => (
              <ListItem button key={index} onClick={() => goToRoute(link)}>
                <ListItemText primary={link.Title}></ListItemText>
              </ListItem>
            ))}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default LinkColumn;
