import { Box, createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { BuyerAddress, Me } from "ordercloud-javascript-sdk";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import locationService from "../../services/locationService";
import { neutral } from "../../themes/colors";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import AddressDialog from "./AddressDialog";
import BachmansLoading from "../Shared/BachmansLoading";
import ConfirmDialog from "../Shared/ConfirmDialog";
import { deliveryTextMap } from "../../constants/DeliveryText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      display: "flex",
      flexFlow: "column nowrap",
    },
    addressBox: {
      flexGrow: 1,
      height: "100%",
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(1.5),
    },
    address: {
      fontWeight: theme.typography.fontWeightBold,
      color: neutral.text_white_bg,
      lineHeight: 1.4,
      marginTop: theme.spacing(2),
    },
    link: {
      cursor: "pointer",
    },
  })
);

const AddressBook: React.FunctionComponent = () => {
  const classes = useStyles();
  const [addresses, setAddresses] = useState<BuyerAddress[]>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<BuyerAddress>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const options = { page: 1, pageSize: 100, filters: { Editable: true, Shipping: true } };
    Me.ListAddresses(options).then((addresses) => {
      setAddresses(addresses.Items);
      setTimeout(() => {
        setLoading(false);
      }, 750);
    });
  }, []);

  const handleOpenDialog = (address?: BuyerAddress) => (event: React.MouseEvent<any>) => {
    setDialogOpen(!dialogOpen);
    setSelectedAddress(address);
  };

  const handleOpenDeleteDialog = (address?: BuyerAddress) => (event: React.MouseEvent<any>) => {
    setDeleteDialogOpen(!deleteDialogOpen);
    setSelectedAddress(address);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const onAddressUpdate = (newAddress: BuyerAddress) => {
    var isNewAddress = !addresses?.map((a) => a.ID).includes(newAddress.ID);
    if (isNewAddress) {
      setAddresses([...(addresses || []), newAddress]);
    } else {
      setAddresses(
        addresses?.map((a) => {
          return a.ID === newAddress.ID ? newAddress : a;
        })
      );
    }
    goBackToPrevious();
  };

  const onAddressDelete = (deletedAddressID: string) => {
    setAddresses(addresses?.filter((address) => address.ID !== deletedAddressID));
    setDeleteDialogOpen(false);
    goBackToPrevious();
  };

  const goBackToPrevious = (): void => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let navBackTo = urlSearchParams ? urlSearchParams.get("navBackTo") : "";
    if (navBackTo) {
      window.open(`${navBackTo}`, "_self");
    }
  };

  const onAddressSubmit = async (address: BuyerAddress): Promise<void> => {
    if (selectedAddress && selectedAddress.ID) {
      await Me.PatchAddress(selectedAddress.ID, address);
      onAddressUpdate({ ID: selectedAddress.ID, ...address });
    } else {
      if (!address?.xp) {
        address.xp = {
          addressType: "Residence",
        };
      }
      const newAddress = await Me.CreateAddress(address);
      onAddressUpdate(newAddress);
    }
  };

  const deleteAddress = async (address?: BuyerAddress): Promise<void> => {
    if (address && address.ID) {
      await Me.DeleteAddress(address.ID);
      setDeleteDialogOpen(false);
      onAddressDelete(address.ID);
    } else setDeleteDialogOpen(false);
  };

  const getAddressNickName = (address?: BuyerAddress) => {
    if (address?.xp?.DeliveryMethod !== "InStorePickUp") {
      if (address?.xp?.NickName) {
        return address?.xp?.NickName;
      } else if (address?.Zip && !address?.xp?.Nickname) {
        return `${address?.FirstName} ${address?.LastName}`;
      } else {
        return deliveryTextMap[(address?.Shipping as unknown) as string]?.text;
      }
    } else {
      return address?.AddressName + " Store";
    }
  };

  return (
    <Fragment>
      {loading ? (
        <BachmansLoading text="Loading Addresses..." />
      ) : (
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} sm={4}>
            <DoubleOutlinedBtn
              buttonText="Add New Address"
              styleProps={{
                width: "calc(100% - 8px)",
                margin: "0 auto",
              }}
              buttonProps={{
                onClick: handleOpenDialog(),
              }}
            />
            <AddressDialog
              variant="Shipping"
              open={dialogOpen}
              address={selectedAddress}
              dialogTitle={selectedAddress ? "Edit Address" : "New Address"}
              onClose={handleClose}
              onSubmit={onAddressSubmit}
            ></AddressDialog>
            <ConfirmDialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              onAccept={() => deleteAddress(selectedAddress)}
              title="Are you sure you want to delete this address?"
              loadingText="Deleting address..."
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {addresses &&
                addresses.map((address) => (
                  <Grid item xs={12} md={4} key={address.ID} className={classes.gridItem}>
                    <div className={classes.addressBox}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box display="flex" flexDirection="column" mt={0.5}>
                          <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                            {getAddressNickName(address)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {address?.xp?.addressType || "Residence"}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography color="primary" variant="body2">
                            <Link className={classes.link} color="primary" onClick={handleOpenDialog(address)}>
                              Edit
                            </Link>
                            <Box display="inline-block" px={1.5}>
                              |
                            </Box>
                            <Link className={classes.link} color="primary" onClick={handleOpenDeleteDialog(address)}>
                              Delete
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        title="addressCardID"
                        className={classes.address}
                        dangerouslySetInnerHTML={{
                          __html: locationService.BuildAddressLines(address, "full", true),
                        }}
                      ></Typography>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default AddressBook;
