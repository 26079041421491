import { createStyles, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import { DynamicContentCardProps } from "./DynamicContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
      },
      "& .slick-track": {
        [theme.breakpoints.down("md")]: {
          display: "flex",
          gap: theme.spacing(2),
        },
        "& .slick-slide img": {
          aspectRatio: "1.5 / 1",
        },
      },
      "& .slick-arrow": {
        cursor: "pointer",
        color: "white",
        zIndex: 1,
        transition: "opacity .25s",
        opacity: 0.8,
        width: "1.5em",
        height: "1.5em",
        "&:hover": {
          opacity: 1,
        },
        "&.slick-prev": {
          left: 0,
        },
        "&.slick-next": {
          right: 0,
        },
      },
      "& .slick-dots": {
        bottom: "-25px",
        "& li": {
          margin: 0,
          "&.slick-active button:before": {
            color: theme.palette.primary.dark,
            opacity: 1,
          },
          "& button:before": {
            opacity: 0.75,
            fontSize: 12,
            color: "grey",
          },
        },
      },
    },
    carouselBox: {},
    header: {
      marginBottom: theme.spacing(1),
    },
  })
);

const DynamicCarousel: React.FunctionComponent<DynamicContentCardProps> = (props) => {
  const { content } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const settings = {
    dots: isMobile ? false : true,
    arrows: true,
    nextArrow: <ChevronRight fontSize="large" color="inherit" style={{ color: "white" }} />,
    prevArrow: <ChevronLeft fontSize="large" color="inherit" style={{ color: "white" }} />,
    centerMode: isMobile ? true : false,
    centerPadding: "64px",
    initialSlide: 0,
    easing: "ease",
    adaptiveHeight: true,
  };

  return (
    <div className={classes.carouselBox}>
      <div className={classes.header}>
        <Typography gutterBottom variant="h4">
          {content.title}
        </Typography>
        <Typography variant="body1">{content.description}</Typography>
      </div>
      <Slider className={classes.root} {...settings}>
        {content?.carouselImages?.map((image, index) => (
          <img
            key={index}
            src={image.fields.file.url}
            className={"carouselImages"}
            alt={content.title + "Carousel Image"}
          ></img>
        ))}
      </Slider>
    </div>
  );
};

export default DynamicCarousel;
