import React, { Fragment, FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import { Box, ButtonBase, Container, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Link from "../Shared/Link";
import { AuthDialogTab } from "../Authentication/AuthDialog";
import { SessionContext } from "../../providers/session";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

interface ShoppingCartHeadingProps {
  state?: AuthDialogTab;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartTitle: {
      width: "100%",
      margin: theme.spacing(5, 0, 3, 0),
      padding: theme.spacing(0, 1.5),
    },
    buttonContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    btnAccount: {
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.5),
    },
  })
);

const ShoppingCartHeading: FunctionComponent<ShoppingCartHeadingProps> = ({ state }, props) => {
  const classes = useStyles();
  const { anonymous } = useContext(SessionContext);
  const containerRef = useRef<any>();
  const [accountMobileMenuOpen, setAccountMobileMenuOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        visible: true,
        current: "Cart",
      })
    );
  }, [dispatch]);

  const handleAuthDialogOpen = () => {
    dispatch(setBreadcrumbs({ authDialogOpen: true }));
  };

  const toggleAccountMobileMenu = () => {
    setAccountMobileMenuOpen(!accountMobileMenuOpen);
  };

  return (
    <Container className={classes.cartTitle}>
      <Typography variant="h1">Shopping Cart</Typography>
      <Box mt={1}>
        {anonymous ? (
          <Fragment>
            <Typography variant="body2">
              Already have an account?
              <ButtonBase
                className={classes.btnAccount}
                onClick={anonymous ? handleAuthDialogOpen : toggleAccountMobileMenu}
                ref={containerRef}
              >
                Login
              </ButtonBase>
            </Typography>
          </Fragment>
        ) : (
          <Link to="/account/address-book" variant="body2" underline="none" className={classes.btnAccount}>
            View My Address Book
          </Link>
        )}
      </Box>
    </Container>
  );
};

export default ShoppingCartHeading;
