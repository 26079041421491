import { createClient, ContentfulClientApi, EntryCollection, ContentTypeCollection } from "contentful";
import { StaticPageState } from "../providers/contentful";
import { each, filter, find, groupBy, map, sortBy } from "lodash";
import { SimpleContentModel } from "../models/contentful/SimpleContent";
import { ComplexContentModel } from "../models/contentful/ComplexContent";
import appConfig from "../constants/app.constants";

import { SimpleContentsBlockState, ComplexContentsBlockState } from "../providers/contentful";

export type contentfulBlockType = "banner" | "full" | "inline" | "stacked" | "dynamic";
// interface PagesGroupedBySection {
//   [key: string]: StaticPage[];
// }
class Contentful {
  private client: ContentfulClientApi;

  constructor() {
    this.client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
      environment: appConfig.contentfulEnv as string,
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY as string,
    });
  }
  public getContentTypes(query?: any): Promise<ContentTypeCollection> {
    return this.client.getContentTypes(query);
  }

  public getEntries<T = any>(query?: any): Promise<EntryCollection<T>> {
    return this.client.getEntries<T>(query);
  }

  public getParsedEntries = (query?: any) => {
    return this.client.getEntries<any>(query).then(this.client.parseEntries);
  };

  public getContentTypeAsync = (contentTypeID: string) => {
    return this.client.getContentType(contentTypeID);
  };

  public getAssets = (query?: any) => {
    return this.client.getAssets(query);
  };

  public groupStaticPagesBySection = (staticPages: StaticPageState) => {
    if (staticPages.data) {
      let sections = groupBy(
        sortBy(
          filter(staticPages.data, (p: any) => p.fields.navigation && !p.fields.parentPage),
          (p: any) => p.fields.listOrder
        ),
        (p: any) => p.fields.section
      );
      //format and add links to pages;  sections: PagesGroupedBySection
      return each(sections, (pages: any) => {
        //pages: StaticPage[]
        pages = map(pages, (page: any) => {
          page.fields.link = `/${page.fields?.section}/${page.fields?.slug}`;
          return page;
        });
      });
    } else {
      return {};
    }
  };

  public getArticlesByTag = () => {
    return this.getContentTypeAsync("article").then((results) => {
      let tags = find(results.fields, { id: "tags" })?.items?.validations[0]?.in;
      return map(tags, (tag) => {
        return { link: `/information/resource-hub?tag=${tag}`, title: tag };
      });
    });
  };

  public getWorkshopAndEventsLinks = async () => {
    let response = await this.getEntries({
      content_type: "eventsLanding",
      include: 2,
    });
    let parsedRespone: any = this.client.parseEntries(response);
    let complexContent = parsedRespone.items[0]?.fields?.navLinks;
    return map(complexContent, (c) => {
      return { link: c?.fields?.link, title: c?.fields?.title };
    });
  };

  public getSimpleContentFromIDs(ids: string[], simpleContent: SimpleContentsBlockState): SimpleContentModel[] {
    var matched = simpleContent.data?.filter((d) => ids?.includes(d.sys.id));
    return matched?.map((simple) => simple.fields) || [];
  }

  public getComplexContentFromIDs(ids: string[], complexContent: ComplexContentsBlockState): ComplexContentModel[] {
    var matched = complexContent.data?.filter((d) => ids?.includes(d.sys.id));
    return matched?.map((complex) => complex.fields) || [];
  }

  // private PaginateVirtually(
  //   items: Entry<any>[],
  //   page: number = 1,
  //   pageSize: number = 10
  // ) {
  //   return items.slice((page - 1) * pageSize, page * pageSize);
  // }
}

export default new Contentful();
