import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import MaterialLink from "../../components/Shared/Link";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../redux/slices/breadcrumbs";
import { AppDispatch } from "../../redux/store";
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { EventsLandingContext } from "../../providers/contentful";
import React from "react";
import FullWidthContentBlock from "../Shared/Content/FullWidthContentBlock";
import { CatalogContext } from "../../providers/catalog";
import CategoryHeader from "../Category/CategoryHeader";
import BachmansContentBlock from "../Shared/Content/BachmansContentBlock";
import { ComplexContent } from "../../models/contentful/ComplexContent";
import { neutral } from "../../themes/colors";
import { BuyerProduct } from "ordercloud-javascript-sdk";
import productService from "../../services/product.service";
import BachmansCarousel from "../Shared/BachmansCarousel";
import DescriptionRelatedServices from "../Category/DescriptionRelatedServices";
import CategoryFeaturedContent from "../Category/CategoryFeaturedContent";
import { ArrowRight } from "@material-ui/icons";
import deliveryResourceService from "../../services/delivery-resource.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentRow: {
      marginBottom: theme.spacing(3),
    },
    eventCard: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& img": {
        backgroundColor: neutral.grey_background,
        aspectRatio: "3 / 1 !important",
      },
      "& @supports not (aspect-ratio: 3 / 1)": {
        paddingTop: "33.27814569536424%",
        height: 0,
        width: "100%",
        position: "relative",
        overflow: "hidden",
        "& img": {
          objectFit: "cover",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          left: "50%",
          top: "50%",
          width: "auto",
          maxWidth: "100%",
          height: "auto",
        },
      },
    },
    linkViewAll: {
      display: "inline-flex",
      alignItems: "center",
      lineHeight: 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
    greyBackground: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      backgroundColor: neutral.grey_background,
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(4),
      },
    },
  })
);

interface ContentRows {
  firstRow?: ComplexContent[];
  secondRow?: ComplexContent[];
}

const EventsLanding: React.FunctionComponent = () => {
  const classes = useStyles();
  const eventsContent = useContext(EventsLandingContext);
  const [featuredEvents, setFeaturedEvents] = useState<BuyerProduct[]>();
  const { categories } = useContext(CatalogContext);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        current: "Workshops & Events",
        dark: false,
        visible: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    (async function getFeatured() {
      let buyerInfo = await deliveryResourceService.GetBuyerXp();
      let featured = await productService.ListProductsByCodes(buyerInfo?.FeaturedEvents, true);
      setFeaturedEvents(featured);
    })();
  }, []);

  const eventsCategory = useMemo(() => {
    if (categories) {
      const events = categories.find((c) => c.ID === "WorkshopsEvents");
      return events;
    }
  }, [categories]);

  const contentRows: ContentRows = useMemo(() => {
    const splitIndex = Math.floor((eventsContent?.data?.fields?.complexContent?.length || 0) / 2);
    const content = eventsContent?.data?.fields?.complexContent;
    return {
      firstRow: content?.slice(0, splitIndex),
      secondRow: content?.slice(splitIndex, content?.length),
    };
  }, [eventsContent]);

  interface ContentRowProps {
    contentArray?: ComplexContent[];
  }
  const ContentRow: React.FunctionComponent<ContentRowProps> = (props) => {
    const { contentArray } = props;
    const classes = useStyles();
    const colSize = contentArray?.length === 2 ? 6 : 4; //the contentArray will only have length 2 or 3.
    return (
      <Grid container spacing={3} className={classes.contentRow}>
        {contentArray &&
          contentArray.length &&
          contentArray.map((content, i) => (
            <Grid item xs={12} sm={colSize} key={i} className={contentArray?.length === 2 ? classes.eventCard : ""}>
              <BachmansContentBlock content={content} />
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <div>
      <CategoryHeader category={eventsCategory} />
      <Container>
        <ContentRow contentArray={contentRows.firstRow} />
        <ContentRow contentArray={contentRows.secondRow} />
        {eventsContent.data?.fields?.fullWidthContent?.fields && (
          <FullWidthContentBlock fullWidthContent={eventsContent.data?.fields?.fullWidthContent?.fields} />
        )}
      </Container>
      <div className={classes.greyBackground}>
        <Container>
          <Fragment>
            {featuredEvents && featuredEvents.length && (
              <Fragment>
                <Typography variant="h4" gutterBottom>
                  Featured Workshops & Events
                  <MaterialLink className={classes.linkViewAll} to="/e/workshops-events" color="primary">
                    View All Events
                    <ArrowRight fontSize="small" />
                  </MaterialLink>
                </Typography>
                <BachmansCarousel variant="event" carouselData={featuredEvents}></BachmansCarousel>
              </Fragment>
            )}
            <DescriptionRelatedServices category={eventsCategory} />
          </Fragment>
        </Container>
      </div>
      <Container>
        <div>{eventsCategory && <CategoryFeaturedContent category={eventsCategory}></CategoryFeaturedContent>}</div>
      </Container>
    </div>
  );
};

export default EventsLanding;
