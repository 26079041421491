import React, { FunctionComponent } from "react";
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { Facebook, Instagram, Pinterest, YouTube } from "@material-ui/icons";
import { neutral, purple } from "../../../themes/colors";

interface SociaMediaLinksProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: neutral.offwhite_bg,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: theme.spacing(4, 0, 3, 0),
      "& > a": { margin: theme.spacing(0, 1, 0, 1) },
      "& .MuiSvgIcon-root": {
        fontSize: "1.9rem",
      },
      // reduced Mui iconButton padding-x below 'xs' breakpoints
      [theme.breakpoints.down("xs")]: {
        "& .MuiIconButton-root": {
          padding: "12px 0",
        },
      },
    },
  })
);
const SocialMediaLinks: FunctionComponent<SociaMediaLinksProps> = (props) => {
  const classes = useStyle();

  const TikTokIcon = ({ color = purple.regular }) => {
    return (
      <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width="25" height="25">
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

  const NewTwitterX = ({ color = purple.regular }) => {
    return (
      <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25">
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
      </svg>
    );
  };

  return (
    <div className={classes.root}>
      <IconButton color="primary" target="_blank" href="https://www.instagram.com/shopbachmans/">
        <Instagram />
      </IconButton>
      <IconButton color="primary" target="_blank" href="https://www.facebook.com/BachmansMN">
        <Facebook />
      </IconButton>
      <IconButton color="primary" target="_blank" href="https://www.youtube.com/user/BachmansFloral">
        <YouTube />
      </IconButton>
      <IconButton color="primary" target="_blank" href="https://www.pinterest.com/ShopBachmans/">
        <Pinterest />
      </IconButton>
      <IconButton color="primary" target="_blank" href="https://www.tiktok.com/@bachmans">
        <TikTokIcon />
      </IconButton>
      <IconButton color="primary" target="_blank" href="https://twitter.com/shopbachmans">
        <NewTwitterX />
      </IconButton>
    </div>
  );
};

export default SocialMediaLinks;
