import React, { Fragment, useMemo } from "react";
import { Address } from "ordercloud-javascript-sdk";
import { Box, createStyles, makeStyles, Link, Theme, Typography } from "@material-ui/core";
import { Location } from "./GoogleMaps";
import { neutral } from "../../themes/colors";
import locationService from "../../services/locationService";
import LinkTo from "../Shared/Link";

export interface StoreListProps {
  storeData?: Address[];
  currentPosition?: Location;
  updateCenter?: (loc: Location) => void;
  updateZoom?: (z: number) => void;
}

interface StoresWithDistance extends Address {
  distance: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: theme.typography.fontWeightBold,
      color: neutral.text_white_bg,
    },
    address: {
      marginBottom: theme.spacing(1),
    },
    linkText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    coloredOrderedList: {
      paddingLeft: theme.spacing(2),
      "& > li": {
        "&::marker": {
          color: theme.palette.primary.main,
          fontSize: "16px",
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
    listItem: {
      cursor: "pointer",
      padding: theme.spacing(1.5),
      borderTop: `1px solid ${neutral.text_utility_bg}`,
      "&:hover": {
        backgroundColor: neutral.offwhite_bg,
      },
    },
  })
);

export const StoreList: React.FunctionComponent<StoreListProps> = (props) => {
  const { storeData, currentPosition, updateCenter, updateZoom } = props;
  const classes = useStyles();
  const directionsUrl = locationService.DirectionsUrlPrefix;

  const sortByDistance = (stores: StoresWithDistance[]) =>
    stores.sort((firstEl, secondEl) => (firstEl.distance > secondEl.distance ? 1 : -1));

  const storesWithDistance: StoresWithDistance[] = useMemo(() => {
    const stores =
      storeData && storeData.length && currentPosition
        ? storeData?.map((store) => ({
            ...store,
            distance: locationService.GetDistance(currentPosition, store?.xp?.Location),
          }))
        : [];
    return [
      ...sortByDistance(stores.filter((store) => store?.xp?.StoreType === "Flagship")),
      ...sortByDistance(stores.filter((store) => store?.xp?.StoreType !== "Flagship")),
    ];
  }, [storeData, currentPosition]);

  const handleClick = (storeLocation: Location) => (e: React.MouseEvent) => {
    if (updateCenter) {
      updateCenter(storeLocation);
    }
    if (updateZoom) {
      updateZoom(15);
    }
  };

  return (
    <Fragment>
      <ol className={classes.coloredOrderedList}>
        {storesWithDistance.map((store: StoresWithDistance) => (
          <Fragment key={store.ID}>
            <li className={classes.listItem} onClick={handleClick(store.xp?.Location)} id={store.ID}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subtitle1" className={classes.text}>
                  {store?.CompanyName}
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  {store?.distance} mi
                </Typography>
              </Box>
              <div className={classes.address}>
                <Typography variant="body2" className={classes.text}>
                  {store?.Street1}
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  {store?.City + ", " + store?.State + " " + store?.Zip}
                </Typography>
              </div>
              <span className={classes.linkText}>
                <LinkTo to={`/store-locator/${store.ID}`} variant="body2" className={classes.linkText}>
                  Store Hours & Information
                </LinkTo>{" "}
                |{" "}
                <Link
                  href={`${directionsUrl + store?.xp?.PlaceID}&destination=${store.xp?.Location.lat},${
                    store.xp?.Location.lng
                  }`}
                  target="_blank"
                  variant="body2"
                  className={classes.linkText}
                >
                  Get Directions
                </Link>
              </span>
            </li>
          </Fragment>
        ))}
      </ol>
    </Fragment>
  );
};
