import { cloneDeep, compact, filter, map, reduce } from "lodash";
import { Me, Order, Meta, LineItems, Shipment, Payments } from "ordercloud-javascript-sdk";
import { BachmansShipmentWithLineItems } from "../models/shipment";
import BachmansOrder, { CompletedOrder } from "../models/order";
/**
 * filters out orders from me perspective that don't have a shipment
 */
export interface AccountOrderListResponse {
  Items: CompletedOrder[];
  Meta: Meta;
}
async function listOrdersAsync(page: number, orderID?: string | null): Promise<AccountOrderListResponse> {
  let filters: any = { Status: "!Unsubmitted" };
  if (orderID) filters.ID = orderID;
  let orders = await Me.ListOrders({ page: page, pageSize: 10, filters: filters });
  let successfulOrders: any = { Meta: null, Items: null };
  let validOrderIds: string[] = [];
  let shipmentQueue = orders.Items.map(async (order) => {
    let shipmentList = await Me.ListShipments({ orderID: order.ID });
    if (shipmentList.Meta.TotalCount > 0) validOrderIds.push(order.ID);
    return shipmentList;
  });
  await Promise.all(shipmentQueue);
  successfulOrders.Meta = orders.Meta;
  successfulOrders.Items = filter(orders.Items, (o) => validOrderIds.includes(o.ID));
  let orderQueue = successfulOrders.Items.map(async (order: Order) => {
    return await getCompletedOrderAsync(order);
  });
  successfulOrders.Items = await Promise.all(orderQueue);
  return successfulOrders;
}

/**
 * decorate order with payments and shipments that have line items and totals
 * @param order
 * @returns MyBachOrder
 */
async function getCompletedOrderAsync(order: BachmansOrder): Promise<CompletedOrder> {
  try {
    let myOrder: CompletedOrder = Object.assign({}, order);
    if (!myOrder.ID) throw console.error("need orderID");
    let lineItemData = await LineItems.List("Outgoing", myOrder.ID, { pageSize: 100 }); // lis have additional info such as LineTotal and ShippingAddress that shipment items don't have
    let shipmentData = await Me.ListShipments({ orderID: order.ID, pageSize: 100 });
    let queue: any = [];
    shipmentData.Items.forEach((shipment: Shipment) => {
      queue.push(
        (async () => {
          if (!shipment.ID) return;
          let updatedShipment: BachmansShipmentWithLineItems = Object.assign({ LineItems: [] }, shipment);
          let shipmentItems = await Me.ListShipmentItems(shipment.ID);
          let shipmentItemLIs = map(shipmentItems.Items, "LineItemID");
          updatedShipment.LineItems = filter(cloneDeep(lineItemData).Items, (li) => {
            return shipmentItemLIs.includes(li.ID);
          });
          updatedShipment.Subtotal = reduce(
            updatedShipment.LineItems,
            (total, li) => {
              return total + (li.LineTotal || 0);
            },
            0
          );
          updatedShipment.Total = updatedShipment.Subtotal + (updatedShipment?.Cost || 0);

          let test = updatedShipment.LineItems.length ? updatedShipment.LineItems[0].ShippingAddress : undefined;
          updatedShipment.ToAddress = test;
          return updatedShipment;
        })()
      );
    });
    let updatedShipments = await Promise.all(queue);
    let paymentData = await Payments.List("Outgoing", myOrder.ID);
    myOrder.Shipments = updatedShipments as BachmansShipmentWithLineItems[];
    myOrder.Payments = paymentData.Items;
    return myOrder;
  } catch (ex) {
    throw console.error(ex);
  }
}

const statusMap = {
  Received: [
    "DISPATCHED",
    "New",
    "Turn Around",
    "Customer Servic",
    "Ret2Yrd",
    "Printed",
    "Exported",
    "Staged Design",
    "Staged",
    "Staged Plants",
    "In Production",
    "In Plants",
    "In Xmas Prod",
    "Staged Xmas",
    "Store Staged",
    "Store Out",
    "Store Rec",
    "In Design",
  ],
  Delivered: ["DELIVERED", "Complete"],
  Canceled: ["CANCELED"],
};
/**
 * Takes in shipment.xp.Status, returns a readable display
 * @param ddmsStatus
 * @param shouldTranslate
 * @returns string
 */
function orderStatusFilter(ddmsStatus: string | undefined, shouldTranslate: boolean) {
  if (!ddmsStatus) return "Processing";
  if (!shouldTranslate) return ddmsStatus;
  return (
    compact(
      map(statusMap, function (val, key) {
        if (val.indexOf(ddmsStatus) > -1) return key;
      })
    )[0] || "Received"
  );
}

const service = {
  ListOrdersAsync: listOrdersAsync,
  GetCompletedOrderAsync: getCompletedOrderAsync,
  OrderStatusFilter: orderStatusFilter,
};

export default service;
