import React, { Fragment, useEffect, useRef, useState } from "react";
import eventService from "../../services/event.service";
import BachmansCarousel from "./BachmansCarousel";

export const carouselSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  fade: true,
};

const FeaturedEventsCarousel: React.FunctionComponent = () => {
  const [featuredEvents, setFeaturedEvents] = useState<any[]>();
  const componentMounted = useRef(true);

  useEffect(() => {
    eventService.ListAsync().then((eventData) => {
      if (componentMounted.current) {
        setFeaturedEvents(eventData.events);
      }
    });
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <Fragment>
      {featuredEvents && featuredEvents.length && (
        <BachmansCarousel
          carouselData={featuredEvents}
          variant="event"
          dotsInside={true}
          settings={carouselSettings}
        ></BachmansCarousel>
      )}
    </Fragment>
  );
};

export default FeaturedEventsCarousel;
