import {
  alpha,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
  Card,
  CardActions,
  Box,
  Theme,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { ComplexContentModel } from "../../../models/contentful/ComplexContent";
import { DoubleOutlinedLinkBtn } from "../DoubleOutlinedBtn";
import { neutral } from "../../../themes/colors";
import theme from "../../../themes/main";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderRadius: "0px",
      border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
      padding: theme.spacing(2),
    },
    topLineText: {
      fontStyle: "italic",
    },
    cardContent: {
      flexBasis: "100%",
      color: neutral.text_white_bg,
      [theme.breakpoints.up("sm")]: {
        padding: 0,
        flexBasis: "auto",
      },
    },
    title: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    cardAction: {
      flexBasis: "100%",
      padding: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1, 0, 0, 0),
        flexBasis: "auto",
      },
    },
  })
);
interface FullWidthContentBlockProps {
  fullWidthContent: ComplexContentModel;
}

const FullWidthContentBlock: FunctionComponent<FullWidthContentBlockProps> = (props) => {
  const classes = useStyles();
  const { fullWidthContent } = props;

  return (
    <Card className={classes.root} elevation={0}>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <CardContent className={classes.cardContent}>
          <Typography variant="body1" color="primary" className={classes.topLineText}>
            {fullWidthContent?.superTitle}
          </Typography>
          <Typography variant="h2" color="primary" className={classes.title}>
            {fullWidthContent?.title}
          </Typography>
          <Typography variant="body2">{fullWidthContent?.subTitle}</Typography>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <DoubleOutlinedLinkBtn
            href={fullWidthContent?.linkUrl ? fullWidthContent.linkUrl : ""}
            target="_blank"
            buttonText={fullWidthContent?.linkText ? fullWidthContent.linkText : "Learn More"}
            buttonProps={{ variant: "outlined" }}
            styleProps={{
              padding: theme.spacing(0.5, 2),
            }}
          />
        </CardActions>
      </Box>
    </Card>
  );
};

export default FullWidthContentBlock;
