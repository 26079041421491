import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import HomeStackedContent from "../Home/HomeStackedContent";
import ListLayoutWithFilters from "../Shared/ListLayoutWithFilters";
import CareAdviceList from "../StaticPages/CareAdvice/CareAdviceList";
import CareAdviceSearchResult from "../StaticPages/CareAdvice/CareAdviceSearchResult";

interface CareAdviceInfoListProps {
  infoResults: any[];
}

const CareAdviceInfoList: FunctionComponent<CareAdviceInfoListProps> = (props) => {
  const { infoResults } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const articleList = useMemo(() => {
    return (
      <CareAdviceSearchResult
        searchResults={infoResults}
        sortState="Newest to Oldest"
        articleSelect={(slug: string, info: any) => {
          let routePrefix = "";
          routePrefix = info.section ? `/${info.section}` : `/information/resource-hub`;
          let route: any = { pathname: `${routePrefix}${slug ? `/${slug}` : ""}` };
          history.push(route);
        }}
        pageSize={100}
      ></CareAdviceSearchResult>
    );
  }, [infoResults, history]);
  return (
    <Fragment>
      <ListLayoutWithFilters
        title={null}
        filters={<div></div>}
        stackedContent={<HomeStackedContent />}
        itemList={articleList}
        pagination={<div></div>}
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
      ></ListLayoutWithFilters>
    </Fragment>
  );
};

export default CareAdviceInfoList;
