import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const PhoneMaskInput = (props: any) => {
  // https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      guide={false}
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
};

PhoneMaskInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default PhoneMaskInput;
