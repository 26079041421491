import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, makeStyles, Theme, Typography } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import BannerPromotion from "../Shared/BannerPromotion";
import { CategoryProps } from "./CategoryFeaturedContent";
import fallBackBannerImg from "../../assets/images/fallback-banner.jpg";
import { SimpleContentModel } from "../../models/contentful/SimpleContent";
import contentfulService from "../../services/contentful.service";
import { SimpleContentBlocksContext } from "../../providers/contentful";

const useStyles = (headerPosition?: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      slide: {
        overflow: "hidden",
        height: 150,
        position: "relative",
        [theme.breakpoints.up("md")]: {
          height: 350,
          backgroundColor: theme.palette.grey[300],
        },
      },
      slideMarginBottom: {
        marginBottom: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          marginBottom: theme.spacing(8),
        },
      },
      image: {
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: -1,
        marginTop: theme.spacing(-4),
      },
      categoryName: {
        position: "relative",
        top: 60,
        color: "white",
        [theme.breakpoints.up("md")]: {
          top: headerPosition === "top" ? 40 : 200,
        },
      },
    })
  );

interface CategoryHeaderProps extends CategoryProps {
  headerPosition?: "top" | "bottom";
}

const CategoryHeader: React.FunctionComponent<CategoryHeaderProps> = (props) => {
  const { category, headerPosition } = props;
  const classes = useStyles(headerPosition)();
  const [bannerContent, setBannerContent] = useState<SimpleContentModel>();
  const simpleBlockContent = useContext(SimpleContentBlocksContext);

  useEffect(() => {
    var banners = contentfulService.getSimpleContentFromIDs(category?.xp?.Content?.banner?.value, simpleBlockContent);
    if (banners && banners.length) {
      setBannerContent(banners[0]);
    }
  }, [bannerContent, category?.xp?.Content?.banner?.value, simpleBlockContent]);

  return (
    <Fragment>
      <div className={`${classes.slide} ${bannerContent === undefined && classes.slideMarginBottom}`}>
        <Container>
          <Typography className={classes.categoryName} variant="h1">
            {category?.Name}
          </Typography>
        </Container>
        <div
          className={classes.image}
          style={
            category?.xp?.Contentful?.Images?.Banner?.url
              ? { backgroundImage: "url(" + category?.xp?.Contentful?.Images?.Banner?.url + ")" }
              : { backgroundImage: "url(" + fallBackBannerImg + ")" }
          }
        ></div>
      </div>
      {bannerContent !== undefined && (
        <Container maxWidth="lg">
          <BannerPromotion variant="categories" category={category} />
        </Container>
      )}
    </Fragment>
  );
};

export default CategoryHeader;
