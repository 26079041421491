import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import React, { FunctionComponent, useMemo } from "react";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../redux/slices/breadcrumbs";
// import InlineContentBlock from "../components/Shared/Content/InlineContentBlock";
// import CareAdviceInformation from "../components/Articles/CareAdviceInformation";
// import articles from "../components/Articles/ArticleData";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paletteNeutral: {
      display: "flex",
      flexFlow: "row wrap",
      gap: theme.spacing(2),
      marginBottom: theme.spacing(3),
      "& > *": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 75,
        width: 100,
        wordBreak: "break-word",
        textAlign: "center",
        padding: theme.spacing(1),
        boxShadow: theme.shadows[1],
      },
      "& > *:nth-of-type(1)": {
        backgroundColor: "#323232",
        color: "white",
      },
      "& > *:nth-of-type(2)": {
        backgroundColor: "#999999",
        color: "white",
      },
      "& > *:nth-of-type(3)": {
        backgroundColor: "#666666",
        color: "white",
      },
      "& > *:nth-of-type(4)": {
        backgroundColor: "#EDEDED",
      },
      "& > *:nth-of-type(5)": {
        backgroundColor: "#A9A9A9",
      },
      "& > *:nth-of-type(6)": {
        backgroundColor: "#D4D4D4",
      },
      "& > *:nth-of-type(7)": {
        backgroundColor: "#F1F1F1",
      },
      "& > *:nth-of-type(8)": {
        backgroundColor: "#FBFBFB",
      },
      "& > *:nth-of-type(9)": {
        backgroundColor: "#F7F7F7",
      },
    },
    palettePrimary: {
      display: "flex",
      flexFlow: "row wrap",
      gap: theme.spacing(2),
      "& > *": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 75,
        width: 100,
        wordBreak: "break-word",
        textAlign: "center",
        padding: theme.spacing(1),
        boxShadow: theme.shadows[1],
        marginBottom: theme.spacing(3),
      },
      "& > *:nth-of-type(1)": {
        backgroundColor: "#824DAC",
        color: "white",
      },
      "& > *:nth-of-type(2)": {
        backgroundColor: "#9262B8",
        color: "white",
      },
      "& > *:nth-of-type(3)": {
        backgroundColor: "#9574C1",
        color: "white",
      },
      "& > *:nth-of-type(4)": {
        backgroundColor: "#AA82C8",
        color: "white",
      },
      "& > *:nth-of-type(5)": {
        backgroundColor: "#E1D5EC",
      },
      "& > *:nth-of-type(6)": {
        backgroundColor: "#e2d6ed",
      },
    },
    paletteGreen: {
      display: "flex",
      flexFlow: "row wrap",
      gap: theme.spacing(2),
      "& > *": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 75,
        width: 100,
        wordBreak: "break-word",
        textAlign: "center",
        padding: theme.spacing(1),
        boxShadow: theme.shadows[1],
      },
      "& > *:nth-of-type(1)": {
        backgroundColor: "#91A339",
        color: "white",
      },
      "& > *:nth-of-type(2)": {
        backgroundColor: "#7A8B29",
        color: "white",
      },
      "& > *:nth-of-type(3)": {
        backgroundColor: "#AFD833",
      },
      "& > *:nth-of-type(4)": {
        backgroundColor: "#F0F8DA",
      },
    },
  })
);

const StyleGuide: FunctionComponent<any> = (props) => {
  const classes = useStyle();
  const { setBreadcrumbs } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const flexDirection = useMemo(() => {
    return isDesktop ? "row" : "column";
  }, [isDesktop]);

  setBreadcrumbs({ links: [], current: "Style Guide" });

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="subtitle1">Headings</Typography>
        <Divider />
      </Box>
      <Box display="flex" flexDirection={flexDirection}>
        <Box flex="1">
          <Typography variant="h1" component="h2" gutterBottom>
            Header 01
          </Typography>
          <Typography variant="h2" gutterBottom>
            Header 02
          </Typography>
          <Typography variant="h3" gutterBottom>
            Header 03
          </Typography>
          <Typography variant="h4" gutterBottom>
            Header 04
          </Typography>
          <Typography variant="h5" gutterBottom>
            Header 05
          </Typography>
          <Typography variant="h6" gutterBottom>
            Header 06
          </Typography>
        </Box>
        <Box flex="1">
          <Typography variant="overline" display="block" gutterBottom>
            Titles & Topics
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Category Title
          </Typography>
          <Typography variant="body2" display="block" gutterBottom>
            Facet List
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            Directions
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Category Special Title
          </Typography>
        </Box>
        <Box flex="1">
          <Typography variant="overline" display="block">
            Body Content
          </Typography>
          <Typography variant="body1" paragraph>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae
            rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
            quasi quidem quibusdam.
          </Typography>
          <Typography variant="overline" display="block">
            SEO Copy
          </Typography>
          <Typography variant="caption" paragraph>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae
            rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
            quasi quidem quibusdam.
          </Typography>
        </Box>
      </Box>
      <Box my={8}>
        <Typography variant="subtitle1">Palette</Typography>
        <Divider />
      </Box>
      <Typography variant="body1" gutterBottom>
        Neutral
      </Typography>
      <Box className={classes.paletteNeutral}>
        <Typography variant="body2">text colored: #323232</Typography>
        <Typography variant="body2">text light: #999999</Typography>
        <Typography variant="body2">text white: #666666</Typography>
        <Typography variant="body2">grey: #EDEDED</Typography>
        <Typography variant="body2">strikethrough: #A9A9A9</Typography>
        <Typography variant="body2">disabled: #D4D4D4</Typography>
        <Typography variant="body2">text utility: #F1F1F1</Typography>
        <Typography variant="body2">offwhite: #FBFBFB</Typography>
        <Typography variant="body2">search: #f7f7f7</Typography>
      </Box>

      <Typography variant="body1" gutterBottom>
        Purple
      </Typography>
      <Box className={classes.palettePrimary}>
        <Typography variant="body2">hover: #824DAC</Typography>
        <Typography variant="body2">regular: #9262B8</Typography>
        <Typography variant="body2">price: #9574C1</Typography>
        <Typography variant="body2">utility: #AA82C8</Typography>
        <Typography variant="body2">inline: #E1D5EC</Typography>
        <Typography variant="body2">light: #e2d6ed</Typography>
      </Box>

      <Typography variant="body1" gutterBottom>
        Green
      </Typography>
      <Box className={classes.paletteGreen}>
        <Typography variant="body2">regular: #91A339</Typography>
        <Typography variant="body2">hover: #7A8B29</Typography>
        <Typography variant="body2">special: #AFD833</Typography>
        <Typography variant="body2">promo: #F0F8DA</Typography>
      </Box>
      <Box my={8}>
        <Typography variant="subtitle1">Buttons</Typography>
        <Divider />
      </Box>
      <Box display="flex" marginBottom={5} flexDirection={flexDirection}>
        <Box flex="1">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 40,
            }}
          >
            <Button variant="contained" color="primary">
              Learn More
            </Button>
            <Button variant="outlined" color="primary">
              Learn More
            </Button>
            <Button variant="contained" color="primary" disabled>
              Disabled
            </Button>
            <Button variant="outlined" color="primary" disabled>
              Disabled
            </Button>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 40,
            }}
          >
            <Button variant="contained" color="secondary">
              Add to Cart
            </Button>
            <Button variant="outlined" color="secondary">
              Add to Cart
            </Button>
            <Button variant="contained" color="secondary" disabled>
              Disabled
            </Button>
            <Button variant="outlined" color="secondary" disabled>
              Disabled
            </Button>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 40,
            }}
          >
            <Button variant="outlined">Learn More</Button>
            <Button variant="outlined" disabled>
              Disabled
            </Button>
          </div>
        </Box>
        <Box flex="1">
          <Typography variant="overline" display="block" gutterBottom>
            Links
          </Typography>
          <Link>Learn More</Link>
          <br />
          <Link>Learn More</Link>
        </Box>
      </Box>
      <Box my={8}>
        <Typography variant="subtitle1">Form Control</Typography>
        <Divider />
      </Box>
      <Box display="flex" flexDirection={flexDirection}>
        <Box flex="1">
          <TextField fullWidth margin="dense" variant="outlined" placeholder="Hint Text"></TextField>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            placeholder="Hint Text"
            label="First Name"
            required
          ></TextField>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            placeholder="Hint Text"
            label="First Name"
            required
            value="Input text"
          ></TextField>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Email Address"
            type="email"
            required
            value="Input text"
            error={true}
            InputProps={{
              endAdornment: <Warning color="error" />,
            }}
            helperText="Please enter a valid email address (Ex: johndoe@domain.com)."
          ></TextField>
          <TextField fullWidth margin="dense" variant="outlined" placeholder="Disabled Input" disabled></TextField>
          <form noValidate>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel htmlFor="select-example">Select State</InputLabel>
              <Select variant="outlined" id="select-example" fullWidth margin="dense" label="Select State">
                <MenuItem>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Minnesota</MenuItem>
                <MenuItem value={2}>South Dakota</MenuItem>
                <MenuItem value={3}>New York</MenuItem>
              </Select>
            </FormControl>
          </form>
        </Box>
        <Box flex="1"></Box>
      </Box>
      <Box display="flex">
        {/* <Box flex="0.5" style={{ margin: "5px" }}>
            <InlineContentBlock></InlineContentBlock>
          </Box> */}
        {/* <Box flex="1" style={{ margin: "5px" }}>
            <CareAdviceInformation articles={articles}></CareAdviceInformation>
          </Box> */}
        <Box flex="1" style={{ margin: "5px" }}>
          {/* <StackedContentBlock></StackedContentBlock> */}
        </Box>
      </Box>
      <Box style={{ margin: "5px" }}>{/* <FullWidthContentBlock></FullWidthContentBlock> */}</Box>
    </Container>
  );
};

export default connect(null, { setBreadcrumbs })(StyleGuide);
