import { LineItem } from "ordercloud-javascript-sdk";
import React, { Fragment } from "react";
import Currency from "react-currency-formatter";
import { Typography, makeStyles, createStyles, Theme, Box } from "@material-ui/core";
import { neutral } from "../../../themes/colors";
import eventService from "../../../services/event.service";
import productService from "../../../services/product.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specialRequest: {
      fontStyle: "italic",
      padding: 0,
      width: "100%",
      marginBlock: theme.spacing(2),
      "& .MuiOutlinedInput-multiline": {
        backgroundColor: neutral.search_bg,
        padding: theme.spacing(1),
      },
      "& .MuiButtonBase-root": {
        padding: theme.spacing(0.5),
        opacity: 0.5,
        "&:hover": {
          opacity: 1,
        },
        "& .MuiSvgIcon-root": {
          fontSize: 12,
        },
      },
    },
    productInfoTable: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      marginTop: theme.spacing(3),
      "& img": {
        width: 40,
        height: 40,
        aspectRatio: "1 / 1",
        objectFit: "cover",
        boxShadow: theme.shadows[1],
        alignSelf: "flex-start",
        [theme.breakpoints.up("sm")]: {
          alignSelf: "center",
        },
      },
      "& h6": {
        alignSelf: "flex-start",
        marginLeft: "auto",
      },
    },
    subtext: {
      color: theme.palette.grey[600],
      lineHeight: 1,
    },
    divider: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: neutral.grey_background,
    },
  })
);

interface CheckoutLineItemInfoProps {
  lineItem: LineItem;
}

const CheckoutLineItemInfoTable: React.FunctionComponent<CheckoutLineItemInfoProps> = (props) => {
  const classes = useStyles();
  const { lineItem } = props;

  return (
    <Fragment>
      <Box className={classes.productInfoTable}>
        <img src={lineItem?.xp?.ShownImage} alt={lineItem.Product?.Name} />
        <div>
          <Typography variant="h5">{productService.GetLineItemName(lineItem)}</Typography>
          {lineItem.Product?.xp?.IsWorkShopEvent ? (
            <Typography variant="body2" className={classes.subtext}>
              {"Item # " + lineItem.ProductID + eventService.GetLineItemEventTimeRange(lineItem)}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.subtext}>
              {"Item # " +
                lineItem.ProductID +
                productService.GetLineItemColorType(lineItem) +
                productService.GetLineItemSizeType(lineItem)}
            </Typography>
          )}
          <Typography variant="body2" className={classes.subtext}>
            <Currency quantity={lineItem.UnitPrice || 0} /> x {lineItem.Quantity}
          </Typography>
        </div>
        <Typography variant="subtitle2" color="primary">
          <Currency quantity={lineItem.LineTotal || 0} />
        </Typography>
      </Box>
      {lineItem.xp?.ProductNote && <Typography variant="body2">"{lineItem.xp?.ProductNote}"</Typography>}
    </Fragment>
  );
};

export default CheckoutLineItemInfoTable;
