import { Box, Container, createStyles, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { find, isEqual, isUndefined } from "lodash";
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Quantity, BachmansProduct } from "../../../models/product";
import productService from "../../../services/product.service";
import { neutral } from "../../../themes/colors";
import ProductSelector from "./ProductSelector";
import productUpsellService from "../../../services/productUpsell.service";
import { AseDrawer } from "../../../models/aseDrawer";
import UpsellDrawer from "./UpsellDrawer";
import UpsellDrawerActions, { DeliveryMethods } from "./UpsellDrawerActions";
import { useDispatch } from "react-redux";
import { calculateOrder, SetLineItemAddressPayload, setLineItemShippingAddress } from "../../../redux/slices/order";
import ProductImages from "../ProductImages";
import { setBreadcrumbs } from "../../../redux/slices/breadcrumbs";
import { AppDispatch } from "../../../redux/store";
import ProductInfo from "./ProductInfo";
import { CatalogContext } from "../../../providers/catalog";
import { Address, BuyerProduct, Category, LineItem, LineItems, LineItemSpec, Orders } from "ordercloud-javascript-sdk";
import ProductSpecTable from "./ProductSpecTable";
import ProductCrossSell from "./ProductCrossSell";
import stringService from "../../../services/string.service";
import ProductFeaturedContent from "./ProductFeaturedContent";
import CareAdviceInformation from "../../Articles/CareAdviceInformation";
import useQuery from "../../Shared/hooks/useQuery";
import DeliveryOptionSelect from "./DeliveryOptionSelect";
import { HomeContentContext } from "../../../providers/contentful";
import categoryService from "../../../services/category.service";
import StackedContentBlock from "../../Shared/Content/StackedContentBlock";
import deliveryResourceService from "../../../services/delivery-resource.service";
import { BuyerXp, DeliveryTypes } from "../../../models/buyerXp";
import { useAppSelector } from "../../../redux/store-hook";
import bachmansIntegrationsService from "../../../services/bachmansIntegrations.service";
import SocialLinks from "../../StaticPages/SocialLinks";
import GrownExclusive from "../ProductFlags/GrownExclusive";
import ProductOptions from "./ProductOptions";
import ProductPrice from "../ProductPrice";
import { DrawerProduct } from "../../../models/aseDrawer";
import { isOfType } from "../../../models/generics";
import AddToCartConfirmation from "./AddToCartConfirmation";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import WishListButton from "./WishListButton";
import QuantityInput from "../../Shared/QuantityInput";
import { Skeleton } from "@material-ui/lab";
import addToCartService from "../../../services/addToCart.service";
import deliveryService, { DeliveryDateOptions } from "../../../services/delivery.service";
import googleAnalyticsService from "../../../services/googleAnalytics.service";
import seoService from "../../../services/seo-service";
import { BachmansLineItem } from "../../../models/lineItem";
import AlgoliaAnalytics from "search-insights";
import algolia from "../../../services/algolia.service";
import $ from "jquery";

export interface ProductRouteMatch {
  productCode: string;
  sku?: string;
}

declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}

export type ProductEvent = "event" | "product";
interface ProductDetailProps {
  type?: ProductEvent;
  productGroupData?: BachmansProduct[];
  buttonText?: string;
  onSubmit?: () => void;
  disabled?: boolean;
  quantityChanged?: (quantity: number) => void;
  hideWishList?: boolean;
}

export interface SpecData {
  ASMMarkup?: number | null;
  PLCMarkup?: number | null;
  Specs: LineItemSpec[];
}

export interface LocationState {
  path?: string;
  search?: string;
}

export interface DeliveryOption {
  selectedOption?: "Delivery" | "PickUp";
  type?: DeliveryTypes;
  date?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(3),
    },
    upsellWrapper: {
      display: "grid",
      gap: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat( auto-fill, minmax(250px, 1fr))",
      },
    },
    buttonContainer: {
      flexBasis: "75%",
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      height: 40,
    },
    wishListContainer: {
      flexBasis: "25%",
      height: 40,
      "&>button": {
        width: "100%",
        height: "100%",
      },
    },
    cartButtonGroup: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    gridLeft: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(1),
      },
      "& .MuiTab-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      "& .Mui-selected": {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: "none",
      },
    },
    gridRight: {
      width: "100%",
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        paddingLeft: theme.spacing(2),
      },
    },
    checkLabel: {
      color: neutral.text_white_bg,
    },
    submit: {
      color: theme.palette.secondary.main,
    },
    input: {
      width: "75px",
    },
    marginTop: {
      marginTop: theme.spacing(3),
    },
    marginY: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    upsellSummary: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(1),
    },
    aseLabel: {
      color: neutral.text_white_bg,
    },
    productNoteWidth: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "75%",
      },
    },
    productDetailBody: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(3),
    },
    greyBackground: {
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      backgroundColor: neutral.grey_background,
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(4),
      },
    },
  })
);

export interface AseItem {
  product: DrawerProduct;
  quantity: Quantity;
}

const ProductDetail: React.FunctionComponent<ProductDetailProps> = (props) => {
  const { hideWishList, buttonText } = props;
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const match = useRouteMatch<ProductRouteMatch>();
  const query = useQuery();
  const [productGroup, setProductGroup] = useState<BachmansProduct[]>();
  const [aseDrawers, setAseDrawers] = useState<AseDrawer[]>();
  const currentOrder = useAppSelector((state) => state.order);
  const [quantity, setQuantity] = useState<Quantity>();
  const [defaultProduct, SetDefaultProduct] = useState<BachmansProduct>();
  const [relatedProducts, setRelatedProducts] = useState<string[]>();
  const [product, setProduct] = useState<BuyerProduct>();
  const [productCategory, setProductCategory] = useState<Category>();
  const [productNote, setProductNote] = useState<string>();
  const [selectedDelivery, setSelectedDelivery] = useState<DeliveryOption>();
  const [lyndaleStore, setLyndaleStore] = useState<Address>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isRequiredNote, setIsRequiredNote] = useState<boolean>(false);
  const [productSpecs, setProductSpecs] = useState<SpecData>();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const homeContent = useContext(HomeContentContext);
  const location = useLocation<LocationState>();
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethods>();
  const [deliveryDateOptions, setDeliveryDateOptions] = useState<DeliveryDateOptions>();
  const { categories, DCFcategories } = useContext(CatalogContext);
  const [aseState, setAseState] = useState<AseItem[]>();
  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [buyerXp, setBuyerXp] = useState<BuyerXp>();
  const history = useHistory();
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      const [lyndale, buyerXp] = await Promise.all([
        deliveryResourceService.GetLyndaleStore(),
        deliveryResourceService.GetBuyerXp(),
      ]);
      if (componentMounted.current) {
        setBuyerXp(buyerXp);
        setLyndaleStore(lyndale);
      }
    })();
    if (product) {
      seoService.ImportIntoSeoDataProps(product, "product");
      googleAnalyticsService.ViewProductPage(product);

      if (product && product?.PriceSchedule && product.PriceSchedule?.PriceBreaks) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            detail: {
              products: [
                {
                  name: product.Name, // Name or ID is required.
                  id: product.ID,
                  price: product.PriceSchedule?.PriceBreaks[0].Price,
                },
              ],
            },
          },
        });
      }
    }
  }, [product]);

  useEffect(() => {
    if (product && DCFcategories) {
      (async () => {
        //Get methods and available date options
        const methods = deliveryService.GetAvailableDeliveryMethods([product], DCFcategories);
        const options = await deliveryService.GetDateOptions({
          products: [product],
          deliveryMethods: methods,
          currentOrder: currentOrder,
        });
        if (componentMounted.current) {
          //Set default delivery type. ISP if all existing shipments are ISP else Delivery
          if (deliveryService.AllShipmentsArePickUp(currentOrder)) {
            const pickupMethod = currentOrder?.shipments?.some((s) => s.Shipper === "CurbsidePickUp")
              ? "CurbsidePickUp"
              : "InStorePickUp";
            setSelectedDelivery(deliveryService.BuildDeliveryDateOption(pickupMethod, options));
          } else if (deliveryService.CanDeliver(methods, product)) {
            const matchingDeliveryMethod = deliveryService.GetMatchingDeliveryMethod(methods, currentOrder);
            if (matchingDeliveryMethod) {
              setSelectedDelivery(deliveryService.BuildDeliveryDateOption(matchingDeliveryMethod, options));
            } else {
              setSelectedDelivery(deliveryService.BuildDeliveryDateOption("LocalDelivery", options));
            }
          }
          setDeliveryMethods(methods);
          setDeliveryDateOptions(options);
        }
      })();
    }
  }, [DCFcategories, currentOrder, product]);

  // validate product note
  useEffect(() => {
    if (product?.xp?.RequireNotes) {
      setIsRequiredNote(true);
      if (productNote) {
        setIsRequiredNote(false);
      } else {
        setIsRequiredNote(true);
      }
    }
  }, [product, productNote]);

  //set productGroup
  useEffect(() => {
    if (props.productGroupData) {
      setProductGroup(props.productGroupData);
      SetDefaultProduct(productService.GetDefaultProduct(props.productGroupData));
    } else {
      (async () => {
        const group = await productService.GetProductGroup(match.params.productCode);
        if (componentMounted.current) {
          if (!group || group.length === 0) {
            history.push(`/item-not-found/${match.params.productCode}`);
          } else {
            setProductGroup(group);
          }
        }
      })();
    }
  }, [props.productGroupData, match.params, history]);

  //grabbing ASE products
  useEffect(() => {
    const sku = query.get("sku");
    const currentProd = sku
      ? find(productGroup, function (p) {
          return p.ID === sku;
        })
      : productService.GetDefaultProduct(productGroup);
    if (currentProd && !isEqual(product, currentProd) && DCFcategories) {
      const minQuantity = productService.DetermineMinQuantity(currentProd);
      setQuantity({
        maxQuantity: productService.DetermineMaxQuantity(currentProd, currentOrder),
        minQuantity: minQuantity,
        quantity: minQuantity,
      });
      setProduct(currentProd);
      if (
        !product ||
        !isEqual(currentProd?.xp?.ASE, product?.xp?.ASE) ||
        !isEqual(currentProd.xp?.ProductCode, product?.xp?.ProductCode)
      ) {
        if (props.type !== "event") {
          productUpsellService.GetUpsellCategories(currentProd, DCFcategories).then((upsellCategories: Category[]) => {
            if (componentMounted.current) {
              if (upsellCategories && upsellCategories.length) {
                setAseDrawers(undefined); // to triggger skeleton to show again between product detail views
                var reqs: Promise<any>[] = [];
                upsellCategories.forEach((c: any) => {
                  reqs.push(
                    productUpsellService.GetUpsellProducts(c, currentOrder).then((upsellProducts) => {
                      const drawer: AseDrawer = {
                        ...c,
                        products: upsellProducts,
                      };
                      return drawer;
                    })
                  );
                });
                return Promise.all(reqs).then((result) => {
                  if (componentMounted.current) {
                    setAseDrawers(result.filter((drawer) => drawer.products?.length > 0));
                  }
                });
              } else {
                setAseDrawers([]);
              }
            }
          });
        }
      }
    }
  }, [productGroup, query, product, DCFcategories, props.type, currentOrder]);

  // setting related products
  useEffect(() => {
    if (!stringService.ArraysEqual(product?.xp?.Related, relatedProducts)) {
      setRelatedProducts(product?.xp?.Related);
    }
  }, [product, relatedProducts]);

  //setting breadcrumb
  useEffect(() => {
    if (location?.state?.path) {
      dispatch(
        setBreadcrumbs({
          visible: true,
          hideHome: true,
          links: [
            {
              path: location?.state?.path + location?.state?.search,
              label: location?.state?.path === "/" ? "Home" : "Back to Results",
            },
          ],
          current: "",
        })
      );
    } else if (product && product !== null && categories) {
      productService.GetBreadCrumbsFromProduct(product.ID!, categories).then((cats) => {
        if (componentMounted.current) {
          setProductCategory(cats[cats.length - 1]);
          const breadCrumbLinks = cats.map((c) => ({
            path: categoryService.BuildCategoryUrlFromId(c.ID, "c"),
            label: c.Name!,
          }));
          dispatch(
            setBreadcrumbs({
              visible: true,
              links: breadCrumbLinks,
              current: productService.ProductNameFilter(product),
            })
          );
        }
      });
    }
  }, [product, categories, dispatch, location]);

  // qty change refers to the main selected product
  const handleQuantityChange = (selectedQuantity: number) => {
    setQuantity({
      ...quantity,
      quantity: selectedQuantity,
    });
  };

  // handle quantity change for ASE products
  const handleASEQuanityChange = (productID: string, newQuantity: number) => {
    const newState = aseState?.map((item) =>
      item.product.ID === productID
        ? { product: item.product, quantity: { ...item.quantity, quantity: newQuantity } }
        : item
    );
    setAseState(newState);
  };

  const handleDrawerProductSelect = (selectedProduct: DrawerProduct) => {
    const quantity = {
      maxQuantity: productService.DetermineMaxQuantity(selectedProduct, currentOrder),
      minQuantity: productService.DetermineMinQuantity(selectedProduct),
      quantity: 1,
    };
    const newState = [...(aseState || []), { product: selectedProduct, quantity: quantity }];
    setAseState(newState);
  };

  const handleRemoveDrawerSelect = (product: DrawerProduct) => {
    if (product.ID === "GIFTCARD") {
      setAseState(aseState?.filter((i) => `${i.product.ID}${i.product.Amount}` !== `${product.ID}${product.Amount}`));
    } else {
      setAseState(aseState?.filter((item) => item.product.ID !== product.ID));
    }
  };

  const getStackedContent = () => homeContent.data?.fields?.stackedContent?.map((home) => home.fields);

  interface MatchingShippingData {
    ToAddress: Address | undefined;
    LineItem: BachmansLineItem;
  }

  const getMatchingShippingData = (): MatchingShippingData | undefined => {
    const ISPShipments = currentOrder.shipments?.filter((s) => deliveryService.IsPickUp(s.Shipper));
    const localShipments = currentOrder.shipments?.filter((s) => s.Shipper === "LocalDelivery");
    if (deliveryService.IsPickUp(selectedDelivery?.type) && ISPShipments && ISPShipments.length === 1) {
      return {
        ToAddress: ISPShipments[0].ToAddress,
        LineItem: ISPShipments[0].LineItems[0],
      };
    } else if (selectedDelivery?.type === "LocalDelivery" && localShipments && localShipments.length === 1) {
      return {
        ToAddress: localShipments[0].ToAddress,
        LineItem: localShipments[0].LineItems[0],
      };
    }
  };

  const onSubmit = async () => {
    if (product) {
      let urlSearchParams = new URLSearchParams(location.search);
      let currentQuery = urlSearchParams.get("queryid");
      let categorypageflag = urlSearchParams.get("categorypage");
      let eventMessage: any;
      if (categorypageflag == "true") {
        eventMessage = "Product added into cart through category page";
      } else {
        eventMessage = "Product added into cart through search";
      }
      setIsSubmitting(true);
      let createAddOnLi: Promise<any>[] = [];
      const lineItem = constructLineItem(product, currentOrder);
      const xtraLineitems = constructLIFromExtraProducts();

      const order = isUndefined(currentOrder.order?.DateCreated)
        ? await Orders.Create("Outgoing", currentOrder?.order || {})
        : currentOrder.order;

      if (order?.ID) {
        googleAnalyticsService.AddItemToCart(lineItem);
        let lis: LineItem[] = [];
        lis.push(await bachmansIntegrationsService.upsertLineItem(order.ID, lineItem));

        if (xtraLineitems.length) {
          xtraLineitems.forEach((li: LineItem) => {
            googleAnalyticsService.AddItemToCart(li);
            createAddOnLi.push(bachmansIntegrationsService.upsertLineItem(order.ID!, li));
          });
          let results = await Promise.all(createAddOnLi);
          lis.push(...results);
        }
        const payloads: SetLineItemAddressPayload[] = [];
        lis.forEach((li: LineItem) => {
          const shippingData = getMatchingShippingData();
          if (shippingData && shippingData.ToAddress) {
            const p: SetLineItemAddressPayload = {
              lineItemID: li.ID!,
              address: shippingData.ToAddress,
              routeCode: shippingData.LineItem.xp?.RouteCode,
              deliveryMethod: shippingData.LineItem.xp?.DeliveryMethod,
            };
            payloads.push(p);
          }
        });
        if (payloads && payloads.length > 0) {
          await dispatch(setLineItemShippingAddress(payloads));
        } else {
          await dispatch(calculateOrder(order.ID));
        }
        setLineItems(lis);
        setConfirmDialogOpen(true);

        if (product.PriceSchedule && product.PriceSchedule.PriceBreaks) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
              add: {
                // 'add' actionFieldObject measures.
                products: [
                  {
                    //  adding a product to a shopping cart.
                    name: product.Name,
                    id: product.ID,
                    price: product.PriceSchedule?.PriceBreaks[0].Price,
                    brand: "Bachman's",
                    quantity: lineItem.Quantity,
                  },
                ],
              },
            },
          });
        }
      }
      setIsSubmitting(false);

      if (currentQuery && currentQuery !== "" && currentQuery !== undefined) {
        // Algolia Init method for sending Events
        let userToken;
        AlgoliaAnalytics("init", {
          appId: process.env.REACT_APP_ALGOLIA_APP as string,
          apiKey: process.env.REACT_APP_ALGOLIA_KEY as string,
          useCookie: true,
        });

        // Get AlgoliaUser Token from here
        AlgoliaAnalytics("getUserToken", null, (err, newUserToken) => {
          if (err) {
            console.error(err);
            return;
          }
          userToken = newUserToken;
        });

        // Fetch Product Index and store it in variable
        const sortIndex = algolia.getQuickSearchProducts();
        let index = algolia.getProductIndex(sortIndex);

        AlgoliaAnalytics("convertedObjectIDsAfterSearch", {
          userToken: userToken, // required for Node.js
          eventName: eventMessage,
          index: index.indexName,
          queryID: currentQuery ? currentQuery : currentQuery,
          objectIDs: [product.ID as string],
        });
      }
    }
  };

  // for each ASE product, set the respective ASEQuantiy
  const constructLIFromExtraProducts = (): LineItem[] => {
    const lineItems = aseState?.map((item) => {
      item.product.Quantity = item.quantity.quantity;
      return constructLineItem(item.product);
    });
    return lineItems || [];
  };

  // main product quantity set on component state &  assumes ASE product quanities get set on product during li construction
  const getQuantity = useCallback(
    (isBaseItem: boolean, product: BachmansProduct | DrawerProduct): number => {
      if (isBaseItem) {
        return quantity?.quantity || 1;
      }
      if (isOfType<DrawerProduct>(product, "Quantity")) {
        return product.Quantity || 1;
      }
      return 1;
    },
    [quantity]
  );

  const constructLineItem = (prdct: BuyerProduct, currentOrder?: any): LineItem => {
    let isBaseItem = product?.ID === prdct.ID;
    return addToCartService.ConstructLineItem(
      {
        product: prdct,
        isBaseItem: isBaseItem,
        quantity: getQuantity(isBaseItem, prdct),
        storeID: lyndaleStore?.ID || "",
        productSpecs: productSpecs,
        delivery: selectedDelivery,
        productNote:
          productNote
            ?.replace(/[\n\r]/g, " ")
            .replace(/[\n]/g, " ")
            .replace(/[\t]/g, " ") || "",
        productCategory: productCategory,
        currentOrder: currentOrder,
      },
      buyerXp
    );
  };

  const isOutOfStock = (): boolean => !isUndefined(quantity?.maxQuantity) && (quantity?.maxQuantity || 0) < 1;

  const isGreaterThanMaxQuantityAllowed: boolean = useMemo(() => {
    let selectedQuantity = parseInt(String($("#standard-number").val()), 10);
    return !!(quantity?.maxQuantity && selectedQuantity > quantity!.maxQuantity!);
  }, [quantity]);

  return (
    <Fragment>
      <Container className={classes.container}>
        <Grid container>
          <Grid item md={6} xs={12} className={classes.gridLeft}>
            {!product && <Skeleton variant="rect" height="100%" />}
            {product && (
              <ProductImages
                product={product}
                multiSku={props.productGroupData && props.productGroupData.length > 1 ? true : false}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12} className={classes.gridRight}>
            {/*BAC-1335, use product web facing name in place of product.Description */}
            {!product && <Skeleton variant="text" />}
            {/* {product && (
              <SocialLinks
                seoInfo={{
                  title: product?.xp?.WebFacingProductTitle || product?.Name,
                  description: product?.xp?.WebFacingProductTitle || product?.Name,
                  image: productService.GetProductImage(product)?.url,
                }}
              />
            )} */}
            <Typography variant="h2" style={{ color: neutral.text_white_bg, marginTop: "10px" }}>
              {product?.xp?.WebFacingProductTitle || product?.Name}
            </Typography>
            <Typography variant="overline" style={{ color: neutral.text_light_bg }}>
              {product ? `Item # ${product?.ID}` : <Skeleton variant="text" />}
            </Typography>
            {productGroup && (
              <Box display="flex">
                <GrownExclusive productGroup={productGroup} />
              </Box>
            )}
            <Box className={classes.productDetailBody}>
              {product && productGroup && productGroup.length > 1 ? (
                <ProductSelector product={product} group={productGroup}></ProductSelector>
              ) : (
                <ProductPrice product={product} alignment="left" />
              )}
              {quantity && (
                <div>
                  <QuantityInput
                    product={product}
                    quantity={quantity}
                    onChange={handleQuantityChange}
                    disabled={isSubmitting}
                  />
                </div>
              )}
              <ProductOptions
                deliveryMethod={selectedDelivery?.selectedOption}
                product={product}
                onSpecChange={useCallback((data) => setProductSpecs(data), [])}
                specData={productSpecs}
              />
              {product?.xp?.RequireNotes && (
                <TextField
                  autoFocus
                  id="productRequestNote"
                  required
                  value={productNote}
                  onChange={(e) => setProductNote(e.target.value)}
                  multiline
                  rows={5}
                  variant="outlined"
                  label="Enter Product Request"
                  helperText={
                    <Typography variant="body2" color={"primary"}>
                      This product requires product notes for our designers
                    </Typography>
                  }
                  inputProps={{ maxLength: 100 }}
                  placeholder="Enter style/color notes and our designers will do their best to accommodate your request."
                  className={classes.productNoteWidth}
                />
              )}
              <DeliveryOptionSelect
                deliveryMethods={deliveryMethods}
                deliveryDateOptions={deliveryDateOptions}
                product={product}
                selectedDeliveryOption={selectedDelivery}
                onDeliveryChange={(option: DeliveryOption) => setSelectedDelivery(option)}
              />

              <Fragment>
                {!aseDrawers && <Skeleton variant="rect" height={150} />}
                {aseDrawers && aseDrawers.length > 0 && (
                  <Box>
                    <Typography display="block" className={classes.aseLabel} variant="overline">
                      Add Something Extra
                    </Typography>
                    <Box className={classes.upsellWrapper}>
                      {aseDrawers.map((drawer, i) => (
                        <Fragment key={i}>
                          <UpsellDrawer
                            aseDrawer={drawer}
                            selectedProducts={aseState}
                            onDrawerChange={handleDrawerProductSelect}
                          />
                        </Fragment>
                      ))}
                    </Box>
                  </Box>
                )}
              </Fragment>

              {aseDrawers && aseDrawers.length > 0 && (
                <Box className={classes.upsellSummary}>
                  {aseState &&
                    aseState?.length > 0 &&
                    aseState.map((item: AseItem, i: number) => {
                      return (
                        <Fragment key={i}>
                          <UpsellDrawerActions
                            selectedItem={item}
                            onDrawerUpdate={handleASEQuanityChange}
                            onRemoveDrawerSelect={handleRemoveDrawerSelect}
                          />
                        </Fragment>
                      );
                    })}
                </Box>
              )}

              {/* ADD TO CART BUTTON */}
              <Box className={classes.cartButtonGroup}>
                {product && (
                  <div className={classes.buttonContainer}>
                    <DoubleOutlinedBtn
                      loading={isSubmitting}
                      loadingText={"Adding to Cart"}
                      buttonText={isOutOfStock() ? "OUT OF STOCK" : buttonText || "Add to Cart"}
                      buttonProps={{
                        color: "secondary",
                        onClick: onSubmit,
                        disabled:
                          !selectedDelivery ||
                          isSubmitting ||
                          isRequiredNote ||
                          isOutOfStock() ||
                          isGreaterThanMaxQuantityAllowed,
                      }}
                      styleProps={{
                        height: "calc(100% - 6px)",
                        width: "100%",
                        outlineColor: neutral.disabled,
                      }}
                    />
                  </div>
                )}
                {!hideWishList && (
                  <div className={classes.wishListContainer}>
                    <WishListButton product={product} />
                  </div>
                )}
              </Box>
              {/* ADD TO CART BUTTON */}

              <AddToCartConfirmation
                open={confirmDialogOpen}
                aseDrawers={aseDrawers}
                lineItems={lineItems}
                quantity={quantity}
                onClose={() => {
                  setConfirmDialogOpen(false);
                  setLineItems([]);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.greyBackground}>
        <Container>
          <Grid container>
            <Grid item md={8} className={classes.gridLeft}>
              <ProductInfo product={product} group={props.productGroupData} />
            </Grid>
            <Grid item md={4} className={classes.gridRight}>
              <ProductSpecTable product={product} defaultProduct={defaultProduct} />
              {product?.xp?.Articles && product?.xp?.Articles.length > 0 ? (
                <CareAdviceInformation articles={product?.xp?.Articles || []} />
              ) : (
                <StackedContentBlock content={getStackedContent()} />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        <Box my={4}>
          <ProductCrossSell related={relatedProducts}></ProductCrossSell>
          <ProductFeaturedContent product={product} />
        </Box>
      </Container>
    </Fragment>
  );
};

export default ProductDetail;
