import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  GridSize,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { BachmansShipmentWithItems, ValidatedBachmansShipmentWithLineItems } from "../../models/shipment";
import { neutral } from "../../themes/colors";
import { Address, BuyerAddress, BuyerProduct, LineItem, LineItems } from "ordercloud-javascript-sdk";
import moment from "moment";
import { max } from "lodash";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/store-hook";
import {
  patchLineItems,
  SetLineItemAddressPayload,
  setLineItemShippingAddress,
  setShipmentShippingAddress,
} from "../../redux/slices/order";
import { AppDispatch } from "../../redux/store";
import { DeliveryTypes } from "../../models/buyerXp";
import DeliveryDateModal from "./DeliveryDateModal";
import LineItemRow from "./LineItemRow";
import { CartState, ShipMethodState } from "./index";
import LocationDropDown from "./LocationDropDown";
import deliveryService from "../../services/delivery.service";
import StoreDropDown from "./StoreDropDown";
import { CatalogContext } from "../../providers/catalog";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import stringService from "../../services/string.service";

interface ShipmentListProps {
  shipState?: ShipMethodState;
  productsInOrder: BuyerProduct[];
  cartState: CartState; // need access to this to grab other shipment info like InstorePickup address
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shipmentHeader: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      flexFlow: "column nowrap",
      padding: theme.spacing(1),
      backgroundColor: neutral.grey_background,
      gap: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        flexFlow: "row nowrap",
        minHeight: 56,
      },
    },
    selectWhereIsThisGoing: {
      margin: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 300,
      },
    },
    buttonWhereIsItGoing: {
      backgroundColor: "white",
      borderColor: theme.palette.secondary.main,
      minHeight: 40,
      "&:hover": {
        "& .MuiTypography-root": {
          color: "white",
        },
      },
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        "& .MuiTypography-root": {
          lineHeight: 1.2,
        },
        "& .MuiTypography-body2:last-child": {
          fontSize: 10,
        },
      },
    },
    buttonError: {
      backgroundColor: "white",
      borderColor: theme.palette.error.main,
      minHeight: 40,
      "&:hover": {
        "& .MuiTypography-root": {
          color: "white",
        },
      },
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        "& .MuiTypography-root": {
          lineHeight: 1.2,
        },
        "& .MuiTypography-root.not(.MuiTypography-h5):last-child": {
          fontSize: 10,
        },
      },
    },
    helpertWhereIsThisGoing: {
      paddingLeft: 2,
      fontSize: 10,
    },
    shipmentTableHeader: {
      backgroundColor: neutral.offwhite_bg,
      padding: theme.spacing(0, 1),
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    mobileLineItemActionBar: {
      "& li": {
        fontSize: "12px",
        "& .MuiSvgIcon-root": {
          marginRight: 8,
        },
      },
    },
    recipientChild: {
      display: "inline-block",
      margin: "0px 5px",
      "& > first-of-type": { marginLeft: 0 },
    },
    boldChild: {
      display: "inline-block",
      margin: "0px 5px",
      "& > first-of-type": { marginLeft: 0 },
      fontWeight: theme.typography.fontWeightBold,
    },
    text: {
      color: neutral.text_white_bg,
    },
    gap2: {
      gap: theme.spacing(2),
    },
    pullGridRight: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
        marginLeft: "auto",
      },
    },
    productsLabel: {
      color: neutral.text_white_bg,
    },
    addressNickName: {
      fontWeight: "bold",
      color: neutral.text_colored_bg,
      marginRight: theme.spacing(1),
      lineHeight: 1,
    },
    addressName: {
      maxWidth: "55ch", // causes the address to wrap if there are too many characters
    },
    addressHeading: {
      flexFlow: "column nowrap",
      display: "flex",
      gap: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        flexFlow: "row nowrap",
        alignItems: "center",
        alignSelf: "center", // Q: "Do you need both of these?" A: yes, align-self affects .addressHeading as a sibling to .shipmentHeader and "align-items" affects .addressHeading as a new parent.
      },
    },
    addressHeaderMobileActionBar: {
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        alignSelf: "flex-end",
        justifyContent: "flex-end",
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: theme.spacing(0.5),
      },
    },
    buttonEditAddress: {
      fontSize: 10,
      color: neutral.text_light_bg,
      padding: theme.spacing(0.5, 1),
      marginRight: "auto",
      backgroundColor: neutral.offwhite_bg,
      alignSelf: "flex-end",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "unset",
        alignSelf: "flex-start",
        marginTop: 5,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 12,
      },
    },
    buttonChangePickUpLocation: {
      marginTop: 10,
    },
    buttonEGiftcardEdit: {
      marginTop: 5,
    },
    invisible: {
      opacity: 1,
      visibility: "visible",
    },
  })
);

interface IGridWidths {
  [key: string]: GridSize;
}
export const GridWidths: IGridWidths = {
  product: 6,
  delivery: 2,
  price: 1,
  quantity: 1,
};

export type DeliveryType = "InStorePickUp" | "CurbsidePickUp" | "Delivery";
const ShipmentList: React.FunctionComponent<ShipmentListProps> = (props) => {
  const classes = useStyles();
  const currentOrder = useAppSelector((state) => state.order);
  const dispatch = useDispatch<AppDispatch>();
  const { shipState, productsInOrder, cartState } = props;
  const [dateDialogOpen, setDateDialogOpen] = useState<string>();
  const { DCFcategories } = useContext(CatalogContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [editShipmentAddress, setEditShipmentAddress] = useState<string>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = stringService.GetQueryParamsFromLocation(location.search);

  useEffect(() => {
    if (location.search.length === 0) {
      history.push({ search: "?isInLocationEditMode=false" });
    }
    //console.log(location.search);
  }, [history, location.search]);

  const updateDeliveryDate = async (partial: Partial<LineItem>, items: LineItem[]) => {
    const dateAdded = max(items.map((item) => moment(item.DateAdded)))?.toString();
    const updatedPartial = deliveryService.EnsureDateNeededAfterDateAdded(partial, dateAdded);
    await dispatch(
      patchLineItems({
        lineItemIDs: items.map((li) => li.ID || ""),
        partial: updatedPartial,
      })
    );
  };

  const updateShipmentAddress = async (newAddress: BuyerAddress, shipmentID: string, routeCode?: string) => {
    const shipmentToUpdate = shipState?.shipments?.find((s) => s.ID === shipmentID);
    const productsInShipment = getProductsInShipment(shipmentID);
    if (DCFcategories && shipmentToUpdate?.ID) {
      const methods = deliveryService.GetAvailableDeliveryMethods(productsInShipment, DCFcategories);
      const validMethod = deliveryService.GetDeliveryTypeFromAddress(
        productsInShipment,
        methods,
        newAddress,
        shipmentToUpdate?.Shipper as DeliveryTypes
      );
      if (validMethod) {
        // there is a valid method for the shipment. Update the shipment with addres
        await dispatch(
          setShipmentShippingAddress({
            shipmentID: shipmentToUpdate.ID,
            address: newAddress as Address,
            routeCode: routeCode,
            deliveryMethod: validMethod,
          })
        );
        await updateLineItemAddresses(newAddress, shipmentID, routeCode);
      } else {
        // now no valid method for shipment. Update for individual Line Items
        await updateLineItemAddresses(newAddress, shipmentID, routeCode);
      }
      setEditShipmentAddress(undefined);
    }
  };

  const updateLineItemAddresses = async (address: BuyerAddress, shipmentID: string, routeCode?: string) => {
    const shipmentToUpdate = shipState?.shipments?.find((s) => s.ID === shipmentID);
    if (DCFcategories && shipmentToUpdate) {
      const payload: SetLineItemAddressPayload[] = [];
      shipmentToUpdate.LineItems.forEach((li) => {
        let validMethod;
        const product = productsInOrder.find((p) => p.ID === li.ProductID);
        if (product) {
          const methods = deliveryService.GetAvailableDeliveryMethods([product], DCFcategories);
          validMethod = deliveryService.GetDeliveryTypeFromAddress(
            [product],
            methods,
            address,
            li.xp?.DeliveryMethod as DeliveryTypes
          );
          if (validMethod) {
            payload.push({
              lineItemID: li.ID!,
              address: address as Address,
              routeCode: routeCode,
              deliveryMethod: validMethod,
            });
          }
        }
      });

      console.log("payload", payload);
      await dispatch(setLineItemShippingAddress(payload));
    }
  };

  const getItemCount = (shipment: BachmansShipmentWithItems) => {
    const onlyEvents = shipment?.LineItems?.every((li) => li?.Product?.xp?.IsWorkShopEvent);
    return onlyEvents ? `Tickets` : `Products`;
  };

  const formatPhoneNumber = (shipment: BachmansShipmentWithItems) => {
    const maskedTelephone = shipment.ToAddress?.Phone!.replace(/\D+/g, "").replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return maskedTelephone;
  };

  const getProductsInShipment = (shipmentID: string): BuyerProduct[] => {
    const shipmentProductIDs = shipState?.shipments
      ?.find((s) => s.ID === shipmentID)
      ?.LineItems?.map((li) => li.ProductID);
    const productsInShipment = productsInOrder.filter((p) => shipmentProductIDs?.includes(p.ID!));
    return productsInShipment;
  };

  const getLineItemProduct = (productID: string): BuyerProduct => {
    var prod = productsInOrder.filter((p) => p.ID === productID);
    return prod[0];
  };

  const getSelectedPickUpAddresss = () => {
    // go through selected adddress, find instore pick shipment - return that address
    // else return lyndale store.
    //Issue: is that the shipments list is not top level and doesn't actually give the shipment with InStorePicku
    let PickUpAddress;
    if (cartState.InStorePickUp) {
      PickUpAddress = cartState.InStorePickUp?.shipments[0]?.ToAddress;
      if (PickUpAddress?.FirstName && PickUpAddress?.LastName && PickUpAddress?.Phone) return PickUpAddress;
    }
  };

  const updateDeliveryType = async (type: DeliveryType, lineItemID: string, date: string) => {
    let partialUpdate: Partial<LineItem> = {};
    if (deliveryService.IsPickUp(type)) {
      const currentPickupAddress = getSelectedPickUpAddresss();
      partialUpdate = {
        DateNeeded: date,
        ShippingAddressID: "",
        xp: {
          DeliveryMethod: type,
          AddressType: type,
        },
      };
      if (currentPickupAddress) {
        await LineItems.SetShippingAddress("Outgoing", currentOrder.order?.ID!, lineItemID, currentPickupAddress);
      }
    } else if (type === "Delivery") {
      partialUpdate = {
        DateNeeded: date,
        ShippingAddressID: "",
        xp: {
          DeliveryMethod: "LocalDelivery",
          AddressType: "Residence",
        },
      };
      if (cartState?.LocalDelivery?.shipments[0]?.ToAddressID) {
        partialUpdate.ShippingAddressID = cartState?.LocalDelivery?.shipments[0]?.ToAddressID;
      } else if (cartState?.LocalDelivery?.shipments[0]?.ToAddress) {
        await LineItems.SetShippingAddress(
          "Outgoing",
          currentOrder.order?.ID!,
          lineItemID,
          cartState.LocalDelivery.shipments[0]?.ToAddress
        );
      }
    }
    await dispatch(patchLineItems({ lineItemIDs: [lineItemID], partial: partialUpdate }));
  };

  const shipmentValidationError = (
    shipment: ValidatedBachmansShipmentWithLineItems,
    reason: "Date" | "Address" | "Product"
  ): boolean => {
    const itemsWithDateError = shipment.LineItems.filter(
      (l) => l.Validation?.valid === false && l.Validation?.reason === reason
    );
    return itemsWithDateError && itemsWithDateError?.length >= 1;
  };

  const AddressDropDown = (shipment: ValidatedBachmansShipmentWithLineItems, addressError: boolean) => {
    if (!deliveryService.IsPickUp(shipment.Shipper)) {
      return (
        <LocationDropDown
          formControlProps={{
            id: "groupedShipmentLocation",
            size: "small",
            margin: "dense",
            variant: "outlined",
            required: true,
            className: shipment.Shipper,
            error: addressError,
          }}
          lineItems={shipment.LineItems}
          products={getProductsInShipment(shipment.ID!)}
          shipper={shipment.Shipper as DeliveryTypes}
          formHelperText="Please select an address before continuing."
          onAddressSubmit={(address: Address, routeCode?: string) =>
            updateShipmentAddress(address, shipment.ID!, routeCode)
          }
          isInLocationEditMode={queryParams.isInLocationEditMode}
        />
      );
    } else
      return (
        <StoreDropDown
          formControlProps={{
            size: "small",
            margin: "dense",
            variant: "outlined",
            required: true,
            className: classes.selectWhereIsThisGoing,
            error: addressError,
          }}
          shipment={shipment}
          onAddressSubmit={(address: Address, routeCode?: string) =>
            updateShipmentAddress(address, shipment.ID!, routeCode)
          }
          onDialogClose={() => setEditShipmentAddress(undefined)}
        />
      );
  };

  const onlyEvents = (shipment: ValidatedBachmansShipmentWithLineItems): boolean => {
    return shipment.LineItems.every((li) => li?.Product?.xp?.IsWorkShopEvent);
  };

  return (
    <Fragment>
      {shipState?.shipments?.map((shipment, index) => {
        const dateError = shipmentValidationError(shipment, "Date");
        const addressError = shipmentValidationError(shipment, "Address");
        return (
          <Fragment key={index}>
            {!(shipState.shipToMultiple && (!shipment.ToAddress || shipment.ToAddress === null)) &&
              !onlyEvents(shipment) &&
              shipState?.shipments[0]?.LineItems[0]?.xp.DeliveryMethod !== "Email" && (
                <div className={classes.shipmentHeader}>
                  {!shipment.ToAddress || editShipmentAddress === shipment.ID ? (
                    AddressDropDown(shipment, addressError)
                  ) : (
                    <Fragment>
                      {shipment.ToAddress && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          alignSelf={isMobile ? "flex-start" : "center"}
                        >
                          <Typography className={`${classes.addressNickName}`} variant="body1">
                            {deliveryService.GetAddressName(
                              shipment.ToAddress,
                              deliveryService.IsPickUp(shipment.Shipper)
                            )}
                          </Typography>
                          <Typography variant="body2" className={`${classes.addressName}`}>
                            {`${shipment.ToAddress.Street1}, ${shipment.ToAddress.City}, ${shipment.ToAddress.State}, ${shipment.ToAddress.Zip}`}
                          </Typography>
                          {deliveryService.IsPickUp(shipment.Shipper) && (
                            <Typography variant="body2" color="textSecondary">
                              {shipment.ToAddress?.FirstName} {shipment.ToAddress?.LastName}{" "}
                              {formatPhoneNumber(shipment)}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Fragment>
                  )}
                  {!isMobile && shipment.ToAddress && !deliveryService.IsPickUp(shipment.Shipper) && (
                    <Button
                      id="desktopEditAddressItemForOrder"
                      size="small"
                      className={classes.buttonEditAddress}
                      startIcon={editShipmentAddress ? null : <Edit fontSize="small" />}
                      onClick={() => {
                        editShipmentAddress ? setEditShipmentAddress(undefined) : setEditShipmentAddress(shipment.ID);
                        history.push({
                          search: `?isInLocationEditMode=${queryParams.isInLocationEditMode}`,
                        });
                      }}
                    >
                      {editShipmentAddress ? "Cancel" : "Edit"}
                    </Button>
                  )}
                  {!isMobile && shipment.ToAddress && deliveryService.IsPickUp(shipment.Shipper) && (
                    <Fragment>
                      {editShipmentAddress === shipment.ID ? (
                        <Button
                          onClick={() => setEditShipmentAddress(undefined)}
                          className={classes.buttonEditAddress}
                          variant="text"
                          size="small"
                        >
                          Cancel
                        </Button>
                      ) : (
                        <Button
                          onClick={() => setEditShipmentAddress(shipment.ID)}
                          className={`${classes.buttonEditAddress} ${classes.buttonChangePickUpLocation}`}
                          variant="text"
                          size="small"
                        >
                          Change Pickup Location
                        </Button>
                      )}
                    </Fragment>
                  )}
                  <Box id="deliveryDateGroup" className={classes.addressHeaderMobileActionBar}>
                    {isMobile && (
                      <Fragment>
                        {shipment.ToAddress && deliveryService.IsPickUp(shipment.Shipper) && (
                          <Fragment>
                            {editShipmentAddress === shipment.ID ? (
                              <Button
                                onClick={() => setEditShipmentAddress(undefined)}
                                className={classes.buttonEditAddress}
                                variant="text"
                                size="small"
                              >
                                Cancel
                              </Button>
                            ) : (
                              <Button
                                onClick={() => setEditShipmentAddress(shipment.ID)}
                                className={classes.buttonEditAddress}
                                variant="text"
                                size="small"
                              >
                                Change Pickup Location
                              </Button>
                            )}
                          </Fragment>
                        )}
                        {shipment.ToAddress && !deliveryService.IsPickUp(shipment.Shipper) && (
                          <Button
                            id="mobileEditAddressItemForOrder"
                            size="small"
                            className={classes.buttonEditAddress}
                            startIcon={editShipmentAddress ? null : <Edit fontSize="small" />}
                            onClick={() => {
                              editShipmentAddress
                                ? setEditShipmentAddress(undefined)
                                : setEditShipmentAddress(shipment.ID);
                              history.push({
                                search: `?isInLocationEditMode=${queryParams.isInLocationEditMode}`,
                              });
                            }}
                          >
                            {editShipmentAddress ? "Cancel" : "Edit"}
                          </Button>
                        )}
                      </Fragment>
                    )}
                    <Box
                      className={`${!isMobile && classes.gap2}`}
                      display="flex"
                      flexDirection={isMobile ? "column" : "row"}
                      alignItems={isMobile ? "flex-start" : "center"}
                    >
                      <Typography component="p" variant="body2" color="textSecondary">
                        {shipment?.LineItems?.every((li) => li?.Product?.xp?.IsWorkShopEvent)
                          ? `Event Date`
                          : deliveryService.IsPickUp(shipment?.Shipper)
                          ? "Pickup Date"
                          : "Delivery Date"}
                      </Typography>
                      <Button
                        className={dateError ? classes.buttonError : classes.buttonWhereIsItGoing}
                        variant="outlined"
                        disabled={shipment.LineItems.some(
                          (li) => !li.Validation?.valid && li.Validation?.reason === "DeliveryMethod"
                        )}
                        onClick={() => setDateDialogOpen(shipment.ID)}
                      >
                        <Typography variant="h5">
                          {moment(shipment?.xp?.RequestedDeliveryDate).format("MM/DD/YYYY")}
                        </Typography>
                        {shipment.Shipper === "LocalDelivery" && (
                          <Typography component="p" variant="body2" color="textSecondary">
                            {shipment.xp?.TimePreference === "AM" && "Morning"}
                            {shipment.xp?.TimePreference === "PM" && "Afternoon"}
                            {shipment.xp?.TimePreference !== "NO PREF" && " "}({shipment.xp?.TimeWindow})
                          </Typography>
                        )}
                      </Button>
                    </Box>
                  </Box>
                  <DeliveryDateModal
                    open={dateDialogOpen === shipment.ID}
                    address={shipment.ToAddress}
                    shipment={shipment}
                    onClose={() => setDateDialogOpen(undefined)}
                    products={getProductsInShipment(shipment.ID!)}
                    onSubmit={(partial: Partial<LineItem>) => updateDeliveryDate(partial, shipment.LineItems)}
                  />
                </div>
              )}
            {shipState?.shipments[0]?.LineItems[0]?.xp.DeliveryMethod == "Email" && (
              <div className={classes.shipmentHeader}>
                {!shipment.ToAddress || editShipmentAddress === shipment.ID ? (
                  AddressDropDown(shipment, addressError)
                ) : (
                  <Fragment>
                    {shipment.ToAddress && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        alignSelf={isMobile ? "flex-start" : "center"}
                      >
                        {/* <Typography className={`${classes.addressNickName}`} variant="body1">
                          Recipient Details:
                        </Typography> */}
                        <Typography className={`${classes.addressNickName}`} variant="body1">
                          {shipment.ToAddress?.FirstName} {shipment.ToAddress?.LastName}
                        </Typography>
                        <Typography variant="body2" className={`${classes.addressName}`}>
                          {shipment?.LineItems[0]?.xp.Email} , {formatPhoneNumber(shipment)}
                        </Typography>
                        {deliveryService.IsPickUp(shipment.Shipper) && (
                          <Typography variant="body2" color="textSecondary">
                            {shipment.ToAddress?.FirstName} {shipment.ToAddress?.LastName} {formatPhoneNumber(shipment)}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Fragment>
                )}
                {!isMobile && (
                  <Fragment>
                    {editShipmentAddress === shipment.ID ? (
                      <Button
                        onClick={() => setEditShipmentAddress(undefined)}
                        className={classes.buttonEditAddress}
                        variant="text"
                        size="small"
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setEditShipmentAddress(shipment.ID)}
                        className={`${classes.buttonEditAddress} ${classes.buttonEGiftcardEdit}`}
                        variant="text"
                        size="small"
                      >
                        Edit
                      </Button>
                    )}
                  </Fragment>
                )}

                <Box id="deliveryDateGroup" className={classes.addressHeaderMobileActionBar}>
                  {isMobile && (
                    <Fragment>
                      {shipment.ToAddress && deliveryService.IsPickUp(shipment.Shipper) && (
                        <Fragment>
                          {editShipmentAddress === shipment.ID ? (
                            <Button
                              onClick={() => setEditShipmentAddress(undefined)}
                              className={classes.buttonEditAddress}
                              variant="text"
                              size="small"
                            >
                              Cancel
                            </Button>
                          ) : (
                            <Button
                              onClick={() => setEditShipmentAddress(shipment.ID)}
                              className={classes.buttonEditAddress}
                              variant="text"
                              size="small"
                            >
                              Change Pickup Location
                            </Button>
                          )}
                        </Fragment>
                      )}
                      {shipment.ToAddress && !deliveryService.IsPickUp(shipment.Shipper) && (
                        <Button
                          id="mobileEditAddressItemForOrder"
                          size="small"
                          className={classes.buttonEditAddress}
                          startIcon={editShipmentAddress ? null : <Edit fontSize="small" />}
                          onClick={() => {
                            editShipmentAddress
                              ? setEditShipmentAddress(undefined)
                              : setEditShipmentAddress(shipment.ID);
                            history.push({
                              search: `?isInLocationEditMode=${queryParams.isInLocationEditMode}`,
                            });
                          }}
                        >
                          {editShipmentAddress ? "Cancel" : "Edit"}
                        </Button>
                      )}
                    </Fragment>
                  )}
                </Box>
              </div>
            )}
            <Grid className={classes.shipmentTableHeader}>
              <Grid item sm={6}>
                <Typography className={classes.productsLabel} variant="overline">
                  {getItemCount(shipment)}
                </Typography>
              </Grid>

              <Grid item sm={GridWidths.delivery} className={classes.invisible}>
                <Typography variant="overline" className={classes.text}>
                  {shipment?.LineItems?.every((li) => li?.Product?.xp?.IsWorkShopEvent)
                    ? `Event Date`
                    : deliveryService.IsPickUp(shipment?.Shipper)
                    ? "Pickup Date"
                    : "Delivery Date"}
                </Typography>
              </Grid>

              <Grid item sm={GridWidths.price}>
                <Typography variant="overline" className={classes.text}>
                  Price
                </Typography>
              </Grid>
              <Grid item sm={GridWidths.quantity}>
                <Typography variant="overline" className={classes.text}>
                  QTY
                </Typography>
              </Grid>
              <Grid item xs className={classes.pullGridRight}>
                <Typography variant="overline" className={classes.text}>
                  Total Amount
                </Typography>
              </Grid>
            </Grid>
            <Fragment>
              {shipment?.LineItems?.map((item: LineItem, index) => (
                <LineItemRow
                  multipleRecipients={shipState?.shipToMultiple}
                  key={index}
                  lineItem={item}
                  shipment={shipment}
                  product={getLineItemProduct(item.ProductID)}
                  updateDeliveryType={updateDeliveryType}
                />
              ))}
            </Fragment>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ShipmentList;
