import React, { useState, Fragment } from "react";
import { Box, createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { Entry } from "contentful";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { neutral } from "../../themes/colors";
import { ContactUsDialog } from "./ContactUs";
import DoubleOutlinedBtn from "../Shared/DoubleOutlinedBtn";
import { Email, Phone, Print, Web } from "@material-ui/icons";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    sidebar: {
      backgroundColor: neutral.offwhite_bg,
      borderLeft: `1px solid ${neutral.grey_background}`,
      "& h4": {
        fontFamily: theme.typography.body1.fontFamily,
      },
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover, &:focus": {
          textDecoration: "underline",
          color: theme.palette.primary.dark,
        },
      },
      "& img": {
        maxWidth: "100%",
        display: "block",
        height: "auto",
      },
    },
    locationTitle: {
      textTransform: "uppercase",
    },
    address: {
      margin: theme.spacing(1, 0),
    },
  })
);
interface SidebarContentProps {
  content: Partial<Entry<any>>;
  page: Entry<any>;
}
const GenericStaticPageSidebar: React.FunctionComponent<SidebarContentProps> = (props) => {
  const classes = useStyle();
  const page = props.page;
  const sidebarContact = props.content?.fields?.sidebarContact;
  const departments = props.content?.fields?.departments;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const openContactIframe = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const getLinkablePhoneOrEmailHref = (department: any, linkKey: String, phoneType: String) => {
    let linkablePhoneOrEmailHref = "";
    if (linkKey && department != null) {
      if (phoneType) {
        linkablePhoneOrEmailHref =
          phoneType === "phone"
            ? `${linkKey}${department?.fields?.phone}`.replace("Phone: ", "").trim()
            : `${linkKey}${department?.fields?.fax}`.replace("Fax: ", "").trim();
      } else {
        linkablePhoneOrEmailHref = `${linkKey}${department?.fields?.email}`;
      }
    }
    return linkablePhoneOrEmailHref;
  };

  return (
    <Box padding={2} className={classes.sidebar}>
      <Typography variant="h4">{sidebarContact?.fields?.title} </Typography>
      <ReactMarkdown escapeHtml={false} plugins={[[gfm]]} children={sidebarContact?.fields?.description} />
      {sidebarContact?.fields?.formUrl && (
        <DoubleOutlinedBtn
          buttonText={sidebarContact?.fields?.buttonText || "Contact Us"}
          styleProps={{
            width: "calc(100% - 4px)",
            padding: 4,
            margin: "24px auto",
          }}
          buttonProps={{
            onClick: openContactIframe,
          }}
        />
      )}
      <div>
        {departments?.length && (
          <Typography variant="body2" className={classes.locationTitle}>
            Location{departments?.length > 1 ? "s" : ""}
          </Typography>
        )}
        {departments?.map((department: any, index: number) => (
          <div className={classes.address} key={index}>
            <Typography variant="body2">{department.fields?.title}</Typography>
            <Typography variant="body2">{department.fields?.streetAddress}</Typography>
            <Typography variant="body2">{department.fields?.cityStateZip}</Typography>
            {department.fields?.phone && (
              <Typography variant="body2">
                <Phone color="primary" style={{ fontSize: 15 }} />
                <Link href={`${getLinkablePhoneOrEmailHref(department, "tel:+1", "phone")}`}>
                  &nbsp;{department.fields?.phone.trim()}
                </Link>
              </Typography>
            )}

            {department.fields?.fax && (
              <Typography variant="body2">
                <Print color="primary" style={{ fontSize: 15 }} />
                <Link href={`${getLinkablePhoneOrEmailHref(department, "tel:+1", "fax")}`}>
                  &nbsp;{department.fields?.fax.trim()}
                </Link>
              </Typography>
            )}

            {department.fields?.email && (
              <Typography variant="body2">
                <Email color="primary" style={{ fontSize: 15 }} />
                <Link href={`${getLinkablePhoneOrEmailHref(department, "mailto:", "")}`}>
                  &nbsp;{department.fields?.email.trim()}
                </Link>
              </Typography>
            )}

            {department.fields?.website && (
              <Fragment>
                <Typography variant="body2">
                  <Web color="primary" style={{ fontSize: 15 }} />
                  <Link target="_blank " href={department.fields?.website}>
                    {department.fields?.website}
                  </Link>
                </Typography>
              </Fragment>
            )}
          </div>
        ))}
      </div>
      <ContactUsDialog
        page={page.fields.parentPage ? page.fields.parentPage : page}
        onClose={handleDialogClose}
        open={openDialog}
      />
    </Box>
  );
};

export default GenericStaticPageSidebar;
