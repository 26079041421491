/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Collapse,
  useMediaQuery,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  CircularProgress,
  alpha,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Address, BuyerAddress, BuyerCreditCard, Me } from "ordercloud-javascript-sdk";
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import AddressForm from "../../Shared/Address/AddressForm";
import DoubleOutlinedBtn from "../../Shared/DoubleOutlinedBtn";
import CheckoutCreditCardForm from "./CheckoutCreditCardForm";
import visa from "../../../assets/svg/payment-icons/color/visa.svg";
import mastercard from "../../../assets/svg/payment-icons/color/mastercard.svg";
import discover from "../../../assets/svg/payment-icons/color/discover.svg";
import amex from "../../../assets/svg/payment-icons/color/amex.svg";
import paypal from "../../../assets/svg/payment-icons/color/paypal.svg";
import venmo from "../../../assets/svg/payment-icons/color/venmo.svg";
import { SessionContext } from "../../../providers/session";
import { useAppSelector } from "../../../redux/store-hook";
import {
  BachmansPay,
  CardDetails,
  CreditCardUIPayment,
  PayPalUIPayment,
  PaymentTypes,
  UIPayment,
  VenmoUIPayment,
} from "../../../models/Payment";
import moment from "moment";
import { cloneDeep, isEqual, isUndefined } from "lodash";
import CheckoutGiftCardForm from "./CheckoutGiftCardForm";
import CheckoutPurplePerks from "./CheckoutPurplePerks";
import CheckoutBachmansCharge from "./CheckoutBachmansCharge";
import deliveryService from "../../../services/delivery.service";
import stringService from "../../../services/string.service";
import { SubmitOrderParams } from "..";
import { BraintreePayPalButtonsComponentProps, OnApproveBraintreeData } from "@paypal/react-paypal-js";
import { BraintreeTokenizePayload } from "@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes";
import BraintreeButtonWrapper from "./CheckoutPayPalButton";

interface PaymentInformationProps {
  submitOrder?: (params: SubmitOrderParams) => Promise<void>;
  loading?: boolean;
  address?: BuyerAddress;
  creditCardError?: string;
  updateCreditCardError?: (val: any) => void;
}

declare global {
  interface Window {
    dataLayer: any[]; // Define the type of 'dataLayer' according to your requirements
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orDivider: {
      flexGrow: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        "& hr": {
          width: "100%",
        },
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: 60,
        height: "auto",
        alignSelf: "stretch",
      },
    },
    cardMessageInstructions: {
      paddingLeft: theme.spacing(2),
      fontSize: 10,
    },
    cardMessageForm: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      "& .MuiFormControl-root": {
        width: "100%",
        margin: 0,
      },
    },
    ccDivider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    marginBlock3: {
      marginBlock: theme.spacing(3),
    },
    textPaymentMethod: {
      textTransform: "capitalize",
      lineHeight: 1,
    },
    flexGap: {
      display: "flex",
      flexFlow: "column nowrap",
      gap: theme.spacing(3),
    },
    paymentBox: {
      display: "grid",
      placeItems: "center",
      gap: theme.spacing(1),
      gridTemplateColumns: "repeat(4, 30px)",
      [theme.breakpoints.up("md")]: {
        maxWidth: 200,
      },
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    paymentLabelIcon: {
      display: "grid",
      placeItems: "center",
      maxWidth: 85,
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    buttonGroup: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-end",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& button": { fontSize: 10 },
    },
    inlineTextField: {
      display: "flex",
      alignItems: "stretch",
      gap: theme.spacing(1),
      marginTop: theme.spacing(2),
      "& .MuiFormControl-root": {
        flexGrow: 1,
        margin: 0, // margin="dense" needs to stay on this Textfield as well because it's styles the child elements
      },
      "& button": {
        fontSize: 10,
        margin: 3,
        "& .MuiButton-label": { lineHeight: 1 },
      },
    },
    btnMicro: {
      fontSize: 10,
      textDecoration: "underline",
      alignSelf: "flex-end",
      margin: 3,
    },
    loadingOverlay: {
      backgroundColor: alpha(theme.palette.grey[100], 0.75),
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      zIndex: 2,
      top: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3),
    },
    remainingBalance: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.error.main,
    },
    radioFullLabel: {
      width: "100%",
    },
  })
);

const PaymentInformation: React.FunctionComponent<PaymentInformationProps> = (props) => {
  const { address, submitOrder, creditCardError, updateCreditCardError, loading } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [bachPayState, setBachPayState] = useState<BachmansPay>({});
  const [addressEditable, setAddressEditable] = useState<BuyerAddress>();
  const [anonEmail, setAnonEmail] = useState<string>("");
  const currentOrder = useAppSelector((state) => state.order);
  const [creditCardFormValid, setCreditCardFormValid] = useState(false);
  const [savedCards, setSavedCards] = useState<BuyerCreditCard[]>();
  const [poNumber, setPONumber] = useState<string>();
  const { anonymous, user } = useContext(SessionContext);
  const [billingAddressReadOnly, setBillingAddressReadOnly] = useState<boolean>(false); //todo check if a billing address has already been saved
  const [ccReadOnly, setCCReadOnly] = useState<boolean>(false);
  const [state, setState] = React.useState({
    billingSameAsShipping: false,
    enterPromoCode: false,
  });
  const [braintreePaymentStatus, setBraintreePaymentStatus] = useState({
    PayPal: false,
    Venmo: false,
  });

  const [selectedPayment, setSelectedPayment] = useState("CreditCard");
  const [payPalBilling, setPayPalBilling] = useState<BraintreeTokenizePayload>();

  let currentOrderObject: any;

  type PaymentPriorities = {
    [key in PaymentTypes]: number;
  };

  const priorities: PaymentPriorities = useMemo(() => {
    return {
      PurplePerks: 1,
      GiftCard: 2,
      BachmansCharge: 3,
      CreditCard: 4,
      PayPal: 5,
      Venmo: 6,
    };
  }, []);

  useEffect(() => {
    console.log(currentOrder, "currentOrder");
    setAnonEmail(currentOrder?.order?.xp?.FromUserEmail || "");
  }, [currentOrder?.order]);

  useEffect(() => {
    (async () => {
      if (!anonymous) {
        if (user?.xp?.PO?.PORequired && user.xp.PO.PONumber) {
          setPONumber(user.xp.PO.PONumber);
        }
        const cards = await Me.ListCreditCards();
        setSavedCards(cards.Items);
      }
    })();
  }, [anonymous, user]);

  useEffect(() => {
    setAddressEditable({ ...address });
  }, [address]);

  // Extracting this validateAddress logic, as it is being used when pre-filling billing address from paypal
  const validateAddress = useCallback(
    (addressToValidate: BuyerAddress | undefined): boolean => {
      const hasValue = (value?: string | null) => {
        return !(value === undefined || value === null || value === "");
      };

      const isValid =
        !isUndefined(addressToValidate) &&
        !isEqual(addressToValidate, address) &&
        hasValue(addressToValidate?.FirstName) &&
        hasValue(addressToValidate?.LastName) &&
        hasValue(addressToValidate?.Street1) &&
        hasValue(addressToValidate?.State) &&
        hasValue(addressToValidate?.City) &&
        hasValue(addressToValidate?.Zip) &&
        addressToValidate.Zip!.length >= 5 &&
        hasValue(addressToValidate?.Phone) &&
        addressToValidate.Phone!.length > 9;

      return isValid;
    },
    [address]
  );

  useEffect(() => {
    /**
     * Pre-fill the billing address if details are available from paypal/venmo.
     * Also having fallback, if the value is already filled for any particular field.
     * If auto-filled address is valid, toggle-on the display only billing address.
     */
    if (payPalBilling) {
      const { billingAddress, firstName, lastName, phone } = payPalBilling.details;

      const addressToset: BuyerAddress = {
        FirstName: addressEditable?.FirstName || firstName || "",
        LastName: addressEditable?.LastName || lastName || "",
        Street1: addressEditable?.Street1 || billingAddress?.line1 || "",
        Street2: addressEditable?.Street2 || billingAddress?.line2 || "",
        City: addressEditable?.City || billingAddress?.city || "",
        State: addressEditable?.State || billingAddress?.state || "",
        Zip: addressEditable?.Zip || billingAddress?.postalCode || "",
        Phone: addressEditable?.Phone || billingAddress?.phone || phone || "",
      };
      validateAddress(addressToset) && setBillingAddressReadOnly(true);
      setAddressEditable(addressToset);
    }
  }, [payPalBilling]);

  useEffect(() => {
    if (!isUndefined(creditCardError)) {
      setCCReadOnly(false);
    }
  }, [creditCardError]);

  const setCCFormValidation = useCallback((val: boolean) => {
    setCreditCardFormValid(val);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const getShippingAddress = (asBillingAddress = false): Address | undefined => {
    if (currentOrder.shipments && currentOrder.shipments[0]) {
      const shippingAddress = cloneDeep(currentOrder?.shipments[0]?.ToAddress);
      if (asBillingAddress && shippingAddress?.ID) {
        shippingAddress.ID = undefined;
      }
      return shippingAddress;
    }
    return undefined;
  };

  const setBillingSameAsShipping = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    if (currentOrder.shipments && currentOrder.shipments[0]) {
      setBillingAddressReadOnly(event.target.checked);
      setAddressEditable(getShippingAddress(true));
    }
  };

  const canUseShippingAddress: boolean = useMemo(() => {
    return (
      currentOrder.shipments?.length === 1 &&
      !deliveryService.IsPickUp(currentOrder.shipments[0].Shipper) &&
      currentOrder.shipments[0].Shipper !== "Email"
    );
  }, [currentOrder.shipments]);

  const subtract = (first?: number, second?: number): number => {
    return Number((((first || 0) * 100 - (second || 0) * 100) / 100).toFixed(2));
  };

  const getRemainingTotalAmount = (): number => {
    let remainingTotal = currentOrder?.order?.Total;
    // subtract existing purple perks or gift card charges from remaining total.
    remainingTotal = subtract(remainingTotal, bachPayState.PurplePerks?.Amount);
    remainingTotal = subtract(remainingTotal, bachPayState.GiftCard?.Amount);
    return remainingTotal;
  };

  const updateBachPayState = (val: BachmansPay) => {
    if (updateCreditCardError) {
      updateCreditCardError(undefined);
    }
    setBachPayState(val);
  };

  const onCCEditClick = async (val: boolean) => {
    if (savedCards && savedCards.length) {
      const selectedCard = savedCards.find((saved) => saved.ID === bachPayState.CreditCard?.AcctNumber);
      if (selectedCard) {
        updateBachPayState({
          ...bachPayState,
          CreditCard: undefined,
        });
      }
    }
    setCCReadOnly(val);
  };

  const onCardSelect = async (cardID: string) => {
    if (savedCards && savedCards.length) {
      const selectedCard = savedCards.find((card) => card.ID === cardID);
      if (selectedCard) {
        if (selectedCard.xp?.BillingAddressID) {
          const billingAddress = await Me.GetAddress(selectedCard.xp?.BillingAddressID);
          setAddressEditable(billingAddress);
        }

        const expireDate = moment.utc(selectedCard.ExpirationDate);
        const cardPayment = await setCreditCardUIPayment(selectedCard, expireDate);
        setCCReadOnly(true);
        updateBachPayState({
          ...bachPayState,
          CreditCard: cardPayment,
        });
      }
    }
  };

  const setCreditCardUIPayment = async (selectedCard: BuyerCreditCard<any>, expireDate: moment.Moment) => {
    var cardPayment: CreditCardUIPayment = {
      AcctNumber: selectedCard.ID!,
      Amount: getRemainingTotalAmount(),
      CardDetails: {
        Save: false,
        CardNumber: "••••••••••••" + selectedCard.PartialAccountNumber,
        PartialAccountNumber: selectedCard.PartialAccountNumber,
        CardType: selectedCard.CardType,
        CardholderName: selectedCard.CardholderName,
        CVV: "xxx",
        ExpirationDate: `${expireDate.format("MM")} / ${expireDate.format("YY")}`,
        ExpireMonth: Number(expireDate.format("MM")),
        ExpireYear: Number(expireDate.format("YYYY")),
      },
    };
    return cardPayment;
  };

  const setBraintreeUIPayment = (
    data: OnApproveBraintreeData & {
      paymentSource?: "paypal" | "venmo";
    },
    paymentDetails: BraintreeTokenizePayload
  ) => {
    if (!paymentDetails || !data || !data.paymentSource) {
      return;
    }
    /** Reason behind managing new state instead of directly updating the addressEditable state for billing pre-filling:
     * - As this callback function is passed to braintree button, it creates the closuer for the provided
     * call-back function, thus always having only intial value of state
     * - To break the closuer, option is to rerender paypal buttons when address changes, but that is
     * not recommended due to multiple re-rendering
     */
    setPayPalBilling(paymentDetails);

    const PaymentSourceMapping = {
      paypal: "PayPal",
      venmo: "Venmo",
    };

    const PaymentMode = PaymentSourceMapping[data.paymentSource];

    const braintreePaymentDetails: PayPalUIPayment | VenmoUIPayment = {
      Amount: getRemainingTotalAmount(),
      [`${PaymentMode}Details`]: { ...paymentDetails, ...data },
    };

    updateBachPayState({
      ...bachPayState,
      CreditCard: undefined,
      [PaymentMode]: braintreePaymentDetails,
    });

    setBraintreePaymentStatus((prev) => {
      return { ...prev, [PaymentMode]: true };
    });
  };

  const updateCardDetails = (newDetails?: CardDetails) => {
    if (newDetails) {
      updateBachPayState({
        ...bachPayState,
        CreditCard: {
          CardDetails: newDetails,
        },
      });
    }
  };

  const getNonCCTotal = useCallback((paymentState: BachmansPay) => {
    const nonCCTypes: PaymentTypes[] = ["PurplePerks", "GiftCard", "BachmansCharge"];
    let amount = 0;
    nonCCTypes.forEach((type: PaymentTypes) => {
      const payment = paymentState[type];
      amount += payment?.Amount || 0;
    });
    return Number(amount.toFixed(2));
  }, []);

  const NonCCPaymentTotal: number = useMemo(() => {
    return getNonCCTotal(bachPayState);
  }, [getNonCCTotal, bachPayState]);

  const isAddressValid = useMemo(() => {
    return validateAddress(addressEditable);
  }, [address, addressEditable]);

  const isValid: boolean = useMemo(() => {
    // this validates that the user is able to check out.

    //Step 1. email is valid
    const emailOrNotAnonymous = stringService.ValidEmail(anonEmail) || !anonymous;

    // step 2. Payments valid
    const nonCCPyamentsCoverOrder: boolean =
      !isUndefined(currentOrder.order?.Total) && NonCCPaymentTotal >= (currentOrder.order?.Total || 0);

    const paymentViaBraintreeButtons = Object.values(braintreePaymentStatus).some((status) => status === true);

    const paymentsValid = nonCCPyamentsCoverOrder || creditCardFormValid || paymentViaBraintreeButtons;

    // step 3. PONumber valid
    const requiresPO = Boolean(user?.xp?.PO?.PORequired);
    const POValid = (requiresPO && poNumber && poNumber.length >= 5) || !requiresPO;

    return emailOrNotAnonymous && paymentsValid && isAddressValid && POValid;
  }, [
    anonEmail,
    anonymous,
    currentOrder.order?.Total,
    NonCCPaymentTotal,
    creditCardFormValid,
    braintreePaymentStatus,
    user?.xp?.PO?.PORequired,
    poNumber,
    isAddressValid,
  ]);

  useEffect(() => {
    let isExecuted = false;
    if (isAddressValid === true && !isExecuted) {
      isExecuted = true;
      let paymentMethod = cloneDeep(bachPayState);
      let usedPaymentMethod;
      if (paymentMethod && paymentMethod !== undefined) {
        if (paymentMethod.CreditCard) {
          usedPaymentMethod = "CreditCard";
        } else if (paymentMethod.PurplePerks) {
          usedPaymentMethod = "PurplePerks";
        } else if (paymentMethod.GiftCard) {
          usedPaymentMethod = "GiftCard";
        } else if (paymentMethod.BachmansCharge) {
          usedPaymentMethod = "BachmansCharge";
        } else if (paymentMethod.PayPal) {
          usedPaymentMethod = "PayPal";
        } else if (paymentMethod.Venmo) {
          usedPaymentMethod = "Venmo";
        }
      }

      if (currentOrder && currentOrder?.shipments && currentOrder?.order && currentOrder?.shipments[0]?.xp) {
        currentOrderObject = currentOrder?.shipments.map(function (item: any) {
          return item.LineItems.map(function (i: any) {
            if (i) {
              return {
                item_name: i.Product.Name,
                item_id: i.Product.ID,
                price: i.UnitPrice,
                quantity: i.Quantity,
              };
            }
          });
        });
        let currentOrderArray = currentOrderObject.reduce((acc: any, curr: any) => acc.concat(curr), []);
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_payment_info",
          ecommerce: {
            currency: "USD",
            value: currentOrder?.order.Total,
            payment_type: usedPaymentMethod,
            items: currentOrderArray,
          },
        });
      }
    }
  }, [isAddressValid]);

  const handleSubmit = async () => {
    /* Purchase order object starts */
    let purchaseObj;
    if (currentOrder && currentOrder?.shipments && currentOrder?.order && currentOrder?.shipments[0]?.xp) {
      let purchaseOrderobj = currentOrder?.shipments.map(function (item: any) {
        return item.LineItems.map(function (i: any) {
          if (i) {
            return {
              item_name: i.Product.Name,
              item_id: i.Product.ID,
              price: i.UnitPrice,
              quantity: i.Quantity,
            };
          }
        });
      });

      purchaseObj = purchaseOrderobj.reduce((acc, curr) => acc.concat(curr), []);
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "USD",
          value: currentOrder?.order.Total,
          tax: currentOrder?.shipments[0]?.xp.TaxableCost,
          items: purchaseObj,
        },
      });
      /* Purchase order object ends */
      const payments = cloneDeep(bachPayState);

      const remainingTotalAmount = getRemainingTotalAmount();

      // rewriting Amount property at submit, incase of changes in cart after payment authorization

      if (payments.PayPal) {
        if (remainingTotalAmount !== 0) {
          payments.PayPal.Amount = remainingTotalAmount;
        } else {
          payments.PayPal = undefined;
        }
      }

      if (payments.Venmo) {
        if (remainingTotalAmount !== 0) {
          payments.Venmo.Amount = remainingTotalAmount;
        } else {
          payments.Venmo = undefined;
        }
      }

      if (payments.CreditCard) {
        payments.CreditCard.Amount = getRemainingTotalAmount();

        if (payments.CreditCard?.CardDetails?.ExpirationDate) {
          payments.CreditCard.CardDetails.ExpirationDate = payments.CreditCard.CardDetails.ExpirationDate?.replace(
            " / ",
            ""
          );
        }
      }

      if (submitOrder && addressEditable) {
        await submitOrder({
          paymentObj: payments,
          address: addressEditable,
          anonUserEmail: anonEmail,
          poNumber: poNumber,
        });
      }
    }
  };

  const getAmountAlreadyApplied = useCallback(
    (type: PaymentTypes, localState?: BachmansPay) => {
      const payState = localState || bachPayState;
      // need to get the sum of amounts for all payment methods with priorities less than what is called
      const allTypes: PaymentTypes[] = ["PurplePerks", "GiftCard", "BachmansCharge", "CreditCard", "PayPal", "Venmo"];
      let higherPriorityAmountAlreadyApplied = 0;
      allTypes.forEach((t: PaymentTypes) => {
        if (priorities[t] < priorities[type] && payState[t] && payState[t]?.Amount) {
          higherPriorityAmountAlreadyApplied += payState[t]?.Amount || 0;
        }
      });
      return higherPriorityAmountAlreadyApplied;
    },
    [bachPayState, priorities]
  );

  const updateState = (newState: BachmansPay) => {
    // If a non Credit Card payment is used this function will correctly reset payment amounts
    // based on our predefined payment priorities.
    if (currentOrder?.order?.Total) {
      const orderTotal = currentOrder?.order?.Total;
      const allTypes = Object.keys(priorities) as PaymentTypes[];

      allTypes.forEach((type) => {
        if (newState[type] && newState[type]?.Balance) {
          const newPayment: UIPayment = {
            ...newState[type],
            Amount: newState[type]?.Balance,
          };
          if (type === "PurplePerks") newState.PurplePerks = newPayment;
          else if (type === "GiftCard") newState.GiftCard = newPayment;
          else if (type === "BachmansCharge") newState.BachmansCharge = newPayment;
        }
      });

      const NonCCAmount = getNonCCTotal(newState);
      if (NonCCAmount > orderTotal) {
        // now we need to remove some $$ from payment amounts starting with least priority
        const amountToRemove = NonCCAmount - orderTotal;
        let amountRemoved = 0;
        allTypes.reverse().forEach((type) => {
          if (
            type !== "CreditCard" &&
            type !== "PayPal" &&
            type !== "Venmo" &&
            amountRemoved < amountToRemove &&
            newState[type] &&
            newState[type]?.Amount
          ) {
            const ableToRemove = Math.min(amountToRemove - amountRemoved, newState[type]?.Amount || 0);
            const newPayment: UIPayment = {
              ...newState[type],
              Amount: newState[type]?.Amount! - ableToRemove,
            };
            if (type === "PurplePerks") newState.PurplePerks = newPayment;
            else if (type === "GiftCard") newState.GiftCard = newPayment;
            else if (type === "BachmansCharge") newState.BachmansCharge = newPayment;
            amountRemoved += ableToRemove;
          }
        });
      }
      setBachPayState(newState);
    }
  };

  const updatePaymentAmounts = (type: PaymentTypes, payment?: UIPayment) => {
    let payState = cloneDeep(bachPayState);
    if (type === "PurplePerks") {
      payState.PurplePerks = payment;
      payState.CreditCard = undefined;
      setCCReadOnly(false);
    } else if (type === "GiftCard") {
      payState.GiftCard = payment;
      payState.CreditCard = undefined;
      setCCReadOnly(false);
    } else if (type === "BachmansCharge") {
      payState.BachmansCharge = payment;
      payState.CreditCard = undefined;
      setCCReadOnly(false);
    }
    updateState(payState);
  };

  type DisabledPayments = {
    [key in PaymentTypes]: boolean;
  };

  const disabledPayments: DisabledPayments = useMemo(() => {
    const disabledObj: any = {};

    const paymentTypeDisabled = (type: PaymentTypes): boolean => {
      if (currentOrder.order?.Total) {
        const amountAlready = getAmountAlreadyApplied(type);
        return subtract(amountAlready, currentOrder.order.Total) === 0;
      } else return false;
    };

    const allTypes: PaymentTypes[] = ["PurplePerks", "GiftCard", "BachmansCharge", "CreditCard", "PayPal", "Venmo"];
    allTypes.forEach((type: PaymentTypes) => {
      const disabled = paymentTypeDisabled(type);
      disabledObj[type] = disabled;
    });
    return disabledObj;
  }, [currentOrder?.order?.Total, getAmountAlreadyApplied]);

  const style: BraintreePayPalButtonsComponentProps["style"] = { layout: "vertical" };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ event });
    setSelectedPayment(event.target.value);

    setBraintreePaymentStatus({
      Venmo: false,
      PayPal: false,
    });

    setBachPayState({
      ...bachPayState,
      CreditCard: undefined,
      Venmo: undefined,
      PayPal: undefined,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12} sm item className={classes.flexGap}>
        <Typography display="block" variant="overline">
          Select Your Payment Method
        </Typography>
        <CheckoutPurplePerks updatePaymentAmounts={updatePaymentAmounts} bachmansPay={bachPayState} />
        <CheckoutGiftCardForm
          bachmansPay={bachPayState}
          updatePaymentAmounts={updatePaymentAmounts}
          disabled={disabledPayments.GiftCard}
        />
        <CheckoutBachmansCharge
          updatePaymentAmounts={updatePaymentAmounts}
          disabled={disabledPayments.BachmansCharge}
          bachmansPay={bachPayState}
        />
        {!!(NonCCPaymentTotal && NonCCPaymentTotal > 0 && currentOrder?.order?.Total) && (
          <Typography variant="body2" className={classes.textPaymentMethod} color="error">
            Remaining order balance: <strong>${subtract(currentOrder?.order?.Total, NonCCPaymentTotal)}</strong>
          </Typography>
        )}
        <FormControl component="fieldset">
          <RadioGroup
            name="paymentMethodToggle"
            id="paymentMethodToggle"
            value={selectedPayment}
            onChange={handleRadioChange}
          >
            <Box marginBottom="16px">
              <FormControlLabel
                key="CreditCard"
                classes={{ root: classes.radioFullLabel, label: classes.radioFullLabel }}
                value="CreditCard"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h3" className={classes.textPaymentMethod} color="textSecondary">
                      Credit Card
                    </Typography>
                    <Box className={classes.paymentBox}>
                      <img src={visa} alt="visa" />
                      <img src={mastercard} alt="mastercard" />
                      <img src={discover} alt="discover" />
                      <img src={amex} alt="amex" />
                    </Box>
                  </Box>
                }
              />
              <Collapse key={"CreditCardForm"} in={selectedPayment === "CreditCard"}>
                {!disabledPayments.CreditCard && (
                  <Box>
                    {savedCards && savedCards?.length > 0 && (
                      <Fragment>
                        <FormControl fullWidth variant="outlined" margin="dense">
                          <InputLabel variant="outlined" id="savedCreditCards">
                            View Saved Credit Cards
                          </InputLabel>
                          <Select
                            value={bachPayState?.CreditCard?.AcctNumber || ""}
                            onChange={(e: any) => onCardSelect(e.target.value)}
                            labelId="savedCreditCards"
                            id="savedCreditCardsSelect"
                            label="View Saved Credit Cards"
                            disabled={disabledPayments.CreditCard}
                            MenuProps={{
                              PaperProps: {
                                square: true,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {savedCards?.map((card) => (
                              <MenuItem key={card.ID} value={card.ID}>
                                <Typography variant="body1">
                                  {card.CardholderName + " ending in " + card.PartialAccountNumber}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Divider className={classes.ccDivider} />
                      </Fragment>
                    )}
                    <CheckoutCreditCardForm
                      cardDetails={bachPayState.CreditCard?.CardDetails}
                      onChange={updateCardDetails}
                      onValidationChange={setCCFormValidation}
                      onReadOnlyChange={onCCEditClick}
                      readOnly={ccReadOnly}
                      creditCardError={creditCardError}
                      disableSave={!isUndefined(bachPayState?.CreditCard?.AcctNumber)}
                      disabled={disabledPayments.CreditCard}
                    />
                  </Box>
                )}
              </Collapse>
            </Box>

            <Box marginBottom="16px">
              <FormControlLabel
                key="PayPal"
                value="PayPal"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box className={classes.paymentLabelIcon}>
                    <img src={paypal} alt="paypal" />
                  </Box>
                }
              />
              <Collapse key={"PayPalButton"} in={selectedPayment === "PayPal" && !bachPayState.PayPal}>
                <Box display="grid">
                  <BraintreeButtonWrapper
                    amount={getRemainingTotalAmount()}
                    shippingAddress={getShippingAddress()}
                    buttonStyle={style}
                    onApproveCallBack={setBraintreeUIPayment}
                    fundingSource="paypal"
                    disabled={disabledPayments.PayPal}
                  />
                </Box>
              </Collapse>
            </Box>

            <Box marginBottom="16px">
              <FormControlLabel
                key="Venmo"
                value="Venmo"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box className={classes.paymentLabelIcon}>
                    <img src={venmo} alt="venmo" />
                  </Box>
                }
              />

              <Collapse key={"VenmoButton"} in={selectedPayment === "Venmo" && !bachPayState.Venmo}>
                <BraintreeButtonWrapper
                  amount={getRemainingTotalAmount()}
                  shippingAddress={getShippingAddress()}
                  buttonStyle={style}
                  onApproveCallBack={setBraintreeUIPayment}
                  fundingSource="venmo"
                  disabled={disabledPayments.Venmo}
                />
              </Collapse>
            </Box>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm className={classes.orDivider}>
        <Divider orientation={!isMobile ? "vertical" : "horizontal"} />
      </Grid>
      <Grid item xs={12} sm>
        <Box display="flex" justifyContent="space-between">
          <Typography display="block" variant="overline">
            {billingAddressReadOnly ? "Billing Address" : "Enter Billing Address"}
          </Typography>
          {billingAddressReadOnly && (
            <Button
              variant="text"
              size="small"
              color="primary"
              className={classes.btnMicro}
              onClick={() => {
                setState({ ...state, billingSameAsShipping: false });
                setBillingAddressReadOnly(false);
              }}
            >
              Edit
            </Button>
          )}
        </Box>
        {canUseShippingAddress && !billingAddressReadOnly && (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.billingSameAsShipping}
                onChange={setBillingSameAsShipping}
                name="billingSameAsShipping"
                color="primary"
                size="small"
              />
            }
            label="Use Shipping Address"
          />
        )}
        <Collapse in={!billingAddressReadOnly} unmountOnExit>
          <form className={classes.cardMessageForm} noValidate autoComplete="off">
            <AddressForm
              variant="Billing"
              address={addressEditable}
              addressStatic={address}
              onAddressChange={(val: BuyerAddress) => setAddressEditable(val)}
            />
          </form>
        </Collapse>

        {billingAddressReadOnly && (
          <Fragment>
            <Typography variant="body1">{addressEditable?.FirstName + " " + addressEditable?.LastName}</Typography>
            <Typography variant="body1">{addressEditable?.Street1}</Typography>
            <Typography variant="body1">{addressEditable?.Street2}</Typography>
            <Typography variant="body1">
              {addressEditable?.City + ", " + addressEditable?.State + " " + addressEditable?.Zip}
            </Typography>
            <Typography variant="body1">{addressEditable?.Phone}</Typography>
          </Fragment>
        )}
        {anonymous && (
          <Box className={classes.inlineTextField}>
            <TextField
              value={anonEmail}
              placeholder="Email"
              onChange={(e: any) => setAnonEmail(e.target.value)}
              margin="dense"
              autoFocus
              variant="outlined"
              id="anonEmail"
              name="Anonymous Email"
              label="Email"
              required
              type="text"
              disabled={currentOrder.order?.xp?.FromUserEmail != null}
            />
          </Box>
        )}
        {/* Enter PO number if necessary */}
        {Boolean(user?.xp?.PO?.PORequired) && (
          <div style={{ marginTop: "8px" }}>
            <Typography variant="body1">Enter Purchase Order Number:</Typography>
            <TextField
              fullWidth
              value={poNumber}
              onChange={(e: any) => setPONumber(stringService.OnlyAlphaNumeric(e.target.value))}
              inputProps={{ maxLength: 15 }}
              margin="dense"
              id="ponumber"
              variant="outlined"
              name="PO Number"
              label="PO Number"
              type="text"
              required
              disabled={loading}
            ></TextField>
          </div>
        )}
        <DoubleOutlinedBtn
          buttonText="Place Order"
          buttonProps={{
            type: "submit",
            disabled: !isValid || loading,
            onClick: (e: any) => {
              e.stopPropagation();
              handleSubmit();
            },
          }}
          styleProps={{
            marginLeft: isMobile ? null : "auto",
            marginTop: 24,
            padding: "4px 40px",
            width: "100%",
          }}
        />
      </Grid>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <CircularProgress />
          <Typography display="inline" variant="caption">
            Submitting Order...
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default PaymentInformation;
