import React, { Fragment, FunctionComponent, useContext } from "react";
import { Box, Button, createStyles, Grid, makeStyles, Menu, Theme, useMediaQuery } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { LIActionBarContext } from "../../../providers/lineItemAction";

interface LineItemActionBarProps {}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    lineItemActionBar: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    menuPaper: {},
    mobileLineItemActionBar: {
      "& li": {
        fontSize: "12px",
        "& .MuiSvgIcon-root": {
          marginRight: 8,
        },
      },
    },
  })
);
const LineItemActionBar: FunctionComponent<LineItemActionBarProps> = (props) => {
  const classes = useStyle();
  const { children } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { toggleOpen, isOpen } = useContext(LIActionBarContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (toggleOpen) {
      toggleOpen();
    }
  };

  const handleClose = () => {
    if (toggleOpen) {
      toggleOpen();
    }
  };

  return (
    <Fragment>
      {isMobile ? (
        <Grid item justifyContent="flex-end">
          <Button
            startIcon={<MenuIcon fontSize="small" />}
            variant="text"
            size="small"
            color="primary"
            aria-controls="mobileLineItemActionBar"
            aria-haspopup="true"
            onClick={handleClick}
          >
            More Product Options
          </Button>
          <Menu
            id="mobileLineItemActionBar"
            anchorEl={anchorEl}
            keepMounted
            open={isOpen}
            onClose={handleClose}
            className={classes.mobileLineItemActionBar}
            classes={{ paper: classes.menuPaper }}
          >
            {children}
          </Menu>
        </Grid>
      ) : (
        <Box className={classes.lineItemActionBar}>{children}</Box>
      )}
    </Fragment>
  );
};
export default LineItemActionBar;
