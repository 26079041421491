import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Container, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery, List } from "@material-ui/core";
import { neutral, purple, green } from "../../../themes/colors";
import LinkColumn from "./LinkColumn";
import SocialMediaLinks from "./SocialMediaLinks";
import PurplePerks from "../../Shared/PurplePerksCard";
import logoBadge from "../../../assets/svg/logoBadge.svg";
import { Dictionary } from "@reduxjs/toolkit";
import contentful from "../../../services/contentful.service";
import { StaticPageContext, FooterContext } from "../../../providers/contentful";
import { CatalogContext } from "../../../providers/catalog";
import { Category } from "ordercloud-javascript-sdk";
import { contentfulEventArticleLinks, contenfulStaticPage } from "../../../models/contentful/links";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { linkCoulmnType } from "./LinkColumn";
import { usePathname } from "./usePathname";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: neutral.offwhite_bg,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      paddingTop: theme.spacing(10),
      marginTop: 0,
      color: neutral.text_white_bg,
      position: "relative",
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(14),
      },
    },
    rootMarginTop: {
      marginTop: theme.spacing(12),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(14),
      },
    },
    purpleFooterSummary: {
      background: purple.regular,
      color: neutral.text_utility_bg,
      padding: theme.spacing(5, 0),
    },
    logoFooter: {
      margin: theme.spacing(0, 2, 2, 0),
      width: "clamp(80px, 9vw, 105px)",
      float: "left",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 4, 0, 0),
        float: "unset",
      },
    },
    container: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    copyright: {
      background: purple.hover,
      color: neutral.text_utility_bg,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    footerMarkdown: {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      color: "inherit",
      textAlign: "justify",
      "& a": {
        color: green.promo,
        textDecoration: "none",
        fontWeight: theme.typography.fontWeightBold,
        "&:hover, &:focus": {
          textDecoration: "underline",
        },
      },
    },
  })
);

interface FooterProps {}

const Footer = (props: FooterProps) => {
  const classes = useStyles();
  const [expandedIndex, setExpandedIndex] = React.useState(-1);
  const [formattedPages, setFormattedPages] = useState<Dictionary<any>>([]);
  const [topCategories, setTopCategories] = useState<Category[]>([]);
  const [workshopEventLinks, setWorkshopEventLinks] = useState<Array<any>>([]);
  const [footerListGroups, setfooterListGroups] = useState<{
    [key: string]: Category | contentfulEventArticleLinks | contenfulStaticPage | any;
  }>({});
  const staticPages = useContext(StaticPageContext);
  const { categories } = useContext(CatalogContext);
  const footerContentful = useContext(FooterContext);
  const path = usePathname();

  useEffect(() => {
    contentful.getWorkshopAndEventsLinks().then((response) => {
      setWorkshopEventLinks(response);
    });
  }, []);

  useEffect(() => {
    if (categories) {
      let topCats = categories.filter((c: Category) => !c.ParentID).filter((c: Category) => c.ID !== "WorkshopsEvents");
      topCats.push({
        ID: "OnSale",
        Name: "On Sale",
        Description: "",
        ListOrder: 0,
        Active: true,
        ParentID: "",
        ChildCount: 0,
        xp: {
          Link: "/customer-service/on-sale-this-week",
        },
      });
      setTopCategories(topCats);
    }
  }, [categories]);

  useEffect(() => {
    if (staticPages.ready) {
      setFormattedPages(contentful.groupStaticPagesBySection(staticPages));
    }
  }, [staticPages, footerContentful]);

  useEffect(() => {
    if (formattedPages) {
      setfooterListGroups({
        "Bachman's Products": topCategories,
        "Workshop & Events": workshopEventLinks,
        Services: formattedPages?.services,
        "Resource Hub": formattedPages.information,
        "Customer Service": formattedPages["customer-service"],
        Corporate: formattedPages.corporate,
      });
    }
  }, [staticPages, formattedPages, workshopEventLinks, setfooterListGroups, topCategories]);

  const handleExpandClick = useCallback((selectedIndex: number) => {
    setExpandedIndex((currentState) => (currentState === selectedIndex ? -1 : selectedIndex));
  }, []);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const innerContent = useMemo(() => {
    return Object.entries(footerListGroups).map(([title, links], index) => {
      let type: linkCoulmnType = "categoryLink";
      if (title === "Resource Hub") {
        type = "resourcehublink";
      }
      if (title === "Workshop & Events") type = "eventLink";
      return (
        <LinkColumn
          title={title}
          links={links ? links : []}
          expandedIndex={expandedIndex}
          index={index}
          key={index}
          expandCallback={handleExpandClick}
          isDesktop={isDesktop}
          type={type}
        ></LinkColumn>
      );
    });
  }, [expandedIndex, handleExpandClick, isDesktop, footerListGroups]);

  return (
    <footer className={`${path !== "/" ? classes.rootMarginTop : ""} ${classes.root}`}>
      <Link to="/customer-service/purple-perks-loyalty-program">
        <PurplePerks type="footer" />
      </Link>
      {isDesktop ? (
        <Container>
          <Grid container justifyContent="space-evenly" alignItems="stretch">
            {innerContent}
          </Grid>
        </Container>
      ) : (
        <List>{innerContent}</List>
      )}
      <SocialMediaLinks />
      <div className={classes.purpleFooterSummary}>
        <Container className={classes.container}>
          <img className={classes.logoFooter} alt="Bachmans.com" src={logoBadge} />
          {footerContentful.data && (
            <ReactMarkdown
              className={classes.footerMarkdown}
              plugins={[gfm]}
              children={footerContentful.data?.fields?.footerContent}
            />
          )}
        </Container>
      </div>
      <div className={classes.copyright}>
        <Typography variant="body1" color="inherit">
          &#169; Copyright {new Date().getFullYear()}, Bachman's Inc.
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
