import {
  Box,
  Button,
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { ValidatedBachmansShipmentWithLineItems } from "../../../models/shipment";
import { DeliveryNoteData } from "./index";
import $ from "jquery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMessageInstructions: {
      paddingLeft: theme.spacing(2),
      fontSize: 10,
    },
    cardMessageForm: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      "& button:only-of-type": {
        alignSelf: "flex-end",
        fontSize: 10,
        marginTop: theme.spacing(1),
      },
    },
    edit: {
      textDecoration: "underline",
      fontSize: "10px",
    },
    buttonGroup: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-end",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& button": { fontSize: 10 },
    },
  })
);

interface deliveryInstructionsProps {
  shipment?: ValidatedBachmansShipmentWithLineItems;
  deliveryNoteData?: DeliveryNoteData;
  saveDeliveryNote: (deliveryNote?: string | null) => Promise<void>;
  updateDeliveryNote: (data: DeliveryNoteData) => void;
}

const DeliveryInstructions: React.FunctionComponent<deliveryInstructionsProps> = (props) => {
  const classes = useStyles();
  const { deliveryNoteData, updateDeliveryNote, shipment, saveDeliveryNote } = props;
  const [deliveryNoteEditable, setDeliveryNoteEditable] = useState<string | null>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setDeliveryNoteEditable(deliveryNoteData?.deliveryNote);
  }, [deliveryNoteData]);

  const hasPlacement: boolean = useMemo(() => {
    return !!(shipment?.PlacementCharges && shipment?.PlacementCharges > 0);
  }, [shipment?.PlacementCharges]);

  const handleCheckboxChange = (e: any) => {
    var elementOffset = $("#deliveryInstructionsCheckbox").offset()?.top;
    if (isMobile && e.target.checked && elementOffset) {
      window.scrollTo({
        top: elementOffset - 100,
        left: 0,
        behavior: "smooth",
      });
    }
    if (!e.target.checked && deliveryNoteData?.deliveryNote && deliveryNoteData.deliveryNote !== "") {
      saveDeliveryNote(null);
      $("#delivery-info-instructions-invalid").removeClass("show");
    } else {
      updateDeliveryNote({
        showField: e.target.checked,
        readOnly: deliveryNoteData?.readOnly,
        deliveryNote: e.target.checked === false ? null : deliveryNoteData?.deliveryNote,
      });
    }
  };

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            id="deliveryInstructionsCheckbox"
            checked={deliveryNoteData?.showField || false}
            value={deliveryNoteData?.showField || false}
            disabled={hasPlacement}
            onChange={handleCheckboxChange}
            name="includeDeliveryInstructions"
            color="primary"
            size="small"
          />
        }
        label={hasPlacement ? "Include Delivery and Placement Instructions" : "Include Delivery Instructions"}
      />
      {deliveryNoteData?.deliveryNote && deliveryNoteData?.readOnly ? (
        <Box display="flex">
          <Typography variant="body2" className="deliveryInstructions">
            {deliveryNoteData?.deliveryNote}
          </Typography>
          <Button
            className={classes.edit}
            variant="text"
            color="primary"
            size="small"
            onClick={async () =>
              await updateDeliveryNote({
                ...deliveryNoteData,
                readOnly: false,
              })
            }
          >
            Edit
          </Button>
        </Box>
      ) : (
        <Collapse in={deliveryNoteData?.showField === true} unmountOnExit>
          <Typography gutterBottom variant="body2" color="textSecondary">
            Non-Contact Delivery: We are only able to place your items at the building front exterior door or front of
            garage.
          </Typography>
          <p
            id="delivery-info-instructions-invalid"
            className="MuiTypography-root MuiTypography-body2 MuiTypography-colorError delivery-info-invalid-error"
          >
            Please enter delivery instructions or uncheck the include delivery instructions checkbox.
          </p>
          <form
            className={classes.cardMessageForm}
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <TextField
              margin="dense"
              autoFocus
              variant="outlined"
              value={deliveryNoteEditable || ""}
              onChange={(e: any) => setDeliveryNoteEditable(e.target.value)}
              inputProps={{ maxLength: 60 }}
              error={(deliveryNoteEditable || "").length >= 60}
              helperText={(deliveryNoteEditable || "").length >= 60 && "The maximum line limit is 60 characters"}
              id="deliveryInstructions"
              name="Delivery Instructions"
              label={
                hasPlacement
                  ? "Include Delivery and Placement Instructions (Required)"
                  : "Include Delivery Instructions"
              }
              type="text"
              fullWidth
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  saveDeliveryNote(deliveryNoteEditable);
                }
              }}
            />
          </form>
        </Collapse>
      )}
    </Fragment>
  );
};

export default DeliveryInstructions;
